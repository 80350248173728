import React from 'react';

import TrainingSessionHeader from 'components/training_session/TrainingSessionHeader';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';
import { withData, withRouteParams } from 'support/page';
import { UrlButton } from 'components/mainComponents/buttons';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { Navigate } from 'react-router-dom/dist';
import { isVisible } from './module.js';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb/Breadcrumb.jsx';
import { flowRight as compose } from 'lodash';

import 'scss/VirtualRoomPage.scss';

const VirtualRoomPage = props => {
  const { module, training_session } = props;
  const { t } = useTranslation();
  const training_session_id = props.params.training_session_id;
  const url = `/ts/${training_session_id}/module/${module.id}/videoconference`;
  const trainingSessionName = training_session.custom_name || training_session.name;

  const moduleVisibility = isVisible(module);
  if (!moduleVisibility) return <Navigate to={`/ts/${props.params.training_session_id}/modules`} replace />;

  return (
    <div className='page VirtualRoomPage'>
      <TrainingSessionHeader training_session_id={training_session_id} />
      <main>
        <Breadcrumb
          links={[
            { name: trainingSessionName, url: `/ts/${training_session_id}` },
            { name: t('TrainingSessionHeader.modules_details'), url: `/ts/${training_session_id}/modules` },
            { name: module.name, url: `/ts/${training_session_id}/module/${module.id}` },
            { name: t('TrainingSessionHeader.video_call_room') },
          ]}
        />
        <h1>{t('TrainingSessionHeader.video_call_room')}</h1>
        <section>
          <h1>{t('TrainingSessionHeader.join_call_room')}</h1>
          <UrlButton url={url} label={t('TrainingSessionHeader.virtual_room')} icon='video--on' />
        </section>
      </main>
    </div>
  );
};

const virtualRoomModuleQuery = gql`
  query virtual_room_module($id: ID!) {
    module(id: $id) {
      id
      name
      virtualroom
      visibilityMode
      visibilityStartDate
      visibilityEndDate
    }
  }
`;
const trainingSessionQuery = gql`
  query training_session($id: ID!) {
    training_session(id: $id) {
      id
      custom_name
      name
    }
  }
`;

const withGraphqlData = compose(
  graphql(virtualRoomModuleQuery, {
    name: 'module',
    options: props => {
      return {
        variables: {
          id: props.params.id,
        },
      };
    },
  }),
  graphql(trainingSessionQuery, {
    name: 'training_session',
    options: props => {
      return {
        variables: {
          id: props.params.training_session_id,
        },
      };
    },
  })
);

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('module', 'training_session')(
        VirtualRoomPage, { loading: LoadingPagePlaceholder })));
