import { useNetwork, useReceiveSettings, useScreenShare } from '@daily-co/daily-react';
import {
  CPU_LOAD_HIGH,
  DISPLAY_MODE_FEATURED_SPEAKER,
  DISPLAY_MODE_GRID,
  MIN_TILES_LENGTH_LOW_LAYER,
  MIN_TILES_LENGTH_MIDDLE_LAYER,
  NETWORK_QUALITY_LOW,
  NETWORK_QUALITY_VERY_LOW,
  NETWORK_TOPOLOGY_SFU,
} from 'constants/video_conference';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';
import useParticipants from 'contexts/video_conference/ParticipantsContext';
import useSelectedTile from 'contexts/video_conference/SelectedTileContext';
import { useCallStatusState } from 'hooks/video_conference';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

function useReceiveSideQuality(tiles, displayMode) {
  const { topology } = useNetwork();
  const { updateReceiveSettings, receiveSettings } = useReceiveSettings();
  const { screens } = useScreenShare();

  const normalizedParticipants = useParticipants();
  const { quality, cpuLoad } = useCallStatusState();
  const { lastRemoteSpeakerId } = useCurrentSpeaker();
  const [selectedSpeakerId] = useSelectedTile();

  const hasPoorNetwork = [NETWORK_QUALITY_LOW, NETWORK_QUALITY_VERY_LOW].includes(quality);
  const hasHighCpuLoad = cpuLoad === CPU_LOAD_HIGH;
  const defaultSelectedParticipantId = normalizedParticipants[0].sessionId;

  const updateSettings = useCallback(async () => {
    if (topology !== NETWORK_TOPOLOGY_SFU) return;

    let gridLayer = 0;
    if (tiles.length < MIN_TILES_LENGTH_LOW_LAYER) gridLayer = 1;
    if (tiles.length < MIN_TILES_LENGTH_MIDDLE_LAYER) gridLayer = 2;

    const isFeatured = id => {
      const isPrimaryTile = [selectedSpeakerId, lastRemoteSpeakerId, defaultSelectedParticipantId].includes(id);
      return !screens.length && displayMode === DISPLAY_MODE_FEATURED_SPEAKER && isPrimaryTile;
    };

    const newReceiveSettings = tiles.reduce((settings, tile) => {
      if (tile.local) return settings;
      if (hasPoorNetwork || hasHighCpuLoad) {
        settings[tile.sessionId] = { video: { layer: 0 } };
      } else if (displayMode === DISPLAY_MODE_GRID) {
        settings[tile.sessionId] = { video: { layer: gridLayer } };
      } else if (isFeatured(tile.sessionId)) {
        settings[tile.sessionId] = { video: { layer: 2 } };
      } else {
        settings[tile.sessionId] = { video: { layer: 0 } };
      }
      return settings;
    }, {});

    if (_.isEmpty(newReceiveSettings)) return;
    if (_.isEqual(receiveSettings, newReceiveSettings)) return;
    updateReceiveSettings(newReceiveSettings);
  }, [
    defaultSelectedParticipantId,
    displayMode,
    hasHighCpuLoad,
    hasPoorNetwork,
    lastRemoteSpeakerId,
    selectedSpeakerId,
    screens.length,
    tiles,
    topology,
    updateReceiveSettings,
    receiveSettings,
  ]);

  const debouncedUpdateSettings = useMemo(() => _.debounce(updateSettings, 100), [updateSettings]);

  useEffect(() => debouncedUpdateSettings(), [debouncedUpdateSettings]);
}

export default useReceiveSideQuality;
