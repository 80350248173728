import React, { useRef, useState } from 'react';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { Navigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { activityItem, isVisible, mustShowTotalScore, totalScore } from 'controllers/module_controller';
import { ActivityLine } from 'components/mainComponents/ActivityLine';
import { ActivityRenderer } from './ActivityRenderer';
import { Button } from 'components/mainComponents/Button';
import { DiscussionPanel } from './DiscussionPanel';
import { GUEST_TYPE } from 'support/auth';
import { IconButton } from 'components/mainComponents/IconButton';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { MODULE_QUERY, SET_TRAINEE_CURSOR_MUTATION, TRAINING_SESSION_QUERY } from './queries';
import { ParentCallbackContextProvider } from 'contexts/ParentCallbackContext';
import { TrainingSessionBanner } from 'components/mainComponents/TrainingSessionBanner';
import { useAuth } from 'contexts/AuthContext';
import { withData, withRouteParams } from 'support/page';
import { PageLoader } from 'components/v2/layouts/PageLoader';

import './ModulePage.scss';

function Module(props) {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const previewMode = auth?.guestInfo?.type !== GUEST_TYPE.TRAINEE;
  const isSequenceEmpty = props.module.sequence.length === 0;
  const [currentActivityId, setCurrentActivityId] = useState(() => defaultCurrentActivityId());
  const progress = mustShowTotalScore(props.module) ? totalScore(props.module) : undefined;
  const [saveTraineeCursor] = useMutation(SET_TRAINEE_CURSOR_MUTATION);

  const currentActivity = props.module.sequence.find(activity => activity.id === currentActivityId);
  const currentActivityItem = activityItem(currentActivity, props.module);

  const previousActivity = getPreviousActivity();
  const nextActivity = getNextActivity();
  const pathToCurrentTrainingSessionModules = `/ts/${props.params.training_session_id}/modules`;

  const modulePageRef = useRef(null);

  if (!isVisible(props.module)) return <Navigate to={pathToCurrentTrainingSessionModules} replace />;

  return (
    <LeftMenuLayout forceHideMenu>
      <ParentCallbackContextProvider callback={props.moduleRequest.refetch}>
        <div className='v2_ModulePage' ref={modulePageRef}>
          <aside className='v2_ModulePage__activities-line'>
            <IconButton
              as='a'
              to={`/ts/${props.params.training_session_id}/modules`}
              icon='arrow--left'
              size='2xl'
              variant='primary'
              className='v2_ModulePage__back-button'
            />
            {props.module.virtualroom && (
              <Button
                as='a'
                href={`/ts/${props.training_session.id}/module/${props.module.id}/videoconference`}
                fullWidth
                className='v2_ModulePage__join-call-room-button v2_ModulePage__join-call-room-button--one-column-layout'
              >
                {t('ModulePage.join_call_room')}
              </Button>
            )}
            {!isSequenceEmpty && (
              <ActivityLine
                progress={progress}
                selectedActivityId={currentActivityId}
                onSelect={selectActivity}
                activities={props.module.sequence}
                documents={props.module.documents}
                evaluations={props.module.pending_evaluations.map(pendingEvaluation => pendingEvaluation.evaluation)}
                previewMode={previewMode}
              />
            )}
          </aside>
          <article className='v2_ModulePage__content'>
            <TrainingSessionBanner img={props.module.image?.url}>{props.module.name}</TrainingSessionBanner>
            {isSequenceEmpty ? (
              <p className='v2_ModulePage__content--empty'>{t('ModulePage.module_is_empty')}</p>
            ) : (
              <ActivityRenderer
                previewMode={previewMode}
                module={props.module}
                item={currentActivityItem}
                activity={currentActivity}
                training_session={props.training_session}
                guestId={auth?.guestInfo?.id}
              />
            )}
            {!previewMode && (previousActivity || nextActivity || isLastActivity(currentActivity?.id)) && (
              <div className='v2_ModulePage__activity-navigation'>
                {previousActivity && (
                  <span>
                    <IconButton
                      title={t('ModulePage.previous')}
                      icon='arrow--left'
                      onClick={() => selectActivity(previousActivity)}
                      variant='primary'
                    >
                      {t('ModulePage.previous')}
                    </IconButton>
                  </span>
                )}
                {nextActivity && (
                  <span className='v2_ModulePage__activity-next'>
                    <IconButton
                      title={t('ModulePage.next')}
                      icon='arrow--right'
                      onClick={() => selectActivity(nextActivity)}
                      variant='primary'
                    >
                      {t('ModulePage.next')}
                    </IconButton>
                  </span>
                )}
                {isLastActivity(currentActivity?.id) && currentActivity.finished && (
                  <span className='v2_ModulePage__activity-finish'>
                    <IconButton
                      as='a'
                      title={t('ModulePage.finish')}
                      icon='checkmark'
                      to={pathToCurrentTrainingSessionModules}
                      variant='primary'
                    >
                      {t('ModulePage.finish')}
                    </IconButton>
                  </span>
                )}
              </div>
            )}
          </article>
          <aside
            className={clsx('v2_ModulePage__social-panel v2_ModulePage__c-area', {
              'v2_ModulePage__social-panel--unavailable': !props.module.forum && !props.module.virtualroom,
            })}
          >
            {props.module.virtualroom && (
              <Button
                as='a'
                href={`/ts/${props.training_session.id}/module/${props.module.id}/videoconference`}
                fullWidth
                className='v2_ModulePage__join-call-room-button v2_ModulePage__join-call-room-button--three-columns-layout'
              >
                {t('ModulePage.join_call_room')}
              </Button>
            )}
            {props.module.forum && <DiscussionPanel moduleId={props.module.id} discussions={props.module.comments} />}
          </aside>
        </div>
      </ParentCallbackContextProvider>
    </LeftMenuLayout>
  );

  function defaultCurrentActivityId() {
    if (previewMode) {
      return props.module.sequence[0]?.id ?? null;
    }

    const cursorActivity = props.module.sequence.find(activity => activity.id === props.module.cursor?.module_sequence_item_id);
    if (cursorActivity?.available) {
      return cursorActivity.id;
    }

    const lastFinishedActivity = props.module.sequence.findLast(activity => activity.available && activity.finished);
    if (lastFinishedActivity) {
      return lastFinishedActivity.id;
    }

    return props.module.sequence[0]?.id ?? null;
  }

  function scrollToTop() {
    modulePageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function selectActivity(activity) {
    if (previewMode || activity.available) {
      setCurrentActivityId(activity.id);

      if (auth.guestInfo.type === GUEST_TYPE.trainee) {
        saveTraineeCursor({
          variables: {
            trainee_id: auth.guestInfo.id,
            module_id: props.module.id,
            module_sequence_item_id: activity.id,
          },
        });
      }
    }
    scrollToTop();
  }

  function getPreviousActivity() {
    const currentIndex = props.module.sequence.findIndex(msi => msi.id === currentActivity?.id);

    if (currentIndex > 0) {
      return props.module.sequence[currentIndex - 1];
    }
  }

  function getNextActivity() {
    const currentIndex = props.module.sequence.findIndex(msi => msi.id === currentActivity?.id);
    const potentialNextActivity = currentIndex > -1 && props.module.sequence[currentIndex + 1];

    if (previewMode || potentialNextActivity?.available) {
      return potentialNextActivity;
    }
  }

  function isLastActivity(activityId) {
    const currentIndex = props.module.sequence.findIndex(msi => msi.id === activityId);

    if (currentIndex === -1) return false;
    if (currentIndex + 1 === props.module.sequence.length) return true;
    return false;
  }
}

const withGraphqlData = compose(
  graphql(TRAINING_SESSION_QUERY, {
    name: 'training_session',
    options: props => ({
      variables: {
        id: props.params.training_session_id,
      },
    }),
  }),
  graphql(MODULE_QUERY, {
    name: 'module',
    options: props => ({
      variables: {
        id: props.params.module_id,
      },
    }),
  }),
  graphql(SET_TRAINEE_CURSOR_MUTATION, { name: 'setTraineeCursor' })
);

// prettier-ignore
export const ModulePage =
  withRouteParams(
    withGraphqlData(
      withData('training_session', { request: "module", keepFields: ["refetch"]})(
        Module, { loading: PageLoader })));
