import React from 'react';

import '../../scss/mainComponents/ItemsCollection.scss';

const Item = ({ children }) => {
  return <div>{children}</div>;
};

const ItemsCollection = ({ children }) => {
  return (
    <div className='items-collection'>
      {children.map((e, idx) => (
        <Item key={idx}>{e}</Item>
      ))}
    </div>
  );
};

export default ItemsCollection;
