import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import './Breadcrumb.scss';

export const Breadcrumb = ({ children }) => {
  return (
    <nav aria-label='Breadcrumb'>
      <ol className='V2_Breadcrumb'>{children}</ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  children: PropTypes.node,
};

Breadcrumb.Item = ({ children, url }) => {
  return <li className='V2_Breadcrumb__item'>{url ? <Link to={url}>{children}</Link> : children}</li>;
};

Breadcrumb.Item.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string,
};
