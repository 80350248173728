import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import indicatorStyles from 'scss/video_conference/BarVolumeIndicator.module.scss';
import { useAudioLevel } from '@daily-co/daily-react';
import clsx from 'clsx';
import { MIN_VOLUME_THRESHOLD } from 'constants/video_conference';

const BarVolumeIndicator = ({ audioTrack, styles, horizontal }) => {
  const audioVolumeRef = useRef(null);

  const updateIndicator = useCallback(volume => {
    if (!audioVolumeRef.current) return;
    const volumeLevel = volume >= MIN_VOLUME_THRESHOLD ? volume : 0;
    audioVolumeRef.current.style.setProperty('--volume', `${Math.min(100, 100 * volumeLevel)}%`);
  }, []);

  useAudioLevel(audioTrack, updateIndicator);

  const fullSegmentClasses = clsx(
    styles.fullSegment,
    horizontal ? indicatorStyles['fullSegment--horizontal'] : indicatorStyles['fullSegment--vertical']
  );

  return (
    <div className={styles.volumeMeter} ref={audioVolumeRef}>
      <div className={fullSegmentClasses} />
    </div>
  );
};

BarVolumeIndicator.propTypes = {
  audioTrack: PropTypes.object.isRequired,
  styles: PropTypes.shape({
    volumeMeter: PropTypes.string,
    fullSegment: PropTypes.string,
  }).isRequired,
  horizontal: PropTypes.bool,
};

BarVolumeIndicator.defaultProps = {
  horizontal: false,
};

export default BarVolumeIndicator;
