import React, { Component } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import '../../../scss/VideoPlayer.scss';
import { getGuestType, getGuestId } from '../../../support/auth';

const SAVED_WATCHED_CROSSTIMES = 'videoplayer_watched_crosstimes';
const SAVED_WATCHED_CROSSTIMES_VERSION = 1;

class VideoPlayer extends Component {
  constructor(props, context) {
    super(props, context);

    let crosstimes = [];
    if (props.wistia) {
      crosstimes = getSavedWatchedCrosstimes(props.wistia.hashed_id);
    }

    this.state = { percent: 0, crosstimes, completed: false };

    if (props.wistia) {
      // This is the way to get a callback on Wistia's init
      // this will be used to track that the video has been played
      window._wq = [
        {
          id: props.wistia.hashed_id,
          onReady: onWistiaReady(this),
        },
      ];
    } else {
      props.onRead();
    }
  }

  render() {
    const isWistia = this.props.wistia;
    const viewerId = `${getGuestType()}_${getGuestId()}`;
    const progressColor = backgroundColor(this.state.completed);

    return (
      <div className='VideoPlayer'>
        {!isWistia && <video src={this.props.url} controls controlsList='nodownload' />}

        {isWistia && (
          <div>
            <div
              className={`wistia_embed wistia_async_${this.props.wistia.hashed_id} email=${viewerId} videoFoam=true playerColor=000000 seo=false copyLinkAndThumbnail=false`}
              style={{ width: '640px', height: '360px' }}
            >
              &nbsp;
            </div>
            <div className='read-notice'>
              {this.props.t('VideoPlayer.you_must_see')}
              <div className='completion'>
                {this.state.crosstimes.map((percent, i) => (
                  <div
                    className='progress-chunk'
                    key={`chunk-${i}`}
                    style={{ left: `${percent}%`, backgroundColor: progressColor, borderColor: progressColor }}
                  />
                ))}
                <span>{this.props.t('VideoPlayer.at_least_75')}</span>
              </div>
              {this.props.t('VideoPlayer.of_video_to_validate_activity')}
            </div>
            <div className='read-state'>
              ({this.state.percent}% {this.props.t('VideoPlayer.currently_seen')})
            </div>
          </div>
        )}
      </div>
    );
  }
}

function onWistiaReady(component) {
  return function (video) {
    const duration = video.duration();

    video.bind('percentwatchedchanged', percent => {
      component.setState({ percent: Math.round(percent * 100) });
      if (percent >= 0.75) {
        component.props.onRead();
      }
    });

    video.bind('secondchange', s => {
      const percent = (s / duration) * 100;
      const percentSteppedByFives = Math.floor(percent / 5) * 5;

      if (!_.includes(component.state.crosstimes, percentSteppedByFives)) {
        const newCrosstimes = _.sortBy(component.state.crosstimes.concat([percentSteppedByFives]), x => x);
        component.setState({ crosstimes: newCrosstimes });
        saveWatchedCrosstimes(component.props.wistia.hashed_id, newCrosstimes);

        if (component.state.completed === false && component.state.crosstimes.length > 15) {
          component.setState({ completed: true });
          component.props.onRead();
        }
      }
    });
  };
}

function getSavedWatchedCrosstimes(id) {
  const datedCrosstimes = getAllSavedWatchedCrosstimes();
  const datedCrosstime = datedCrosstimes[id];

  if (datedCrosstime) {
    return datedCrosstime.crosstimes;
  }
  return [];
}

function saveWatchedCrosstimes(id, crosstimes) {
  const today = Math.floor(new Date().valueOf() / (1000 * 60 * 60 * 24));

  const datedCrosstimes = getAllSavedWatchedCrosstimes();

  const cleanedDatedCrosstimes = {};
  for (const key in datedCrosstimes) {
    const value = datedCrosstimes[key];
    if (today - value.date < 10) {
      cleanedDatedCrosstimes[key] = value; // remove 10 days old content
    }
  }

  cleanedDatedCrosstimes[id] = { date: today, crosstimes };
  localStorage.setItem(
    SAVED_WATCHED_CROSSTIMES,
    JSON.stringify({ version: SAVED_WATCHED_CROSSTIMES_VERSION, data: cleanedDatedCrosstimes })
  );
}

function getAllSavedWatchedCrosstimes() {
  const json = localStorage.getItem(SAVED_WATCHED_CROSSTIMES);
  if (json) {
    const value = JSON.parse(json);
    if (value.version === SAVED_WATCHED_CROSSTIMES_VERSION) {
      return value.data;
    }
    localStorage.removeItem(SAVED_WATCHED_CROSSTIMES);
    return {};
  }
  return {};
}

function backgroundColor(completed) {
  if (completed) {
    return '#9eefaa';
  }
  return '#ffcd5a';
}

export default withTranslation()(VideoPlayer);
