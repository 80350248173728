import _ from 'lodash';
import {
  UPDATE_STATUS,
  TOGGLE_SCREEN,
  TOGGLE_RECORD,
  NETWORK_MODE_CHANGE,
  UPDATE_QUALITY,
  UPDATE_PARTICIPANT_QUALITY,
  RECORDING_STARTED,
  RECORDING_STOPPED,
  CAMERA_STARTED,
  TOGGLE_MIC_BUTTON,
  TOGGLE_SHOULD_TURN_OFF_MIC,
  UPDATE_CPU_LOAD,
} from '../../actions/video_conference';

import { CONNECTION_TYPE_P2P, CONNECTION_TYPE_SFU } from '../../constants/video_conference/connectionTypes';

import { getStreamStates } from '../../helpers/video_conference/callStatus';

const reduceCallStatus =
  callObject =>
  (oldCallStatus, { action, payload }) => {
    const { isRecording } = oldCallStatus;

    const callFlags = () => {
      const { isCameraMuted, isMicMuted, isSharingScreen } = getStreamStates(callObject);
      return { isCameraMuted, isMicMuted, isSharingScreen };
    };

    const participants = callObject.participants();

    switch (action) {
      case UPDATE_STATUS: {
        const screenSharingParticipants = _.filter(participants, participant => participant.screen);
        return { ...oldCallStatus, screenSharingParticipants, ...callFlags() };
      }

      case TOGGLE_SCREEN: {
        const { isSharingScreen } = getStreamStates(callObject);
        isSharingScreen ? callObject.stopScreenShare() : callObject.startScreenShare();
        return { ...oldCallStatus, ...callFlags() };
      }

      case TOGGLE_RECORD: {
        const { recordingOwner } = payload;
        isRecording ? callObject.stopRecording() : callObject.startRecording();
        return { ...oldCallStatus, ...callFlags(), ...(isRecording && { isRecording: !isRecording }), recordingOwner: recordingOwner };
      }

      case RECORDING_STARTED: {
        const { recordingId } = payload;
        return { ...oldCallStatus, isRecording: true, recordingId };
      }

      case RECORDING_STOPPED: {
        return { ...oldCallStatus, isRecording: false };
      }

      case UPDATE_QUALITY: {
        const { threshold } = payload;
        return {
          ...oldCallStatus,
          quality: threshold,
        };
      }

      case UPDATE_CPU_LOAD: {
        const { threshold } = payload;
        return {
          ...oldCallStatus,
          cpuLoad: threshold,
        };
      }

      case NETWORK_MODE_CHANGE: {
        const { type, event } = payload;

        if (event === 'interrupted') {
          return { ...oldCallStatus, offlineReason: type };
        }
        const connectionType = {
          sfu: CONNECTION_TYPE_SFU,
          'peer-to-peer': CONNECTION_TYPE_P2P,
        }[type];
        return { ...oldCallStatus, offlineReason: null, connectionType };
      }

      case UPDATE_PARTICIPANT_QUALITY: {
        const { participantId, quality } = payload;

        return {
          ...oldCallStatus,
          participantQuality: {
            ...oldCallStatus.participantQuality,
            [participantId]: quality,
          },
        };
      }

      case CAMERA_STARTED: {
        return { ...oldCallStatus, isCameraStarted: true };
      }

      case TOGGLE_MIC_BUTTON: {
        return { ...oldCallStatus, isMicBtnDisabled: !oldCallStatus.isMicBtnDisabled };
      }

      case TOGGLE_SHOULD_TURN_OFF_MIC: {
        return { ...oldCallStatus, shouldTurnOffMic: !oldCallStatus.shouldTurnOffMic };
      }

      default:
        return oldCallStatus;
    }
  };

export default reduceCallStatus;
