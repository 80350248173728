import { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { HorizontalRule } from 'components/mainComponents/HorizontalRule';
import { Paper } from 'components/mainComponents/Paper';
import { Select } from 'components/mainComponents/Select';
import './MasterDetailLayout.scss';

/**
 * @summary
 * Display a master-detail view with mobile support.
 *
 * @param {object} props
 * @param {[object]} props.items Items to list. They can be any object.
 * @param {(item: object) => string | string} props.itemDisplay Either a function applied
 *  to an item which returns a display string, or the name of an item's properties to use for display.
 *  Defaults to `(item) => item.toString()`.
 * @param {string | number} props.defaultItemId Default selected item, identified
 *  by its `id` prop if any, or by its index. Defaults to index 0.
 * @param {(item: object) => ReactNode} props.children Render prop which is given the
 *  currently selected item object.
 *
 * @description
 * ### Supporting dynamic items
 * By default, when the `items` value changes, the currently selected item will most probably lead
 * to an exception because it's not part of the new `items`. If the `items` value will change,
 * you can use a `key` prop with a value related to the `items` value so that React will not
 * just update the `MasterDetailLayout` but instead re-create it.
 *
 * @example
 * const items = [
 *     {
 *        id: 1,
 *        name: "Item 1",
 *        content: "Some arbitrary content",
 *        result: { answser: "D", correct: true }
 *     },
 *     {
 *        id: 2,
 *        name: "Item 2",
 *        content: "Some arbitrary content",
 *        result: { answser: 42, correct: true }
 *     },
 *     {
 *        id: 3,
 *        name: "Item 3",
 *        content: "Some arbitrary content",
 *        result: { answser: undefined, correct: false }
 *     }
 * ];
 *
 * return (
 *     <MasterDetailLayout items={items} defaultItemId={2}>
 *         {(item) => (
 *             <>
 *                 <h1>Displaying {item.name}</h1>
 *                 <p>Content: {item.content}</p>
 *                 <h2>You can define and use any arbitrary prop in the items:</h2>
 *                 <pre>{JSON.stringify(item.result)}</pre>
 *             <>
 *         )}
 *     </MasterDetailLayout>
 * );
 */
export function MasterDetailLayout(props) {
  const defaultItemId = props.defaultItemId?.toString() ?? props.items?.[0]?.id?.toString() ?? '0';
  const [selectedItemId, setSelectedItemId] = useState(defaultItemId);
  const selectedItem = props.items?.find((item, index) => (item.id?.toString() ?? index.toString()) === selectedItemId);

  if (!props.items || props.items.length < 1) {
    throw new Error('MasterDetailLayout must have at least one master item');
  }

  return (
    <section className='MasterDetailLayout'>
      <Paper className='MasterDetailLayout__items'>
        <ul className='MasterDetailLayout__items-list'>
          {props.items.map((item, index) => {
            const itemId = item.id?.toString() ?? index.toString();
            return (
              <li key={itemId}>
                <button
                  onClick={() => setSelectedItemId(itemId)}
                  className={clsx('MasterDetailLayout__item-switcher', {
                    'MasterDetailLayout__item-switcher--active': selectedItemId === itemId,
                  })}
                >
                  {itemDisplay(item)}
                </button>
              </li>
            );
          })}
        </ul>
      </Paper>
      <div>
        <div className='MasterDetailLayout__item-selector'>
          <Select value={selectedItemId} onChange={id => setSelectedItemId(id)}>
            {props.items.map((item, index) => {
              const itemId = item.id?.toString() ?? index.toString();
              return (
                <Select.Item value={itemId} key={itemId}>
                  {itemDisplay(item)}
                </Select.Item>
              );
            })}
          </Select>
          <HorizontalRule />
        </div>
        <div>{props.children(selectedItem)}</div>
      </div>
    </section>
  );

  function itemDisplay(item) {
    if (typeof props.itemDisplay === 'function') {
      return props.itemDisplay(item);
    }

    if (typeof props.itemDisplay === 'string') {
      return item[props.itemDisplay];
    }

    throw new Error(`Unsupported itemDisplay value: ${props.itemDisplay}`);
  }
}

MasterDetailLayout.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  itemDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.func,
  defaultItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MasterDetailLayout.defaultProps = {
  itemDisplay: item => item.toString(),
};
