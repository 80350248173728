import React from 'react';
import { useTranslation } from 'react-i18next';

import 'scss/session/EmptyPage.scss';
import { Illustration } from 'components/mainComponents/Illustration';

export default function EmptyPage() {
  const { t } = useTranslation();

  return (
    <div className='page EmptyPage'>
      <Illustration name='robot-disappointed' size='lg' />

      <div>
        <h1 className='h1'>{t('EmptyPage.extranet_is_not_accessible')}</h1>
        <p>{t('EmptyPage.check_given_address')}</p>
      </div>
    </div>
  );
}
