import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/mainComponents/Icon';
import clsx from 'clsx';

export const IconButton = props => {
  const { title, ariaLabel, icon, clickFn, type, disabled, iconElement, className, ...forward } = props;
  const typeClass = clsx(type && `button--${type}`);
  const stateClass = clsx((!clickFn || disabled) && 'button--disabled');
  const normalizedAriaLabel = ariaLabel ?? title;

  const sharedProps = {
    className: clsx(iconElement ? `button--transparent ${className}` : 'button button--round', className, typeClass, stateClass),
    'aria-label': normalizedAriaLabel,
    title,
    onClick: clickFn,
    disabled,
    ...forward,
  };

  return (
    <button type='button' {...sharedProps}>
      {iconElement || (icon && <Icon icon={icon} />)}
    </button>
  );
};

const hasAriaLabel = props => {
  if (!props.ariaLabel && !props.title) {
    return new Error('IconButton requires either an ariaLabel or a title');
  }
};

IconButton.propTypes = {
  icon: PropTypes.string,
  title: hasAriaLabel,
  ariaLabel: hasAriaLabel,
  clickFn: PropTypes.func,
  type: PropTypes.oneOf(['secondary', 'large', 'error']),
  disabled: PropTypes.bool,
  iconElement: PropTypes.element,
};

IconButton.defaultProps = {
  className: '',
};

export default IconButton;
