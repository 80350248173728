import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts/AuthContext';

export const useGuestMenuEntries = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { guestInfo, switchGuestInfos } = auth;
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    if (guestInfo?.type) {
      const links = switchGuestInfos.map(info => {
        return {
          icon: 'login',
          alt: t('Header.switch_log'),
          label: t('Header.switch_log') + ' ' + t(`Header.${info.type.toLowerCase()}`),
          path: `/signin/passwordless_validation?token=${info.token}&redirect_path=/`,
        };
      });

      links.push({
        icon: 'logout',
        alt: t('Header.logout'),
        label: t('Header.logout'),
        path: `/logout`,
      });

      setEntries(links);
    }
  }, [guestInfo, switchGuestInfos, t]);

  return entries;
};

export function useGuestMenuEntriesV2() {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { guestInfo, switchGuestInfos } = auth;
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    if (guestInfo?.type) {
      const links = switchGuestInfos.map(info => {
        return {
          icon: 'login',
          label: t('Header.switch_log') + ' ' + t(`Header.${info.type.toLowerCase()}`),
          path: `/signin/passwordless_validation?token=${info.token}&redirect_path=/`,
        };
      });
      setEntries(links);
    }
  }, [guestInfo, switchGuestInfos, t]);

  return entries;
}
