import { gql } from '@apollo/client';
import _ from 'lodash';

export function deleteComment(client, comment) {
  if (comment.dailycoroom_id) {
    const dailycoRoom = cachedDailycoRoom(client, comment.dailycoroom_id);
    const comments = dailycoRoom.comments.filter(c => c.id !== comment.id);
    updateCachedDailycoRoom(client, {
      ...dailycoRoom,
      comments: comments,
    });
  } else if (comment.trainee_training_session_id) {
    const trainingSession = cachedTrainingSession(client, comment.training_session_id);
    const comments = trainingSession.comments.filter(c => c.id !== comment.id);
    updateCachedTrainingSession(client, {
      ...trainingSession,
      comments: comments,
    });
  } else {
    const module = cachedModule(client, comment.module_id);
    const comments = module.comments.filter(c => c.id !== comment.id);
    updateCachedModule(client, {
      ...module,
      comments: comments,
    });
  }
}

export function deleteSubcomment(client, parentComment, subcommentId) {
  const comment = cachedComment(client, parentComment.id);
  const subcomments = comment.subcomments.filter(sub => sub.id !== subcommentId);
  updateCachedComment(client, {
    ...comment,
    subcomments,
  });
}

export function createComment(client, newComment) {
  if (newComment.dailycoroom_id) {
    const dailycoRoom = cachedDailycoRoom(client, newComment.dailycoroom_id);
    const isCommentInCache = dailycoRoom.comments.find(comment => comment.id !== newComment.id.toString()) !== undefined;

    if (!isCommentInCache) {
      updateCachedDailycoRoom(client, {
        ...dailycoRoom,
        comments: [newComment, ...dailycoRoom.comments],
      });
    }
  } else if (newComment.trainee_training_session_id) {
    const trainingSession = cachedTrainingSession(client, newComment.training_session_id);
    const isCommentInCache = trainingSession.comments.find(comment => comment.id === newComment.id.toString()) !== undefined;
    if (!isCommentInCache) {
      updateCachedTrainingSession(client, {
        ...trainingSession,
        comments: [...trainingSession.comments, newComment],
      });
    }
  } else {
    const module = cachedModule(client, newComment.module_id);
    const isCommentInCache = module.comments.find(comment => comment.id === newComment.id.toString()) !== undefined;
    if (!isCommentInCache) {
      updateCachedModule(client, {
        ...module,
        comments: [...module.comments, newComment],
      });
    }
  }
}

export function createSubcomment(client, parent_id, subcomment) {
  const data = client.readFragment({ fragment: COMMENT_FRAGMENT, id: `Comment:${parent_id}` });
  if (!_.find(data.subcomments, s => s.id === subcomment.id.toString())) {
    client.writeFragment({
      data: {
        ...data,
        subcomments: [...data.subcomments, subcomment],
      },
      fragment: COMMENT_FRAGMENT,
      id: `Comment:${parent_id}`,
    });
  }
}

function cachedDailycoRoom(apollo, dailycoroomId) {
  return apollo.readFragment({
    fragment: DAILYCO_ROOM_FRAGMENT,
    fragmentName: 'dailycoroomComments',
    id: `Dailycoroom:${dailycoroomId}`,
  });
}

function updateCachedDailycoRoom(apollo, updatedDailycoRoom) {
  apollo.writeFragment({
    data: updatedDailycoRoom,
    fragment: DAILYCO_ROOM_FRAGMENT,
    fragmentName: 'dailycoroomComments',
    id: `Dailycoroom:${updatedDailycoRoom.id}`,
  });
}

function cachedTrainingSession(apollo, trainingSessionId) {
  return apollo.readFragment({
    fragment: TRAINING_SESSION_FRAGMENT,
    fragmentName: 'trainingsessionComments',
    id: `TrainingSession:${trainingSessionId}`,
  });
}

function updateCachedTrainingSession(apollo, updatedtrainingSession) {
  apollo.writeFragment({
    data: updatedtrainingSession,
    fragment: TRAINING_SESSION_FRAGMENT,
    fragmentName: 'trainingsessionComments',
    id: `TrainingSession:${updatedtrainingSession.id}`,
  });
}

function cachedModule(apollo, moduleId) {
  return apollo.readFragment({
    fragment: MODULE_FRAGMENT,
    fragmentName: 'moduleComments',
    id: `Module:${moduleId}`,
  });
}

function updateCachedModule(apollo, updatedModule) {
  apollo.writeFragment({
    data: updatedModule,
    fragment: MODULE_FRAGMENT,
    fragmentName: 'moduleComments',
    id: `Module:${updatedModule.id}`,
  });
}

function cachedComment(apollo, commentId) {
  return apollo.readFragment({
    fragment: COMMENT_FRAGMENT,
    id: `Comment:${commentId}`,
  });
}

function updateCachedComment(apollo, updatedComment) {
  apollo.writeFragment({
    data: updatedComment,
    fragment: COMMENT_FRAGMENT,
    id: `Comment:${updatedComment.id}`,
  });
}

export const COMMENT_FRAGMENT = gql`
  fragment comment on Comment {
    id
    text
    author
    inserted_at
    livestream_id
    dailycoroom_id
    trainee_id
    trainee_training_session_id
    trainee_id
    guest_id
    guest_type
    module_id
    subcomments {
      id
      text
      author
      inserted_at
      dailycoroom_id
      trainee_id
      trainee_training_session_id
      guest_id
      guest_type
      module_id
      subcomments {
        id
        text
        author
        inserted_at
      }
    }
  }
`;

const MODULE_FRAGMENT = gql`
  fragment moduleComments on Module {
    id
    comments {
      ...comment
    }
  }

  ${COMMENT_FRAGMENT}
`;

export const DAILYCO_ROOM_FRAGMENT = gql`
  fragment dailycoroomComments on Dailycoroom {
    id
    comments {
      ...comment
    }
  }

  ${COMMENT_FRAGMENT}
`;

const TRAINING_SESSION_FRAGMENT = gql`
  fragment trainingsessionComments on TrainingSession {
    id
    comments {
      ...comment
    }
  }

  ${COMMENT_FRAGMENT}
`;
