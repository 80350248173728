import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Trigger({ activity }) {
  return <div className='trigger'>{triggerText(activity)}</div>;
}

function triggerText({ precondition, ...rest }) {
  return (triggerTextGeneratorFns[precondition] || triggerTextNilGenerator)({ ...rest });
}

const TriggerTextDateGenerator = ({ precondition_date }) => {
  const { t } = useTranslation();
  const date = precondition_date ? new Date(precondition_date) : new Date();
  return t('ModulePage.trigger_available_at', {
    date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
  });
};
const TriggerTextScoreGenerator = ({ precondition_score }) => {
  const { t } = useTranslation();
  return t('ModulePage.trigger_minimal_score', { score: precondition_score });
};

const triggerTextNilGenerator = () => '';

const triggerTextGeneratorFns = {
  date: TriggerTextDateGenerator,
  score: TriggerTextScoreGenerator,
};

export function hasTrigger(activity) {
  return triggerTextGeneratorFns[activity.precondition];
}
