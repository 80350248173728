import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/mainComponents/Icon';
import 'scss/mainComponents/buttons/Button.scss';

export const UrlButton = props => {
  const { size, label, ariaLabel, icon, url, blank, type, className = '', ...forward } = props;

  const sizeClass = (size && `button--${size}`) || '';
  const iconClass = (icon && `button--has-icon`) || '';
  const labelClass = (label && `button--has-label`) || '';
  const typeClass = (type && `button--${type}`) || '';
  const stateClass = (!url && `button--disabled`) || '';
  const normalizedAriaLabel = ariaLabel ?? label;
  const opts = blank ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  normalizedAriaLabel ?? console.error('no ARIA label provided for button');

  const sharedProps = {
    className: `button ${className} ${sizeClass} ${iconClass} ${labelClass} ${typeClass} ${stateClass}`,
    'aria-label': normalizedAriaLabel,
    title: label,
    ...forward,
  };

  return (
    <a href={url} {...opts} {...sharedProps}>
      {icon && <Icon icon={icon} />}
      {label && (
        <label className='button__label'>
          <p>{label}</p>
        </label>
      )}
    </a>
  );
};

UrlButton.propTypes = {
  icon: PropTypes.elementType,
  size: PropTypes.oneOf(['small', 'large']),
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  url: PropTypes.string,
  blank: PropTypes.bool,
  type: PropTypes.oneOf(['secondary', 'large']),
};

UrlButton.defaultProps = {};
