import { useTranslation } from 'react-i18next';

function useErrorMessage(code) {
  const { t } = useTranslation();

  return t(`Error.message_${code}`, {
    defaultValue: t('Error.default_message'),
  });
}

export default useErrorMessage;
