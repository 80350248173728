import React, { useEffect, useRef, useState } from 'react';
import styles from '../../../scss/video_conference/Collapsible.module.scss';
import PropTypes from 'prop-types';
import Icon from 'components/mainComponents/Icon';

function Collapsible({ open, isActive, children, title, rightElement, classes, leftElement, onClick, withIcons }) {
  const [height, setHeight] = useState();
  const [isOpen, setIsOpen] = useState(open);

  const ref = useRef(null);

  const { topClassName, mainClassName, bottomClassName, contentClassName } = classes;

  const titleClasses = `${styles.title} ${!isActive ? styles['title--inactive'] : ''}`;
  const topClasses = `${styles.top} ${topClassName || ''}`;
  const collapsibleClasses = `${styles.collapsible} ${!isActive && styles['collapsible--inactive']} ${mainClassName || ''}`;
  const bottomClasses = `${styles.bottom} ${bottomClassName || ''}`;
  const contentClasses = `${styles.content} ${isOpen && styles['content--open']} ${contentClassName || ''}`;

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className={collapsibleClasses}>
      <div className={topClasses} onClick={onClick || handleToggle}>
        <div>{leftElement}</div>
        <div className={titleClasses}>
          {withIcons && <Icon icon={isOpen ? 'caret-right' : 'caret-down'} className={styles.icon} />}
          {title}
        </div>
        <div className={styles.rightElement}>{rightElement}</div>
      </div>

      <div className={bottomClasses} style={{ height }}>
        <div ref={ref} className={styles.bottomRef}>
          <div className={contentClasses}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Collapsible;

Collapsible.propTypes = {
  open: PropTypes.bool,
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  rightElement: PropTypes.node,
  leftElement: PropTypes.node,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  withIcons: PropTypes.bool,
};

Collapsible.defaultProps = {
  open: false,
  isActive: true,
  rightElement: null,
  leftElement: null,
  classes: {},
  onClick: undefined,
  withIcons: false,
};
