/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@sentry/react';
import { callAll } from '../helpers/utils';

function SentryErrorBoundary({ FallbackComponent, resetKeys, id, children, beforeCapture, ...props }) {
  return (
    <ErrorBoundary
      key={JSON.stringify(resetKeys)}
      beforeCapture={callAll(scope => scope.setTag('errorBoundaryId', id), beforeCapture)}
      // allows to use hooks in fallback
      fallback={fallbackProps => <FallbackComponent {...fallbackProps} />}
      {...props}
    >
      {children}
    </ErrorBoundary>
  );
}

SentryErrorBoundary.propTypes = {
  FallbackComponent: PropTypes.elementType.isRequired,
  id: PropTypes.string.isRequired,
  beforeCapture: PropTypes.func,
  children: PropTypes.node.isRequired,
  resetKeys: PropTypes.arrayOf(PropTypes.any),
};

SentryErrorBoundary.defaultProps = {
  beforeCapture: undefined,
  resetKeys: undefined,
};

export default SentryErrorBoundary;
