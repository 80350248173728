import React, { useCallback } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionButton } from 'components/mainComponents/buttons';

import HeaderPublicPages from 'components/HeaderPublicPages';

import 'scss/LogOutPage.scss';
import { Illustration } from 'components/mainComponents/Illustration';

const LogOutPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signout } = useAuth();

  const logout = useCallback(() => {
    signout();
    navigate('/');
  }, [signout, navigate]);

  return (
    <div className='page logout'>
      <HeaderPublicPages />
      <main>
        <h1 className='logout__title'>{t('LogOutPage.disconnection')}</h1>
        <Illustration name='robot-bye' size='lg' />
        <h2 className='logout__subtitle'>{t('LogOutPage.are_you_sure_disconnect')}</h2>
        <div className='logout__buttons'>
          <ActionButton icon='cross' label={t('LogOutPage.cancel')} clickFn={() => navigate(-1)} />
          <ActionButton icon='logout' label={t('LogOutPage.logout')} clickFn={logout} type='secondary' />
        </div>
      </main>
    </div>
  );
};
export default LogOutPage;
