import { useCallback, useEffect, useRef } from 'react';
import { INITIAL_COMMENT_COUNT } from 'constants/video_conference';
import { COMMENTS_QUERY } from 'queries/video_conference';
import { useQuery } from '@apollo/client';

// The comments will be fetched every 5 minutes
const POOL_INTERVAL = 5 * 60 * 1000;

function useCommentsQuery(
  dailycoRoomId,
  { pollInterval = POOL_INTERVAL, pageSizeIncrement = INITIAL_COMMENT_COUNT, ...options } = {}
) {
  const sizeRef = useRef(pageSizeIncrement);
  const nextPage = useRef(false);
  const getQueryVariables = useCallback(
    () => ({
      id: dailycoRoomId,
      page: 0,
      size: sizeRef.current,
    }),
    [dailycoRoomId]
  );

  const { data, networkStatus, refetch, error, ...result } = useQuery(COMMENTS_QUERY, {
    pollInterval,
    fetchPolicy: 'cache-first',
    variables: getQueryVariables(),
    onCompleted: resolvedData => {
      const resolvedCommentsLength = resolvedData?.dailycoroom?.comments?.length ?? 0;
      nextPage.current = resolvedCommentsLength === sizeRef.current;
    },
    ...options,
  });

  const comments = data?.dailycoroom.comments ?? [];

  useEffect(() => {
    // if there is comment added to cache
    if (comments.length > sizeRef.current) {
      sizeRef.current = comments.length;
    }
  }, [comments.length]);

  const fetchNextPage = useCallback(() => {
    if (!nextPage.current) {
      return Promise.resolve([]);
    }
    sizeRef.current += pageSizeIncrement;
    return refetch(getQueryVariables());
  }, [nextPage, pageSizeIncrement, refetch, getQueryVariables]);

  return {
    comments,
    fetchNextPage,
    hasNextPage: nextPage.current,
    networkStatus,
    error,
    // `refetch` `networkStatus` is equal 4,
    // but there is a bug in apollo v2 and `networkStatus` never has value 4
    // if using polling
    // so we check if pagination variables are changing (`networkStatus === 2`)
    isFetchingNextPage: networkStatus === 2,
    ...result,
  };
}

export default useCommentsQuery;
