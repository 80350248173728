import { useEffect, useCallback } from 'react';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

function useConnectionLogMutation(trainingSessionId) {
  const [logConnection] = useMutation(CONNECTION_LOG);

  const doLogging = useCallback(() => {
    logConnection({ variables: { training_session_id: trainingSessionId } });
  }, [logConnection, trainingSessionId]);

  useEffect(() => {
    const interval = setInterval(() => {
      doLogging();
    }, 30000);
    return () => clearInterval(interval);
  }, [doLogging]);
}

const CONNECTION_LOG = gql`
  mutation connection_log($training_session_id: ID!) {
    connection_log(training_session_id: $training_session_id)
  }
`;

export default useConnectionLogMutation;
