import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../../../scss/video_conference/ErrorPage.module.scss';

function ErrorPage({ error }) {
  const { t } = useTranslation();
  return (
    <div className={styles.ErrorPage}>
      <div className={styles.container}>{t('ErrorPage.error_message', { errorMessage: error.message })}</div>
    </div>
  );
}

ErrorPage.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string.isRequired }).isRequired,
};

export default ErrorPage;
