import { gql, useMutation } from '@apollo/client';
import { ComponentsRenderer } from '@a-world-for-us/elearning_content_editor';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UrlButton } from 'components/mainComponents/buttons';
import { useParentCallback } from 'contexts/ParentCallbackContext';
import { getTraineeName } from 'support/evaluationUtils';

import '../../scss/AnswerEvaluationEditableComponents.scss';

const AnswerEvaluationEditableComponents = ({ pending_evaluation: { evaluation }, trainee_id }) => {
  const fireParentCallback = useParentCallback();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { editable_components } = evaluation;
  const traineeName = getTraineeName(evaluation, evaluation.trainee_name);
  const traineeId = trainee_id === 'null' ? null : trainee_id;

  const [setEvaluationRead] = useMutation(SET_EVALUATION_READ_MUTATION);
  const [setEditableComponentRead] = useMutation(SET_EDITABLE_COMPONENT_READ_MUTATION);
  const [createQuizReply] = useMutation(CREATE_QUIZ_REPLY_MUTATION);

  useEffect(() => {
    ComponentsRenderer.init({
      elementId: 'components-renderer',
      componentsPayload: editable_components || [],
      shouldTrackProgress: true,
      onContentRead: () => {
        if (!evaluation.read) {
          setEvaluationRead({ variables: { id: evaluation.id, traineeId } });
          setTimeout(() => navigate(backPath()), 1000);
        }
      },
      onComponentRead: id => {
        setEditableComponentRead({ variables: { id } }).then(() => fireParentCallback?.());
      },
      config: {
        isReplayable: evaluation.replayable,
        sendQuizAnswer: (id, reply) => {
          const p = createQuizReply({
            variables: { id, traineeId, ...reply },
          });
          p.then(() => fireParentCallback?.());
          return p;
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backPath = () => {
    const baseUrl = `/ts/${evaluation.training_session.id}`;
    if (evaluation.module_id) {
      return `${baseUrl}/module/${evaluation.module_id}`;
    } else {
      return baseUrl;
    }
  };

  return (
    <div className='AnswerEvaluation'>
      <div>
        <h2>{evaluation.name}</h2>
        {traineeName && (
          <p className='trainee-name'>
            Apprenant: <span>{traineeName}</span>
          </p>
        )}
      </div>
      <div id='components-renderer'></div>
      <UrlButton label={t('AnswerEvaluation.back_to_session_page')} url={backPath()} icon='arrow--left' type='secondary' />
    </div>
  );
};

const SET_EVALUATION_READ_MUTATION = gql`
  mutation ($id: ID!, $traineeId: ID) {
    set_evaluation_read(id: $id, trainee_id: $traineeId)
  }
`;

const SET_EDITABLE_COMPONENT_READ_MUTATION = gql`
  mutation ($id: ID!) {
    set_editable_component_read(id: $id)
  }
`;

const CREATE_QUIZ_REPLY_MUTATION = gql`
  mutation (
    $id: ID!
    $answer: String
    $rate: Int
    $singleChoiceAnswer: QuizTestReplyInput
    $multipleChoiceAnswers: [QuizTestReplyInput]
    $matrixAnswers: [QuizMatrixReplyInput]
    $traineeId: ID
  ) {
    create_quiz_reply(
      trainee_id: $traineeId
      editable_component_id: $id
      quizFreeTextReply: { answer: $answer }
      quizStarsRatingReply: { rate: $rate }
      quizOutOfTenReply: { rate: $rate }
      quizSingleChoiceReply: $singleChoiceAnswer
      quizMultipleChoiceReplies: $multipleChoiceAnswers
      quizMatrixReplies: $matrixAnswers
    ) {
      is_verifiable
      validation {
        is_correct
        raw_correction
      }
    }
  }
`;

export default AnswerEvaluationEditableComponents;
