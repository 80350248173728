import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Heading } from 'components/mainComponents/Heading';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { UserAvatar } from 'components/mainComponents/UserAvatar';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { withData, withRouteParams } from 'support/page';

import './TrainingSessionTraineesPage.scss';

function TrainingSessionTrainees({ training_session }) {
  const { t } = useTranslation();
  const trainingSessionName = training_session.custom_name || training_session.name;

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${training_session.id}`}>{trainingSessionName}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('TrainingSessionTraineesPage.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <section>
        <Heading className='V2_TrainingSessionTraineesPage__title' as='h1' level={1}>
          {t('TrainingSessionTraineesPage.title')}
        </Heading>

        <div className='V2_TrainingSessionTraineesPage__trainees-list'>
          {training_session.trainees.map(trainee => (
            <UserAvatar user={trainee} size='lg' />
          ))}
        </div>
      </section>
    </LeftMenuLayout>
  );
}

const QUERY = gql`
  query training_session_trainees_page($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      show_trainees_in_extranet
      trainees {
        id
        firstname
        lastname
        logo {
          url
        }
        company {
          name
        }
      }
    }
  }
`;

const withGraphqlData = graphql(QUERY, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: parseInt(props.params.training_session_id, 10) },
    };
  },
});

// prettier-ignore
export const TrainingSessionTraineesPage =
    withRouteParams(
        withGraphqlData(
            withData('training_session')(
                TrainingSessionTrainees, {loading: PageLoader})));
