import React, { useState } from 'react';

import './FormInputMatrix.scss';
import { EvaluationQuestion, EvaluationQuestionLabel } from 'components/v2/evaluations/form_inputs/EvaluationQuestion';

export function FormInputMatrix(props) {
  const [answerGiven, setAnswerGiven] = useState({});

  const onAnswer = (i, j) => {
    const newAnswers = { ...answerGiven, [i]: j };
    setAnswerGiven(newAnswers);
    props.onAnswer(props.input.id, newAnswers);
  };

  const data = JSON.parse(props.input.data);
  const answerDisplayed = props.answerSent || answerGiven;

  return (
    <EvaluationQuestion>
      <EvaluationQuestionLabel>{data.topic}</EvaluationQuestionLabel>

      <table className='v2_FormInputMatrix__matrix'>
        <thead>
          <tr>
            <th />
            {data.levels.map((level, i) => (
              <th key={i} className='v2_FormInputMatrix__scale-label-container'>
                <span className='v2_FormInputMatrix__scale-label'>{level.text}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.answers.map((answer, i) => (
            <tr key={i}>
              <th scope='row' className='v2_FormInputMatrix__label'>
                {answer.text}
              </th>
              {data.levels.map((level, j) => (
                <td key={`${i}-${j}`}>
                  <button
                    onClick={() => onAnswer(i, j)}
                    className={`v2_FormInputMatrix__checkmark ${
                      isSelected(answerDisplayed, i, j) ? 'v2_FormInputMatrix__checkmark--selected' : ''
                    }`}
                    disabled={props.correcting}
                  >
                    ✓
                  </button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </EvaluationQuestion>
  );
}

function isSelected(answer, i, j) {
  return answer[i] === j;
}
