import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useAuth } from 'contexts/AuthContext';
import HeaderPublicPages from 'components/HeaderPublicPages';
import { SimpleInput, TextArea } from 'components/mainComponents/inputs';
import { ActionButton } from 'components/mainComponents/buttons';
import TechnicalError from '../TechnicalError';
import IncomingEmailNotice from '../SigninComponent/IncomingEmailNotice';
import { REQUEST_ACCESS } from './mutations';

import 'scss/session/AccessRequestPage.scss';

export default function AccessRequestPage() {
  const { auth } = useAuth();
  const [requestAccess, mutationState] = useMutation(REQUEST_ACCESS);
  const { data, error, called } = mutationState;
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (called && data && data.requestAccess && !data.requestAccess.error) {
      setSent(true);
    }
  }, [data, called]);

  const sendRequest = async () => {
    await requestAccess({
      variables: {
        email: email,
        user_code: auth?.userCode,
        firstname: firstname,
        lastname: lastname,
        reason: reason,
      },
    });
  };

  return (
    <div className='page AccessRequestPage'>
      <HeaderPublicPages />
      <div className='AccessRequestPage__content'>
        <div className='h1 AccessRequestPage__title'>{t('AccessRequestPage.send_access_request_to_extranet')}</div>

        {sent && <IncomingEmailNotice />}
        {!sent && (
          <>
            {error && <TechnicalError />}
            <div className='h2'>{t('AccessRequestPage.the_service_provider_will_receive_a_request_message')}</div>

            <div className='AccessRequestPage__form'>
              <SimpleInput
                label={t('AccessRequestPage.email')}
                type='email'
                value={email}
                placeholder={t('AccessRequestPage.email')}
                changeFn={newValue => setEmail(newValue)}
              />
              <SimpleInput
                label={t('AccessRequestPage.name')}
                type='text'
                value={firstname}
                placeholder={t('AccessRequestPage.name')}
                changeFn={newValue => setFirstname(newValue)}
              />
              <SimpleInput
                label={t('AccessRequestPage.surname')}
                type='text'
                value={lastname}
                placeholder={t('AccessRequestPage.surname')}
                changeFn={newValue => setLastname(newValue)}
              />
              <TextArea
                label={t('AccessRequestPage.free_text_in_request')}
                value={reason}
                placeholder={t('AccessRequestPage.free_text_in_request')}
                changeFn={newValue => setReason(newValue)}
              />
              <ActionButton icon='mail' clickFn={sendRequest} label={t('AccessRequestPage.send_request')} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
