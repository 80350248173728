import { PARTICIPANT_ELEMENT } from 'constants/video_conference/roomElements';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import React, { useContext } from 'react';
import styles from 'scss/video_conference/ParticipantView.module.scss';
import Avatar from 'components/video_conference/Avatar';
import { RoomOptsContext } from 'contexts/video_conference';
import { participantContent } from 'components/video_conference/Sidebar/utils';
import IconButton from 'components/video_conference/IconButton';
import { useTranslation } from 'react-i18next';

function ParticipantView() {
  const { roomElements, contentProps, closeRoomElement } = useRoomElementsDisplay();
  const isParticipantViewOpen = roomElements.includes(PARTICIPANT_ELEMENT);
  const { usersData } = useContext(RoomOptsContext);
  const { t } = useTranslation();

  const handleCloseParticipantView = () => {
    closeRoomElement(PARTICIPANT_ELEMENT);
  };

  if (isParticipantViewOpen) {
    const participant = contentProps?.PARTICIPANT_ELEMENT;
    const { userName } = participant;
    const { imageUrl, bio } = participantContent(participant, usersData);

    return (
      <div className={styles.participantView}>
        <IconButton
          icon='cross'
          ariaLabel={t('ParticipantView.close')}
          clickFn={handleCloseParticipantView}
          type='secondary'
          className={styles.closeButton}
        />
        <Avatar className={styles.avatar} src={imageUrl} icon='user-boy' shape='circle' />
        <p className={styles.text}>{userName.toUpperCase()}</p>
        <p className={styles.text}>{bio}</p>
      </div>
    );
  }
}

export default ParticipantView;
