import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TileRenderer from 'components/video_conference/Tile/TileRenderer';
import { compareParticipantsBySessionId, compareParticipantsBySpeakingActivity } from '../utils';
import AppPropTypes from 'propTypes';
import GridTile from 'components/video_conference/Tile/GridTile';
import EnterFullscreenButton from '../EnterFullscreenButton';
import gridTileStyles from 'scss/video_conference/GridTile.module.scss';
import BarVolumeIndicator from 'components/video_conference/VolumeIndicator/BarVolumeIndicator';
import ToggleCameraButton from '../ToggleCameraButton';
import styles from 'scss/video_conference/GridDisplayMode.module.scss';
import TileIcon from 'components/video_conference/Tile/TileIcon';
import useReceiveSideQuality from 'hooks/video_conference/useReceiveSideQuality';
import { DISPLAY_MODE_GRID, TILES_PER_PAGE_LIMIT } from 'constants/video_conference';
import StatusBars from 'components/video_conference/StatusBars';
import { useStatusBarsState } from 'hooks/video_conference/useStatusBars';
import { useMediaTracks } from 'hooks/video_conference/useMediaTracks';
import { PARTICIPANT_LOCAL, PARTICIPANT_SHARING_SCREEN } from 'constants/video_conference/participants';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';
import useGridMode from 'hooks/video_conference/useGridMode';
import { useParticipantIds } from '@daily-co/daily-react';
import GridPagination from './GridPagination';
import useThrottledResizeObserver from 'hooks/video_conference/useThrottledResizeObserver';

function GridDisplayMode({ tiles }) {
  const { ref, width, height } = useThrottledResizeObserver();
  const statusBars = useStatusBarsState();
  const { activityTimestamps } = useCurrentSpeaker();
  const participantIds = useParticipantIds();

  const isPaginated = tiles.length > TILES_PER_PAGE_LIMIT;
  const [[localTile], remoteTiles] = _.partition(tiles, PARTICIPANT_LOCAL);
  const [[...screenSharingTiles] = [], remainingTiles] = _.partition(remoteTiles, PARTICIPANT_SHARING_SCREEN);
  const remoteTilesInOrder = isPaginated
    ? remainingTiles.sort(compareParticipantsBySpeakingActivity(activityTimestamps))
    : remainingTiles.sort(compareParticipantsBySessionId);
  const displayedTiles = useMemo(
    () => [localTile, ...screenSharingTiles, ...remoteTilesInOrder],
    [localTile, screenSharingTiles, remoteTilesInOrder]
  );

  const { firstIndexInLastRow, defaultTileStyle, lastRowStyle, visibleParticipants, paginationProps } = useGridMode({
    width,
    height,
    sessionIds: participantIds,
    displayedTiles,
  });

  useReceiveSideQuality(tiles, DISPLAY_MODE_GRID);
  useMediaTracks(visibleParticipants);

  const visibleParticipantsWithStyle = useMemo(
    () =>
      visibleParticipants.map((tile, index) => ({
        tileInfo: tile,
        tileStyle: index < firstIndexInLastRow ? defaultTileStyle : lastRowStyle,
      })),
    [visibleParticipants, defaultTileStyle, firstIndexInLastRow, lastRowStyle]
  );

  return (
    <>
      <GridPagination {...paginationProps} />
      <div className={gridTileStyles.tileGrid} ref={ref}>
        <div className={gridTileStyles.verticalCenter}>
          {visibleParticipantsWithStyle.map(
            ({ tileInfo, tileStyle }) =>
              tileInfo.sessionId && <TileRenderer key={tileInfo.sessionId} tile={tileInfo} style={tileStyle} />
          )}
        </div>
        <StatusBars statusBars={statusBars} />
      </div>
    </>
  );
}

GridDisplayMode.tileListGenerator = ({ normalizedParticipants }) => {
  let [[localParticipant], remoteParticipants] = _.partition(normalizedParticipants, PARTICIPANT_LOCAL);

  localParticipant = {
    ...localParticipant,
    renderer: GridTile,
    leftIcons: <ToggleCameraButton />,
    rightIcons: (
      <>
        <EnterFullscreenButton
          sessionId={localParticipant.sessionId}
          className={localParticipant.audio ? '' : styles.fullscreenButton}
        />
        {localParticipant.audio && localParticipant.audioTrack && (
          <BarVolumeIndicator styles={gridTileStyles} audioTrack={localParticipant.audioTrack} />
        )}
        {!localParticipant.audio && <TileIcon icon='microphone--off' className={styles.micIcon} />}
      </>
    ),
  };

  remoteParticipants = remoteParticipants.map(eachRemoteParticipant => ({
    ...eachRemoteParticipant,
    renderer: GridTile,
    rightIcons: (
      <>
        <EnterFullscreenButton
          sessionId={eachRemoteParticipant.sessionId}
          className={!eachRemoteParticipant.audio ? styles.fullscreenButton : ''}
        />
        {!eachRemoteParticipant.audio && <TileIcon icon='microphone--off' className={styles.micIcon} />}
      </>
    ),
  }));

  const result = [localParticipant, ...remoteParticipants];

  return result;
};

GridDisplayMode.propTypes = {
  tiles: PropTypes.arrayOf(AppPropTypes.tile).isRequired,
};

export default GridDisplayMode;
