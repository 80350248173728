import React, { useState } from 'react';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import Textarea from 'react-textarea-autosize';
import { createComment, createSubcomment, COMMENT_FRAGMENT } from 'controllers/comment_controller.js';

import 'scss/CommentInput.scss';

const CommentInput = props => {
  const [draft, setDraft] = useState('');

  const onChangeDraft = e => {
    setDraft(e.target.value);
  };

  const onSendDraft = async () => {
    const { mutate, client, module_id, parent_id, dailycoroom_id, training_session_id, trainee_id } = props;
    setDraft('');
    const {
      data: { create_comment: createdComment },
    } = await mutate({
      variables: {
        module_id,
        parent_id,
        dailycoroom_id,
        training_session_id,
        trainee_id,
        text: draft,
      },
    });
    if (parent_id) {
      createSubcomment(client, parent_id, createdComment);
    } else {
      createComment(client, { ...createdComment, training_session_id });
    }
  };

  const onKeyDown = async e => {
    // submit on enter
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      await onSendDraft();
    }
  };

  const { styles } = props;
  const placeholder = props.placeholder || props.t('CommentInput.new_discussion');

  const specificStyles = {
    input: props.isSubcomment ? styles.subcommentInput : styles.commentInput,
    inputTextarea: props.isSubcomment ? styles.subcommentInputTextarea : styles.commentInputTextarea,
    inputButton: props.isSubcomment ? styles.subcommentInputButton : styles.commentInputButton,
  };

  return (
    <div className={specificStyles.input}>
      <Textarea
        className={specificStyles.inputTextarea}
        value={draft}
        onKeyDown={onKeyDown}
        onChange={onChangeDraft}
        minRows={props.minRows || 1}
        placeholder={placeholder}
      />
      {(draft.length || !props.hideButtonWhenBlank) && (
        <button className={`${specificStyles.inputButton} button primary`} onClick={onSendDraft} disabled={draft.trim().length === 0}>
          {props.inputButtonNode || props.t('CommentInput.send')}
        </button>
      )}
    </div>
  );
};

const createMutation = gql`
  mutation create_comment(
    $module_id: ID
    $text: String!
    $parent_id: ID
    $dailycoroom_id: ID
    $livestream_id: ID
    $training_session_id: ID
    $trainee_id: ID
  ) {
    create_comment(
      module_id: $module_id
      text: $text
      parent_id: $parent_id
      dailycoroom_id: $dailycoroom_id
      livestream_id: $livestream_id
      training_session_id: $training_session_id
      trainee_id: $trainee_id
    ) {
      ...comment
    }
  }

  ${COMMENT_FRAGMENT}
`;

export default withTranslation()(withApollo(graphql(createMutation)(CommentInput)));
