import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBar from './mainComponents/HeaderBar';
import { useAuth } from 'contexts/AuthContext';
import { getLocale } from 'translations/i18n';

function HeaderPublicPages() {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const locale = getLocale().toLowerCase();

  const links = [
    {
      path: '/',
      icon: 'grid',
      alt: t('TrainingSessionHeader.all_my_sessions'),
      label: t('TrainingSessionHeader.all_my_sessions'),
    },
  ];

  if (auth?.guestInfo?.type === 'trainee' && !locale.startsWith('es')) {
    links.push({
      path: `/tutorials`,
      icon: 'play--circle', // wait for another icon
      alt: t('Header.tutorials'),
      label: t('Header.tutorials'),
    });
  }
  return <HeaderBar links={links} />;
}

export default HeaderPublicPages;
