import { NETWORK_STATS_ELEMENT } from 'constants/video_conference/roomElements';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import React from 'react';
import styles from 'scss/video_conference/NetworkStatsPanel.module.scss';
import IconButton from 'components/video_conference/IconButton';
import { useTranslation } from 'react-i18next';
import { useRtcStats } from 'hooks/video_conference';
import { useDaily } from '@daily-co/daily-react';
import { MEASURING_IN_CALL } from 'constants/video_conference';
import { Paper } from 'components/mainComponents/Paper';

const generateStatsToRender = rtcPerformance => [
  { key: 'NetworkStatsPanel.download_rate', statValue: `${Math.floor(rtcPerformance?.rxBpsAvg / 1000)} kb/s` },
  { key: 'NetworkStatsPanel.download_rate', statValue: `${Math.floor(rtcPerformance?.txBpsAvg / 1000)} kb/s` },
  { key: 'NetworkStatsPanel.download_packet_loss', statValue: Math.floor(rtcPerformance?.avgRecvPacketLoss * 100) },
  { key: 'NetworkStatsPanel.upload_packet_loss', statValue: Math.floor(rtcPerformance?.avgSendPacketLoss * 100) },
];

function NetworkStatsPanel() {
  const { roomElements, closeRoomElement } = useRoomElementsDisplay();
  const isPanelOpen = roomElements.includes(NETWORK_STATS_ELEMENT);
  const { t } = useTranslation();
  const callObject = useDaily();

  const [rtcPerformance] = useRtcStats({
    measuringMode: MEASURING_IN_CALL,
    callObject,
  });

  const handleClosePanel = () => {
    closeRoomElement(NETWORK_STATS_ELEMENT);
  };

  if (isPanelOpen) {
    return (
      <div className={styles.container}>
        <IconButton
          icon='cross'
          ariaLabel={t('NetworkStatsPanel.close')}
          clickFn={handleClosePanel}
          type='secondary'
          className={styles.closeButton}
        />
        <div className={styles.networkStats}>
          {generateStatsToRender(rtcPerformance).map(({ key, statValue }) => (
            <Paper>
              <p className={styles.statsTitle}>{t(key)}</p>
              <p className={styles.statsContent}>{statValue}</p>
            </Paper>
          ))}
        </div>
      </div>
    );
  }
}

export default NetworkStatsPanel;
