import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ParticipantAudio = ({ audioTrack }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audioTrack || !audio) {
      return;
    }

    const mediaStream = new MediaStream([audioTrack]);

    audio.srcObject = mediaStream;

    return () => {
      audio.srcObject = null;
    };
  }, [audioTrack]);

  return <audio ref={audioRef} playsInline autoPlay />;
};

ParticipantAudio.propTypes = {
  audioTrack: PropTypes.instanceOf(MediaStreamTrack),
};

ParticipantAudio.defaultProps = {
  audioTrack: undefined,
};

export default React.memo(ParticipantAudio);
