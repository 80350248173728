import React from 'react';
import PropTypes from 'prop-types';
import styles from 'scss/video_conference/StatusBar.module.scss';
import IconButton from 'components/video_conference/IconButton';
import { useStatusBarsDispatch } from 'hooks/video_conference/useStatusBars';

function StatusBar({ id, content, className }) {
  const { closeStatusBar } = useStatusBarsDispatch();

  const handleStatusBarCloseClick = () => closeStatusBar(id);

  return (
    <div id='problems-status-bar' role='alert' className={`${className} ${styles.barContainer}`}>
      <p>{content}</p>
      <IconButton type='secondary' icon='cross' clickFn={handleStatusBarCloseClick} className={styles.closeButton} />
    </div>
  );
}

StatusBar.propTypes = {
  label: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

StatusBar.defaultProps = {
  className: '',
};

export default StatusBar;
