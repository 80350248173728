import React from 'react';
import { Root, Portal, Content, Item, Trigger, SubTrigger, SubContent, Sub, Label, Separator } from '@radix-ui/react-dropdown-menu';
import './Dropdown.scss';
import clsx from 'clsx';

export function Dropdown({ trigger, children }) {
  return (
    <Root>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <Content sideOffset={5} align='end' className='Dropdown'>
          {children}
        </Content>
      </Portal>
    </Root>
  );
}

export const DropdownSubTrigger = SubTrigger;
export const DropdownSub = Sub;
export const DropdownSeparator = () => <Separator className='DropdownSeparator' />;
export function DropdownSubContent({ children }) {
  return (
    <Portal>
      <SubContent sideOffset={2} alignOffset={-5} className='Dropdown'>
        {children}
      </SubContent>
    </Portal>
  );
}
export function DropdownItem(props) {
  const { children, className: extraClassName, ...otherProps } = props;
  const classname = clsx('DropdownItem', extraClassName);

  return (
    <Item className={classname} {...otherProps}>
      {children}
    </Item>
  );
}
export function DropdownLabel(props) {
  const { children, className: extraClassName, ...otherProps } = props;
  const classname = clsx('DropdownLabel', extraClassName);

  return (
    <Label className={classname} {...otherProps}>
      {children}
    </Label>
  );
}
