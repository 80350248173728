import { useReducer, useCallback } from 'react';
import { reduceRtcStats } from '../../../reducers/video_conference';
import useRtcStatsWatcher from './useRtcStatsWatcher';

// eslint-disable-next-line import/prefer-default-export
export function useRtcStats({ interval, measuringMode, callObject, startPreCallTesting }) {
  const [rtcPerformance, dispatchPerfResults] = useReducer(reduceRtcStats, {
    _rxBpsSamples: [],
    _txBpsSamples: [],
    _rxPacketLossSamples: [],
    _txPacketLossSamples: [],
    _zeroSampleCount: 0,
    _callObject: callObject,
    speedMeasureSupported: undefined,
    timestampArr: [],
    highPacketLoss: false,
    lowRtcBandwidth: false,
  });

  useRtcStatsWatcher({
    callObject,
    dispatchPerfResults,
    interval,
    measuringMode,
    startPreCallTesting,
  });

  const resetRtcPerformance = useCallback(() => {
    if (!rtcPerformance.reset) {
      dispatchPerfResults({ reset: true });
    }
  }, [dispatchPerfResults, rtcPerformance.reset]);

  return [rtcPerformance, resetRtcPerformance];
}
