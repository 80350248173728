import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAutoFullscreen from 'hooks/video_conference/useAutoFullscreen';
import IconButton from '../IconButton';
import TileIcon from '../Tile/TileIcon';

const EnterFullscreenButton = ({ sessionId, className }) => {
  const { t } = useTranslation();

  const [, setFullscreenTileId] = useAutoFullscreen();

  const handleButtonClick = () => setFullscreenTileId(sessionId);

  return (
    <IconButton
      title={t('CallBox.enter_fullscreen')}
      clickFn={handleButtonClick}
      iconElement={<TileIcon icon='expand' />}
      className={className}
    />
  );
};

EnterFullscreenButton.propTypes = {
  sessionId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

EnterFullscreenButton.defaultProps = {
  className: '',
};

export default EnterFullscreenButton;
