import BasicTile from 'components/video_conference/Tile/BasicTile';
import normalTileStyles from 'scss/video_conference/NormalTile.module.scss';
import clsx from 'clsx';
import styles from 'scss/video_conference/RemainingParticipantsTile.module.scss';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/video_conference/Avatar';
import { participantContent } from 'components/video_conference/Sidebar/utils';
import { useContext } from 'react';
import { RoomOptsContext } from 'contexts/video_conference';

function RemainingParticipantsTile({ remainingParticipants }) {
  const { t } = useTranslation();

  const { usersData } = useContext(RoomOptsContext);

  const tileClasses = clsx(normalTileStyles.Tile, styles.tile);

  return (
    remainingParticipants.length > 0 && (
      <BasicTile className={tileClasses}>
        <div className={styles.avatars}>
          {remainingParticipants.slice(0, 2).map(participant => {
            const { imageUrl } = participantContent(participant, usersData);
            return <Avatar src={imageUrl} icon='user-boy' className={styles.avatar} shape='square' />;
          })}
        </div>
        <p>{t('RemainingParticipantsTile.remaining_participants', { amount: remainingParticipants.length })}</p>
      </BasicTile>
    )
  );
}

export default RemainingParticipantsTile;
