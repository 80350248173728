import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'scss/video_conference/MicStep.module.scss';
import PreCallModal from '../../PreCallModal';
import StepContainer from '../../StepContainer';
import PropTypes from 'prop-types';
import { MIC_STEP_MODAL, NOT_ALLOWED_ERROR, PLAYBACK, RECORDED } from 'constants/video_conference';
import useMicTest from 'hooks/video_conference/useMicTest';
import useErrorsState from 'hooks/video_conference/useErrors';
import PermissionsModal from '../../PermissionsModal';
import MicStepTitle from './MicStepTitle';
import MicStepResults from './MicStepResults';
import MicStepTest from './MicStepTest';
import clsx from 'clsx';
import BarVolumeIndicator from 'components/video_conference/VolumeIndicator/BarVolumeIndicator';
import { useAudioTrack, useLocalSessionId } from '@daily-co/daily-react';

function MicStep({ onContinue, isActive, onWebcamSettingsClick, onCompleted }) {
  const { t } = useTranslation();
  const localSessionId = useLocalSessionId();
  const localAudio = useAudioTrack(localSessionId);

  const { suggestions, problems, resetState, togglePlay, state, startRecording, finishRecordingWithSuccess } = useMicTest(isActive);
  const { cameraError } = useErrorsState();

  const [micUnavailable, setMicUnavailable] = useState(false);

  const arePermissionsDenied = cameraError === NOT_ALLOWED_ERROR;
  const isRecordingFinished = state === RECORDED || state === PLAYBACK;

  const firstRowClass = clsx(styles.row, styles['row--first']);
  const lastRowClass = clsx(styles.row, styles['row--last']);
  const lastRowButtonClass = clsx(styles.row, styles['row--last'], styles['row--singleButton']);

  const handleContinue = () => {
    onCompleted(true);
    onContinue();
  };

  const handleContinueWithSuccess = () => {
    finishRecordingWithSuccess();
    onCompleted(true);
    onContinue();
  };

  return (
    isActive && (
      <StepContainer title={t('MicStep.title')}>
        <MicStepTest
          isHidden={arePermissionsDenied || micUnavailable}
          state={state}
          onCompleted={onCompleted}
          resetState={resetState}
          setMicUnavailable={setMicUnavailable}
          micUnavailable={micUnavailable}
          isRecordingFinished={isRecordingFinished}
          startRecording={startRecording}
          className={firstRowClass}
          isPlayback={state === PLAYBACK}
          onPlaybackToggle={togglePlay}
          buttonClassName={styles.recordButton}
        />
        {!arePermissionsDenied && !micUnavailable && (
          <div className={styles.row}>
            <BarVolumeIndicator styles={styles} audioTrack={localAudio.persistentTrack} horizontal />
          </div>
        )}
        <div className={styles.row}>
          <MicStepTitle
            micUnavailable={micUnavailable}
            isRecordingFinished={isRecordingFinished}
            isMicBlocked={arePermissionsDenied}
          />
        </div>
        <MicStepResults
          className={lastRowClass}
          buttonClassName={lastRowButtonClass}
          permissionsDenied={arePermissionsDenied}
          micUnavailable={micUnavailable}
          handleContinueWithSuccess={handleContinueWithSuccess}
          handleContinue={handleContinue}
          isRecordingFinished={isRecordingFinished}
        />
        <PreCallModal
          modalId={MIC_STEP_MODAL}
          title={t('MicStep.modal.title')}
          bottomText={t('MicStep.modal.bottom_text')}
          suggestions={suggestions}
          problems={problems}
          onWebcamSettingsClick={onWebcamSettingsClick}
        />
        <PermissionsModal />
      </StepContainer>
    )
  );
}

export default MicStep;

MicStep.propTypes = {
  onContinue: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  onWebcamSettingsClick: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

MicStep.defaultProps = {
  isActive: false,
};
