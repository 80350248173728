export const iconNames = [
  'alien',
  'arrow--down',
  'arrow--left',
  'arrow--right',
  'arrow--up',
  'astronaute--head',
  'award--alt',
  'award',
  'basketball',
  'bell--off',
  'bell',
  'bookmark--add',
  'bookmark--filled',
  'bookmark',
  'box',
  'burger--menu',
  'calculator',
  'calendar--blank',
  'calendar',
  'camera--filled',
  'camera--off--filled',
  'camera--off',
  'camera',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'checkbox--checked--filled',
  'checkbox--checked',
  'checkbox--indeterminate--filed',
  'checkbox--indeterminate',
  'checkbox',
  'checkmark--circle',
  'checkmark',
  'chevron--down--to--line',
  'chevron--down',
  'chevron--left--to--line',
  'chevron--left',
  'chevron--right--to--line',
  'chevron--right',
  'chevron--up--to--line',
  'chevron--up',
  'circle',
  'clip',
  'color-switch',
  'compass',
  'compress',
  'continue--circle',
  'continue',
  'contrast',
  'copy',
  'cross--circle',
  'cross--square',
  'cross',
  'diploma',
  'direction',
  'directions',
  'document--add',
  'document--audio',
  'document--blank',
  'document--doc',
  'document--docx',
  'document--pdf',
  'document--powerpoint',
  'document--subtract',
  'document--video',
  'document--word',
  'document--xls',
  'document--xlsx',
  'document',
  'documents--blank',
  'download',
  'eraser--alt',
  'eraser',
  'error',
  'expand',
  'export',
  'external--link',
  'eye--off',
  'eye',
  'face--dissatisfied',
  'face--neutral',
  'face--satisfied',
  'fill',
  'flag--filled',
  'flag',
  'floppy-disk',
  'folder--zip',
  'folder',
  'folders',
  'gear',
  'globe',
  'grid',
  'home',
  'image',
  'images',
  'info--circle--filled',
  'info--circle',
  'info--square--filled',
  'info--square',
  'info',
  'layout-auto',
  'layout-spotlight',
  'layout-tiled',
  'leaflet',
  'link',
  'list--boxes',
  'list--checked',
  'loading-spinner--dark--half',
  'loading-spinner--dark--one-quarter',
  'loading-spinner--dark--three-quarters',
  'loading-spinner--light--half',
  'loading-spinner--light--one-quarter',
  'loading-spinner--light--three-quarters',
  'location--filled',
  'location',
  'login',
  'logout',
  'magnifying-glass',
  'mail',
  'microphone--off',
  'microphone',
  'microscope',
  'minus--circle',
  'minus--square',
  'minus',
  'monitor',
  'moon--flag',
  'music',
  'notepad',
  'padlock--locked',
  'padlock--off',
  'padlock--unlocked',
  'paper-plane--alt--filled',
  'paper-plane--alt',
  'paper-plane--filled',
  'paper-plane',
  'pause--circle',
  'pause',
  'pen',
  'phone--block--filled',
  'phone--block',
  'phone--filled',
  'phone--off--filled',
  'phone--off',
  'phone--voice--filled',
  'phone--voice',
  'phone',
  'pin--alt',
  'pin',
  'play--circle',
  'play',
  'plus--circle',
  'plus--square',
  'plus',
  'present-screen--off',
  'present-screen',
  'question--circle--filled',
  'question--circle',
  'question--square--filled',
  'question--square',
  'question',
  'radio-button--checked',
  'radio-button',
  'record--circle',
  'record',
  'redo',
  'reply--all',
  'reply',
  'robot-head',
  'rocket--launch',
  'rocket',
  'shapes',
  'share',
  'sign--alt',
  'sign',
  'skip--back--circle',
  'skip--back',
  'skip--forward--circle',
  'skip--forward',
  'sliders--horizontal',
  'sliders--vertical',
  'speech-balloon--dots',
  'speech-balloon',
  'square',
  'star--filed',
  'star',
  'steps--horizontal',
  'steps--vertical',
  'stop--circle',
  'stop',
  'student--hat',
  'student--info',
  'student',
  'tag--off',
  'tag',
  'target',
  'telescope',
  'text--align--left',
  'text-align--center',
  'text-align--justify',
  'text-align--right',
  'three--dots--horizontal',
  'three--dots--vertical',
  'thumb--down--filled',
  'thumb--down',
  'thumb--up--filled',
  'thumb--up',
  'trash',
  'trophy',
  'ufo',
  'undo',
  'upload',
  'user--circle',
  'user',
  'users',
  'video--off',
  'video--on',
  'view--off',
  'view--on',
  'volum--down--alt',
  'volum--down',
  'volum--mute',
  'volum--up--alt',
  'volum--up',
  'warning--circle',
  'warning--square',
  'warning--triangle--filled',
  'warning--triangle--inverted--filled',
  'warning--triangle--inverted',
  'warning--triangle',
  'warning',
  'wifi',
  'write',
  'zoom-in',
  'zoom-out',
];
