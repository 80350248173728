import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import 'scss/support/page.scss';

const Spinner = ({ size }) => {
  const style = {
    '--dynSize': `${size}px`,
  };
  return <div className='Spinner' style={style} />;
};

export function LoadingPlaceholder(props) {
  const { className = '' } = props;
  const ref = useRef(null);
  const [size, setSize] = useState('');

  useEffect(() => {
    const refDim = Math.min(ref.current.clientHeight, ref.current.clientWidth);
    setSize(Math.trunc(refDim * 0.45));
  }, [ref]);

  return (
    <div className={className} ref={ref}>
      <Spinner size={size} />
    </div>
  );
}

const Loading = ({ fields }) => {
  const { t } = useTranslation();
  return (
    <div className='page loading-page'>
      <main>
        <h1>{t('loading')}</h1>
      </main>
    </div>
  );
};

function LoadError({ error, history }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (/unauthorized|not found/i.test(error)) {
    if (history) {
      navigate('/');
      window.alert(t('unauthorized'));
    } else {
      window.location = '/';
    }
  }

  return null;
}

/**
 * Return a HOC acting upon the availability of all data specified in `fields` in `props`.
 * When all required fields are available, renders wrapped Component.
 *
 * Apollo add one field per named query, based on the name given in graphql() options,
 * and NOT on the name used in the query text.
 *
 * Say a component is bound to 2 composed queries named "request_foo" and "request_bar", querying respectively fields "foo" and "bar",
 *
 * ```
 * const withGraphqlData = compose(
 *   graphql(gql`
 *     query dont_care($id: ID!) {
 *       foo(id: $id) {
 *        ...
 *       }
 *     }
 *     `, {
 *     name: 'request_foo',
 *     options: props => {
 *       return {
 *         variables: { id: 42 },
 *       };
 *     },
 *   }),
 *   graphql(gql`
 *     query dont_care_either($id: ID!) {
 *       bar(id: $id) {
 *        ...
 *       }
 *     }
 *     `, {
 *     name: 'request_bar',
 *     options: props => {
 *       return {
 *         variables: { id: 42 },
 *         pollInterval: 12000,
 *       };
 *     },
 *   })
 * );
 *
 * export default
 *   withRouteParams(
 *     withGraphqlData(
 *       withData({
 *         request: 'request_foo', // Name of the request
 *         data: 'foo'             // Name of the field containing the data in the request (optional if same as `request`)
 *       },
 *       {
 *         request: 'request_bar',
 *         data: 'bar'
 *       })( ... )
 * ```
 *
 * among all other attributes, props will contain :
 *
 * ```
 * {
 *   ...
 *   request_foo: {
 *     error: undefined | string,
 *     loading: true | false,
 *     foo: undefined | {...}
 *   },
 *   request_bar: {
 *     error: undefined | string,
 *     loading: true | false,
 *     bar: undefined | {...}
 *   },
 * }
 * ```
 *
 * The returned HOC will uplift request_name.field one level above when data are all available (to avoid
 * a useless but boring indirection).
 *
 * From the previous example, accessing retrieved data of each request is simply a matter of dereferencing
 * the request names : props.request_foo and props.request_bar.
 *
 * A more compact form can be used, when the name of the request and the field have the same name
 *
 * e.g. `...withData('foo', {request: 'request_bar', data: 'bar'} )( ... )`
 *
 * In such a case, data can be accessed the following way: props.foo and props.request_bar.
 *
 * When using the object form of the param, you can also provide a `keepFields` property to specify
 * additional fields to keep from the request. These fields will be accessible in `props.[requestName]Request`:
 * `...withData('foo', {request: 'request_bar', data: 'bar', keepFields: ["refetch"]} )( ... )`
 * will give the following structure:
 * ```
 * {
 *     request_bar: {}, // data from GQL
 *     request_barRequest: { refetch: ... } // all fields specified in `keepFields`
 * }
 * ```
 */
export function withData(...fields) {
  return (Component, opts = {}) => {
    const { loading: Loader = Loading } = opts;
    return props => {
      const fieldWithError = fields.find(field => {
        const [requestName] = dissectField(field);
        return !!props[requestName].error;
      });

      if (fieldWithError) {
        const fieldNameInProps = typeof fieldWithError === 'string' ? fieldWithError : fieldWithError.request;
        const error = props[fieldNameInProps].error; // Get the first error reason
        return <LoadError error={error} />;
      }

      // Mark as loading only during the interval needed for all GraphQL requests to be done the FIRST time
      //
      const stillLoading = fields.find(field => {
        const [req, f] = dissectField(field);
        return props[req][f] === undefined && props[req].loading === true;
      });
      if (stillLoading) {
        return <Loader {...props} />;
      }

      // Lift up graphql fields from props.field.field to props.field
      // Also add any field mentioned in "keepFields" when the arg is an object
      // Keep all other props, injected by other upper contextes (i18n, router, ...)
      //
      const data = fields.reduce((acc, field) => {
        const [requestName, dataFieldName, keepFields] = dissectField(field);
        const data = {
          ...acc,
          [requestName]: props[requestName][dataFieldName],
        };

        if (keepFields?.length > 0) {
          data[requestName + 'Request'] = {};

          for (const keepField of keepFields) {
            data[requestName + 'Request'][keepField] = props[requestName][keepField];
          }
        }

        return data;
      }, {});

      return <Component {...props} {...data} />;
    };
  };
}

function dissectField(field) {
  switch (typeof field) {
    case 'string': {
      return [field, field, undefined];
    }
    case 'object': {
      return [field.request, field.data ?? field.request, field.keepFields];
    }
    default: {
      throw new Error('unexpected field declaration');
    }
  }
}

/**
 * Return a HOC that will inject a merge of route and search parameters as a property
 * to wrapped element of type Component.
 *
 * Name of the property can be overriden by providing a `routeParamName` option.
 */
export function withRouteParams(Component, opts = {}) {
  const { routeParamName = 'params' } = opts;

  return forward => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const acc = {};
    for (const [key, value] of searchParams.entries()) {
      acc[key] = value;
    }

    console.log(acc);

    const routeParams = { [routeParamName]: { ...params, ...acc } };
    return <Component {...routeParams} {...forward} />;
  };
}
