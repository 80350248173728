import { useDevices } from '@daily-co/daily-react';
import { ActionButton } from 'components/mainComponents/buttons';
import Dropdown from 'components/video_conference/Dropdown';
import Tooltip from 'components/video_conference/Tooltip';
import { GRANTED_STATE, WEBCAM_RETEST_TOOLTIP } from 'constants/video_conference';
import { useSettingsPersistence } from 'hooks/video_conference';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../../../../../../scss/video_conference/WebcamStepTest.module.scss';

function WebcamStepTest({ className, isTesting, isCompleted, isCameraMuted, onCompleted, startTest, resetState }) {
  const { t } = useTranslation();
  const { cameras, camState, setCamera } = useDevices();

  const persistSetting = useSettingsPersistence();

  const [selectedCam, setSelectedCam] = useState(null);
  const [tooltipOnEvent, setTooltipOnEvent] = useState(false);
  const [isCameraChanged, setIsCameraChanged] = useState(false);

  const options = cameras?.map(cam => ({
    value: cam.device.deviceId,
    label: cam.device.label,
  }));

  const renderButtonLabel = () => {
    if (isTesting) return t('WebcamStep.button.testing');
    if (isCompleted) return t('WebcamStep.button.test_again');
    return t('WebcamStep.testButton');
  };

  const handleCamChange = newCam => {
    setCamera(newCam);
    persistSetting('audioDeviceId', newCam);
    setSelectedCam(newCam);

    if (isCompleted) {
      setTooltipOnEvent(true);
      setIsCameraChanged(true);
      resetState();
      onCompleted(false);
    }
  };
  const handleStartTest = () => {
    onCompleted(false);
    startTest();

    if (isCameraChanged) {
      setTooltipOnEvent(false);
      setIsCameraChanged(false);
    }
  };

  useEffect(() => {
    if (camState === GRANTED_STATE && selectedCam === null && cameras.some(cam => cam.selected)) {
      setSelectedCam(cameras.find(cam => cam.selected).device.deviceId);
    }
  }, [camState, selectedCam, cameras]);

  return (
    <div className={className}>
      <Dropdown
        name='mics'
        options={options}
        onChange={handleCamChange}
        value={selectedCam}
        disabled={isCameraMuted || isTesting}
        label={t('Settings.camera')}
      />
      <Tooltip
        description={isCameraChanged && t('WebcamStep.tooltip.retest')}
        tooltipOnEvent={tooltipOnEvent}
        timeout={5000}
        setTooltipOnEvent={setTooltipOnEvent}
        id={WEBCAM_RETEST_TOOLTIP}
      >
        <ActionButton
          clickFn={!isCameraMuted && !isTesting ? handleStartTest : undefined}
          label={renderButtonLabel()}
          icon={isTesting ? 'loading-spinner--dark--one-quarter' : null}
          className={styles.button}
        />
      </Tooltip>
    </div>
  );
}

export default WebcamStepTest;

WebcamStepTest.propTypes = {
  className: PropTypes.string,
  isTesting: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isCameraMuted: PropTypes.bool,
  onCompleted: PropTypes.func.isRequired,
  startTest: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
};

WebcamStepTest.defaultProps = {
  className: '',
  isTesting: false,
  isCompleted: false,
  isCameraMuted: false,
};
