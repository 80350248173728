import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { ActionButton } from 'components/mainComponents/buttons';
import HeaderPublicPages from 'components/HeaderPublicPages';
import '../../scss/TermsOfServicePage.scss';

const TermsOfServicePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { acceptTOS, signout } = useAuth();

  const { data } = useQuery(QUERY, {
    fetchPolicy: 'no-cache',
  });
  const [acceptTermsOfService] = useMutation(ACCEPT_TOS_MUTATION);

  const onAccept = () => {
    acceptTermsOfService();
    acceptTOS();
    navigate('/');
  };

  const onSignout = () => {
    signout();
    window.location.href = '/';
  };

  return (
    <div className='page TermsOfServicePage'>
      <HeaderPublicPages />
      <main>
        <h1>{t('TermsOfServicePage.title')}</h1>
        <div dangerouslySetInnerHTML={{ __html: data?.terms_of_service?.html }}></div>
        <div className='TermsOfServicePage__buttons'>
          <ActionButton icon='checkmark' label={t('TermsOfServicePage.accept')} clickFn={onAccept} />
          <ActionButton icon='logout' label={t('TermsOfServicePage.deny')} clickFn={onSignout} type='secondary' />
        </div>
      </main>
    </div>
  );
};

const QUERY = gql`
  query {
    terms_of_service {
      html
    }
  }
`;

const ACCEPT_TOS_MUTATION = gql`
  mutation {
    accept_terms_of_service
  }
`;

export default TermsOfServicePage;
