import React from 'react';
import PropTypes from 'prop-types';
import IconVolumeIndicator from './IconVolumeIndicator';
import BarVolumeIndicator from './BarVolumeIndicator';

const VOLUME_BAR = 'bar';
const VOLUME_INDICATOR = 'icon';

function VolumeIndicator({ audioTrack, mode, styles }) {
  switch (mode) {
    case VOLUME_BAR:
      return <BarVolumeIndicator styles={styles} audioTrack={audioTrack} />;

    case VOLUME_INDICATOR:
      return <IconVolumeIndicator styles={styles} audioTrack={audioTrack} />;

    default:
      throw new Error('Unknown VolumeMeter mode:', mode);
  }
}

VolumeIndicator.propTypes = {
  audioTrack: PropTypes.object.isRequired,
  mode: PropTypes.oneOf([VOLUME_BAR, VOLUME_INDICATOR]),
  styles: PropTypes.shape({
    volumeMeter: PropTypes.string,
    fullSegment: PropTypes.string,
    cornerIcon: PropTypes.string,
  }),
};

VolumeIndicator.defaultProps = {
  mode: VOLUME_BAR,
  styles: undefined,
};

export default VolumeIndicator;
