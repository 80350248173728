import { useCallback } from 'react';
import { UPDATE_PARTICIPANT_QUALITY } from 'actions/video_conference';
import { useThrottledDailyEvent } from '@daily-co/daily-react';

function useProblemIndicatorUpdating({ participantQuality, dispatch }) {
  const updateCallState = useCallback(
    events => {
      events.forEach(({ participant, action }) => {
        if (participant && (action === 'participant-joined' || !participantQuality[participant.session_id])) {
          dispatch({
            action: UPDATE_PARTICIPANT_QUALITY,
            payload: {
              participantId: participant.session_id,
              quality: {
                problemIndicators: {
                  problemIndicatorVideo: undefined,
                  problemIndicatorNetwork: undefined,
                },
              },
            },
          });
        }
      });
    },
    [dispatch, participantQuality]
  );

  useThrottledDailyEvent(['participant-joined', 'participant-updated', 'participant-left'], updateCallState);
}

export default useProblemIndicatorUpdating;
