import { useEffect, createContext, useContext } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

import { GUEST_TYPE } from 'support/auth';
import { LoadingPlaceholder, withData } from 'support/page';
import { withAuth } from 'contexts/AuthContext';

import { useIsExtranetV2EnabledLocally, IS_EXTRANET_V2_ENABLED_KEY } from './useIsExtranetV2EnabledLocally';

const UIv2Context = createContext();
export function useIsUIv2() {
  const isUIv2 = useContext(UIv2Context);
  if (typeof isUIv2 === 'undefined') {
    throw new Error('useIsUIv2 must be used inside UIv2ContextProvider');
  }
  return isUIv2;
}

export function UIv2Guard(props) {
  const { isActivable, isEnabled: isExtranetV2EnabledServerSide } = props.extranetV2;
  const isExtranetV2EnabledLocally = useIsExtranetV2EnabledLocally() && props.auth?.guestInfo?.type?.toLowerCase() === GUEST_TYPE.USER;

  useEffect(() => {
    if (isExtranetV2EnabledServerSide && isExtranetV2EnabledLocally) {
      localStorage.removeItem(IS_EXTRANET_V2_ENABLED_KEY);
    }
  }, [isExtranetV2EnabledServerSide, isExtranetV2EnabledLocally]);

  const isV2Active = isActivable && (isExtranetV2EnabledServerSide || isExtranetV2EnabledLocally);
  return (
    <UIv2Context.Provider value={isV2Active}>
      {props.children({
        isV2Active,
        isV2Activable: isActivable,
      })}
    </UIv2Context.Provider>
  );
}

const EXTRANET_V2_QUERY = gql`
  query extranetV2($userCode: String!) {
    extranetV2(userCode: $userCode) {
      isEnabled
      isActivable
    }
  }
`;

const withGraphQLData = graphql(EXTRANET_V2_QUERY, {
  name: 'extranetV2',
  options(props) {
    return {
      variables: {
        userCode: props.auth.userCode,
      },
    };
  },
});

export default withAuth(withGraphQLData(withData('extranetV2')(UIv2Guard, { loading: LoadingPlaceholder })));
