import _ from 'lodash';

export const EXTERNAL_SYNC_INTERVAL = 30_000;

export const rtcStatsActionTypes = {
  SAMPLES_MEASURED: 'SAMPLES_MEASURED',
  SAMPLES_SYNCED: 'SAMPLES_SYNCED',
  FEEDBACK_ID_CHANGED: 'FEEDBACK_ID_CHANGED',
};

export const getInitialRtcState = () => ({
  recvBitrateSamples: [],
  recvPacketLossSamples: [],
  sendBitrateSamples: [],
  sendPacketLossSamples: [],
});

const dailyKeysToGraphqlMap = {
  videoRecvBitsPerSecond: 'recvBitrateSamples',
  videoRecvPacketLoss: 'recvPacketLossSamples',
  videoSendBitsPerSecond: 'sendBitrateSamples',
  videoSendPacketLoss: 'sendPacketLossSamples',
};

export function dailyStatsToGraphqlVariables(dailyStats) {
  return Object.keys(dailyStats).reduce((acc, key) => {
    if (Object.keys(dailyKeysToGraphqlMap).includes(key)) {
      const graphqlKey = dailyKeysToGraphqlMap[key];
      return { ...acc, [graphqlKey]: dailyStats[key] };
    }
    return acc;
  }, {});
}

export function reduceQualityStats(state, action) {
  switch (action.type) {
    case rtcStatsActionTypes.SAMPLES_MEASURED: {
      const newState = _.cloneDeep(state);
      Object.keys(action.samples).forEach(sampleKey => {
        newState[sampleKey].push(action.samples[sampleKey]);
      });
      return newState;
    }
    case rtcStatsActionTypes.SAMPLES_SYNCED:
    case rtcStatsActionTypes.FEEDBACK_ID_CHANGED: {
      return {
        interval: state.interval,
        ...getInitialRtcState(),
      };
    }
    default: {
      throw new Error(`Unknown action type: ${action.type}`);
    }
  }
}
