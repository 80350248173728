import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import Vex from '../vex';
import { withData, withRouteParams } from '../../support/page';
import { formatSlotDate } from '../../support/date';
import SigningPad from './SigningPad';
import { ActionButton } from 'components/mainComponents/buttons';
import '../../scss/AttendancePage.scss';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';

const AttendanceInClassSignPage = ({ trainingSessionSlot }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromDigiforma = searchParams.get('fromDigiforma');
  const { id, customers, signatures, subsession, trainingSessionInstructors } = trainingSessionSlot;

  const [signedTrainees, setSignedTrainees] = useState([]);
  const [unsignedTrainees, setUnSignedTrainees] = useState([]);
  const [selectedTrainee, setSelectedTrainee] = useState(null);

  const [signingAgainTraineeId, setSigningAgainTraineeId] = useState(null);

  useEffect(() => {
    const traineeTrainingSessions = customers.flatMap(it => it.customerTrainees);
    const signedTrainees = traineeTrainingSessions.reduce((acc, item) => {
      const foundSignature = signatures.find(it => it?.customerTrainee?.id === item.id);
      if (foundSignature && foundSignature.signature !== 'EMPTY') {
        acc.push({ ...item.trainee, ...item, signature: foundSignature.signature });
      }
      return acc;
    }, []);
    const unsignedTrainees = traineeTrainingSessions
      .filter(item => !signedTrainees.some(it => it.id === item.id))
      .map(it => ({ ...it.trainee, ...it }));

    const signedInstructors = trainingSessionInstructors.reduce((acc, item) => {
      const foundSignature = signatures.find(it => it?.trainingSessionInstructor?.id === item.id);
      if (foundSignature && foundSignature.signature !== 'EMPTY') {
        acc.push({ ...item.instructor, ...item, signature: foundSignature.signature, isInstructor: true });
      }
      return acc;
    }, []);
    const unsignedInstructors = trainingSessionInstructors
      .filter(item => !signedInstructors.some(it => it.id === item.id))
      .map(it => ({ ...it.instructor, ...it, isInstructor: true }));

    setSignedTrainees([...signedTrainees, ...signedInstructors]);
    setUnSignedTrainees([...unsignedTrainees, ...unsignedInstructors]);
  }, [customers, trainingSessionInstructors, signatures]);

  const renderBackButton = () => {
    if (searchParams.get('goback') === 'true') {
      return (
        <ActionButton icon='arrow--left' label={t('AttendanceSingleSignPage.go_back')} clickFn={() => navigate(-1)} type='secondary' />
      );
    }

    return null;
  };

  const renderSelectedUnsignedTrainee = item => (
    <li className='unsigned-trainee selected'>
      <button disabled>
        <span className='lastname'>{item.lastname}</span>
        <span className='firstname'>{item.firstname}</span>
      </button>
    </li>
  );

  const renderUnelectedUnsignedTrainee = item => (
    <li className='unsigned-trainee'>
      <button
        onClick={() => {
          setSelectedTrainee(item);
        }}
      >
        <span className='lastname'>{item.lastname}</span>
        <span className='firstname'>{item.firstname}</span>
      </button>
    </li>
  );

  const renderUnsignedTrainees = () =>
    unsignedTrainees.map(item => {
      const cond = selectedTrainee && selectedTrainee.id === item.id;
      return (
        <Fragment key={item.id}>
          {cond && renderSelectedUnsignedTrainee(item)}
          {!cond && renderUnelectedUnsignedTrainee(item)}
        </Fragment>
      );
    });

  const renderSignedTrainees = () =>
    signedTrainees.map(item => (
      <li key={item.id} className='signed-trainee' onClick={() => setSigningAgainTraineeId(item.id)}>
        <img src={item.signature} alt='signature' />
        <div className='attendance-info'>
          <span className='lastname'>{item.lastname}</span>
          <span className='firstname'>{item.firstname}</span>
        </div>
      </li>
    ));

  const markTraineeSigned = signatureData => {
    const idx = unsignedTrainees.findIndex(trainee => trainee.id === selectedTrainee.id);
    const trainee = unsignedTrainees[idx];

    const newUnsignedTrainees = [...unsignedTrainees];
    newUnsignedTrainees.splice(idx, 1);
    setUnSignedTrainees(newUnsignedTrainees);

    const newSignedTrainees = [...signedTrainees];
    newSignedTrainees.push({ ...trainee, signature: signatureData });
    setSignedTrainees(newSignedTrainees);
  };

  const markTraineeUnsigned = traineeId => {
    const idx = signedTrainees.findIndex(trainee => trainee.id === traineeId);
    if (idx === -1) {
      return;
    }
    const { signature, ...trainee } = signedTrainees[idx];

    const newSignedTrainees = [...signedTrainees];
    newSignedTrainees.splice(idx, 1);
    setSignedTrainees(newSignedTrainees);

    const newUnsignedTrainees = [...unsignedTrainees];
    newUnsignedTrainees.push({ ...trainee });
    setUnSignedTrainees(newUnsignedTrainees);
  };

  const renderSignAgainConfirmDialog = () => (
    <Vex
      open={signingAgainTraineeId !== null}
      onCancel={() => setSigningAgainTraineeId(null)}
      onConfirm={() => {
        markTraineeUnsigned(signingAgainTraineeId);
        setSigningAgainTraineeId(null);
      }}
    >
      <Vex.Message>{t('AttendanceSingleSignPage.confirm_sign_again')}</Vex.Message>
    </Vex>
  );

  const renderSigningPad = () => (
    <>
      {!selectedTrainee && <div id='trainee-form' />}
      {selectedTrainee && (
        <SigningPad
          trainingSessionSlotId={id}
          traineeTrainingSessionId={!selectedTrainee.isInstructor ? selectedTrainee.id : null}
          trainingSessionInstructorId={selectedTrainee.isInstructor ? selectedTrainee.id : null}
          cancelFn={() => {
            setSelectedTrainee(null);
          }}
          succeedFn={signatureData => {
            markTraineeSigned(signatureData);
            setSelectedTrainee(null);
          }}
        />
      )}
    </>
  );

  return (
    <div className='page AttendanceInClassSignPage'>
      <main className={fromDigiforma ? 'main--no-header' : ''}>
        {renderBackButton()}

        <div className='section-title'>
          <div>{subsession.name}</div>
          <div>
            {t('AttendanceSingleSignPage.training_session_of')} {formatSlotDate(trainingSessionSlot)}
          </div>
        </div>

        <p className='section-title'>
          <span>{t('AttendanceOnSiteSignaturePage.choose_signatory')}</span>
        </p>

        <ul className='no-bullet'>{renderUnsignedTrainees()}</ul>

        {renderSigningPad()}

        <ul className='no-bullet'>{renderSignedTrainees()}</ul>

        {renderSignAgainConfirmDialog()}
      </main>
    </div>
  );
};

const query = gql`
  query ($id: ID!) {
    trainingSessionSlot(id: $id) {
      id
      date
      startTime
      endTime
      slot
      subsession {
        name
      }
      customers {
        customerTrainees {
          id
          trainee {
            firstname
            lastname
          }
        }
      }
      trainingSessionInstructors {
        id
        instructor {
          firstname
          lastname
        }
      }
      signatures {
        signature
        type
        customerTrainee {
          id
        }
        trainingSessionInstructor {
          id
        }
      }
    }
  }
`;

const withGraphqlData = graphql(query, {
  options: props => ({
    variables: {
      id: props.params.date,
    },
  }),
  name: 'trainingSessionSlot',
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('trainingSessionSlot')(
        AttendanceInClassSignPage, { loading: LoadingPagePlaceholder })));
