import { setLocale, clearLocale } from 'translations/i18n';
import _ from 'lodash';

const AUTH_TOKEN = 'auth_token';
const GUEST_INFO = 'guest_info';
const SWITCH_GUEST_INFOS = 'switch_guest_infos';

export const GUEST_TYPE = {
  ACADEMY: 'academy',
  INSTRUCTOR: 'instructor',
  TRAINEE: 'trainee',
  FUNDING_AGENCY: 'funding_agency',
  COMPANY: 'company',
  USER: 'user',
};

export function getToken() {
  return localStorage.getItem(`${getUserCode()}:${AUTH_TOKEN}`);
}

export function getUserCode() {
  const hostparts = window.location.hostname.split('.');
  return hostparts[0];
}

export function isLoggedIn() {
  return getToken() !== null;
}

export function shouldAcceptTOS() {
  return getGuestInfo().should_accept_tos;
}

export function setLoggedIn(guest, token, switch_guest_infos) {
  localStorage.setItem(`${getUserCode()}:${AUTH_TOKEN}`, token);
  localStorage.setItem(`${getUserCode()}:${GUEST_INFO}`, JSON.stringify(guest));
  localStorage.setItem(`${getUserCode()}:${SWITCH_GUEST_INFOS}`, JSON.stringify(switch_guest_infos));
  setLocale(guest.locale);
}

export function acceptTOS() {
  let guest = getGuestInfo();
  guest.should_accept_tos = false;
  localStorage.setItem(`${getUserCode()}:${GUEST_INFO}`, JSON.stringify(guest));
}

export function getGuestInfo() {
  return JSON.parse(localStorage.getItem(`${getUserCode()}:${GUEST_INFO}`));
}

export function getSwitchGuestInfos() {
  const storedGuestInfos = localStorage.getItem(`${getUserCode()}:${SWITCH_GUEST_INFOS}`);
  if (!storedGuestInfos) return [];
  return JSON.parse(storedGuestInfos);
}

export function getGuestId() {
  const guestInfo = getGuestInfo();
  return guestInfo && guestInfo.id;
}

export function getGuestType() {
  const guestInfo = getGuestInfo();
  return guestInfo && guestInfo.type.toLowerCase();
}

export function getGuestName() {
  const guestInfo = getGuestInfo();
  return guestInfo && guestInfo.name;
}

export function signout() {
  localStorage.removeItem(`${getUserCode()}:${AUTH_TOKEN}`);
  localStorage.removeItem(`${getUserCode()}:${GUEST_INFO}`);
  localStorage.removeItem(`${getUserCode()}:${SWITCH_GUEST_INFOS}`);
  clearSession();
}

export function guestTypeFromTypename(guestType) {
  return _.startCase(_.camelCase(guestType)).replace(/ /g, '');
}

function clearSession() {
  clearLocale();
}
