import React from 'react';
import PropTypes from 'prop-types';

import './IconedList.scss';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

export function IconedList({ icon, elements, key }) {
  return (
    <div className='IconedList'>
      {elements?.map((element, i) => (
        <div className='IconedList__element' key={`${key ?? 'IconedListElement'}-${i}`}>
          <SvgIcon name={icon} size='md' />
          <p>{element.text}</p>
        </div>
      ))}
    </div>
  );
}

IconedList.propTypes = {
  icon: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  key: PropTypes.string,
};
