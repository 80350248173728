import React from 'react';
import styles from '../../../scss/video_conference/PreCallPage.module.scss';
import usePreCallState from 'hooks/video_conference/usePreCall';
import { MIC_STEP, PRE_CALL_STEP, SET_UP_INITIAL_STEP, SET_UP_WIZARD_STEP } from 'constants/video_conference';
import PreCallStep from './Steps/PreCallStep';
import SetUpWizardStep from './Steps/SetUpWizardStep';

function PreCallPage() {
  const { setNamedStep, currentStepName } = usePreCallState();

  const handleOpenWizard = () => setNamedStep(SET_UP_WIZARD_STEP);

  const renderStep = () => {
    switch (currentStepName) {
      case PRE_CALL_STEP:
      case SET_UP_INITIAL_STEP:
        return <PreCallStep shouldGoToTest={currentStepName === SET_UP_INITIAL_STEP} onTestClick={handleOpenWizard} />;
      case SET_UP_WIZARD_STEP:
        setNamedStep(MIC_STEP);
        return <SetUpWizardStep />;
      default:
        return <SetUpWizardStep />;
    }
  };

  return <div className={styles.preCallPage}>{renderStep()}</div>;
}

export default PreCallPage;
