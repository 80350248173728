import { gql } from '@apollo/client';
import { COMMENT_FRAGMENT } from 'controllers/comment_controller';

export const VIDEO_CALL_CONFIG_QUERY = gql`
  query {
    videoCallConfig {
      problemIndicatorVideo
      problemIndicatorNetwork
      audioDeviceId
      videoDeviceId
      outputDeviceId
      displayMode
      backgroundImageUrl
    }
  }
`;

export const COMMENTS_QUERY = gql`
  query dailycoroom($id: ID!, $page: Int!, $size: Int!) {
    dailycoroom(id: $id) {
      id
      comments(pagination: { page: $page, size: $size }) @connection(key: "comments") {
        ...comment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const TRAINEES_ACADEMY_ID_QUERY = gql`
  query ($id: ID!) {
    trainee(id: $id) {
      user_id
    }
  }
`;

export const INSTRUCTORS_ACADEMY_ID_QUERY = gql`
  query ($id: ID!) {
    instructor(id: $id) {
      user_id
    }
  }
`;

export const TRAINEES_QUERY = gql`
  query ($id: ID!) {
    trainingSession(id: $id) {
      academy {
        id
        academy_extranet_avatar {
          id
          url
        }
      }
      trainees {
        id
        bio
        logo {
          id
          url
        }
      }
      instructors {
        id
        bio
        logo {
          id
          url
        }
      }
    }
  }
`;

export const TRAINING_SESSION_QUERY = gql`
  query training_session_page($id: ID!) {
    training_session(id: $id) {
      name
      custom_name
      dates {
        start_time
        end_time
        date
        slot
      }
      modules {
        id
        name
      }
    }
  }
`;

export const FILESTACK_SECURITY_QUERY = gql`
  query filestackSecurity($handle: String!, $image_type: String!) {
    filestackSecurity(handle: $handle, image_type: $image_type) {
      policy
      signature
    }
  }
`;
