import useParticipants from 'contexts/video_conference/ParticipantsContext';
import { useTranslation } from 'react-i18next';

const useStatusCaption = () => {
  const { t } = useTranslation();
  const participants = useParticipants();

  let featuredOverlayCaption;

  if (participants.length === 1) {
    featuredOverlayCaption = t('CallBox.waiting_for_others');
  }

  return featuredOverlayCaption;
};

export default useStatusCaption;
