import useDebugWindow from 'hooks/useDebugWindow';
import { useInCallQualityMeasuringState } from 'hooks/useInCallQualityMeasuring';
import DebugWindow from '../DebugWindow';
import React from 'react';

function QualityMeasuringWindow() {
  const { isDebugWindowOpen, toggleDebugWindow } = useDebugWindow();

  const debugProps = useInCallQualityMeasuringState();

  return isDebugWindowOpen ? <DebugWindow toggleDebugWindow={toggleDebugWindow} debugProps={debugProps} /> : null;
}

export default QualityMeasuringWindow;
