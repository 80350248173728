import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import TrainingSessionHeader from '../training_session/TrainingSessionHeader';
import Trainee from './Trainee';
import { withData, withRouteParams } from '../../support/page';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb';

import 'scss/TrainingSessionTraineesPage.scss';

const Trainees = ({ trainees }) => (
  <div className='trainees'>
    {trainees.map(trainee => (
      <Trainee key={trainee.id} {...trainee} />
    ))}
  </div>
);

const TrainingSessionTraineesPage = ({ training_session }) => {
  const { t } = useTranslation();
  const trainingSessionName = training_session.custom_name || training_session.name;

  return (
    <div className='page TrainingSessionTraineesPage'>
      <TrainingSessionHeader training_session_id={training_session.id} selected='trainees' />
      <main>
        <Breadcrumb
          links={[{ name: trainingSessionName, url: `/ts/${training_session.id}` }, { name: t('TrainingSessionHeader.trainees') }]}
        />
        <section>
          <h1>{trainingSessionName}</h1>
          <h3>{t('TrainingSessionTraineesPage.participants_list')}</h3>

          <Trainees trainees={training_session.trainees} />
        </section>
      </main>
    </div>
  );
};

const query = gql`
  query training_session_trainees_page($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      show_program_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      has_numeric_signing
      start_date
      modules {
        id
        name
        visible
      }
      program {
        id
      }
      trainees {
        id
        firstname
        lastname
        logo {
          url
        }
        company {
          name
        }
      }
    }
  }
`;

const withGraphqlData = graphql(query, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: parseInt(props.params.training_session_id, 10) },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        TrainingSessionTraineesPage, { loading: LoadingPagePlaceholder })));
