import _ from 'lodash';

export function broadcastMessage(callObject, { messageType, payload, targetRoles }) {
  if (typeof payload !== 'object' || !messageType) {
    throw new Error('broadcastMessage: messageType and payload must be given');
  }

  const data = { messageType, payload };

  if (!targetRoles || targetRoles.length === 0) {
    callObject.sendAppMessage(data, '*');
  } else {
    targetRoles.forEach(role => {
      const roleIds = _(callObject.participants())
        .values()
        .filter(participant => participant.user_id.startsWith(role))
        .map(participant => participant.session_id)
        .value();

      roleIds.forEach(roleId => {
        callObject.sendAppMessage(data, roleId);
      });
    });
  }
}

export function listenMessage(callObject, messageType, callback) {
  if (!callObject) return;
  callObject.on('app-message', ({ fromId, data: { messageType: receivedMessageType, payload } }) => {
    if (messageType === receivedMessageType) {
      callback({ fromId, payload });
    }
  });
}

window.broadcastMessage = broadcastMessage;
window.listenMessage = listenMessage;
