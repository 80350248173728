import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import './IconButton.scss';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

export const IconButton = forwardRef((props, ref) => {
  const { as, href, children, icon, className: extraClassName, variant, size, ...elementProps } = props;
  const Element = as === 'a' ? Link : 'button';
  const className = clsx('IconButton', `IconButton--${variant}`, { 'IconButton--with-text': children !== undefined }, extraClassName);

  return (
    <Element className={className} to={href} {...elementProps} ref={ref}>
      <SvgIcon name={icon} size={size} />
      {children && <span className='IconButton__text'>{children}</span>}
    </Element>
  );
});
IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  as: PropTypes.oneOf(['button', 'a']),
  icon: PropTypes.any.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'transparent']).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
  children: PropTypes.node,
};

IconButton.defaultProps = {
  as: 'button',
  variant: 'transparent',
  size: 'xl',
};
