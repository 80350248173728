import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import IconButton from './IconButton';

const ShortcutButton = props => {
  const { path, ...rest } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === path;

  return <IconButton isActive={isActive} clickFn={() => navigate(path)} {...rest} />;
};

export default ShortcutButton;
