import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import EntryButton from './EntryButton';
import ButtonLabel from './ButtonLabel';

export const EntryLinkButton = props => {
  const { path, content: Content, ...rest } = props;
  const { label } = rest;
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === path;

  return (
    <EntryButton isActive={isActive} clickFn={() => navigate(path)} {...rest}>
      {(Content && <Content />) || (label && <ButtonLabel label={label} />) || null}
    </EntryButton>
  );
};

export default EntryLinkButton;
