import React from 'react';
import styles from '../../../scss/video_conference/DebugWindow.module.scss';
import PropTypes from 'prop-types';

function DebugWindow({ toggleDebugWindow, debugProps }) {
  return (
    <div className={styles.window}>
      <button className={styles.closeButton} onClick={toggleDebugWindow}>
        <i className='icon-close2' />
      </button>
      <h3 className={styles.title}>QUALITY DEBUG WINDOW</h3>
      <ul>
        {debugProps?.map(el => (
          <li>{el}</li>
        ))}
      </ul>
    </div>
  );
}

export default DebugWindow;

DebugWindow.propTypes = {
  toggleDebugWindow: PropTypes.func.isRequired,
  debugProps: PropTypes.arrayOf(PropTypes.string).isRequired,
};
