import { useState } from 'react';
import _ from 'lodash';

function useSearch(searchableItems) {
  const [searchTerm, setSearchTerm] = useState('');
  let filteredData = searchableItems;

  if (searchTerm !== '') {
    filteredData = searchableItems.filter(item => {
      let searchableItem = item;
      if (_.isObject(item)) {
        searchableItem = Object.values(item).join(' ').toLowerCase();
      }
      return searchableItem.search(searchTerm) !== -1;
    });
  }

  const handleSearch = e => {
    e.persist();
    _.debounce(() => setSearchTerm(e.target.value?.toLowerCase()), 300)();
  };

  return {
    handleSearch,
    filteredData,
  };
}

export default useSearch;
