import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  videoRatingData: {
    videoQuality: undefined,
    expectedFramerate: undefined,
    actualFramerate: undefined,
  },
  audioQuality: undefined,
  camMarkedUnavailable: false,
  micMarkedUnavailable: false,
};

function storeUserDeviceRatings(userDeviceRatings) {
  localStorage.setItem('userDeviceRatings', JSON.stringify(userDeviceRatings));
}

function useUserDeviceRatingsState() {
  const initialState = JSON.parse(localStorage.getItem('userDeviceRatings')) || defaultState;

  const [state, setState] = useState(initialState);

  useEffect(() => {
    storeUserDeviceRatings(state);
  }, [state]);

  return [state, setState];
}

const UserDeviceRatingsContext = createContext();

export const UserDeviceRatingsProvider = ({ children }) => {
  const [userDeviceRatings, setState] = useUserDeviceRatingsState();

  const registerVideoRating = useCallback(
    ({ videoRatingData, camMarkedUnavailable }) => {
      setState(oldState => ({
        ...oldState,
        videoRatingData: videoRatingData || oldState.videoRatingData,
        camMarkedUnavailable: camMarkedUnavailable !== undefined ? camMarkedUnavailable : oldState.camMarkedUnavailable,
      }));
    },
    [setState]
  );

  const registerAudioRating = useCallback(
    ({ audioQuality, micMarkedUnavailable }) => {
      setState(oldState => ({
        ...oldState,
        audioQuality: audioQuality || oldState.audioQuality,
        micMarkedUnavailable: micMarkedUnavailable !== undefined ? micMarkedUnavailable : oldState.micMarkedUnavailable,
      }));
    },
    [setState]
  );

  return (
    <UserDeviceRatingsContext.Provider value={{ userDeviceRatings, registerVideoRating, registerAudioRating }}>
      {children}
    </UserDeviceRatingsContext.Provider>
  );
};

function useUserDeviceRatings() {
  const userDeviceRatingsState = useContext(UserDeviceRatingsContext);

  if (userDeviceRatingsState === undefined) {
    throw new Error('useUserDeviceRatings must be used within a UserDeviceRatingsProvider');
  }

  return userDeviceRatingsState;
}

UserDeviceRatingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default useUserDeviceRatings;
