import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withAuth } from 'contexts/AuthContext';
import { withData } from 'support/page';

import { TrainingSessionSelect, TrainingSessionSelectLoader } from './TrainingSessionSelect';

const INSTRUCTOR_TRAINING_SESSIONS = gql`
  query trainingSessions($id: ID!) {
    instructor(id: $id) {
      training_sessions {
        id
        name
      }
    }
  }
`;

const withGraphQlData = graphql(INSTRUCTOR_TRAINING_SESSIONS, {
  name: 'user',
  options: props => {
    return {
      variables: {
        id: props?.auth?.guestInfo?.id,
      },
    };
  },
});

// prettier-ignore
export const InstructorTrainingSessionSelect =
  withAuth(
    withGraphQlData(
      withData({request: "user", data: 'instructor'})(
        TrainingSessionSelect, { loading: TrainingSessionSelectLoader })));
