import { LEFT_MEETING, MEASURING_IN_CALL, MEASURING_PRE_CALL } from 'constants/video_conference';
import { useCallback, useEffect, useRef, useState } from 'react';
import useRtcStatsPersistance from '../useRtcStatsPersistance';
import { useDailyEvent } from '@daily-co/daily-react';

function useRtcStatsWatcher({
  callObject,
  dispatchPerfResults,
  interval = 1500,
  measuringMode,
  camMarkedUnavailable,
  startPreCallTesting,
}) {
  if ([MEASURING_PRE_CALL, MEASURING_IN_CALL].indexOf(measuringMode) === -1) {
    throw new Error('Use MEASURING_PRE_CALL or MEASURING_IN_CALL');
  }

  const [timedOut, setTimedOut] = useState(false);

  // Available keys provided by daily.co include: stats, threshold, quality
  const handleUpdateDailyStats = useCallback(
    ({ stats }) => {
      dispatchPerfResults({ dailyStats: stats, measuringMode, camMarkedUnavailable, timedOut });
    },
    [camMarkedUnavailable, dispatchPerfResults, measuringMode, timedOut]
  );

  const persistDailyStats = useRtcStatsPersistance({ measuringMode, statsReadInterval: interval });

  const networkStatsInterval = useRef(null);

  const handleLeftMeeting = () => {
    clearInterval(networkStatsInterval.current);
  };

  useEffect(() => {
    if (measuringMode === MEASURING_IN_CALL || (measuringMode === MEASURING_PRE_CALL && startPreCallTesting)) {
      if (process.env.NODE_ENV === 'development') {
        window.callObject = callObject;
      }

      networkStatsInterval.current = setInterval(() => {
        const networkStats = callObject.getNetworkStats();
        // BUG: Daily.co sometimes returns this as a non - promise object:
        // { stats: { latest: { } } }
        if (networkStats.then) {
          return networkStats.then(({ stats, threshold, quality }) => {
            if (measuringMode === MEASURING_PRE_CALL) {
              window.parent.postMessage({
                type: 'test-in-progress',
                message: 'BANDWIDTH_TESTING',
              });
            }
            persistDailyStats(stats.latest);
            handleUpdateDailyStats({ stats, threshold, quality });
          });
        }

        return null;
      }, interval);

      return () => {
        handleLeftMeeting();
      };
    }
  }, [callObject, interval, handleUpdateDailyStats, persistDailyStats, startPreCallTesting, measuringMode]);

  useDailyEvent(LEFT_MEETING, handleLeftMeeting);

  useEffect(() => {
    if (measuringMode === MEASURING_PRE_CALL && startPreCallTesting) {
      let timeout;
      timeout = setTimeout(() => {
        setTimedOut(true);
      }, 10000);

      return () => {
        clearTimeout(timeout);
        setTimedOut(false);
      };
    }
  }, [startPreCallTesting, measuringMode]);
}

export default useRtcStatsWatcher;
