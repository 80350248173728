import Pusher from 'pusher-js';
import { getUserCode, getGuestId, getGuestType } from './auth';
import { getConfig } from '../config';

const PUSHER_KEY = getConfig().REACT_APP_PUSHER_KEY;
let pusher = null;
const guestChannels = {};

export function guestSubscribe(channelId, eventName, callback) {
  createPusher();

  const channel = guestChannels[channelId] || pusher.subscribe(`presence-${getConfig().env}_${channelId}`);
  guestChannels[channelId] = channel;
  channel.bind(eventName, data => {
    callback(data);
  });
  return channel;
}

export function guestOnPresence(channelId, callback) {
  const channel = guestChannels[channelId];
  channel.__to_be_sent = [];

  const onChanged = function () {
    for (const toBeSent of channel.__to_be_sent) {
      channel.trigger(toBeSent.eventName, toBeSent.data);
    }
    callback(cleanMembers(channel.members));
  };

  guestSubscribe(channelId, 'pusher:member_removed', onChanged);
  guestSubscribe(channelId, 'pusher:subscription_succeeded', onChanged);

  callback(cleanMembers(channel.members));

  return channel;
}

export function unSubscribe(channelId, eventName) {
  const channel = guestChannels[channelId];
  if (channel) {
    channel.unbind(eventName);
    channel.unbind('pusher:member_removed');
    channel.unbind('pusher:subscription_succeeded');
  }
}

export function notify(channelId, eventName, data0) {
  createPusher();

  const data = JSON.stringify(data0);

  const channel = guestChannels[channelId];
  guestChannels[channelId] = channel;
  if (channel.subscribed) {
    channel.trigger(eventName, data);
  } else {
    channel.__to_be_sent.push({ eventName, data });
  }
  return channel;
}

function cleanMembers(members) {
  const result = [];
  members.each(member => {
    result.push({ id: member.id, name: member.info.name });
  });
  return result;
}

function createPusher() {
  if (pusher === null) {
    pusher = new Pusher(PUSHER_KEY, {
      authEndpoint: `${getConfig().serverRoot}/webhooks/guest/${getUserCode()}/authenticate_pusher2/${getGuestType()}/${getGuestId()}`,
      cluster: 'eu',
      encrypted: true,
    });
  }
}
