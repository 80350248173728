// ts-check

import React, { useEffect, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Vex from '../vex';
import { withData, withRouteParams } from 'support/page';
import { formatSlotDate } from 'support/date';
import SigningPad from './SigningPad';
import { ActionButton, UrlButton } from 'components/mainComponents/buttons';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb';
import { flowRight as compose } from 'lodash';

import 'scss/AttendancePage.scss';

/**
 * Single signing page use for remote Trainee signing
 * OR for Instructor (in case of remote signing)
 */
const AttendanceSingleSignPage = ({ signatureForActorAtDate, training_session }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { training_session_id: id } = useParams();
  const [isSigned, setIsSigned] = useState(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { signature, date, customerTrainee, trainingSessionInstructor } = signatureForActorAtDate;
  const trainingSessionName = training_session.custom_name || training_session.name;

  useEffect(() => {
    setIsSigned(!!signature);
  }, [signature]);

  if (isSigned) {
    return (
      <div className='page AttendanceSingleSignPage'>
        <main>
          <Breadcrumb
            links={[
              { name: trainingSessionName, url: `/ts/${id}` },
              { name: t('TrainingSessionAttendancePage.digital_signoff'), url: `/ts/${id}/attendances` },
              { name: t('Breadcrumb.attendanceSingleSign') },
            ]}
          />

          <div className='section-title'>
            <div>{date.subsession.name}</div>
            <div>{`${t('AttendanceSingleSignPage.training_session_of')} ${formatSlotDate(date)}`}</div>
          </div>

          <h2 style={{ textAlign: 'center' }}>{t('AttendanceSingleSignPage.thank_you_your_signature_is_well_saved')}</h2>

          <div className='back-button'>
            <UrlButton icon='arrow--left' label={t('AttendanceSingleSignPage.back_to_extranet_session')} url={`/ts/${id}`} />
            <ActionButton
              icon='warning--triangle'
              label={t('AttendanceSingleSignPage.sign_again')}
              clickFn={() => setShowConfirmDialog(true)}
              type='secondary'
            />
          </div>

          <Vex
            open={showConfirmDialog}
            onCancel={() => setShowConfirmDialog(false)}
            onConfirm={() => {
              setIsSigned(false);
              setShowConfirmDialog(false);
            }}
          >
            <Vex.Message>{t('AttendanceSingleSignPage.confirm_sign_again')}</Vex.Message>
          </Vex>
        </main>
      </div>
    );
  }

  return (
    <div className='page AttendanceSingleSignPage'>
      <main>
        <Breadcrumb
          links={[
            { name: trainingSessionName, url: `/ts/${id}` },
            { name: t('TrainingSessionAttendancePage.digital_signoff'), url: `/ts/${id}/attendances` },
            { name: t('Breadcrumb.attendanceSingleSign') },
          ]}
        />
        <div className='section-title'>
          <div>
            <div>{date.subsession.name}</div>
            <div>{`${t('AttendanceSingleSignPage.training_session_of')} ${formatSlotDate(date)}`}</div>
          </div>
          <div>{t('AttendanceSingleSignPage.sign_below')}</div>
        </div>

        <SigningPad
          trainingSessionSlotId={date.id}
          trainingSessionInstructorId={trainingSessionInstructor && trainingSessionInstructor.id}
          traineeTrainingSessionId={customerTrainee && customerTrainee.id}
          cancelFn={() => navigate(-1)}
          succeedFn={() => {
            setIsSigned(true);
          }}
        />
      </main>
    </div>
  );
};

const signatureQuery = gql`
  query signatureForActorAtDate($trainingSessionSlotId: ID!, $trainingSessionInstructorId: ID, $traineeTrainingSessionId: ID) {
    signatureForActorAtDate(
      trainingSessionSlotId: $trainingSessionSlotId
      trainingSessionInstructorId: $trainingSessionInstructorId
      traineeTrainingSessionId: $traineeTrainingSessionId
    ) {
      signature {
        signature
      }
      date {
        id
        date
        slot
        startTime
        endTime
        subsession {
          name
        }
      }
      trainingSessionInstructor {
        instructor {
          firstname
          lastname
        }
      }
      customerTrainee {
        trainee {
          firstname
          lastname
        }
      }
    }
  }
`;
const trainingSessionNameQuery = gql`
  query training_session($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
    }
  }
`;

const withGraphqlData = compose(
  graphql(signatureQuery, {
    options: props => ({
      variables: {
        trainingSessionSlotId: props.params.date,
        trainingSessionInstructorId: props.params.instructor,
        traieeTrainingSessionId: props.params.trainee,
      },
    }),
    name: 'signatureForActorAtDate',
  }),
  graphql(trainingSessionNameQuery, {
    options: props => ({
      variables: {
        id: props.params.training_session_id,
      },
    }),
    name: 'training_session',
  })
);

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('signatureForActorAtDate', 'training_session')(
        AttendanceSingleSignPage, { loading: LoadingPagePlaceholder })));
