import { gql } from '@apollo/client';
import { COMMENT_FRAGMENT } from 'controllers/comment_controller.js';

export const TRAINING_SESSION_QUERY = gql`
  query module_page_training_session($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      show_program_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      show_dates_in_extranet
      has_numeric_signing
      start_date
      program {
        id
      }
      modules {
        id
        name
        visible
        visibilityMode
        visibilityStartDate
        visibilityEndDate
        virtualroom
      }
    }
  }
`;

export const MODULE_QUERY = gql`
  query module_page($id: ID!) {
    module(id: $id) {
      id
      name
      forum
      virtualroom
      description
      visible
      visibilityMode
      visibilityStartDate
      visibilityEndDate
      show_score
      cursor {
        module_sequence_item_id
      }
      image {
        id
        url
      }
      documents {
        id
        filename
        url
        mime
        wistia {
          hashed_id
        }
        downloadable
      }
      scorms {
        id
        name
        url
      }
      email_markers {
        id
        email {
          title
          content
        }
      }
      upload_markers {
        id
        name
        description
        submitted_upload {
          id
          score
          url
          filename
          mimetype
          comment
        }
      }
      embeds {
        id
        name
        url
        embedly
      }
      html_docs {
        id
      }
      livestreams {
        id
        url
        start
        use_virtualroom
      }
      sequence {
        id
        resource_type
        resource_id
        finished
        scorable
        score
        available
        name
        description
        duration_min
        precondition
        precondition_score
        precondition_date
      }
      pending_evaluations {
        id
        trainee_id
        pending
        answer_evaluation_result {
          score
          total
        }
        evaluation {
          id
          name
          type
          replayable
        }
      }
      comments {
        ...comment
      }
    }
  }

  ${COMMENT_FRAGMENT}
`;

export const SET_TRAINEE_CURSOR_MUTATION = gql`
  mutation set_module_trainee_cursor($trainee_id: ID!, $module_id: ID!, $module_sequence_item_id: ID!) {
    set_module_trainee_cursor(trainee_id: $trainee_id, module_id: $module_id, module_sequence_item_id: $module_sequence_item_id)
  }
`;
