import { useEffect } from 'react';
import _ from 'lodash';
import { broadcastMessage } from 'helpers/video_conference';
import { JOINED_MEETING, MESSAGE_PROBLEM_INDICATORS } from 'constants/video_conference';
import { useDaily, useMeetingState } from '@daily-co/daily-react';

function useParticipantNotification(problemIndicators) {
  const callObject = useDaily();
  const meetingState = useMeetingState();

  useEffect(() => {
    // eslint-disable-line react-hooks/rules-of-hooks
    if (_.every(problemIndicators, value => value !== undefined) && meetingState === JOINED_MEETING) {
      // Broadcast message via daily.co api to all instructors about your status
      broadcastMessage(callObject, {
        messageType: MESSAGE_PROBLEM_INDICATORS,
        payload: {
          problemIndicators,
        },
        targetRoles: ['instructor', 'user', 'trainee'],
      });
    }
  }, [callObject, problemIndicators, meetingState]);
}

export default useParticipantNotification;
