import { useDebugValue, useEffect, useState } from 'react';

function useMedia(query, initialState = false) {
  const [matches, setMatches] = useState(initialState);
  useDebugValue(matches, value => `\`${query}\` => ${value}`);

  useEffect(() => {
    let mounted = true;

    const mql = window.matchMedia(query);
    function handleChange(event) {
      if (mounted) {
        setMatches(event.matches);
      }
    }

    mql.addEventListener('change', handleChange);
    setMatches(mql.matches);

    return () => {
      mounted = false;
      mql.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
}

export default useMedia;
