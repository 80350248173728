import React, { useEffect } from 'react';

import '../../../scss/ImagePlayer.scss';

function ImagePlayer({ url, onRead }) {
  useEffect(() => {
    onRead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='ImagePlayer'>
      <img src={url} alt='logo' />
    </div>
  );
}

export default ImagePlayer;
