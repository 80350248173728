import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownLabel, DropdownSeparator } from 'components/mainComponents/Dropdown';
import { Icon } from 'components/mainComponents/Icon';
import { useGuestMenuEntriesV2 } from 'support/guest';
import { useNavigate } from 'react-router-dom';
import { UserButton } from 'components/mainComponents/UserButton';
import { useTranslation } from 'react-i18next';
import './UserDropdownMenu.scss';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

export function UserDropdownMenu({ fullName, type }) {
  const { t } = useTranslation();
  const entries = useGuestMenuEntriesV2();
  const navigate = useNavigate();

  return (
    <Dropdown trigger={<UserButton fullName={fullName} />}>
      <DropdownLabel className='UserDropdownMenu__userName'>{fullName}</DropdownLabel>
      <DropdownLabel className='UserDropdownMenu__accessType'>{t('Header.access', { type: `${t(type)}` })}</DropdownLabel>
      <DropdownSeparator />
      {entries.length > 0 &&
        entries.map((link, idx) => {
          return (
            <DropdownItem className='UserDropdownMenu__item' key={idx} onClick={() => navigate(link.path)}>
              <Icon icon={link.icon} className='UserDropdownMenu__icon' background='none' />
              <span className='UserDropdownMenu__item-label'>{link.label}</span>
            </DropdownItem>
          );
        })}

      <DropdownItem className='UserDropdownMenu__item' onClick={() => navigate(`/logout`)}>
        <SvgIcon name='logout' className='UserDropdownMenu__icon' />
        <span className='UserDropdownMenu__item-label'>{t('Header.logout')}</span>
      </DropdownItem>
    </Dropdown>
  );
}

UserDropdownMenu.propTypes = {
  fullName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
