import { useRef } from 'react';
import _ from 'lodash';
import useAudio from 'hooks/video_conference/useAudio';
import { useDailyEvent } from '@daily-co/daily-react';
import { PARTICIPANT_JOINED } from 'constants/video_conference';

const useNotificationSoundOnRoomEnter = () => {
  const [playNotificationSound] = useAudio('/sounds/joined-meeting-sound.wav');

  const throttledPlaySound = useRef(_.throttle(playNotificationSound, 1000), [playNotificationSound]).current;

  useDailyEvent(PARTICIPANT_JOINED, throttledPlaySound);
};

export default useNotificationSoundOnRoomEnter;
