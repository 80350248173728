import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Textarea from 'react-textarea-autosize';

import '../../../scss/form_inputs/FormInputFree.scss';

const FormInputFree = props => {
  const [answer, setAnswer] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setAnswer(props.answerSent || '');
  }, [props.answerSent]);

  const onAnswer = e => {
    setAnswer(e.target.value);
    props.onAnswer(props.input.id, e.target.value);
  };

  const data = JSON.parse(props.input.data);

  return (
    <div className={`FormInputFree FormInput ${props.isLive ? 'live' : ''}`}>
      <p className={`question ${data.question.length < 80 ? 'short-question' : ''}`}>{data.question}</p>

      <div className='answers'>
        <Textarea value={answer} onChange={onAnswer} minRows={3} placeholder={t('AnswerEvaluation.Form.answer')} />
      </div>
    </div>
  );
};

export default FormInputFree;
