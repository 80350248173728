import { ActionButton } from 'components/mainComponents/buttons';
import PreCallResultButtons from 'components/video_conference/PreCallPage/PreCallResultButtons';
import { MIC_STEP_MODAL, PERMISSIONS_MODAL } from 'constants/video_conference';
import { useModalDispatch } from 'hooks/video_conference/useModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function MicStepResults({
  className,
  buttonClassName,
  permissionsDenied,
  micUnavailable,
  handleContinueWithSuccess,
  isRecordingFinished,
  handleContinue,
}) {
  const { t } = useTranslation();

  const { openModal } = useModalDispatch();

  const handleOpenModal = () => openModal(MIC_STEP_MODAL);
  const handleOpenPermissionsModal = () => openModal(PERMISSIONS_MODAL);
  const handleRefresh = () => window.location.reload(false);

  if (permissionsDenied) {
    return (
      <PreCallResultButtons
        onAcceptClick={handleRefresh}
        onHelpClick={handleOpenPermissionsModal}
        acceptLabel={t('MicStep.permissions_denied.button.accept')}
        helpLabel={t('MicStep.permissions_denied.button.help')}
        classes={className}
      />
    );
  }

  if (micUnavailable) {
    return (
      <ActionButton
        icon='question--circle'
        clickFn={handleOpenModal}
        label={t('MicStep.button.knowledge')}
        className={buttonClassName}
      />
    );
  }

  if (isRecordingFinished) {
    return <PreCallResultButtons onAcceptClick={handleContinueWithSuccess} onHelpClick={handleOpenModal} classes={className} />;
  }

  return (
    <div className={buttonClassName}>
      <ActionButton
        icon='arrow--right'
        clickFn={isRecordingFinished ? handleContinue : undefined}
        label={t('MicStep.button.continuer')}
      />
    </div>
  );
}

export default MicStepResults;

MicStepResults.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  permissionsDenied: PropTypes.bool,
  micUnavailable: PropTypes.bool,
  handleContinueWithSuccess: PropTypes.func.isRequired,
  isRecordingFinished: PropTypes.bool,
  handleContinue: PropTypes.func.isRequired,
};

MicStepResults.defaultProps = {
  className: '',
  buttonClassName: '',
  permissionsDenied: false,
  micUnavailable: false,
  isRecordingFinished: false,
};
