import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import './Heading.scss';

export function Heading({ as, className: additionalClassName, level, ...htmlAttributes }) {
  const Component = as ?? level ? `h${level}` : 'h2';
  const levelClassName = `Heading--level-${level}`;
  const className = clsx('Heading', levelClassName, additionalClassName);

  return <Component {...htmlAttributes} className={className} />;
}

Heading.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  level: PropTypes.oneOf([1, 2, 3, 4, 5]),
};

Heading.defaultProps = {
  level: 1,
};
