import React from 'react';
import { clsx } from 'clsx';
import { Drawer } from 'components/mainComponents/Drawer';
import { SidebarNavigationContent } from 'components/mainComponents/SidebarNavigationContent';
import useDisclosure from 'hooks/useDisclosure';
import { IconButton } from 'components/mainComponents/IconButton';
import AccountInfo from 'components/mainComponents/HeaderBar/AccountInfo';
import './SidebarNavigation.scss';

export function SidebarNavigation({ className: additionalClassName, trainingSessionId = null }) {
  const className = clsx('SidebarNavigationTrigger', additionalClassName);
  const { open, close, isOpen } = useDisclosure();
  return (
    <>
      <IconButton icon='burger--menu' onClick={open} className={className} />
      <Drawer isOpen={isOpen} onClose={close}>
        <div className='SidebarNavigationTrigger__SidebarNavigation'>
          <div className='SidebarNavigationTrigger__SidebarNavigationHeader'>
            <IconButton icon='burger--menu' onClick={close} />
            <AccountInfo />
          </div>
          <div className='SidebarNavigationTrigger__SidebarNavigationContent'>
            <SidebarNavigationContent trainingSessionId={trainingSessionId} />
          </div>
        </div>
      </Drawer>
    </>
  );
}
