import React, { createContext, useContext, useState, useMemo } from 'react';

import ThemedBlock from './ThemedBlock';

import { computeDerivedFromColorContrastRatio } from './algos';

export const ThemeContext = createContext({});

const ThemeContextProvider = ({ children, algo = computeDerivedFromColorContrastRatio }) => {
  const [customColor, setCustomColor] = useState('#ffffff');
  const [textColor, derivedColorSet] = useMemo(() => algo(customColor), [customColor, algo]);
  const [customColor80, customColor60, customColor40, customColor20, customColor10] = derivedColorSet;
  const themeBlockColors = {
    textColor,
    customColor,
    customColor10,
    customColor20,
    customColor40,
    customColor60,
    customColor80,
  };

  return (
    <ThemeContext.Provider value={{ setCustomColor, themeBlockColors }}>
      <ThemedBlock colors={themeBlockColors}>{children}</ThemedBlock>
    </ThemeContext.Provider>
  );
};

export const useThemeColorSetter = () => {
  const { setCustomColor } = useContext(ThemeContext);
  return setCustomColor;
};

export default ThemeContextProvider;
