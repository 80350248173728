import { useCallback, useMemo, useState } from 'react';

function useStep(maxStep) {
  const [currentStep, setCurrentStep] = useState(1);

  const canGoToNextStep = useMemo(() => currentStep + 1 <= maxStep, [currentStep, maxStep]);

  const canGoToPrevStep = useMemo(() => currentStep - 1 >= 1, [currentStep]);

  const goToNextStep = useCallback(() => {
    if (canGoToNextStep) {
      setCurrentStep(step => step + 1);
    }
  }, [canGoToNextStep]);

  const goToPrevStep = useCallback(() => {
    if (canGoToPrevStep) {
      setCurrentStep(step => step - 1);
    }
  }, [canGoToPrevStep]);

  const reset = useCallback(() => {
    setCurrentStep(1);
  }, []);

  return {
    currentStep,
    goToNextStep,
    goToPrevStep,
    canGoToNextStep,
    canGoToPrevStep,
    setCurrentStep,
    reset,
  };
}

export default useStep;
