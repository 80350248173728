import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './CommentThreadDisplay.scss';
import { Dropdown, DropdownItem } from 'components/mainComponents/Dropdown';
import { IconButton } from 'components/mainComponents/IconButton';
import { Input } from 'components/mainComponents/Input';
import { Paper } from 'components/mainComponents/Paper';
import { CommentPropType } from 'propTypes';
import { localDateTimeFormat, relativeTime } from 'support/date';

export function CommentThreadDisplay(props) {
  const { t } = useTranslation();
  const [replyText, setReplyText] = useState('');

  return (
    <Paper className='CommentThreadDisplay'>
      <Message {...props.comment} canUseActions={props.canUseActions} onDelete={() => props.onDelete(props.comment)} />
      <div className='CommentThreadDisplay__answers-list'>
        <form className='CommentThreadDisplay__answer-form' onSubmit={reply}>
          <Input
            value={replyText}
            onChange={event => setReplyText(event.target.value)}
            placeholder={t('CommentThread.new_answer_input_hint')}
          />
          <IconButton icon='paper-plane--alt' disabled={replyText.trim().length === 0} variant='primary' />
        </form>
        {props.comment.subcomments.map(answer => (
          <Message
            {...answer}
            canUseActions={props.canUseActions}
            onDelete={() => props.onDelete(answer, props.comment.id)}
            key={answer.id}
          />
        ))}
      </div>
    </Paper>
  );

  function reply(event) {
    event.preventDefault();

    if (replyText.trim().length > 0) {
      props.onReply(replyText);
      setReplyText('');
    }
  }
}

CommentThreadDisplay.propTypes = {
  onReply: PropTypes.func,
  onDelete: PropTypes.func,
  canUseActions: PropTypes.bool,
  comment: CommentPropType,
};

CommentThreadDisplay.defaultProps = {
  canUseActions: false,
};

function Message(props) {
  const { t } = useTranslation();

  return (
    <div className='CommentThreadDisplay__message'>
      <div className='CommentThreadDisplay__message-info'>
        <p className='CommentThreadDisplay__message-author'>{props.author}</p>
        <p className='CommentThreadDisplay__message-time' title={localDateTimeFormat(props.inserted_at, 'long', true)}>
          {relativeTime(props.inserted_at, true)}
        </p>
        {props.canUseActions && (
          <Dropdown
            trigger={
              <IconButton
                icon='three-dots--horizontal'
                size='md'
                className='CommentThreadDisplay__message-actions'
                aria-label={t('CommentThread.message_actions_label')}
              />
            }
          >
            <DropdownItem onClick={props.onDelete}>{t('CommentThread.delete_message')}</DropdownItem>
          </Dropdown>
        )}
      </div>
      <p>{props.text}</p>
    </div>
  );
}
