import { graphql } from '@apollo/client/react/hoc';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getConfig } from 'config';
import { withData, withRouteParams } from 'support/page';
import { getLocale } from 'translations/i18n';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogContent,
  AlertDialogTitle,
} from 'components/mainComponents/AlertDialog';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { Select } from 'components/mainComponents/Select';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { MasterDetailLayout } from 'components/v2/layouts/MasterDetailLayout';

import './TrainingSessionAttendanceTrackingPage.scss';
import { URL_PARAM_DIGIFORMA_URL } from './constants';
import { SlotAttendanceTracking } from './SlotAttendanceTracking';

function TrainingSessionAttendanceTracking(props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const locale = getLocale();

  const modules = trainingModules(props.training_session);
  const [selectedModuleId, setSelectedModuleId] = useState(modules[0]?.id);
  const selectedModule = modules.find(module => module.id === selectedModuleId);

  const [sendRemoteSigningMailToTrainees] = useMutation(MAIL_TRAINEES);
  const [sendRemoteSigningMailToInstructors] = useMutation(MAIL_INSTRUCTORS);
  const [remoteSigningEmailStatus, setRemoteSigningEmailStatus] = useState(undefined);
  const areRemoteSigningEmailStatusEqual = remoteSigningEmailStatus?.instructors === remoteSigningEmailStatus?.trainees;

  const digiformaUrl = searchParams.get(URL_PARAM_DIGIFORMA_URL);

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${props.training_session.id}`}>
          {props.training_session.custom_name ?? props.training_session.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('TrainingSessionAttendanceTracking.title')}</Breadcrumb.Item>
      </Breadcrumb>

      <Heading as='h1' level={1} className='TrainingSessionAttendanceTrackingPage__heading'>
        {t('TrainingSessionAttendanceTracking.title')}
      </Heading>

      <p className='TrainingSessionAttendanceTrackingPage__module-select'>
        <Select value={selectedModuleId} onChange={id => setSelectedModuleId(id)}>
          {modules.map(module => (
            <Select.Item value={module.id} key={module.id}>
              {module.name}
            </Select.Item>
          ))}
        </Select>
        {digiformaUrl && (
          <Button size='sm' as='a' href={`${getConfig().serverRoot}${digiformaUrl}`}>
            {t('TrainingSessionAttendanceTracking.back_to_digiforma')}
          </Button>
        )}
      </p>

      <MasterDetailLayout key={selectedModuleId} items={selectedModule.trainingSessionSlots} itemDisplay={moduleSlotName}>
        {sessionSlot => (
          <SlotAttendanceTracking
            sessionSlot={sessionSlot}
            trainingSessionId={props.training_session.id}
            onSendRemoteSigningEmails={(signingInstructorIds, signingTraineeIds) =>
              sendRemoteSigningEmails(sessionSlot.id, signingInstructorIds, signingTraineeIds)
            }
          />
        )}
      </MasterDetailLayout>

      <AlertDialog
        open={remoteSigningEmailStatus !== undefined}
        onOpenChange={isOpen => !isOpen && setRemoteSigningEmailStatus(undefined)}
      >
        <AlertDialogTitle>{t('TrainingSessionAttendanceTracking.remote_signing')}</AlertDialogTitle>
        <AlertDialogContent>
          {areRemoteSigningEmailStatusEqual &&
            remoteSigningEmailStatus?.instructors === true &&
            t('TrainingSessionAttendanceTrackingPage.remote_signing_email_dialog_content_success_both')}
          {areRemoteSigningEmailStatusEqual &&
            remoteSigningEmailStatus?.instructors === false &&
            t('TrainingSessionAttendanceTrackingPage.remote_signing_email_dialog_content_error_both')}
          {!areRemoteSigningEmailStatusEqual && (
            <>
              {remoteSigningEmailStatus?.instructors !== undefined && (
                <p>
                  {t(
                    `TrainingSessionAttendanceTrackingPage.remote_signing_email_dialog_content_${
                      remoteSigningEmailStatus?.instructors === true ? 'success' : 'error'
                    }_instructors`
                  )}
                </p>
              )}
              {remoteSigningEmailStatus?.trainees !== undefined && (
                <p>
                  {t(
                    `TrainingSessionAttendanceTrackingPage.remote_signing_email_dialog_content_${
                      remoteSigningEmailStatus?.trainees === true ? 'success' : 'error'
                    }_trainees`
                  )}
                </p>
              )}
            </>
          )}
        </AlertDialogContent>
        <AlertDialogActions>
          <AlertDialogAction>
            <Button>{t('TrainingSessionAttendanceTrackingPage.remote_signing_email_dialog_dismiss')}</Button>
          </AlertDialogAction>
        </AlertDialogActions>
      </AlertDialog>
    </LeftMenuLayout>
  );

  function moduleSlotName(moduleSlot) {
    return `${new Date(moduleSlot.date).toLocaleDateString(locale)} (${t(moduleSlot.slot)})`;
  }

  async function sendRemoteSigningEmails(sessionSlotId, signingInstructorIds, signingTraineeIds) {
    const [instructorEmailsResult, traineeEmailsResult] = await Promise.allSettled([
      signingInstructorIds.length === 0
        ? undefined
        : sendRemoteSigningMailToInstructors({
            variables: {
              trainingSessionSlotId: sessionSlotId,
              selected: signingInstructorIds,
            },
          }),
      signingTraineeIds.length === 0
        ? undefined
        : sendRemoteSigningMailToTrainees({
            variables: {
              trainingSessionSlotId: sessionSlotId,
              selected: signingTraineeIds,
            },
          }),
    ]);

    setRemoteSigningEmailStatus({
      instructors: signingInstructorIds.length > 0 ? instructorEmailsResult.status === 'fulfilled' : undefined,
      trainees: signingTraineeIds.length > 0 ? traineeEmailsResult.status === 'fulfilled' : undefined,
    });
  }
}

function trainingModules(trainingSession) {
  return trainingSession.subsessions.filter(subsession => !subsession.datesAreInterval && subsession.trainingSessionSlots.length > 0);
}

const query = gql`
  query training_session_attendance_page($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      address
      use_map
      show_program_in_extranet
      show_rules_in_extranet
      show_place_in_extranet
      show_dates_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      has_numeric_signing
      extranet_description_html {
        html
        css
      }
      image {
        url
      }
      start_date
      end_date
      subsessions {
        id
        name
        datesAreInterval
        trainingSessionSlots {
          id
          date
          startTime
          endTime
          slot
          signatures {
            signature
            customerTrainee {
              id
            }
            trainingSessionInstructor {
              id
            }
          }
          trainingSessionInstructors {
            id
            instructor {
              id
              firstname
              lastname
              email
            }
          }
          customers {
            customerTrainees {
              id
              abandons {
                id
                trainingSessionSlots {
                  id
                }
              }
              trainee {
                id
                firstname
                lastname
                email
              }
            }
          }
        }
      }
      modules {
        id
        name
        visible
      }
      program {
        id
      }
      attendance_id
    }
  }
`;

const MAIL_TRAINEES = gql`
  mutation mail_attendance_to_trainees($trainingSessionSlotId: ID!, $selected: [ID]) {
    mail_attendance_to_trainees(trainingSessionSlotId: $trainingSessionSlotId, selected: $selected)
  }
`;

const MAIL_INSTRUCTORS = gql`
  mutation mail_attendance_to_instructors($trainingSessionSlotId: ID!, $selected: [ID]) {
    mail_attendance_to_instructors(trainingSessionSlotId: $trainingSessionSlotId, selected: $selected)
  }
`;

const withGraphqlData = graphql(query, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: parseInt(props.params.training_session_id, 10) },
    };
  },
});

// prettier-ignore
export const TrainingSessionAttendanceTrackingPage =
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        TrainingSessionAttendanceTracking, { loading: PageLoader })));
