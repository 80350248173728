import React from 'react';
import styles from '../../../../scss/video_conference/BackgroundSettings.module.scss';
import PropTypes from 'prop-types';
import { isSafari } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts/AuthContext';
import { useMutation, useQuery } from '@apollo/client';
import { FILESTACK_SECURITY_QUERY, VIDEO_CALL_CONFIG_QUERY } from 'queries/video_conference';
import { VIDEO_CALL_CONFIG_MUTATION } from 'mutations/video_conference';
import { BACKGROUND_CUSTOM, BACKGROUND_UPLOAD, TYPE_OF_BACKGROUND } from 'constants/video_conference';
import Icon from 'components/mainComponents/Icon';
import clsx from 'clsx';
import { filestackDeleteFile, filestackInitializer } from 'components/filestack';
import getFilestackDefaultOptions from 'helpers/video_conference/filestackOptions';

function BackgroundSettings({
  onChange,
  classes,
  withLabels,
  disabled,
  setChosenBackground,
  chosenBackground,
  currentBackgroundSettings,
}) {
  const [updateVideoCallBackground] = useMutation(VIDEO_CALL_CONFIG_MUTATION);
  const { data: filestackSecurity } = useQuery(FILESTACK_SECURITY_QUERY, {
    fetchPolicy: 'network-only',
    variables: { handle: getFileHandle(), image_type: TYPE_OF_BACKGROUND },
  });

  const { auth } = useAuth();
  const { t } = useTranslation();

  const setAndChangeBackground = (event, imageUrl) => {
    event.target.value === BACKGROUND_UPLOAD ? setChosenBackground(BACKGROUND_CUSTOM) : setChosenBackground(event.target.value);
    onChange(event, imageUrl);
  };

  const setAndMutateBackgroundImage = (event, file) => {
    updateVideoCallBackground({
      variables: { videoCallConfig: { backgroundImageUrl: file.url } },
      update: cache => {
        updateVideoCallConfigCache(file.url, cache);
      },
    });
    setAndChangeBackground(event, file.url);
  };

  const updateVideoCallConfigCache = (backgroundImage, cache) => {
    let data = cache.readQuery({ query: VIDEO_CALL_CONFIG_QUERY });
    data = { ...data, videoCallConfig: { ...data.videoCallConfig, backgroundImageUrl: backgroundImage } };
    cache.writeQuery({ query: VIDEO_CALL_CONFIG_QUERY, data });
  };

  const isDisabled = disabled || isSafari();

  const backgroundSettingsClasses = clsx(styles.backgroundSettings, classes);
  const formGroupClasses = clsx(styles.formGroup, {
    [styles['formGroup--disabled']]: isDisabled,
    [styles['formGroup--safari']]: isSafari(),
  });
  const backgroundPickerClasses = clsx(styles.backgroundEffect, { [styles.backgroundEffectWithCustomImage]: isCustomImageSet() });
  const backgroundImageAndIconPreviewClasses = clsx(styles.backgroundPreview, {
    [styles.backgroundPreviewWidthWithCustomImage]: isCustomImageSet(),
    [styles.backgroundPreviewWidthWithoutCustomImage]: !isCustomImageSet(),
  });

  const styleComponentByProperties = isDisabled =>
    clsx(backgroundImageAndIconPreviewClasses, { [styles.previewDisabled]: isDisabled });

  const styleLabel = value => clsx(styles.label, { [styles.checked]: chosenBackground === value && value !== BACKGROUND_UPLOAD });

  const pickFile = event => {
    event.persist();
    const storagePath = `extranet/${auth.guestInfo.id}/video_call_background_images/`;
    const options = getFilestackDefaultOptions(storagePath);
    filestackInitializer(event, options, onSuccessfulUpload, t);
  };

  const onSuccessfulUpload = (event, file) => {
    filestackDeleteFile(filestackSecurity, getFileHandle());
    setAndMutateBackgroundImage(event, file);
  };

  function getFileHandle() {
    const customBackground = currentBackgroundSettings.find(({ value }) => value === BACKGROUND_CUSTOM);
    return customBackground ? customBackground.thumbSrc.split('/').pop() : '';
  }

  function isCustomImageSet() {
    const customBackground = currentBackgroundSettings.find(({ value }) => value === BACKGROUND_CUSTOM);
    return !!customBackground;
  }

  return (
    <div className={backgroundSettingsClasses}>
      {isSafari() && (
        <p className={styles.message}>
          <i className={`icon-warning ${styles.statusIcon} ${styles['statusIcon--info']}`} />
          {t('Settings.background_effects.not_available')}
        </p>
      )}
      <fieldset className={formGroupClasses}>
        {withLabels && <legend className={styles.legend}>{t('Settings.background_effects')}</legend>}
        {currentBackgroundSettings.map(({ value, labelKey, thumbSrc, icon, inputType }) => (
          <label key={value} className={styleLabel(value)}>
            {inputType === 'file' ? (
              <input
                type='radio'
                label={t('Settings.background_upload')}
                className={backgroundPickerClasses}
                onClick={pickFile}
                value={value}
                disabled={isDisabled}
              />
            ) : (
              <input
                type='radio'
                className={backgroundPickerClasses}
                name='background_effect'
                value={value}
                checked={chosenBackground === value}
                onChange={setAndChangeBackground}
                disabled={isDisabled}
              />
            )}
            {thumbSrc && <img src={thumbSrc} alt={t(labelKey)} className={styleComponentByProperties(isDisabled)} />}
            {icon && <Icon icon={icon} className={styles.icon} />}
            {withLabels && t(labelKey)}
          </label>
        ))}
      </fieldset>
    </div>
  );
}

export default BackgroundSettings;

BackgroundSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  withLabels: PropTypes.bool,
  disabled: PropTypes.bool,
  chosenBackground: PropTypes.string,
  currentBackgroundSettings: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      labelKey: PropTypes.string,
      thumbSrc: PropTypes.string,
      icon: PropTypes.string,
      inputType: PropTypes.string,
    })
  ).isRequired,
};

BackgroundSettings.defaultProps = {
  withLabels: true,
  disabled: false,
  classes: '',
  icon: null,
};
