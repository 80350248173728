import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { AccountInfo } from './AccountInfo';
import './HeaderBar.scss';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';
import { SidebarNavigation } from 'components/mainComponents/SidebarNavigation';
import { UserDropdownMenu } from 'components/mainComponents/UserDropdownMenu';
import { useAuth } from 'contexts/AuthContext';
import { getLocale } from 'translations/i18n';

export function HeaderBar(props) {
  const { trainingSessionId } = props;
  const { auth } = useAuth();
  const { type, name } = auth.guestInfo;
  const locale = getLocale().toLowerCase();

  return (
    <header className='V2_HeaderBar'>
      <div className='V2_HeaderBar__container'>
        <div className='V2_HeaderBar__side'>
          {trainingSessionId && (
            <SidebarNavigation
              className={clsx('V2_HeaderBar__SidebarNavigationTrigger', {
                'V2_HeaderBar__SidebarNavigationTrigger--force-show': props.forceShowMenuTrigger,
              })}
              trainingSessionId={trainingSessionId}
            />
          )}
          <AccountInfo />
        </div>
        <div className='V2_HeaderBar__side'>
          {type === 'trainee' && trainingSessionId && !locale.startsWith('es') && (
            <NavLink to='/tutorials'>
              <RoundedIcon icon='question' background='secondary' />
            </NavLink>
          )}
          {trainingSessionId ? <UserDropdownMenu fullName={name} type={type} /> : <p>{name}</p>}
        </div>
      </div>
    </header>
  );
}

HeaderBar.propTypes = {
  forceShowMenuTrigger: PropTypes.bool,
  trainingSessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
