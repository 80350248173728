import { ActionButton } from 'components/mainComponents/buttons';
import PreCallResultButtons from 'components/video_conference/PreCallPage/PreCallResultButtons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useModalDispatch } from 'hooks/video_conference/useModal';
import { VIDEO_TEST_STEP_MODAL } from 'constants/video_conference';

function WebcamStepResults({ isCamOff, className, buttonClassName, isDisabled, onContinue, onContinueWithCamOff, isCompleted }) {
  const { t } = useTranslation();

  const { openModal } = useModalDispatch();

  const handleOpenModal = () => openModal(VIDEO_TEST_STEP_MODAL);

  return isCamOff || !isCompleted ? (
    <div className={buttonClassName}>
      <ActionButton
        clickFn={isCamOff ? onContinueWithCamOff : undefined}
        label={t('WebcamStep.button.continue')}
        icon='arrow--right'
      />
    </div>
  ) : (
    <PreCallResultButtons onAcceptClick={onContinue} onHelpClick={handleOpenModal} disabled={isDisabled} classes={className} />
  );
}

export default WebcamStepResults;

WebcamStepResults.propTypes = {
  isHidden: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
  onContinueWithCamOff: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool,
};

WebcamStepResults.defaultProps = {
  isHidden: false,
  className: '',
  buttonClassName: '',
  isDisabled: false,
  isCompleted: false,
};
