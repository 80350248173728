import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import SentryErrorBoundary from 'components/SentryErrorBoundary';
import useErrorMessage from 'hooks/useErrorMessage';

import './RootErrorBoundary.scss';
import { IconButton } from 'components/mainComponents/IconButton';

function RootErrorFallback({ error }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const errorMessage = useErrorMessage(error.message);

  return (
    <div className='RootErrorBoundary' role='alert'>
      <Heading>{t('RootErrorFallback.title')}</Heading>
      <p className='RootErrorBoundary__error-text'>{errorMessage}</p>
      <div className='RootErrorBoundary__actions'>
        <Button variant='text' onClick={() => navigate(0)}>
          {t('RootErrorFallback.refresh')}
        </Button>
        <IconButton variant='primary' as='a' to='/' icon='home'>
          {t('RootErrorFallback.go_home')}
        </IconButton>
      </div>
    </div>
  );
}

RootErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

export function RootErrorBoundary({ children }) {
  const location = useLocation();
  return (
    <SentryErrorBoundary resetKeys={[location]} FallbackComponent={RootErrorFallback} id='root'>
      {children}
    </SentryErrorBoundary>
  );
}

RootErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
