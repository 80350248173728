const { useEffect, useCallback, useState } = require('react');

function useDebugWindow() {
  const [isDebugWindowOpen, setIsDebugWindowOpen] = useState(false);

  const toggleDebugWindow = useCallback(() => setIsDebugWindowOpen(!isDebugWindowOpen), [isDebugWindowOpen]);

  const handleKeyPress = useCallback(
    event => {
      if (event.ctrlKey && event.shiftKey && event.key === '0') {
        toggleDebugWindow();
      }
    },
    [toggleDebugWindow]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return { toggleDebugWindow, isDebugWindowOpen };
}

export default useDebugWindow;
