import { useState, useEffect, useCallback, useMemo } from 'react';

const useAudio = url => {
  const audio = useMemo(() => new Audio(url), [url]);
  const [audioPlaying, setAudioPlaying] = useState(false);

  const startPlaying = useCallback(() => setAudioPlaying(true), []);

  useEffect(() => {
    const tryPlayingSound = async () => {
      try {
        await audio.play();
      } catch (err) {
        // ignored - user did not grant permission
      }
    };

    if (audioPlaying) {
      tryPlayingSound();
    } else {
      audio.pause();
    }
  }, [audio, audioPlaying]);

  useEffect(() => {
    const onPlayingEnded = () => setAudioPlaying(false);

    audio.addEventListener('ended', onPlayingEnded);

    return () => {
      audio.removeEventListener('ended', onPlayingEnded);
    };
  }, [audio]);

  return [startPlaying, audioPlaying];
};

export default useAudio;
