import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useParentCallback } from '../../../../contexts/ParentCallbackContext';
import './ScormActivity.scss';
import { useAuth } from 'contexts/AuthContext';
import usePageVisibility from 'hooks/usePageVisibility';

const useFarCallbackPolling = previewMode => {
  const fireParentCallback = useParentCallback();
  const intervalId = useRef(null);
  const schedule = useRef(null);
  const isPageVisible = usePageVisibility();
  const periodMs = (!!previewMode && -1) || (isPageVisible && 10000) || 30000;

  useEffect(() => {
    if (periodMs > 0) {
      schedule.current = () => {
        intervalId.current = window.setTimeout(() => {
          fireParentCallback();
          schedule.current();
        }, periodMs);
      };
    }
  }, [fireParentCallback, periodMs]);

  useEffect(() => {
    schedule.current && schedule.current();

    return () => {
      window.clearTimeout(intervalId.current);
    };
  }, []);
};

export function ScormActivity({ scorm, previewMode }) {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const mode = previewMode ? 'GET' : 'POST';

  useFarCallbackPolling(previewMode);

  return (
    <form className='ScormActivity' action={scorm.url} method={mode} target='_blank'>
      {!canUpload(auth?.guestInfo?.type) && (
        <div className='scorm-preview-mode-text'>{t('Scorm.preview_mode_button_placeholder')}</div>
      )}

      <button>
        <i className='icon-scorm' />
        <span>{scorm.name}</span>
      </button>
    </form>
  );
}

function canUpload(guestType) {
  return guestType === 'trainee';
}
