import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './UserAvatar.scss';
import { SvgIcon } from 'components/mainComponents/SvgIcon';
import { userFullname } from 'controllers/userController';

export function UserAvatar(props) {
  const { t } = useTranslation();
  const pictureSize = sizePropToValue(props.size);

  return (
    <div className={`UserAvatar UserAvatar--${props.size}`} style={{ width: pictureSize }}>
      <figure>
        <div className='UserAvatar__picture-wrapper'>
          {props.user.logo ? (
            <img
              src={props.user.logo.url}
              className='UserAvatar__picture'
              height={pictureSize}
              width={pictureSize}
              alt={t('UserAvatar.picture_alt_text')}
            />
          ) : (
            <SvgIcon name='user' height={pictureSize} width={pictureSize} />
          )}
        </div>
        <figcaption className='UserAvatar__name'>{userFullname(props.user)}</figcaption>
      </figure>
    </div>
  );
}

UserAvatar.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
};

UserAvatar.defaultProps = {
  size: 'sm',
};

function sizePropToValue(sizeProp) {
  switch (sizeProp) {
    case 'lg':
      return 200;
    case 'sm':
    default:
      return 96;
  }
}
