import { useTranslation } from 'react-i18next';
import { Paper } from 'components/mainComponents/Paper';
import { RoundedIcon } from 'components/mainComponents//RoundedIcon';
import { Heading } from 'components/mainComponents//Heading';
import { WidgetSubtitle } from 'components/mainComponents/WidgetSubtitle';
import { Mapbox } from 'components/mainComponents/Mapbox';

import './MapWidget.scss';

export function MapWidget(props) {
  const { t } = useTranslation();
  const { showMapInExtranet, address, placeInfo } = props;
  const subtitle = address ?? t('MapWidget.subtitle');

  return (
    <Paper className='MapWidget'>
      <div className='MapWidget__header'>
        <RoundedIcon icon='location' background='primary' />
        <div>
          <Heading level={4}>{t('MapWidget.title')}</Heading>
          <WidgetSubtitle>{subtitle}</WidgetSubtitle>
        </div>
      </div>
      {showMapInExtranet && placeInfo && (
        <div className='MapWidget__container'>
          <Mapbox coordinates={{ lon: placeInfo.longitude, lat: placeInfo.latitude }} />
        </div>
      )}
    </Paper>
  );
}
