import moment from 'moment';

export function compareCommentsByInsertedAtAsc(c1, c2) {
  return moment.parseZone(c1.inserted_at).utc().diff(moment.parseZone(c2.inserted_at).utc());
}

export function compareCommentsByInsertedAtDesc(c1, c2) {
  return -1 * compareCommentsByInsertedAtAsc(c1, c2);
}

export const uniq = (array, fn) => array.reduce((acc, item) => (acc.find(e => fn(item, e)) ? acc : [...acc, item]), []);
