import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../../../scss/video_conference/PreCallStep.module.scss';
import usePreCallState from 'hooks/video_conference/usePreCall';
import PropTypes from 'prop-types';
import { ActionButton } from 'components/mainComponents/buttons';
import { useCallStatusState } from 'hooks/video_conference';
import { useDaily, useLocalParticipant } from '@daily-co/daily-react';
import Settings from 'components/video_conference/Settings';
import PreCallTile from 'components/video_conference/Tile/PreCallTile';
import TileRenderer from 'components/video_conference/Tile/TileRenderer';
import useErrorsState from 'hooks/video_conference/useErrors';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TileTray from './TileTray';
import { VIDEOCALL_SETTINGS_MODAL } from 'constants/video_conference';
import { useModalDispatch } from 'hooks/video_conference/useModal';
import { Illustration } from 'components/mainComponents/Illustration';

function PreCallStep({ shouldGoToTest, onTestClick }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const callObject = useDaily();
  const [searchParams] = useSearchParams();
  const areRobots = searchParams.get('robots');

  const { handleJoinCall, loading, goToNextStep } = usePreCallState();
  const { isCameraStarted } = useCallStatusState();
  const localParticipant = useLocalParticipant();
  const { auth } = useAuth();
  const errors = useErrorsState();
  const { openModal } = useModalDispatch();

  const handleSettingsOpen = () => openModal(VIDEOCALL_SETTINGS_MODAL);
  const handleGoBackClick = () => {
    navigate(-1);
    callObject.leave();
  };

  const areErrors = Object.values(errors).some(value => value);
  const { name } = auth.guestInfo;
  const isVideoReady = isCameraStarted || areErrors;
  const isDisabled = loading || !isVideoReady;

  useEffect(() => {
    if (areRobots) handleJoinCall();
  }, [areRobots, handleJoinCall]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>{name}</p>
        <h1 className={styles.title}>{shouldGoToTest ? t('PreCallStep.set_up') : t('PreCallStep.intro')}</h1>
      </div>
      <div className={styles.preCallVideo}>
        {isVideoReady ? (
          <TileRenderer tile={{ ...localParticipant, renderer: PreCallTile }} classes={styles.tile} />
        ) : (
          <div className={styles.spinner}>
            <Illustration name='loading-spinner-one-quarter' className={styles.spinnerIcon} />
          </div>
        )}
        <TileTray
          onSettingsClick={handleSettingsOpen}
          onTestClick={onTestClick}
          shouldGoToTest={shouldGoToTest}
          isVideoReady={!isDisabled}
        />
      </div>
      <div className={styles.buttons}>
        <ActionButton
          className={styles.button}
          clickFn={!isDisabled ? (shouldGoToTest ? handleJoinCall : handleGoBackClick) : undefined}
          label={shouldGoToTest ? t('PreCallStep.skip_test') : t('PreCallStep.cancel')}
          type='secondary'
        />
        <ActionButton
          className={styles.button}
          clickFn={!isDisabled ? (shouldGoToTest ? goToNextStep : handleJoinCall) : undefined}
          label={shouldGoToTest ? t('PreCallStep.test') : t('PreCallStep.join')}
          icon={isDisabled ? 'loading-spinner--dark--one-quarter' : 'arrow--right'}
        />
      </div>
      <Settings />
    </div>
  );
}

export default PreCallStep;

PreCallStep.propTypes = {
  shouldGoToTest: PropTypes.bool,
  onTestClick: PropTypes.func,
};

PreCallStep.defaultProps = {
  shouldGoToTest: false,
  onTestClick: () => {},
};

PreCallStep.defaultProps = {
  shouldGoToTest: false,
  onTestClick: () => {},
};
