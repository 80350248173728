import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './ActivityCard.scss';
import { Paper } from 'components/mainComponents/Paper';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';
import { SvgIcon } from 'components/mainComponents/SvgIcon';
import { logo } from 'components/module/module';
import { localDateFormat } from 'support/date';

export function ActivityCard(props) {
  const { t } = useTranslation();
  const wrapperClassname = clsx('ActivityCard', {
    'ActivityCard--current': props.isSelected,
    'ActivityCard--over': props.activity.finished,
    'ActivityCard--not-available': !props.previewMode && !props.activity.available,
  });
  const icon = logo(props.activity, props.activityItem);
  const ContentWrapper = props.isSelected ? Paper : 'div';

  return (
    <li className={wrapperClassname}>
      <ContentWrapper className='ActivityCard__wrapper'>
        <button
          className='ActivityCard__container'
          disabled={!(props.previewMode || props.activity.available)}
          onClick={() => props.onSelect()}
        >
          <RoundedIcon icon={icon} background={props.activity.available ? 'alt-1' : 'grey'} />
          <div className='ActivityCard__title'>
            <p>{props.activity.name}</p>
            {!props.activity.available && props.activity.precondition !== 'finished' && (
              <div className='ActivityCard__subtitle'>
                <span>
                  {props.activity.precondition === 'date' && props.activity.precondition_date
                    ? t('ActivityCard.activity_availability_date', { date: localDateFormat(props.activity.precondition_date, true) })
                    : t('ActivityCard.activity_availability_date_unknown')}
                </span>
                <span>
                  {props.activity.precondition === 'score' &&
                    t('ActivityCard.activity_availability_score', { score: props.activity.precondition_score })}
                </span>
              </div>
            )}
          </div>
          {props.activity.finished && <SvgIcon icon='checkmark' className='ActivityCard__checkmark' />}
        </button>
      </ContentWrapper>
    </li>
  );
}

ActivityCard.propTypes = {
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  activity: PropTypes.object.isRequired,
  activityItem: PropTypes.object,
  previewMode: PropTypes.bool,
};
