import React, { useEffect, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import _, { flowRight as compose } from 'lodash';
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { withData, withRouteParams } from 'support/page';
import TrainingSessionHeader from '../training_session/TrainingSessionHeader';
import { COMMENT_FRAGMENT } from 'controllers/comment_controller.js';
import { useAuth } from 'contexts/AuthContext';
import { ActionButton, UrlButton } from 'components/mainComponents/buttons';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import ActivityRenderer from './ModulePage/ActivityRenderer';
import ActivityPath from './ModulePage/ActivityPath';
import Discussions from './ModulePage/Discussion';
import ModuleHeader from './ModulePage/ModuleHeader';
import { getItem, isActivityAvailable, isVisible } from './module';
import { Navigate } from 'react-router-dom/dist';
import { useNavigate } from 'react-router-dom';
import { ParentCallbackContextProvider } from 'contexts/ParentCallbackContext';
import { Breadcrumb } from '../mainComponents/Breadcrumb/Breadcrumb';

import 'scss/ModulePage.scss';

const ModulePage = props => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { t } = useTranslation();
  const [state, setState] = useState({
    selectedActivity: null,
    previewMode: auth?.guestInfo?.type !== 'trainee',
  });

  const select = activity => {
    if (state.previewMode) {
      setState(state => ({ ...state, selectedActivity: activity }));
      return;
    }

    if (isActivityAvailable(activity, state.previewMode)) {
      if (auth?.guestInfo?.type === 'trainee') {
        props.mutate({
          variables: {
            trainee_id: auth?.guestInfo?.id,
            module_id: props.module.id,
            module_sequence_item_id: activity.id,
          },
        });
      }

      setState(state => ({ ...state, selectedActivity: activity }));
    }
  };

  useEffect(() => {
    if (props.training_session && props.module) {
      const module = props.module;
      let newActivity = null;

      if (state.selectedActivity) {
        // It is possible that the activity previously selected has been deleted, resetted, made unavailable.
        // => the case may arise that the activity that was selected cannot be the active one anymore.

        const activityId = state.selectedActivity.id;
        newActivity = module.sequence.find(({ id }) => activityId === id) || null;

        if (newActivity && !isActivityAvailable(newActivity, state.previewMode)) {
          newActivity = null;
        }
      }

      if (!newActivity && module.cursor) {
        newActivity = _.find(module.sequence, activity => {
          const { id } = activity;
          return id === module.cursor.module_sequence_item_id && isActivityAvailable(activity);
        });
      }

      if (!newActivity) {
        newActivity = module.sequence.find(x => isActivityAvailable(x) && x.finished);
      }

      if (!newActivity) {
        newActivity = module.sequence.length > 0 && module.sequence[0];
      }

      newActivity = newActivity || null; // Coerce falsy values (false, undefined, null) to null

      if (newActivity !== state.selectedActivity) {
        setState(state => ({ ...state, selectedActivity: newActivity }));
      }
    }
  }, [props.training_session, props.module, state.selectedActivity, state.previewMode]);

  // Check if the module is visibile
  if (!isVisible(props.module)) return <Navigate to={`/ts/${props.params.training_session_id}/modules`} replace />;

  const onPrev = () => {
    if (canGoPrev().possible) {
      select(props.module.sequence[canGoPrev().index]);
    }
  };

  const onNext = () => {
    if (canGoNext().possible) {
      select(props.module.sequence[canGoNext().index]);
    }
  };

  const onFinish = () => {
    if (canGoNext().finished) {
      navigate(`/ts/${props.params.training_session_id}/modules`);
    }
  };

  const canGoPrev = () => {
    const { selectedActivity } = state;
    if (!selectedActivity) return { possible: false };
    const { module } = props;
    const currentIndex = _.findIndex(module.sequence, msi => msi.id === selectedActivity.id);
    return {
      possible: currentIndex > 0,
      index: currentIndex - 1,
    };
  };

  const canGoNext = () => {
    const { selectedActivity } = state;
    if (!selectedActivity) return { possible: false };
    const { module } = props;
    const currentIndex = _.findIndex(module.sequence, msi => msi.id === selectedActivity.id);

    const nextActivity = module.sequence[currentIndex + 1];
    return {
      possible: currentIndex < module.sequence.length - 1 && isActivityAvailable(nextActivity),
      finished: currentIndex === module.sequence.length - 1 && !isActivityAvailable(nextActivity) && selectedActivity.finished,
      index: currentIndex + 1,
    };
  };

  const { training_session } = props;
  const { module } = props;
  const { selectedActivity } = state;
  const selectedItem = selectedActivity && getItem(selectedActivity, module);
  const training_session_name = training_session.custom_name || training_session.name;

  return (
    <div className={`page ModulePage ${state.previewMode ? 'preview' : ''}`}>
      <TrainingSessionHeader training_session_id={training_session.id} selected={`module-${module.id}`} />
      <main>
        <ModuleHeader module={module} />
        <Breadcrumb
          links={[
            { name: training_session_name, url: `/ts/${training_session.id}` },
            { name: t('TrainingSessionHeader.modules_details'), url: `/ts/${training_session.id}/modules` },
            { name: module.name },
          ]}
        />
        <div className='two-columns'>
          <div className='left-column'>
            {module.virtualroom && (
              <UrlButton
                url={`/ts/${training_session.id}/module/${module.id}/virtual_room`}
                label={t('TrainingSessionHeader.virtual_room')}
                icon='video--on'
                type='secondary'
              />
            )}
            <ActivityPath
              module={module}
              selectedActivity={selectedActivity}
              previewMode={state.previewMode}
              onActivityChange={select}
            />
          </div>

          <div className='right-column'>
            {selectedActivity && selectedItem && (
              <ActivityRenderer
                previewMode={state.previewMode}
                module={module}
                item={selectedItem}
                activity={selectedActivity}
                training_session={training_session}
                guestId={auth?.guestInfo?.id}
              />
            )}
            {module.forum && <Discussions module={module} />}
          </div>
        </div>

        {!state.previewMode && (
          <div className='move-buttons'>
            {canGoPrev().possible && (
              <ActionButton label={t('ModulePage.previous')} clickFn={onPrev} icon='arrow--left' type='secondary' />
            )}

            {canGoNext().possible && (
              <ActionButton className='next' label={t('ModulePage.next')} clickFn={onNext} icon='arrow--right' />
            )}

            {canGoNext().finished && <ActionButton className='finish' type='blue' label={t('ModulePage.finish')} clickFn={onFinish} />}
          </div>
        )}
      </main>
    </div>
  );
};

const TrainingSessionDataContainer = withRouteParams(props => {
  const { t } = useTranslation();

  const id = parseInt(props.params.id, 10);

  const { loading, error, data, refetch } = useReloadableQuery(moduleQuery, {
    name: 'module',
    variables: {
      id,
    },
  });

  if (loading) {
    return <LoadingPagePlaceholder />;
  }

  if (error) {
    return t('unauthorized');
  }

  return (
    <ParentCallbackContextProvider callback={refetch}>
      <ModulePage {...props} module={data.module} />
    </ParentCallbackContextProvider>
  );
});

const useReloadableQuery = function (query, opts) {
  const { loading, error, data, refetch, networkStatus } = useQuery(query, opts);
  const [stateCache, setStateCache] = useState({ loading, error, data });

  useEffect(() => {
    if (networkStatus === NetworkStatus.refetch) {
      if (data || error) {
        setStateCache(prevState => ({ ...prevState, error, data }));
      }
    } else {
      setStateCache({ loading, error, data });
    }
  }, [loading, error, data, networkStatus, setStateCache]);

  return { ...stateCache, refetch };
};

const query = gql`
  query module_page_training_session($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      show_program_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      show_dates_in_extranet
      has_numeric_signing
      start_date
      program {
        id
      }
      modules {
        id
        name
        visible
        visibilityMode
        visibilityStartDate
        visibilityEndDate
        virtualroom
      }
    }
  }
`;

const moduleQuery = gql`
  query module_page($id: ID!) {
    module(id: $id) {
      id
      name
      forum
      virtualroom
      description
      visible
      visibilityMode
      visibilityStartDate
      visibilityEndDate
      show_score
      cursor {
        module_sequence_item_id
      }
      image {
        id
        url
      }
      documents {
        id
        filename
        url
        mime
        wistia {
          hashed_id
        }
        downloadable
      }
      scorms {
        id
        name
        url
      }
      email_markers {
        id
        email {
          title
          content
        }
      }
      upload_markers {
        id
        name
        description
        submitted_upload {
          id
          score
          url
          filename
          mimetype
          comment
        }
      }
      embeds {
        id
        name
        url
        embedly
      }
      html_docs {
        id
      }
      livestreams {
        id
        url
        start
        use_virtualroom
      }
      sequence {
        id
        resource_type
        resource_id
        finished
        scorable
        score
        available
        name
        description
        duration_min
        precondition
        precondition_score
        precondition_date
      }
      pending_evaluations {
        id
        trainee_id
        pending
        answer_evaluation_result {
          score
          total
        }
        evaluation {
          id
          name
          type
          replayable
        }
      }
      comments {
        ...comment
      }
    }
  }

  ${COMMENT_FRAGMENT}
`;

const mutation = gql`
  mutation set_module_trainee_cursor($trainee_id: ID!, $module_id: ID!, $module_sequence_item_id: ID!) {
    set_module_trainee_cursor(trainee_id: $trainee_id, module_id: $module_id, module_sequence_item_id: $module_sequence_item_id)
  }
`;

const withGraphqlData = compose(
  graphql(query, {
    name: 'training_session',
    options: props => ({
      variables: {
        id: parseInt(props.params.training_session_id, 10),
      },
    }),
  }),
  graphql(mutation)
);

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        TrainingSessionDataContainer, { loading: LoadingPagePlaceholder })));
