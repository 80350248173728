export function getLocalConfig() {
  let config = localStorage.getItem('videoCallConfig');

  if (config) {
    config = JSON.parse(config);
  } else {
    config = {};
  }

  return config;
}
