import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Score from './Score';
import { getConfig } from '../../../config';
import { getLocale } from 'translations/i18n';
import { useAuth } from 'contexts/AuthContext';
import { ActionButton, UrlButton } from 'components/mainComponents/buttons';
import { useParentCallback } from 'contexts/ParentCallbackContext';
import '../../../scss/UploadMarker.scss';

import * as filestack from 'filestack-js';

const API_KEY = getConfig().FILESTACK_API_KEY;
const S3_BUCKET = getConfig().REACT_APP_S3_BUCKET;
const DEFAULT_FILE_MAX_SIZE = 20 * 1024 * 1024;

const pickFile = (submit, t) => e => {
  const { id, trainee } = e.currentTarget.dataset;
  const storagePath = `/upload_markers/${id}/trainee-${trainee}`;
  const language = getLocale().split('-')[0]; // this is used to avoid the strings like 'fr-FR' or 'en-GB', the picker doesn't recognized the language on that format.
  filestack
    .init(API_KEY)
    .picker({
      lang: language,
      storeTo: {
        location: 'S3',
        container: S3_BUCKET,
        path: storagePath,
        region: 'eu-west-1',
      },
      maxSize: DEFAULT_FILE_MAX_SIZE * 25,
      maxFiles: 1,
      transformations: { crop: true, rotate: true, circle: true },
      onFileUploadFinished: ({ url, filename, mimetype }) => {
        submit({
          variables: {
            id,
            url,
            filename,
            mimetype,
          },
        });
      },
      onFileUploadFailed: () => submit(false) && alert(t('UploadMarker.unable_to_load')),
    })
    .open();
};

const uploadedFile = ({ filename, mimetype, url }, t) => (
  <div className='upload-uploaded-file'>
    {mimetype.startsWith('image/') ? (
      <img src={url} className='upload-image' alt={filename} />
    ) : (
      <div className='upload-uploaded-file__download'>
        {t('UploadMarker.loaded_file')}:{' '}
        <UrlButton
          type='secondary'
          ariaLabel={'UploadMarker.loaded_file'}
          label={filename}
          url={url}
          icon='download'
          rel='noopener noreferrer'
          size='small'
        />
      </div>
    )}
  </div>
);

const uploadReview = ({ score, comment }) => (
  <div className='upload-review'>
    <Score score={score} total={100} correct />
    {comment !== null && <div className='upload-review-comment'>{comment}</div>}
  </div>
);

function UploadMarker({ trainee_id, upload: { id, description, submitted_upload } }) {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const [submitUpload] = useMutation(submitUploadMutation);
  const fireParentCallback = useParentCallback();
  let pickButtonClick = pickFile((...args) => {
    submitUpload(...args).then(fireParentCallback());
  }, t);

  if (submitted_upload && submitted_upload.score) {
    const originalFn = pickButtonClick;
    const msg = t('UploadMarker.if_you_upload_a_new_file');
    pickButtonClick = e => window.confirm(msg) && originalFn(e);
  }

  const scored = submitted_upload && submitted_upload.score;

  return (
    <div className='UploadMarker'>
      {!canUpload(auth?.guestInfo?.type) && (
        <div className='upload-preview-mode-text'>{t('UploadMarker.preview_mode_button_placeholder')}</div>
      )}

      <div className='upload-description'>{description}</div>

      {submitted_upload && (
        <>
          {uploadedFile(submitted_upload, t)}
          {submitted_upload.score !== null ? (
            uploadReview(submitted_upload)
          ) : (
            <div className='upload-pending-review'>{t('UploadMarker.file_has_not_been_noted')}</div>
          )}
        </>
      )}

      {canUpload(auth?.guestInfo?.type) && !scored && (
        <ActionButton
          label={t('UploadMarker.choose_a_file')}
          icon='upload'
          clickFn={pickButtonClick}
          data-id={id}
          data-trainee={trainee_id}
        />
      )}
    </div>
  );
}

function canUpload(guestType) {
  return guestType === 'trainee';
}

const submitUploadMutation = gql`
  mutation submit_upload($id: ID!, $url: String!, $filename: String!, $mimetype: String!) {
    submitUpload(id: $id, url: $url, filename: $filename, mimetype: $mimetype) {
      id
      url
      filename
      mimetype
      score
      comment
    }
  }
`;

export default UploadMarker;
