import React from 'react';

import './AttendancesPage.scss';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Heading } from 'components/mainComponents/Heading';
import { AttendancesList } from 'components/v2/AttendancesPage/AttendancesList';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { withData, withRouteParams } from 'support/page';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

function Attendances({ trainingSession }) {
  const { t } = useTranslation();
  const trainingSessionName = trainingSession.custom_name || trainingSession.name;

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${trainingSession.id}`}>{trainingSessionName}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('AttendancesWidget.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <section className='V2_AttendancesPage'>
        <Heading className='V2_AttendancesPage__title' as='h1' level={1}>
          {t('TrainingSessionAttendancePage.digital_signoff')}
        </Heading>
        <AttendancesList
          pendingSlotsToSign={trainingSession.pendingSlotsToSign}
          tsId={trainingSession.id}
          showSigningButtonInExtranet={trainingSession.show_signing_button_in_extranet}
        />
      </section>
    </LeftMenuLayout>
  );
}

const ATTENDANCES_PAGE_QUERY = gql`
  query pending_signins_panel($id: ID!) {
    trainingSession(id: $id) {
      id
      name
      custom_name
      show_signing_button_in_extranet
      pendingSlotsToSign {
        id
        subsession {
          name
        }
        date
        startTime
        endTime
      }
    }
  }
`;

const withGraphqlData = graphql(ATTENDANCES_PAGE_QUERY, {
  name: 'trainingSession',
  options: props => {
    return {
      variables: { id: props.params.training_session_id },
    };
  },
});

// prettier-ignore
export const AttendancesPage =
  withRouteParams(
    withGraphqlData(
      withData('trainingSession')(
        Attendances, { loading: PageLoader })));
