import * as React from 'react';
import { gql } from '@apollo/client';
import NewVideoConferencePage from '../NewVideoConferencePage';
import VideoConferencePage from '../../VideoConferencePage';
import { isVisible } from '../../module/module';
import { Navigate } from 'react-router-dom/dist';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { withRouteParams } from '../../../support/page';
import { getUserCode } from '../../../support/auth';

const EXTRANET_CONFIG_QUERY = gql`
  query ($userCode: String!) {
    extranetConfig(userCode: $userCode) {
      id
      newVideoConference
    }
  }
`;

const MODULE_VISIBILITY_QUERY = gql`
  query ($id: ID!) {
    module(id: $id) {
      visibilityMode
      visibilityStartDate
      visibilityEndDate
    }
  }
`;

function VideoConferencePageResolver({ extranetConfig, module, params }) {
  const { extranetConfig: extranetConfigData, loading } = extranetConfig;
  const { module: moduleData, loading: visibilityLoading } = module;

  if (loading || visibilityLoading) {
    return null;
  }

  if (!isVisible(moduleData)) return <Navigate to={`/ts/${params.training_session_id}/modules`} replace />;

  if (extranetConfigData?.newVideoConference) {
    return <NewVideoConferencePage />;
  }

  return <VideoConferencePage params={params} />;
}

const withGraphqlData = compose(
  graphql(EXTRANET_CONFIG_QUERY, {
    name: 'extranetConfig',
    options: props => {
      return {
        variables: { userCode: getUserCode() },
      };
    },
  }),
  graphql(MODULE_VISIBILITY_QUERY, {
    name: 'module',
    options: props => {
      return {
        variables: { id: props.params.id },
      };
    },
  })
);

export default withRouteParams(withGraphqlData(VideoConferencePageResolver));
