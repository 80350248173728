import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/mainComponents/Button';
import { IconButton } from 'components/mainComponents/IconButton';
import SignaturePad from 'components/signature-pad';

import './SignatureCanvas.scss';

export function SignatureCanvas({ onCancel, onSave, disabled }) {
  const signaturePadRef = useRef();
  const { t } = useTranslation();
  const clearCanvas = () => signaturePadRef.current?.clear();
  const saveSignature = async () => {
    if (!signaturePadRef.current || signaturePadRef.current?.isEmpty()) return;
    await onSave(signaturePadRef.current?.toDataURL());
    clearCanvas();
  };

  return (
    <div className='SignatureCanvas'>
      <div className='SignatureCanvas__header'>
        <p>{t('SignatureCanvas.description')}</p>
        <EraseButton onClick={clearCanvas} />
      </div>
      <div className='SignatureCanvas__main'>
        <SignaturePad className='SignatureCanvas__pad' ref={signaturePadRef} />
        <EraseButton onClick={clearCanvas} />
      </div>
      <div className='SignatureCanvas__footer'>
        <Button variant='text' onClick={onCancel}>
          {t('SignatureCanvas.cancel')}
        </Button>
        <Button disabled={disabled} onClick={saveSignature}>
          {t('SignatureCanvas.save')}
        </Button>
      </div>
    </div>
  );
}

function EraseButton({ onClick }) {
  return <IconButton onClick={onClick} variant='primary' className='SignatureCanvas__eraser' icon='eraser' />;
}
