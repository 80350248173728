import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withData } from 'support/page';
import CustomColorChooser from 'components/mainComponents/CustomColorChooser';
import { withAuth } from 'contexts/AuthContext';
import { UPDATE_CUSTOM_COLOR } from './mutations.jsx';
import { ACCOUNT_INFO_QUERY } from 'components/mainComponents/HeaderBar/AccountInfo';
import Icon from 'components/mainComponents/Icon.jsx';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';

const ConfigurationMessage = ({ icon, message }) => {
  return (
    <div className='display-config-panel__message'>
      <Icon icon={icon} />
      <p>{message}</p>
    </div>
  );
};

export function DisplayConfigurationPanel({ accountInfos }) {
  const { t } = useTranslation();
  const [updateCustomColorMutation, mutationState] = useMutation(UPDATE_CUSTOM_COLOR);
  const { loading, error } = mutationState;
  const [color, setColor] = useState('#ffffff');

  useEffect(() => {
    setColor(accountInfos.extranetCustomColor);
  }, [accountInfos]);

  const updateExtranetColor = newColor => {
    updateCustomColorMutation({ variables: { extranetCustomColor: newColor } });
    setColor(newColor);
  };

  return (
    <div className='display-config-panel'>
      <div className='display-config-panel__header'>
        <h2>{t('DisplayConfigurationPanel.interface_color')}</h2>
        <ConfigurationMessage icon='question--circle' message={t('DisplayConfigurationPanel.information')} />
        <CustomColorChooser notifyColorSaveFn={updateExtranetColor} color={color} />
        <ConfigurationMessage icon='warning--triangle' message={t('DisplayConfigurationPanel.caution')} />
        {error && <p>Error: {error.message}</p>}
        {loading && <p>{t('loading')}</p>}
      </div>
    </div>
  );
}

const withGraphqlData = graphql(ACCOUNT_INFO_QUERY, {
  name: 'accountInfos',
  options: props => {
    return {
      variables: { userCode: props.auth?.userCode },
    };
  },
});

// prettier-ignore
export default
  withAuth(
    withGraphqlData(
      withData('accountInfos')(
        DisplayConfigurationPanel, { loading: LoadingPagePlaceholder })));
