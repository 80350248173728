import React from 'react';
import PropTypes from 'prop-types';
import { PaletteColorPropType } from 'propTypes';

import _ from 'lodash';
import './ProgressCircle.scss';
import clsx from 'clsx';

export function ProgressCircle(props) {
  const circle = circle_properties({ ...props, radius: props.size === 'lg' ? 43 : 42 });
  const wrapperClassname = clsx(
    'ProgressCircle',
    `ProgressCircle--size-${props.size}`,
    `ProgressCircle--stroke-${props.color}`,
    props.className
  );

  return (
    <div role='progressbar' aria-valuenow={props.value * 100} className={wrapperClassname}>
      <svg className='ProgressCircle__circle' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
        <circle className='ProgressCircle__circle-track' cx='50' cy='50' r={circle.radius} />
        <circle
          className={`ProgressCircle__progress ${props.value === 0 ? 'ProgressCircle__progress--hidden' : ''}`}
          cx='50'
          cy='50'
          r={circle.radius}
          stroke={props.color}
          strokeDasharray={[circle.progress_length, circle.remaining_length]}
          strokeDashoffset={circle.stroke_dash_offset}
        />
      </svg>
      {(props.size === 'lg' || props.size === 'md') && (
        <div className='ProgressCircle__captions'>
          {props.subtitle && <p className='ProgressCircle__subtitle'>{props.subtitle}</p>}
          {props.title && <p className='ProgressCircle__title'>{props.title}</p>}
        </div>
      )}
    </div>
  );
}

ProgressCircle.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['xs', 'md', 'lg']),
  color: PaletteColorPropType,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProgressCircle.defaultProps = {
  size: 'lg',
  color: 'primary',
};

export function circle_properties({ radius, value }) {
  const circumference = 2 * Math.PI * radius;
  const progress_length = _.clamp(value, 0, 1) * circumference;
  const remaining_length = circumference - progress_length;
  const stroke_dash_offset = 0.25 * circumference; // Dash offset starts on the right

  return {
    radius,
    circumference,
    stroke_dash_offset,
    progress_length,
    remaining_length,
  };
}
