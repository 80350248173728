import { useLocalParticipant } from '@daily-co/daily-react';
import { TOGGLE_MIC_BUTTON, TOGGLE_SHOULD_TURN_OFF_MIC } from 'actions/video_conference';
import { RECORDING } from 'constants/video_conference';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCallStatusDispatch, useCallStatusState } from '..';
import useDevicesToggle from '../useDevicesToggle';
import useRecorder from '../useRecorder';

function useMicTest(isActive) {
  const [progress, setProgress] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();
  const recordingInterval = useRef(null);
  const { shouldTurnOffMic } = useCallStatusState();
  const dispatch = useCallStatusDispatch();
  const { audioTrack } = useLocalParticipant() || {};
  const { startRecording, stopRecording, state, togglePlay, returnToInitialState, returnToPreviousState } = useRecorder(audioTrack);

  const { toggleMic, isMicMuted } = useDevicesToggle();

  const suggestions = [t('MicStep.mic_issues.example_1'), t('MicStep.mic_issues.example_2')];

  const finishRecordingWithSuccess = () => {
    setIsSuccess(true);
  };

  const resetState = () => {
    returnToInitialState();
    setIsSuccess(false);
  };

  useEffect(() => {
    if (isMicMuted && !shouldTurnOffMic) {
      toggleMic();
      dispatch({ action: TOGGLE_SHOULD_TURN_OFF_MIC });
    }
  }, [isMicMuted, dispatch, toggleMic, shouldTurnOffMic]);

  useEffect(() => {
    if (state === RECORDING) {
      setIsSuccess(false);
      dispatch({ action: TOGGLE_MIC_BUTTON });
      recordingInterval.current = setInterval(() => {
        setProgress(prev => prev + 1);
      }, 50);
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (progress === 100 && state === RECORDING) {
      setProgress(0);
      stopRecording();
      dispatch({ action: TOGGLE_MIC_BUTTON });
      clearInterval(recordingInterval.current);
    }
  }, [progress, dispatch, state, stopRecording]);

  useEffect(() => {
    if (!isActive && state === RECORDING) {
      setProgress(0);
      stopRecording();
      returnToPreviousState();
      clearInterval(recordingInterval.current);
    }
  }, [isActive, state, stopRecording, returnToPreviousState]);

  // Mocked problems, to be replaced with real ones
  const problems = [
    { title: t('ConnectionTestStep.network_issues'), items: [t('ConnectionTestStep.network_issues.low_bandwidth')] },
    { title: t('ConnectionTestStep.adjustments'), items: [t('ConnectionTestStep.adjustments.bandwidth', { kbs: 512 })] },
  ];

  return {
    startRecording,
    finishRecordingWithSuccess,
    isSuccess,
    suggestions,
    progress,
    problems,
    resetState,
    togglePlay,
    state,
  };
}

export default useMicTest;
