import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'scss/video_conference/Tray.module.scss';
import { RoomOptsContext } from 'contexts/video_conference';
import { callReady } from 'helpers/video_conference/callStatus';
import useDisplayMode from 'hooks/video_conference/useDisplayMode';
import { useCallStatusState } from 'hooks/video_conference';
import { useDaily, useScreenShare } from '@daily-co/daily-react';
import useErrorsState from 'hooks/video_conference/useErrors';
import {
  CAM_IN_USE,
  CAMERA_ERROR,
  DISPLAY_MODE_MODAL,
  MIC_ERROR,
  MIC_IN_USE,
  NOT_ALLOWED_ERROR,
  NOT_FOUND,
  VIDEOCALL_SETTINGS_MODAL,
} from 'constants/video_conference';
import Tooltip from 'components/video_conference/Tooltip';
import useDevicesToggle from 'hooks/video_conference/useDevicesToggle';
import IconButton from 'components/video_conference/IconButton';
import useMedia from 'hooks/video_conference/useMedia';
import { useNavigateMaybeWithFeedback } from 'components/video_conference/FeedbackContext';
import { CHAT_ELEMENT, MENU_ELEMENT } from 'constants/video_conference/roomElements';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import { MD_BREAKPOINT } from 'constants/breakpoints';
import { useModalDispatch } from 'hooks/video_conference/useModal';
import RecordingStatus from './RecordingStatus';
import clsx from 'clsx';
import Recording from './Recordings';

function Tray() {
  const callObject = useDaily();

  const { t } = useTranslation();
  const { isRecording } = useCallStatusState();
  const { trainingSessionId, moduleId } = useContext(RoomOptsContext);
  const { isCameraMuted, isMicMuted, toggleCamera, toggleMic } = useDevicesToggle();
  const { startScreenShare, stopScreenShare, isSharingScreen } = useScreenShare();

  const ready = callReady(callObject);
  const isDesktop = useMedia(MD_BREAKPOINT);
  const navigateMaybeWithFeedback = useNavigateMaybeWithFeedback();
  const { toggleRoomElement, closeRoomElement } = useRoomElementsDisplay();

  const [displayMode] = useDisplayMode();
  const { openModal } = useModalDispatch();

  const { cameraError, micError } = useErrorsState();

  const DISPLAY_MODE_BUTTON_ICONS = {
    FEATURED_SPEAKER: 'layout-spotlight',
    GRID: 'layout-tiled',
    AUTO: 'layout-auto',
  };

  const renderCameraErrorMsg = () => {
    if (ready && !cameraError) return null;

    switch (cameraError) {
      case NOT_ALLOWED_ERROR:
        return t('Tray.camera_not_allowed_error');
      case NOT_FOUND:
        return t('Tray.camera_not_found_error');
      case CAM_IN_USE:
        return t('Tray.camera_in_use_error');
      default:
        return null;
    }
  };

  const renderMicErrorMsg = () => {
    if (ready && !micError) return null;

    switch (micError) {
      case NOT_ALLOWED_ERROR:
        return t('Tray.mic_not_allowed_error');
      case NOT_FOUND:
        return t('Tray.mic_not_found_error');
      case MIC_IN_USE:
        return t('Tray.mic_in_use_error');
      default:
        return null;
    }
  };

  const handleToggleScreenShareClick = () => (isSharingScreen ? stopScreenShare() : startScreenShare());

  const handleOpenDisplayModeModal = () => openModal(DISPLAY_MODE_MODAL);
  const handleToggleMenuClick = () => {
    closeRoomElement(CHAT_ELEMENT);
    toggleRoomElement(MENU_ELEMENT);
  };
  const handleQuitClick = () => {
    navigateMaybeWithFeedback(`/ts/${trainingSessionId}/module/${moduleId}`);
  };
  const handleOpenSettings = () => openModal(VIDEOCALL_SETTINGS_MODAL);
  const micButtonClasses = clsx(styles.button, styles.micButton);

  return (
    <div className={styles.Tray}>
      <RecordingStatus isRecording={isRecording} />
      <Tooltip description={renderMicErrorMsg()} id={MIC_ERROR} className={micButtonClasses}>
        <IconButton
          clickFn={ready && !micError ? toggleMic : undefined}
          title={isMicMuted ? t('Tray.unmute') : t('Tray.mute')}
          type={isMicMuted ? 'error' : 'secondary'}
          icon={isMicMuted ? 'microphone--off' : 'microphone'}
        />
      </Tooltip>

      <Tooltip description={renderCameraErrorMsg()} id={CAMERA_ERROR} className={styles.button}>
        <IconButton
          clickFn={ready && !cameraError ? toggleCamera : undefined}
          icon={isCameraMuted ? 'camera--off' : 'video--on'}
          title={isCameraMuted ? t('Tray.turn_cam_on') : t('Tray.turn_cam_off')}
          type={isCameraMuted ? 'error' : 'secondary'}
        />
      </Tooltip>

      {isDesktop && (
        <IconButton
          title={isSharingScreen ? t('Tray.stop_screenshare') : t('Tray.start_screenshare')}
          disabled={!ready}
          clickFn={handleToggleScreenShareClick}
          icon={isSharingScreen ? 'present-screen--off' : 'present-screen'}
          type={isSharingScreen ? 'default' : 'secondary'}
        />
      )}

      {isDesktop && (
        <IconButton
          title={t('Tray.display_mode')}
          icon={DISPLAY_MODE_BUTTON_ICONS[displayMode]}
          clickFn={handleOpenDisplayModeModal}
          type='secondary'
        />
      )}
      <Recording ready={ready} />
      <IconButton
        title={t('Tray.settings')}
        icon={isDesktop ? 'gear' : 'three--dots--vertical'}
        clickFn={isDesktop ? handleOpenSettings : handleToggleMenuClick}
        type='secondary'
        className={styles.button}
      />
      {!isDesktop && (
        <IconButton title={'Tray.exit'} icon='logout' clickFn={handleQuitClick} type='secondary' className={styles.button} />
      )}
    </div>
  );
}

export default Tray;
