import React, { useEffect } from 'react';
import StepContainer from 'components/video_conference/PreCallPage/StepContainer';
import { useTranslation } from 'react-i18next';
import styles from 'scss/video_conference/ConnectionTestStep.module.scss';
import { useModalDispatch } from 'hooks/video_conference/useModal';
import PreCallModal from 'components/video_conference/PreCallPage/PreCallModal';
import { ADJUSTED_TEST_STATE, CONNECTION_TEST_STEP_MODAL, SUCCESS_TEST_STATE } from 'constants/video_conference';
import PropTypes from 'prop-types';
import usePreCallState from 'hooks/video_conference/usePreCall';
import PreCallResultButtons from 'components/video_conference/PreCallPage/PreCallResultButtons';
import useConnectionTest from 'hooks/video_conference/useConnectionTest';
import { ActionButton } from 'components/mainComponents/buttons';
import ConnectionTestStepTitle from './ConnectionTestStepTitle';
import clsx from 'clsx';
import { useLocalParticipant } from '@daily-co/daily-react';
import TileRenderer from 'components/video_conference/Tile/TileRenderer';
import PreCallTile from 'components/video_conference/Tile/PreCallTile';

function ConnectionTestStep({ isActive, onCompleted, isCompleted }) {
  const { t } = useTranslation();
  const localParticipant = useLocalParticipant();

  const { openModal } = useModalDispatch();
  const { handleJoinCall } = usePreCallState();
  const { problems, suggestions, timedOut, testState } = useConnectionTest(isActive);

  const handleAcceptClick = () => onCompleted(true);
  const handleOpenModal = () => openModal(CONNECTION_TEST_STEP_MODAL);

  const isSuccess = testState === SUCCESS_TEST_STATE;
  const isAdjusted = testState === ADJUSTED_TEST_STATE;

  const firstRowClass = clsx(styles.row, styles['row--first']);

  useEffect(() => {
    if (isSuccess && !isCompleted) {
      onCompleted(true);
    }
  }, [isSuccess, onCompleted, isCompleted]);

  return (
    isActive && (
      <>
        <StepContainer title={t('ConnectionTestStep.title.default')}>
          <div className={firstRowClass}>
            <ConnectionTestStepTitle testState={testState} isActive={isActive} timedOut={timedOut} className={styles.title} />
          </div>
          {isAdjusted ? (
            <PreCallResultButtons onAcceptClick={handleAcceptClick} onHelpClick={handleOpenModal} classes={styles.row} />
          ) : (
            <ActionButton
              clickFn={isCompleted ? handleJoinCall : undefined}
              label={t('WebcamStep.button.continue')}
              icon='arrow--right'
              className={styles.button}
            />
          )}
          <PreCallModal
            modalId={CONNECTION_TEST_STEP_MODAL}
            title={t('ConnectionTestStep.modal.title')}
            bottomText={t('ConnectionTestStep.modal.bottom_text')}
            suggestions={suggestions}
            problems={problems}
          />
          <TileRenderer tile={{ ...localParticipant, renderer: PreCallTile }} />
        </StepContainer>
      </>
    )
  );
}

export default ConnectionTestStep;

ConnectionTestStep.propTypes = {
  isActive: PropTypes.bool,
  onCompleted: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

ConnectionTestStep.defaultProps = {
  isActive: false,
};
