import { useDaily, useLocalParticipant } from '@daily-co/daily-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ADJUSTED_TEST_STATE,
  CONSTRAINT_DIMENSION_ORDER,
  ERROR_TEST_STATE,
  FRAMERATE_THRESHOLD,
  HEIGHT_DIMENSION,
  INITIAL_TEST_STATE,
  NETWORK_QUALITY_GOOD,
  NETWORK_QUALITY_LOW,
  PENDING_TEST_STATE,
  SEND_SETTINGS_FOR_HIGH_QUALITY,
  SEND_SETTINGS_FOR_LOW_QUALITY,
  SUCCESS_TEST_STATE,
  WIDTH_DIMENSION,
} from 'constants/video_conference';
import { useTranslation } from 'react-i18next';
import { useCallStatusState } from 'hooks/video_conference';
import usePrevious from 'hooks/video_conference/usePrevious';
import { getGuestType } from 'support/auth';
import useFramerateCounter from 'hooks/video_conference/useFramerateCounter';
import usePreCallState from 'hooks/video_conference/usePreCall';
import { useSettingsPersistence } from 'hooks/video_conference';
import useResizeObserver from 'use-resize-observer';

function useVideoTest(isActive) {
  const constraintOrder = CONSTRAINT_DIMENSION_ORDER[getGuestType().toUpperCase()];
  const { videoTrack } = useLocalParticipant() || {};
  const stream = useMemo(() => videoTrack && new MediaStream([videoTrack]), [videoTrack]);

  const [testState, setTestState] = useState(INITIAL_TEST_STATE);
  const [dimension, setDimension] = useState(WIDTH_DIMENSION);
  const [constraintIdx, setConstraintIdx] = useState(0);
  const [trackConstraints, setTrackConstraints] = useState({
    [dimension]: constraintOrder[0],
    frameRate: stream?.getVideoTracks()[0].getSettings().frameRate,
  });

  const callObject = useDaily();
  const { t } = useTranslation();

  const { videoRef } = useCallStatusState();
  const { setDebugWebcamProps } = usePreCallState();
  const persistSetting = useSettingsPersistence();
  const {
    startFramerateCount,
    stopFramerateCount,
    expectedFramerate,
    actualFramerate,
    resetFramerateCount,
    actualFramerateToPersist,
    expectedFramerateToPersist,
  } = useFramerateCounter(stream, videoRef);
  const previousState = usePrevious(testState);
  const { width: videoWidth, height: videoHeight } = useResizeObserver({
    ref: videoRef,
  });

  const constraintsFramerate = JSON.parse(localStorage.getItem('bandwidthSettings'))?.trackConstraints?.frameRate;
  const isCompleted = [SUCCESS_TEST_STATE, ERROR_TEST_STATE, ADJUSTED_TEST_STATE].includes(testState);
  const currentConstraint = constraintOrder[constraintIdx];
  const suggestions = [t('WebcamStep.webcam_issues.example_1'), t('WebcamStep.webcam_issues.example_2')];
  const problems = [
    {
      title: t('WebcamStep.webcam_issues'),
      items: [t('WebcamStep.webcam_issues.low_framerate'), t('WebcamStep.webcam_issues.lagging_framerate')],
    },
  ];

  const resetState = useCallback(() => {
    setTestState(INITIAL_TEST_STATE);
    resetFramerateCount();
    setConstraintIdx(0);
    setTrackConstraints({
      ...trackConstraints,
      [dimension]: constraintOrder[0],
    });
  }, [constraintOrder, dimension, trackConstraints, resetFramerateCount]);

  const startTest = useCallback(() => {
    if (isCompleted) resetState();
    setTestState(PENDING_TEST_STATE);
    startFramerateCount();
  }, [isCompleted, resetState, startFramerateCount]);

  const setQuality = useCallback(
    (stateType, networkQuality, settingsQuality) => {
      setTestState(stateType);
      persistSetting('videoQualityThreshold', networkQuality);
      callObject.setBandwidth({
        trackConstraints: { [dimension]: constraintOrder[0] },
      });
      callObject.updateSendSettings({ video: settingsQuality });
    },
    [callObject, dimension, constraintOrder, persistSetting]
  );

  useEffect(() => {
    if (videoWidth > videoHeight) {
      setDimension(WIDTH_DIMENSION);
    } else {
      setDimension(HEIGHT_DIMENSION);
    }
  }, [videoHeight, videoWidth]);

  useEffect(() => {
    const frameRateAcceptable = () => actualFramerate / expectedFramerate >= FRAMERATE_THRESHOLD;
    if (typeof actualFramerate === 'number' && typeof expectedFramerate === 'number' && !isCompleted) {
      stopFramerateCount();
      if (frameRateAcceptable()) {
        currentConstraint !== constraintOrder[0]
          ? setQuality(ADJUSTED_TEST_STATE, NETWORK_QUALITY_LOW, SEND_SETTINGS_FOR_LOW_QUALITY)
          : setQuality(SUCCESS_TEST_STATE, NETWORK_QUALITY_GOOD, SEND_SETTINGS_FOR_HIGH_QUALITY);
        resetFramerateCount();
      } else {
        if (constraintIdx + 1 < constraintOrder.length) {
          setConstraintIdx(constraintIdx + 1);
          setTrackConstraints({
            ...trackConstraints,
            [dimension]: constraintOrder[constraintIdx + 1],
          });
          startTest();
        } else {
          setQuality(ADJUSTED_TEST_STATE, NETWORK_QUALITY_LOW, SEND_SETTINGS_FOR_LOW_QUALITY);
        }
      }
    }
  }, [
    actualFramerate,
    expectedFramerate,
    isCompleted,
    constraintOrder,
    constraintIdx,
    startTest,
    trackConstraints,
    dimension,
    currentConstraint,
    resetFramerateCount,
    stopFramerateCount,
    actualFramerateToPersist,
    expectedFramerateToPersist,
    setQuality,
  ]);

  useEffect(() => {
    if (testState === PENDING_TEST_STATE) {
      callObject.setBandwidth({
        trackConstraints: { [dimension]: trackConstraints[dimension] },
      });
    }
  }, [trackConstraints, callObject, dimension, testState]);

  useEffect(() => {
    if (!isActive && testState === PENDING_TEST_STATE) {
      setTestState(previousState);
      stopFramerateCount();
    }
  }, [isActive, testState, previousState, stopFramerateCount]);

  useEffect(() => {
    const debugProps = [
      `Constraints-requested framerate: ${constraintsFramerate || 'N/A'}`,
      `Expected framerate: ${expectedFramerateToPersist}`,
      `Actual framerate: ${actualFramerateToPersist}`,
    ];
    setDebugWebcamProps(debugProps);
  }, [actualFramerateToPersist, constraintsFramerate, expectedFramerateToPersist, setDebugWebcamProps]);

  return {
    testState,
    startTest,
    suggestions,
    problems,
    setTestState,
    resetState,
    isCompleted,
  };
}

export default useVideoTest;
