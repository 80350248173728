import React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withData } from '../../support/page';
import HeaderBar from 'components/mainComponents/HeaderBar';
import TrainingSessionList from '../training_session/TrainingSessionList';
import { ActionButton } from 'components/mainComponents/buttons';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { withAuth } from 'contexts/AuthContext';

const CompanyRootPage = ({ company }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='page CompanyRootPage'>
      <HeaderBar />
      <main>
        <ActionButton icon='arrow--left' label={t('CompanyRootPage.go_back')} clickFn={() => navigate(-1)} type='secondary' />
        <h1>{company.name}</h1>
        <h2>{t('CompanyRootPage.training_sessions')}</h2>
        <TrainingSessionList training_sessions={company.training_sessions} />
      </main>
    </div>
  );
};

const query = gql`
  query company_root_page($id: ID!) {
    company(id: $id) {
      id
      name
      training_sessions {
        id
        name
        custom_name
        start_date
        image {
          url
        }
      }
    }
  }
`;

const withGraphqlData = graphql(query, {
  name: 'company',
  options: props => {
    return {
      variables: { id: props.auth?.guestInfo?.id },
    };
  },
});

// prettier-ignore
export default
  withAuth(
    withGraphqlData(
      withData('company')(
        CompanyRootPage, { loading: LoadingPagePlaceholder })));
