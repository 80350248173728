import clsx from 'clsx';
import PropTypes from 'prop-types';

import './CommonPagesLayout.scss';
import { HeaderBar } from 'components/v2/HeaderBar';

export function CommonPagesLayout(props) {
  const columnClassname = clsx('CommonPagesLayout__content-column', {
    'CommonPagesLayout__content-column--full-width': props.fullWidth,
  });

  return (
    <div className='CommonPagesLayout'>
      <HeaderBar />
      <div className='CommonPagesLayout__content'>
        <main className={columnClassname}>{props.children}</main>
      </div>
    </div>
  );
}

CommonPagesLayout.propTypes = {
  fullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

CommonPagesLayout.defaultProps = {
  fullWidth: false,
};
