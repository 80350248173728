import { useAudioTrack, useDaily, useLocalParticipant, useVideoTrack } from '@daily-co/daily-react';
import { useCallback } from 'react';
import useSettingsPersistence from 'hooks/video_conference/useSettingsPersistence';

function useDevicesToggle() {
  const callObject = useDaily();
  const localParticipant = useLocalParticipant();
  const localVideo = useVideoTrack(localParticipant?.session_id);
  const localAudio = useAudioTrack(localParticipant?.session_id);
  const isCameraMuted = localVideo?.isOff;
  const isMicMuted = localAudio?.isOff;

  const persistSetting = useSettingsPersistence();

  const toggleCamera = useCallback(() => {
    persistSetting('isCameraMuted', !isCameraMuted);
    callObject.setLocalVideo(isCameraMuted);
  }, [callObject, isCameraMuted, persistSetting]);

  const toggleMic = useCallback(() => {
    persistSetting('isMicMuted', !isMicMuted);
    callObject.setLocalAudio(isMicMuted);
  }, [callObject, isMicMuted, persistSetting]);

  return {
    toggleCamera,
    toggleMic,
    isCameraMuted,
    isMicMuted,
  };
}

export default useDevicesToggle;
