import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/mainComponents/Button';
import './UserButton.scss';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

export const UserButton = forwardRef(({ fullName, ...htmlAttributes }, ref) => {
  return (
    <Button size='sm' className='UserButton' {...htmlAttributes} ref={ref}>
      <span className='UserButton__fullname'>{fullName}</span>
      <SvgIcon name='user' className='UserButton__user-icon' />
      <SvgIcon name='caret-down' className='UserButton__icon' />
    </Button>
  );
});

UserButton.displayName = 'UserButton';

UserButton.propTypes = {
  fullName: PropTypes.string.isRequired,
};
