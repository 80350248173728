import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CornerCam from 'components/video_conference/CornerCam';
import AppPropTypes from '../../../propTypes';
import { RoomOptsContext } from '../../../contexts/video_conference';
import BasicTile from './BasicTile';
import { getUserImageUrl } from '../../../helpers/video_conference';
import TileContent from './TileContent';
import TileOverlay from './TileOverlay';
import normalTileStyles from '../../../scss/video_conference/NormalTile.module.scss';
import fullscreenTileStyles from '../../../scss/video_conference/FullscreenTile.module.scss';
import generalTileStyles from '../../../scss/video_conference/GeneralTile.module.scss';
import TileIcon from './TileIcon';

const styles = { ...generalTileStyles, ...normalTileStyles, ...fullscreenTileStyles };

const FullscreenTile = ({ tile, children, leftIcons, rightIcons }) => {
  const { usersData } = useContext(RoomOptsContext);

  const imageUrl = getUserImageUrl(usersData, `${tile.digiformaType}-${tile.digiformaId}`);

  return (
    <div className={styles.tileGrid}>
      <BasicTile
        className={styles.Tile}
        styles={styles}
        leftIcons={leftIcons}
        rightIcons={
          <>
            {rightIcons}
            {!tile.audio && <TileIcon icon='microphone--off' />}
          </>
        }
      >
        {children}
        <TileContent tile={tile} styles={styles} placeholderImageUrl={imageUrl} enableAdaptiveBackground={true} />

        {tile.video && <TileOverlay caption={tile.userName} styles={styles} />}

        <CornerCam tile={tile} className={styles.cornerVideo} />
      </BasicTile>
    </div>
  );
};

FullscreenTile.propTypes = {
  tile: AppPropTypes.tile.isRequired,
  children: PropTypes.node,
  leftIcons: PropTypes.node,
  rightIcons: PropTypes.node,
};

FullscreenTile.defaultProps = {
  children: undefined,
  leftIcons: undefined,
  rightIcons: undefined,
};

export default FullscreenTile;
