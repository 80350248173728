import { useSyncExternalStore } from 'react';

export const IS_EXTRANET_V2_ENABLED_KEY = 'isExtranetV2Enabled';

function subscribeToLocalStorage(listener) {
  window.addEventListener('storage', listener);
  return () => {
    window.removeEventListener('storage', listener);
  };
}

function getSnapShotOfIsExtranetV2Enabled() {
  return localStorage.getItem(IS_EXTRANET_V2_ENABLED_KEY) === 'true';
}

export function useIsExtranetV2EnabledLocally() {
  return useSyncExternalStore(subscribeToLocalStorage, getSnapShotOfIsExtranetV2Enabled);
}
