import React, { useEffect } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { ComponentsRenderer } from '@a-world-for-us/elearning_content_editor';
import { withAuth } from 'contexts/AuthContext.js';
import { getLocale } from 'translations/i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withData } from '../support/page';
import { editableComponentsQuery } from '../support/editableComponentsUtils';
import { ActionButton } from 'components/mainComponents/buttons';
import HeaderPublicPages from './HeaderPublicPages';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';

const AboutPage = ({ extranetConfig }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !extranetConfig ||
      (extranetConfig.editableComponents && extranetConfig.editableComponents.length === 0) ||
      extranetConfig.descriptionHtml
    )
      return;

    ComponentsRenderer.init({
      elementId: 'components-renderer',
      componentsPayload: extranetConfig.editableComponents,
      shouldTrackProgress: false,
      config: {
        language: getLocale(),
      },
    });
  }, [extranetConfig]);

  const renderGrapeContent = () => {
    const { descriptionHtml } = extranetConfig;
    if (!descriptionHtml) return;

    return (
      <section className='description'>
        <style>{descriptionHtml.css}</style>
        <div
          dangerouslySetInnerHTML={{
            __html: descriptionHtml.html,
          }}
        />
      </section>
    );
  };

  const renderEditableComponents = () => {
    if (extranetConfig.editableComponents === []) return;

    return (
      <section className='description'>
        <div id='components-renderer' />
      </section>
    );
  };

  return (
    <div className='page CompanyRootPage'>
      <HeaderPublicPages />
      <main>
        <ActionButton icon='arrow--left' label={t('AboutPage.go_back')} clickFn={() => navigate(-1)} type='secondary' />
        {extranetConfig.descriptionHtml ? renderGrapeContent() : renderEditableComponents()}
      </main>
    </div>
  );
};

const query = gql`
  query($userCode: String!) {
    extranetConfig(userCode: $userCode) {
      id
      descriptionHtml {
        html
        css
      }
      editableComponents ${editableComponentsQuery()}
    }
  }
`;

const withGraphqlData = graphql(query, {
  name: 'extranetConfig',
  options: props => {
    return {
      fetchPolicy: 'no-cache',
      variables: { userCode: props.auth?.userCode },
    };
  },
});

// prettier-ignore
export default
  withAuth(
    withGraphqlData(
      withData('extranetConfig')(
        AboutPage, { loading: LoadingPagePlaceholder })));
