import { CLOSE_MODAL, OPEN_MODAL } from 'actions/video_conference';
import React, { useReducer, useContext, useCallback } from 'react';

export const ModalStateContext = React.createContext();
export const ModalDispatchContext = React.createContext();

const reducer = (modal, { action, payload }) => {
  switch (action) {
    case OPEN_MODAL: {
      const { id } = payload;

      return { active: true, id };
    }

    case CLOSE_MODAL: {
      return { active: false, id: null };
    }

    default:
      return modal;
  }
};

export default reducer;

function useModal() {
  const [modal, dispatchModal] = useReducer(reducer, { active: false, id: null });

  const openModal = useCallback(
    id => {
      dispatchModal({
        action: OPEN_MODAL,
        payload: {
          id,
        },
      });
    },
    [dispatchModal]
  );

  const closeModal = useCallback(() => {
    dispatchModal({
      action: CLOSE_MODAL,
    });
  }, [dispatchModal]);

  return { modal, openModal, closeModal };
}

export function ModalProvider({ children }) {
  const { modal, openModal, closeModal } = useModal();

  const actions = {
    openModal,
    closeModal,
  };

  return (
    <ModalStateContext.Provider value={modal}>
      <ModalDispatchContext.Provider value={actions}>{children}</ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
}

export function useModalDispatch() {
  return useContext(ModalDispatchContext);
}

export function useModalState() {
  return useContext(ModalStateContext);
}
