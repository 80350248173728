import { ActionButton } from 'components/mainComponents/buttons';
import { DISPLAY_MODE_MODAL, VIDEOCALL_SETTINGS_MODAL } from 'constants/video_conference';
import { PARTICIPANTS_ELEMENT } from 'constants/video_conference/roomElements';
import { useAuth } from 'contexts/AuthContext';
import { RoomOptsContext } from 'contexts/video_conference';
import useAttendanceSigningListener from 'hooks/video_conference/useAttendanceSigningListener';
import useDisplayMode from 'hooks/video_conference/useDisplayMode';
import { useModalDispatch } from 'hooks/video_conference/useModal';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'scss/video_conference/RoomMenuMobileTray.module.scss';
import { GUEST_TYPE } from 'support/auth';

function RoomMenuMobileTray() {
  const { t } = useTranslation();

  const [displayMode] = useDisplayMode();
  const { openModal } = useModalDispatch();
  const { openRoomElement } = useRoomElementsDisplay();
  const { auth } = useAuth();
  const { initSigning } = useAttendanceSigningListener(true);
  const { isAttendanceSigningVisible } = useContext(RoomOptsContext);

  const guestType = auth?.guestInfo?.type;
  const hasPermissions = [GUEST_TYPE.USER, GUEST_TYPE.INSTRUCTOR].includes(guestType);
  const DISPLAY_MODE_BUTTON_ICONS = {
    FEATURED_SPEAKER: 'layout-spotlight',
    GRID: 'layout-tiled',
    AUTO: 'layout-auto',
  };

  const handleStartAttendanceSigningClick = () => initSigning();
  const handleOpenDisplayModalClick = () => openModal(DISPLAY_MODE_MODAL);
  const handleOpenSettingsModalClick = () => openModal(VIDEOCALL_SETTINGS_MODAL);
  const handleOpenParticipantsClick = () => openRoomElement(PARTICIPANTS_ELEMENT);

  return (
    <div className={styles.tray}>
      <div className={styles.buttonGroup}>
        <ActionButton
          type='secondary'
          label={t('Tray.participants')}
          clickFn={handleOpenParticipantsClick}
          icon='users'
          rightIcon='chevron--right'
          className={styles['button--mobile']}
        />

        <ActionButton
          type='secondary'
          label={t('Tray.settings')}
          clickFn={handleOpenSettingsModalClick}
          icon='gear'
          rightIcon='chevron--right'
          className={styles['button--mobile']}
        />
        <ActionButton
          type='secondary'
          label={t('Tray.display_mode')}
          icon={DISPLAY_MODE_BUTTON_ICONS[displayMode]}
          clickFn={handleOpenDisplayModalClick}
          className={styles['button--mobile']}
          rightIcon='chevron--right'
        />
        {hasPermissions && isAttendanceSigningVisible && (
          <ActionButton
            type='secondary'
            label={t('Tray.attendance_signing')}
            clickFn={handleStartAttendanceSigningClick}
            icon='sign'
            rightIcon='chevron--right'
            className={styles['button--mobile']}
          />
        )}
      </div>
    </div>
  );
}

export default RoomMenuMobileTray;
