const RGB_CONVERTER_REGEX = /^#?([a-fA-Z0-9]{2})([a-fA-Z0-9]{2})([a-fA-Z0-9]{2})([a-fA-Z0-9]{2})?$/;

export const computeNormalizedColorChannels = colorAsRGBString => {
  const matchRes = colorAsRGBString.match(RGB_CONVERTER_REGEX);
  if (matchRes) {
    return matchRes.slice(1).map(hexValue => normalizeHexValue(hexValue));
  } else {
    return [0.0, 0.0, 0.0];
  }
};

const normalizeHexValue = hexValueString => parseInt(hexValueString, 16) / 255.0;

export const computeLuminance = normalizedColorChannels => {
  const [r, g, b] = normalizedColorChannels.map(c => linearizeColorChannel(c));
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const linearizeColorChannel = colorChannel => {
  if (colorChannel <= 0.03928) {
    return colorChannel / 12.92;
  } else {
    return Math.pow((colorChannel + 0.055) / 1.055, 2.4);
  }
};

export const computePerceptualLightness = luminance => {
  // Send this function a luminance value between 0.0 and 1.0,
  // and it returns L* which is "perceptual lightness"

  if (luminance <= 216 / 24389) {
    // The CIE standard states 0.008856 but 216/24389 is the intent for 0.008856451679036
    return luminance * (24389 / 27); // The CIE standard states 903.3, but 24389/27 is the intent, making 903.296296296296296
  } else {
    return Math.pow(luminance, 1 / 3.0) * 116 - 16;
  }
};

export const computeContrastRatio = (textColorRGB, backgroundColorRGB) => {
  const textColorRGBLuminance = computeLuminance(textColorRGB);
  const backgroundColorRGBLuminance = computeLuminance(backgroundColorRGB);
  const l1 = Math.max(textColorRGBLuminance, backgroundColorRGBLuminance);
  const l2 = Math.min(textColorRGBLuminance, backgroundColorRGBLuminance);
  return (l1 + 0.05) / (l2 + 0.05);
};

export const computeNormalizedRGBtoHSL = ([r, g, b]) => {
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  let h = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  const l = (cmax + cmin) / 2;
  const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  return [h, s, l];
};

export const computeHSLtoNormalizedRGB = ([h, s, l]) => {
  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.min(r + m, 1);
  g = Math.min(g + m, 1);
  b = Math.min(b + m, 1);

  return [r, g, b];
};

export const lighten = ([h, s, l], percent) => {
  return [h, s, l * percent];
};

export const darken = ([h, s, l], percent) => {
  return [h, s * percent, l * (2 - percent)];
};

/**
 * Gets the red, green, and blue color channels from an hexadecimal color code.
 * @param {string} hexColorCode Hexadecimal color code.
 *  Leading `#` is optional and the code can either be 3 or 6 characters long.
 * @returns An RGB string of the same color.
 */
export function hexColorToRGB(hexColorCode) {
  const { r, g, b } = getColorChannels(hexColorCode);
  return `rgb(${r}, ${g}, ${b})`;
}

/**
 * Get either black or white as a contrasting color to the given color.
 * @param {string} hexColorCode Hexadecimal color code.
 * @returns The white or black color code that contrasts with the given color.
 */
export function getContrastHexColor(hexColorCode) {
  const { r: red, g: green, b: blue } = getColorChannels(hexColorCode);
  const ratio = red * 0.299 + green * 0.587 + blue * 0.114;
  return ratio >= 128 ? '#000000' : '#ffffff';
}

/**
 * Shade a color towards white or black, or blend two colors.
 *
 * Colors can be specified either as RGB with `rgb(...)` or `rgba(...)` strings,
 * or as hex codes with `#` + 3, 4, 6, or 8 characters. The returned format is
 * the one of the stop color if given, or the one of the start color otherwise.
 *
 * @param {number} percentage Shading or blending percentage.
 *  - [-1.0; 1.0] for shading a color, with negative values for darker shades.
 *  - [0.0; 1.0] for blending two colors. 0 is the starting color, 1 is the stop color.
 * @param {string} startColor Start color of the shading or blending, or to convert.
 * @param {string} stopColor Blending stop color.
 *   - Omit or provide a falsy value to shade `startColor`.
 *   - Provide a color value to blend from `startColor` to it.
 * @param {boolean} useLinear Use linear shading / blending instead of logarithmic.
 *  Defaults to `false`.
 * @returns The resulting color.
 *
 * @see https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js) (v4.1)
 */
export function shadeOrBlendColor(percentage, startColor, stopColor, useLinear) {
  if (
    typeof percentage != 'number' ||
    percentage < -1 ||
    percentage > 1 ||
    typeof startColor != 'string' ||
    (startColor[0] !== 'r' && startColor[0] !== '#') ||
    (stopColor && typeof stopColor != 'string')
  ) {
    return null;
  }

  const sanitizedStopColor = typeof stopColor == 'string' ? stopColor : undefined;
  const startColorChannels = getColorChannels(startColor);
  let stopColorChannels = undefined;

  if (sanitizedStopColor) {
    stopColorChannels = getColorChannels(sanitizedStopColor);
  } else if (percentage < 0) {
    stopColorChannels = { r: 0, g: 0, b: 0, a: -1 };
  } else {
    stopColorChannels = { r: 255, g: 255, b: 255, a: -1 };
  }

  if (!startColorChannels || !stopColorChannels) {
    return null;
  }

  const absolutePercentage = Math.abs(percentage);
  const inversedPercentage = 1 - absolutePercentage;

  // Compute the result R, G, and B channels
  const resultColorChannels = { r: 0, g: 0, b: 0, a: 0 };
  if (useLinear) {
    resultColorChannels.r = Math.round(inversedPercentage * startColorChannels.r + absolutePercentage * stopColorChannels.r);
    resultColorChannels.g = Math.round(inversedPercentage * startColorChannels.g + absolutePercentage * stopColorChannels.g);
    resultColorChannels.b = Math.round(inversedPercentage * startColorChannels.b + absolutePercentage * stopColorChannels.b);
  } else {
    resultColorChannels.r = Math.round(
      (inversedPercentage * startColorChannels.r ** 2 + absolutePercentage * stopColorChannels.r ** 2) ** 0.5
    );
    resultColorChannels.g = Math.round(
      (inversedPercentage * startColorChannels.g ** 2 + absolutePercentage * stopColorChannels.g ** 2) ** 0.5
    );
    resultColorChannels.b = Math.round(
      (inversedPercentage * startColorChannels.b ** 2 + absolutePercentage * stopColorChannels.b ** 2) ** 0.5
    );
  }

  // Compute the result alpha channel
  if (startColorChannels.a >= 0 && stopColorChannels.a >= 0) {
    resultColorChannels.a = startColorChannels.a * inversedPercentage + stopColorChannels.a * absolutePercentage;
  } else if (startColorChannels.a === -1 && stopColorChannels.a === -1) {
    resultColorChannels.a = 0;
  } else {
    resultColorChannels.a = startColorChannels.a >= 0 ? startColorChannels.a : stopColorChannels.a;
  }

  // Make the result color
  const hasDestinationColorAlpha = resultColorChannels.a > 0;
  if (sanitizedStopColor?.length > 9 || (!sanitizedStopColor && startColor.length > 9)) {
    return (
      `rgb${hasDestinationColorAlpha ? 'a' : ''}(` +
      `${resultColorChannels.r},${resultColorChannels.g},${resultColorChannels.b}` +
      (hasDestinationColorAlpha ? `,${Math.round(resultColorChannels.a * 1000) / 1000}` : '') +
      ')'
    );
  } else {
    return (
      '#' +
      (
        4294967296 +
        resultColorChannels.r * 16777216 +
        resultColorChannels.g * 65536 +
        resultColorChannels.b * 256 +
        (hasDestinationColorAlpha ? Math.round(resultColorChannels.a * 255) : 0)
      )
        .toString(16)
        .slice(1, hasDestinationColorAlpha ? undefined : -2)
    );
  }
}

/**
 * Get a color's channels information.
 * @param {string} color Color to rip.
 *  Format can be `rgb()`, `rgba()`, or hex code with `#` + 3, 4, 6, or 8 characters.
 * @returns The color information as an object with `r`, `g`, `b`, and `a` properties.
 *  Color channel values are in [0; 255].
 *  `a` is in [0.0; 1.0] or `-1` when there is no alpha channel.
 */
export function getColorChannels(color) {
  if (color.length > 9) {
    const parsedChannels = color.split(',');
    const [red, green, blue, alpha] = parsedChannels;

    if (parsedChannels.length < 3 || parsedChannels.length > 4) {
      return null;
    }

    return {
      r: parseInt(red[3] === 'a' ? red.slice(5) : red.slice(4)),
      g: parseInt(green),
      b: parseInt(blue),
      a: alpha ? parseFloat(alpha) : -1,
    };
  } else {
    if (color.length === 8 || color.length === 6 || color.length < 4) {
      return null;
    }

    let sanitizedColor = color;
    if (color.length < 6) {
      sanitizedColor =
        '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3] + (color.length > 4 ? color[4] + color[4] : '');
    }

    const decimalColor = parseInt(sanitizedColor.slice(1), 16);

    if (color.length === 9 || color.length === 5) {
      return {
        r: (decimalColor >> 24) & 255,
        g: (decimalColor >> 16) & 255,
        b: (decimalColor >> 8) & 255,
        a: Math.round((decimalColor & 255) / 0.255) / 1000,
      };
    } else {
      return {
        r: decimalColor >> 16,
        g: (decimalColor >> 8) & 255,
        b: decimalColor & 255,
        a: -1,
      };
    }
  }
}
