import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import fullCalendarFr from '@fullcalendar/core/locales/fr';
import fullCalendarDe from '@fullcalendar/core/locales/de';
import fullCalendarEs from '@fullcalendar/core/locales/es';
import { NativeDatePropTypes } from 'propTypes';
import { getLocale } from 'translations/i18n';
import { localTimeFormat } from 'support/date';

import { Paper } from 'components/mainComponents/Paper';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';
import { Heading } from 'components/mainComponents/Heading';
import { WidgetSubtitle } from 'components/mainComponents/WidgetSubtitle';
import { IconButton } from 'components/mainComponents/IconButton';

import './TrainingCalendarWidget.scss';

const currentLocale = getLocale();
const fullCalendarLocales = [fullCalendarFr, fullCalendarDe, fullCalendarEs];

export function TrainingCalendarWidget(props) {
  const { t } = useTranslation();
  const calendarRef = createRef();
  const [currentDate, setCurrentDate] = useState(new Date());

  return (
    <Paper className='TrainingCalendarWidget'>
      <div className='TrainingCalendarWidget__header'>
        <RoundedIcon icon='calendar--blank' background='secondary' />
        <div>
          <Heading level={4}>{t('TrainingCalendarWidget.title')}</Heading>
          <WidgetSubtitle>{t('TrainingCalendarWidget.subtitle')}</WidgetSubtitle>
        </div>
      </div>
      <div>
        <div className='TrainingCalendarWidget__calendar-controls'>
          <IconButton icon='chevron--left' onClick={navigateCalendarPrevious} />
          <span className='TrainingCalendarWidget__calendar-date'>{currentDateDisplay()}</span>
          <IconButton icon='chevron--right' onClick={navigateCalendarNext} />
        </div>
        <FullCalendar
          ref={calendarRef}
          locales={fullCalendarLocales}
          locale={currentLocale}
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          headerToolbar={false}
          events={props.events}
          eventContent={Event}
        />
      </div>
      {props.children && <div className='TrainingCalendarWidget__actions'>{props.children}</div>}
    </Paper>
  );

  function navigateCalendarPrevious() {
    if (!calendar()) return;

    calendar().prev();
    setCurrentDate(calendar().getDate());
  }

  function navigateCalendarNext() {
    if (!calendar()) return;

    calendar().next();
    setCurrentDate(calendar().getDate());
  }

  function calendar() {
    return calendarRef.current?.getApi();
  }

  function currentDateDisplay() {
    return currentDate.toLocaleDateString(currentLocale, { month: 'long', year: 'numeric' });
  }
}

TrainingCalendarWidget.propTypes = {
  children: PropTypes.node,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: NativeDatePropTypes,
      end: NativeDatePropTypes,
    })
  ),
};

TrainingCalendarWidget.defaultProps = {
  events: [],
};

function Event(props) {
  const timeString = `${localTimeFormat(props.event.start)} - ${localTimeFormat(props.event.end)}`;

  return (
    <div className='TrainingCalendarWidget__event' title={`${timeString} ${props.event.title}`}>
      {timeString}
    </div>
  );
}
