import React, { useEffect } from 'react';

import '../../../scss/AudioPlayer.scss';

function AudioPlayer({ url, onRead }) {
  useEffect(() => {
    onRead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='AudioPlayer'>
      <audio src={url} controls />
    </div>
  );
}

export default AudioPlayer;
