import React from 'react';

import Icon from 'components/mainComponents/Icon';
import chunk from '../chunk';
import { computeTotalScore, getItem, isActivityAvailable, logo, mustShowTotalScore, sameActivity, statusClassName } from '../module';
import Trigger, { hasTrigger } from './Trigger';
import { Status, StatusPendingScore } from './Status';
import { useTranslation } from 'react-i18next';

export default function ActivityPath({ module, selectedActivity, previewMode, onActivityChange }) {
  const pairedActivities = chunk(module.sequence, 2, 1);

  return (
    <ul className='all-activities'>
      <li>
        <StartBullet />
      </li>
      {pairedActivities.map(([activity, nextActivity], i) => {
        const item = getItem(activity, module);

        return (
          item && (
            <ActivityBlock
              key={`activity-${i}`}
              activity={activity}
              nextActivity={nextActivity}
              item={item}
              selectedActivity={selectedActivity}
              previewMode={previewMode}
              select={onActivityChange}
            />
          )
        );
      })}
      {(mustShowTotalScore(module) && (
        <li>
          <TotalScore score={computeTotalScore(module)} />
        </li>
      )) || (
        <li className='bullet-end'>
          <StartBullet />
        </li>
      )}
    </ul>
  );
}

function ActivityBlock(props) {
  const { previewMode = false, activity } = props;
  const shouldRenderTrigger = (previewMode || !isActivityAvailable(activity, previewMode)) && hasTrigger(activity);

  return (
    <>
      {shouldRenderTrigger && <Trigger activity={activity} />}
      <Activity {...props} />
    </>
  );
}

function Activity(props) {
  const { select, activity, item, selectedActivity, previewMode } = props;
  const selectedClass = sameActivity(selectedActivity, activity) ? 'selected' : '';
  const hasDuration = activity.duration_min && activity.duration_min !== '';

  return (
    <li className={`${statusClassName(activity, previewMode)} ${selectedClass}`} onClick={() => select(activity)}>
      <Status {...props} />
      <Icon icon={logo(activity, item)} />
      <span>{activity.name}</span>
      {hasDuration && <Duration duration={activity.duration_min} />}
    </li>
  );
}

const StartBullet = () => (
  <div className='status'>
    <svg viewBox='-1.2 -1.2 2.4 2.4'>
      <path d='M 1 0 A 1 1 0 1 1 -1 0' strokeWidth='0' fill='#becede' />
    </svg>
  </div>
);

function Duration({ duration }) {
  const { t } = useTranslation();

  return <div className='duration'>{t('duration_in_minutes', { duration })}</div>;
}

const TotalScore = ({ score }) => {
  const { t } = useTranslation();

  return (
    <>
      <StatusPendingScore score={score} />
      <span>{t('score')}</span>
    </>
  );
};
