import { useMutation } from '@apollo/client';
import { useFeedbackId } from 'components/video_conference/FeedbackContext';
import { MEASURING_IN_CALL } from 'constants/video_conference';
import { APPEND_VIDEO_CALL_QUALITY_SAMPLES_MUTATION } from 'mutations/video_conference';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import {
  dailyStatsToGraphqlVariables,
  EXTERNAL_SYNC_INTERVAL,
  getInitialRtcState,
  reduceQualityStats,
  rtcStatsActionTypes,
} from '../utils';

function useRtcStatsPersistance({ measuringMode, statsReadInterval }) {
  const { feedbackUUID, feedbackCreated } = useFeedbackId();
  const [qualitySamples, dispatchQualityStats] = useReducer(reduceQualityStats, {
    ...getInitialRtcState(),
    interval: statsReadInterval,
  });
  const [appendNewStats] = useMutation(APPEND_VIDEO_CALL_QUALITY_SAMPLES_MUTATION, {
    onCompleted: () => {
      dispatchQualityStats({ type: rtcStatsActionTypes.SAMPLES_SYNCED });
    },
  });
  // using ref to avoid putting qualitySamples as useEffect dependency
  const currentQualitySamplesRef = useRef(qualitySamples);
  const prevDailyFeedbackUUIDRef = useRef(feedbackUUID);

  useEffect(() => {
    currentQualitySamplesRef.current = qualitySamples;
  }, [qualitySamples]);

  useEffect(() => {
    if (feedbackUUID) {
      const prevFeedbackUUID = prevDailyFeedbackUUIDRef.current;
      if (feedbackUUID !== prevFeedbackUUID && prevFeedbackUUID) {
        appendNewStats({
          variables: {
            feedbackUUID: prevFeedbackUUID,
            qualitySamples: currentQualitySamplesRef.current,
          },
        });
        // force samples reset even if mutation fails (SAMPLES_SYNCED event would not be dispatched)
        dispatchQualityStats({ type: rtcStatsActionTypes.FEEDBACK_ID_CHANGED });
      }

      prevDailyFeedbackUUIDRef.current = feedbackUUID;
    }
  }, [appendNewStats, dispatchQualityStats, feedbackUUID]);

  useEffect(() => {
    if (measuringMode !== MEASURING_IN_CALL || !feedbackCreated) {
      return () => {};
    }

    const intervalId = setInterval(() => {
      appendNewStats({
        variables: {
          feedbackUUID,
          qualitySamples: {
            ...currentQualitySamplesRef.current,
            interval: statsReadInterval,
          },
        },
      });
    }, EXTERNAL_SYNC_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [appendNewStats, feedbackCreated, feedbackUUID, measuringMode, statsReadInterval]);

  return useCallback(
    dailyStats => {
      const samples = dailyStatsToGraphqlVariables(dailyStats);
      dispatchQualityStats({ type: rtcStatsActionTypes.SAMPLES_MEASURED, samples });
    },
    [dispatchQualityStats]
  );
}

export default useRtcStatsPersistance;
