import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from 'components/mainComponents/buttons';

import 'scss/mainComponents/customColorChooser/ColorPicker.scss';

const ColorPicker = ({ color = '#000000', notifyColorChangeFn, notifyColorSaveFn }) => {
  const { t } = useTranslation();
  const [currentColor, setCurrentColor] = useState(color);
  let input = document.getElementsByClassName('color-picker__input')[0];

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const handleColorChanged = e => {
    const newColor = e.target.value;
    notifyColorChangeFn(newColor);
    setCurrentColor(newColor);
  };

  const openInputColor = () => {
    input = document.getElementsByClassName('color-picker__input')[0];
    input.disabled = false;
    input.click();
  };

  const cancelColorChange = () => {
    notifyColorChangeFn(color);
    input.disabled = true;
  };

  const handleSave = () => {
    notifyColorSaveFn(currentColor);
    input.disabled = true;
  };

  return (
    <div className='color-picker'>
      <label className='color-picker__label'>
        <input
          type='color'
          name='color-picker-demo'
          className='color-picker__input'
          value={color}
          onChange={handleColorChanged}
          disabled={true}
        />
        <span>{currentColor}</span>
      </label>

      <ActionButton label={t('ColorPicker_v1.button.edit')} icon='pen' clickFn={openInputColor} />
      <ActionButton label={t('ColorPicker_v1.button.save')} clickFn={handleSave} icon='floppy-disk' />
      <ActionButton label={t('ColorPicker_v1.button.cancel')} clickFn={cancelColorChange} icon='cross' type='secondary' />
    </div>
  );
};

export default ColorPicker;
