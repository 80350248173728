import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/mainComponents/Button';
import { Checkbox } from 'components/mainComponents/CheckboxV2';
import { Heading } from 'components/mainComponents/Heading';
import { HorizontalRule } from 'components/mainComponents/HorizontalRule';
import { Paper } from 'components/mainComponents/Paper';
import { userFullname } from 'controllers/userController';
import { GUEST_TYPE } from 'support/auth';
import './RemoteSigningPanel.scss';

export function RemoteSigningPanel(props) {
  const { t } = useTranslation();

  const [selectedInstructors, setSelectedInstructors] = useState(new Set());
  const selectableInstructors = props.instructors.filter(instructor => instructor.instructor.email);
  const instructorsGlobalCheckboxState = checkboxState(selectedInstructors.size, selectableInstructors.length);

  const [selectedTrainees, setSelectedTrainees] = useState(new Set());
  const selectableTrainees = props.trainees.filter(trainee => trainee.trainee.email);
  const traineesGlobalCheckboxState = checkboxState(selectedTrainees.size, selectableTrainees.length);

  const signingLists = [
    {
      userType: GUEST_TYPE.INSTRUCTOR,
      users: props.instructors,
      selectableUsers: selectableInstructors,
      titleTranslationKey: 'TrainingSessionAttendanceTracking.remote_signing_instructors',
      globalCheckboxState: instructorsGlobalCheckboxState,
      selectedUsers: selectedInstructors,
      selectedUsersSetter: setSelectedInstructors,
      getUserData: instructor => instructor.instructor,
      getUserId: instructor => instructor.id,
      ignoreDropoutsWhenSelectAll: false,
    },
    {
      userType: GUEST_TYPE.TRAINEE,
      users: props.trainees,
      selectableUsers: selectableTrainees,
      titleTranslationKey: 'TrainingSessionAttendanceTracking.remote_signing_trainees',
      globalCheckboxState: traineesGlobalCheckboxState,
      selectedUsers: selectedTrainees,
      selectedUsersSetter: setSelectedTrainees,
      getUserData: trainee => trainee.trainee,
      getUserId: trainee => trainee.id,
      ignoreDropoutsWhenSelectAll: true,
    },
  ];

  const hasSomeRemoteSigning = signingLists.some(list => list.users.length > 0);

  useEffect(() => {
    signingLists.forEach(list => changeAllUsersSelection(list, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only when the session slot ID changes
  }, [props.sessionSlotId]);

  return (
    <Paper className='RemoteSigningPanel'>
      <Paper.Title>{t('TrainingSessionAttendanceTracking.remote_signing')}</Paper.Title>
      {!hasSomeRemoteSigning && (
        <p className='RemoteSigningPanel__empty-message'>{t('TrainingSessionAttendanceTracking.remote_signing_none')}</p>
      )}
      {hasSomeRemoteSigning && (
        <>
          {signingLists.map(list => {
            return (
              list.users?.length > 0 && (
                <Fragment key={list.userType}>
                  <Heading level={5} className='RemoteSigningPanel__users-list-title'>
                    {t(list.titleTranslationKey)}
                  </Heading>
                  <HorizontalRule tight />
                  <ul className='RemoteSigningPanel__users-list'>
                    <li>
                      <Checkbox
                        fullWidth
                        position='right'
                        ref={list.globalCheckbox}
                        checked={list.globalCheckboxState}
                        onCheckedChange={_ => changeAllUsersSelection(list)}
                        disabled={list.selectableUsers.length === 0}
                      >
                        {t('TrainingSessionAttendanceTracking.remote_signing_select_all')}
                      </Checkbox>
                    </li>
                    {list.users.map(user => {
                      const userData = list.getUserData(user);
                      const userId = list.getUserId(user);

                      return (
                        <li key={userId} className='RemoteSigningPanel__user'>
                          <Checkbox
                            fullWidth
                            position='right'
                            checked={list.selectedUsers.has(userId)}
                            onCheckedChange={checked => changeUserSelection(list, userId, checked)}
                            disabled={!userData.email}
                          >
                            <span className='RemoteSigningPanel__user-label'>
                              <span className='RemoteSigningPanel__user-name'>{userFullname(userData)}</span>
                              <span className='RemoteSigningPanel__user-email' title={userData.email}>
                                {userData.email ?? t('TrainingSessionAttendanceTracking.no_email')}
                              </span>
                            </span>
                          </Checkbox>
                        </li>
                      );
                    })}
                  </ul>
                </Fragment>
              )
            );
          })}

          <Paper.Actions>
            <Button disabled={selectedInstructors.size === 0 && selectedTrainees.size === 0} onClick={sendEmails}>
              {t('TrainingSessionAttendanceTracking.remote_signing_action')}
            </Button>
          </Paper.Actions>
        </>
      )}
    </Paper>
  );

  function sendEmails() {
    props.onSendEmails?.(Array.from(selectedInstructors), Array.from(selectedTrainees));
    setSelectedInstructors(new Set());
    setSelectedTrainees(new Set());
  }

  function changeAllUsersSelection(signingList, forcedSelection = undefined) {
    let shouldUnselectAll = signingList.selectedUsers.size > 0;

    if (forcedSelection === true) {
      shouldUnselectAll = false;
    } else if (forcedSelection === false) {
      shouldUnselectAll = true;
    }

    signingList.selectedUsersSetter(() => {
      if (shouldUnselectAll) {
        return new Set();
      }

      let allUsers = signingList.selectableUsers;
      if (signingList.ignoreDropoutsWhenSelectAll) {
        allUsers = allUsers.filter(user => !hasUserDroppedOut(user, props.sessionSlotId));
      }

      allUsers = allUsers.filter(user => !userHasSigned(props.signatures, signingList.getUserId(user), signingList.userType));

      return new Set(allUsers.map(user => signingList.getUserId(user)));
    });
  }
}

function changeUserSelection(signingList, id, isSelected) {
  if (isSelected) {
    signingList.selectedUsersSetter(previousSelection => {
      previousSelection.add(id);
      return new Set(previousSelection);
    });
  } else {
    signingList.selectedUsersSetter(previousSelection => {
      previousSelection.delete(id);
      return new Set(previousSelection);
    });
  }
}

function hasUserDroppedOut(user, sessionSlotId) {
  return !!user.abandons.find(dropOut => dropOut.trainingSessionSlots.map(slot => slot.id).includes(sessionSlotId));
}

function userHasSigned(signatures, userId, userType) {
  return !!signatures.find(signature => {
    if (userType === GUEST_TYPE.INSTRUCTOR) {
      return signature.trainingSessionInstructor?.id === userId;
    } else if (userType === GUEST_TYPE.TRAINEE) {
      return signature.customerTrainee?.id === userId;
    }

    return false;
  });
}

function checkboxState(currentSelectionSize, allSelectableOptionsSize) {
  if (currentSelectionSize === 0) {
    return false;
  } else if (currentSelectionSize < allSelectableOptionsSize) {
    return 'indeterminate';
  } else {
    return true;
  }
}
