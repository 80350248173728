import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './ActivityRenderer.scss';
import { LivestreamActivity } from './activities/LivestreamActivity';
import { EmailMarkerActivity } from './activities/EmailMarkerActivity';
import { UploadMarkerActivity } from './activities/UploadMarkerActivity';
import { EmbedActivity } from './activities/EmbedActivity';
import { HtmlDocActivity } from './activities/HtmlDocActivity';
import { ScormActivity } from './activities/ScormActivity';
import Document from 'components/document/Document';
import { Paper } from 'components/mainComponents/Paper';
import { AnswerEvaluation } from 'components/v2/evaluations';
import {
  DocumentPropType,
  EmailMarkerPropType,
  EmbedPropType,
  EvaluationPropType,
  HtmlDocPropType,
  LivestreamPropType,
  ModulePropType,
  ModuleSequenceItemPropType,
  ScormPropType,
  UploadMarkerPropType,
} from 'propTypes';

export function ActivityRenderer(props) {
  const { t } = useTranslation();
  const classnames = clsx('ActivityRenderer', `ActivityRenderer--${props.activity.resource_type.replaceAll('_', '-')}`);

  const isNameShown = props.activity.resource_type !== 'html_doc' && props.activity.resource_type !== 'evaluation';
  const isDescriptionShown = props.activity.description?.length > 0 && props.activity.resource_type !== 'html_doc';

  return (
    <Paper className={classnames} key={`activity-${props.activity.id}`}>
      {props.previewMode && <div className='PreviewBanner'>{t('ModulePage.preview_banner')}</div>}
      {isNameShown && <h2>{props.activity.name}</h2>}
      {isDescriptionShown && <div className='ActivityRenderer__description'>{props.activity.description}</div>}
      <ActivityContent {...props} />
    </Paper>
  );
}

ActivityRenderer.propTypes = {
  previewMode: PropTypes.bool,
  item: PropTypes.oneOfType([
    DocumentPropType,
    EmailMarkerPropType,
    EmbedPropType,
    EvaluationPropType,
    HtmlDocPropType,
    LivestreamPropType,
    ScormPropType,
    UploadMarkerPropType,
  ]),
  activity: ModuleSequenceItemPropType,
  module: ModulePropType,
  training_session: PropTypes.object,
  guestId: PropTypes.string,
};

function ActivityContent(props) {
  switch (props.activity.resource_type) {
    case 'evaluation':
      return (
        <AnswerEvaluation
          evaluationId={props.item.evaluation.id}
          traineeId={props.item.trainee_id}
          key={`evaluation-${props.item.id}`}
          previewMode={props.previewMode}
        />
      );
    case 'document':
      return <Document document={props.item} key={`document-${props.item.id}`} readForNotifications />;
    case 'embed':
      return <EmbedActivity embed={props.item} key={`embed-${props.item.id}`} />;
    case 'module_scorm':
      return <ScormActivity scorm={props.item} previewMode={props.previewMode} key={`module-scorm-${props.item.id}`} />;
    case 'livestream':
      return (
        <LivestreamActivity
          livestream={props.item}
          training_session_id={props.training_session.id}
          module_id={props.module.id}
          key={`livestream-${props.item.id}`}
        />
      );
    case 'html_doc':
      return (
        <HtmlDocActivity
          html_doc={props.item}
          traineeId={props.guestId}
          key={`html-doc-${props.item.id}`}
          previewMode={props.previewMode}
        />
      );
    case 'email_marker':
      return <EmailMarkerActivity activity={props.activity} email={props.item} key={`html-email-${props.item.id}`} />;
    case 'upload_marker':
      return <UploadMarkerActivity upload={props.item} trainee_id={props.guestId} key={`upload-marker-${props.item.id}`} />;
    default:
      throw new Error(`Unknown resource type: '${props.activity.resource_type}'`);
  }
}
