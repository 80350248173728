import React from 'react';
import { useTranslation } from 'react-i18next';

import 'scss/session/SigninComponent.scss';

export default function Connecting({ email }) {
  const { t } = useTranslation();

  return <div className='SigninCompoment__connecting-text'>{t('SigninPage.current_connection')}</div>;
}
