import React from 'react';

import { buildPatternStyle } from 'support/image';

export default function ModuleHeader({ module }) {
  return (
    <div className='hero' style={buildPatternStyle(module)}>
      <h1>{module.name}</h1>
      <div className='description'>{module.description}</div>
    </div>
  );
}
