import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import './TextArea.scss';

export const TextArea = forwardRef(({ className, autoSize, ...props }, ref) => {
  const textAreaClassName = clsx('TextArea', className);
  const TextareaElement = autoSize ? TextareaAutosize : 'textarea';

  return <TextareaElement className={textAreaClassName} {...props} ref={ref} />;
});

TextArea.displayName = 'TextArea';
TextArea.propTypes = {
  className: PropTypes.string,
  autoSize: PropTypes.bool,
};

TextArea.defaultProps = {
  autoSize: false,
};
