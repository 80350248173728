import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotificationsDispatch } from 'hooks/useNotifications';
import styles from '../../../scss/video_conference/Notification.module.scss';
import { ACTIVATE_NOTIFICATION, DISMISS_NOTIFICATION } from '../../../actions/video_conference';
import { NotificationPropType } from '../../../propTypes';

const DEFAULT_TIMEOUT = 5000;

const Notification = ({ notification }) => {
  const dispatch = useNotificationsDispatch();

  const handleClose = useCallback(
    () =>
      dispatch({
        action: DISMISS_NOTIFICATION,
        payload: { notificationId: notification.id },
      }),
    [dispatch, notification.id]
  );

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, notification.timeout || DEFAULT_TIMEOUT);
  }, [handleClose, notification.timeout]);

  const onActivate = notification.onActivate || (() => {});

  const handleClick = () => {
    dispatch({
      action: ACTIVATE_NOTIFICATION,
      payload: { notificationId: notification.id },
    });

    onActivate();

    handleClose();
  };

  const alertHeading = 'alert-heading';
  const alertParagraph = 'alert-paragraph';
  return (
    <div className={styles.notification} data-testid='alert-container'>
      <button className={styles.notificationInfo} type='button' onClick={handleClick} data-testid='button-main'>
        <h3 role={alertHeading}>{notification.title}</h3>
        <p role={alertParagraph}>{notification.text}</p>
      </button>

      <button className={styles.commentDelete} type='button' onClick={handleClose} data-testid='button-icon'>
        <i className='icon-close1' />
      </button>
    </div>
  );
};

Notification.propTypes = {
  notification: NotificationPropType.isRequired,
  onActivate: PropTypes.func,
};

Notification.defaultProps = {
  onActivate: undefined,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Notification;
