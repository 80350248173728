import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import './WidgetSubtitle.scss';

export function WidgetSubtitle({ as, className: additionalClassName = '', ...htmlAttributes }) {
  const Component = as ?? 'p';
  const className = clsx('WidgetSubtitle', additionalClassName);
  return <Component className={className} {...htmlAttributes} />;
}

WidgetSubtitle.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
