import React, { useState } from 'react';
import StatusBar from '../StatusBar';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import StatusBarToggleList from '../StatusBar/StatusBarToggleList';
import { STATUS_BAR_TYPES } from 'constants/video_conference';
import styles from '../../../scss/video_conference/StatusBars.module.scss';

function StatusBars({ statusBars }) {
  const [barsByType, setBarsByType] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    setBarsByType(_.groupBy(statusBars, statusBar => statusBar.type));
  }, [statusBars]);

  const renderSingleBars = () => {
    return (
      <>
        {_(barsByType)
          .pickBy((bars, key) => key === STATUS_BAR_TYPES.SINGLE || bars.length === 1)
          .map(bars => bars.map(statusBar => <StatusBar {...statusBar} key={statusBar.id} />))
          .value()}
      </>
    );
  };

  const renderMultipleBars = () => {
    return (
      <>
        {_(barsByType)
          .pickBy((bars, key) => key !== STATUS_BAR_TYPES.SINGLE && bars.length > 1)
          .map((bars, key) => <StatusBarToggleList statusBars={bars} toggleHeader={t(`StatusBars.${key}_message`)} key={key} />)
          .value()}
      </>
    );
  };

  return (
    <div className={styles.container}>
      {renderSingleBars()}
      {renderMultipleBars()}
    </div>
  );
}

StatusBars.propTypes = {
  statusBars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      emoji: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StatusBars;
