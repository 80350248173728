import { useCallback } from 'react';
import { getLocalConfig } from '../../../helpers/video_conference';
import useConfigPersistence from '../useConfigPersistence';

function useProblemIndicatorPersistence() {
  const persistConfig = useConfigPersistence();

  return useCallback(
    (problemIndicators, opts = { localOnly: false }) => {
      const { localOnly } = opts;

      const localConfig = { ...getLocalConfig(), ...problemIndicators };

      persistConfig(localConfig, { localOnly });
    },
    [persistConfig]
  );
}

export default useProblemIndicatorPersistence;
