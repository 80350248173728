import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { IconButton } from 'components/mainComponents/IconButton';
import { moveIntoFolder, openInNewTab } from '../DocumentUtils';
import { documentLogo } from 'support/logos';
import { DocumentPropTypes, FolderPropTypes } from './DocumentsSharedPropTypes';

const DocumentsGrid = ({ folders, setFolders, documents, setDocuments, setSelectedFolder, searchInput }) => {
  return (
    <div className='new-documents__grid'>
      {/* LIST FOLDERS */}
      {folders?.map(({ id, name, isShown }) => (
        <div key={id} className={gridTile(isShown)}>
          <div
            className='new-documents__grid-folder'
            data-folder-id={id}
            onClick={event => moveIntoFolder(event, folders, setFolders, documents, setSelectedFolder, setDocuments, searchInput)}
          >
            <IconButton icon='folder' size='2xl' />
            <p className='new-documents__grid-filename'>{name}</p>
          </div>
        </div>
      ))}
      {/* LIST DOCUMENTS, WHICH DOES NOT BELONG TO ANY FOLDER */}
      {documents?.map(document => (
        <div className={gridTile(document.isShown)} key={document.id} onClick={() => openInNewTab(document)}>
          <div className='new-documents__grid-document'>
            <IconButton icon={documentLogo(document)} size='2xl' className='new-documents__document-icon' />
            <p className='new-documents__grid-filename'>{document.filename}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const gridTile = isShown => clsx('new-documents__grid-binder', { 'new-documents__hidden': !isShown });

DocumentsGrid.propTypes = {
  folders: PropTypes.arrayOf(FolderPropTypes),
  documents: PropTypes.arrayOf(DocumentPropTypes),
  setFolders: PropTypes.func.isRequired,
  setDocuments: PropTypes.func.isRequired,
  setSelectedFolder: PropTypes.func.isRequired,
};

export default DocumentsGrid;
