import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './RadioGroup.scss';
import { forwardRef } from 'react';

function RadioGroupWithRef(props, ref) {
  const { children, ...otherProps } = props;
  const classname = clsx('RadioGroup', { 'RadioGroup--disabled': props.disabled });

  return (
    <RadixRadioGroup.Root ref={ref} {...otherProps} className={classname}>
      {children}
    </RadixRadioGroup.Root>
  );
}

export const RadioGroup = forwardRef(RadioGroupWithRef);

RadioGroup.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  loop: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
};

RadioGroup.Option = props => {
  const classname = clsx('RadioGroup__option', { 'RadioGroup__option--disabled': props.disabled });

  return (
    <label className={classname}>
      <RadixRadioGroup.Item value={props.value} disabled={props.disabled} className='RadioGroup__input'>
        <RadixRadioGroup.Indicator className='RadioGroup__indicator' />
      </RadixRadioGroup.Item>
      {props.children}
    </label>
  );
};
