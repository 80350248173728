import React from 'react';
import PropTypes from 'prop-types';

const TileOverlay = ({ caption, styles }) => <div className={styles.caption}>{caption}</div>;

TileOverlay.propTypes = {
  caption: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    featuredOverlay: PropTypes.string,
    overlay: PropTypes.string,
  }).isRequired,
};

export default TileOverlay;
