import { useApolloClient, useMutation } from '@apollo/client';
import { useState } from 'react';

import { CommentThreadDisplay } from './CommentThreadDisplay';
import { CREATE_COMMENT, DELETE_COMMENT } from './queries';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTitle,
} from 'components/mainComponents/AlertDialog';
import { useAuth } from 'contexts/AuthContext';
import {
  createSubcomment as updateApolloCacheAfterCreate,
  deleteComment as updateApolloCacheAfterDeleteThread,
  deleteSubcomment as updateApolloCacheAfterDeleteReply,
} from 'controllers/comment_controller.js';
import { CommentPropType, IDPropType } from 'propTypes';
import { GUEST_TYPE } from 'support/auth';
import { localDateFormat, localTimeFormat } from 'support/date';
import { Button } from 'components/mainComponents/Button';
import { useTranslation } from 'react-i18next';

export function CommentThread(props) {
  const { auth } = useAuth();
  const { t } = useTranslation();

  const apolloClient = useApolloClient();
  const [persistCreateComment] = useMutation(CREATE_COMMENT);
  const [persistDeleteComment] = useMutation(DELETE_COMMENT);

  const [commentBeingDeleted, setCommentBeingDeleted] = useState(undefined);

  return (
    <>
      <CommentThreadDisplay
        comment={props.comment}
        canUseActions={[GUEST_TYPE.ACADEMY, GUEST_TYPE.INSTRUCTOR].includes(auth.guestInfo.type)}
        onReply={createComment}
        onDelete={(comment, parentId) => setCommentBeingDeleted({ comment, parentId })}
      />
      <AlertDialog open={commentBeingDeleted !== undefined} onOpenChange={isOpen => !isOpen && setCommentBeingDeleted(undefined)}>
        <AlertDialogTitle>{t('CommentThread.DeleteConfirmationDialog.title')}</AlertDialogTitle>
        {commentBeingDeleted && (
          <AlertDialogContent>
            <p className='TrainingSessionFollowupPage__deleting-comment'>{commentBeingDeleted.comment.text}</p>
            <p>
              {t('CommentThread.DeleteConfirmationDialog.author', {
                author: commentBeingDeleted.comment.author,
                date: localDateFormat(commentBeingDeleted.comment.inserted_at),
                time: localTimeFormat(commentBeingDeleted.comment.inserted_at),
              })}
            </p>
          </AlertDialogContent>
        )}
        <AlertDialogActions>
          <AlertDialogCancel>
            <Button variant='text'>{t('CommentThread.DeleteConfirmationDialog.cancel')}</Button>
          </AlertDialogCancel>
          <AlertDialogAction>
            <Button onClick={() => deleteComment(commentBeingDeleted)}>{t('CommentThread.DeleteConfirmationDialog.confirm')}</Button>
          </AlertDialogAction>
        </AlertDialogActions>
      </AlertDialog>
    </>
  );

  async function createComment(text) {
    const {
      data: { create_comment: createdComment },
    } = await persistCreateComment({
      variables: {
        text,
        parentId: props.comment.id,
      },
    });

    updateApolloCacheAfterCreate(apolloClient, props.comment.id, createdComment);
  }

  async function deleteComment({ comment: commentBeingDeleted, parentId }) {
    await persistDeleteComment({
      variables: {
        id: commentBeingDeleted.id,
      },
    });

    if (parentId) {
      updateApolloCacheAfterDeleteReply(apolloClient, props.comment, commentBeingDeleted.id);
    } else {
      updateApolloCacheAfterDeleteThread(apolloClient, {
        ...commentBeingDeleted,
        training_session_id: props.trainingSessionId,
        module_id: props.moduleId,
      });
    }
  }
}

CommentThread.propTypes = {
  comment: CommentPropType,
  moduleId: IDPropType,
  traineeId: IDPropType,
  trainingSessionId: IDPropType,
};
