export function tileInfoFromParticipants({ lastRemoteSpeakerId, normalizedParticipants, displayModeComponent, selectedSpeakerId }) {
  const { tileListGenerator } = displayModeComponent;
  return tileListGenerator({ normalizedParticipants, lastRemoteSpeakerId, selectedSpeakerId });
}

export function compareParticipantsBySessionId(participant1, participant2) {
  if (participant1.sessionId > participant2.sessionId) {
    return 1;
  }
  if (participant1.sessionId === participant2.sessionId) {
    return 0;
  }
  return -1;
}

export const compareParticipantsBySpeakingActivity = activityTimestamps => (participant1, participant2) => {
  const participant1ActiveAt = activityTimestamps[participant1.sessionId];
  const participant2ActiveAt = activityTimestamps[participant2.sessionId];
  if (participant1ActiveAt && participant2ActiveAt) {
    return participant2ActiveAt - participant1ActiveAt;
  }
  if (participant1ActiveAt) {
    return -1;
  }
  if (participant2ActiveAt) {
    return 1;
  }
  return compareParticipantsBySessionId(participant1, participant2);
};

export function sortTilesByActivityTimestamp({ tiles, activityTimestamps, limit }) {
  const smallTilesLimit = limit > 0 ? limit - (limit < tiles.length) : limit;

  return (
    [...tiles]
      // take recent speakers
      .sort(compareParticipantsBySpeakingActivity(activityTimestamps))
      .slice(0, smallTilesLimit)
      // sort them by sessionId, not activity;
      .sort(compareParticipantsBySessionId)
  );
}
