export const CAMERA_ERROR = 'camera-error';
export const MIC_ERROR = 'mic-error';
export const NOT_ALLOWED_ERROR = 'not allowed';
export const CAM_IN_USE = 'cam-in-use';
export const MIC_IN_USE = 'mic-in-use';
export const CAM_MIC_IN_USE = 'cam-mic-in-use';
export const NOT_FOUND = 'not-found';
export const GRANTED_STATE = 'granted';

export const CALL_ERROR = 'error';
export const LOAD_ATTEMPT_FAILED = 'load-attempt-failed';
