import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ToggleContext = createContext();

function Toggle({ initialOn, children }) {
  const [isOn, setIsOn] = useState(initialOn);

  return <ToggleContext.Provider value={[isOn, setIsOn]}>{children}</ToggleContext.Provider>;
}

function useToggle() {
  const context = useContext(ToggleContext);
  if (context === undefined) {
    throw new Error('`useToggle` must be used within `Toggle`');
  }
  return context;
}

export function ToggleOn({ children }) {
  const [isOn] = useToggle();

  return isOn ? children : null;
}

export function ToggleOff({ children }) {
  const [isOn] = useToggle();

  return isOn ? null : children;
}

export function ToggleButton({ className }) {
  const [isOn, setIsOn] = useToggle();

  const handleClick = () => setIsOn(prevOn => !prevOn);

  const title = isOn ? 'Hide details' : 'Show details';
  return (
    <button
      type='button'
      onClick={handleClick}
      aria-controls='problems-status-bar'
      aria-label="Toggle problem's details"
      aria-expanded={isOn}
      className={className}
    >
      {title}
    </button>
  );
}

Toggle.propTypes = {
  initialOn: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Toggle.defaultProps = {
  initialOn: false,
};

ToggleButton.propTypes = {
  className: PropTypes.string,
};

ToggleButton.defaultProps = {
  className: '',
};

export default Toggle;
