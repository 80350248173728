import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLabel from './ButtonLabel';
import EntryButton from './EntryButton';
import EntryLinkButton from './EntryLinkButton';
import EntrySubmenuButton from './EntrySubmenuButton';
import Icon from '../Icon';
import IconButton from './IconButton';
import ShortcutButton from './ShortcutButton';

import '../../../scss/mainComponents/Menu.scss';

const Menu = ({ links = [], slotCount = 5, children }) => {
  const [node, setNode] = useState(null);
  const refCallback = useCallback(node => {
    setNode(node);
  }, []);
  const [panelIsOpen, setPanelIsOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (node && panelIsOpen && !node.contains(e.target)) {
        setPanelIsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [panelIsOpen, node]);

  const [shortcutLinks, submenuLinks] = splitLinkList(links, slotCount);
  const [panelHeap, setPanelHeap] = useState([submenuLinks]);
  const [headerHeap, setHeaderHeap] = useState([children]);
  const hasPanel = submenuLinks.length > 0;

  const handleTriggerClick = () => {
    // Clicking the burger button resets the submenu navigation
    //
    setPanelHeap([submenuLinks]);
    setHeaderHeap([children]);
    setPanelIsOpen(!panelIsOpen);
  };

  const handleEnterSubmenu = props => {
    const { submenu } = props;
    const header = <SubmenuPanelHeader {...props} notifyClickFn={handleLeaveSubmenu} />;
    setHeaderHeap([header, ...headerHeap]);
    setPanelHeap([submenu, ...panelHeap]);
  };

  const handleLeaveSubmenu = () => {
    // Functionnal fun !
    //
    // setPanelHeap(panelHeap) ?! It looks like doing nothing !
    //
    // Though we are leaving the current submenu, we DO NOT
    // unstack anything: when function handleLeaveSubmenu is built, it captures
    // panelHeap and headerHeap as they are BEFORE anything is stacked up.
    // Therefore this function essentially "resets" states with thoses captured values.
    //
    // When a submenu is entered states are changed (header and submenu are stacked), therefore
    // a rendering is triggered, hence this function is rebuilt in the new context, with
    // new "reset" values.
    //
    // :)
    //
    setPanelHeap(panelHeap);
    setHeaderHeap(headerHeap);
  };

  const currentEntries = panelHeap[0];
  const currentHeader = headerHeap[0];

  return (
    <div className='menu' ref={refCallback}>
      {hasPanel && <PanelTriggerButton clickFn={handleTriggerClick} />}
      <ShortcutsList links={shortcutLinks} />
      {panelIsOpen && <Panel entries={currentEntries} headerContent={currentHeader} notifyEnterSubMenuFn={handleEnterSubmenu} />}
    </div>
  );
};

const splitLinkList = (links, slotCount) => {
  const stickyLinksCount = links.filter(link => link.sticky).length;

  return links.reduce(
    ([shortcutLinks, menuLinks, remainingSlotsCount, remainingStickyLinksCount], link) => {
      if (link.submenu || !link.icon) {
        return [shortcutLinks, [...menuLinks, link], remainingSlotsCount, remainingStickyLinksCount];
      } else if (link.sticky) {
        if (remainingSlotsCount > 0) {
          return [[...shortcutLinks, link], menuLinks, remainingSlotsCount - 1, remainingStickyLinksCount - 1];
        } else {
          return [shortcutLinks, [...menuLinks, link], 0, remainingStickyLinksCount - 1];
        }
      } else {
        return [shortcutLinks, [...menuLinks, link], remainingSlotsCount, remainingStickyLinksCount];
      }
    },
    [[], [], slotCount, stickyLinksCount]
  );
};

const PanelTriggerButton = ({ clickFn }) => {
  const { t } = useTranslation();

  return <IconButton clickFn={clickFn} icon='burger--menu' label={t('Header.navigation_menu')} />;
};

const ShortcutsList = ({ links }) => {
  return links.map((props, idx) => <ShortcutButton {...props} key={idx} />);
};

const Panel = ({ entries, headerContent, notifyEnterSubMenuFn }) => {
  return (
    <div className={`menu__panel`}>
      <PanelHeader>{headerContent}</PanelHeader>
      <PanelEntries entries={entries} notifyEnterSubMenuFn={notifyEnterSubMenuFn} />
    </div>
  );
};

const PanelHeader = ({ children }) => {
  return <div className='menu__panel__header'>{children}</div>;
};

const PanelEntries = ({ entries, notifyEnterSubMenuFn }) => {
  return (
    <>
      {entries.map((entry, idx) => {
        return <PanelEntry key={idx} {...{ ...entry, notifyEnterSubMenuFn }} />;
      })}
    </>
  );
};

const PanelEntry = ({ notifyEnterSubMenuFn, ...entry }) => {
  if (!!entry.submenu) {
    return <EntrySubmenuButton {...entry} notifyEnterSubMenuFn={notifyEnterSubMenuFn} />;
  } else {
    return <EntryLinkButton {...entry} />;
  }
};

const SubmenuPanelHeader = ({ icon, label, content: Content, notifyClickFn }) => {
  const { t } = useTranslation();

  const closeLabel = `${t('Header.close_submenu')} ${label}`;

  return (
    <EntryButton icon={icon} label={closeLabel} clickFn={notifyClickFn}>
      {(Content && <Content />) || (label && <ButtonLabel label={label} />) || null}
      <Icon icon='cross' />
    </EntryButton>
  );
};

export default Menu;
