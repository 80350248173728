import React, { createContext, useContext, useMemo } from 'react';
import { tileInfoFromParticipants } from 'components/video_conference/CallBox/utils';
import useDisplayModeComponent from 'hooks/useDisplayModeComponent';
import useSelectedTile from '../SelectedTileContext';
import useCurrentSpeaker from '../CurrentSpeakerContext';
import useParticipants from '../ParticipantsContext';

function useTileInfoState() {
  const { lastRemoteSpeakerId } = useCurrentSpeaker();
  const displayModeComponent = useDisplayModeComponent();
  const normalizedParticipants = useParticipants();
  const [selectedSpeakerId] = useSelectedTile();

  const tileInfo = useMemo(
    () =>
      tileInfoFromParticipants({
        lastRemoteSpeakerId,
        selectedSpeakerId,
        normalizedParticipants,
        displayModeComponent,
      }),
    [displayModeComponent, lastRemoteSpeakerId, normalizedParticipants, selectedSpeakerId]
  );

  return tileInfo;
}

const TileInfoContext = createContext();

const TileInfoProvider = ({ children }) => {
  const tileInfo = useTileInfoState();

  return <TileInfoContext.Provider value={tileInfo}>{children}</TileInfoContext.Provider>;
};

export const useTileInfo = () => {
  const tileInfo = useContext(TileInfoContext);

  if (tileInfo === undefined) {
    throw new Error('useTileInfoState must be used within a TileInfoProvider');
  }

  return tileInfo;
};

export default TileInfoProvider;
