import React, { useState } from 'react';

import './FormInputScore.scss';
import { EvaluationQuestion, EvaluationQuestionLabel } from 'components/v2/evaluations/form_inputs/EvaluationQuestion';

export function FormInputScore(props) {
  const [answerGiven, setAnswerGiven] = useState(null);

  const onAnswer = value => {
    setAnswerGiven(value);
    props.onAnswer(props.input.id, value);
  };

  const data = JSON.parse(props.input.data);
  const answerDisplayed = props.answerSent !== null && props.answerSent !== undefined ? props.answerSent : answerGiven;

  return (
    <EvaluationQuestion>
      <EvaluationQuestionLabel>{data.question}</EvaluationQuestionLabel>
      <div className='scores'>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
          <button
            key={i}
            className={`v2__FormInputScore__digit ${answerDisplayed === i ? 'v2__FormInputScore__digit--selected' : ''}`}
            onClick={() => onAnswer(i)}
            disabled={props.correcting}
          >
            {i}
          </button>
        ))}
      </div>
    </EvaluationQuestion>
  );
}
