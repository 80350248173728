import React, { createContext, useCallback, useContext, useRef } from 'react';

export const ParentCallbackContext = createContext({});

const RELOAD_DELAY_MS = 2000;

export function ParentCallbackContextProvider({ children, callback }) {
  const requestId = useRef(0);

  const fireParentCallback = useCallback(() => {
    let localId = ++requestId.current;
    const fireLatestCallback = () => {
      if (requestId.current === localId) {
        callback();
      }
    };

    setTimeout(fireLatestCallback, RELOAD_DELAY_MS);
  }, [callback]);

  return <ParentCallbackContext.Provider value={{ fireParentCallback }}>{children}</ParentCallbackContext.Provider>;
}

export const useParentCallback = () => {
  const { fireParentCallback } = useContext(ParentCallbackContext);
  return fireParentCallback;
};
