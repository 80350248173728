import React from 'react';

import { Heading } from 'components/mainComponents/Heading';
import { AttendanceWidget } from 'components/v2/AttendancesPage/AttendanceWidget';
import { useTranslation } from 'react-i18next';
import './AttendancesList.scss';

export function AttendancesList({ pendingSlotsToSign, showSigningButtonInExtranet, tsId }) {
  const { t } = useTranslation();
  if (!showSigningButtonInExtranet || pendingSlotsToSign.length === 0) return <AttendancesEmptyList />;

  return (
    <div className='AttendancesList'>
      <div className='AttendancesList__subtitle'>{t('PendingSignings.attendances_to_sign')}</div>
      <ul className='AttendancesList_list'>
        {pendingSlotsToSign.map(slot => (
          <AttendanceWidget key={slot.id} slot={slot} tsId={tsId} />
        ))}
      </ul>
    </div>
  );
}

const AttendancesEmptyList = () => {
  const { t } = useTranslation();
  return (
    <Heading as='h2' level={3} className='AttendancesList__empty'>
      {t('TrainingSessionAttendancePage.no_dates_to_sign')}
    </Heading>
  );
};
