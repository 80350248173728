import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { ElearningSequenceWidget } from 'components/mainComponents/ElearningSequenceWidget';
import { Heading } from 'components/mainComponents/Heading';
import { isVisible } from 'components/module/module';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { ProgressCircle } from 'components/mainComponents/ProgressCircle';
import { withData, withRouteParams } from 'support/page';

import './ModulesPage.scss';

function ModulesPage({ trainingSession }) {
  const { t } = useTranslation();
  const { id: trainingSessionId, custom_name: customName, name, modules: allModules } = trainingSession;
  const modules = allModules.filter(isVisible);
  const totalScore = selectTotalScore(modules);

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${trainingSessionId}`}>{customName || name}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('ModulesPage.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <section>
        <Heading className='V2_ModulesPage__pageTitle' as='h1'>
          {t('ModulesPage.title')}
        </Heading>
        <div className='V2_ModulesPage__main'>
          {modules.length === 0 && <p className='V2_ModulesPage__empty-session'>{t('ModulesPage.session_is_empty')}</p>}
          {modules.length > 0 && (
            <>
              <div className='V2_ModulesPage__progress'>
                <ProgressCircle
                  color='secondary'
                  subtitle={t('ModulesPage.score')}
                  title={totalScore.formattedPercent}
                  value={totalScore.raw}
                />
              </div>
              <ul className='V2_ModulesPage__modules'>
                {modules.map(({ name, id, image, sequence }) => {
                  const progressValue =
                    sequence.length > 0 ? sequence.filter(sequence => sequence.finished).length / sequence.length : 1;

                  return (
                    <ElearningSequenceWidget
                      key={id}
                      as='li'
                      className='V2_ModulesPage__module'
                      moduleUrl={`/ts/${trainingSessionId}/module/${id}`}
                      imgSrc={image?.url}
                      accentColor='var(--colors-primary)'
                      title={name}
                      progressValue={progressValue}
                      finished={moduleIsFinished(sequence)}
                    />
                  );
                })}
              </ul>
            </>
          )}
        </div>
      </section>
    </LeftMenuLayout>
  );
}

function moduleIsFinished(moduleSequences) {
  return moduleSequences.every(({ finished }) => finished);
}

export function selectTotalScore(modules) {
  const scores = modules.flatMap(({ sequence }) => sequence.map(({ score }) => score));
  const { length } = scores;
  const totalScore = length ? scores.reduce((total, score) => total + score, 0) / length : 0;
  const raw = totalScore / 100;
  const formattedPercent = `${Math.round(raw * 100)}/100`;

  return { raw, formattedPercent };
}

const MODULE_LIST = gql`
  query ($id: ID!) {
    trainingSession(id: $id) {
      id
      name
      custom_name
      modules {
        id
        name
        image {
          filename
          url
          id
        }
        visibilityMode
        visibilityStartDate
        visibilityEndDate
        virtualroom
        sequence {
          finished
          available
          score
        }
      }
    }
  }
`;

const withGraphqlData = graphql(MODULE_LIST, {
  name: 'trainingSession',
  options: props => {
    return {
      variables: {
        id: props.params.training_session_id,
      },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData("trainingSession")(
        ModulesPage, { loading: PageLoader })));
