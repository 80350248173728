import React from 'react';
import { useTranslation } from 'react-i18next';
import StepContainer from '../../StepContainer';
import styles from 'scss/video_conference/WebcamStep.module.scss';
import PropTypes from 'prop-types';
import useVideoTest from 'hooks/video_conference/useVideoTest';
import {
  ADJUSTED_TEST_STATE,
  ERROR_TEST_STATE,
  PENDING_TEST_STATE,
  SUCCESS_TEST_STATE,
  VIDEO_TEST_STEP_MODAL,
} from 'constants/video_conference';
import PreCallModal from '../../PreCallModal';
import WebcamStepTitle from './WebcamStepTitle';
import WebcamStepResults from './WebcamStepResults';
import WebcamSettings from './WebcamSettings';
import WebcamStepTest from './WebcamStepTest';
import { ToggleSwitch } from 'components/mainComponents/ToggleSwitch';
import useDevicesToggle from 'hooks/video_conference/useDevicesToggle';
import clsx from 'clsx';

function WebcamStep({ isActive, onContinue, onCompleted }) {
  const { t } = useTranslation();

  const { isCameraMuted, toggleCamera } = useDevicesToggle();

  const handleContinue = () => {
    onCompleted(true);
    onContinue();
  };
  const { testState, startTest, problems, suggestions, setTestState, resetState, isCompleted } = useVideoTest(isActive);

  const isSuccess = testState === SUCCESS_TEST_STATE;
  const isAdjusted = testState === ADJUSTED_TEST_STATE;
  const isError = testState === ERROR_TEST_STATE || testState === ADJUSTED_TEST_STATE;
  const isTesting = testState === PENDING_TEST_STATE;

  const lastRowClasses = clsx(styles.row, styles['row--last']);
  const toggleSwitchClasses = clsx(styles.row, styles.switch);
  const lastRowButtonClass = clsx(styles.row, styles['row--last'], styles['row--singleButton']);

  const handleContinueWithCamOff = () => {
    setTestState(SUCCESS_TEST_STATE);
    onCompleted(true);
    onContinue();
  };

  return (
    isActive && (
      <StepContainer title={t('WebcamStep.title')}>
        <WebcamStepTest
          startTest={startTest}
          isCameraMuted={isCameraMuted}
          isTesting={isTesting}
          isCompleted={isCompleted}
          resetState={resetState}
          onCompleted={onCompleted}
          className={styles.row}
        />
        <ToggleSwitch
          label={!isCameraMuted ? t('PreCallSwitch.on') : t('PreCallSwitch.off')}
          state={isTesting ? 'disabled' : 'default'}
          onChange={toggleCamera}
          checked={!isCameraMuted}
          className={toggleSwitchClasses}
        />
        <WebcamSettings isCameraMuted={isCameraMuted} isCompleted={isCompleted} resetState={resetState} isTesting={isTesting} />
        <div className={styles.row}>
          <WebcamStepTitle
            isCameraMuted={isCameraMuted}
            isAdjusted={isAdjusted}
            isTesting={isTesting}
            isError={isError}
            isSuccess={isSuccess}
          />
        </div>
        <WebcamStepResults
          isCamOff={isCameraMuted}
          className={lastRowClasses}
          buttonClassName={lastRowButtonClass}
          onContinue={handleContinue}
          onContinueWithCamOff={handleContinueWithCamOff}
          isDisabled={isTesting}
          isCompleted={isCompleted}
        />
        <PreCallModal
          modalId={VIDEO_TEST_STEP_MODAL}
          title={t('WebcamStep.modal.title')}
          bottomText={t('WebcamStep.modal.bottom_text')}
          suggestions={suggestions}
          problems={problems}
        />
      </StepContainer>
    )
  );
}

export default WebcamStep;

WebcamStep.propTypes = {
  isActive: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

WebcamStep.defaultProps = {
  isActive: false,
};
