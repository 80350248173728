const streamMemo = {
  trackMemo: {},
  lastTrackByTile: {},

  get(videoTrack, tile) {
    const newMediaStream = vt => new MediaStream([vt]);

    if (tile) {
      if (videoTrack.readyState === 'ended') {
        return this.lastTrackByTile[tile.sessionId] || newMediaStream(videoTrack);
      }

      this.lastTrackByTile[tile.sessionId] = newMediaStream(videoTrack);
    }

    if (!this.trackMemo[videoTrack.id]) {
      this.trackMemo[videoTrack.id] = newMediaStream(videoTrack);
    }

    return this.trackMemo[videoTrack.id];
  },
};

export default streamMemo;
