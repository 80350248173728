import { useModalDispatch } from 'hooks/video_conference/useModal';
import React from 'react';
import styles from '../../../../scss/video_conference/PreCallModal.module.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Collapsible from 'components/video_conference/Collapsible';
import { COMMON_ISSUES } from './mockedData';
import useSearch from 'hooks/video_conference/useSearch';
import Modal from 'components/video_conference/Modal';
import Icon from 'components/mainComponents/Icon';

function PreCallModal({ modalId, title, bottomText, suggestions, onWebcamSettingsClick }) {
  const { t } = useTranslation();
  const { closeModal } = useModalDispatch();
  const { handleSearch, filteredData } = useSearch(COMMON_ISSUES);

  const handleWebcamSettingsClick = () => {
    closeModal();
    onWebcamSettingsClick();
  };

  return (
    <Modal modalId={modalId} title={title}>
      <div className={styles.content}>
        <ul className={styles.suggestions}>
          {suggestions.map(suggestion => (
            <li key={suggestion} className={styles.suggestion}>
              <Icon icon='arrow--right' className={styles.suggestion__icon} />
              {suggestion}
            </li>
          ))}
        </ul>
        <div className={styles.issues}>
          <div className={styles.issuesTop}>
            <h3 className={styles.issuesTitle}>{t('PreCallModal.common_issues')}</h3>
            <input className={styles.issuesSearch} type='search' onChange={handleSearch} placeholder={t('PreCallModal.search')} />
          </div>
          <div className={styles.issuesList}>
            {filteredData.map(({ title, content, id }) => (
              <Collapsible
                withIcons
                title={title}
                key={id}
                classes={{
                  mainClassName: styles.collapsible,
                  bottomClassName: styles.collapsibleBottom,
                  topClassName: styles.collapsibleTop,
                }}
              >
                {content}
              </Collapsible>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <p className={styles.bottomText}>{bottomText}</p>
        <div className={styles.buttons}>
          <button onClick={handleWebcamSettingsClick} className={styles.linkButton}>
            {t('PreCallModal.webcam_settings')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

PreCallModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bottomText: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onWebcamSettingsClick: PropTypes.func,
};

PreCallModal.defaultProps = {
  onWebcamSettingsClick: () => {},
};

export default PreCallModal;
