import React, { Suspense, useEffect } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ComponentsRenderer } from '@a-world-for-us/elearning_content_editor';
import { getLocale } from 'translations/i18n';
import Dates from '../dates/Dates';
import TrainingSessionHeader from './TrainingSessionHeader';
import { withData, withRouteParams } from '../../support/page';
import { buildPatternStyle } from '../../support/image';
import { useAuth } from 'contexts/AuthContext';
import { getConfig } from '../../config';
import { editableComponentsQuery } from '../../support/editableComponentsUtils';
import { UrlButton } from 'components/mainComponents/buttons';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { isVisible } from '../module/module';

import '../../scss/ConnectionTest.scss';
import '../../scss/TrainingSessionPage.scss';

const Map = React.lazy(() => import('./Map'));

const TrainingSessionPage = ({ training_session }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (!training_session.editableComponents.length) {
      return;
    }

    setTimeout(() => {
      ComponentsRenderer.init({
        elementId: 'components-renderer',
        componentsPayload: training_session.editableComponents,
        shouldTrackProgress: false,
        config: {
          language: getLocale(),
        },
      });
    }, 0);

    return () => {
      ComponentsRenderer.destroy();
    };
  }, [training_session.editableComponents]);

  const training_session_id = training_session.id;
  const hasPlace =
    training_session.remote === false &&
    training_session.address &&
    training_session.address !== '' &&
    training_session.show_place_in_extranet;
  const hasDates = training_session.start_date && training_session.show_dates_in_extranet;
  const hasPendingEvaluations = training_session.pending_evaluations.filter(pe => pe.pending === true).length > 0;
  const hasPendingSequences = training_session.modules.filter(moduleIsAvailable).length > 0;
  const hasPendingSignins = training_session.pendingSlotsToSign.length > 0 && training_session.show_signing_button_in_extranet;
  const hasDocuments = training_session.documents.length > 0 || training_session.addressed_documents.length > 0;
  const hasPendingActivities = hasPendingEvaluations || hasPendingSequences || hasPendingSignins || hasDocuments;
  const trainingSessionName = training_session.custom_name || training_session.name;

  const activitiesToCompleteTitle =
    auth?.guestInfo?.type === 'trainee'
      ? t('TrainingSessionPage.e-learning_activities_to_be_completed')
      : t('TrainingSessionPage.preview_e_learning_activities');

  return (
    <div className='page TrainingSessionPage'>
      <TrainingSessionHeader training_session_id={training_session.id} selected='root' />
      <main>
        <div className='hero' style={buildPatternStyle(training_session)}>
          <h1>{trainingSessionName}</h1>
        </div>

        {hasPendingActivities && (
          <section className='pendingLinks'>
            <h2>{t('TrainingSessionPage.reminder_tasks_to_complete')}</h2>
            <ul className='pendingLinks__list'>
              {hasPendingEvaluations && (
                <li className='pendingLinks__item'>
                  <UrlButton
                    icon='star'
                    label={t('TrainingSessionPage.evaluations_to_be_completed')}
                    url={`/ts/${training_session_id}/evaluations/`}
                    type='secondary'
                  />
                </li>
              )}
              {hasPendingSequences && (
                <li className='pendingLinks__item'>
                  <UrlButton
                    icon='steps--vertical'
                    label={activitiesToCompleteTitle}
                    url={`/ts/${training_session_id}/modules`}
                    type='secondary'
                  />
                </li>
              )}
              {hasPendingSignins && (
                <li className='pendingLinks__item'>
                  <UrlButton
                    icon='sign'
                    label={t('TrainingSessionPage.attendances_to_sign')}
                    url={`/ts/${training_session_id}/attendances/`}
                    type='secondary'
                  />
                </li>
              )}
              {hasDocuments && (
                <li className='pendingLinks__item'>
                  <UrlButton
                    icon='documents--blank'
                    label={t('TrainingSessionPage.personal_documents')}
                    url={`/ts/${training_session_id}/documents/`}
                    type='secondary'
                  />
                </li>
              )}
            </ul>
          </section>
        )}

        {training_session.extranet_description_html && (
          <section className='description'>
            <style>{training_session.extranet_description_html.css}</style>
            <div
              dangerouslySetInnerHTML={{
                __html: training_session.extranet_description_html.html,
              }}
            />
          </section>
        )}

        {training_session.editableComponents.length > 0 && (
          <section className='description'>
            <div id='components-renderer' />
          </section>
        )}

        {(hasPlace || hasDates) && (
          <div className={`place-and-date use-map-${training_session.use_map}`}>
            <div>
              {training_session.use_map && hasPlace && (
                <Suspense fallback={<div>{t('TrainingSessionPage.card_loading')}</div>}>
                  <Map useMap={training_session.use_map} place={training_session.address} placeInfo={training_session.place_info} />
                </Suspense>
              )}
              {hasDates && <Dates height={300} />}
            </div>
          </div>
        )}

        {training_session.show_rules_in_extranet && (
          <div className='training-session-rules'>
            <h2>{t('TrainingSessionPage.download_the_rules')}</h2>
            <UrlButton
              url={`${getConfig().serverRoot}/api/v1/${auth?.userCode}/custom_documents/generate_document/rules/${
                training_session.id
              }?locale=${getLocale()}`}
              blank={true}
              label={t('TrainingSessionPage.download_the_rules')}
              icon='download'
              type='secondary'
            />
          </div>
        )}
      </main>
    </div>
  );
};

function moduleIsAvailable(module) {
  const hasAvailableActivity = module.sequence.find(s => s.available && !s.finished);
  return isVisible(module) && hasAvailableActivity;
}

const trainingSessionQuery = gql`
  query training_session_page($id: ID!)  {
    training_session(id: $id) {
      id
      name
      custom_name
      address
      remote
      use_map
      show_rules_in_extranet
      show_place_in_extranet
      show_dates_in_extranet
      show_signing_button_in_extranet
      extranet_description_html {
        html
        css
      }
      editableComponents ${editableComponentsQuery()}
      image {
        url
      }
      place_info {
        latitude
        longitude
      }
      start_date
      end_date
      dates {
        start_time
        end_time
        date
        slot
        room {
          id
          name
        }
      }
      modules {
        id
        name
        visible
        visibilityMode
        visibilityStartDate
        visibilityEndDate
        virtualroom
        sequence {
          finished
          available
        }
      }
      pendingSlotsToSign {
        date
        slot
      }
      pending_evaluations {
        pending
      }
      documents {
        id
      }
      addressed_documents {
        id
      }
    }
  }
`;

const withGraphqlData = graphql(trainingSessionQuery, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: parseInt(props.params.id, 10) },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        TrainingSessionPage, { loading: LoadingPagePlaceholder })));
