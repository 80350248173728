import { useDaily } from '@daily-co/daily-react';
import { backgroundEffectOptions } from 'components/video_conference/Settings';
import { BACKGROUND_CUSTOM, BACKGROUND_IMAGE_TYPE, BACKGROUND_NONE } from 'constants/video_conference';
import { isSafari } from 'helpers/utils';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { VIDEO_CALL_CONFIG_QUERY } from 'queries/video_conference';
import _ from 'lodash';

function useBackgroundSettings() {
  const callObject = useDaily();
  const dataUriPrefixAndMimeType = 'data:image';
  const { data: { videoCallConfig } = {} } = useQuery(VIDEO_CALL_CONFIG_QUERY);
  const customImageUrl = videoCallConfig?.backgroundImageUrl || '';
  const [chosenBackground, setChosenBackground] = useState(BACKGROUND_NONE);

  // TEMPORARY: If there is backgroundImageUrl stored in localStorage,
  // then it should be deleted in favor of data from the backend and query caching.
  const storagedVideoCallConfig = JSON.parse(localStorage.getItem('videoCallConfig'));
  if (storagedVideoCallConfig?.backgroundImageUrl) {
    delete storagedVideoCallConfig['backgroundImageUrl'];
    localStorage.setItem('videoCallConfig', JSON.stringify(storagedVideoCallConfig));
  }

  useEffect(() => {
    (async () => {
      async function getChosenBackground() {
        const inputSettings = !isSafari() && (await callObject.getInputSettings());
        if (!_.isEmpty(inputSettings)) {
          const {
            video: {
              processor: { type, config },
            },
          } = inputSettings;
          if (config?.source?.includes(dataUriPrefixAndMimeType)) {
            return _.find(backgroundEffectOptions, { value: BACKGROUND_CUSTOM });
          }
          return backgroundEffectOptions.find(backgroundEffect =>
            type === BACKGROUND_IMAGE_TYPE ? backgroundEffect.config.source === config.source : backgroundEffect.type === type
          );
        }
      }

      const backgroundEffect = await getChosenBackground();
      setChosenBackground(backgroundEffect?.value || BACKGROUND_NONE);
    })();
  }, [callObject]);

  const chosenBackgroundConfig = backgroundEffectOptions.find(backgroundEffect => backgroundEffect.value === chosenBackground);
  const currentBackgroundSettings = backgroundEffectOptions.reduce((filteredBackgrounds, backgroundEffect) => {
    if (backgroundEffect.value === BACKGROUND_CUSTOM && customImageUrl === '') return filteredBackgrounds;
    if (backgroundEffect.value === BACKGROUND_CUSTOM)
      backgroundEffect = { ...backgroundEffect, thumbSrc: customImageUrl, config: { source: customImageUrl } };
    filteredBackgrounds.push(backgroundEffect);
    return filteredBackgrounds;
  }, []);
  return {
    chosenBackground,
    chosenBackgroundConfig,
    setChosenBackground,
    currentBackgroundSettings,
  };
}

export default useBackgroundSettings;
