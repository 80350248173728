import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router';
import ErrorPage from '../ErrorPage';
import RoomRenderer from './RoomRenderer';
import { useQuery } from '@apollo/client';
import { ACCOUNT_INFO_QUERY } from 'components/mainComponents/HeaderBar/AccountInfo';
import { useAuth } from 'contexts/AuthContext';
import { useThemeColorSetter } from 'components/mainComponents/CustomColorChooser/ThemeContext';

function NewVideoConferencePage() {
  const [error, setError] = useState();

  const { auth } = useAuth();

  const { data } = useQuery(ACCOUNT_INFO_QUERY, {
    variables: { userCode: auth?.userCode },
  });
  const { id: moduleId, training_session_id: trainingSessionId } = useParams();

  const setCustomColor = useThemeColorSetter();

  useEffect(() => {
    if (data?.accountInfos?.extranetCustomColor) {
      setCustomColor(data.accountInfos.extranetCustomColor);
    }
  }, [data, setCustomColor]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, { extra: { moduleId, trainingSessionId } });
    }
  }, [error, moduleId, trainingSessionId]);

  if (error) {
    return <ErrorPage error={error} />;
  }
  return <RoomRenderer onError={setError} />;
}

export default NewVideoConferencePage;
