import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CornerCam from 'components/video_conference/CornerCam';
import AppPropTypes from '../../../propTypes';
import { RoomOptsContext } from '../../../contexts/video_conference';
import BasicTile from './BasicTile';
import { getUserImageUrl } from '../../../helpers/video_conference';
import { getTileClassName } from './utils';
import normalTileStyles from '../../../scss/video_conference/NormalTile.module.scss';
import gridTileStyles from '../../../scss/video_conference/GridTile.module.scss';
import generalTileStyles from '../../../scss/video_conference/GeneralTile.module.scss';
import TileOverlay from './TileOverlay';
import TileContent from './TileContent';
import useStatusCaption from 'hooks/video_conference/useStatusCaption';
import { useDaily } from '@daily-co/daily-react';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';

const styles = { ...generalTileStyles, ...normalTileStyles, ...gridTileStyles };

const GridTile = React.memo(({ tile, style, className }) => {
  const { usersData } = useContext(RoomOptsContext);
  const callObject = useDaily();
  const { currentSpeakerId } = useCurrentSpeaker();

  const imageUrl = getUserImageUrl(usersData, `${tile.digiformaType}-${tile.digiformaId}`);

  const current = tile.sessionId === currentSpeakerId;

  const tileClassName = getTileClassName(styles, {
    ...tile,
    current: current && Object.keys(callObject.participants()).length > 2,
  });

  const statusCaption = useStatusCaption();
  const caption = (tile.local && statusCaption) || tile.userName;

  const shouldRenderOverlay = Boolean(statusCaption) || Boolean(tile.video);

  return (
    <BasicTile
      className={[className || '', tileClassName].join(' ')}
      style={style}
      styles={styles}
      leftIcons={tile.leftIcons}
      rightIcons={tile.rightIcons}
    >
      <CornerCam tile={tile} className={styles.cornerVideo} />

      <TileContent tile={tile} styles={styles} placeholderImageUrl={imageUrl} />

      {shouldRenderOverlay && <TileOverlay caption={caption} styles={styles} isFeatured={tile.local && Boolean(statusCaption)} />}
    </BasicTile>
  );
});

GridTile.propTypes = {
  tile: AppPropTypes.tile.isRequired,
  caption: PropTypes.string,
  leftIcons: PropTypes.node,
  rightIcons: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.string),
};

GridTile.defaultProps = {
  caption: undefined,
  leftIcons: undefined,
  rightIcons: undefined,
  style: undefined,
};

export default GridTile;
