export function getDokkuInstanceName() {
  return process.env.REACT_APP_DIGIFORMA_DOKKU_INSTANCE;
}

const devServerRoot = _href => {
  const domain = process.env.REACT_APP_EXTRANET_DEV_SERVER_ROOT || 'lvh.me';
  const proto = /https/.test(window.location.href) ? 'https' : 'http';
  const port = /https/.test(window.location.href) ? 4001 : 4000;

  return `${proto}://${domain}:${port}`;
};

const dev = {
  env: 'dev',
  serverRoot: devServerRoot(window.location.href),
  FILESTACK_API_KEY: 'AJEzjj51MQ0q2d1p2ZABSz',
  REACT_APP_S3_BUCKET: 'awfus-digiforma-dev',
  REACT_APP_PUSHER_KEY: '3d2477e252c75a6eea8f',
};

const prod = {
  env: 'prod',
  serverRoot: 'https://app.digiforma.com',
  FILESTACK_API_KEY: 'AaBTp6qRTCy2QvJFTQqXIz',
  REACT_APP_S3_BUCKET: 'awfus-digiforma',
  REACT_APP_PUSHER_KEY: '9a76537e643e20a5215e',
};

const dokku = {
  env: 'dokku',
  serverRoot: `https://${getDokkuInstanceName()}.digiformatest.com`,
  FILESTACK_API_KEY: 'AJEzjj51MQ0q2d1p2ZABSz',
  REACT_APP_S3_BUCKET: 'awfus-digiforma-dev',
  REACT_APP_PUSHER_KEY: '3d2477e252c75a6eea8f',
};

const staging = {
  env: 'prod',
  serverRoot: 'https://app-staging.digiforma.com',
  FILESTACK_API_KEY: 'AJEzjj51MQ0q2d1p2ZABSz',
  REACT_APP_S3_BUCKET: 'awfus-digiforma-staging',
  REACT_APP_PUSHER_KEY: '3d2477e252c75a6eea8f',
};

export function getConfig() {
  if (process.env.REACT_APP_EXTRANET_FORCE_DEV || /lvh\.me/.test(window.location.href)) {
    return dev;
  }
  if (/digiformatest\.net/.test(window.location.href)) {
    return dokku;
  }
  if (/digiformastaging\.(net|com)/.test(window.location.href)) {
    return staging;
  }
  return prod;
}
