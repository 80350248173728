import DailyIframe from '@daily-co/daily-js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DailyProvider } from '@daily-co/daily-react';

export const CallObjectContext = createContext();

function useCallObjectState() {
  const [callObject, setCallObject] = useState(null);

  useEffect(() => {
    // This condition is necessary because of React 18 duplicate useEffect invoking in strict mode
    const hasCallObject = DailyIframe.getCallInstance();
    if (hasCallObject) return;

    // Create Daily.co call object: https://docs.daily.co/reference#%EF%B8%8F-createcallobject
    // Note that this will execute HTTP requests to the API synchronously, that's why
    // the join operation is not waited on with any callback or promise
    const call = DailyIframe.createCallObject({ subscribeToTracksAutomatically: false });

    setCallObject(call);
  }, []);

  useEffect(() => {
    if (!callObject) return;
    const destroyCallObject = () => callObject.destroy();
    window.addEventListener('beforeunload', destroyCallObject);

    return () => {
      window.removeEventListener('beforeunload', destroyCallObject);
      destroyCallObject();
    };
  }, [callObject]);

  return callObject;
}

function CallObjectProvider({ children }) {
  const callObject = useCallObjectState();

  return <DailyProvider callObject={callObject}>{children}</DailyProvider>;
}

CallObjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useCallObject() {
  return useContext(CallObjectContext);
}

export default CallObjectProvider;
