import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts/AuthContext';

import 'scss/Error404Page.scss';
import { Illustration } from 'components/mainComponents/Illustration';
import { UrlButton } from 'components/mainComponents/buttons';

export default function Error404Page() {
  const { t } = useTranslation();
  const { auth } = useAuth();

  return (
    <div className='page Error404Page'>
      <Illustration name='robot-disappointed' size='lg' />

      <h1 className='h1'>{t('Error404Page.no_page_at_this_address')}</h1>

      {!auth?.isLoggedIn && (
        <div className='Error404Page__buttons'>
          <UrlButton label={t('Error404Page.connection')} url='/signin' icon='login' />
        </div>
      )}

      {auth?.isLoggedIn && (
        <div className='Error404Page__buttons'>
          <UrlButton icon='arrow--left' url='/' label={t('Error404Page.back_to_home')} type='secondary' />
        </div>
      )}
    </div>
  );
}
