import { getConfig } from '../../config';
import { getLocale } from 'translations/i18n';
const S3_BUCKET = getConfig().REACT_APP_S3_BUCKET;

export default function getFilestackDefaultOptions(storagePath) {
  return {
    lang: getLocale(),
    accept: ['.jpg', '.png'],
    imageMax: [1920, 1200],
    storeTo: {
      location: 'S3',
      container: S3_BUCKET,
      path: storagePath,
      region: 'eu-west-1',
    },
  };
}
