import { useRef, useState } from 'react';

function useFramerateCounter(stream, videoRef) {
  const interval = useRef(null);

  const [expectedFramerate, setExpectedFramerate] = useState(undefined);
  const [actualFramerate, setActualFramerate] = useState(undefined);

  // For debugging purposes
  const [expectedFramerateToPersist, setExpectedFramerateToPersist] = useState(undefined);
  const [actualFramerateToPersist, setActualFramerateToPersist] = useState(undefined);

  const resetFramerateCount = () => {
    setActualFramerate(undefined);
    setExpectedFramerate(undefined);
  };

  const startFramerateCount = () => {
    resetFramerateCount();
    if (stream) {
      const video = videoRef.current;
      video.srcObject = stream;

      const countFrames = () => video.webkitDecodedFrameCount || video.mozPaintedFrames;
      const expectedFramerate = stream.getVideoTracks()[0].getSettings().frameRate;
      let actualFramerate;
      let frameCount1 = countFrames();
      let frameCount2;

      interval.current = setInterval(() => {
        frameCount2 = countFrames();
        actualFramerate = frameCount2 - frameCount1 || undefined;
        frameCount1 = frameCount2;

        if (typeof actualFramerate === 'number' && typeof expectedFramerate === 'number') {
          setActualFramerate(actualFramerate);
          setExpectedFramerate(expectedFramerate);

          // For debugging purposes
          setActualFramerateToPersist(actualFramerate);
          setExpectedFramerateToPersist(expectedFramerate);
        }
      }, 1000);
    }
  };

  const stopFramerateCount = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  return {
    startFramerateCount,
    stopFramerateCount,
    expectedFramerate,
    actualFramerate,
    resetFramerateCount,
    expectedFramerateToPersist,
    actualFramerateToPersist,
  };
}

export default useFramerateCounter;
