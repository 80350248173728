import { gql } from '@apollo/client';
import { INITIAL_COMMENT_COUNT } from 'constants/video_conference';
import { COMMENT_FRAGMENT } from 'controllers/comment_controller';

export const VIDEO_CALL_CONFIG_MUTATION = gql`
  mutation ($videoCallConfig: VideoCallConfigInput!) {
    updateVideoCallConfig(videoCallConfig: $videoCallConfig) {
      id
      problemIndicatorVideo
      problemIndicatorNetwork
      audioDeviceId
      videoDeviceId
      outputDeviceId
      displayMode
      backgroundImageUrl
    }
  }
`;

export const UPDATE_GUEST_AVATAR_MUTATION = gql`
  mutation ($guestAvatarConfig: GuestAvatarConfigInput!) {
    updateGuestAvatarConfig(guestAvatarConfig: $guestAvatarConfig) {
      logo {
        id
        filename
        url
        size
        mime
      }
    }
  }
`;

export const JOIN_TEST_ROOM_MUTATION = gql`
  mutation {
    join_test_room {
      token
    }
  }
`;

// Legacy. Remove once not needed anymore - only used by the old video call UI
export const JOIN_ROOM_MUTATION = gql`
  mutation ($room_id: String!, $module_id: ID!) {
    join_videoconference_room(room_id: $room_id, module_id: $module_id)
  }
`;

export const JOIN_ROOM_WITH_DATA_MUTATION = gql`
  mutation($room_id: String!, $module_id: ID!)  {
    join_videoconference_room_with_data(room_id: $room_id, module_id: $module_id) {
      token
      enable_recording
      dailycoroom {
        id
        comments(pagination: {page: 0, size: ${INITIAL_COMMENT_COUNT}}) @connection(key: "comments") {
          ...comment
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const CREATE_RECORDING_MUTATION = gql`
  mutation ($recordingId: String!, $dailycoroomId: ID!, $recordingOwnerInput: RecordingOwnerInput!) {
    create_recording(recordingId: $recordingId, dailycoroomId: $dailycoroomId, recordingOwner: $recordingOwnerInput) {
      id
    }
  }
`;

export const APPEND_VIDEO_CALL_QUALITY_SAMPLES_MUTATION = gql`
  mutation ($feedbackUUID: String!, $qualitySamples: VideoCallQualitySamples!) {
    appendVideoCallQualitySamples(feedbackUuid: $feedbackUUID, qualitySamples: $qualitySamples)
  }
`;

export const RATE_VIDEO_CALL_QUALITY_MUTATION = gql`
  mutation ($feedbackUUID: String!, $rating: Int!) {
    rateVideoCallQuality(feedbackUuid: $feedbackUUID, rating: $rating)
  }
`;

export const CREATE_FEEDBACK_MUTATION = gql`
  mutation ($feedbackUUID: String!, $dailySessionId: String!) {
    createVideoCallFeedback(feedbackUuid: $feedbackUUID, dailySessionId: $dailySessionId)
  }
`;

export const CREATE_COMMENT_MUTATION = gql`
  mutation create_comment(
    $module_id: ID
    $text: String!
    $parent_id: ID
    $dailycoroom_id: ID
    $livestream_id: ID
    $training_session_id: ID
    $trainee_id: ID
  ) {
    create_comment(
      module_id: $module_id
      text: $text
      parent_id: $parent_id
      dailycoroom_id: $dailycoroom_id
      livestream_id: $livestream_id
      training_session_id: $training_session_id
      trainee_id: $trainee_id
    ) {
      ...comment
    }
  }

  ${COMMENT_FRAGMENT}
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation delete_comment($id: ID!) {
    delete_comment(id: $id)
  }
`;
