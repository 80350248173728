import React from 'react';
import PropTypes from 'prop-types';

import ParticipantAudio from './ParticipantAudio';

const AudioTracks = ({ audioTracks }) => (
  <>
    {audioTracks.map(track => (
      <ParticipantAudio key={track.sessionId} audioTrack={track.audioTrack} />
    ))}
  </>
);

AudioTracks.propTypes = {
  audioTracks: PropTypes.arrayOf(
    PropTypes.shape({
      sessionId: PropTypes.string,
      audioTrack: PropTypes.instanceOf(MediaStreamTrack),
    })
  ).isRequired,
};

export default AudioTracks;
