import React from 'react';
import styles from '../../../../scss/video_conference/StepContainer.module.scss';
import PropTypes from 'prop-types';

function StepContainer({ title, children }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      {children}
    </div>
  );
}

export default StepContainer;

StepContainer.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
