import { useModalState } from 'hooks/video_conference/useModal';
import React from 'react';
import styles from '../../../../scss/video_conference/PermissionsModal.module.scss';
import { useTranslation } from 'react-i18next';
import PermissionsImage from '../../images/permissions.png';
import Modal from 'components/video_conference/Modal';
import { PERMISSIONS_MODAL } from 'constants/video_conference';
import Icon from 'components/mainComponents/Icon';

function PermissionsModal() {
  const { t } = useTranslation();
  const modal = useModalState();

  const modalId = PERMISSIONS_MODAL;
  const isOpen = modal.id === modalId;

  return (
    isOpen && (
      <Modal modalId={modalId}>
        <div className={styles.top}>
          <h2 className={styles.title}>{t('PermissionsModal.title')}</h2>
        </div>
        <div className={styles.content}>
          <p>{t('PermissionsModal.first_step')}</p>
          <div className={styles.info}>
            <img src={PermissionsImage} alt='permissions' className={styles.info__img} />
          </div>
          <p>{t('PermissionsModal.second_step')}</p>
          <div className={styles.infoBox}>
            <Icon icon='checkmark--circle' />
            {t('PermissionsModal.third_step', { url: window.location.origin })}
          </div>
        </div>
      </Modal>
    )
  );
}

export default PermissionsModal;
