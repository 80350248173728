import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';

import { SvgIcon } from 'components/mainComponents/SvgIcon';
import styles from 'scss/video_conference/Rating.module.scss';

function Rating({ value, onChange, ratingUniqueId }) {
  const [hoveredValue, setHoveredValue] = useState(null);

  const possibleRatings = Array.from({ length: 5 }).map((_ignored, i) => i + 1);

  const getStarClasses = checked => clsx(styles.star, { [styles['star--checked']]: checked });

  return (
    <fieldset className={styles.ratingForm}>
      {possibleRatings.map(ratingValue => {
        const handleRatingMouseEnter = () => setHoveredValue(ratingValue);
        const handleRatingMouseLeave = () => setHoveredValue(null);
        const ratingInputId = `${ratingUniqueId}-${ratingValue}`;
        return (
          <React.Fragment key={ratingInputId}>
            <input
              value={ratingValue}
              id={ratingInputId}
              type='radio'
              checked={value ? value === ratingValue : undefined}
              name={ratingUniqueId}
              className={styles.visuallyHidden}
              onChange={onChange ? () => onChange(ratingValue) : undefined}
            />
            <label htmlFor={ratingInputId} onMouseEnter={handleRatingMouseEnter} onMouseLeave={handleRatingMouseLeave}>
              <span className={styles.visuallyHidden}>
                {ratingValue} {ratingValue === 1 ? 'Star' : 'Stars'}
              </span>
              <SvgIcon name='star--filled' className={getStarClasses(ratingValue <= (hoveredValue || value))} />
            </label>
          </React.Fragment>
        );
      })}
    </fieldset>
  );
}

Rating.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  ratingUniqueId: PropTypes.string,
};

Rating.defaultProps = {
  onChange: undefined,
  value: undefined,
  ratingUniqueId: _.uniqueId('rating'),
};

export default Rating;
