import React, { Component } from 'react';
import Particles from 'react-tsparticles';
import { withTranslation } from 'react-i18next';

import '../../scss/form_inputs/LiveEvaluationLobby.scss';

class LiveEvaluationLobby extends Component {
  state = {
    particlesSet: null,
  };

  componentDidUpdate() {
    particles(this.props.currentPage, this.state.particlesSet, newParticlesSet => {
      this.setState({ particlesSet: newParticlesSet });
    });
  }

  render() {
    const { members } = this.props;

    const style = {
      backgroundColor: colors(this.props.currentPage),
    };

    const waitingStyle = {
      display: this.props.currentPage === 0 ? 'block' : 'none',
    };

    return (
      <div className='LiveEvaluationLobby' style={style}>
        {this.state.particlesSet === 0 && <Particles className='Particles' params={lobbyParticlesJson} />}
        {this.state.particlesSet === 1 && <Particles className='Particles' params={particlesJson} />}

        <div style={waitingStyle}>
          <p className='waiting-message'>{this.props.t('LiveEvaluationLobby.wait_for_everyone')}</p>
          <ul className='no-bullet'>
            {members.map(member => (
              <li className='trainee connected' data-id={`trainee_${member.id}`} key={member.id}>
                <span className='name'>{member.name}</span>
                <span className='status'>
                  <span className='connected'>{this.props.t('LiveEvaluationLobby.connected')}</span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LiveEvaluationLobby);

function colors(index) {
  if (index === 0) return '#F9F9F9';

  const choices = ['#8bc3db', '#e392a4', '#56c8c4', '#e3c492', '#92e396', '#cc92e3', '#a9b4de', '#e9df8d'];
  return choices[index % choices.length];
}

function particles(currentPage, particlesSet, onParticlesSetChanged) {
  setTimeout(() => {
    if (currentPage > 0 && particlesSet !== 1) {
      onParticlesSetChanged(1);
    } else if (particlesSet !== 0 && currentPage === 0) {
      onParticlesSetChanged(0);
    }
  }, 300);
}

let particlesJson = {
  particles: {
    number: {
      value: 6,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: '#ffffff',
    },
    shape: {
      type: 'polygon',
      stroke: {
        width: 0,
        color: '#000',
      },
      polygon: {
        nb_sides: 6,
      },
      image: {
        src: 'img/github.svg',
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.04,
      random: true,
      anim: {
        enable: false,
        speed: 0.5,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 500,
      random: false,
      anim: {
        enable: true,
        speed: 12.173348482389219,
        size_min: 8.927122220418761,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
      distance: 400.63781540212045,
      color: '#040404',
      opacity: 0.2564082018573571,
      width: 20,
    },
    move: {
      enable: true,
      speed: 2,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false,
        mode: 'grab',
      },
      onclick: {
        enable: false,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};

let lobbyParticlesJson = {
  particles: {
    number: {
      value: 20,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: '#338cdb',
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: '#338cdb',
      },
      polygon: {
        nb_sides: 5,
      },
      image: {
        src: 'img/github.svg',
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.1,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.01,
        sync: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: '#338cdb',
      opacity: 0.2,
      width: 1,
    },
    move: {
      enable: true,
      speed: 6,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false,
        mode: 'repulse',
      },
      onclick: {
        enable: false,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};
