import React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';
import { withData, withRouteParams } from 'support/page';

import { Calendar } from 'components/v2/Calendar';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Heading } from 'components/mainComponents/Heading';
import { PageLoader } from 'components/v2/layouts/PageLoader';

function CalendarPage({ trainingSession }) {
  const { t } = useTranslation();
  const trainingSessionName = trainingSession.custom_name || trainingSession.name;

  const events = trainingSession.training_session_slots.map(slot => {
    return {
      name: slot.subsession.name,
      modality: t(`Calendar.${slot.subsession.modality}`),
      instructors: slot.training_session_instructors,
      start: new Date(`${slot.date}T${slot.start_time}`),
      end: new Date(`${slot.date}T${slot.end_time}`),
    };
  });

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${trainingSession.id}`}>{trainingSessionName}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('DatesPage.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <section className='V2_CalendarPage'>
        <Heading level={1}>{trainingSessionName}</Heading>
        <Calendar trainingSession={trainingSession} events={events} />
      </section>
    </LeftMenuLayout>
  );
}

const TRAINING_SESSION_NAME_QUERY = gql`
  query training_session_name($id: ID!) {
    trainingSession(id: $id) {
      id
      name
      custom_name

      start_date
      end_date
      dates_webcal_url
      dates_google_url
      subsessions {
        id
      }
      training_session_slots {
        start_time
        end_time
        slot
        date
        training_session_instructors {
          instructor {
            id
            firstname
            lastname
          }
        }
        subsession {
          id
          name
          modality
        }
        room {
          id
          name
        }
      }
      instructors {
        id
        firstname
        lastname
      }
    }
  }
`;

const withGraphqlData = graphql(TRAINING_SESSION_NAME_QUERY, {
  name: 'trainingSession',
  options: props => {
    return {
      variables: { id: props.params.training_session_id },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('trainingSession')(
        CalendarPage, { loading: PageLoader })));
