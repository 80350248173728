import './PageLoader.scss';

import { LoadingIndicator } from 'components/mainComponents/LoadingIndicator';

export function PageLoader() {
  return (
    <div role='alert' className='PageLoader'>
      <LoadingIndicator />
    </div>
  );
}
