import { useScreenShare } from '@daily-co/daily-react';
import FeaturedSpeakerDisplayMode from 'components/video_conference/CallBox/DisplayModes/FeaturedSpeakerDisplayMode';
import GridDisplayMode from 'components/video_conference/CallBox/DisplayModes/GridDisplayMode';
import { DISPLAY_MODE_FEATURED_SPEAKER, DISPLAY_MODE_GRID } from 'constants/video_conference';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';
import useParticipants from 'contexts/video_conference/ParticipantsContext';
import useDisplayMode from 'hooks/video_conference/useDisplayMode';
import _ from 'lodash';

function useDisplayModeComponent() {
  const { currentSpeakerId } = useCurrentSpeaker();
  const participants = useParticipants();
  const { screens } = useScreenShare();

  const [displayMode] = useDisplayMode();

  switch (displayMode) {
    case DISPLAY_MODE_FEATURED_SPEAKER:
      return FeaturedSpeakerDisplayMode;

    case DISPLAY_MODE_GRID:
      return GridDisplayMode;

    default: {
      // DISPLAY_MODE_AUTO

      const isLocalSpeaker = _.find(participants, participant => participant.local).sessionId === currentSpeakerId;

      if (isLocalSpeaker && screens.length === 0) {
        return GridDisplayMode;
      }

      return FeaturedSpeakerDisplayMode;
    }
  }
}

export default useDisplayModeComponent;
