import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

function useSelectedTileState() {
  return useState(null);
}

const SelectedTileContext = createContext();

export const SelectedTileProvider = ({ children }) => {
  const selectedTileState = useSelectedTileState();

  return <SelectedTileContext.Provider value={selectedTileState}>{children}</SelectedTileContext.Provider>;
};

function useSelectedTile() {
  const selectedTileState = useContext(SelectedTileContext);

  if (selectedTileState === undefined) {
    throw new Error('useSelectedTile must be used within a SelectedTileProvider');
  }

  return selectedTileState;
}

SelectedTileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default useSelectedTile;
