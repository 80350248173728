import { useEffect } from 'react';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

function useTrainingSessionConnectionMonitor(trainingSessionId) {
  const [logConnection] = useMutation(CONNECTION_LOG);

  useEffect(() => {
    const doLogging = () => {
      logConnection({ variables: { training_session_id: trainingSessionId } });
    };
    doLogging();

    const interval = setInterval(() => {
      doLogging();
    }, 30000);
    return () => clearInterval(interval);
  }, [trainingSessionId, logConnection]);
}

const CONNECTION_LOG = gql`
  mutation connection_log($training_session_id: ID!) {
    connection_log(training_session_id: $training_session_id)
  }
`;

export default useTrainingSessionConnectionMonitor;
