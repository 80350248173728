import { getUserImageUrl } from 'helpers/video_conference';
import { GUEST_TYPE, guestTypeFromTypename } from 'support/auth';

const PARTICIPANT_TYPES = [GUEST_TYPE.TRAINEE, GUEST_TYPE.INSTRUCTOR, GUEST_TYPE.USER, GUEST_TYPE.ACADEMY];

export function normalizeParticipant(dailyParticipant) {
  const [digiformaId] = dailyParticipant.user_id.match(/[0-9]+/g);
  const [digiformaType] = dailyParticipant.user_id.match(/[^0-9^-]+/g);

  let { screenVideoTrack, audioTrack, videoTrack } = dailyParticipant;

  if (!(audioTrack instanceof MediaStreamTrack)) {
    audioTrack = undefined;
  }

  if (!(videoTrack instanceof MediaStreamTrack)) {
    videoTrack = undefined;
  }

  if (!(screenVideoTrack instanceof MediaStreamTrack)) {
    screenVideoTrack = undefined;
  }

  return {
    userName: dailyParticipant.user_name,
    digiformaId,
    digiformaType,
    local: dailyParticipant.local,
    sessionId: dailyParticipant.session_id,
    audio: dailyParticipant.audio,
    video: dailyParticipant.video,
    screen: dailyParticipant.screen,
    audioTrack,
    videoTrack,
    screenVideoTrack,
  };
}

const hasProperType = (typename, digiformaType, guestType) => {
  if (typename === guestTypeFromTypename(guestType)) {
    return digiformaType === guestType;
  }
};

export function participantContent(dailyParticipant, usersData) {
  const user = usersData.find(({ id, __typename }) => {
    if (dailyParticipant.digiformaId !== id) return false;
    return PARTICIPANT_TYPES.some(participantType => hasProperType(__typename, dailyParticipant.digiformaType, participantType));
  });

  const userId = `${dailyParticipant.digiformaType}-${dailyParticipant.digiformaId}`;
  const imageUrl = getUserImageUrl(usersData, userId);

  const bio = user && user.bio;

  return { imageUrl, bio };
}
