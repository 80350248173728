import { getConfig, getDokkuInstanceName } from 'config';

export function getDomain() {
  switch (getConfig().env) {
    case 'dev':
      return `${process.env.REACT_APP_EXTRANET_DEV_SERVER_ROOT || 'lvh.me'}:${
        window.location.href.startsWith('https://') ? 4001 : 4000
      }`;

    case 'dokku':
      return `${getDokkuInstanceName()}.digiformatest.com`;

    case 'prod':
      return 'app.digiforma.com';

    default:
      throw new Error('Unknown value in getConfig().env: ', getConfig().env);
  }
}
