import { ASPECT_RATIO_LIMITS, GRID_GAP, MIN_TILE_WIDTH } from 'constants/video_conference';

export function clampWidthToAspectRatio(width, height) {
  const aspectRatio = width / height;
  let clampedWidth = width;

  if (aspectRatio < ASPECT_RATIO_LIMITS.MIN) {
    clampedWidth = height * ASPECT_RATIO_LIMITS.MIN;
  } else if (aspectRatio > ASPECT_RATIO_LIMITS.MAX) {
    clampedWidth = height * ASPECT_RATIO_LIMITS.MAX;
  }

  return clampedWidth;
}

export function calculateSingleTileDimension({ numberOfTiles, availableSpace, gap = GRID_GAP }) {
  const availableSpaceWithoutGap = availableSpace - gap * (numberOfTiles - 1);
  const tileDimension = Math.floor(availableSpaceWithoutGap / numberOfTiles);
  return Math.max(MIN_TILE_WIDTH, tileDimension);
}

export function createStyleObject(width, height) {
  return {
    width: `${width}px`,
    height: `${height}px`,
  };
}
