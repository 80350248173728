import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderPublicPages from 'components/HeaderPublicPages';
import SigninComponent from '../SigninComponent';
import IncomingEmailNotice from '../SigninComponent/IncomingEmailNotice';

import 'scss/session/SigninPage.scss';
import { Illustration } from 'components/mainComponents/Illustration';

export default function SigninPage() {
  const [sent, setSent] = useState(false);
  const { t } = useTranslation();

  return (
    <div className='page SigninPage'>
      <HeaderPublicPages />
      <div className='SigninPage__content'>
        <div className='h1 SigninPage__title'>{t('SigninPage.connect')}</div>
        {sent && <IncomingEmailNotice />}
        {!sent && (
          <>
            <Illustration name='robot-levitation' size='lg' />
            <div className='h2 SigninPage__guest-access-text'>{t('SigninPage.guest_access')}</div>
            <div className='h2 SigninPage__enter-email-text'>{t('SigninPage.enter_email')}</div>
            <div className='SigninPage__signin-component'>
              <SigninComponent mailSentFn={() => setSent(true)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
