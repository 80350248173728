import React from 'react';

import '../../../scss/mainComponents/customColorChooser/ColorDemo.scss';

const ColorDemo = ({ withLabel = false }) => {
  return (
    <div className='color-demo'>
      <div className='color-demo__box box--0'>{withLabel && <span className='color-demo__label'>Color Custom</span>}</div>
      <div className='color-demo__box box--2'>{withLabel && <span className='color-demo__label'>Color 60</span>}</div>
      <div className='color-demo__box box--4'>{withLabel && <span className='color-demo__label'>Color 20</span>}</div>
      <div className='color-demo__box box--1'>{withLabel && <span className='color-demo__label'>Color 80</span>}</div>
      <div className='color-demo__box box--3'>{withLabel && <span className='color-demo__label'>Color 40</span>}</div>
      <div className='color-demo__box box--5'>{withLabel && <span className='color-demo__label'>Color 10</span>}</div>
    </div>
  );
};

export default ColorDemo;
