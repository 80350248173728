import React from 'react';
import { localDateFormat, formatSlotTime } from 'support/date';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/mainComponents/Button';

import './AttendanceWidget.scss';

export function AttendanceWidget({ slot, tsId }) {
  const { t } = useTranslation();
  const date = localDateFormat(slot.date);
  const time = formatSlotTime(slot);

  return (
    <li className='AttendanceWidget'>
      <div className='AttendanceWidget__ModuleName'>{slot.subsession.name}</div>
      <div className='AttendanceWidget__RigthGrid'>
        <div className='AttendanceWidget__RigthGrid__A'>{date}</div>
        <div className='AttendanceWidget__RigthGrid__B'>{time}</div>
        <Button as='a' className='AttendanceWidget__Button' href={`/ts/${tsId}/attendance/sign/single?date=${slot.id}&goback=true`}>
          {t('AttendancesWidget.action')}
        </Button>
      </div>
    </li>
  );
}
