import { getContrastHexColor, shadeOrBlendColor } from 'support/colors';

/**
 * Base color name.
 */
export const COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ALT_1: 'alt_1',
  ALT_2: 'alt_2',
  ALT_3: 'alt_3',
};

/**
 * Available colors.
 */
export const COLORS = Object.values(COLOR);

/**
 * Variant name.
 */
export const VARIANT = {
  CONTRAST: 'contrast',
  DARK: 'dark',
  DARKER: 'darker',
};

/**
 * Available variants.
 */
export const VARIANTS = Object.values(VARIANT);

const DEFAULT_COLOR_VALUES = {
  [COLOR.PRIMARY]: {
    value: '#2121e8',
    [VARIANT.CONTRAST]: {
      value: '#ffffff',
    },
    [VARIANT.DARK]: {
      value: '#1919B8',
    },
    [VARIANT.DARKER]: {
      value: '#141287',
    },
  },
  [COLOR.SECONDARY]: {
    value: '#5625e9',

    [VARIANT.CONTRAST]: {
      value: '#ffffff',
    },
    [VARIANT.DARK]: {
      value: '#ffffff',
    },
    [VARIANT.DARKER]: {
      value: '#ffffff',
    },
  },
  [COLOR.ALT_1]: {
    value: '#8a28eb',

    [VARIANT.CONTRAST]: {
      value: '#ffffff',
    },
    [VARIANT.DARK]: {
      value: '#ffffff',
    },
    [VARIANT.DARKER]: {
      value: '#ffffff',
    },
  },
  [COLOR.ALT_2]: {
    value: '#bb27ec',

    [VARIANT.CONTRAST]: {
      value: '#ffffff',
    },
    [VARIANT.DARK]: {
      value: '#ffffff',
    },
    [VARIANT.DARKER]: {
      value: '#ffffff',
    },
  },
  [COLOR.ALT_3]: {
    value: '#ed2ced',

    [VARIANT.CONTRAST]: {
      value: '#ffffff',
    },
    [VARIANT.DARK]: {
      value: '#ffffff',
    },
    [VARIANT.DARKER]: {
      value: '#ffffff',
    },
  },
};

/**
 * Compute the value of a variant of the given color.
 * @param {string} variant Variant to compute (one of {@link VARIANT}.)
 * @param {string} colorValue Value to compute from.
 * @returns Computed value for the requested variant of the given color.
 *  Returns `colorValue` if `variant` is invalid.
 */
export function computeVariantValue(variant, colorValue) {
  switch (variant) {
    case VARIANT.CONTRAST:
      return getContrastHexColor(colorValue);
    case VARIANT.DARK:
      return shadeOrBlendColor(-0.2, colorValue);
    case VARIANT.DARKER:
      return shadeOrBlendColor(-0.4, colorValue);
    default:
      return colorValue;
  }
}

/**
 * Create a color configuration from the given values.
 * Uses automatic or default values if incomplete or none provided.
 *
 * If a single color value is provided and equal to `#ffffff`,
 * it will be overrode with the default primary color instead.
 *
 * @param {Object | String} colorValues Color values to use in the configuration.
 *  Can be a string representing the {@link COLOR.PRIMARY} color, in which case
 *    it's used for all colors.
 *  Can be an object that uses color names and variant names to define color values:
 *  ```
 *  const values = {
 *      primary: {
 *          value: "#FFF",
 *          contrast: { value: "#000" },
 *          dark: { value: "#AAA" },
 *          darker: { value: "#CCC" }
 *      }
 *  }
 *  ```
 */
export function createConfiguration(colorValues) {
  const configuration = {};
  const inputConfiguration = typeof colorValues === 'string' ? createMinimalConfigurationFromSingleColor(colorValues) : colorValues;
  const isPrimaryForAllColors = typeof colorValues === 'string';

  for (const color of COLORS) {
    const inputColorValue = inputConfiguration?.[color]?.value;

    configuration[color] = {
      name: color,
      displayNameTranslationKey: `ColorConfiguration.color_${color}`,
      cssCustomProperty: `--colors-${color}`,
    };

    if (inputColorValue !== undefined) {
      configuration[color].value = inputColorValue;
    } else if (isPrimaryForAllColors) {
      configuration[color].value = inputConfiguration[COLOR.PRIMARY].value;
    } else {
      configuration[color].value = DEFAULT_COLOR_VALUES[color].value;
    }

    for (const variant of VARIANTS) {
      const inputVariantValue = inputConfiguration?.[color]?.[variant]?.value;

      configuration[color][variant] = {
        name: `${color}${variant}`,
        displayNameTranslationKey: `ColorConfiguration.color_${color}_${variant}`,
        cssCustomProperty: `--colors-${color}-${variant}`,
      };

      if (inputVariantValue !== undefined) {
        configuration[color][variant].value = inputVariantValue;
      } else if (inputColorValue !== undefined) {
        configuration[color][variant].value = computeVariantValue(variant, inputColorValue);
      } else {
        configuration[color][variant].value = DEFAULT_COLOR_VALUES[color][variant].value;
      }
    }
  }

  return configuration;
}

function createMinimalConfigurationFromSingleColor(colorValue) {
  // Don't allow white by default because it makes the UI hard to use.
  const sanitizedColorValue = /^#f*$/i.test(colorValue) ? DEFAULT_COLOR_VALUES[COLOR.PRIMARY].value : colorValue;

  const configuration = {};

  for (const color of COLORS) {
    configuration[color] = { value: sanitizedColorValue };
  }

  return configuration;
}
