import React from 'react';
import PropTypes from 'prop-types';

import { ActionWidget } from '../ActionWidget';
import { useTranslation } from 'react-i18next';

export function DocumentsWidget({ documentsCount, children }) {
  const { t } = useTranslation();

  return (
    <ActionWidget
      icon='folder'
      color='alt-3'
      title={t('DocumentsWidget.title')}
      subtitle={t('DocumentsWidget.documents_count', {
        count: documentsCount,
      })}
    >
      {documentsCount > 0 && children}
    </ActionWidget>
  );
}

DocumentsWidget.propTypes = {
  documentsCount: PropTypes.number.isRequired,
  children: PropTypes.node,
};
