import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDailyEvent } from '@daily-co/daily-react';
import {
  CALL_ERROR,
  CAMERA_ERROR,
  CAM_IN_USE,
  CAM_MIC_IN_USE,
  LOAD_ATTEMPT_FAILED,
  MIC_IN_USE,
  NOT_ALLOWED_ERROR,
  NOT_FOUND,
} from 'constants/video_conference';
import * as Sentry from '@sentry/react';

const ErrorsContext = createContext({});

function useErrors() {
  const [cameraError, setCameraError] = useState(null);
  const [micError, setMicError] = useState(null);
  const [callError, setCallError] = useState(null);
  const [loadAttemptFailed, setLoadAttemptFailed] = useState(false);

  const setCameraOrMicError = useCallback(
    error => {
      if (error.missingMedia.includes('video')) {
        setCameraError(NOT_FOUND);
      }
      if (error.missingMedia.includes('audio')) {
        setMicError(NOT_FOUND);
      }
    },
    [setCameraError, setMicError]
  );

  const determineAndSetError = useCallback(
    error => {
      switch (error.type) {
        case CAM_IN_USE:
          setCameraError(CAM_IN_USE);
          break;
        case MIC_IN_USE:
          setMicError(MIC_IN_USE);
          break;
        case CAM_MIC_IN_USE:
          setCameraError(CAM_IN_USE);
          setMicError(MIC_IN_USE);
          break;
        default:
          setCameraError(NOT_ALLOWED_ERROR);
          setMicError(NOT_ALLOWED_ERROR);
      }
    },
    [setCameraError, setMicError]
  );

  const setDefaultNotAllowedError = useCallback(() => {
    setCameraError(NOT_ALLOWED_ERROR);
    setMicError(NOT_ALLOWED_ERROR);
  }, [setCameraError, setMicError]);

  useDailyEvent(
    CAMERA_ERROR,
    useCallback(
      event => {
        if (event.error?.type === NOT_FOUND) {
          setCameraOrMicError(event.error);
        }

        if (event.errorMsg.errorMsg === NOT_ALLOWED_ERROR) {
          if (event.error?.type) {
            determineAndSetError(event.error);
          } else {
            setDefaultNotAllowedError();
          }
        }
      },
      [determineAndSetError, setCameraOrMicError, setDefaultNotAllowedError]
    )
  );

  useDailyEvent(
    LOAD_ATTEMPT_FAILED,
    useCallback(() => {
      setLoadAttemptFailed(true);
    }, [])
  );

  // Unrecoverable call errors, e.g. networks errors
  useDailyEvent(
    CALL_ERROR,
    useCallback(event => {
      setCallError(event.errorMsg);
      localStorage.setItem('lastError', JSON.stringify(event));
      Sentry.captureException(event);
      window.location.reload();
    }, [])
  );

  return {
    cameraError,
    micError,
    callError,
    loadAttemptFailed,
  };
}

export const ErrorsProvider = ({ children }) => {
  const errorsState = useErrors();
  return <ErrorsContext.Provider value={errorsState}>{children}</ErrorsContext.Provider>;
};

const useErrorsState = () => {
  const errorsState = useContext(ErrorsContext);
  if (!errorsState) {
    throw new Error('useErrorsState must be used within a ErrorsProvider');
  }

  return errorsState;
};

export default useErrorsState;
