import Tooltip from 'components/video_conference/Tooltip';
import { MIC_STEP_TOOLTIP } from 'constants/video_conference';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../../../../scss/video_conference/MicStepTitle.module.scss';
import PropTypes from 'prop-types';
import Icon from 'components/mainComponents/Icon';

function MicStepTitle({ micUnavailable, isMicBlocked, isRecordingFinished }) {
  const { t } = useTranslation();

  if (isMicBlocked) {
    return t('MicStepTitle.mic_blocked');
  }

  if (micUnavailable) {
    return (
      <div className={styles.title}>
        <Tooltip description={t('MicStepTitle.tooltip.no_mic')} id={MIC_STEP_TOOLTIP}>
          <Icon icon='warning--circle' />
        </Tooltip>
        {t('MicStepTitle.no_mic')}
      </div>
    );
  }

  if (!isRecordingFinished) {
    return t('MicStepTitle.not_finished');
  }

  return <div className={styles.title}>{t('MicStepTitle.finished')}</div>;
}

export default MicStepTitle;

MicStepTitle.propTypes = {
  micUnavailable: PropTypes.bool.isRequired,
  isMicBlocked: PropTypes.bool.isRequired,
  isRecordingFinished: PropTypes.bool.isRequired,
};
