import { ActionButton } from 'components/mainComponents/buttons';
import IconButton from 'components/video_conference/IconButton';
import { useCallStatusState } from 'hooks/video_conference';
import React from 'react';
import styles from 'scss/video_conference/TileTray.module.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDevicesToggle from 'hooks/video_conference/useDevicesToggle';
import clsx from 'clsx';

function TileTray({ shouldGoToTest, isVideoReady, onTestClick, onSettingsClick }) {
  const { t } = useTranslation();

  const { isCameraMuted, isMicMuted, isMicBtnDisabled } = useCallStatusState();
  const { toggleCamera, toggleMic } = useDevicesToggle();

  const buttonTrayStyles = clsx(styles.buttonTray, shouldGoToTest && styles['buttonTray--centered']);

  return (
    <div className={styles.tileTray}>
      {!shouldGoToTest && (
        <ActionButton
          clickFn={isVideoReady ? onTestClick : undefined}
          type='secondary'
          label={t('TileTray.go_to_setup')}
          icon='notepad'
          className={styles.tileTray__button}
        />
      )}
      <div className={buttonTrayStyles}>
        <IconButton
          icon={isMicMuted ? 'microphone--off' : 'microphone'}
          clickFn={toggleMic}
          disabled={isMicBtnDisabled || !isVideoReady}
          type={isMicMuted ? 'error' : 'secondary'}
          title={isMicMuted ? t('TileTray.mic_off') : t('TileTray.mic_on')}
          className={styles.buttonTray__button}
        />
        <IconButton
          icon={isCameraMuted ? 'camera--off' : 'video--on'}
          clickFn={toggleCamera}
          disabled={!isVideoReady}
          type={isCameraMuted ? 'error' : 'secondary'}
          title={isCameraMuted ? t('TileTray.cam_off') : t('TileTray.cam_on')}
          className={styles.buttonTray__button}
        />
        <IconButton
          icon='three--dots--vertical'
          clickFn={onSettingsClick}
          disabled={!isVideoReady}
          type='secondary'
          title={t('TileTray.settings')}
          className={styles.buttonTray__button}
        />
      </div>
    </div>
  );
}

export default TileTray;

TileTray.propTypes = {
  shouldGoToTest: PropTypes.bool,
  isVideoReady: PropTypes.bool,
  onTestClick: PropTypes.func,
  onSettingsClick: PropTypes.func,
};

TileTray.defaultProps = {
  shouldGoToTest: false,
  isVideoReady: false,
  onTestClick: () => {},
  onSettingsClick: () => {},
};
