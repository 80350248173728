import React, { useState } from 'react';

import '../../../scss/form_inputs/FormInputRating.scss';

const FormInputRating = props => {
  const [answerGiven, setAnswerGiven] = useState(null);

  const onAnswer = value => {
    setAnswerGiven(value);
    props.onAnswer(props.input.id, value);
  };

  const data = JSON.parse(props.input.data);
  const answerDisplayed = props.answerSent !== null && props.answerSent !== undefined ? props.answerSent : answerGiven;

  return (
    <div className={`FormInputRating FormInput ${props.isLive ? 'live' : ''}`}>
      <p className={`question ${data.question.length < 80 ? 'short-question' : ''}`}>{data.question}</p>

      <div className='stars'>
        {[1, 2, 3, 4, 5].map(i => (
          <button onClick={() => onAnswer(i)} key={i}>
            <i className={`icon-star-full ${answerDisplayed >= i ? 'full' : ''}`} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default FormInputRating;
