import React, { Component } from 'react';
import propTypes from 'prop-types';

import signaturePad from 'signature_pad';

/**
 * @typedef SignaturePadProps
 * @type {Object}
 * @property {(ref: SignaturePad) => void} ref
 * @property {number|Function} [dotSize=1]
 * @property {number} [width=600]
 * @property {number} [height=300]
 * @property {number} [minWidth=0.5]
 * @property {number} [maxWidth=0.5]
 * @property {number} [throttle=16]
 * @property {number} [minDistance=5]
 * @property {string} [backgroundColor='rgb(255,255,255)']
 * @property {string} [penColor='rgb(0,0,0)']
 * @property {number} [velocityFilterWeight=0.7]
 * @property {() => void} [onBegin=() => {}]
 * @property {() => void} [enEnd=() => {}]
 */

/**
 * @class
 * @name SignaturePad
 * @extends React.Component<SignaturePadProps>
 * @description A React wrapper for the SignaturePad class
 * @property {HTMLCanvasElement} _canvasRef
 * @property {signaturePad | null} _signaturePad
 * @property {SignaturePadProps} props
 */
class SignaturePad extends Component {
  /**
   * @param {SignaturePadProps} props
   */
  constructor(props) {
    super(props);

    this._canvasRef = React.createRef();
    this._signaturePad = null;
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this._signaturePad = new signaturePad(this._canvasRef, this.props);
    this.adjustRatio();
    this.addGlobalListeners();
    this._signaturePad.on();
  }

  componentWillUnmount() {
    this._signaturePad.off();
    this.removeGlobalListeners();
  }

  adjustRatio() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const canvas = this._canvasRef;
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
  }

  handleResize() {
    this.clear();
    this.adjustRatio();
  }

  addGlobalListeners() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
  }

  removeGlobalListeners() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);
  }

  /**
   * Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
   * @param {string} [mime]
   * @returns {string}
   */
  toDataURL(mime) {
    return this._signaturePad && this._signaturePad.toDataURL(mime);
  }

  /**
   * Draws signature image from data URL.
   * Note: This method does not populate internal data structure
   * that represents drawn signature. Thus, after using #fromDataURL,
   * #toData won't work properly.
   * @param {string} data
   */
  fromDataURL(data) {
    return this._signaturePad && this._signaturePad.fromDataURL(data);
  }

  /**
   * Returns signature image as an array of point groups
   * @returns {*}
   */
  toData() {
    return this._signaturePad && this._signaturePad.toData();
  }

  /**
   * Draws signature image from an array of point groups
   * @param {*} data
   */
  fromData(data) {
    return this._signaturePad && this._signaturePad.fromData(data);
  }

  /**
   * Clears the canvas
   */
  clear() {
    return this._signaturePad && this._signaturePad.clear();
  }

  /**
   * Returns true if canvas is empty, otherwise returns false
   * @returns {boolean}
   */
  isEmpty() {
    return this._signaturePad && this._signaturePad.isEmpty();
  }

  /**
   * Unbinds all event handler
   */
  off() {
    return this._signaturePad && this._signaturePad.off();
  }

  /**
   * Rebinds all event handlers
   */
  on() {
    return this._signaturePad && this._signaturePad.on();
  }

  render() {
    const { width, height, className } = this.props;

    return <canvas className={className} id='signature-pad' ref={r => (this._canvasRef = r)} width={width} height={height} />;
  }
}

SignaturePad.propTypes = {
  dotSize: propTypes.oneOfType([propTypes.number, propTypes.func]),
  width: propTypes.number,
  height: propTypes.number,
  minWidth: propTypes.number,
  maxWidth: propTypes.number,
  throttle: propTypes.number,
  minDistance: propTypes.number,
  backgroundColor: propTypes.string,
  penColor: propTypes.string,
  velocityFilterWeight: propTypes.number,
  onBegin: propTypes.func,
  onEnd: propTypes.func,
  className: propTypes.string,
};

SignaturePad.defaultProps = {
  dotSize: 1.5,
  width: 600,
  height: 300,
  minWidth: 0.5,
  maxWidth: 2.5,
  throttle: 16,
  minDistance: 5,
  backgroundColor: 'rgb(255,255,255)',
  penColor: 'rgb(0,0,0)',
  velocityFilterWeight: 0.7,
  onBegin: () => {},
  enEnd: () => {},
};

export default SignaturePad;
