import React, { useCallback, useContext, useEffect } from 'react';
import {
  CONNECTION_TEST_STEP,
  JOINED_STATE,
  MIC_STEP,
  PRE_CALL_STEP,
  SET_UP_INITIAL_STEP,
  WEBCAM_STEP,
  SET_UP_WIZARD_STEP,
} from 'constants/video_conference';
import { joinCall } from 'helpers/video_conference';
import { createContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCallStateDispatch } from '../useCallState';
import useStep from '../useStep';
import { useDaily } from '@daily-co/daily-react';

const PreCallContext = createContext({});

export const PRE_CALL_STEPS = [
  { key: 1, name: PRE_CALL_STEP },
  { key: 2, name: SET_UP_INITIAL_STEP },
  { key: 3, name: MIC_STEP },
  { key: 4, name: WEBCAM_STEP },
  { key: 5, name: CONNECTION_TEST_STEP },
  { key: 6, name: SET_UP_WIZARD_STEP },
];

function usePreCall() {
  const callObject = useDaily();
  const {
    properties: { token },
  } = callObject;
  const { id } = useParams();
  const roomId = `${window.config.env}-module-${id}`;

  // To change after moving localStorage data to back-end
  const qualityVerdict = {
    bandwidthSettings: JSON.parse(localStorage.getItem('bandwidthSettings')),
    micQualityVerdict: localStorage.getItem('micQualityVerdict'),
    camQualityVerdict: localStorage.getItem('camQualityVerdict'),
  };

  const { bandwidthSettings } = qualityVerdict;

  const [loading, setLoading] = useState(false);
  const [completedSteps, setCompletedSteps] = useState({
    MIC_STEP: false,
    WEBCAM_STEP: false,
    CONNECTION_TEST_STEP: false,
  });
  const { currentStep, setCurrentStep, goToNextStep, goToPrevStep, canGoToNextStep, canGoToPrevStep } = useStep(7);

  // For debugging purposes
  const [debugWebcamProps, setDebugWebcamProps] = useState([]);
  const [debugNetworkProps, setDebugNetworkProps] = useState([]);

  const debugProps = [...debugWebcamProps, ...debugNetworkProps];

  const setCallState = useCallStateDispatch();

  const handleJoinCall = () => {
    setLoading(true);
    joinCall({
      callObject,
      url: `https://digiforma.daily.co/${roomId}?t=${token}`,
      token,
    }).then(() => {
      setLoading(false);
      setCallState(JOINED_STATE);
    });
  };

  const findStepKey = stepName => {
    const step = PRE_CALL_STEPS.find(step => step.name === stepName);
    return step.key;
  };

  const setNamedStep = useCallback(
    stepName => {
      setCurrentStep(findStepKey(stepName));
    },
    [setCurrentStep]
  );

  const { name: currentStepName } = PRE_CALL_STEPS.find(step => step.key === currentStep);

  const canGoToNextSetUpStep = canGoToNextStep && currentStep < findStepKey(CONNECTION_TEST_STEP);
  const canGoToPrevSetUpStep = canGoToPrevStep && currentStep > findStepKey(MIC_STEP);
  const isSetUpStep = currentStep > findStepKey(SET_UP_INITIAL_STEP);

  const goToNextSetUpStep = () => {
    goToNextStep();
  };

  const setCompletedStep = (stepName, isCompleted) => {
    setCompletedSteps({ ...completedSteps, [stepName]: isCompleted });
  };

  useEffect(() => {
    if (currentStepName === PRE_CALL_STEP && !bandwidthSettings) {
      setNamedStep(SET_UP_INITIAL_STEP);
    }
  }, [setNamedStep, bandwidthSettings, currentStepName]);

  return {
    handleJoinCall,
    loading,
    goToNextSetUpStep,
    goToNextStep,
    goToPrevStep,
    currentStep,
    setCurrentStep,
    setNamedStep,
    currentStepName,
    canGoToNextSetUpStep,
    canGoToPrevSetUpStep,
    isSetUpStep,
    setCompletedStep,
    completedSteps,
    setDebugNetworkProps,
    setDebugWebcamProps,
    debugProps,
  };
}

export const PreCallProvider = ({ children }) => {
  const preCallState = usePreCall();
  return <PreCallContext.Provider value={preCallState}>{children}</PreCallContext.Provider>;
};

const usePreCallState = () => {
  const preCallState = useContext(PreCallContext);
  if (!preCallState) {
    throw new Error('usePreCallState must be used within a PreCallProvider');
  }

  return preCallState;
};

export default usePreCallState;
