import GeoPattern from 'geopattern';

export function resizeImage(url, width) {
  return url.replace('https://cdn.filestackcontent.com/', `https://process.filestackapi.com/resize=width:${width}/`);
}

export function buildPattern(hash) {
  const pattern = GeoPattern.generate(hash, { color: '#EEE' });
  return pattern.toDataUrl();
}

export function buildPatternStyle(training_session) {
  const hasImage = training_session.image;

  let trainingSessionStyle = {};
  if (hasImage) {
    trainingSessionStyle = {
      backgroundImage: `url(${training_session.image.url})`,
      backgroundSize: 'cover',
      backgroundPosition: `center`,
    };
  } else {
    trainingSessionStyle = {
      backgroundImage: buildPattern(training_session.name),
      backgroundRepeat: 'repeat',
      backgroundSize: 'contain',
      color: '#555',
      textShadow: 'none',
    };
  }

  return trainingSessionStyle;
}
