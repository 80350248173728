export const sortByDate = sortable => {
  return [...sortable].sort((first, second) => new Date(second.inserted_at) - new Date(first.inserted_at));
};

export const sortByName = (sortable, type) => {
  if (type === 'document') return [...sortable].sort((first, second) => first.filename.localeCompare(second.filename));
  return [...sortable].sort((first, second) => first.name.localeCompare(second.name));
};

export const blobAndStoreInFolder = (url, filename, folder) => {
  const blobPromise = fetch(url).then(result => {
    if (result.status === 200) return result.blob();
    return Promise.reject(new Error(result.statusText));
  });

  folder.file(filename, blobPromise);
};

export const moveIntoFolder = (event, folders, setFolders, documents, setSelectedFolder, setDocuments, searchInput) => {
  const folderId = event.currentTarget.dataset.folderId;
  const selectedFolder = folders.find(folder => folder.id === folderId);
  const hiddenFolders = folders.map(folder => {
    return { ...folder, isShown: false };
  });

  const folderDocuments = folders.flatMap(folder => {
    const shouldShowDocuments = folder.id === folderId;
    return folder.documents.map(document => {
      return { ...document, isShown: shouldShowDocuments };
    });
  });

  const baseDocuments = documents.map(document => ({ ...document, isShown: false }));
  searchInput.current.value = '';
  setFolders(hiddenFolders);
  setSelectedFolder(selectedFolder);
  setDocuments(folderDocuments.concat(baseDocuments));
};

export const openInNewTab = ({ url }) => {
  window.open(url, '_blank').focus();
};
