import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import _ from 'lodash';
import Document from '../document/Document';

import '../../scss/Program.scss';

class Program extends Component {
  render() {
    return this.props.program.documents.length > 0 ? renderDocuments(this.props) : renderProgram(this.props);
  }
}

function renderDocuments({ program, t }) {
  const { documents } = program;
  return (
    <div className='Program'>
      {documents.map(document => (
        <Document document={document} readForNotifications={false} key={`document-${document.id}`} />
      ))}
    </div>
  );
}

function renderProgram({ program, t }) {
  $(document).ready(() => {
    toggleOneElement();
    toggleAllElements();
  });

  return (
    <div className='Program'>
      <div className='hero-section'>
        <h1 className='title'>{program.name}</h1>

        {program.subtitle && (
          <div className='subtitle'>
            <p>{program.subtitle}</p>
          </div>
        )}

        <div className='modality'>{t(formatTrainingPedagogicalModality(program))}</div>
      </div>

      <div className='program-product'>
        {(program.duration_in_hours || program.duration_in_days || (program.duration_in_hours && program.duration_in_days)) && (
          <div className='duration'>
            <i className='pic icon-clock' />
            <p>
              {t('Program.duration')}:{formatDuration(program, t)}
            </p>
          </div>
        )}

        {program.description && program.description.length > 0 && (
          <div className='description'>
            <p>{program.description}</p>
          </div>
        )}

        {program.goals.length > 0 && (
          <div className='goals'>
            <h2 className='subtitle-section'>{t('Program.educational_goals')}</h2>
            <div className='section'>
              <div className='category'>
                <i className='pic icon-crm' />
              </div>
              <ul>
                {program.goals.map((goal, i) => (
                  <li className='list-goals' key={`goal-${i}`}>
                    <i className='pic icon-check' />
                    <span>{goal.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {(program.targets.length > 0 || program.prerequisites.length > 0) && (
          <div className='marketing'>
            <h2 className='subtitle-section'>{t('Program.trainee_profile')}</h2>
            <div className='section marketing'>
              <div className='category marketing-category'>
                <i className='pic icon-trainees' />
              </div>
              <div className='subcategory'>
                <i className='pic icon-user' />
                <span>{t('Program.for_whom')}</span>
                <ul>
                  {program.targets.map((target, i) => (
                    <li key={`target-${i}`}>
                      <i className='pic icon-chevron-thin-right' />
                      <span>{target.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='subcategory'>
                <i className='pic icon-pencil' />
                <span>{t('Program.prequisites')}</span>
                <ul>
                  {program.prerequisites.map((prerequisite, i) => (
                    <li key={`prerequisite-${i}`}>
                      <i className='pic icon-chevron-thin-right' />
                      <span>{prerequisite.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}

        {program.steps.length > 0 && (
          <div className='steps'>
            <div className='steps-subtitle'>
              <h2 className='subtitle-section'>{t('Program.training_content')}</h2>
              <div className='dropdown-all'>+</div>
            </div>
            <div className='section step-block'>
              <ul className='list-steps'>
                {program.steps.map((step, i) => (
                  <li className='dropdown-btn-list' key={`step-${i}`}>
                    <div>{step.text}</div>
                    {step.substeps.length > 0 && (
                      <ul className='list-substeps'>
                        {step.substeps.map((substep, j) => (
                          <li key={`substep-${j}`}>
                            <i className='pic icon-chevron-thin-right' />
                            <span>{substep.text}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className='resources'>
          {program.mentoring && (
            <div className='section resources-block'>
              <div className='category-resources'>
                <i className='pic icon-dna' />
                <span>{t('Program.educational_team')}</span>
              </div>
              <p>{program.mentoring}</p>
            </div>
          )}

          {program.assessments.length > 0 && (
            <div className='section resources-block'>
              <div className='category-resources'>
                <i className='pic icon-quality' />
                <span>{t('Program.monitoring_of_implementation_and_evaluation')}</span>
              </div>
              <ul>
                {program.assessments.map((assessment, i) => (
                  <li key={`assessment-${i}`}>
                    <i className='pic icon-chevron-thin-right' />
                    <span>{assessment.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {program.pedagogical_resources.length > 0 && (
            <div className='section resources-block'>
              <div className='category-resources'>
                <i className='pic icon-sextant' />
                <span>{t('Program.technical_and_educational_resources')}</span>
              </div>
              <ul>
                {program.pedagogical_resources.map((pedagogical_resource, i) => (
                  <li key={`pedagogical_resource-${i}`}>
                    <i className='pic icon-chevron-thin-right' />
                    <span>{pedagogical_resource.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function formatTrainingPedagogicalModality(program) {
  switch (program.training_pedagogical_modality) {
    case 0:
      return 'Program.on_site_training';
    case 1:
      return 'Program.mixed_training';
    case 2:
      return 'Program.distance_learning';
    default:
      return 'Program.on_site_training';
  }
}

function formatDuration(program, t) {
  const hours = Math.trunc(program.duration_in_hours);
  const minutes = Math.trunc((program.duration_in_hours - hours) * 60);
  const minutesString = _.padStart(`${minutes}`, 2, '0');

  let durationHours;
  if (minutesString === '00') {
    durationHours = t('Program.hour', { count: hours });
  } else {
    durationHours = `${hours}:${minutesString}`;
  }

  if (program.duration_in_days) {
    return `${durationHours}  ${t('Program.day', { count: program.duration_in_days })}`;
  }
  return durationHours;
}

function toggleOneElement() {
  const dropdown = document.getElementsByClassName('dropdown-btn-list');
  for (let i = 0; i < dropdown.length; ++i) {
    dropdown[i].addEventListener('click', function () {
      if (this.children[1]) {
        // if the program has substeps
        const dropdownContent = this.children[1];
        if (dropdownContent.style.display === 'block') {
          dropdown[i].classList.toggle('dropdown-active', false);
          dropdownContent.style.display = 'none';
        } else {
          dropdown[i].classList.add('dropdown-active');
          dropdownContent.style.display = 'block';
        }
      }
    });
  }
}

function toggleAllElements() {
  const dropdownButtonAll = document.getElementsByClassName('dropdown-all');
  const listDropdownBtn = document.getElementsByClassName('dropdown-btn-list');
  const list = document.getElementsByClassName('list-substeps');
  if (dropdownButtonAll.length > 0) {
    dropdownButtonAll[0].addEventListener('click', function () {
      if (list[0]) {
        // if the program has substeps
        if (this.innerText === '-') {
          for (let i = 0; i < listDropdownBtn.length; ++i) {
            listDropdownBtn[i].classList.toggle('dropdown-active', false);
            list[i].style.display = 'none';
          }
          this.innerText = '+';
        } else if (this.innerText === '+') {
          for (let i = 0; i < listDropdownBtn.length; ++i) {
            listDropdownBtn[i].classList.add('dropdown-active');
            list[i].style.display = 'block';
          }
          this.innerText = '-';
        }
      }
    });
  }
}

export default withTranslation()(Program);
