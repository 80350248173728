import { useDaily, useLocalParticipant } from '@daily-co/daily-react';
import { backgroundEffectOptions } from 'components/video_conference/Settings';
import BackgroundSettings from 'components/video_conference/Settings/BackgroundSettings';
import PreCallTile from 'components/video_conference/Tile/PreCallTile';
import TileRenderer from 'components/video_conference/Tile/TileRenderer';
import { BACKGROUND_UPLOAD } from 'constants/video_conference';
import { updateBackgroundImage } from 'helpers/video_conference';
import { useSettingsPersistence } from 'hooks/video_conference';
import useBackgroundSettings from 'hooks/video_conference/useBackgroundSettings';
import React from 'react';
import styles from '../../../../../../scss/video_conference/WebcamSettings.module.scss';
import PropTypes from 'prop-types';

function WebcamSettings({ isCompleted, isTesting, resetState, isCameraMuted }) {
  const localParticipant = useLocalParticipant();
  const callObject = useDaily();

  const { chosenBackground, setChosenBackground, currentBackgroundSettings } = useBackgroundSettings();
  const persistSetting = useSettingsPersistence();

  const handleBackgroundEffectChange = ({ target: { value: newBackgroundEffect } }, customBackgroundUrl) => {
    let chosenBackground = currentBackgroundSettings.find(option => option.value === newBackgroundEffect);
    if (newBackgroundEffect === BACKGROUND_UPLOAD) chosenBackground = { ...chosenBackground, config: { source: customBackgroundUrl } };
    updateBackgroundImage(chosenBackground, persistSetting, callObject);
    if (isCompleted) {
      resetState();
    }
  };

  return (
    <div className={styles.settings}>
      <div className={styles.row}>
        <TileRenderer
          tile={{ ...localParticipant, renderer: PreCallTile }}
          classes={styles.tile}
          wrapperClasses={styles.aspectRatio}
        />
      </div>
      <div className={styles.row}>
        <BackgroundSettings
          options={backgroundEffectOptions}
          onChange={handleBackgroundEffectChange}
          disabled={isCameraMuted || isTesting}
          chosenBackground={chosenBackground}
          currentBackgroundSettings={currentBackgroundSettings}
          setChosenBackground={setChosenBackground}
        />
      </div>
    </div>
  );
}

export default WebcamSettings;

WebcamSettings.propTypes = {
  isCompleted: PropTypes.bool,
  isTesting: PropTypes.bool,
  resetState: PropTypes.func.isRequired,
  isCameraMuted: PropTypes.bool,
};

WebcamSettings.defaultProps = {
  isCompleted: false,
  isTesting: false,
  isCameraMuted: false,
};
