import clsx from 'clsx';
import React, { useState } from 'react';

import './FormInputRating.scss';
import { EvaluationQuestion, EvaluationQuestionLabel } from 'components/v2/evaluations/form_inputs/EvaluationQuestion';

export function FormInputRating(props) {
  const [answerGiven, setAnswerGiven] = useState(null);

  const onAnswer = value => {
    setAnswerGiven(value);
    props.onAnswer(props.input.id, value);
  };

  const data = JSON.parse(props.input.data);
  const answerDisplayed = props.answerSent !== null && props.answerSent !== undefined ? props.answerSent : answerGiven;

  return (
    <EvaluationQuestion>
      <EvaluationQuestionLabel>{data.question}</EvaluationQuestionLabel>
      <div>
        {[1, 2, 3, 4, 5].map(i => (
          <button onClick={() => onAnswer(i)} key={i} className='v2_FormInputRating__star' disabled={props.correcting}>
            <i
              className={clsx('icon-star-full v2_FormInputRating__star-icon', {
                'v2_FormInputRating__star-icon--full': answerDisplayed >= i,
              })}
            />
          </button>
        ))}
      </div>
    </EvaluationQuestion>
  );
}
