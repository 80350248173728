import { gql } from '@apollo/client';

export const ACCOUNT_INFO_QUERY = gql`
  query accountInfos($userCode: String!) {
    accountInfos(userCode: $userCode) {
      id
      firstname
      lastname
      company
      extranetCustomColor
      extranetColorConfiguration {
        primary {
          value
          contrast {
            value
          }
          dark {
            value
          }
          darker {
            value
          }
        }
        secondary {
          value
          contrast {
            value
          }
          dark {
            value
          }
          darker {
            value
          }
        }
        alt_1 {
          value
          contrast {
            value
          }
          dark {
            value
          }
          darker {
            value
          }
        }
        alt_2 {
          value
          contrast {
            value
          }
          dark {
            value
          }
          darker {
            value
          }
        }
        alt_3 {
          value
          contrast {
            value
          }
          dark {
            value
          }
          darker {
            value
          }
        }
      }
      logo {
        url
      }
    }
  }
`;
