import PropTypes from 'prop-types';
import { createContext, useState, useContext, useEffect } from 'react';

import { COLORS, VARIANTS, computeVariantValue, createConfiguration } from './colorConfiguration';
import { getColorChannels, hexColorToRGB } from 'support/colors';

const ColorConfigurationContext = createContext(undefined);

const bodyElement = document.getElementsByTagName('body')[0];

export function ColorConfigurationProvider(props) {
  const [colors, setColors] = useState(createConfiguration(props.defaultConfiguration));

  useEffect(() => {
    setColors(createConfiguration(props.defaultConfiguration));
  }, [props.defaultConfiguration]);

  useEffect(() => {
    const colorConfigurationStyle = cssStyleFromConfiguration(colors);
    for (const styleProperty in colorConfigurationStyle) {
      bodyElement.style.setProperty(styleProperty, colorConfigurationStyle[styleProperty]);
    }
  }, [colors]);

  return (
    <ColorConfigurationContext.Provider value={[colors, updateColor, resetConfiguration]}>
      {props.children}
    </ColorConfigurationContext.Provider>
  );

  /**
   * Update a color in the configuration.
   * @param {string} name Name of the color to update. Must be one of: {@link COLORS}.
   * @param {Object} options
      In addition to other documented properties, you can specify variant values to assign
      by using a variant name as the property name, eg: `contrast: "#FFF"`.
   * @param {string} options.value Value to assign to the base color.
   * @param {string[]} options.autoVariants List of variant colors to auto compute from the base color.
   *  An automatic variant is ignored if a value is explicitely provided for the same variant.
   */
  function updateColor(name, options) {
    if (!COLORS.includes(name)) {
      throw new Error(`Color '${name}' does not exist.`);
    }

    const hasOptionSomeColorOrVariantValue = Object.keys(options).some(property => ['value', ...VARIANTS].includes(property));
    if (!hasOptionSomeColorOrVariantValue) {
      throw new Error('Invalid color value provided: you must specify the variant to update.');
    }

    setColors(configuration => {
      return {
        ...configuration,
        [name]: updateColorConfiguration(configuration[name], options),
      };
    });
  }

  /**
   * Reset the configuration to the default one provided in props.
   */
  function resetConfiguration() {
    setColors(createConfiguration(props.defaultConfiguration));
  }
}

ColorConfigurationProvider.propTypes = {
  /**
   * The default configuration to use.
   * Resets the configuration on change.
   */
  defaultConfiguration: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export function useColorConfiguration() {
  return useContext(ColorConfigurationContext);
}

function updateColorConfiguration(colorConfiguration, options) {
  if (options.value) {
    colorConfiguration.value = options.value;

    for (const variant of options.autoVariants) {
      colorConfiguration[variant].value = computeVariantValue(variant, options.value);
    }
  }

  for (const variant of VARIANTS) {
    if (options[variant]) {
      colorConfiguration[variant].value = options[variant];
    }
  }

  return colorConfiguration;
}

function cssStyleFromConfiguration(colors) {
  const style = {};

  for (const color of Object.values(colors)) {
    const colorChannels = getColorChannels(color.value);

    style[`${color.cssCustomProperty}-rgb`] = `${colorChannels.r}, ${colorChannels.g}, ${colorChannels.b}`;
    style[color.cssCustomProperty] = hexColorToRGB(color.value);

    for (const variant of VARIANTS) {
      const variantChannels = getColorChannels(color[variant].value);

      style[`${color[variant].cssCustomProperty}-rgb`] = `${variantChannels.r}, ${variantChannels.g}, ${variantChannels.b}`;
      style[color[variant].cssCustomProperty] = hexColorToRGB(color[variant].value);
    }
  }

  return style;
}
