import {
  ABORTED_TEST_STATE,
  ADJUSTED_TEST_STATE,
  CONNECTION_TESTING_STATE,
  ERROR_TEST_STATE,
  FAILED_TEST_STATE,
  INITIAL_TEST_STATE,
  PENDING_TEST_STATE,
  SPEED_TESTING_STATE,
  SUCCESS_TEST_STATE,
} from 'constants/video_conference';
import { useTranslation } from 'react-i18next';
import Icon from 'components/mainComponents/Icon';
import PropTypes from 'prop-types';

function ConnectionTestStepTitle({ isActive, testState, timedOut, className }) {
  const { t } = useTranslation();

  const renderLoadingSpinner = children => (
    <>
      <Icon icon='loading-spinner--dark--one-quarter' />
      {children}
    </>
  );

  const renderTitle = () => {
    switch (testState) {
      case SPEED_TESTING_STATE:
        return renderLoadingSpinner(t('ConnectionTestStep.title.speed_testing'));
      case CONNECTION_TESTING_STATE:
        return renderLoadingSpinner(
          timedOut ? t('ConnectionTestStep.title.timed_out') : t('ConnectionTestStep.title.bandwidth_testing')
        );
      case PENDING_TEST_STATE:
        return renderLoadingSpinner(timedOut ? t('ConnectionTestStep.title.timed_out') : t('ConnectionTestStep.title.testing'));
      case ADJUSTED_TEST_STATE:
        return isActive ? t('ConnectionTestStep.title.error') : t('ConnectionTestStep.title.unresolved_issues');
      case SUCCESS_TEST_STATE:
        return t('ConnectionTestStep.title.success');
      case ERROR_TEST_STATE:
        return t('ConnectionTestStep.title.skipped_test');
      case ABORTED_TEST_STATE:
        return t('ConnectionTestStep.title.aborted_test');
      case FAILED_TEST_STATE:
        return t('ConnectionTestStep.title.failed_test');
      default:
        return t('ConnectionTestStep.title.default');
    }
  };

  return <div className={className}>{renderTitle()}</div>;
}

export default ConnectionTestStepTitle;

ConnectionTestStepTitle.propTypes = {
  isActive: PropTypes.bool,
  testState: PropTypes.string,
  timedOut: PropTypes.bool,
  className: PropTypes.string,
};

ConnectionTestStepTitle.defaultProps = {
  isActive: false,
  testState: INITIAL_TEST_STATE,
  timedOut: false,
  className: '',
};
