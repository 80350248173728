import { useCallback } from 'react';
import { getLocalConfig } from '../../../helpers/video_conference';
import useConfigPersistence from '../useConfigPersistence';

function useSettingsPersistence() {
  const persistConfig = useConfigPersistence();

  return useCallback(
    (setting, value) => {
      const localConfig = { ...getLocalConfig(), [setting]: value };

      persistConfig(localConfig);
    },
    [persistConfig]
  );
}

export default useSettingsPersistence;
