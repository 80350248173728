import { useAuth } from 'contexts/AuthContext';
import { RoomOptsContext } from 'contexts/video_conference';
import { getUserImageUrl } from 'helpers/video_conference';
import { useContext, useState } from 'react';
import { AVATAR_CUSTOM, AVATAR_NONE, avatarSettings } from 'constants/video_conference';

function useAvatarSettings() {
  const { auth } = useAuth();
  const { usersData } = useContext(RoomOptsContext);
  const avatarImageUrl = getUserImageUrl(usersData, `${auth.guestInfo.type}-${auth.guestInfo.id}`);
  const chosenAvatarValue = avatarImageUrl === null ? AVATAR_NONE : AVATAR_CUSTOM;
  const avatarSettingsToDiscard = chosenAvatarValue === AVATAR_NONE ? AVATAR_CUSTOM : AVATAR_NONE;

  const [chosenAvatar, setChosenAvatar] = useState(chosenAvatarValue);

  const currentAvatarSettings = avatarSettings.reduce((filteredAvatarSettings, avatarOptions) => {
    if (avatarOptions.value !== avatarSettingsToDiscard) {
      avatarOptions = 'thumbSrc' in avatarOptions ? { ...avatarOptions, thumbSrc: avatarImageUrl } : avatarOptions;
      filteredAvatarSettings.push(avatarOptions);
    }
    return filteredAvatarSettings;
  }, []);
  return {
    chosenAvatar,
    setChosenAvatar,
    currentAvatarSettings,
  };
}

export default useAvatarSettings;
