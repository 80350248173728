import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function UnknownEmailError({ email }) {
  const { t } = useTranslation();

  return (
    <div className='SigninCompoment__unknown-email-panel'>
      <div>{t('SigninPage.unrecognized_email')}</div>
      <span>{t('SigninPage.verify_email_address_and_try_again')} </span>
      <Link to='/signin/request_access'>{t('SigninPage.make_a_request_to_speaker')}</Link>.
    </div>
  );
}
