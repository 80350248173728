import * as Sentry from '@sentry/react';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { getToken } from 'support/auth';
import { getConfig } from '../config';

const httpLink = createHttpLink({
  uri: `${getConfig().serverRoot}/api/v1/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const { message, path } = error;
      Sentry.captureException(error);
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  dataIdFromObject: object => `${object.__typename}:${object.id}`,
  defaultOptions: {
    // The useQuery hook uses Apollo Client's watchQuery function
    // see https://www.apollographql.com/docs/react/api/core/ApolloClient/#example-defaultoptions-object
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
