import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withData, withRouteParams } from '../../support/page';
import DocumentList from './DocumentList';
import TrainingSessionHeader from 'components/training_session/TrainingSessionHeader';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import NewDocumentPage from './NewDocumentPage';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb/Breadcrumb';
import { getGuestInfo } from 'support/auth';
import { flowRight as compose } from 'lodash';

const DocumentPage = props => {
  const { t } = useTranslation();
  const { training_session, new_documents_section } = props;

  if (new_documents_section.can_access) {
    return <NewDocumentPage training_session={training_session} />;
  } else {
    const personnalDocTitle =
      training_session.addressed_documents.length > 0
        ? t('TrainingSessionPage.personal_documents')
        : t('TrainingSessionPage.no_personal_documents');
    const sharedDocTitle =
      training_session.documents.length > 0 ? t('TrainingSessionPage.shared_documents') : t('TrainingSessionPage.no_shared_documents');
    const training_session_name = training_session.custom_name || training_session.name;
    return (
      <div className='page DocumentPage'>
        <TrainingSessionHeader training_session_id={training_session.id} />
        <main>
          <Breadcrumb
            links={[
              { name: training_session_name, url: `/ts/${training_session.id}` },
              { name: t('TrainingSessionPage.personal_documents') },
            ]}
          />
          <h2>{personnalDocTitle}</h2>
          <DocumentList documents={training_session.addressed_documents} />
          <h2>{sharedDocTitle}</h2>
          <DocumentList documents={training_session.documents} />
        </main>
      </div>
    );
  }
};

const newDocumentsSection = gql`
  query new_documents_section {
    new_documents_section {
      can_access
    }
  }
`;

const documentsQuery = gql`
  query training_session_documents($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      documents {
        id
        filename
        url
        mime
        wistia {
          hashed_id
        }
        downloadable
      }
      addressed_documents {
        id
        filename
        url
        mime
        wistia {
          hashed_id
        }
        downloadable
      }
    }
  }
`;

const withGraphqlData = compose(
  graphql(documentsQuery, {
    name: 'training_session',
    options: props => {
      return {
        variables: { id: props.params.training_session_id },
      };
    },
  }),
  graphql(newDocumentsSection, {
    name: 'new_documents_section',
    options: props => {
      return {
        variables: { id: getGuestInfo().id },
      };
    },
  })
);

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session', 'new_documents_section')(
        DocumentPage, { loading: LoadingPagePlaceholder })));
