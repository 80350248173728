import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/mainComponents/Icon';

import '../../scss/mainComponents/Tile.scss';

const Tile = ({ icon, title }) => {
  return (
    <div className='tile'>
      <Icon icon={icon} />
      <div className='tile__title'>
        <p>{title}</p>
      </div>
    </div>
  );
};

export const NavigationLinkTile = ({ to, ...rest }) => {
  return (
    <Link to={to}>
      <Tile {...rest} />
    </Link>
  );
};

export const FileLinkTile = ({ to, onClickFn = () => {}, ...rest }) => {
  return (
    <a href={to} target='_blank' rel='noopener noreferrer' onClick={onClickFn}>
      <Tile {...rest} />
    </a>
  );
};

export default Tile;
