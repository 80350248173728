import { getDomain } from 'helpers/video_conference/domain';
import { useEffect, useState } from 'react';
import JQSpeedTest from 'support/JQSpeedTest';

const DOMAIN = getDomain();

const TEST_IMAGE_SIZE = 5242880;
const SPEED_TEST_RETRIES = 2;

function useSpeedTest() {
  const [downloadSpeed, setDownloadSpeed] = useState(undefined);
  const [uploadSpeed, setUploadSpeed] = useState(undefined);
  const [retries, setRetries] = useState(0);
  const [speedTestResults, setSpeedTestResults] = useState(null);
  const [isUploadFinished, setIsUploadFinished] = useState(false);
  const [speedTestError, setCurrentError] = useState(null);

  const startSpeedTest = () => {
    new JQSpeedTest({
      testDlCallback: downloadSpeed => setDownloadSpeed(downloadSpeed),
      testUlCallback: uploadSpeed => setUploadSpeed(uploadSpeed),
      testErrorCallback: error => {
        if (retries < SPEED_TEST_RETRIES) {
          setRetries(retries + 1);
          startSpeedTest();
        } else {
          setCurrentError(error);
        }
      },
      testImageUrl: '/5mb.bin',
      testImageSize: TEST_IMAGE_SIZE,
      testUploadUrl: `//${DOMAIN}/speedtest/upload`,
      testPingUrl: `//${DOMAIN}/speedtest/heartbeat`,
    });
  };

  const resetSpeedTest = () => {
    setIsUploadFinished(false);
    setDownloadSpeed(undefined);
    setUploadSpeed(undefined);
    setSpeedTestResults(null);
  };

  useEffect(() => {
    if (typeof downloadSpeed === 'number' && typeof uploadSpeed === 'number' && speedTestResults === null) {
      setSpeedTestResults({ downloadSpeed, uploadSpeed });
      setIsUploadFinished(true);
    }
  }, [downloadSpeed, uploadSpeed, speedTestResults]);

  return { startSpeedTest, resetSpeedTest, isUploadFinished, speedTestError, speedTestResults };
}

export default useSpeedTest;
