import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ProgressCircle } from 'components/mainComponents/ProgressCircle';
import { ActivityCard } from 'components/mainComponents/ActivityLine/ActivityCard';
import { DocumentPropType, EvaluationPropType } from 'propTypes';
import './ActivityLine.scss';

export function ActivityLine(props) {
  const { t } = useTranslation();

  return (
    <div className='ActivityLine'>
      {props.progress !== undefined && (
        <ProgressCircle
          value={props.progress / 100}
          size='md'
          title={`${props.progress}/100`}
          subtitle={t('ActivityLine.score')}
          color='secondary'
          className='ActivityLine__progress'
        />
      )}
      <ul className='ActivityLine__activities-list'>
        {props.activities.map((activity, i) => (
          <ActivityCard
            key={activity.id}
            activity={activity}
            activityItem={activityItem(activity)}
            isSelected={activity.id === props.selectedActivityId}
            onSelect={() => props.onSelect(activity)}
            previewMode={props.previewMode}
            index={i}
          />
        ))}
      </ul>
    </div>
  );

  function activityItem(activity) {
    switch (activity.resource_type) {
      case 'document':
        return props.documents.find(document => document.id === activity.resource_id.toString());
      case 'evaluation':
        return props.evaluations.find(evaluation => evaluation.id === activity.resource_id.toString());
      default:
        return null;
    }
  }
}

ActivityLine.propTypes = {
  progress: PropTypes.number,
  selectedActivityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  previewMode: PropTypes.bool,
  documents: PropTypes.arrayOf(DocumentPropType),
  evaluations: PropTypes.arrayOf(EvaluationPropType),
};
