import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useAudioLevel } from '@daily-co/daily-react';
import clsx from 'clsx';
import indicatorStyles from 'scss/video_conference/IconVolumeIndicator.module.scss';
import _ from 'lodash';
import { MIN_VOLUME_THRESHOLD } from 'constants/video_conference';

const IconVolumeIndicator = ({ audioTrack, styles }) => {
  const audioVolumeRef = useRef(null);

  const updateIndicator = volume => {
    if (!audioVolumeRef.current) return;
    const volumeLevel = volume >= MIN_VOLUME_THRESHOLD ? volume : 0;
    audioVolumeRef.current.style.setProperty('--volume', `${0.5 + Math.min(0.5, 2 * volumeLevel)}`);
    audioVolumeRef.current.style.visibility = volumeLevel > 0 ? 'visible' : 'hidden';
  };

  useAudioLevel(audioTrack, _.throttle(updateIndicator), 150);

  const indicatorClasses = clsx(styles.cornerIcon, styles.volumeIndicator, indicatorStyles.indicator);

  return (
    <div className={indicatorClasses} ref={audioVolumeRef}>
      <i className='icon-volume-medium' />
    </div>
  );
};

IconVolumeIndicator.propTypes = {
  audioTrack: PropTypes.object.isRequired,
  styles: PropTypes.shape({
    cornerIcon: PropTypes.string,
    volumeIndicator: PropTypes.string,
  }).isRequired,
};

export default IconVolumeIndicator;
