import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Input.scss';

export const Input = forwardRef(({ className, ...props }, ref) => {
  const inputClassName = clsx('Input', className);

  return <input className={inputClassName} {...props} ref={ref} />;
});
Input.displayName = 'Input';

Input.propTypes = {
  className: PropTypes.string,
};
