import React, { useState } from 'react';

import './FormInputRadio.scss';
import { RadioGroup } from 'components/mainComponents/RadioGroup';
import {
  EvaluationQuestion,
  EvaluationQuestionAnswerDescription,
  EvaluationQuestionLabel,
} from 'components/v2/evaluations/form_inputs/EvaluationQuestion';

export function FormInputRadio(props) {
  const [answerGiven, setAnswerGiven] = useState(null);

  const data = JSON.parse(props.input.data);
  const correctAnswer = data.answers.find(answer => answer.correct);
  const answerDisplayed = props.answerSent !== null && props.answerSent !== undefined ? props.answerSent : answerGiven;

  let correction = undefined;
  if (props.correcting && correctAnswer) {
    const value = data.answers[answerDisplayed];
    correction = value && value.correct ? 'correct' : 'wrong';
  }

  return (
    <EvaluationQuestion correction={correction}>
      <EvaluationQuestionLabel>{data.question}</EvaluationQuestionLabel>
      <RadioGroup value={answerDisplayed?.toString()} onValueChange={selectAnswer} disabled={props.correcting}>
        {data.answers.map((answer, index) => (
          <RadioGroup.Option value={index.toString()} key={answer.text}>
            {answer.text}
          </RadioGroup.Option>
        ))}
      </RadioGroup>

      {props.correcting && data.correct_answer && data.correct_answer.length > 0 && (
        <EvaluationQuestionAnswerDescription>{data.correct_answer}</EvaluationQuestionAnswerDescription>
      )}
    </EvaluationQuestion>
  );

  function selectAnswer(answerIndex) {
    setAnswerGiven(answerIndex);
    props.onAnswer(props.input.id, parseInt(answerIndex, 10));
  }
}
