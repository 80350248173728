import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { HeaderBar } from 'components/v2/HeaderBar';

import { SidebarNavigationContent } from 'components/mainComponents/SidebarNavigationContent';
import { useParams } from 'react-router-dom';
import useConnectionLogMutation from './useConnectionLogMutation';
import './LeftMenuLayout.scss';
export function LeftMenuLayout(props) {
  const params = useParams();
  const trainingSessionId = params.training_session_id;
  useConnectionLogMutation(trainingSessionId);

  return (
    <div className='V2_LeftMenuLayout'>
      <HeaderBar
        trainingSessionId={trainingSessionId}
        forceShowMenuTrigger={props.forceHideMenu}
        className='V2_LeftMenuLayout__header'
      />
      <div className='V2_LeftMenuLayout__body'>
        {!props.forceHideMenu && (
          <div className='V2_LeftMenuLayout__nav'>
            <SidebarNavigationContent trainingSessionId={trainingSessionId} />
          </div>
        )}
        <main className={clsx('V2_LeftMenuLayout__content', { 'V2_LeftMenuLayout__content--no-menu': props.forceHideMenu })}>
          {props.children}
          <div className='V2_LeftMenuLayout__bottom-spacer'></div>
        </main>
      </div>
    </div>
  );
}

LeftMenuLayout.propTypes = {
  forceHideMenu: PropTypes.bool,
  children: PropTypes.node,
};
