import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ATTENDANCE_SIGNING,
  ATTENDANCE_SIGNING_DONE,
  MESSAGE_INIT_SIGNING,
  MESSAGE_SIGNATURE_DONE,
  SIGNATURE_LIST,
  STATUS_BAR_ICONS,
  STATUS_BAR_TYPES,
} from '../../../constants/video_conference';
import { broadcastMessage, listenMessage } from '../../../helpers/video_conference/messaging';
import { useModalDispatch } from '../useModal';
import { useStatusBarsDispatch } from '../useStatusBars';
import styles from '../../../scss/video_conference/StatusBar.module.scss';
import { useNotificationsDispatch } from 'hooks/useNotifications';
import { ADD_NOTIFICATIONS } from 'actions/video_conference';
import { useAuth } from 'contexts/AuthContext';
import { GUEST_TYPE } from 'support/auth';
import SignatureListStatusBar from 'components/video_conference/SignatureList/SignatureListStatusBar';
import useSignatureList from '../useSignatureList';
import { useDaily } from '@daily-co/daily-react';

function useAttendanceSigningListener(initiateListener = false) {
  const { auth } = useAuth();
  const callObject = useDaily();
  const { t } = useTranslation();

  const { addStatusBar, closeStatusBar } = useStatusBarsDispatch();

  const { openModal } = useModalDispatch();

  const dispatchNotifications = useNotificationsDispatch();

  const [signedParticipants, setSignedParticipant] = useSignatureList();

  const guestType = auth?.guestInfo?.type;
  const guestId = auth?.guestInfo?.id;
  const isSignatureListAccessible = [GUEST_TYPE.INSTRUCTOR, GUEST_TYPE.USER].includes(guestType);

  const handleSigningClick = () => openModal(ATTENDANCE_SIGNING);

  const SigningStatusBarContent = () => {
    return (
      <>
        {t('StatusBars.attendance_signing.messageBeginning')}
        <button className={styles.barButton} onClick={handleSigningClick}>
          {t('StatusBars.attendance_signing.messageButton')}
        </button>
        {t('StatusBars.attendance_signing.messageEnding')}
      </>
    );
  };

  const SignatureConfirmationContent = () => {
    return (
      <>
        {t('AttendanceSignModal.signature_confirmation')}
        <button type='button' onClick={() => closeStatusBar(ATTENDANCE_SIGNING_DONE)} className={styles.barButton}>
          {t('AttendanceSignModal.close')}
        </button>
      </>
    );
  };

  const dispatchAttendanceSigningActions = useCallback(() => {
    dispatchNotifications({
      action: ADD_NOTIFICATIONS,
      payload: {
        newNotifications: [
          {
            id: ATTENDANCE_SIGNING,
            title: t('Notification.attendance_signing.title'),
            text: t('Notification.attendance_signing.text'),
            onActivate: () => openModal(ATTENDANCE_SIGNING),
          },
        ],
      },
    });

    addStatusBar([
      {
        id: ATTENDANCE_SIGNING,
        label: ATTENDANCE_SIGNING,
        emoji: STATUS_BAR_ICONS[ATTENDANCE_SIGNING],
        type: STATUS_BAR_TYPES.SINGLE,
        content: <SigningStatusBarContent />,
      },
    ]);

    isSignatureListAccessible &&
      addStatusBar([
        {
          id: SIGNATURE_LIST,
          label: SIGNATURE_LIST,
          emoji: STATUS_BAR_ICONS[SIGNATURE_LIST],
          type: STATUS_BAR_TYPES.SINGLE,
          content: <SignatureListStatusBar />,
        },
      ]);
  }, [addStatusBar, dispatchNotifications, t, openModal, isSignatureListAccessible]);

  const initSigning = () => {
    broadcastMessage(callObject, {
      messageType: MESSAGE_INIT_SIGNING,
      payload: { isSigningInitiated: true },
    });

    dispatchAttendanceSigningActions();
  };

  const setSignatureDone = () => {
    isSignatureListAccessible && setSignedParticipant(guestId);

    broadcastMessage(callObject, {
      messageType: MESSAGE_SIGNATURE_DONE,
      payload: { guestId, guestType },
      targetRoles: [GUEST_TYPE.INSTRUCTOR.toLowerCase(), GUEST_TYPE.USER.toLowerCase()],
    });
    closeStatusBar(ATTENDANCE_SIGNING);
    addStatusBar([
      {
        id: ATTENDANCE_SIGNING_DONE,
        label: ATTENDANCE_SIGNING_DONE,
        emoji: STATUS_BAR_ICONS[ATTENDANCE_SIGNING],
        type: STATUS_BAR_TYPES.SINGLE,
        content: <SignatureConfirmationContent />,
      },
    ]);
  };

  useEffect(() => {
    initiateListener &&
      listenMessage(callObject, MESSAGE_INIT_SIGNING, () => {
        dispatchAttendanceSigningActions();
      });
  }, [callObject, initiateListener, dispatchAttendanceSigningActions, signedParticipants, guestId]);

  useEffect(() => {
    listenMessage(callObject, MESSAGE_SIGNATURE_DONE, data => {
      setSignedParticipant(data.payload.guestId);
    });
  }, [callObject, setSignedParticipant]);

  return { initSigning, setSignatureDone };
}

export default useAttendanceSigningListener;
