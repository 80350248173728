import React from 'react';
import { withAuth } from 'contexts/AuthContext';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withData } from '../../support/page';
import { ActionButton } from 'components/mainComponents/buttons';
import PageHeader from './PageHeader';
import TrainingSessionList from '../training_session/TrainingSessionList';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';

const TraineeRootPage = ({ trainee }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='page TraineeRootPage'>
      <PageHeader />
      <main>
        <ActionButton icon='arrow--left' label={t('TraineeRootPage.go_back')} clickFn={() => navigate(-1)} type='secondary' />
        <h1>{t('TraineeRootPage.all_training_sessions')}</h1>
        <TrainingSessionList training_sessions={trainee.training_sessions} />
      </main>
    </div>
  );
};

const query = gql`
  query trainee_root_page($id: ID!) {
    trainee(id: $id) {
      id
      firstname
      lastname
      training_sessions {
        id
        name
        custom_name
        start_date
        image {
          url
        }
      }
    }
  }
`;

const withGraphqlData = graphql(query, {
  name: 'trainee',
  options: props => {
    return {
      variables: {
        id: props?.auth?.guestInfo?.id,
      },
    };
  },
});

// prettier-ignore
export default
  withAuth(
    withGraphqlData(
      withData('trainee')(
        TraineeRootPage, { loading: LoadingPagePlaceholder })));
