import React, { useState } from 'react';
import _ from 'lodash';

import '../../../scss/form_inputs/FormInputCheckbox.scss';

const FormInputCheckbox = props => {
  const [answerGiven, setAnswerGiven] = useState([]);

  const onAnswer = value => {
    let newAnswer;
    if (_.includes(answerGiven, value)) {
      newAnswer = _.without(answerGiven, value);
    } else {
      newAnswer = _.union(answerGiven, [value]);
    }
    setAnswerGiven(newAnswer);
    props.onAnswer(props.input.id, newAnswer);
  };

  const data = JSON.parse(props.input.data);
  const correctAnswers = _.filter(data.answers, a => a.correct);
  const answerDisplayed = props.answerSent !== null && props.answerSent !== undefined ? props.answerSent : answerGiven;

  let correctClass = '';
  if (props.correcting && correctAnswers.length > 0) {
    const correct =
      answerDisplayed.length > 0 &&
      correctAnswers.length === answerDisplayed.length &&
      _.every(answerDisplayed, a => data.answers[a].correct === true);
    correctClass = correct ? 'correct' : 'wrong';
  }

  return (
    <div className={`FormInputCheckbox FormInput ${props.isLive ? 'live' : ''} ${correctClass}`}>
      <p className={`question ${data.question.length < 80 ? 'short-question' : ''}`}>{data.question}</p>

      <div className='answers'>
        {data.answers.map((answer, i) => (
          <div className={`answer ${_.includes(answerDisplayed, i) ? 'selected' : ''}`} key={i}>
            <input type='checkbox' />
            <div className='check' onClick={() => onAnswer(i)} />
            <label onClick={() => onAnswer(i)}>{answer.text}</label>
          </div>
        ))}
      </div>

      {props.correcting && data.correct_answer && data.correct_answer.length > 0 && (
        <p className='correct-answer'>{data.correct_answer}</p>
      )}
    </div>
  );
};

export default FormInputCheckbox;
