import React from 'react';
import ItemsCollection from 'components/mainComponents/ItemsCollection';
import DocumentLink from './DocumentLink';

const DocumentList = ({ documents }) => {
  return (
    <ItemsCollection>
      {documents.map((d, idx) => (
        <DocumentLink key={idx} document={d} />
      ))}
    </ItemsCollection>
  );
};

export default DocumentList;
