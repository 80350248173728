import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './ColorConfigurationPage.scss';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Button } from 'components/mainComponents/Button';
import { Checkbox } from 'components/mainComponents/CheckboxV2';
import { ColorPicker } from 'components/mainComponents/ColorPicker';
import { Heading } from 'components/mainComponents/Heading';
import { IconButton } from 'components/mainComponents/IconButton';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';
import { useColorConfiguration, useColorConfigurationLoader, VARIANTS } from 'components/v2/ColorConfiguration';
import { CommonPagesLayout } from 'components/v2/layouts/CommonPagesLayout';

export function ColorConfigurationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [colors, updateColor, resetConfiguration] = useColorConfiguration();
  const { saveConfiguration, isSavingColorConfiguration, saveColorConfigurationError, saveColorConfigurationData } =
    useColorConfigurationLoader();
  const [automaticColorPickers, setAutomaticColorPickers] = useState(() => new Set(getAllColorVariantNames()));

  if (saveColorConfigurationError) {
    console.error('Error saving color configuration', saveColorConfigurationError);
  }

  useEffect(() => {
    return resetConfiguration;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommonPagesLayout>
      <Breadcrumb>
        <Breadcrumb.Item>{t('ColorConfigurationPage.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <Button className='ColorConfigurationPage__go-back' onClick={() => navigate(-1)}>
        {t('ColorConfigurationPage.go_back')}
      </Button>
      <section>
        <Heading level={1}>{t('ColorConfigurationPage.title')}</Heading>
        <p className='ColorConfigurationPage__description'>{t('ColorConfigurationPage.description')}</p>

        <div className='ColorConfigurationPage__content'>
          <div className='ColorConfigurationPage__colors-list'>
            {Object.values(colors).map(color => {
              const autoVariants = getColorAutoVariants(color);

              return (
                <Fragment key={color.name}>
                  <ColorPicker
                    label={t(color.displayNameTranslationKey)}
                    value={color.value}
                    onChange={value => updateColor(color.name, { value, autoVariants })}
                  />
                  {VARIANTS.map(variant => (
                    <div className='ColorConfigurationPage__automated-color-picker' key={color[variant].name}>
                      <ColorPicker
                        label={t(color[variant].displayNameTranslationKey)}
                        value={color[variant].value}
                        onChange={value => updateColor(color.name, { [variant]: value })}
                        disabled={autoVariants.includes(variant)}
                      />
                      <Checkbox
                        checked={autoVariants.includes(variant)}
                        onCheckedChange={isChecked => setColorPickerAutomatic(isChecked, color.name, variant)}
                      >
                        {t('ColorConfigurationPage.auto_color_label')}
                      </Checkbox>
                    </div>
                  ))}
                </Fragment>
              );
            })}
          </div>

          <div className='ColorConfigurationPage__preview'>
            <Heading level={4}>{t('ColorConfigurationPage.preview_title')}</Heading>
            <RoundedIcon icon='flag' background='primary' />
            <RoundedIcon icon='paper-plane--filled' background='secondary' />
            <RoundedIcon icon='star' background='alt-1' />
            <RoundedIcon icon='sign' background='alt-2' />
            <RoundedIcon icon='folder' background='alt-3' />
            <Button>{t('ColorConfigurationPage.test_button_label')}</Button>
            <IconButton icon='gear' />
            <IconButton icon='gear' variant='primary' />
          </div>
        </div>

        <p className='ColorConfigurationPage__actions'>
          <Button onClick={resetConfiguration} variant='text'>
            {t('ColorConfigurationPage.discard')}
          </Button>
          <Button onClick={() => saveConfiguration(colors)} disabled={isSavingColorConfiguration}>
            {t('ColorConfigurationPage.save')}
          </Button>
          {saveColorConfigurationData && (
            <p className='ColorConfigurationPage__success' role='alert'>
              {t('ColorConfigurationPage.success')}
            </p>
          )}
        </p>
      </section>
    </CommonPagesLayout>
  );

  function setColorPickerAutomatic(isAutomatic, baseColorName, variant) {
    const variantColorName = colors[baseColorName][variant].name;

    if (isAutomatic) {
      setAutomaticColorPickers(previous => new Set(previous.add(variantColorName)));
      updateColor(baseColorName, { value: colors[baseColorName].value, autoVariants: [variant] });
    } else {
      setAutomaticColorPickers(previous => {
        previous.delete(variantColorName);
        return new Set(previous);
      });
    }
  }

  function getColorAutoVariants(color) {
    return VARIANTS.reduce((autoVariants, variant) => {
      if (automaticColorPickers.has(color[variant].name)) {
        autoVariants.push(variant);
      }

      return autoVariants;
    }, []);
  }

  function getAllColorVariantNames() {
    return Object.values(colors).reduce((variantNames, color) => {
      variantNames.push(...VARIANTS.map(variant => color[variant].name));
      return variantNames;
    }, []);
  }
}
