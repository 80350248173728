import clsx from 'clsx';
import PropTypes from 'prop-types';

import './Illustration.scss';
import illustrationsSprite from './illustrations.svg';
import { illustrationNames } from 'components/mainComponents/Illustration/illustrationNames';

export function Illustration(props) {
  const animationName = getAnimationName(props.name);
  const useStandardSize = !props.width && !props.height;
  const classname = clsx(
    'Illustration',
    {
      [`Illustration--size-${props.size}`]: useStandardSize,
      [`Illustration--animate-${animationName}`]: !!animationName,
    },
    props.className
  );

  return (
    <svg className={classname} width={props.width ?? props.height} height={props.height ?? props.width}>
      <use href={`${illustrationsSprite}#${props.name}`} />
    </svg>
  );
}

function getAnimationName(svgName) {
  // See end of file why this convoluted way of handling animations.

  switch (svgName) {
    case 'loading-spinner-full':
    case 'loading-spinner-half':
    case 'loading-spinner-one-quarter':
    case 'loading-spinner-three-quarters':
      return 'full-rotation';
    default:
      return null;
  }
}

Illustration.propType = {
  name: PropTypes.oneOf(illustrationNames).isRequired,
  size: PropTypes.oneOf(['md', 'lg']),
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

Illustration.defaultProps = {
  size: 'md',
};

/*
 * Why so much work for animations?
 * Using SVG sprites means using shadow DOMs, which comes with constraints.
 * One of those is that CSS can't pierce the shadow DOM. Because of that, we can't define
 * any style outside of the symbols (so no global _svg.scss file as we've had previously.)
 * WARNING: Gecko seems to be less strict regarding shadow DOM piercing, but Webkit and Blink
 * follow the SVG and HTML specs. Always test SVGs in WebKit- or Blink-based browsers.
 *
 * Also, there's an old issue on WebKit's bug tracker about the fact that CSS animations inside
 * SVG symbols don't work. See https://bugs.webkit.org/show_bug.cgi?id=173154.
 * Consequence: can't use them in our sprites or all browsers won't work.
 *
 * Lastly, SMILs in symbols are stripped when the symbol is used in WebKit & Blink.
 * I don't know why but that makes using SMIL impossible.
 * What is SMIL ? --> https://developer.mozilla.org/en-US/docs/Web/SVG/SVG_animation_with_SMIL.
 *
 * My last resort to avoid code repetition is to let the consumer of the SVG apply the animation
 * (so on the `<use />` tag.)
 */
