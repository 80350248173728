import { useState, useEffect } from 'react';
import _ from 'lodash';
import useProblemIndicatorPersistence from '../useProblemIndicatorPersistence';

// Remove after replacing local storage with GraphQL
export function storeQualityVerdict(qualityThreshold) {
  if (qualityThreshold) localStorage.setItem('qualityThreshold', qualityThreshold);
}

function useQualityPersistence(qualityThreshold, problemIndicators) {
  const persistProblemIndicators = useProblemIndicatorPersistence();

  const [startTs] = useState(Date.now(), []);

  // Storing quality verdict into local storage every time performance data changes
  useEffect(() => {
    storeQualityVerdict(qualityThreshold);
    persistProblemIndicators(problemIndicators, { localOnly: true });
  }, [qualityThreshold, problemIndicators, persistProblemIndicators]);

  useEffect(() => {
    // Only update problem indicators if at least 30 seconds have passed
    // since call start. During this initial period, indicators may inaccurately
    // show no error even though an initial pre-call test might have shown errors.
    if (Date.now() - startTs >= 30000 && _.every(problemIndicators, value => value !== undefined)) {
      persistProblemIndicators(problemIndicators);
    }
  }, [problemIndicators, persistProblemIndicators, startTs]);
}

export default useQualityPersistence;
