// PRE-CALL STEPS

export const PRE_CALL_STEP = 'PRE_CALL_STEP';
export const SET_UP_INITIAL_STEP = 'SET_UP_INITIAL_STEP';
export const SET_UP_WIZARD_STEP = 'SET_UP_WIZARD_STEP';
export const MIC_STEP = 'MIC_STEP';
export const WEBCAM_STEP = 'WEBCAM_STEP';
export const CONNECTION_TEST_STEP = 'CONNECTION_TEST_STEP';
export const SET_UP_CHECKLIST_STEP = 'SET_UP_CHECKLIST_STEP';

// TEST STATES
export const INITIAL_TEST_STATE = 'INITIAL_TEST_STATE';
export const PENDING_TEST_STATE = 'PENDING_TEST_STATE';
export const SUCCESS_TEST_STATE = 'SUCCESS_TEST_STATE';
export const ERROR_TEST_STATE = 'ERROR_TEST_STATE';
export const SPEED_TESTING_STATE = 'SPEED_TESTING_STATE';
export const CONNECTION_TESTING_STATE = 'CONNECTION_TESTING_STATE';
export const ADJUSTED_TEST_STATE = 'ADJUSTED_TEST_STATE';
export const ABORTED_TEST_STATE = 'ABORTED_TEST_STATE';
export const FAILED_TEST_STATE = 'FAILED_TEST_STATE';

// MIC TEST STATES
export const PLAYBACK = 'PLAYBACK';
export const RECORDED = 'RECORDED';
export const RECORDING = 'RECORDING';
export const NOT_RECORDED = 'NOT_RECORDED';
