import React from 'react';
import PropTypes from 'prop-types';

import AccountInfo from './AccountInfo';
import GuestInfo from 'components/GuestInfo';
import Menu from '../Menu';

import '../../../scss/mainComponents/HeaderBar.scss';

const HeaderBar = props => {
  const { links = [] } = props;

  return (
    <div className='header-bar'>
      <Menu links={links} slotCount={4} />
      <div className='header-bar__right-info'>
        <AccountInfo />
        <GuestInfo />
      </div>
    </div>
  );
};

HeaderBar.propTypes = {
  data: PropTypes.object,
};

HeaderBar.defaultProps = {};

export default HeaderBar;
