import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../scss/TextedUserConfused.scss';
import { Illustration } from 'components/mainComponents/Illustration';

export function TextedUserConfused() {
  const { t } = useTranslation();
  return (
    <div className='TextedUserConfused'>
      <Illustration name='user-confused' size='lg' />
      <p>{t('TextUserConfused.no_session')}</p>
    </div>
  );
}
