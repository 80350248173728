import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withAuth } from 'contexts/AuthContext';
import { withData } from '../../../support/page';
import { clsx } from 'clsx';

import { useThemeColorSetter } from 'components/mainComponents/CustomColorChooser/ThemeContext';

import ImageChip from './ImageChip';

import '../../../scss/mainComponents/AccountInfo.scss';
import { LoadingPlaceholder } from 'support/page';

const Logo = ({ url }) => <ImageChip url={url} />;

const Title = ({ name }) => <div className='accountInfo__title'>{name}</div>;

export const AccountInfo = ({ accountInfos, className: additionalClassName }) => {
  const className = clsx('accountInfo', additionalClassName);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { company, firstname, lastname, logo, extranetCustomColor } = accountInfos;

  const setCustomColor = useThemeColorSetter();

  const name = company || `${firstname} ${lastname}`;
  const logoUrl = logo?.url;
  const title = t('Header.about', { name });

  useEffect(() => {
    setCustomColor(extranetCustomColor);
  }, [extranetCustomColor, setCustomColor]);

  return (
    <button
      className={className}
      onClick={() => {
        navigate('/about');
      }}
      title={title}
      aria-label={title}
    >
      {logoUrl && <Logo url={logoUrl} />}
      {!logoUrl && <Title name={name} />}
    </button>
  );
};

export const ACCOUNT_INFO_QUERY = gql`
  query accountInfos($userCode: String!) {
    accountInfos(userCode: $userCode) {
      id
      firstname
      lastname
      company
      extranetCustomColor
      logo {
        url
      }
    }
  }
`;

export const LoadingAccountInfo = () => {
  return <LoadingPlaceholder className='accountInfo--loading accountInfo' />;
};

const withGraphqlData = graphql(ACCOUNT_INFO_QUERY, {
  name: 'accountInfos',
  options: props => {
    return {
      variables: {
        userCode: props?.auth?.userCode,
      },
    };
  },
});

export default withAuth(withGraphqlData(withData('accountInfos')(AccountInfo, { loading: LoadingAccountInfo })));
