import PropTypes from 'prop-types';
import AppPropTypes from 'propTypes';
import React, { useEffect, useRef } from 'react';

const CornerCam = ({ tile, className }) => {
  const cornerVideoRef = useRef(null);

  useEffect(() => {
    const cornerVideo = cornerVideoRef.current;
    if (cornerVideo && tile.videoTrack) {
      cornerVideo.srcObject = new MediaStream([tile.videoTrack]);
    }

    return () => {
      if (!cornerVideo) return;
      cornerVideo.scrObject = null;
    };
  }, [cornerVideoRef, tile.videoTrack]);

  return tile.screen && tile.video && <video autoPlay muted playsInline className={className} ref={cornerVideoRef} />;
};

CornerCam.propTypes = {
  tile: AppPropTypes.tile.isRequired,
  className: PropTypes.string.isRequired,
};

export default CornerCam;
