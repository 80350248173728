import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/video_conference/Avatar';
import avatarStyles from 'scss/video_conference/AvatarTile.module.scss';

const VideoPlaceholder = ({ className, styles, userName, imageUrl, isFeatured }) => (
  <div className={className}>
    <div className={avatarStyles.avatar}>
      <Avatar src={imageUrl} alt={userName} icon='user-boy' className={avatarStyles.avatar__content} shape='square' />
    </div>
    {!isFeatured && <span className={styles.caption}>{userName}</span>}
  </div>
);

VideoPlaceholder.propTypes = {
  className: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    avatar: PropTypes.string,
    caption: PropTypes.string,
  }).isRequired,
  userName: PropTypes.string,
  imageUrl: PropTypes.string,
  isFeatured: PropTypes.bool,
};

VideoPlaceholder.defaultProps = {
  isFeatured: false,
  userName: undefined,
};

export default VideoPlaceholder;
