import { useMutation } from '@apollo/client';
import { JOIN_TEST_ROOM_MUTATION } from 'mutations/video_conference';
import { useCallback, useEffect, useState, useRef } from 'react';
import {
  PENDING_TEST_STATE,
  ERROR_TEST_STATE,
  INITIAL_TEST_STATE,
  SUCCESS_TEST_STATE,
  SPEED_TESTING_STATE,
  ADJUSTED_TEST_STATE,
  BITRATE_THRESHOLD,
  NETWORK_QUALITY_LOW,
  NETWORK_QUALITY_GOOD,
  SEND_SETTINGS_FOR_LOW_QUALITY,
  SEND_SETTINGS_FOR_HIGH_QUALITY,
  CONNECTION_TESTING_STATE,
  PRE_CALL_CONNECTION_QUALITY_BAD,
  PRE_CALL_CONNECTION_QUALITY_WARNING,
  PRE_CALL_CONNECTION_QUALITY_ABORTED,
  PRE_CALL_CONNECTION_QUALITY_FAILED,
  ABORTED_TEST_STATE,
  FAILED_TEST_STATE,
} from 'constants/video_conference';
import { useTranslation } from 'react-i18next';
import { storeQualityVerdict } from 'hooks/video_conference/useQualityPersistence';
import { useDaily, useLocalParticipant } from '@daily-co/daily-react';
import { getGuestType, GUEST_TYPE } from 'support/auth';
import useSpeedTest from 'hooks/video_conference/useSpeedTest';
import usePreCallState from 'hooks/video_conference/usePreCall';
import { useCallStatusDispatch } from 'hooks/video_conference';
import { UPDATE_QUALITY } from 'actions/video_conference';
import { getLocalConfig } from 'helpers/video_conference';
import useDevicesToggle from 'hooks/video_conference/useDevicesToggle';
import * as Sentry from '@sentry/react';

function useConnectionTest(isActive) {
  const [testState, setTestState] = useState(INITIAL_TEST_STATE);
  const [testError, setTestError] = useState(null);
  const [mainToken, setMainToken] = useState(null);
  const [connectionTestResult, setConnectionTestResults] = useState(null);
  const [bandwidthSettings, setBandwidthSettings] = useState({});
  const [timedOut, setTimedOut] = useState(false);
  const [shouldCamOff, setShouldCamOff] = useState(false);

  const [mutate, { data }] = useMutation(JOIN_TEST_ROOM_MUTATION, { errorPolicy: 'all' });
  const { t } = useTranslation();
  const testTimeout = useRef(null);
  const callObject = useDaily();
  const localParticipant = useLocalParticipant();
  const { toggleCamera, isCameraMuted } = useDevicesToggle();

  const { startSpeedTest, resetSpeedTest, isUploadFinished, speedTestError, speedTestResults } = useSpeedTest();
  const dispatch = useCallStatusDispatch();
  const { setDebugNetworkProps } = usePreCallState();
  const localConfig = getLocalConfig();
  const videoQualityThreshold = localConfig.videoQualityThreshold && localConfig.videoQualityThreshold;

  const isTesting = [SPEED_TESTING_STATE, CONNECTION_TESTING_STATE].includes(testState);
  const isConnectionQualityLow = [PRE_CALL_CONNECTION_QUALITY_BAD, PRE_CALL_CONNECTION_QUALITY_WARNING].includes(
    connectionTestResult?.result
  );
  const guestType = getGuestType().toUpperCase();
  const problems = [
    {
      title: t('ConnectionTestStep.network_issues'),
      items: [isConnectionQualityLow && t('ConnectionTestStep.network_issues.packet_loss')],
    },
    {
      title: t('ConnectionTestStep.adjustments'),
      items: [t('ConnectionTestStep.adjustments.bandwidth', { kbs: bandwidthSettings?.kbs })],
    },
  ];
  const suggestions = [t('ConnectionTestStep.network_issues.example_1'), t('ConnectionTestStep.network_issues.example_2')];
  const videoTrack = localParticipant.tracks.video.persistentTrack;

  const resetTest = useCallback(() => {
    resetSpeedTest();
    setTestState(INITIAL_TEST_STATE);
    setTimedOut(false);
  }, [resetSpeedTest]);

  const startTest = useCallback(() => {
    resetTest();
    if (testState !== SPEED_TESTING_STATE) {
      setTestState(SPEED_TESTING_STATE);
      startSpeedTest();
    }
  }, [startSpeedTest, resetTest, testState]);

  const testConnectionQuality = useCallback(async () => {
    const testResults =
      videoTrack &&
      (await callObject.testConnectionQuality({
        videoTrack,
        duration: 15,
      }));

    if (testResults) setConnectionTestResults(testResults);
    if (shouldCamOff) toggleCamera();
    if (isConnectionQualityLow) {
      Sentry.captureMessage('Connection quality is not optimal', { extra: { data: testResults.data } });
    }
  }, [callObject, isConnectionQualityLow, shouldCamOff, toggleCamera, videoTrack]);

  const manageTestState = () => {
    if (isActive && testState === INITIAL_TEST_STATE) startTest();
    if (speedTestError) setTestState(ERROR_TEST_STATE);
    if (!isActive && testState === PENDING_TEST_STATE) {
      setTestState(INITIAL_TEST_STATE);
      clearTimeout(testTimeout.current);
      resetTest();
    }
  };

  const startConnectionTest = () => {
    if (isUploadFinished && testState === SPEED_TESTING_STATE) {
      if (isCameraMuted) {
        toggleCamera();
        setShouldCamOff(true);
      }
      if (videoTrack) setTestState(CONNECTION_TESTING_STATE);
    }

    if (testState === CONNECTION_TESTING_STATE) {
      testTimeout.current = setTimeout(() => {
        setTimedOut(true);
      }, 10000);
      testConnectionQuality().catch(error => setTestError(error));
      setTestState(PENDING_TEST_STATE);
    }
  };

  const clearTimers = () => {
    return () => clearTimeout(testTimeout.current);
  };

  const setToken = () => {
    if (data) {
      setMainToken(data.join_test_room.token);
    } else {
      mutate().catch(() => setTestState(ERROR_TEST_STATE));
    }
  };

  const setFinalTestResults = () => {
    if (isUploadFinished && connectionTestResult?.result) {
      const { downloadSpeed } = speedTestResults;
      setTimedOut(false);
      if (connectionTestResult?.result === PRE_CALL_CONNECTION_QUALITY_ABORTED) {
        setTestState(ABORTED_TEST_STATE);
      } else if (connectionTestResult?.result === PRE_CALL_CONNECTION_QUALITY_FAILED) {
        setTestState(FAILED_TEST_STATE);
      } else if (isConnectionQualityLow || downloadSpeed < BITRATE_THRESHOLD) {
        if (guestType === GUEST_TYPE.INSTRUCTOR || guestType === GUEST_TYPE.USER) {
          callObject.updateSendSettings({ video: SEND_SETTINGS_FOR_LOW_QUALITY }).then(sendSettings => {
            setBandwidthSettings({ kbs: sendSettings.video.encodings.high?.maxBitrate || null });
          });
          dispatch({
            action: UPDATE_QUALITY,
            payload: {
              threshold: NETWORK_QUALITY_LOW,
            },
          });
          storeQualityVerdict(NETWORK_QUALITY_LOW);
          setTestState(ADJUSTED_TEST_STATE);
        }
        if (guestType === GUEST_TYPE.TRAINEE) {
          toggleCamera();
          setTestState(ADJUSTED_TEST_STATE);
        }
      } else {
        if (videoQualityThreshold !== NETWORK_QUALITY_LOW) {
          callObject.updateSendSettings({ video: SEND_SETTINGS_FOR_HIGH_QUALITY }).then(sendSettings => {
            setBandwidthSettings({ kbs: sendSettings.video.encodings.high?.maxBitrate || null });
          });
        }
        dispatch({
          action: UPDATE_QUALITY,
          payload: {
            threshold: NETWORK_QUALITY_GOOD,
          },
        });
        storeQualityVerdict(NETWORK_QUALITY_GOOD);
        setTestState(SUCCESS_TEST_STATE);
      }
    }
  };

  const setDebugWindowValues = () => {
    const debugProps = [
      `Constraints-requested kbs: ${bandwidthSettings?.kbs || 'N/A'}`,
      `Max round-time delay: ${connectionTestResult?.data.maxRTT}`,
      `Packet loss: ${connectionTestResult?.data.packetLoss}`,
    ];
    setDebugNetworkProps(debugProps);
  };

  useEffect(manageTestState, [isActive, startTest, resetTest, testState, speedTestError]);
  useEffect(startConnectionTest, [isCameraMuted, isUploadFinished, testState, toggleCamera, videoTrack, testConnectionQuality]);
  useEffect(setToken, [data, mutate]);
  useEffect(setDebugWindowValues, [setDebugNetworkProps, bandwidthSettings, connectionTestResult]);
  useEffect(clearTimers, []);
  useEffect(setFinalTestResults, [
    callObject,
    toggleCamera,
    guestType,
    isUploadFinished,
    speedTestResults,
    dispatch,
    videoQualityThreshold,
    connectionTestResult,
    isConnectionQualityLow,
  ]);

  return {
    testState,
    startTest,
    suggestions,
    problems,
    mainToken,
    timedOut,
    isTesting,
    testError,
  };
}

export default useConnectionTest;
