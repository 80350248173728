import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import styles from '../../../scss/video_conference/Tooltip.module.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

function Tooltip({ description, children, tooltipOnEvent, setTooltipOnEvent, timeout, id, className }) {
  const [isOpen, setOpen] = useState(tooltipOnEvent);

  useEffect(() => {
    if (tooltipOnEvent) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setTooltipOnEvent(false);
      }, timeout);
    }
  }, [tooltipOnEvent, setTooltipOnEvent, timeout]);

  return (
    <div className={className}>
      <div className={styles.icon} data-tooltip-id={id}>
        {children}
      </div>
      {description && (
        <ReactTooltip isOpen={isOpen} id={id} className={styles.tooltip}>
          {description}
        </ReactTooltip>
      )}
    </div>
  );
}

export default Tooltip;

Tooltip.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  timeout: PropTypes.number,
  setTooltipOnEvent: PropTypes.func,
  id: PropTypes.string.isRequired,
  tooltipOnEvent: PropTypes.bool,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  description: null,
  children: null,
  timeout: 5000,
  setTooltipOnEvent: null,
  tooltipOnEvent: false,
  className: '',
};
