import PropTypes from 'prop-types';

const AppPropTypes = {
  styles: PropTypes.shape({
    Tile: PropTypes.string,
    overlay: PropTypes.string,
    cornerIcons: PropTypes.string,
    cornerIcon: PropTypes.string,
    volumeMeter: PropTypes.string,
    flip: PropTypes.string,
    fullSegment: PropTypes.string,
    video: PropTypes.string,
    avatar: PropTypes.string,
    current: PropTypes.string,
    selected: PropTypes.string,
    featuredOverlay: PropTypes.string,
    noVideo: PropTypes.string,
  }),
  tile: PropTypes.shape({
    current: PropTypes.bool,
    primary: PropTypes.bool,
    userName: PropTypes.string,
    digiformaId: PropTypes.string,
    digiformaType: PropTypes.string,
    local: PropTypes.bool,
    sessionId: PropTypes.string,
    audio: PropTypes.bool,
    video: PropTypes.bool,
    screen: PropTypes.bool,
    audioTrack: PropTypes.instanceOf(MediaStreamTrack),
    videoTrack: PropTypes.instanceOf(MediaStreamTrack),
  }),
  videoCallQuality: PropTypes.shape({
    bandwidthSettings: PropTypes.string,
    camQualityVerdict: PropTypes.string,
  }),
};

export default AppPropTypes;

export const IDPropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export const AnswerEvaluationResultPropType = PropTypes.shape({
  score: PropTypes.number,
  total: PropTypes.number,
});

export const ChildrenPropType = {
  children: PropTypes.node,
};

export const CommentPropType = PropTypes.shape({
  author: PropTypes.string.isRequired,
  dailycoroom_id: IDPropType,
  id: IDPropType,
  inserted_at: PropTypes.string.isRequired,
  module_id: IDPropType,
  subcomments: PropTypes.arrayOf(
    PropTypes.shape({
      id: IDPropType,
      text: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      inserted_at: PropTypes.string.isRequired,
    })
  ).isRequired,
  text: PropTypes.string.isRequired,
  trainee_id: IDPropType,
  trainee_training_session_id: IDPropType,
});

export const CommentArrayPropType = PropTypes.arrayOf(CommentPropType);

export const DocumentPropType = PropTypes.shape({
  id: IDPropType,
  filename: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  mime: PropTypes.string.isRequired,
  downloadable: PropTypes.bool,
});

export const EmbedPropType = PropTypes.shape({
  id: IDPropType,
  name: PropTypes.string,
  url: PropTypes.string,
  embedly: PropTypes.string,
});

export const EmailMarkerPropType = PropTypes.shape({
  id: IDPropType,
  email: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
});

export const EvaluationPropType = PropTypes.shape({
  id: IDPropType,
  name: PropTypes.string,
  replayable: PropTypes.bool,
  type: PropTypes.string.isRequired,
});

export const GraphqlGuestType = PropTypes.shape({});

export const HtmlDocPropType = PropTypes.shape({
  id: IDPropType,
  html: PropTypes.string,
  css: PropTypes.string,
  editable_components: PropTypes.arrayOf(PropTypes.object),
  read: PropTypes.bool,
  replayable: PropTypes.bool,
  show_score: PropTypes.bool,
  show_validation: PropTypes.bool,
});

export const ImagePropType = PropTypes.shape({
  id: IDPropType,
  filename: PropTypes.string,
  mime: PropTypes.string,
  size: PropTypes.string,
  url: PropTypes.string,
});

export const LivestreamPropType = PropTypes.shape({
  id: IDPropType,
  start: PropTypes.string,
  url: PropTypes.string,
  use_virtualroom: PropTypes.bool,
  comments: PropTypes.arrayOf(CommentPropType),
});

export const MediaStreamTrackPropType = PropTypes.instanceOf(MediaStreamTrack);

export const ModuleCursorPropType = PropTypes.shape({
  module_sequence_item_id: IDPropType,
});

export const ModuleVisibilityModePropType = PropTypes.oneOf(['ALWAYS', 'DATE', 'NEVER']);

export const ModuleSequenceItemPropType = PropTypes.shape({
  id: IDPropType,
  name: PropTypes.string,
  description: PropTypes.string,
  available: PropTypes.bool,
  finished: PropTypes.bool,
  duration_min: PropTypes.number,
  precondition: PropTypes.string.isRequired,
  precondition_date: PropTypes.string,
  precondition_score: PropTypes.number,
  resource_id: PropTypes.number,
  resource_type: PropTypes.string.isRequired,
  scorable: PropTypes.bool,
  score: PropTypes.number,
});

export function NativeDatePropTypes(props, propName, componentName) {
  if (!(props[propName] instanceof Date)) {
    return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Value is not a native Date object.`);
  }
}

export const NotificationPropType = PropTypes.shape({
  id: IDPropType,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const PaletteColorPropType = PropTypes.oneOf(['primary', 'secondary', 'alt-1', 'alt-2', 'alt-3', 'grey']);

export const PendingEvaluationPropType = PropTypes.shape({
  id: IDPropType,
  pending: PropTypes.bool,
  trainee_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  evaluation: EvaluationPropType,
  answer_evaluation_result: AnswerEvaluationResultPropType,
});

export const RoomOptionsPropType = PropTypes.shape({
  module_id: PropTypes.string,
  dailycoroom_id: PropTypes.string,
});

export const ScormPropType = PropTypes.shape({
  id: IDPropType,
  name: PropTypes.string,
  url: PropTypes.string,
});

export const UploadMarkerPropType = PropTypes.shape({
  id: IDPropType,
  name: PropTypes.string,
  description: PropTypes.string,
  submittedUpload: PropTypes.shape({
    id: IDPropType,
    filename: PropTypes.string,
    comment: PropTypes.string,
    mimetype: PropTypes.string,
    score: PropTypes.number,
    trainee_id: IDPropType,
    url: PropTypes.string,
  }),
});

export const ModulePropType = PropTypes.shape({
  id: IDPropType,
  comments: PropTypes.arrayOf(CommentPropType),
  cursor: ModuleCursorPropType,
  description: PropTypes.string,
  documents: PropTypes.arrayOf(DocumentPropType),
  email_markers: PropTypes.arrayOf(EmailMarkerPropType),
  embeds: PropTypes.arrayOf(EmbedPropType),
  forum: PropTypes.bool,
  html_docs: PropTypes.arrayOf(HtmlDocPropType),
  image: ImagePropType,
  livestreams: PropTypes.arrayOf(LivestreamPropType),
  name: PropTypes.string,
  pending_evaluations: PropTypes.arrayOf(PendingEvaluationPropType),
  scorms: PropTypes.arrayOf(ScormPropType),
  sequence: PropTypes.arrayOf(ModuleSequenceItemPropType),
  show_score: PropTypes.bool,
  upload_markers: PropTypes.arrayOf(UploadMarkerPropType),
  virtualroom: PropTypes.bool,
  visibility_end_date: PropTypes.string,
  visibility_mode: ModuleVisibilityModePropType,
  visibility_start_date: PropTypes.string,
  visible: PropTypes.bool,
});
