import React from 'react';
import PropTypes from 'prop-types';

import { ActionWidget } from 'components/mainComponents/ActionWidget';
import { useTranslation } from 'react-i18next';

export function EvaluationsWidget({ pendingEvaluationsCount, children }) {
  const { t } = useTranslation();

  return (
    <ActionWidget
      icon='star'
      color='alt-1'
      title={t('EvaluationsWidget.title')}
      subtitle={t('EvaluationsWidget.pending_evaluations', {
        count: pendingEvaluationsCount,
      })}
    >
      {pendingEvaluationsCount > 0 && children}
    </ActionWidget>
  );
}

EvaluationsWidget.propTypes = {
  pendingEvaluationsCount: PropTypes.number.isRequired,
  children: PropTypes.node,
};
