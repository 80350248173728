import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './LogInPage.scss';
import { GUEST_SIGNIN } from './queries';
import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import { Input } from 'components/mainComponents/Input';
import { Paper } from 'components/mainComponents/Paper';
import { useAuth } from 'contexts/AuthContext';
import { Illustration } from 'components/mainComponents/Illustration';

export function LogInPage() {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [guestSignin, mutationState] = useMutation(GUEST_SIGNIN);
  const { data, loading, error, called } = mutationState;

  const formHandler = useCallback(
    async event => {
      event.preventDefault();
      const formData = new FormData(event.target);
      await guestSignin({
        variables: {
          email: formData.get('email'),
          user_code: auth?.userCode,
        },
      });
    },
    [auth?.userCode, guestSignin]
  );

  const infos = formDisplayState(data, loading, error, called, t);

  return (
    <div className='V2__LogInPage'>
      <Heading as='h1' level={1}>
        {t('SigninPage.connect')}
      </Heading>
      <>
        <Illustration name={infos.illustration} size='lg' />
        <Heading level={2}>{infos.title}</Heading>
        <Heading as='p' level={4}>
          {infos.subtitle}
        </Heading>
        {infos.requestForm && (
          <Heading as='p' level={4}>
            <Link className='V2__LogInPage__link' to='/login/request_access'>
              {t('SigninPage.make_a_request_to_speaker')}
            </Link>
          </Heading>
        )}
        {infos.loginForm && (
          <Paper className='V2__LogInPage__form' as='form' onSubmit={formHandler}>
            <Input className='V2__LogInPage__input' name='email' type='email' placeholder={t('AccessRequestPage.email')} />
            <Button className='V2__LogInPage__button' type='submit'>
              {t('SigninPage.send')}
            </Button>
          </Paper>
        )}
      </>
    </div>
  );
}

const formDisplayState = (data, loading, error, called, t) => {
  let infos = {
    title: t('SigninPage.guest_access'),
    subtitle: t('SigninPage.enter_email'),
    illustration: 'robot-levitation',
    loginForm: true,
    requestForm: false,
  };

  if (loading) {
    infos = {
      title: t('SigninPage.current_connection'),
      subtitle: '',
      loginForm: false,
      requestForm: false,
    };
  }
  if (error) {
    infos = {
      title: t('SessionTechnicalError.technical_error'),
      subtitle: '',
      illustration: 'robot-disappointed',
      loginForm: false,
      requestForm: false,
    };
  }
  if (data?.guestSignin?.error) {
    infos = {
      title: t('SigninPage.unrecognized_email'),
      subtitle: t('SigninPage.verify_email_address_and_try_again'),
      illustration: 'robot-disappointed',
      loginForm: true,
      requestForm: true,
    };
  }
  if (called && data && data.guestSignin && !data.guestSignin.error) {
    infos = {
      title: t('SigninPage.see_your_email_inbox'),
      subtitle: t('SigninPage.connection_email_sent_message'),
      illustration: 'robot-surfing',
      loginForm: false,
      requestForm: false,
    };
  }
  return infos;
};
