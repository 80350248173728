import React, { useState } from 'react';

import '../../../scss/form_inputs/FormInputScore.scss';

const FormInputScore = props => {
  const [answerGiven, setAnswerGiven] = useState(null);

  const onAnswer = value => {
    setAnswerGiven(value);
    props.onAnswer(props.input.id, value);
  };

  const data = JSON.parse(props.input.data);
  const answerDisplayed = props.answerSent !== null && props.answerSent !== undefined ? props.answerSent : answerGiven;

  return (
    <div className={`FormInputScore FormInput ${props.isLive ? 'live' : ''}`}>
      <p className={`question ${data.question.length < 80 ? 'short-question' : ''}`}>{data.question}</p>

      <div className='scores'>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
          <button key={i} className={answerDisplayed === i ? 'selected' : ''} onClick={() => onAnswer(i)}>
            {i}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FormInputScore;
