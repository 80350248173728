import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts/AuthContext';
import { ActionButton } from 'components/mainComponents/buttons';
import { SimpleInput } from 'components/mainComponents/inputs';
import { GUEST_SIGNIN } from './mutations';
import UnknownEmailError from './UnknowEmailError';
import TechnicalError from '../TechnicalError';
import Connecting from './Connecting';

import 'scss/session/SigninComponent.scss';

export default function SigninComponent({ mailSentFn }) {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const [guestSignin, mutationState] = useMutation(GUEST_SIGNIN);
  const { data, loading, error, called } = mutationState;
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (called && data && data.guestSignin && !data.guestSignin.error) {
      mailSentFn();
    }
  }, [mailSentFn, data, called]);

  const signin = async () => {
    await guestSignin({
      variables: {
        email: email,
        user_code: auth?.userCode,
      },
    });
  };

  return (
    <div className='SigninComponent layout-column layout-centered'>
      {error && <TechnicalError />}
      {data?.guestSignin?.error && <UnknownEmailError email={email} />}
      {loading && <Connecting />}
      <SimpleInput
        className='SigninComponent__email-input'
        type='email'
        value={email}
        placeholder='email'
        changeFn={newValue => setEmail(newValue)}
      />
      <ActionButton clickFn={(emailRegex.test(email) && signin) || null} label={t('SigninPage.send')} icon='login' />
    </div>
  );
}

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
