import React from 'react';

import HeaderBar from 'components/mainComponents/HeaderBar';
import { useTranslation } from 'react-i18next';

const PageHeader = () => {
  const { t } = useTranslation();

  const headerEntries = [
    {
      path: `/tutorials`,
      icon: 'play--circle', // wait for another icon
      alt: t('Header.tutorials'),
      label: t('Header.tutorials'),
    },
  ];

  return <HeaderBar links={headerEntries} />;
};

export default PageHeader;
