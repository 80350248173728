import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import roomStyles from '../../../scss/video_conference/Room.module.scss';
import normalTileStyles from '../../../scss/video_conference/NormalTile.module.scss';

function useBasicTile(containerRef) {
  const onKeyPress = e => {
    // it will trigger only if containerRef was focused (not any of it's children)
    // it adds support to nested focusable elements
    if (containerRef.current && e.target === containerRef.current && e.key === 'Enter') {
      containerRef.current.click();
    }
  };
  const getTileProps = ({ onClick, ...props }) => {
    if (onClick) {
      return {
        onClick,
        onKeyPress,
        role: 'button',
        tabIndex: '0',
        ...props,
      };
    }
    return props;
  };

  return {
    getTileProps,
  };
}

const BasicTile = ({ children, leftIcons, rightIcons, onClick, className, styles, style }) => {
  const containerRef = useRef();
  const { getTileProps } = useBasicTile(containerRef);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div style={style} ref={containerRef} {...getTileProps({ className, onClick })}>
      {children}

      <div className={`${styles.cornerIcons} ${roomStyles.left}`}>{leftIcons}</div>
      <div className={`${styles.cornerIcons} ${roomStyles.right}`}>{rightIcons}</div>
    </div>
  );
};

BasicTile.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  leftIcons: PropTypes.node,
  rightIcons: PropTypes.node,
  styles: PropTypes.shape({ cornerIcons: PropTypes.string }),
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

BasicTile.defaultProps = {
  children: undefined,
  onClick: undefined,
  leftIcons: undefined,
  rightIcons: undefined,
  styles: normalTileStyles,
  className: undefined,
  style: undefined,
};

export default BasicTile;
