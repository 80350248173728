import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import notificationStyles from '../../../scss/video_conference/Notification.module.scss';
import Notification from '../Notification';
import { NotificationPropType } from '../../../propTypes';

const Notifications = ({ notifications }) => {
  useEffect(() => {
    const activeNotification = notifications.find(notification => notification.active);

    if (activeNotification) {
      activeNotification.onActivate();
    }
  }, [notifications]);

  return (
    <div className={notificationStyles.notificationContainer}>
      {notifications.map(notification => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

Notifications.propTypes = {
  notifications: propTypes.arrayOf(NotificationPropType).isRequired,
};

export default Notifications;
