import React from 'react';
import { useTranslation } from 'react-i18next';

const StatusPreview = () => {
  const { t } = useTranslation();

  return (
    <div className='status preview'>
      <i className='icon-search text' />
      <svg viewBox='-1.2 -1.2 2.4 2.4'>
        <circle cx='0' cy='0' r='1' stroke='#80c98b' strokeWidth='0.2' fill='#transparent' />
        <textpath path='M 1 0 A 1 1 0 1 1 -1 0'>{t('ModulePage.activity_status_preview')}</textpath>
      </svg>
    </div>
  );
};

const StatusUnavailable = () => (
  <div className='status unavailable'>
    <svg viewBox='-1.2 -1.2 2.4 2.4'>
      <circle cx='0' cy='0' r='1' stroke='#becede' strokeDasharray='0.2,0.2' strokeWidth='0.2' fill='transparent' />
    </svg>
  </div>
);

const StatusFinished = () => (
  <div className='status finished'>
    <svg viewBox='-1.2 -1.2 2.4 2.4'>
      <circle cx='0' cy='0' r='1' stroke='#80c98b' strokeWidth='0.2' fill='transparent' />
    </svg>
    <i className='icon-check-circle' />
  </div>
);

export function StatusPendingScore({ score }) {
  const [ex, ey] = getCoordinatesForPercent(Math.max(score, 1)); // Avoid 0 coz' path d="A ... " won't work
  const largeArcFlag = score > 50 ? 1 : 0;
  const reverseLargeArcFlag = (largeArcFlag + 1) % 2;

  return (
    <div className='status pending'>
      <svg viewBox='-1.2 -1.2 2.4 2.4'>
        <circle cx='0' cy='0' r='1' fill='red' />
        <path d={`M 1 0 A 1 1 0 ${largeArcFlag} 1 ${ex} ${ey}`} strokeWidth='0.2' stroke='#80c98b' fillOpacity='0' />
        <path d={`M ${ex} ${ey} A 1 1 0 ${reverseLargeArcFlag} 1 1 0`} strokeWidth='0.2' stroke='#becede' fillOpacity='0' />
      </svg>
      <div className='score'>{score !== null ? `${Math.floor(score)}%` : '👁'}</div>
    </div>
  );
}

const StatusPendingNoScore = () => (
  <div className='status pending'>
    <svg viewBox='-1.2 -1.2 2.4 2.4'>
      <circle cx='0' cy='0' r='1' stroke='#becede' strokeWidth='0.2' fill='transparent' />
    </svg>
  </div>
);

function StatusPending({ activity }) {
  const { score, resource_type } = activity;
  return resource_type === 'module_scorm' ? <StatusPendingScore score={score} /> : <StatusPendingNoScore />;
}

export function Status({ activity, nextActivity = {}, previewMode }) {
  const { available, finished } = activity;
  const { precondition = '' } = nextActivity;

  if (previewMode) {
    return <StatusPreview />;
  }
  if (!available) {
    return <StatusUnavailable />;
  }
  if (precondition === 'score' && activity.score < nextActivity.precondition_score) {
    return <StatusPendingScore score={activity.score} />;
  }
  if (finished) {
    return <StatusFinished />;
  }
  return <StatusPending activity={activity} />;
}

function getCoordinatesForPercent(percent) {
  const angleRad = 2 * Math.PI * percent * 0.01;
  const x = Math.cos(angleRad);
  const y = Math.sin(angleRad);
  return [x, y];
}
