import _ from 'lodash';
import useDisplayModeComponent from 'hooks/useDisplayModeComponent';
import FeaturedSpeakerDisplayMode from '../CallBox/DisplayModes/FeaturedSpeakerDisplayMode';
import GridDisplayMode from '../CallBox/DisplayModes/GridDisplayMode';
import { useCurrentTileRenderer } from './TileRenderer';
import SmallTile from './SmallTile';

export const getTileClassName = (styles, { current, local }) => {
  const classes = _.compact([!local && current && styles.current, local && styles.local]);

  return [styles.Tile, ...classes].join(' ');
};

export const getVideoClassName = ({ styles, tile, displayedVideoTrack }) => {
  let classes;

  const { screenVideoTrack } = tile;

  const tileDisplaysScreen = displayedVideoTrack === screenVideoTrack;

  if (displayedVideoTrack) {
    classes = _.compact([tileDisplaysScreen && styles.screen]);
  } else {
    classes = [styles.noVideo];
  }

  return [styles.video, ...classes].join(' ');
};

export const useDisplayedVideoTrack = tile => {
  const displayModeComponent = useDisplayModeComponent();
  const tileRenderer = useCurrentTileRenderer();

  if (!tile) return null;

  switch (displayModeComponent) {
    case FeaturedSpeakerDisplayMode:
      if (tile.screen && tileRenderer !== SmallTile) {
        return tile.screenVideoTrack;
      }
      break;

    case GridDisplayMode:
      if (tile.screen) {
        return tile.screenVideoTrack;
      }
      break;

    default:
      throw new Error('Unknown displayModeComponent');
  }

  return tile.videoTrack;
};
