import { ActionButton } from 'components/mainComponents/buttons';
import IconButton from 'components/video_conference/IconButton';
import Tooltip from 'components/video_conference/Tooltip';
import { CONNECTION_TEST_STEP, MIC_STEP, NO_MIC_TOOLTIP, PRE_CALL_STEP, WEBCAM_STEP } from 'constants/video_conference';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../../../../scss/video_conference/SetUpWizardButtonTray.module.scss';
import usePreCallState from 'hooks/video_conference/usePreCall';
import PropTypes from 'prop-types';
import Icon from 'components/mainComponents/Icon';

function SetUpWizardButtonTray({ hasMicError, completedSteps }) {
  const { t } = useTranslation();
  const {
    goToNextSetUpStep,
    goToPrevStep,
    canGoToNextSetUpStep,
    canGoToPrevSetUpStep,
    currentStepName,
    setNamedStep,
    handleJoinCall,
  } = usePreCallState();

  const STEPS = [
    {
      id: MIC_STEP,
      clickFn: () => currentStepName !== MIC_STEP && setNamedStep(MIC_STEP),
      icon: 'microphone',
      isCompleted: completedSteps[MIC_STEP],
      ariaLabel: t('SetUpWizardButtonTray.microphone_test'),
    },
    {
      id: WEBCAM_STEP,
      clickFn: () => currentStepName !== WEBCAM_STEP && setNamedStep(WEBCAM_STEP),
      icon: 'video--on',
      isCompleted: completedSteps[WEBCAM_STEP],
      ariaLabel: t('SetUpWizardButtonTray.webcam_test'),
    },
    {
      id: CONNECTION_TEST_STEP,
      clickFn: () => currentStepName !== CONNECTION_TEST_STEP && setNamedStep(CONNECTION_TEST_STEP),
      icon: 'wifi',
      isCompleted: completedSteps[CONNECTION_TEST_STEP],
      ariaLabel: t('SetUpWizardButtonTray.connection_test'),
    },
  ];

  const areStepsCompleted = Object.values(completedSteps).every(value => value);

  const styleStepperButton = id => `${styles.icon} ${currentStepName !== id ? styles['icon--inactive'] : ''}`;

  const handleReturnToPreCall = () => setNamedStep(PRE_CALL_STEP);

  return (
    <div className={styles.buttonTray}>
      <div className={styles['button--left']}>
        <ActionButton
          label={t('SetUpWizardButtonTray.return')}
          type='secondary'
          clickFn={handleReturnToPreCall}
          className={styles['button--left__button']}
        />
      </div>
      <div className={styles.buttons}>
        <IconButton
          icon='arrow--left'
          clickFn={goToPrevStep}
          disabled={!canGoToPrevSetUpStep}
          type='secondary'
          ariaLabel={t('SetUpWizardButtonTray.go_to_previous_step')}
        />
        {STEPS.map(({ isCompleted, icon, id, clickFn, ariaLabel }, index) => (
          <React.Fragment key={id}>
            <span className={styles.icon__number}>
              {isCompleted ? <Icon icon='checkmark--circle' className={styles.icon__checkmark} /> : index + 1}
            </span>
            <IconButton icon={icon} className={styleStepperButton(id)} clickFn={clickFn} ariaLabel={ariaLabel} />
          </React.Fragment>
        ))}
        <IconButton
          icon='arrow--right'
          clickFn={goToNextSetUpStep}
          disabled={!canGoToNextSetUpStep}
          type='secondary'
          className={styles['icon--arrow']}
          ariaLabel={t('SetUpWizardButtonTray.go_to_next_step')}
        />
      </div>
      <div className={styles['button--right']}>
        <Tooltip description={hasMicError && t('SetUpWizardButtonTray.no_mic')} id={NO_MIC_TOOLTIP} className={styles.tooltip}>
          <ActionButton
            clickFn={!hasMicError && handleJoinCall}
            label={t('SetUpWizardButtonTray.join_the_call')}
            type={areStepsCompleted ? 'default' : 'secondary'}
            icon='arrow--right'
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default SetUpWizardButtonTray;

SetUpWizardButtonTray.propTypes = {
  hasMicError: PropTypes.bool.isRequired,
  completedSteps: PropTypes.objectOf(PropTypes.bool).isRequired,
};
