import React from 'react';
import PropTypes from 'prop-types';
import styles from 'scss/video_conference/AvatarSettings.module.scss';
import { AVATAR_CUSTOM, AVATAR_NONE } from 'constants/video_conference';
import { useTranslation } from 'react-i18next';
import Icon from 'components/mainComponents/Icon';

const FILE_TYPE = 'file';

function RenderAvatarSettingItem({
  avatarSettings: { value, labelKey, thumbSrc, icon, inputType, name },
  setAndMutateAvatar,
  pickFile,
  chosenAvatar,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.previewChecked} id={name}>
      {inputType === FILE_TYPE ? (
        <input type='radio' className={styles.avatarSettings} onClick={pickFile} value={value} name={name} checked={false} readOnly />
      ) : (
        <input
          type='radio'
          className={styles.avatarSettings}
          value={value}
          checked={chosenAvatar === value}
          name={name}
          onChange={setAndMutateAvatar}
        />
      )}
      {value === AVATAR_CUSTOM ? (
        <button type='button' className={`${styles.btnDelete}`} onClick={setAndMutateAvatar} value={AVATAR_NONE}>
          <span className={styles.iconCross}>&times;</span>
        </button>
      ) : null}
      {thumbSrc && <img src={thumbSrc} alt={t(labelKey)} className={styles.avatarSettingsPreview} />}
      {icon && <Icon icon={icon} className={styles.avatarSettingsPreview} />}
      {t(labelKey)}
    </div>
  );
}

export default RenderAvatarSettingItem;

RenderAvatarSettingItem.propTypes = {
  avatarSettings: PropTypes.shape({
    value: PropTypes.string,
    labelKey: PropTypes.string,
    thumbSrc: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  setAndMutateAvatar: PropTypes.func.isRequired,
  pickFile: PropTypes.func.isRequired,
  chosenAvatar: PropTypes.string,
};
