import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'translations/i18n';

import { AttendancesWidget } from 'components/mainComponents/AttendancesWidget';
import { Button } from 'components/mainComponents/Button';
import { DocumentsWidget } from 'components/mainComponents/DocumentsWidget';
import { EvaluationsWidget } from 'components/mainComponents/EvaluationsWidget';
import { MapWidget } from 'components/mainComponents/MapWidget/MapWidget';
import { RulesWidget } from 'components/mainComponents/RulesWidget';
import { TrainingCalendarWidget } from 'components/mainComponents/TrainingCalendarWidget/TrainingCalendarWidget';
import { TrainingInstructorsWidget } from 'components/mainComponents/TrainingInstructorsWidget';
import { getConfig } from 'config';
import { useAuth } from 'contexts/AuthContext';
import { withData, withRouteParams } from 'support/page';

import './WidgetList.scss';
import { LoadingIndicator } from 'components/mainComponents/LoadingIndicator';

function WidgetList(props) {
  const {
    params: { training_session_id: id },
    widget: {
      pending_evaluations: evaluations,
      documents,
      pendingSlotsToSign,
      trainingSessionSlots,
      instructors,
      showSigningButtonInExtranet,
      address,
      placeInfo,
      remote,
      showPlaceInExtranet,
      useMap: showMapInExtranet,
      showDatesInExtranet,
      showRulesInExtranet,
    },
  } = props;
  const pendingEvaluations = selectPendingEvaluations(evaluations);
  const events = selectTrainingSessionEvents(trainingSessionSlots);

  const classname = clsx('WidgetList', { 'WidgetList--multi-columns': props.multiColumns });

  return (
    <div className={classname}>
      <PendingEvaluations trainingSessionId={id} count={pendingEvaluations.length} />
      <Documents count={documents.length} trainingSessionId={id} />
      {showSigningButtonInExtranet && <PendingAttendances count={pendingSlotsToSign.length} trainingSessionsId={id} />}
      {showRulesInExtranet && <Rules trainingSessionId={id} />}
      <TrainingInstructorsWidget instructors={instructors} />
      {showDatesInExtranet && <Calendar events={events} trainingSessionId={id} />}
      {showPlaceInExtranet && !remote && address && address !== '' && (
        <Map showMapInExtranet={showMapInExtranet} address={address} placeInfo={placeInfo} />
      )}
    </div>
  );
}

function PendingEvaluations({ count, trainingSessionId }) {
  const { t } = useTranslation();

  return (
    <EvaluationsWidget pendingEvaluationsCount={count}>
      <Button as='a' href={`/ts/${trainingSessionId}/evaluations`} size='sm'>
        {t('EvaluationsWidget.action')}
      </Button>
    </EvaluationsWidget>
  );
}

function Documents({ count, trainingSessionId }) {
  const { t } = useTranslation();
  return (
    <DocumentsWidget documentsCount={count}>
      <Button as='a' href={`/ts/${trainingSessionId}/documents`} size='sm'>
        {t('DocumentsWidget.action')}
      </Button>
    </DocumentsWidget>
  );
}

function Calendar({ trainingSessionId, events }) {
  const { t } = useTranslation();
  return (
    <TrainingCalendarWidget events={events}>
      <Button as='a' href={`/ts/${trainingSessionId}/dates`} size='sm'>
        {t('TrainingCalendarWidget.action')}
      </Button>
    </TrainingCalendarWidget>
  );
}

function PendingAttendances({ trainingSessionsId, count }) {
  const { t } = useTranslation();
  return (
    <AttendancesWidget pendingSignaturesCount={count}>
      <Button as='a' href={`/ts/${trainingSessionsId}/attendances`} size='sm'>
        {t('AttendancesWidget.action')}
      </Button>
    </AttendancesWidget>
  );
}

function Map({ showMapInExtranet, address, placeInfo }) {
  return <MapWidget showMapInExtranet={showMapInExtranet} address={address} placeInfo={placeInfo} />;
}

function Rules({ trainingSessionId }) {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const userCode = auth.userCode;
  const rulesLink = `${
    getConfig().serverRoot
  }/api/v1/${userCode}/custom_documents/generate_document/rules/${trainingSessionId}?locale=${getLocale()}`;

  return (
    <RulesWidget>
      <Button as='a' href={rulesLink} target='_blank' size='sm'>
        {t('RulesWidget.action')}
      </Button>
    </RulesWidget>
  );
}

function selectPendingEvaluations(evaluations) {
  return evaluations.filter(evaluation => evaluation.pending);
}

function selectTrainingSessionEvents(trainingSessionSlots) {
  return trainingSessionSlots.map(slot => ({
    start: new Date(`${slot.date}T${slot.startTime}`),
    end: new Date(`${slot.date}T${slot.endTime}`),
  }));
}

const WIDGET_LIST = gql`
  query widgetList($id: ID!) {
    trainingSession(id: $id) {
      showDatesInExtranet
      showSigningButtonInExtranet
      showPlaceInExtranet
      useMap
      showRulesInExtranet
      address
      placeInfo {
        latitude
        longitude
      }
      remote
      pending_evaluations {
        id
        pending
      }
      documents {
        id
      }
      pendingSlotsToSign {
        id
      }
      trainingSessionSlots {
        id
        startTime
        endTime
        date
      }
      instructors {
        id
        firstname
        lastname
        logo {
          url
        }
      }
    }
  }
`;
const withGraphqlData = graphql(WIDGET_LIST, {
  name: 'widget',
  options: props => {
    return {
      variables: {
        id: props.params.training_session_id,
      },
    };
  },
});

function WidgetListLoader(props) {
  const className = clsx('WidgetListLoader', { 'WidgetListLoader--full-width': props.fullWidth });
  return (
    <div className={className}>
      <LoadingIndicator size='sm' />
    </div>
  );
}

export default withRouteParams(
  withGraphqlData(
    withData({ request: 'widget', data: 'trainingSession' })(WidgetList, {
      loading: WidgetListLoader,
    })
  )
);
