import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { CREATE_RECORDING_MUTATION } from 'mutations/video_conference';

function useCreateRecording({ recordingOwner, isRecording, recordingId, dailycoroomId, onRecordingError }) {
  const [createRecording] = useMutation(CREATE_RECORDING_MUTATION, {
    onError: onRecordingError,
  });

  // When recording is started, notify backend that it should create recording
  useEffect(() => {
    if (isRecording && recordingId && recordingOwner) {
      createRecording({ variables: { recordingId, dailycoroomId, recordingOwnerInput: recordingOwner } });
    }
  }, [isRecording, recordingId, dailycoroomId, createRecording, recordingOwner]);
}

export default useCreateRecording;
