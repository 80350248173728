import { NOT_RECORDED, PLAYBACK, RECORDED, RECORDING } from 'constants/video_conference';
import { useCallback, useMemo, useState } from 'react';
import usePrevious from '../usePrevious';

function useRecorder(audioTrack) {
  const stream = useMemo(() => audioTrack && new MediaStream([audioTrack]), [audioTrack]);
  const recorder = useMemo(() => stream && new MediaRecorder(stream), [stream]);
  const [testAudio, setTestAudio] = useState(null);
  const [state, setState] = useState(NOT_RECORDED);
  const previousState = usePrevious(state);
  const previousAudio = usePrevious(testAudio);

  const startRecording = useCallback(() => {
    if (state !== RECORDING) {
      setState(RECORDING);
      const chunks = [];
      recorder.start();
      recorder.addEventListener('dataavailable', e => chunks.push(e.data));
      recorder.addEventListener('stop', () => {
        const blob = new Blob(chunks, { type: chunks[0].type });
        const url = URL.createObjectURL(blob);
        const testAudio = new Audio(url);
        setTestAudio(testAudio);
      });
    }
  }, [recorder, state]);

  const stopRecording = () => {
    if (state === RECORDING) {
      recorder.stop();
      setState(RECORDED);
    }
  };

  const togglePlay = () => {
    if (state === PLAYBACK) {
      testAudio.pause();
      setState(RECORDED);
    } else {
      setState(PLAYBACK);
      testAudio.play().catch(() => setState(NOT_RECORDED));
      testAudio.addEventListener('ended', () => {
        setState(RECORDED);
      });
    }
  };

  const returnToInitialState = () => {
    setTestAudio(null);
    setState(NOT_RECORDED);
  };

  const returnToPreviousState = () => {
    setTestAudio(previousAudio);
    setState(previousState);
  };

  return {
    startRecording,
    stopRecording,
    state,
    togglePlay,
    returnToInitialState,
    returnToPreviousState,
  };
}

export default useRecorder;
