import React from 'react';

import { NavigationLinkTile } from 'components/mainComponents/Tile';
import ItemsCollection from 'components/mainComponents/ItemsCollection';

const ModuleList = props => {
  const { training_session_id, modules } = props;
  return (
    <ItemsCollection>
      {modules.map((module, idx) => (
        <NavigationLinkTile key={idx} to={`/ts/${training_session_id}/module/${module.id}`} icon='write' title={module.name} />
      ))}
    </ItemsCollection>
  );
};

export default ModuleList;
