import { NETWORK_TOPOLOGY_P2P, NETWORK_TOPOLOGY_SFU, RECONNECTING, SIGNALING, STATUS_BAR_TYPES } from 'constants/video_conference';
import { useTranslation } from 'react-i18next';
import { useCallStatusState } from '../useCallStatus';
import { useStatusBarsDispatch, useStatusBarsState } from '../useStatusBars';

const useConnectionStatusBars = () => {
  const { t } = useTranslation();
  const { offlineReason } = useCallStatusState();
  const { addStatusBar, closeStatusBar } = useStatusBarsDispatch();
  const statusBars = useStatusBarsState();

  const isStatusBar = id => statusBars.some(statusBar => statusBar.id === id);

  if (offlineReason === SIGNALING && !isStatusBar(SIGNALING)) {
    addStatusBar([
      {
        id: SIGNALING,
        label: SIGNALING,
        type: STATUS_BAR_TYPES.SINGLE,
        content: t('CallBox.waiting_for_connection'),
      },
    ]);
  }

  if (offlineReason !== SIGNALING && isStatusBar(SIGNALING)) {
    closeStatusBar(SIGNALING);
  }

  if ((offlineReason === NETWORK_TOPOLOGY_P2P || offlineReason === NETWORK_TOPOLOGY_SFU) && !isStatusBar(RECONNECTING)) {
    addStatusBar([
      {
        id: RECONNECTING,
        label: RECONNECTING,
        type: STATUS_BAR_TYPES.SINGLE,
        content: t('CallBox.reconnecting'),
      },
    ]);
  }

  if (offlineReason !== NETWORK_TOPOLOGY_P2P && offlineReason !== NETWORK_TOPOLOGY_SFU && isStatusBar(RECONNECTING)) {
    closeStatusBar(RECONNECTING);
  }
};

export default useConnectionStatusBars;
