import { useTranslation } from 'react-i18next';
import { localDateFormat, localTimeFormat } from 'support/date';

export function LivestreamActivity(props) {
  const { t } = useTranslation();
  const { livestream } = props;

  const url = livestream.use_virtualroom
    ? `/ts/${props.training_session_id}/module/${props.module_id}/videoconference`
    : livestream.url;

  return (
    <div className='livestream'>
      <div>
        {t('LivestreamActivity.date')}
        <span className='livestream-date'>{localDateFormat(livestream.start, true)}</span>
        <span className='livestream-time'>{localTimeFormat(livestream.start, true)}</span>
      </div>
      <div>
        {t('LivestreamActivity.link')}
        <span className='livestream-link'>
          <a target='_blank' rel='noopener noreferrer' href={url}>
            {url}
          </a>
        </span>
      </div>
    </div>
  );
}
