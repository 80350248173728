import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Vex from '../vex';

import { ActionButton, UrlButton } from 'components/mainComponents/buttons';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

const AttendanceBoard = ({ id: training_session_id, date, fromDigiforma }) => {
  const { t } = useTranslation();
  const { id, customers, trainingSessionInstructors: unsortedTrainingSessionInstructors, signatures } = date;
  const trainingSessionInstructors = [...unsortedTrainingSessionInstructors].sort(compareTrainingSessionInstructors);
  const traineeTrainingSessions = customers.flatMap(it => it.customerTrainees).sort(compareTraineeTrainingSessions);

  const hasInstructors = trainingSessionInstructors.length > 0;
  const hasTrainees = traineeTrainingSessions.length > 0;

  return (
    <div className='AttendanceBoard'>
      {(hasInstructors || hasTrainees) && (
        <UrlButton
          label={t('AttendanceBoard.in_class_signing')}
          url={`/ts/${training_session_id}/attendance/sign/class?date=${id}&goback=true${fromDigiforma ? '&fromDigiforma=true' : ''}`}
          size='small'
          type='secondary'
        />
      )}
      <h2>{t('AttendanceBoard.instructors')}</h2>
      {hasInstructors ? (
        <div className='ButtonBar'>
          <TraineesByMailSigningButton
            id={id}
            traineeTrainingSessions={[]}
            trainingSessionInstructors={trainingSessionInstructors}
            signatures={signatures}
          />
        </div>
      ) : (
        t('AttendanceBoard.no_instructors')
      )}
      <div className='AttendanceDetails'>
        {trainingSessionInstructors.map(it => {
          const foundSignature = signatures.find(
            signature => signature.trainingSessionInstructor && signature.trainingSessionInstructor.id === it.id
          );
          const instructor = it.instructor;

          return (
            <div key={it.id} className='SignatureFrame'>
              {foundSignature && foundSignature.signature !== 'EMPTY' && (
                <SignaturePanel attendee={{ ...instructor }} signature={foundSignature.signature} />
              )}
              {foundSignature && foundSignature.signature === 'EMPTY' && <CheckedSignaturePanel attendee={{ ...instructor }} />}
              {!foundSignature && <NoSignaturePanel attendee={{ ...instructor }} />}
            </div>
          );
        })}
      </div>

      <h2>{t('AttendanceBoard.trainees')}</h2>
      <div className='ButtonBar'>
        {hasTrainees ? (
          <TraineesByMailSigningButton
            id={id}
            traineeTrainingSessions={traineeTrainingSessions}
            trainingSessionInstructors={[]}
            signatures={signatures}
          />
        ) : (
          t('AttendanceBoard.no_trainees')
        )}
      </div>
      <div className='AttendanceDetails'>
        {traineeTrainingSessions.map(it => {
          const foundSignature = signatures.find(signature => signature.customerTrainee && signature.customerTrainee.id === it.id);
          const trainee = it.trainee;
          return (
            <div key={it.id} className='SignatureFrame'>
              {foundSignature && foundSignature.signature !== 'EMPTY' && (
                <SignaturePanel attendee={{ ...trainee }} signature={foundSignature.signature} />
              )}
              {foundSignature && foundSignature.signature === 'EMPTY' && <CheckedSignaturePanel attendee={{ ...trainee }} />}
              {!foundSignature && <NoSignaturePanel attendee={{ ...trainee }} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TraineesByMailSigningButton = ({ id, traineeTrainingSessions, trainingSessionInstructors, signatures }) => {
  const { t } = useTranslation();
  const [isChoiceDialogOpen, setIsChoiceDialogOpen] = useState(false);
  const [isMessageDisplayOpen, setIsMessageDisplayOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [trainees, setTrainees] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const toggleAllChecked = () => setAllChecked(it => !it);

  useEffect(() => {
    setTrainees(trainees => trainees.map(it => ({ ...it, checked: allChecked })));
    setInstructors(instructors => instructors.map(it => ({ ...it, checked: allChecked })));
  }, [allChecked]);

  useEffect(() => {
    setTrainees(
      traineeTrainingSessions.map(tts => {
        const foundItem = signatures.find(signature => signature.customerTrainee?.id === tts.id);
        const abandonCount = tts.abandons?.flatMap(abandon => abandon.trainingSessionSlots?.filter(ts_slot => ts_slot.id === id));
        const isChecked = tts.trainee.email && !foundItem && abandonCount.length < 1;
        return { ...tts, checked: isChecked };
      })
    );
    setIsChoiceDialogOpen(false);
  }, [signatures, traineeTrainingSessions, id]);

  useEffect(() => {
    setInstructors(
      trainingSessionInstructors.map(item => {
        const foundItem = signatures.find(it => it.trainingSessionInstructor?.id === item.id);
        const isChecked = item.instructor.email && !foundItem;
        return { ...item, checked: isChecked };
      })
    );

    setIsChoiceDialogOpen(false);
  }, [signatures, trainingSessionInstructors]);

  const [mailToTrainees] = useMutation(MAIL_TRAINEES);
  const [mailToInstructors] = useMutation(MAIL_INSTRUCTORS);

  const closeSelectionPanel = () => {
    setIsChoiceDialogOpen(false);
  };

  const sendMails = async () => {
    const trainee_ids = trainees.filter(t => t.checked).map(t => t.id);
    const instructor_ids = instructors.filter(i => i.checked).map(i => i.id);

    try {
      await mailToTrainees({
        variables: {
          trainingSessionSlotId: id,
          selected: trainee_ids,
        },
      });

      await mailToInstructors({
        variables: {
          trainingSessionSlotId: id,
          selected: instructor_ids,
        },
      });

      setErrorMessage(null);
      setIsMessageDisplayOpen(true);
    } catch {
      setErrorMessage(t('AttendanceBoard.mail_sending_error'));
      setIsMessageDisplayOpen(true);
      closeSelectionPanel();
    }
  };

  const toggleTrainee = id => {
    const idx = trainees.findIndex(trainee => trainee.trainee.id === id);
    const trainee = trainees[idx];
    const newTrainee = { ...trainee, checked: !trainee.checked };
    const newTrainees = [...trainees];
    newTrainees[idx] = newTrainee;
    setTrainees(newTrainees);
  };

  const toggleInstructor = id => {
    const idx = instructors.findIndex(instructor => instructor.instructor.id === id);
    const instructor = instructors[idx];
    const newInstructor = { ...instructor, checked: !instructor.checked };
    const newInstructors = [...instructors];
    newInstructors[idx] = newInstructor;
    setInstructors(newInstructors);
  };

  const renderSelectionPanel = () => (
    <div className='TraineeSelectionBoard'>
      <div className='TraineeSelectAll' onClick={toggleAllChecked}>
        <div>{allChecked ? t('AttendanceBoard.clear_all') : t('AttendanceBoard.select_all')}</div>
        <Checkbox checked={allChecked} />
      </div>
      <SelectionPanel trainees_or_instructors={trainees} toggle={toggleTrainee} />
      <SelectionPanel trainees_or_instructors={instructors} toggle={toggleInstructor} />
      <div className='ButtonBar'>
        <ActionButton label={t('AttendanceBoard.cancel')} clickFn={closeSelectionPanel} size='small' type='secondary' />
        <ActionButton label={t('AttendanceBoard.send')} clickFn={sendMails} size='small' />
      </div>
    </div>
  );

  return (
    <div>
      <ActionButton
        label={t('AttendanceBoard.by_mail_signing')}
        clickFn={() => setIsChoiceDialogOpen(true)}
        size='small'
        type='secondary'
      />
      {isChoiceDialogOpen && renderSelectionPanel()}

      <Vex open={isMessageDisplayOpen} className={errorMessage && 'vex-error'} onConfirm={() => setIsMessageDisplayOpen(false)}>
        {errorMessage && <Vex.Message>{errorMessage}</Vex.Message>}
        {!errorMessage && <Vex.Message>Lien d'émargement envoyé par email</Vex.Message>}
        <Vex.Button type='primary'>OK</Vex.Button>
      </Vex>
    </div>
  );
};

const SelectableTraineeOrInstructor = ({ trainee_or_instructor, toggle, checked }) => (
  <div
    key={trainee_or_instructor.id}
    className={`trainee alternate-background ${checked ? 'checked' : ''}`}
    onClick={() => toggle(trainee_or_instructor.id)}
  >
    <div className='name'>{trainee_or_instructor_fullname(trainee_or_instructor)}</div>
    <div className='email'>{trainee_or_instructor.email}</div>
    <Checkbox checked={checked} />
  </div>
);

const PlaceholderTraineeOrInstructor = ({ trainee_or_instructor }) => {
  const { t } = useTranslation();
  return (
    <div key={trainee_or_instructor.id} className='trainee unselectable'>
      {trainee_or_instructor_fullname(trainee_or_instructor)}
      <span>{t('AttendanceBoard.no_email')}</span>
    </div>
  );
};

const SelectionPanel = ({ trainees_or_instructors, toggle }) => (
  <div className='EmailTargetList'>
    {trainees_or_instructors.map(({ id, checked, trainee, instructor }) => {
      const trainee_or_instructor = trainee || instructor;
      if (!trainee_or_instructor.email) return <PlaceholderTraineeOrInstructor trainee_or_instructor={trainee_or_instructor} />;
      return (
        <SelectableTraineeOrInstructor key={id} trainee_or_instructor={trainee_or_instructor} checked={checked} toggle={toggle} />
      );
    })}
  </div>
);

const SignaturePanel = ({ attendee, signature }) => (
  <div className='SignaturePanel signed'>
    <SignaturePanelHeader attendee={attendee} />
    <div className='Signature'>
      <img src={signature} alt='signature' />
    </div>
  </div>
);

const CheckedSignaturePanel = ({ attendee }) => (
  <div className='SignaturePanel checked'>
    <SignaturePanelHeader attendee={attendee} />
    <div className='Signature'>
      <span>✓</span>
    </div>
  </div>
);

const NoSignaturePanel = ({ attendee }) => {
  const { t } = useTranslation();

  return (
    <div className='SignaturePanel unsigned'>
      <SignaturePanelHeader attendee={attendee} />
      <div className='Signature'>
        <span>{t('AttendanceBoard.waiting_for_signature')}</span>
      </div>
    </div>
  );
};

const SignaturePanelHeader = ({ attendee }) => (
  <div className='SignerDetails'>
    <Picture logo={attendee.logo} />
    <div className='name'>{trainee_or_instructor_fullname(attendee)}</div>
  </div>
);

const Picture = ({ logo }) => (
  <div className='picture'>
    {logo && logo.url && <img src={logo.url} alt='logo' />}
    {!logo && <SvgIcon icon='user' />}
  </div>
);

const Checkbox = ({ checked }) => <div className='checkbox'>{checked && <div className='check' />}</div>;

const trainee_or_instructor_fullname = ({ firstname, lastname }) => [lastname, firstname].filter(it => it).join(' ');

const compareTraineeTrainingSessions = (a, b) => {
  let fullnameA = trainee_or_instructor_fullname(a.trainee);
  let fullnameB = trainee_or_instructor_fullname(b.trainee);
  return fullnameA.localeCompare(fullnameB);
};

const compareTrainingSessionInstructors = (a, b) => {
  let fullnameA = trainee_or_instructor_fullname(a.instructor);
  let fullnameB = trainee_or_instructor_fullname(b.instructor);
  return fullnameA.localeCompare(fullnameB);
};

const MAIL_TRAINEES = gql`
  mutation mail_attendance_to_trainees($trainingSessionSlotId: ID!, $selected: [ID]) {
    mail_attendance_to_trainees(trainingSessionSlotId: $trainingSessionSlotId, selected: $selected)
  }
`;

const MAIL_INSTRUCTORS = gql`
  mutation mail_attendance_to_instructors($trainingSessionSlotId: ID!, $selected: [ID]) {
    mail_attendance_to_instructors(trainingSessionSlotId: $trainingSessionSlotId, selected: $selected)
  }
`;

export default AttendanceBoard;
