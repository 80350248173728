import React from 'react';
import { useTranslation } from 'react-i18next';

import 'scss/session/IncomingEmailNotice.scss';
import { Illustration } from 'components/mainComponents/Illustration';

export default function IncomingEmailNotice() {
  const { t } = useTranslation();

  return (
    <div className='IncomingEmailNotice'>
      <Illustration name='robot-surfing' size='lg' />
      <div className='h2'>{t('SigninPage.see_your_email_inbox')}</div>

      <div className='IncomingEmailNotice_message'>{t('SigninPage.connection_email_sent_message')}</div>
    </div>
  );
}
