import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { MediaStreamTrackPropType } from 'propTypes';
import normalTileStyles from 'scss/video_conference/NormalTile.module.scss';
import { useCurrentTile } from 'components/video_conference/Tile/TileRenderer';
import streamMemo from 'components/video_conference/VideoBoxes/streamMemo';
import { useDisplayedVideoTrack } from 'components/video_conference/Tile/utils';
import { useCallStatusState } from 'hooks/video_conference';
import { useCallStateState } from 'hooks/video_conference/useCallState';
import { PRE_CALL_STATE } from 'constants/video_conference';
import clsx from 'clsx';

const VideoBox = ({ videoTrack, styles, className, mirror, wrapperStyles }) => {
  styles = styles || normalTileStyles;

  const tile = useCurrentTile();

  const displayedVideoTrack = useRef(null);
  displayedVideoTrack.current = useDisplayedVideoTrack(tile);

  const isDisplayingScreen = tile && displayedVideoTrack === tile.screenVideoTrack;
  const { currentState } = useCallStateState();
  const { videoRef: preCallVideoRef } = useCallStatusState();
  const callVideoRef = useRef(null);
  const videoRef = useMemo(() => (currentState === PRE_CALL_STATE ? preCallVideoRef : callVideoRef), [currentState, preCallVideoRef]);

  const videoNode = useMemo(() => {
    return <video ref={videoRef} tabIndex='-1' autoPlay muted playsInline data-testid='video-box' />;
  }, [videoRef]);

  const videoClassName = clsx(mirror && styles.flip, className);
  const videoBoxWrapperStyles = clsx(styles.videoWrapper, isDisplayingScreen && styles.videoWrapperBehindScreen, wrapperStyles);

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.className = videoClassName;
    }
  }, [videoClassName, videoRef]);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.srcObject = streamMemo.get(videoTrack, tile);
      video.load();
    }

    return () => {
      video.srcObject = null;
      video.load();
    };

    // FIXME: This is not ideal because of the react-hooks/exhaustive-deps rule.
    // When I add `tile` to the dependency list, tiles blink a lot more.
    // Check what's happening later on, please.
    // eslint-disable-next-line
  }, [videoTrack]);

  return <div className={videoBoxWrapperStyles}>{videoNode}</div>;
};

VideoBox.propTypes = {
  videoTrack: MediaStreamTrackPropType.isRequired,
  className: PropTypes.string,
  styles: PropTypes.shape({ videoWrapper: PropTypes.string }),
  wrapperStyles: PropTypes.string,
};

VideoBox.defaultProps = {
  className: undefined,
  styles: normalTileStyles,
  wrapperStyles: '',
};

export default React.memo(VideoBox);
