import { AVATAR_CUSTOM, AVATAR_NONE, AVATAR_UPLOAD } from './avatarTypes';

export const avatarSettings = [
  {
    value: AVATAR_NONE,
    labelKey: 'Settings.avatar_none',
    icon: 'user',
    iconType: 'avatar',
    name: 'avatar-settings-avatar-none',
  },
  {
    value: AVATAR_CUSTOM,
    labelKey: 'Settings.avatar_custom',
    thumbSrc: '',
    name: 'avatar-settings-avatar-custom',
  },
  {
    value: AVATAR_UPLOAD,
    labelKey: 'Settings.avatar_upload',
    icon: 'upload',
    inputType: 'file',
    name: 'avatar-settings-avatar-upload',
  },
];
