import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function WebcamStepTitle({ isTesting, isError, isSuccess, isCameraMuted }) {
  const { t } = useTranslation();

  if (isTesting) {
    return t('WebcamStepTitle.testing');
  }
  if (isCameraMuted) {
    return t('WebcamStepTitle.no_camera');
  }
  if (isError) {
    return t('WebcamStepTitle.error');
  }
  if (isSuccess) {
    return t('WebcamStepTitle.success');
  }
  return t('WebcamStepTitle.default');
}

export default WebcamStepTitle;

WebcamStepTitle.propTypes = {
  isTesting: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isCameraMuted: PropTypes.bool.isRequired,
};
