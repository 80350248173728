import Icon from 'components/mainComponents/Icon';
import styles from 'scss/video_conference/RecordingStatus.module.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function RecordingStatus({ isRecording }) {
  const { t } = useTranslation();

  return (
    isRecording && (
      <span className={styles.recording}>
        <Icon icon='record' className={styles.recordingIcon} />
        {t('RecordingStatus.rec')}
      </span>
    )
  );
}

export default RecordingStatus;

RecordingStatus.propTypes = {
  isRecording: PropTypes.bool,
};

RecordingStatus.defaultProp = {
  isRecording: false,
};
