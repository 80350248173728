import { useDaily, useDailyEvent } from '@daily-co/daily-react';
import { TOGGLE_RECORD } from 'actions/video_conference';
import { MESSAGE_STARTED_RECORDING, RECORDING_STARTED_EVENT } from 'constants/video_conference';
import { useAuth } from 'contexts/AuthContext';
import { RoomOptsContext } from 'contexts/video_conference';
import { broadcastMessage, listenMessage } from 'helpers/video_conference';
import { useCallStatusDispatch, useCallStatusState } from 'hooks/video_conference';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GUEST_TYPE } from 'support/auth';
import IconButton from 'components/video_conference/IconButton';
import PropTypes from 'prop-types';

function Recording({ ready }) {
  const { auth } = useAuth();
  const { type: guestType, id: guestId } = auth.guestInfo;
  const { t } = useTranslation();
  const { isRecording, recordingOwner } = useCallStatusState();
  const { enableRecording } = useContext(RoomOptsContext);

  const [canInitRecording, setCanInitRecording] = useState(enableRecording);
  const [isRecordingInitiated, setIsRecordingInitiated] = useState(false);

  const callObject = useDaily();
  const dispatch = useCallStatusDispatch();

  useDailyEvent(RECORDING_STARTED_EVENT, () => {
    toggleRecordingByOwner(guestType, guestId, recordingOwner?.type, recordingOwner?.id, setCanInitRecording);
  });

  useEffect(() => {
    listenMessage(callObject, MESSAGE_STARTED_RECORDING, ({ payload: { recordingOwner } }) => {
      toggleRecordingByOwner(guestType, guestId, recordingOwner?.type, recordingOwner?.id, setCanInitRecording);

      if (isRecording) {
        setCanInitRecording(true);
      }
    });
  }, [callObject, setCanInitRecording, guestId, guestType, isRecording]);

  const handleToggleRecordClick = () => {
    const recordingOwner = { type: guestType, id: guestId };

    setIsRecordingInitiated(!isRecording);

    if (isRecording) {
      dispatch({ action: TOGGLE_RECORD, payload: { type: undefined, id: undefined } });
    } else {
      dispatch({ action: TOGGLE_RECORD, payload: { recordingOwner: recordingOwner } });
    }

    broadcastMessage(callObject, {
      messageType: MESSAGE_STARTED_RECORDING,
      payload: { recordingOwner: recordingOwner },
      targetRoles: [GUEST_TYPE.INSTRUCTOR, GUEST_TYPE.USER],
    });
  };

  const renderRecordingIcon = () => {
    if (isRecording) return 'record';
    if (isRecordingInitiated) return 'loading-spinner--dark--one-quarter';
    return 'record--circle';
  };

  return (
    <div>
      {[GUEST_TYPE.USER, GUEST_TYPE.INSTRUCTOR].includes(guestType) && enableRecording && (
        <IconButton
          type={!isRecording && 'secondary'}
          title={isRecording ? t('Tray.stop_recording') : t('Tray.start_recording')}
          disabled={!ready || !canInitRecording}
          clickFn={isRecordingInitiated && !isRecording ? undefined : handleToggleRecordClick}
          icon={renderRecordingIcon()}
        />
      )}
    </div>
  );
}

const toggleRecordingByOwner = (guestType, guestId, ownerType, ownerId, setCanInitRecording) => {
  const canToggleRecording = guestType === ownerType && guestId === ownerId;
  setCanInitRecording(canToggleRecording);
};

Recording.propTypes = {
  ready: PropTypes.bool,
};

export default Recording;
