import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/video_conference/IconButton';
import useDevicesToggle from 'hooks/video_conference/useDevicesToggle';
import TileIcon from 'components/video_conference/Tile/TileIcon';

const ToggleCameraButton = () => {
  const { t } = useTranslation();
  const { toggleCamera, isCameraMuted } = useDevicesToggle();

  return (
    <IconButton
      clickFn={toggleCamera}
      title={isCameraMuted ? t('Tray.turn_cam_on') : t('Tray.turn_cam_off')}
      iconElement={<TileIcon icon={isCameraMuted ? 'camera--off' : 'video--on'} />}
    />
  );
};

export default ToggleCameraButton;
