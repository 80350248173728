import React from 'react';
import PropTypes from 'prop-types';

import './Breadcrumb.scss';

export const Breadcrumb = ({ links }) => {
  const nbLinks = links ? links.length : 0;
  return (
    <nav aria-label='Breadcrumb'>
      <ol className='breadcrumb'>
        {links &&
          Object.entries(links).map(([k, { name, url }]) => {
            const isLast = Number(k) === nbLinks - 1;
            return <BreadcrumbItem name={name} url={url} key={k} isLast={isLast} />;
          })}
      </ol>
    </nav>
  );
};

Breadcrumb.defaultProps = {
  separator: '>',
};

Breadcrumb.propTypes = {
  links: PropTypes.array,
  separator: PropTypes.string,
};

const BreadcrumbItem = ({ name, url, isLast }) => {
  return isLast ? (
    <li className='breadcrumb__item'>
      <span className='breadcrumb__item__content' aria-current='page'>
        {name}
      </span>
    </li>
  ) : (
    <li className='breadcrumb__item'>
      <a className='breadcrumb__item__content' href={url}>
        {name}
      </a>
    </li>
  );
};

BreadcrumbItem.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  isLast: PropTypes.bool,
};
