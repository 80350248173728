import Toggle from 'components/Toggle';
import { ToggleOn } from 'components/Toggle';
import { ToggleButton } from 'components/Toggle';
import React from 'react';
import StatusBar from '.';
import PropTypes from 'prop-types';
import styles from '../../../scss/video_conference/StatusBar.module.scss';

function StatusBarToggleList({ statusBars, toggleHeader }) {
  const renderContent = () => (
    <>
      {toggleHeader}
      <Toggle>
        <ToggleButton className={styles.barButton} />
        <ToggleOn>
          <ul className={styles.barList}>
            {statusBars.map(bar => (
              <li key={bar.id}>
                <StatusBar {...bar} className={styles.barListItem} content={bar.content} />
              </li>
            ))}
          </ul>
        </ToggleOn>
      </Toggle>
    </>
  );

  return <StatusBar id='warning' label='warning' emoji='⚠️' content={renderContent()} />;
}

StatusBarToggleList.propTypes = {
  statusBars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      emoji: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  toggleHeader: PropTypes.node.isRequired,
};

export default StatusBarToggleList;
