import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';
import { Paper } from 'components/mainComponents/Paper';
import { Heading } from 'components/mainComponents/Heading';
import { UserAvatar } from 'components/mainComponents/UserAvatar/UserAvatar';
import { WidgetSubtitle } from 'components/mainComponents/WidgetSubtitle';

import './TrainingInstructorsWidget.scss';

export function TrainingInstructorsWidget(props) {
  const { t } = useTranslation();

  return (
    <Paper className='TrainingInstructorsWidget'>
      <div className='TrainingInstructorsWidget__header'>
        <RoundedIcon icon='user' background='alt-2' />
        <div>
          <Heading level={4} as='h3'>
            {t('TrainingSessionPage.instructors_widget_title')}
          </Heading>
          <WidgetSubtitle>{t('TrainingSessionPage.instructors_widget_subtitle')}</WidgetSubtitle>
        </div>
      </div>
      <div className='TrainingInstructorsWidget__instructor-list'>
        {props.instructors.map(instructor => (
          <UserAvatar user={instructor} key={instructor.id} />
        ))}
      </div>
    </Paper>
  );
}

TrainingInstructorsWidget.propTypes = {
  instructors: PropTypes.arrayOf(
    PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }).isRequired
  ),
};
