import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  DISPLAY_MODE_AUTO,
  DISPLAY_MODE_FEATURED_SPEAKER,
  DISPLAY_MODE_GRID,
  DISPLAY_MODE_STORAGE_KEY,
} from '../../../constants/video_conference';
import { ChildrenPropType } from '../../../propTypes';

const DisplayModeStateContext = createContext();

const DisplayModeDispatchContext = createContext();

const NEXT_MODE = 'NEXT_DISPLAY_MODE';
const MODE_CHANGE = 'DISPLAY_MODE_CHANGE';

const nextModeMapper = {
  [DISPLAY_MODE_FEATURED_SPEAKER]: DISPLAY_MODE_GRID,
  [DISPLAY_MODE_GRID]: DISPLAY_MODE_AUTO,
  [DISPLAY_MODE_AUTO]: DISPLAY_MODE_FEATURED_SPEAKER,
};

const displayModeReducer = (state, action) => {
  switch (action.type) {
    case NEXT_MODE: {
      const nextMode = nextModeMapper[state];
      if (!nextMode) {
        throw new Error(`${state} is not a valid display mode`);
      }

      return nextMode;
    }
    case MODE_CHANGE: {
      if (action.payload in nextModeMapper) {
        return action.payload;
      }

      throw new Error(`${state} is not a valid display mode`);
    }
    default:
      throw new Error(`${action.type} is not expected action type of displayModeReducer`);
  }
};

const DisplayModeProvider = ({ defaultDisplayMode, children }) => {
  const [displayMode, dispatch] = useReducer(displayModeReducer, defaultDisplayMode);

  useEffect(() => {
    localStorage.setItem(DISPLAY_MODE_STORAGE_KEY, displayMode);
  }, [displayMode]);

  return (
    <DisplayModeStateContext.Provider value={displayMode}>
      <DisplayModeDispatchContext.Provider value={dispatch}>{children}</DisplayModeDispatchContext.Provider>
    </DisplayModeStateContext.Provider>
  );
};

DisplayModeProvider.propTypes = {
  ...ChildrenPropType,
  defaultDisplayMode: PropTypes.oneOf([...Object.keys(nextModeMapper)]),
};

DisplayModeProvider.defaultProps = {
  defaultDisplayMode: Object.keys(nextModeMapper)[0],
};

export const useDisplayModeState = () => {
  const displayMode = useContext(DisplayModeStateContext);
  if (displayMode === undefined) {
    throw new Error('useDisplayModeState must be used within a DisplayModeProvider');
  }

  return displayMode;
};

export const useDisplayModeDispatch = () => {
  const displayModeDispatch = useContext(DisplayModeDispatchContext);
  if (displayModeDispatch === undefined) {
    throw new Error('useDisplayModeDispatch must be used within a DisplayModeProvider');
  }

  const nextMode = () => displayModeDispatch({ type: NEXT_MODE });
  const changeMode = mode => displayModeDispatch({ type: MODE_CHANGE, payload: mode });

  return { nextMode, changeMode };
};

export default DisplayModeProvider;
