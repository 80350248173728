import { useCallback, useRef } from 'react';

function useScrollOnHover({ scrollSpeed = 5, containerRef }) {
  const isScrollRef = useRef();
  const setMove = state => (isScrollRef.current = state);
  const scrollRef = containerRef.current?.children[1];

  const moveDown = useCallback(() => {
    if (isScrollRef.current && scrollRef) {
      scrollRef.scrollTop = scrollRef.scrollTop + scrollSpeed;
      requestAnimationFrame(moveDown);
    }
  }, [scrollRef, scrollSpeed]);

  const moveUp = useCallback(() => {
    if (isScrollRef.current && scrollRef) {
      scrollRef.scrollTop = scrollRef.scrollTop - scrollSpeed;
      requestAnimationFrame(moveUp);
    }
  }, [scrollRef, scrollSpeed]);

  const handleMouseEnterTop = useCallback(
    e => {
      setMove(true);
      moveUp();
    },
    [moveUp]
  );

  const handleMouseEnterBottom = useCallback(
    e => {
      setMove(true);
      moveDown();
    },
    [moveDown]
  );

  const handleMouseLeave = () => setMove(false);

  return { handleMouseEnterTop, handleMouseEnterBottom, handleMouseLeave };
}

export default useScrollOnHover;
