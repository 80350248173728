import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';
import { flowRight as compose } from 'lodash';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogContent,
} from 'components/mainComponents/AlertDialog';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Button } from 'components/mainComponents/Button';
import { formatSlotDate } from 'support/date';
import { Heading } from 'components/mainComponents/Heading';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { SignatureCanvas } from 'components/mainComponents/SignatureCanvas';
import { useAttendanceSignatureMutation } from 'hooks/useAttendanceSignatureMutation';
import useDisclosure from 'hooks/useDisclosure';
import { withData, withRouteParams } from 'support/page';

import './AttendanceSingleSignaturePage.scss';

function AttendanceSingleSignaturePage(props) {
  const sessionId = props.trainingSession.id;
  const sessionName = props.trainingSession.custom_name || props.trainingSession.name;

  const signatureDataUrl = props.signatureForActorAtDate.signature?.signature;
  const slotId = props.signatureForActorAtDate.date.id;
  const date = props.signatureForActorAtDate.date.date;
  const startTime = props.signatureForActorAtDate.date.startTime;
  const endTime = props.signatureForActorAtDate.date.endTime;
  const subsessionName = props.signatureForActorAtDate.date.subsession.name;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    open: openSignAgainConfirmDialog,
    isOpen: isSignAgainConfirmDialogOpen,
    close: closeSignAgainConfirmDialog,
    setIsOpen: setIsSignAgainConfirmDialogOpen,
  } = useDisclosure();
  const [allowSignature, setAllowSignature] = useState(!!signatureDataUrl);

  const [sign, { loading: signatureLoading, error: signatureError }] = useAttendanceSignatureMutation({ slotId });
  const subTitle = t('AttendanceSingleSignaturePage.page_title', { date: formatSlotDate({ date, startTime, endTime }) });
  return (
    <>
      <AlertDialog open={isSignAgainConfirmDialogOpen} onOpenChange={setIsSignAgainConfirmDialogOpen}>
        <AlertDialogContent>{t('AttendanceSingleSignaturePage.alert_sign_again')}</AlertDialogContent>
        <AlertDialogActions>
          <AlertDialogCancel>
            <Button onClick={closeSignAgainConfirmDialog} variant='text'>
              {t('AttendanceSingleSignaturePage.cancel_sign_again')}
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction>
            <Button onClick={() => setAllowSignature(false)}>{t('AttendanceSingleSignaturePage.confirm_sign_again')}</Button>
          </AlertDialogAction>
        </AlertDialogActions>
      </AlertDialog>

      <LeftMenuLayout>
        <Breadcrumb>
          <Breadcrumb.Item url={`/ts/${sessionId}`}>{sessionName}</Breadcrumb.Item>
          <Breadcrumb.Item url={`/ts/${sessionId}/attendances`}>{t('AttendanceSingleSignaturePage.attendances')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('AttendanceSingleSignaturePage.signature')}</Breadcrumb.Item>
        </Breadcrumb>
        <section>
          <Heading level={1}>{subsessionName}</Heading>
          <Heading level={2}>{subTitle}</Heading>
          {allowSignature ? (
            <div className='V2_AttendanceSingleSignaturePage__hasAlreadySigned'>
              <Heading level={3}>{t('AttendanceSingleSignaturePage.already_signed_title')}</Heading>
              <p>{t('AttendanceSingleSignaturePage.already_signed_description')}</p>
              <div className='V2_AttendanceSingleSignaturePage__hasAlreadySignedActions'>
                <Button variant='text' onClick={openSignAgainConfirmDialog}>
                  {t('AttendanceSingleSignaturePage.sign_again')}
                </Button>
                <Button as='a' href={`/ts/${sessionId}/attendances`}>
                  {t('AttendanceSingleSignaturePage.go_back_to_signatures')}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <SignatureCanvas disabled={signatureLoading} onCancel={() => navigate(-1)} onSave={saveSignature} />
              {signatureError && (
                <p role='alert' className='V2_AttendanceSingleSignaturePage__signError'>
                  {t('AttendanceSingleSignaturePage.sign_error')}
                </p>
              )}
            </>
          )}
        </section>
      </LeftMenuLayout>
    </>
  );

  async function saveSignature(signature) {
    try {
      await sign(signature);
      setAllowSignature(true);
    } catch (err) {
      console.error(err);
    }
  }
}

const TRAINING_SESSION = gql`
  query TrainingSession($id: ID!) {
    trainingSession(id: $id) {
      id
      name
      custom_name
    }
  }
`;
const TRAINING_SESSION_SLOT = gql`
  query TrainingSessionSlot($id: ID!) {
    signatureForActorAtDate(trainingSessionSlotId: $id) {
      signature {
        signature
      }
      date {
        id
        date
        startTime
        endTime
        subsession {
          name
        }
      }
    }
  }
`;
const withGraphqlData = compose(
  graphql(TRAINING_SESSION, {
    name: 'trainingSession',
    options: props => {
      return {
        variables: {
          id: props.params.training_session_id,
        },
      };
    },
  }),
  graphql(TRAINING_SESSION_SLOT, {
    name: 'signatureForActorAtDate',
    options: props => {
      return {
        variables: {
          id: props.params.date,
        },
      };
    },
  })
);
export default withRouteParams(
  withGraphqlData(
    withData('trainingSession', 'signatureForActorAtDate')(AttendanceSingleSignaturePage, {
      loading: PageLoader,
    })
  )
);
