import clsx from 'clsx';
import PropTypes from 'prop-types';

import iconsSprite from './icons.svg';
import './SvgIcon.scss';
import { iconNames } from 'components/mainComponents/SvgIcon/iconNames';

export function SvgIcon(props) {
  const animationName = getAnimationName(props.name);
  const useStandardSize = !props.width && !props.height;
  const classname = clsx(
    'SvgIcon',
    {
      [`SvgIcon--size-${props.size}`]: useStandardSize,
      [`SvgIcon--animate-${animationName}`]: !!animationName,
    },
    props.className
  );

  return (
    <svg className={classname} width={props.width ?? props.height} height={props.height ?? props.width}>
      <use href={`${iconsSprite}#${props.name}`} />
    </svg>
  );
}

function getAnimationName(svgName) {
  // See end of file "src/components/mainComponents/Illustration/Illustration.jsx"
  // why this convoluted way of handling animations.

  switch (svgName) {
    case 'loading-spinner--dark--half':
    case 'loading-spinner--dark--one-quarter':
    case 'loading-spinner--dark--three-quarters':
    case 'loading-spinner--light--half':
    case 'loading-spinner--light--one-quarter':
    case 'loading-spinner--light--three-quarters':
      return 'full-rotation';
    default:
      return null;
  }
}

SvgIcon.propType = {
  name: PropTypes.oneOf(iconNames).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

SvgIcon.defaultProps = {
  size: 'sm',
};
