import React from 'react';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

const Trainee = ({ firstname, lastname, logo, company }) => (
  <div className='trainee'>
    <div className='picture'>{logo ? <img src={logo.url} alt='logo' /> : <SvgIcon name='student' width={150} />}</div>
    <div className='cartouche'>
      <div className='name'>
        <div className='firstname'>{firstname}</div>
        <div className='lastname'>{lastname}</div>
      </div>
      {company ? (
        <div className='company'>
          <span>{company.name}</span>
        </div>
      ) : null}
    </div>
  </div>
);

export default Trainee;
