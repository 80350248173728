import { useMutation } from '@apollo/client';
import { useDailyEvent } from '@daily-co/daily-react';
import { CREATE_FEEDBACK_MUTATION } from 'mutations/video_conference';
import React, { createContext, useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { ChildrenPropType } from 'propTypes';
import { RoomOptsContext } from 'contexts/video_conference';
import { MEETING_SESSION_UPDATED } from 'constants/video_conference';

const FeedbackIdContext = createContext();

const actionTypes = {
  FEEDBACK_CREATED: 'FEEDBACK_CREATED',
  NEW_SESSION_STARTED: 'NEW_SESSION_STARTED',
};

function reduceFeedbackState(prevState, action) {
  switch (action.type) {
    case actionTypes.FEEDBACK_CREATED: {
      return {
        ...prevState,
        feedbackCreated: true,
      };
    }
    case actionTypes.NEW_SESSION_STARTED: {
      return {
        ...prevState,
        dailySessionId: action.dailySessionId,
        feedbackUUID: action.feedbackUUID,
        feedbackCreated: false,
      };
    }
    default: {
      throw new Error(`reduceFeedbackState does not support action type: ${action.type}`);
    }
  }
}

const FeedbackIdProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reduceFeedbackState, {
    dailySessionId: null,
    feedbackUUID: null,
    feedbackCreated: false,
  });

  const { feedbackUUID, dailySessionId, feedbackCreated } = state;
  const [createFeedback] = useMutation(CREATE_FEEDBACK_MUTATION, {
    onCompleted: () => {
      dispatch({ type: actionTypes.FEEDBACK_CREATED });
    },
  });

  const prevDailySessionIdRef = useRef(dailySessionId);

  useEffect(() => {
    if (!dailySessionId || !feedbackUUID) {
      return;
    }
    if (!feedbackCreated) {
      createFeedback({ variables: { feedbackUUID, dailySessionId } });
    }
  }, [createFeedback, dailySessionId, feedbackCreated, feedbackUUID]);

  useEffect(() => {
    prevDailySessionIdRef.current = dailySessionId;
  }, [dailySessionId]);

  const handleMeetingSessionUpdate = useCallback(event => {
    const meetingSessionId = event?.meetingSession?.id;
    if (meetingSessionId && meetingSessionId !== prevDailySessionIdRef.current) {
      dispatch({
        type: actionTypes.NEW_SESSION_STARTED,
        dailySessionId: meetingSessionId,
        feedbackUUID: uuidV4(),
      });
    }
  }, []);

  useDailyEvent(MEETING_SESSION_UPDATED, handleMeetingSessionUpdate);

  return <FeedbackIdContext.Provider value={state}>{children}</FeedbackIdContext.Provider>;
};

FeedbackIdContext.propTypes = {
  ...ChildrenPropType,
};

export function useFeedbackId() {
  const context = useContext(FeedbackIdContext);

  if (context === undefined) {
    throw new Error('useFeedbackId must be used within a FeedbackIdContext');
  }
  return context;
}

export const useNavigateMaybeWithFeedback = () => {
  const { feedbackUUID } = useFeedbackId();
  const navigate = useNavigate();
  const { trainingSessionId, moduleId } = useContext(RoomOptsContext);

  const navigateMaybeWithFeedback = useCallback(
    goalNavigationDestination => {
      if (feedbackUUID) {
        navigate('/videoconference-feedback', {
          state: { feedbackUUID, navigateTo: goalNavigationDestination, trainingSessionId, moduleId },
        });
      } else {
        navigate(goalNavigationDestination);
      }
    },
    [feedbackUUID, navigate, moduleId, trainingSessionId]
  );

  return navigateMaybeWithFeedback;
};

export default FeedbackIdProvider;
