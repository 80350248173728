import { useEffect, useCallback, useRef } from 'react';
import { ADD_NOTIFICATIONS } from '../../../actions/video_conference';
import { getCommentTimestamp } from '../../../helpers/video_conference';
import { useNotificationsDispatch } from 'hooks/useNotifications';

const useNewCommentNotifications = (commentsDesc, { onActivate, filter = () => true }) => {
  const dispatchNotifications = useNotificationsDispatch();
  const newestCommentTimestamp = useRef(getCommentTimestamp(commentsDesc[0]));

  const notificationFromComment = useCallback(
    comment => ({
      id: `comment-${comment.id}`,
      title: comment.author,
      text: comment.text,
      type: 'comment',
      onActivate,
    }),
    [onActivate]
  );

  useEffect(() => {
    // comments added since last time
    const newerComments = commentsDesc.filter(
      comment => getCommentTimestamp(comment) > newestCommentTimestamp.current || !newestCommentTimestamp.current
    );

    newestCommentTimestamp.current = getCommentTimestamp(newerComments[0] ?? commentsDesc[0]);

    if (newerComments?.length) {
      dispatchNotifications({
        action: ADD_NOTIFICATIONS,
        payload: {
          newNotifications: newerComments.filter(filter).map(notificationFromComment),
        },
      });
    }
  }, [commentsDesc, dispatchNotifications, filter, notificationFromComment]);
};

export default useNewCommentNotifications;
