import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useSelectedTile from 'contexts/video_conference/SelectedTileContext';
import AppPropTypes from 'propTypes';
import { RoomOptsContext } from 'contexts/video_conference';
import BasicTile from './BasicTile';
import { getUserImageUrl } from 'helpers/video_conference';
import { getTileClassName } from './utils';
import normalTileStyles from 'scss/video_conference/NormalTile.module.scss';
import generalTileStyles from 'scss/video_conference/GeneralTile.module.scss';
import TileOverlay from './TileOverlay';
import TileContent from './TileContent';
import VolumeIndicator from 'components/video_conference/VolumeIndicator';
import { useDaily } from '@daily-co/daily-react';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';
import TileIcon from './TileIcon';
import IconButton from 'components/video_conference/IconButton';
import clsx from 'clsx';

const styles = { ...generalTileStyles, ...normalTileStyles };

const SmallTile = ({ tile }) => {
  const { leftIcons, rightIcons } = tile;
  const { usersData } = useContext(RoomOptsContext);
  const callObject = useDaily();

  const [selectedSpeakerId, setSelectedTileId] = useSelectedTile();
  const { currentSpeakerId } = useCurrentSpeaker();

  const handleTileClick = () => {
    setSelectedTileId(tile.sessionId === selectedSpeakerId ? null : tile.sessionId);
  };

  const imageUrl = getUserImageUrl(usersData, `${tile.digiformaType}-${tile.digiformaId}`);

  const current = tile.sessionId === currentSpeakerId;
  const selected = selectedSpeakerId === tile.sessionId;

  const basicTileClassName = getTileClassName(styles, {
    ...tile,
    current: current && Object.keys(callObject.participants()).length > 2,
  });

  const tileClassName = clsx(basicTileClassName, { [styles.selected]: selected });

  return (
    <BasicTile
      className={tileClassName}
      styles={styles}
      onClick={handleTileClick}
      leftIcons={leftIcons}
      rightIcons={
        <>
          {rightIcons}
          {tile.screen && <IconButton iconElement={<TileIcon icon='present-screen' />} className={styles.cornerIcon} />}
          {tile.audio && tile.audioTrack ? (
            <VolumeIndicator audioTrack={tile.audioTrack} mode={tile.local ? 'bar' : 'icon'} styles={styles} />
          ) : (
            <TileIcon icon='microphone--off' />
          )}
        </>
      }
    >
      <TileContent tile={tile} styles={styles} placeholderImageUrl={imageUrl} />

      {tile.video && <TileOverlay styles={styles} caption={tile.userName} />}
    </BasicTile>
  );
};

SmallTile.propTypes = {
  tile: AppPropTypes.tile.isRequired,
  leftIcons: PropTypes.node,
  rightIcons: PropTypes.node,
};

SmallTile.defaultProps = {
  leftIcons: undefined,
  rightIcons: undefined,
};

export default SmallTile;
