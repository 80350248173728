import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TileInfoProvider from 'contexts/video_conference/TileInfoContext';
import { AutoFullscreenProvider } from 'hooks/video_conference/useAutoFullscreen';
import { SelectedTileProvider } from 'contexts/video_conference/SelectedTileContext';
import { useCallStatusDispatch, useCallStatusState } from 'hooks/video_conference/useCallStatus';
import { RoomOptsContext } from 'contexts/video_conference';
import { useNotificationsDispatch, useNotificationsState } from 'hooks/useNotifications';
import CallBox from 'components/video_conference/CallBox';
import Tray from 'components/video_conference/Tray';
import styles from 'scss/video_conference/Room.module.scss';
import useNotificationSoundOnRoomEnter from 'hooks/video_conference/useNotificationSoundOnRoomEnter';
import Sidebar from 'components/video_conference/Sidebar';
import Notifications from 'components/video_conference/Notifications';
import DisplayModeProvider from 'components/video_conference/DisplayModeContext';
import { getLocalParticipant } from 'helpers/video_conference';
import { defaultDisplayMode } from 'helpers/video_conference/callStatus';
import { DISPLAY_MODE_STORAGE_KEY } from 'constants/video_conference';
import { ADD_NOTIFICATIONS, TOGGLE_SHOULD_TURN_OFF_MIC } from 'actions/video_conference';
import { SignatureListProvider } from 'hooks/video_conference/useSignatureList';
import { useDaily } from '@daily-co/daily-react';
import useConnectionStatusBars from 'hooks/video_conference/useConnectionStatusBars';
import QualityMeasuringWindow from 'components/video_conference/QualityMeasuringWindow';
import useDevicesToggle from 'hooks/video_conference/useDevicesToggle';
import Settings from 'components/video_conference/Settings';
import RoomMenu from 'components/video_conference/RoomMenu';
import Participants from 'components/video_conference/Participants';
import useCreateRecording from 'hooks/video_conference/useCreateRecording';
import { useRecordingStoppedNotification } from 'hooks/video_conference/useRecordingStoppedNotification';
import AttendanceSignModal from '../AttendanceSignModal';

function RoomAfterApiJoin() {
  const callObject = useDaily();
  const { t } = useTranslation();

  useNotificationSoundOnRoomEnter(callObject);

  useConnectionStatusBars();

  const { toggleMic } = useDevicesToggle();
  const { isRecording, recordingId, shouldTurnOffMic, recordingOwner } = useCallStatusState();
  const dispatch = useCallStatusDispatch();

  const dispatchNotifications = useNotificationsDispatch();
  const notifications = useNotificationsState();

  const onRecordingError = useCallback(
    error => {
      let translationKey;

      if (error.networkError && error.networkError.name === 'TypeError') {
        translationKey = 'recording_mutation_error';
      } else {
        translationKey = 'recording_error';
      }

      dispatchNotifications({
        action: ADD_NOTIFICATIONS,
        payload: {
          newNotifications: [
            {
              id: 'recording-start-error',
              title: t(`Notification.${translationKey}.title`),
              text: `${t(`Notification.${translationKey}.text`)}} ${error}`,
            },
          ],
        },
      });
    },
    [dispatchNotifications, t]
  );

  const { dailycoroomId, isAttendanceSigningVisible } = useContext(RoomOptsContext);

  useCreateRecording({
    recordingOwner,
    isRecording,
    recordingId,
    dailycoroomId,
    onRecordingError,
  });

  useRecordingStoppedNotification(isRecording, recordingId);

  const localParticipant = getLocalParticipant(callObject);
  const initialDisplayMode = localStorage.getItem(DISPLAY_MODE_STORAGE_KEY) || defaultDisplayMode(localParticipant);

  useEffect(() => {
    if (shouldTurnOffMic) {
      toggleMic();
      dispatch({ action: TOGGLE_SHOULD_TURN_OFF_MIC });
    }
  }, [shouldTurnOffMic, dispatch, toggleMic]);

  return (
    <DisplayModeProvider defaultDisplayMode={initialDisplayMode}>
      <SelectedTileProvider>
        <TileInfoProvider>
          <SignatureListProvider>
            <RoomMenu />
            <div className={styles.mainRow}>
              <div className={styles.mainCol}>
                <AutoFullscreenProvider>
                  <CallBox />
                </AutoFullscreenProvider>
                <Tray />
                {isAttendanceSigningVisible && <AttendanceSignModal />}
              </div>
              <Settings />
              <Sidebar />
              <Participants />
              <Notifications notifications={notifications} />
              <QualityMeasuringWindow />
            </div>
          </SignatureListProvider>
        </TileInfoProvider>
      </SelectedTileProvider>
    </DisplayModeProvider>
  );
}

export default RoomAfterApiJoin;
