import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { guestOnPresence, guestSubscribe, unSubscribe } from '../../support/realtime';
import { getGuestType } from '../../support/auth';

import '../../scss/form_inputs/LiveEvaluationControls.scss';

class LiveEvaluationControls extends Component {
  state = {
    admin: isAdmin(),
  };

  componentDidMount() {
    const instance = this;
    setTimeout(() => {
      guestSubscribe(`evaluation_${instance.props.evaluationId}`, 'current_page', x =>
        instance.props.onCurrentPageChanged(x.current_page, x.sync_with_instructor)
      );
      guestOnPresence(`evaluation_${instance.props.evaluationId}`, instance.props.onMembersChanged);
    }, 0);
  }

  componentWillUnmount() {
    unSubscribe(`evaluation_${this.props.evaluationId}`, 'current_page');
  }

  onStart = async () => {
    if (this.props.currentPage >= this.props.nbPages) {
      if (this.state.admin) {
        this.props.onShowResults();
      } else {
        this.props.onAnswer();
      }
    } else {
      this.props.onCurrentPageChanged(this.props.currentPage + 1);
      if (this.state.admin) {
        this.sendCurrentPageToServer(this.props.currentPage + 1, true);
      }
    }
  };

  sendCurrentPageToServer = async (currentPage, syncWithInstructor) => {
    await this.props.mutate({
      variables: {
        id: this.props.evaluationId,
        currentPage,
        syncWithInstructor,
      },
    });
  };

  onStartUnsynced = () => {
    this.sendCurrentPageToServer(this.props.currentPage, false);
  };

  render() {
    return (
      <div className='LiveEvaluationControls'>
        <div>
          {this.state.admin && this.props.currentPage === 0 && (
            <button className='next-question button primary' onClick={this.onStart}>
              {this.props.t('LiveEvaluationControls.start_activity')}
              ;
              <br />
              <span className='small'>{this.props.t('LiveEvaluationControls.supervising_questions_passage')}</span>
            </button>
          )}

          {this.state.admin === false && this.props.syncWithInstructor === false && this.props.currentPage < this.props.nbPages && (
            <button className='next-question button primary' onClick={this.onStart}>
              {this.props.t('LiveEvaluationControls.next_question')}
            </button>
          )}

          {this.state.admin === false && this.props.currentPage > 0 && this.props.currentPage === this.props.nbPages && (
            <button className='next-question button primary' onClick={this.onStart}>
              {this.props.t('LiveEvaluationControls.send_my_answers')}
            </button>
          )}

          {this.state.admin && this.props.currentPage === this.props.nbPages && (
            <button className='next-question button primary' onClick={this.onStart}>
              {this.props.t('LiveEvaluationControls.see_results')}
            </button>
          )}

          {this.state.admin && this.props.currentPage > 0 && this.props.currentPage < this.props.nbPages - 1 && (
            <button className='next-question button primary' onClick={this.onStart}>
              {this.props.t('LiveEvaluationControls.next_question')}
            </button>
          )}
        </div>
        {this.state.admin && this.props.currentPage === 0 && (
          <div>
            <button className='start-unsync button' onClick={this.onStartUnsynced}>
              {this.props.t('LiveEvaluationControls.start_by_letting_trainees_respond')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

function isAdmin() {
  const type = getGuestType();
  return type === 'instructor' || type === 'user';
}

// prettier-ignore
export default
  withTranslation()(
    graphql(gql`
      mutation set_activity_currentPage($id: ID!, $currentPage: Int!, $syncWithInstructor: Boolean!) {
        setActivityCurrentPage(id: $id, currentPage: $currentPage, syncWithInstructor: $syncWithInstructor)
      }
    `)(LiveEvaluationControls)
);
