import { graphql } from '@apollo/client/react/hoc';
import { ComponentsRenderer } from '@a-world-for-us/elearning_content_editor';
import { clsx } from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'translations/i18n';

import { useAuth } from 'contexts/AuthContext';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { Heading } from 'components/mainComponents/Heading';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { Paper } from 'components/mainComponents/Paper';
import { GUEST_TYPE } from 'support/auth';
import { withData, withRouteParams } from 'support/page';

import { trainingSessionQuery } from './queries';
import './TrainingSessionPage.scss';
import { WidgetList } from './WidgetList';

function TrainingSessionPage(props) {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const type = auth?.guestInfo?.type;
  const instructorAndAcademyMode = type === GUEST_TYPE.INSTRUCTOR || type === GUEST_TYPE.ACADEMY;
  const trainingSession = props.training_session;
  const sessionName = trainingSession.customName || trainingSession.name;
  const hasDescription = trainingSession?.editableComponents?.length > 0;
  const classname = clsx('V2_TrainingSessionPage', { 'V2_TrainingSessionPage--no-description': !hasDescription });

  useEffect(() => {
    if (!hasDescription) {
      return;
    }

    setTimeout(() => {
      ComponentsRenderer.init({
        elementId: 'V2_TrainingSessionPage__description',
        componentsPayload: trainingSession.editableComponents,
        shouldTrackProgress: false,
        config: {
          language: getLocale(),
        },
      });
    }, 0);

    return () => {
      ComponentsRenderer.destroy();
    };
  }, [trainingSession.editableComponents, hasDescription]);

  return (
    <LeftMenuLayout>
      <div className={classname}>
        {trainingSession.image && (
          <div className='V2_TrainingSessionPage__image-wrapper'>
            <img src={trainingSession.image.url} alt='' className='V2_TrainingSessionPage__image' />
          </div>
        )}
        {!hasDescription && <Heading className='V2_TrainingSessionPage__title'>{sessionName}</Heading>}

        <div className='V2_TrainingSessionPage__content'>
          {instructorAndAcademyMode && !hasDescription && (
            <div className='V2_TrainingSessionPage__previewBanner'>
              <p>{t('TrainingSessionPage.preview_banner_title')}</p>
              <p>{t('TrainingSessionPage.preview_banner_msg')}</p>
            </div>
          )}

          {!hasDescription && <WidgetList multiColumns />}

          {hasDescription && (
            <Paper className='V2_TrainingSessionPage__description'>
              <Heading className='V2_TrainingSessionPage__title'>{sessionName}</Heading>
              <div id='V2_TrainingSessionPage__description' />
            </Paper>
          )}
        </div>
        {hasDescription && (
          <div className='V2_TrainingSessionPage__widgets'>
            <WidgetList />
          </div>
        )}
      </div>
    </LeftMenuLayout>
  );
}

const withGraphqlData = graphql(trainingSessionQuery, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: props.params.training_session_id },
    };
  },
});

// prettier-ignore
export default withRouteParams(
    withGraphqlData(
        withData('training_session')(
            TrainingSessionPage, {loading: PageLoader})));
