import { getConfig } from '../config';
import * as filestack from 'filestack-js';

const API_KEY = getConfig().FILESTACK_API_KEY;

export const filestackInitializer = (event, { accept, imageMax, storeTo }, onSuccessfulUpload, t) => {
  filestack
    .init(API_KEY)
    .picker({
      accept,
      imageMax,
      storeTo,
      onFileUploadFinished: file => {
        onSuccessfulUpload(event, file);
      },
      onFileUploadFailed: () => alert(t('Settings.unable_to_load_picture')),
    })
    .open();
};

export const filestackDeleteFile = ({ filestackSecurity: { policy, signature } }, customImageHandle) => {
  if (policy && signature) {
    filestack
      .init(API_KEY, {
        security: {
          policy,
          signature,
        },
      })
      .remove(customImageHandle)
      .catch(error => {
        console.log(error, 'Could not delete file error.');
      });
  }
};
