import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from 'components/mainComponents/Paper';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';

import { PaletteColorPropType } from 'propTypes';
import './ActionWidget.scss';
import clsx from 'clsx';

export function ActionWidget({ icon, color, title, subtitle, children }) {
  const classname = clsx('ActionWidget__content', { 'ActionWidget__content--no-action': children === undefined });

  return (
    <Paper className='ActionWidget'>
      <div className={classname}>
        <RoundedIcon icon={icon} background={color} className='ActionWidget__icon' />
        <div className='ActionWidget__info-container'>
          <p className='ActionWidget__title'>{title}</p>
          {subtitle && <p className='ActionWidget__subtitle'>{subtitle}</p>}
        </div>
        {children && <div className='ActionWidget__action'>{children}</div>}
      </div>
    </Paper>
  );
}

ActionWidget.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PaletteColorPropType,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};
