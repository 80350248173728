import { gql } from '@apollo/client';

export const TRAINEE_ROOT_PAGE = gql`
  query trainee_root_page($id: ID!) {
    trainee(id: $id) {
      id
      training_sessions {
        id
        name
        custom_name
        start_date
        image {
          url
        }
      }
    }
  }
`;

export const INSTRUCTOR_ROOT_PAGE = gql`
  query instructor_root_page($id: ID!) {
    instructor(id: $id) {
      id
      training_sessions {
        id
        name
        custom_name
        start_date
        image {
          url
        }
      }
    }
  }
`;

export const COMPANY_ROOT_PAGE = gql`
  query company_root_page($id: ID!) {
    company(id: $id) {
      id
      training_sessions {
        id
        name
        custom_name
        start_date
        image {
          url
        }
      }
    }
  }
`;

export const FUNDING_AGENCY_ROOT_PAGE = gql`
  query funding_agency_root_page($id: ID!) {
    funding_agency(id: $id) {
      id
      training_sessions {
        id
        name
        custom_name
        start_date
        image {
          url
        }
      }
    }
  }
`;
