import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import { withData } from 'support/page';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { useAuth } from 'contexts/AuthContext';
import { CommonPagesLayout } from 'components/v2/layouts/CommonPagesLayout';

import './TermsOfServicePage.scss';

export function TermsOfServicePage(props) {
  const { t } = useTranslation();
  const { termsOfService } = props;
  const navigate = useNavigate();
  const [acceptTermsOfServiceMutate] = useMutation(ACCEPT_TERMS_OF_SERVICE_MUTATION);
  const { acceptTOS, signout } = useAuth();

  return (
    <CommonPagesLayout>
      <Heading level={2}>{t('TermsOfServicePage.title')}</Heading>
      <div dangerouslySetInnerHTML={{ __html: termsOfService.html }} />
      <div className='v2_TermsOfServicePage__cta'>
        <Button onClick={acceptTermsOfService}>{t('TermsOfServicePage.accept')}</Button>
        <Button variant='text' onClick={denyTermsOfService}>
          {t('TermsOfServicePage.deny')}
        </Button>
      </div>
    </CommonPagesLayout>
  );

  function acceptTermsOfService() {
    acceptTermsOfServiceMutate();
    acceptTOS();
    navigate('/');
  }

  function denyTermsOfService() {
    signout();
    navigate('/');
  }
}

const TERMS_OF_SERVICE_QUERY = gql`
  query TermsOfServiceQuery {
    termsOfService {
      html
    }
  }
`;

const ACCEPT_TERMS_OF_SERVICE_MUTATION = gql`
  mutation AcceptTermsOfServiceMutation {
    accept_terms_of_service
  }
`;

const withGraphQlData = graphql(TERMS_OF_SERVICE_QUERY, {
  name: 'termsOfService',
});

export default withGraphQlData(withData('termsOfService')(TermsOfServicePage, { loading: PageLoader }));
