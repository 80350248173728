import { useTranslation } from 'react-i18next';
import { CommonPagesLayout } from 'components/v2/layouts/CommonPagesLayout';
import { Heading } from 'components/mainComponents/Heading';
import { Paper } from 'components/mainComponents/Paper';
import './TutorialsPage.scss';

export function TutorialsPage() {
  const { t, i18n } = useTranslation();
  const tutorialLinks = getTutorialLinks(t);
  return (
    <CommonPagesLayout fullWidth>
      <header className='V2_TutorialsPage__header'>
        <Heading>{t('TutorialsPage.welcome')}</Heading>
        <p className='description'>{t('TutorialsPage.intro')}</p>
      </header>
      <div className='V2_TutorialsPage'>
        {tutorialLinks.map(({ title, links, text }) => {
          const src = links[i18n.language] ?? links['fr'];
          return <Tutorial src={src} key={src} title={title} description={text} />;
        })}
      </div>
    </CommonPagesLayout>
  );
}

function Tutorial({ title, description, src }) {
  return (
    <Paper as='article'>
      <div className='V2_TutorialsPage__tutorial'>
        <Paper.Title>{title}</Paper.Title>
        <p className='V2_TutorialsPage__tutorial-description'>{description}</p>
        <div className='V2_TutorialsPage__tutorial-video'>
          <iframe
            src={src}
            title={title}
            allowFullScreen
            mozallowfullscreen='true'
            webkitallowfullscreen='true'
            oallowfullscreen='true'
            msallowfullscreen='true'
            name='wistia-embed'
            scrolling='no'
            frameBorder='0'
          />
        </div>
      </div>
    </Paper>
  );
}

function getTutorialLinks(t) {
  return [
    {
      title: t('TutorialsPage.homepage'),
      text: t('TutorialsPage.homepageText'),
      links: {
        fr: 'https://aworldforus.wistia.com/medias/qsczc80grm',
        en: 'https://aworldforus.wistia.com/medias/vwt15pgx9z',
      },
    },
    {
      title: t('TutorialsPage.evaluations'),
      text: t('TutorialsPage.evaluationsText'),
      links: {
        fr: 'https://aworldforus.wistia.com/medias/e43i25px5o',
        en: 'https://aworldforus.wistia.com/medias/6h6ai4hxk8',
      },
    },
    {
      title: t('TutorialsPage.activities'),
      text: t('TutorialsPage.activitiesText'),
      links: {
        fr: 'https://aworldforus.wistia.com/medias/72y9wpsr9i',
        en: 'https://aworldforus.wistia.com/medias/v0xi4zvrp3',
      },
    },
  ];
}
