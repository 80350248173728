import React from 'react';
import Icon from 'components/mainComponents/Icon';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { moveIntoFolder, openInNewTab } from '../DocumentUtils';
import { documentLogo } from 'support/logos';
import { DocumentPropTypes, FolderPropTypes } from './DocumentsSharedPropTypes';

const DocumentsList = ({ folders, setFolders, setSelectedFolder, documents, setDocuments, searchInput }) => {
  return (
    <div>
      {/* LIST FOLDERS */}
      <div className='new-documents__list-folders'>
        {folders?.map(({ id, name, isShown, inserted_at }) => (
          <div key={id} className={folderTile(isShown)}>
            <div
              className='new-documents__list-folders-indicator'
              data-folder-id={id}
              onClick={event => moveIntoFolder(event, folders, setFolders, documents, setSelectedFolder, setDocuments, searchInput)}
            >
              <div className='new-documents__list-folders__name'>
                <Icon icon='folder' className='new-documents__folder-icon' />
                <p>{name}</p>
              </div>
              <div className='new-documents__list-folder__date'>
                <p>{inserted_at}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* LIST DOCUMENTS, WHICH DOES NOT BELONG TO ANY FOLDER */}
      <div className='new-documents__list-documents'>
        {documents?.map(document => (
          <div key={document.id} className={documentTile(document.isShown)}>
            <button className='new-documents__list-documents-indicator' onClick={() => openInNewTab(document)}>
              <div className='new-documents__list-documents__info'>
                <Icon icon={documentLogo(document)} className='new-documents__document-icon new-documents__document-icon-margin' />
                <span className='new-documents__list-documents__name'>{document.filename}</span>
              </div>
              <div className='new-documents__list-documents__date'>
                <p>{document.inserted_at}</p>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const folderTile = isShown => clsx('new-documents__list-folders__row', { 'new-documents__hidden': !isShown });
const documentTile = isShown => clsx('new-documents__list-documents__row', { 'new-documents__hidden': !isShown });

DocumentsList.propTypes = {
  folders: PropTypes.arrayOf(FolderPropTypes),
  documents: PropTypes.arrayOf(DocumentPropTypes),
  setFolders: PropTypes.func.isRequired,
  setDocuments: PropTypes.func.isRequired,
  setSelectedFolder: PropTypes.func.isRequired,
};

export default DocumentsList;
