import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';

import { ANSWER_EVALUATION_MUTATION, PENDING_EVALUATION_QUERY } from './queries';
import AnswerEvaluationEditableComponents from 'components/v2/evaluations/AnswerEvaluationEditableComponents';
import { AnswerEvaluationLegacyForm } from 'components/v2/evaluations/AnswerEvaluationLegacyForm';
import { withData } from 'support/page';
import { IDPropType } from 'propTypes';
import { LoadingIndicator } from 'components/mainComponents/LoadingIndicator';

export function AnswerEvaluationWithData(props) {
  const params = useParams();

  if (props.pending_evaluation.evaluation.form && props.pending_evaluation.evaluation.live) {
    return <Navigate to={`/answer_evaluation/live_v1/${params.evaluationId}/${params.traineeId}`} replace />;
  }

  const EvaluationComponent = props.pending_evaluation.evaluation.form
    ? AnswerEvaluationLegacyForm
    : AnswerEvaluationEditableComponents;

  return <EvaluationComponent {...props} />;
}

export function AnswerEvaluation(props) {
  const { loader: Loader = DefaultLoader, ...otherProps } = props;

  const ComponentWithData = compose(
    graphql(PENDING_EVALUATION_QUERY, {
      name: 'pending_evaluation',
      options: props => {
        const trainee_id = !isNaN(props.traineeId) ? props.traineeId : 'null';
        return { variables: { id: props.evaluationId, trainee_id } };
      },
    }),
    graphql(ANSWER_EVALUATION_MUTATION)
  )(withData('pending_evaluation')(AnswerEvaluationWithData, { loading: Loader }));

  return <ComponentWithData {...otherProps} />;
}

AnswerEvaluation.propTypes = {
  traineeId: IDPropType.isRequired,
  evaluationId: IDPropType.isRequired,
  loader: PropTypes.any,
};

// Using a default dedicated loader to have a responsive size
// The LoadingPagePlaceholder has a fixed-width that doesn't go well with v2
// but I didn't want to risk refactoring it.
function DefaultLoader() {
  return <LoadingIndicator fullWidth />;
}
