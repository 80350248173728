import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { ActionButton } from 'components/mainComponents/buttons';
import { getGuestType, GUEST_TYPE } from 'support/auth';
import { ALL_INSTRUCTORS } from 'actions/datesActions';
import { withData, withRouteParams } from 'support/page';
import { DatesModal } from './DatesModal';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import PropTypes from 'prop-types';
import Icon from 'components/mainComponents/Icon';
import Select from 'components/InstructorsSelect';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import fullCalendarFr from '@fullcalendar/core/locales/fr';
import fullCalendarDe from '@fullcalendar/core/locales/de';
import fullCalendarEs from '@fullcalendar/core/locales/es';

import '../../scss/Dates.scss';
import { getLocale } from 'translations/i18n';

const FULL_CALENDAR_LOCALES = [fullCalendarFr, fullCalendarDe, fullCalendarEs];

const Dates = ({ trainingSession, height }) => {
  const { t } = useTranslation();
  const guestType = getGuestType();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState(ALL_INSTRUCTORS);
  const { googleLink, webcalLink } = addNecessaryParamsToUrls(selectedInstructor, guestType, trainingSession);
  const [chosenCalendar, setChosenCalendar] = useState(googleLink);
  const { start_date, end_date } = trainingSession;

  const currentLocale = getLocale();
  const allEvents = trainingSession.training_session_slots;
  const instructorEvents = getInstructorEvents(selectedInstructor);

  useEffect(() => {
    setChosenCalendar(googleLink);
  }, [t, trainingSession, height, googleLink]);

  return (
    <main>
      <div className='header'>
        <h1>{t('TrainingSessionPage.calendar')}</h1>
      </div>
      <div className='Dates'>
        <div className='dates-header'>
          <div className='dates-interval'>
            <Icon icon='calendar' className='calendar-icon' />
            <span>{formatInterval(start_date, end_date, t)}</span>
          </div>
          <div className='dates-select'>
            {guestType !== GUEST_TYPE.TRAINEE && trainingSession.instructors && (
              <Select instructors={trainingSession.instructors} onChange={setSelectedInstructor} />
            )}
          </div>
        </div>
        <div className='calendar'>
          <FullCalendar
            events={instructorEvents}
            eventContent={Event}
            headerToolbar={{
              start: 'today',
              center: 'prev,title,next',
              end: 'dayGridMonth,listYear',
            }}
            initialView='listYear'
            displayEventTime={false}
            locale={currentLocale}
            locales={FULL_CALENDAR_LOCALES}
            plugins={[listPlugin, dayGridPlugin]}
            height={height || 450}
          />
        </div>
        {trainingSession.dates_webcal_url && (
          <>
            <div className='sharing'>
              <ActionButton
                label={t('Dates.export_button')}
                type='secondary'
                className='url-button'
                clickFn={() => setIsModalOpen(true)}
              />
            </div>
            <div className='sharing-link'>
              <span>
                {t('Dates.export_link')} :
                <input type='text' value={webcalLink} placeholder={webcalLink} className='webcal-url-input' readOnly />
              </span>
              <span onClick={() => copyToClipboard(webcalLink)} className='copy'>
                <Icon icon='checkmark' className='checkmark-icon' />
                <Icon icon='copy' className='copy-icon' />
              </span>
            </div>
          </>
        )}
      </div>
      {isModalOpen && (
        <DatesModal
          handleCloseModal={setIsModalOpen}
          setChosenCalendar={setChosenCalendar}
          googleLink={googleLink}
          webcalLink={webcalLink}
          chosenCalendar={chosenCalendar}
        />
      )}
    </main>
  );

  function getInstructorEvents(selectedInstructorId) {
    if (selectedInstructorId === ALL_INSTRUCTORS) {
      return allEvents;
    } else {
      return allEvents.filter(event =>
        event.training_session_instructors.some(instructorData => instructorData.instructor.id === selectedInstructorId)
      );
    }
  }

  function Event(props) {
    const view = props.view.type;
    const timeString = `${props.event.extendedProps.start_time.slice(0, 5)} - ${props.event.extendedProps.end_time.slice(0, 5)}`;
    const instructors = props.event.extendedProps.training_session_instructors
      .map(instructor => `${instructor.instructor.firstname} ${instructor.instructor.lastname}`)
      .join(', ');

    return (
      <div className={`Calendar__event__${view}`}>
        <p>{timeString}</p>
        <p>
          {props.event.extendedProps.subsession.name} - {t(`Dates.${props.event.extendedProps.subsession.modality}`)}
        </p>
        {view === 'listYear' && (
          <div className='Calendar__event__instructor'>
            <div className='Calendar__event__instructor__icon'>
              <i className='icon-logo-placeholder' />
            </div>
            <p>{instructors}</p>
          </div>
        )}
      </div>
    );
  }
};

function copyToClipboard(webcalLink) {
  navigator.clipboard.writeText(webcalLink);
  document.querySelector('.copy-icon').classList.add('hidden-copy-icon');
  document.querySelector('.checkmark-icon').classList.add('checkmark-icon-show');
  setTimeout(() => {
    document.querySelector('.copy-icon').classList.remove('hidden-copy-icon');
    document.querySelector('.checkmark-icon').classList.remove('checkmark-icon-show');
  }, 1300);
}

const formatInterval = (startDate, endDate, t) => {
  if (startDate && endDate && startDate !== endDate) {
    return `${t('Dates.session_from')} ${moment(startDate).format('DD/MM/YYYY')} ${t('Dates.session_until')} ${moment(endDate).format(
      'DD/MM/YYYY'
    )}`;
  }
  if (startDate && endDate) {
    return `${t('Dates.session_from')} ${moment(startDate).format('DD/MM/YYYY')}`;
  }
  return '';
};

const addNecessaryParamsToUrls = (selectedInstructor, guestType, { dates_google_url, dates_webcal_url, id, subsessions }) => {
  const webcal = new URL(dates_webcal_url);
  const google = new URL(dates_google_url);

  webcal.searchParams.append('sessions[]', id);
  webcal.searchParams.append('subsession_dates_are_interval', false);
  if (guestType === GUEST_TYPE.TRAINEE) {
    subsessions.flatMap(({ id }) => webcal.searchParams.append('subsessions[]', id));
  }

  if (selectedInstructor !== ALL_INSTRUCTORS) {
    webcal.searchParams.append('instructors[]', selectedInstructor);
  }

  const webcalLink = webcal.toString().replace(webcal.protocol, 'webcal:');
  google.searchParams.append('cid', webcalLink);

  return {
    webcalLink: webcalLink,
    googleLink: google.toString(),
  };
};

const trainingSessionDatesQuery = gql`
  query training_session_dates($id: ID!) {
    trainingSession(id: $id) {
      id
      name
      custom_name

      start_date
      end_date
      dates_webcal_url
      dates_google_url
      subsessions {
        id
      }
      training_session_slots {
        start_time
        end_time
        slot
        date
        training_session_instructors {
          instructor {
            id
            firstname
            lastname
          }
        }
        subsession {
          id
          name
          modality
        }
        room {
          id
          name
        }
      }
      instructors {
        id
        firstname
        lastname
      }
    }
  }
`;

const withGraphqlData = graphql(trainingSessionDatesQuery, {
  name: 'trainingSession',
  options: props => {
    const ts_id = props.params.training_session_id ? props.params.training_session_id : props.params.id;
    return {
      variables: { id: parseInt(ts_id, 10) },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('trainingSession')(
        Dates, { loading: LoadingPagePlaceholder })));

Dates.propTypes = {
  trainingSession: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    dates_google_url: PropTypes.string,
    subsessions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    training_session_slots: PropTypes.arrayOf(
      PropTypes.shape({
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        date: PropTypes.string,
        slot: PropTypes.string,
        training_session_instructors: PropTypes.arrayOf(
          PropTypes.shape({
            instructor: PropTypes.shape({
              id: PropTypes.string,
              firstname: PropTypes.string,
              lastname: PropTypes.string,
            }),
          })
        ),
        subsession: PropTypes.shape({
          name: PropTypes.string,
          modality: PropTypes.string,
        }),
        room: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ),
    dates_webcal_url: PropTypes.string,
  }).isRequired,
};
