import { gql } from '@apollo/client';

export const PASSWORDLESS_VALIDATION = gql`
  mutation passwordless_validation($token: String!, $user_code: String!) {
    passwordlessValidation(token: $token, user_code: $user_code) {
      guest {
        id
        type
        name
        locale
        should_accept_tos
      }
      token
      switch_guest_infos {
        token
        type
      }

      error {
        code
        message
      }
    }
  }
`;
