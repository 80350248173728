import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { userFullname } from 'controllers/userController';
import { formatSlotDate } from 'support/date';
import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import { HorizontalRule } from 'components/mainComponents/HorizontalRule';
import { Paper } from 'components/mainComponents/Paper';

import './SlotAttendanceTracking.scss';
import { URL_PARAM_DIGIFORMA_URL } from './constants';
import { TrainingSessionSignatures } from './TrainingSessionSignatures';
import { RemoteSigningPanel } from './RemoteSigningPanel';

export function SlotAttendanceTracking(props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const instructors = [...props.sessionSlot.trainingSessionInstructors].sort(compareInstructors);
  const trainees = props.sessionSlot.customers.flatMap(customer => customer.customerTrainees).sort(compareTrainees);

  const digiformaUrl = searchParams.get(URL_PARAM_DIGIFORMA_URL);

  return (
    <div>
      <Heading as='h3' level={3} className='SlotAttendanceTracking__heading'>
        {formatSlotDate(props.sessionSlot)}
      </Heading>
      <HorizontalRule />
      <div className='SlotAttendanceTracking__content'>
        <Paper className='SlotAttendanceTracking__in-class-signing'>
          <Paper.Title>{t('TrainingSessionAttendanceTracking.in_class_signing')}</Paper.Title>
          <Paper.Actions>
            <Button
              as='a'
              href={`/ts/${props.trainingSessionId}/attendance/sign/class?date=${props.sessionSlot.id}&goback=true${
                digiformaUrl ? '&fromDigiforma=true' : ''
              }`}
            >
              {t('TrainingSessionAttendanceTracking.in_class_signing_action')}
            </Button>
          </Paper.Actions>
        </Paper>
        <div className='SlotAttendanceTracking__remote-signing'>
          <RemoteSigningPanel
            instructors={instructors}
            trainees={trainees}
            signatures={props.sessionSlot.signatures}
            onSendEmails={props.onSendRemoteSigningEmails}
            sessionSlotId={props.sessionSlot.id}
          />
        </div>
        <div className='SlotAttendanceTracking__signatures'>
          <Heading level={4} as='h4' className='SlotAttendanceTracking__heading'>
            {t('TrainingSessionAttendanceTracking.signatures_summary')}
          </Heading>
          <TrainingSessionSignatures instructors={instructors} trainees={trainees} signatures={props.sessionSlot.signatures} />
        </div>
      </div>
    </div>
  );
}

const compareInstructors = (a, b) => {
  let fullnameA = userFullname(a.instructor);
  let fullnameB = userFullname(b.instructor);
  return fullnameA.localeCompare(fullnameB);
};

const compareTrainees = (a, b) => {
  let fullnameA = userFullname(a.trainee);
  let fullnameB = userFullname(b.trainee);
  return fullnameA.localeCompare(fullnameB);
};
