import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import _, { flowRight as compose } from 'lodash';
import React from 'react';

import AnswerEvaluationEditableComponents from './AnswerEvaluationEditableComponents';
import AnswerEvaluationLegacyForm from './AnswerEvaluationLegacyForm';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { editableComponentsQuery } from 'support/editableComponentsUtils';
import { withData } from 'support/page';

function LoadedAnswerEvaluation(props) {
  const { evaluation } = props.pending_evaluation;
  const EvaluationComponent = evaluation.form ? AnswerEvaluationLegacyForm : AnswerEvaluationEditableComponents;

  return <EvaluationComponent {...props} />;
}

const query = gql`
  query pending_evaluation($id: ID!, $trainee_id: ID!) {
    pending_evaluation(id: $id, trainee_id: $trainee_id) {
      id
      pending
      trainee_name
      answer_evaluation_result {
        score
        total
      }
      form_answer {
        answers
      }
      evaluation {
        read
        editable_components ${editableComponentsQuery({ withQuizzes: true, withBasic: false })}
        id
        name
        type
        live
        module_id
        current_page
        sync_with_instructor
        replayable
        show_score
        training_session {
          id
          name
        }
        form {
          id
          inputs {
            id
            type
            data
          }
        }
      }
    }
  }
`;

const mutation = gql`
  mutation answer_evaluation($id: ID!, $trainee_id: ID, $answers: String!) {
    answer_evaluation(id: $id, answers: $answers, trainee_id: $trainee_id) {
      id
      pending
      answer_evaluation_result {
        score
        total
      }
      form_answer {
        answers
      }
    }
  }
`;

export function AnswerEvaluation(props) {
  const { loader: Loader = LoadingPagePlaceholder, ...otherProps } = props;
  const ComponentWithData = compose(
    graphql(query, {
      name: 'pending_evaluation',
      options: props => {
        let trainee_id = parseInt(props.trainee_id, 10);
        if (_.isNaN(trainee_id)) {
          trainee_id = 'null';
        }
        return { variables: { id: parseInt(props.evaluation_id, 10), trainee_id } };
      },
    }),
    graphql(mutation)
  )(withData('pending_evaluation')(LoadedAnswerEvaluation, { loading: Loader }));

  return <ComponentWithData {...otherProps} />;
}
