import React from 'react';
import PropTypes from 'prop-types';

import './TrainingSessionBanner.scss';
import { Heading } from 'components/mainComponents/Heading';
import { Paper } from 'components/mainComponents/Paper';

export function TrainingSessionBanner(props) {
  const hasImage = props.img && props.img.length > 0;

  return (
    <header className={`TrainingSessionBanner ${!hasImage ? 'TrainingSessionBanner--no-image' : ''}`}>
      {hasImage && (
        <div
          className={`TrainingSessionBanner__image TrainingSessionBanner__image--size-${props.size}`}
          style={{ backgroundImage: `url('${props.img}')` }}
        />
      )}
      <Paper className={`TrainingSessionBanner__title ${!hasImage ? 'TrainingSessionBanner__title--no-image' : ''}`}>
        <Heading level={1} as='h1'>
          {props.children}
        </Heading>
      </Paper>
    </header>
  );
}

TrainingSessionBanner.propTypes = {
  img: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  children: PropTypes.string.isRequired,
};

TrainingSessionBanner.defaultProps = {
  size: 'md',
};
