import { BACKGROUND_CUSTOM, BACKGROUND_UPLOAD } from 'constants/video_conference';

export async function updateBackgroundImage(chosenBackground, persistSetting, callObject) {
  if (chosenBackground.value === BACKGROUND_UPLOAD || chosenBackground.value === BACKGROUND_CUSTOM) {
    const blob = await fetch(chosenBackground.config.source).then(image => image.blob());
    const dataUrl = await blobToDataUrl(blob);
    chosenBackground.config = {
      source: dataUrl,
    };
  }
  persistAndApplyBackgroundSettings(chosenBackground, persistSetting, callObject);
}

const blobToDataUrl = blob => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

function persistAndApplyBackgroundSettings(chosenBackground, persistSetting, callObject) {
  const { type, config } = chosenBackground;
  persistSetting('backgroundEffect', chosenBackground);
  callObject.updateInputSettings({
    video: {
      processor: {
        type,
        config,
      },
    },
  });
}
