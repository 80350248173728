import { useDevices } from '@daily-co/daily-react';
import { ActionButton } from 'components/mainComponents/buttons';
import Dropdown from 'components/video_conference/Dropdown';
import { GRANTED_STATE, NOT_FOUND, RECORDING } from 'constants/video_conference';
import { useSettingsPersistence } from 'hooks/video_conference';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function MicStepTest({
  isHidden,
  className,
  state,
  micUnavailable,
  isRecordingFinished,
  startRecording,
  setMicUnavailable,
  resetState,
  onCompleted,
  isPlayback,
  onPlaybackToggle,
  buttonClassName,
}) {
  const { t } = useTranslation();
  const { microphones, micState, setMicrophone } = useDevices();

  const persistSetting = useSettingsPersistence();

  const [selectedMic, setSelectedMic] = useState(null);

  const options = microphones?.map(mic => ({ value: mic.device.deviceId, label: mic.device.label }));

  const handleMicChange = newMic => {
    setMicrophone(newMic);
    persistSetting('audioDeviceId', newMic);
    setSelectedMic(newMic);
    resetState();
    onCompleted(false);
  };

  useEffect(() => {
    if (micState === GRANTED_STATE && selectedMic === null) {
      setSelectedMic(microphones.find(mic => mic.selected)?.device.deviceId);
    }
    if (micState === NOT_FOUND) {
      setMicUnavailable(true);
    }
  }, [micState, selectedMic, microphones, setMicUnavailable]);

  return (
    !isHidden && (
      <div className={className}>
        <Dropdown
          name='mics'
          options={options}
          onChange={handleMicChange}
          value={selectedMic}
          disabled={state === RECORDING || micUnavailable}
          label={t('Settings.microphone')}
        />
        {isRecordingFinished ? (
          <ActionButton
            icon={isPlayback ? 'pause--circle' : 'play--circle'}
            clickFn={onPlaybackToggle}
            type='secondary'
            disabled={!isRecordingFinished}
            label={isPlayback ? t('MicStepTitle.pause') : t('MicStepTitle.play')}
            className={buttonClassName}
          />
        ) : (
          <ActionButton
            clickFn={state !== RECORDING && !micUnavailable ? startRecording : undefined}
            label={state === RECORDING ? t('MicStep.button.recording') : t('MicStep.button.record')}
            icon={state === RECORDING ? 'loading-spinner--dark--one-quarter' : 'record--circle'}
            className={buttonClassName}
          />
        )}
      </div>
    )
  );
}

export default MicStepTest;

MicStepTest.propTypes = {
  isHidden: PropTypes.bool,
  className: PropTypes.string,
  state: PropTypes.string,
  micUnavailable: PropTypes.bool,
  isRecordingFinished: PropTypes.bool,
  startRecording: PropTypes.func.isRequired,
  setMicUnavailable: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  isPlayback: PropTypes.bool,
  onPlaybackToggle: PropTypes.func.isRequired,
  buttonClassName: PropTypes.string,
};

MicStepTest.defaultProps = {
  isHidden: false,
  className: '',
  state: null,
  micUnavailable: false,
  isRecordingFinished: false,
  isPlayback: false,
  buttonClassName: '',
};
