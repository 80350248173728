import React, { useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { flowRight as compose } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TrainingSessionHeader from '../training_session/TrainingSessionHeader';
import EvaluationList from '../evaluation/EvaluationList';
import { withData, withRouteParams } from 'support/page';
import { buildPatternStyle } from 'support/image';
import { COMMENT_FRAGMENT } from 'controllers/comment_controller.js';
import Comment from '../module/forum/Comment';
import { useAuth } from 'contexts/AuthContext';
import 'scss/TrainingSessionTrackingPage.scss';
import trainingSessionTrackingPageCommentStyles from 'scss/TrainingSessionTrackingPageComments.module.scss';
import CommentList from '../module/forum/CommentList';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb';

const TrainingSessionTrackingPage = props => {
  const { t } = useTranslation();

  const { training_session } = props;
  const trainingSessionName = training_session.custom_name || training_session.name;
  const answeredEvaluations = training_session.pending_evaluations.filter(pe => pe.pending !== true);

  return (
    <div className='page TrainingSessionTrackingPage'>
      <TrainingSessionHeader training_session_id={training_session.id} selected='tracking' />
      {training_session.show_trainee_pedagogical_tracking_in_extranet && (
        <main>
          <div className='hero' style={buildPatternStyle(training_session)}>
            <h1>{trainingSessionName}</h1>
          </div>
          <Breadcrumb
            links={[
              { name: trainingSessionName, url: `/ts/${training_session.id}` },
              { name: t('TrainingSessionHeader.educational_follow_up') },
            ]}
          />
          {answeredEvaluations.length > 0 && (
            <section className='training-session-evaluations'>
              <h2>{t('TrainingSessionTrackingPage.answers_to_evaluations')}</h2>
              <EvaluationList evaluations={answeredEvaluations} />
            </section>
          )}
          <CommentPanels {...props} />
        </main>
      )}
    </div>
  );
};

function CommentPanels(props) {
  const { auth } = useAuth();
  const { t } = useTranslation();

  const { training_session, training_session_comments } = props;
  const guestType = auth?.guestInfo?.type;
  const isTrainee = guestType === 'trainee';
  const trainees = filterTraineesForGuest(training_session.trainees, guestType, auth?.guestInfo?.id);
  const comment_groups = group_comments_by_trainee(trainees, training_session_comments?.comments ?? []);
  const Compo = comment_groups.length === 1 && isTrainee ? CommentPanelSimple : CommentPanel;

  return (
    <section className='CommentPanels'>
      <h2>{t('ModuleHeader.educational_follow_up')}</h2>
      {isTrainee && <p className='explanation'>{t('TrainingSessionTrackingPage.here_are_some_personal_comments')}</p>}
      {!isTrainee && <p className='explanation'>{t('TrainingSessionTrackingPage.write_personal_comments_for_trainees')}</p>}
      <>
        {comment_groups.map(trainee => (
          <Compo trainee={trainee} {...props} key={`comments-for-trainee-${trainee.id}`} />
        ))}
      </>
    </section>
  );
}

function group_comments_by_trainee(trainees, comments) {
  return trainees.map(trainee => {
    const traineeComments = comments.filter(c => c.trainee_id === parseInt(trainee.id)).sort((a, b) => a.id <= b.id);
    return { ...trainee, comments: traineeComments };
  }, {});
}

function CommentPanelSimple(props) {
  const {
    trainee: { id: trainee_id, comments },
    ...rest
  } = props;

  return (
    <div className='CommentPanel'>
      <CommentPanelRightColumn>
        <CommentListPanel {...{ ...rest, comments, trainee_id }} />
      </CommentPanelRightColumn>
    </div>
  );
}

function CommentPanel(props) {
  const {
    trainee: { id: trainee_id, firstname, lastname, comments },
    ...rest
  } = props;
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={`CommentPanel ${(opened && 'open') || ''}`}>
      <CommentPanelLeftColumn onclickFn={() => setOpened(!opened)}>
        <div className={`comment-panel-header ${opened ? 'open' : ''}`}>
          <span>{t('trainee')}</span>
          <span className='comment-panel-traine-name'>{` ${firstname} ${lastname}`}</span>
        </div>
      </CommentPanelLeftColumn>
      {opened && (
        <CommentPanelRightColumn>
          <CommentListPanel {...{ comments, trainee_id, ...rest }} />
        </CommentPanelRightColumn>
      )}
    </div>
  );
}

function CommentPanelLeftColumn(props) {
  return (
    <div className='comment-panel-left-column' onClick={props.onclickFn}>
      {props.children}
    </div>
  );
}

function CommentPanelRightColumn(props) {
  return <div className='comment-panel-right-column'>{props.children}</div>;
}

function TimeMark(props) {
  const { inserted_at } = props.comment;
  const date = moment.utc(inserted_at).local().format('DD/MM/YYYY, HH:mm');
  return (
    <div className='TimeMark'>
      <div className='timemark-date'>
        <span>{date}</span>
      </div>
      <div className='timeline' />
      <div className='note'>
        <Comment styles={props.styles} {...{ ...props, foldable: true }} />
      </div>
    </div>
  );
}

function CommentListPanel({ comments, ...props }) {
  const { auth } = useAuth();
  const { t } = useTranslation();
  return (
    <div>
      <CommentList
        styles={trainingSessionTrackingPageCommentStyles}
        renderInput={auth?.guestInfo?.type !== 'trainee'}
        commentComponent={TimeMark}
        comments={comments}
        training_session_id={props.training_session.id}
        trainee_id={props.trainee_id}
        minRows={3}
        placeholder={t('TrainingSessionTrackingPage.new_comment')}
      />
    </div>
  );
}

function filterTraineesForGuest(trainees, guestType, guestId) {
  switch (guestType) {
    case 'user':
    case 'instructor':
      return trainees;
    case 'trainee':
      return trainees.filter(t => t.id === guestId);
    case 'company':
      return trainees.filter(t => t.company !== null && t.company.id === guestId);
    default:
      return [];
  }
}

const trackingQuery = gql`
  query training_session_tracking_page($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      show_program_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      has_numeric_signing
      image {
        url
      }
      start_date
      modules {
        id
        name
        visible
      }
      program {
        id
      }
      pending_evaluations {
        pending
        trainee_id
        trainee_name
        evaluation {
          id
          name
          type
        }
      }
      trainees {
        id
        firstname
        lastname
        logo {
          url
        }
        company {
          id
          name
        }
      }
    }
  }
`;

const commentQuery = gql`
  query comment_query($id: ID!) {
    training_session(id: $id) {
      comments {
        ...comment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

const withGraphqlData = compose(
  graphql(trackingQuery, {
    name: 'training_session',
    options: props => {
      return {
        variables: { id: parseInt(props.params.training_session_id, 10) },
      };
    },
  }),
  graphql(commentQuery, {
    name: 'training_session_comments',
    options: props => {
      return {
        variables: { id: parseInt(props.params.training_session_id, 10) },
        pollInterval: 12000,
      };
    },
  })
);

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session', {request: 'training_session_comments', data: 'training_session'} )(
        TrainingSessionTrackingPage, { loading: LoadingPagePlaceholder })));
