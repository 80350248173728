const commonComponentFields = 'id order read component_type';

export const editableComponentsQuery = ({ withBasic = true, withQuizzes = false } = {}) => `
  {
    __typename
    ... on ConfigEditableComponent {
      ${commonComponentFields}
      modifiers {
        layout
      }
    }
    ${withBasic ? basicComponents : ''}
    ${withQuizzes ? quizComponents : ''}
  }
`;

const basicComponents = `
... on TitleEditableComponent {
  ${commonComponentFields}
  content {
    text
  }
}
... on HeadingEditableComponent {
  ${commonComponentFields}
  content {
    text
  }
  modifiers {
    size
  }
}
... on TextEditableComponent {
  ${commonComponentFields}
  content {
    text
    rawText
  }
}
... on TitleParagraphEditableComponent {
  ${commonComponentFields}
  content {
    text
    rawText
  }
}
... on VideoEditableComponent {
  ${commonComponentFields}
  content {
    url
    caption
    wistiaId
    watchThreshold
  }
  modifiers {
    width
  }
}
... on ImageEditableComponent {
  ${commonComponentFields}
  content {
    url
    caption
    raw_caption
  }
  modifiers {
    width
    caption_layout
  }
}
... on DocumentEditableComponent {
  ${commonComponentFields}
  content {
    url
    html
    text
  }
}
... on WebResourceEditableComponent {
  ${commonComponentFields}
  content {
    url
    html
  }
  modifiers {
    width
  }
}
`;

const quizComponents = `
  ... on QuizFreeTextEditableComponent {
  ${commonComponentFields}
  latest_reply {
    answer
  }
  content {
    raw_question
    html_question
  }
}
... on QuizStarsRatingEditableComponent {
  ${commonComponentFields}
  latest_reply {
    rate
  }
  content {
    raw_question
    html_question
  }
}
... on QuizOutOfTenEditableComponent {
  ${commonComponentFields}
  latest_reply {
    rate
  }
  content {
    raw_question
    html_question
    raw_pre_label
    html_pre_label
    raw_post_label
    html_post_label
  }
}
... on QuizSingleChoiceEditableComponent {
  ${commonComponentFields}
  latest_reply {
    answer_id
  }
  latest_reply_validation {
    is_correct
    raw_correction
  }
  content {
    raw_question
    html_question
    is_verifiable
    answers {
      id
      is_correct
      html_text
      raw_text
      order
    }
  }
}
... on QuizMultipleChoiceEditableComponent {
  ${commonComponentFields}
  latest_reply {
    answer_id
  }
  latest_reply_validation {
    is_correct
    raw_correction
  }
  content {
    raw_question
    html_question
    is_verifiable
    answers {
      id
      is_correct
      html_text
      raw_text
      order
    }
  }
}
... on QuizMatrixEditableComponent {
  ${commonComponentFields}
  latest_reply {
    answer_id
    question_id
  }
  content {
    html_question
    questions {
      id
      html_text
      raw_text
      order
    }
    answers {
      id
      html_text
      raw_text
      order
    }
  }
}
`;
