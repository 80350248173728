import React from 'react';

function EmailMarker({ email, activity: { finished } }) {
  return <div className='email-marker'>{finished && renderContent(email)}</div>;
}

function renderContent({ email: { title, content } }) {
  return (
    <>
      <div className='email-title' dangerouslySetInnerHTML={{ __html: title }} />
      <div className='email-body' dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
}

export default EmailMarker;
