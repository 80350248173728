export function evaluationLogo(e) {
  switch (e.type) {
    case 'activity':
      return 'checkbox--checked';
    case 'pre':
      return 'telescope';
    case 'hot':
      return 'rocket--launch';
    case 'cold':
      return 'moon--flag';
    case 'company':
      return 'robot--head';
    case 'instructor':
      return 'astronaute--head';
    default:
      return 'ufo';
  }
}

export function documentLogo({ mime }) {
  if (/zip/.test(mime)) {
    return 'folder--zip';
  } else if (/audio/.test(mime)) {
    return 'document--audio';
  } else if (/image/.test(mime)) {
    return 'image';
  } else if (/video/.test(mime)) {
    return 'document--video';
  } else if (/excel/.test(mime)) {
    return 'document-xlsx';
  } else if (/officedocument.*spreadsheetml/.test(mime)) {
    return 'document--xls';
  } else if (/powerpoint/.test(mime)) {
    return 'document--powerpoint';
  } else if (/officedocument.*presentation/.test(mime)) {
    return 'document--powerpoint';
  } else if (/msword/.test(mime)) {
    return 'document--word';
  } else if (/officedocument.*wordprocessing/.test(mime)) {
    return 'document--word';
  } else if (/pdf/.test(mime)) {
    return 'document--pdf';
  } else if (/opendocument/.test(mime)) {
    return 'document';
  } else {
    return 'document';
  }
}
