import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';
import { flowRight as compose } from 'lodash';

import { useAuth } from 'contexts/AuthContext';
import { COMMENT_FRAGMENT } from 'controllers/comment_controller.js';
import { userFullname } from 'controllers/userController';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Heading } from 'components/mainComponents/Heading';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { MasterDetailLayout } from 'components/v2/layouts/MasterDetailLayout';
import { GUEST_TYPE } from 'support/auth';
import { withData, withRouteParams } from 'support/page';

import './TrainingSessionFollowupPage.scss';
import { TraineeFollowupThread } from './TraineeFollowupThread';
import { CommentThreadInitiator } from 'components/mainComponents/CommentThread';

function TrainingSessionFollowup(props) {
  const { auth } = useAuth();
  const { t } = useTranslation();

  const trainees = props.training_session.trainees ?? [];
  const isTrainee = auth.guestInfo.type === GUEST_TYPE.TRAINEE;
  const commentsByTrainee = commentThreadsByTrainee(trainees, props.training_session_comments.comments);

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${props.training_session.id}`}>
          {props.training_session.custom_name ?? props.training_session.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('TrainingSessionFollowupPage.title')}</Breadcrumb.Item>
      </Breadcrumb>

      <Heading as='h1' level={1} className='TrainingSessionFollowupPage__heading'>
        {t('TrainingSessionFollowupPage.title')}
      </Heading>

      <p className='TrainingSessionFollowupPage__intro'>
        {t(`TrainingSessionFollowupPage.${!isTrainee ? 'instructor' : 'trainee'}_intro`)}
      </p>

      {isTrainee && (
        <TraineeFollowupThread comments={commentsByTrainee.get(auth.guestInfo.id)} trainingSessionId={props.training_session.id} />
      )}
      {!isTrainee && (
        <>
          <MasterDetailLayout items={trainees} itemDisplay={userFullname}>
            {trainee => (
              <>
                <Heading as='h4' level={4}>
                  {userFullname(trainee)}
                </Heading>
                <CommentThreadInitiator trainingSessionId={props.training_session.id} traineeId={trainee.id} />
                {trainee !== undefined && (
                  <TraineeFollowupThread comments={commentsByTrainee.get(trainee.id)} trainingSessionId={props.training_session.id} />
                )}
              </>
            )}
          </MasterDetailLayout>
        </>
      )}
    </LeftMenuLayout>
  );
}

function commentThreadsByTrainee(allTrainees, comments) {
  const initialMap = new Map(allTrainees.map(trainee => [trainee.id, []]));

  return comments.reduceRight((commentsMap, comment) => {
    commentsMap.get(comment.trainee_id.toString()).push(comment);
    return commentsMap;
  }, initialMap);
}

const trackingQuery = gql`
  query training_session_tracking_page($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      show_program_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      has_numeric_signing
      image {
        url
      }
      start_date
      modules {
        id
        name
        visible
      }
      program {
        id
      }
      trainees {
        id
        firstname
        lastname
        logo {
          url
        }
        company {
          id
          name
        }
      }
    }
  }
`;

const commentQuery = gql`
  query comment_query($id: ID!) {
    training_session(id: $id) {
      id
      comments {
        ...comment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

const withGraphqlData = compose(
  graphql(trackingQuery, {
    name: 'training_session',
    options: props => {
      return {
        variables: { id: parseInt(props.params.training_session_id, 10) },
      };
    },
  }),
  graphql(commentQuery, {
    name: 'training_session_comments',
    options: props => {
      return {
        variables: { id: parseInt(props.params.training_session_id, 10) },
        pollInterval: 12000,
      };
    },
  })
);

export const TrainingSessionFollowupPage = withRouteParams(
  withGraphqlData(
    withData('training_session', { request: 'training_session_comments', data: 'training_session' })(TrainingSessionFollowup, {
      loading: PageLoader,
    })
  )
);
