import { RoomOptsContext } from 'contexts/video_conference';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigateMaybeWithFeedback } from 'components/video_conference/FeedbackContext';
import styles from 'scss/video_conference/RoomMenu.module.scss';
import { ActionButton } from 'components/mainComponents/buttons';
import DisplayModeModal from 'components/video_conference/Tray/DisplayModeModal';
import useMedia from 'hooks/video_conference/useMedia';
import IconButton from 'components/video_conference/IconButton';
import { MENU_ELEMENT } from 'constants/video_conference/roomElements';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import { MD_BREAKPOINT } from 'constants/breakpoints';
import clsx from 'clsx';
import RoomMenuMobileTray from './RoomMenuMobileTray';

function RoomMenu() {
  const { t } = useTranslation();

  const isDesktop = useMedia(MD_BREAKPOINT);
  const navigateMaybeWithFeedback = useNavigateMaybeWithFeedback();
  const { trainingSessionId, moduleId, trainingSessionName, moduleName, academyName, logo } = useContext(RoomOptsContext);
  const { roomElements, closeRoomElement } = useRoomElementsDisplay();

  const handleQuitClick = () => {
    navigateMaybeWithFeedback(`/ts/${trainingSessionId}/module/${moduleId}`);
  };

  const handleCloseMenuClick = () => closeRoomElement(MENU_ELEMENT);

  const isVisible = roomElements.includes(MENU_ELEMENT) || isDesktop;

  const containerMobileStyles = clsx(styles.container, { [styles['container--mobile']]: !isDesktop });

  return (
    isVisible && (
      <div className={containerMobileStyles}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <span>
              {logo?.url ? (
                <img src={logo.url} alt={t('Header.academy_logo', { name: academyName })} className={styles.logoIcon} />
              ) : (
                academyName
              )}
            </span>
          </div>
          <div className={styles.headerText}>
            <h1 className={styles.upperHeader}>{trainingSessionName}</h1>
            <h2 className={styles.lowerHeader}>{moduleName}</h2>
          </div>
          {isDesktop && (
            <ActionButton
              type='secondary'
              label={t('Tray.quit')}
              clickFn={handleQuitClick}
              icon='logout'
              className={styles.exitButton}
            />
          )}
        </div>
        {!isDesktop && <RoomMenuMobileTray />}
        {!isDesktop && <IconButton icon='cross' clickFn={handleCloseMenuClick} className={styles.closeButton} type='secondary' />}
        <DisplayModeModal />
      </div>
    )
  );
}

export default RoomMenu;
