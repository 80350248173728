import { ComponentsRenderer } from '@a-world-for-us/elearning_content_editor';
import { gql, useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { ActivityScore } from './ActivityScore';
import { useParentCallback } from 'contexts/ParentCallbackContext';
import { editableComponentsQuery } from 'support/editableComponentsUtils';
import { getLocale } from 'translations/i18n';

export function HtmlDocActivity({ html_doc, traineeId, previewMode }) {
  const { data } = useQuery(GET_HTML_DOC_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: parseInt(html_doc.id) },
  });
  const fireParentCallback = useParentCallback();
  const [setDocumentRead] = useMutation(SET_DOCUMENT_READ_MUTATION);
  const [setEditableComponentRead] = useMutation(SET_EDITABLE_COMPONENT_READ_MUTATION);
  const [createQuizReply] = useMutation(CREATE_QUIZ_REPLY_MUTATION);

  const isGrapeContentFetched = data && data.html_doc?.html != null && data.html_doc?.css != null;
  const areEditableComponentsFetched = data && data.html_doc?.editable_components && data.html_doc?.editable_components.length > 0;

  const [isHtmlDocRead, setIsHtmlDocRead] = useState(data?.html_doc?.read ?? false);

  const renderGrapeEditorContent = () => {
    if (!isGrapeContentFetched) return null;

    return (
      <>
        <style> {data.html_doc.css} </style>
        <div dangerouslySetInnerHTML={{ __html: data.html_doc.html }} />
      </>
    );
  };

  const renderComponentEditorRenderer = () => {
    if (!areEditableComponentsFetched) return null;

    return <div id='components-renderer' />;
  };

  const computeTestsResult = data => {
    if (isHtmlDocRead) {
      const results = data.html_doc.editable_components
        ?.map(component => component.latest_reply_validation?.is_correct)
        .filter(Boolean);

      const score = results.filter(is_correct => is_correct).length;
      const total = results.length;

      return { score, total };
    }
    return null;
  };

  const assignReplyValidationDataToEditableComponent = (componentId, validationData) => {
    const index = data.html_doc.editable_components.findIndex(({ id }) => id === componentId);
    const newComponentData = { ...data.html_doc.editable_components[index] };
    newComponentData.latest_reply_validation = validationData;
    data.html_doc.editable_components[index] = newComponentData;
  };

  const renderScore = () => {
    if (isHtmlDocRead && data?.html_doc?.show_score) {
      const res = computeTestsResult(data);
      if (res && res.total > 0) {
        const percent = _.round((res.score / res.total) * 100, 1);
        const correct = res.score === res.total;
        return <ActivityScore score={res.score} total={res.total} percent={percent} correct={correct} />;
      }
    }
    return null;
  };

  useEffect(() => {
    if (isGrapeContentFetched) {
      setDocumentRead({ variables: { id: data.html_doc.id } }).then(fireParentCallback);
      return;
    }

    if (!areEditableComponentsFetched) return;

    ComponentsRenderer.init({
      elementId: 'components-renderer',
      componentsPayload: data.html_doc.editable_components,
      shouldTrackProgress: true,
      onContentRead: () => {
        setDocumentRead({ variables: { id: data.html_doc.id } }).then(fireParentCallback);
        setIsHtmlDocRead(true);
      },
      onComponentRead: id => {
        setEditableComponentRead({ variables: { id } }).then(fireParentCallback);
      },
      config: {
        language: getLocale(),
        showValidation: data.html_doc.show_validation,
        isReplayable: data.html_doc.replayable,
        sendQuizAnswer: (id, reply) => {
          const res = createQuizReply({
            variables: { id, traineeId, previewMode, ...reply },
          });
          res.then(fireParentCallback);
          res.then(({ data: { create_quiz_reply: reply_data } }) => {
            if (reply_data?.is_verifiable) {
              assignReplyValidationDataToEditableComponent(id, reply_data.validation);
            }
          });

          return res;
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className='html-doc'>
      {renderScore()}
      {renderGrapeEditorContent()}
      {renderComponentEditorRenderer()}
      {renderScore()}
    </div>
  );
}

const GET_HTML_DOC_QUERY = gql`
  query html_doc($id: ID!) {
    html_doc(id: $id) {
      id
      html
      css
      editable_components ${editableComponentsQuery({ withQuizzes: true })}
      replayable
      show_score
      show_validation
      read
    }
  }
`;

const SET_DOCUMENT_READ_MUTATION = gql`
  mutation set_html_doc_read($id: ID!) {
    set_html_doc_read(id: $id)
  }
`;

const SET_EDITABLE_COMPONENT_READ_MUTATION = gql`
  mutation set_editable_component_read($id: ID!) {
    set_editable_component_read(id: $id)
  }
`;

const CREATE_QUIZ_REPLY_MUTATION = gql`
  mutation (
    $id: ID!
    $traineeId: ID
    $answer: String
    $rate: Int
    $singleChoiceAnswer: QuizTestReplyInput
    $multipleChoiceAnswers: [QuizTestReplyInput]
    $matrixAnswers: [QuizMatrixReplyInput]
    $previewMode: Boolean
  ) {
    create_quiz_reply(
      editable_component_id: $id
      trainee_id: $traineeId
      quizFreeTextReply: { answer: $answer }
      quizStarsRatingReply: { rate: $rate }
      quizOutOfTenReply: { rate: $rate }
      quizSingleChoiceReply: $singleChoiceAnswer
      quizMultipleChoiceReplies: $multipleChoiceAnswers
      quizMatrixReplies: $matrixAnswers
      previewMode: $previewMode
    ) {
      is_verifiable
      validation {
        is_correct
        raw_correction
      }
    }
  }
`;
