import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import CallObjectProvider from 'hooks/video_conference/useCallObject';
import styles from 'scss/video_conference/NewVideoConferencePage.module.scss';
import Room from 'components/video_conference/Room';
import LoadingPlaceholder from 'components/video_conference/LoadingPlaceholder';
import { TRAINEES_QUERY } from 'queries/video_conference';
import FeedbackIdProvider from 'components/video_conference/FeedbackContext';
import { NotificationsProvider } from 'hooks/useNotifications';
import { StatusBarsProvider } from 'hooks/video_conference/useStatusBars';
import { ModalProvider } from 'hooks/video_conference/useModal';
import { ParticipantsProvider } from 'contexts/video_conference/ParticipantsContext';
import { CallStateProvider } from 'hooks/video_conference/useCallState';
import { ErrorsProvider } from 'hooks/video_conference/useErrors';
import { useThemeColorSetter } from 'components/mainComponents/CustomColorChooser/ThemeContext';
import { ACCOUNT_INFO_QUERY } from 'components/mainComponents/HeaderBar/AccountInfo';
import { useAuth } from 'contexts/AuthContext';

function RoomRenderer({ onError }) {
  const { id, training_session_id: trainingSessionId } = useParams();
  const roomId = `${window.config.env}-module-${id}`;

  const { auth } = useAuth();
  const setCustomColor = useThemeColorSetter();

  // Get data of all trainees
  const { data: traineesAndInstructorsData } = useQuery(TRAINEES_QUERY, {
    variables: {
      id: trainingSessionId,
    },
  });

  const { data: accountInfosData } = useQuery(ACCOUNT_INFO_QUERY, {
    variables: { userCode: auth?.userCode },
  });

  useEffect(() => {
    if (accountInfosData?.accountInfos?.extranetCustomColor) {
      setCustomColor(accountInfosData.accountInfos.extranetCustomColor);
    }
  }, [accountInfosData, setCustomColor]);

  if (traineesAndInstructorsData && accountInfosData) {
    const {
      trainingSession: { trainees, instructors, academy },
    } = traineesAndInstructorsData;
    const usersData = trainees.concat(instructors, academy);

    return (
      <div className={styles.NewVideoConferencePage}>
        <CallObjectProvider>
          <NotificationsProvider>
            <StatusBarsProvider>
              <ModalProvider>
                <FeedbackIdProvider>
                  <ParticipantsProvider>
                    <CallStateProvider>
                      <ErrorsProvider>
                        <Room
                          usersData={usersData}
                          roomId={roomId}
                          moduleId={id}
                          trainingSessionId={trainingSessionId}
                          onError={onError}
                          accountInfosData={accountInfosData}
                        />
                      </ErrorsProvider>
                    </CallStateProvider>
                  </ParticipantsProvider>
                </FeedbackIdProvider>
              </ModalProvider>
            </StatusBarsProvider>
          </NotificationsProvider>
        </CallObjectProvider>
      </div>
    );
  }

  return <LoadingPlaceholder />;
}

RoomRenderer.propTypes = {
  onError: PropTypes.func.isRequired,
};

export default RoomRenderer;
