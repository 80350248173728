import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';
import { useState } from 'react';

import './CommentThreadInitiator.scss';
import { CREATE_COMMENT } from './queries';
import { Input } from 'components/mainComponents/Input';
import { IconButton } from 'components/mainComponents/IconButton';
import { createComment as updateApolloCacheAfterCreateDiscussion } from 'controllers/comment_controller.js';
import { IDPropType } from 'propTypes';

export function CommentThreadInitiator(props) {
  const { t } = useTranslation();

  const apolloClient = useApolloClient();
  const [persistCreateDiscussion, { loading: persistCreateDiscussionLoading }] = useMutation(CREATE_COMMENT);

  const [newCommentText, setNewCommentText] = useState('');

  return (
    <form className='CommentThreadInitiator' onSubmit={createDiscussion}>
      <Input
        value={newCommentText}
        onChange={event => setNewCommentText(event.target.value)}
        placeholder={t('CommentThread.new_discussion_input_hint')}
      />
      <IconButton
        icon='paper-plane--alt'
        variant='primary'
        disabled={persistCreateDiscussionLoading || newCommentText.trim().length === 0}
      />
    </form>
  );

  async function createDiscussion(event) {
    event.preventDefault();

    const {
      data: { create_comment: createdComment },
    } = await persistCreateDiscussion({
      variables: {
        text: newCommentText,
        moduleId: props.moduleId,
        traineeId: props.traineeId,
        trainingSessionId: props.trainingSessionId,
      },
    });

    updateApolloCacheAfterCreateDiscussion(apolloClient, {
      ...createdComment,
      training_session_id: props.trainingSessionId,
      trainee_id: props.traineeId,
    });
    setNewCommentText('');
  }
}

CommentThreadInitiator.propTypes = {
  moduleId: IDPropType,
  traineeId: IDPropType,
  trainingSessionId: IDPropType,
};
