import IconButton from 'components/video_conference/IconButton';
import styles from 'scss/video_conference/GridPagination.module.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function GridPagination({ isPaginationVisible, isPrevDisabled, isNextDisabled, handlePrevClick, handleNextClick }) {
  const { t } = useTranslation();
  return (
    isPaginationVisible && (
      <div className={styles.pagination}>
        <IconButton
          icon='arrow--left'
          type='secondary'
          clickFn={!isPrevDisabled ? handlePrevClick : undefined}
          ariaLabel={t('GridPagination.previous_page')}
        />
        <IconButton
          icon='arrow--right'
          type='secondary'
          clickFn={!isNextDisabled ? handleNextClick : undefined}
          ariaLabel={t('GridPagination.next_page')}
        />
      </div>
    )
  );
}

export default GridPagination;

GridPagination.propTypes = {
  isPaginationVisible: PropTypes.bool,
  isPrevDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  handlePrevClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired,
};

GridPagination.defaultProps = {
  isPaginationVisible: false,
  isPrevDisabled: true,
  isNextDisabled: true,
};
