import { gql, useQuery } from '@apollo/client';
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Icon } from 'components/mainComponents/Icon';
import { InstructorTrainingSessionSelect, TraineeTrainingSessionSelect } from 'components/mainComponents/TrainingSessionSelect';

import './SidebarNavigationContent.scss';
import { useAuth } from 'contexts/AuthContext';
import { HorizontalRule } from 'components/mainComponents/HorizontalRule';

export function SidebarNavigationContent({ trainingSessionId, className: additionalClassName }) {
  const { auth } = useAuth();
  const menuItems = useMenuItems(trainingSessionId, auth);
  const className = clsx('SidebarNavigationContent', additionalClassName);

  return (
    <nav className={className}>
      <ul className='SidebarNavigationContent__navigation-container'>
        {menuItems.map((menuItem, idx) => {
          if (menuItem.type === MENU_ITEM_TYPE.LINK) {
            return (
              <li className='SidebarNavigationContent__navigation' key={menuItem.href}>
                <NavLink
                  className={({ isActive }) => menuItem.customClassname?.(menuItem) ?? getNavLinkClassName(isActive)}
                  end={menuItem.exact}
                  to={menuItem.href}
                >
                  <Icon className='SidebarNavigationContent__navigation-link-icon' icon={menuItem.icon} />
                  <span>{menuItem.name}</span>
                </NavLink>
              </li>
            );
          } else {
            return <HorizontalRule tight key={idx} />;
          }
        })}
      </ul>

      {auth.guestInfo.type === 'instructor' && <InstructorTrainingSessionSelect currentSessionId={trainingSessionId} />}
      {auth.guestInfo.type === 'trainee' && <TraineeTrainingSessionSelect currentSessionId={trainingSessionId} />}
    </nav>
  );
}

function getNavLinkClassName(isActive) {
  return clsx('SidebarNavigationContent__navigation-link', { 'SidebarNavigationContent__navigation-link--active': isActive });
}

function getAttendanceNavLinkClassname(menuItem) {
  /* God, why?? 😩
   * The URLs for individual attendance and attendance tracking / class signing are
   * all mixed up but we can't risk changing them because of the links in the emails.
   *
   * Problem: because of this, the navigation menu doesn't show the correct active
   * item regarding attendance ("Attendance" vs. "Attendance tracking".)
   *
   * Solution: this ugly function that manually sets the active class based on the
   * current pathname and the route URLs defined in the App component.
   */

  const classes = ['SidebarNavigationContent__navigation-link'];

  const currentUrl = window.location.pathname;

  if (menuItem.isAttendanceTracking) {
    if (currentUrl.endsWith('/attendance') || currentUrl.endsWith('attendance/sign/class')) {
      classes.push('SidebarNavigationContent__navigation-link--active');
    }
  } else if (menuItem.isIndividualAttendance) {
    if (currentUrl.endsWith('/attendances') || currentUrl.endsWith('/attendance/sign/single')) {
      classes.push('SidebarNavigationContent__navigation-link--active');
    }
  }

  return classes.join(' ');
}

function useMenuItems(trainingSessionId, auth) {
  const { t } = useTranslation();
  const accessType = auth.guestInfo.type;

  const trainingSessionVisibilityQuery = useQuery(TRAINING_SESSION_VISIBILITY_OPTIONS, {
    variables: {
      id: trainingSessionId,
    },
  });
  if (!trainingSessionVisibilityQuery.data) return [];

  const { showTraineePedagogicalTrackingInExtranet, showTraineesInExtranet, showProgramInExtranet, showSigningButtonInExtranet } =
    trainingSessionVisibilityQuery.data.trainingSession;

  const menuItems = [
    {
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}`,
      name: t('SidebarNavigationLink.home'),
      icon: 'home',
      exact: true,
      order: 0,
    },
    {
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}/modules`,
      name: t('SidebarNavigationLink.elearning'),
      icon: 'steps--vertical',
      order: 1,
    },
    {
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}/evaluations`,
      name: t('SidebarNavigationLink.assessment'),
      icon: 'star',
      order: 6,
    },
    {
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}/documents`,
      name: t('SidebarNavigationLink.doc'),
      icon: 'folder',
      order: 7,
    },
    {
      type: MENU_ITEM_TYPE.LINK,
      href: `/about`,
      name: t('SidebarNavigationLink.about'),
      icon: 'info--circle',
      order: 8,
    },
  ];

  if (showTraineePedagogicalTrackingInExtranet) {
    menuItems.push({
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}/tracking`,
      name: t('SidebarNavigationLink.tracking'),
      icon: 'users',
      order: 2,
    });
  }

  if (showProgramInExtranet) {
    menuItems.push({
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}/program`,
      name: t('SidebarNavigationLink.program'),
      icon: 'notepad',
      order: 3,
    });
  }

  if (showTraineesInExtranet) {
    menuItems.push({
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}/trainees`,
      name: t('SidebarNavigationLink.students'),
      icon: 'student',
      order: 4,
    });
  }

  if (showSigningButtonInExtranet) {
    menuItems.push({
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}/attendances`,
      name: t('SidebarNavigationLink.sign'),
      icon: 'sign',
      isIndividualAttendance: true,
      customClassname: getAttendanceNavLinkClassname,
      order: 5,
    });
  }

  if (accessType === 'instructor' || accessType === 'user') {
    menuItems.push({ type: MENU_ITEM_TYPE.SEPARATOR });
    menuItems.push({
      type: MENU_ITEM_TYPE.LINK,
      href: `/ts/${trainingSessionId}/attendance`,
      name: t('SidebarNavigationLink.attendancesTracking'),
      icon: 'list--boxes',
      isAttendanceTracking: true,
      customClassname: getAttendanceNavLinkClassname,
      order: 9,
    });
  }

  if (accessType === 'user') {
    menuItems.push({
      type: MENU_ITEM_TYPE.LINK,
      href: '/admin_page',
      name: t('SidebarNavigationLink.color_configuration'),
      icon: 'sliders--horizontal',
      order: 10,
    });
  }

  menuItems.sort((a, b) => {
    return a.order - b.order;
  });

  return menuItems;
}

const MENU_ITEM_TYPE = {
  LINK: 'link',
  SEPARATOR: 'separator',
};

SidebarNavigationContent.propTypes = {
  trainingSessionId: PropTypes.string.isRequired,
};

const TRAINING_SESSION_VISIBILITY_OPTIONS = gql`
  query trainingSessionVisibilityOptions($id: ID!) {
    trainingSession(id: $id) {
      id
      showProgramInExtranet
      showRulesInExtranet
      showSigningButtonInExtranet
      showTraineePedagogicalTrackingInExtranet
      showTraineesInExtranet
    }
  }
`;
