import React from 'react';

import IconButton from './IconButton';

const EntryButton = props => {
  const { children, ...rest } = props;
  return (
    <IconButton {...rest}>
      <div className='menu__button__content'>{children}</div>
    </IconButton>
  );
};

export default EntryButton;
