import { useDaily } from '@daily-co/daily-react';
import { useEffect, useReducer } from 'react';
import { ADD_STALE_PARTICIPANT_ID } from '../../../actions/video_conference';
import { MESSAGE_RECONNECTED } from '../../../constants/video_conference';
import { listenMessage } from '../../../helpers/video_conference/messaging';
import { reduceStaleParticipants } from '../../../reducers/video_conference';

function useStaleParticipantDetection() {
  const callObject = useDaily();

  const [state, dispatch] = useReducer(reduceStaleParticipants, {
    staleParticipantIds: [],
  });

  const { staleParticipantIds } = state;

  useEffect(() => {
    listenMessage(callObject, MESSAGE_RECONNECTED, ({ fromId, payload }) => {
      const { oldSessionId, newSessionId } = payload;

      if (newSessionId !== oldSessionId) {
        dispatch({ action: ADD_STALE_PARTICIPANT_ID, payload: { oldSessionId } });
      }
    });
  }, [callObject]);

  return staleParticipantIds;
}

export default useStaleParticipantDetection;
