import variables from 'scss/video_conference/_export.scss';
import useThrottledResizeObserver from 'hooks/video_conference/useThrottledResizeObserver';
import { useCallback, useMemo } from 'react';
import { ASPECT_RATIO_LIMITS } from 'constants/video_conference';

const MIN_SMALL_TILE_HEIGHT_IN_PX = 150;
const MIN_SMALL_TILE_WIDTH_IN_PX = 200;
const SMALL_TILE_MARGIN_IN_PX = parseInt(variables['tile-margin'], 10);

function useFeaturedSpeakerMode() {
  const { ref: containerRef, height: tilesContainerHeight, width: tilesContainerWidth } = useThrottledResizeObserver();

  const [isVertical] = useMemo(() => {
    const aspectRatio = tilesContainerWidth / (tilesContainerHeight || 0);
    return [aspectRatio >= ASPECT_RATIO_LIMITS.MAX];
  }, [tilesContainerWidth, tilesContainerHeight]);

  const calculateLimit = useCallback(
    (containerDimension, tileDimension) =>
      containerDimension ? Math.floor(containerDimension / (tileDimension + SMALL_TILE_MARGIN_IN_PX)) : 0,
    []
  );

  const smallTilesLimit = useMemo(
    () =>
      isVertical
        ? calculateLimit(tilesContainerHeight, MIN_SMALL_TILE_HEIGHT_IN_PX)
        : calculateLimit(tilesContainerWidth, MIN_SMALL_TILE_WIDTH_IN_PX),
    [calculateLimit, isVertical, tilesContainerHeight, tilesContainerWidth]
  );

  return {
    containerRef,
    smallTilesLimit,
  };
}

export default useFeaturedSpeakerMode;
