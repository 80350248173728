import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { normalizeParticipant } from 'components/video_conference/Sidebar/utils';
import { useStaleParticipantDetection } from 'hooks/video_conference';
import { useDailyEvent } from '@daily-co/daily-react';
import { JOINED_MEETING, PARTICIPANT_JOINED, PARTICIPANT_LEFT, PARTICIPANT_UPDATED } from 'constants/video_conference';

// Any update of these particular keys of a given participant
// should result in it being updated in the context
const relevantKeys = ['video', 'audio', 'screen', 'videoTrack', 'audioTrack', 'screenVideoTrack'];

function filterParticipants(callObjectParticipants, staleIds) {
  return _.pickBy(callObjectParticipants, participant => participant.local || staleIds.indexOf(participant.session_id) === -1);
}

function useParticipantsState() {
  const staleParticipantIds = useStaleParticipantDetection();

  const [dailyParticipants, setDailyParticipants] = useState({});

  const activeParticipants = useMemo(() => {
    return filterParticipants(dailyParticipants, staleParticipantIds);
  }, [staleParticipantIds, dailyParticipants]);

  const storeRemoteParticipant = useCallback(
    event => {
      setDailyParticipants(oldDailyParticipants => {
        const { participant: updatedParticipant } = event;
        const oldParticipant = oldDailyParticipants[updatedParticipant.session_id];

        const oldRelevantKeys = _.pickBy(oldParticipant, (_value, key) => relevantKeys.includes(key));

        const newRelevantKeys = _.pickBy(updatedParticipant, (_value, key) => relevantKeys.includes(key));

        if (_.isEqual(oldRelevantKeys, newRelevantKeys)) {
          return oldDailyParticipants;
        }

        return {
          ...oldDailyParticipants,
          [updatedParticipant.session_id]: updatedParticipant,
        };
      });
    },
    [setDailyParticipants]
  );

  const unregisterParticipant = useCallback(
    event => {
      const { participant } = event;
      setDailyParticipants(oldDailyParticipants => {
        const newDailyParticipants = { ...oldDailyParticipants };
        delete newDailyParticipants[participant.session_id];
        return newDailyParticipants;
      });
    },
    [setDailyParticipants]
  );

  const storeLocalParticipant = useCallback(
    event => {
      const { participants } = event;

      const newParticipants = {
        ...participants,
        [participants.local.session_id]: participants.local,
      };
      delete newParticipants.local;
      setDailyParticipants(newParticipants);
    },
    [setDailyParticipants]
  );

  useDailyEvent(PARTICIPANT_LEFT, unregisterParticipant);
  useDailyEvent(JOINED_MEETING, storeLocalParticipant);
  useDailyEvent(PARTICIPANT_UPDATED, storeRemoteParticipant);
  useDailyEvent(PARTICIPANT_JOINED, storeRemoteParticipant);

  const normalizedParticipants = useMemo(() => {
    const items = Object.values(activeParticipants).map(participant => normalizeParticipant(participant));

    return items;
  }, [activeParticipants]);

  return normalizedParticipants;
}

export const ParticipantsContext = createContext();

export const ParticipantsProvider = ({ children }) => {
  const participants = useParticipantsState();

  return <ParticipantsContext.Provider value={participants}>{children}</ParticipantsContext.Provider>;
};

ParticipantsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useParticipants() {
  const participants = useContext(ParticipantsContext);

  if (participants === undefined) {
    throw new Error('useParticipants must be used within a ParticipantsProvider');
  }

  return participants;
}

export default useParticipants;
