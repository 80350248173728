import React from 'react';
import { Heading } from 'components/mainComponents/Heading';
import { IconedList } from 'components/mainComponents/IconedList';

import './ProgramContentWidget.scss';

export function ProgramContentWidget({ title, elements }) {
  return (
    <div className='ProgramContentWidget'>
      <Heading level={4} as='h3' className='ProgramContentWidget__title'>
        {title}
      </Heading>
      <IconedList icon='checkmark' elements={elements} />
    </div>
  );
}
