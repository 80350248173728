import React, { useContext, useState, useCallback } from 'react';

const SignatureListContext = React.createContext();

export const SignatureListProvider = ({ children }) => {
  const [signedParticipants, setSignedParticipants] = useState([]);

  const setSignedParticipant = useCallback(
    guestId => {
      setSignedParticipants(prevState =>
        prevState.find(participant => participant === guestId) ? prevState : [...prevState, guestId]
      );
    },
    [setSignedParticipants]
  );

  return <SignatureListContext.Provider value={[signedParticipants, setSignedParticipant]}>{children}</SignatureListContext.Provider>;
};

const useSignatureList = () => useContext(SignatureListContext);

export default useSignatureList;
