import _ from 'lodash';

export const getLocalParticipant = callObject => _.find(callObject.participants(), participant => participant.local);

export const isInstructor = participant => /^instructor-.+$/.test(participant.user_id);

export const getUser = (usersData, userTypeAndId) =>
  usersData.find(user => {
    let userType = user && `${_.snakeCase(user.__typename)}-${user.id}`;
    return userType === userTypeAndId;
  });

export const getUserImageUrl = (usersData, userTypeAndId) => {
  const searchedUser = getUser(usersData, userTypeAndId);
  const avatarPropertyName = searchedUser?.logo ? 'logo' : 'academy_extranet_avatar';
  return searchedUser && searchedUser[avatarPropertyName] && searchedUser[avatarPropertyName].url
    ? searchedUser[avatarPropertyName].url
    : null;
};
