import React from 'react';

import './ActivityScore.scss';

export function ActivityScore({ correct, score, total, percent }) {
  const renderPercentage = () => {
    if (percent === undefined) return null;
    return (
      <>
        <br />
        <span className='ActivityScore--small'>({percent} %)</span>
      </>
    );
  };

  return (
    <div className={`ActivityScore ActivityScore--${correct ? 'correct' : 'wrong'}`}>
      <div>
        Score
        <br />
        {score} /{total}
        {renderPercentage()}
      </div>
    </div>
  );
}
