export const STATE_INITIAL = 'STATE_INITIAL';
export const STATE_TESTING_MIC = 'STATE_TESTING_MIC';
export const STATE_TESTING_CAM = 'STATE_TESTING_CAM';
export const STATE_CHANGING_MIC = 'STATE_CHANGING_MIC';
export const STATE_CHANGING_CAM = 'STATE_CHANGING_CAM';
export const STATE_CHECKLIST = 'STATE_CHECKLIST';
export const STATE_ERROR = 'STATE_ERROR';
export const STATE_MIC_MISSING = 'STATE_MIC_MISSING';
export const STATE_FINISHED = 'STATE_FINISHED';

export class NoRTCError extends Error {}

export const PACKET_LOSS_THRESHOLD = 0.2;
export const BITRATE_THRESHOLD = 512000;
export const STABILITY_THRESHOLD = 0.75;
export const WINDOW_LENGTH = 5;
export const PACKET_LOSS_MS_WINDOW = {
  MEASURING_IN_CALL: 10000,
  MEASURING_PRE_CALL: 5000,
};
export const MIN_SAMPLES = 50;

/* Resolutions automatically applied for specific kbs:
   Peer-to-peer mode: 980kbs (1290x720), 300kbs (960x540)
   SFU: 980kbs (1290x720), 300kbs (640x360)
*/

export const LOW_QUALITY_KBS = 300;
export const HIGH_QUALITY_KBS = 980;

export const DEFAULT_QUALITY_KBS = {
  TRAINEE: HIGH_QUALITY_KBS,
  INSTRUCTOR: HIGH_QUALITY_KBS,
  USER: HIGH_QUALITY_KBS,
};
