import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ActionButton } from 'components/mainComponents/buttons';

function PreCallResultButtons({ onAcceptClick, onHelpClick, acceptLabel, helpLabel, classes, disabled }) {
  const { t } = useTranslation();

  return (
    <div className={classes}>
      <ActionButton
        icon='info--circle'
        label={helpLabel || t('PreCallResultButtons.button.help')}
        clickFn={!disabled ? onHelpClick : undefined}
        type='secondary'
      />
      <ActionButton
        icon='arrow--right'
        label={acceptLabel || t('PreCallResultButtons.button.accept')}
        clickFn={!disabled ? onAcceptClick : undefined}
      />
    </div>
  );
}

export default PreCallResultButtons;

PreCallResultButtons.propTypes = {
  onAcceptClick: PropTypes.func.isRequired,
  onHelpClick: PropTypes.func.isRequired,
  acceptLabel: PropTypes.string,
  helpLabel: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
};

PreCallResultButtons.defaultProps = {
  acceptLabel: null,
  helpLabel: null,
  classes: '',
  disabled: false,
};
