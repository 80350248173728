import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextArea } from 'components/mainComponents/TextArea';
import { EvaluationQuestion, EvaluationQuestionLabel } from 'components/v2/evaluations/form_inputs/EvaluationQuestion';

export function FormInputFreeText(props) {
  const { t } = useTranslation();
  const [answer, setAnswer] = useState(props.answerSent || '');
  const data = JSON.parse(props.input.data);

  useEffect(() => {
    setAnswer(props.answerSent || '');
  }, [props.answerSent]);

  return (
    <EvaluationQuestion>
      <EvaluationQuestionLabel>{data.question}</EvaluationQuestionLabel>
      <TextArea
        autoSize
        value={answer}
        onChange={onAnswer}
        minRows={3}
        disabled={props.correcting}
        placeholder={t('AnswerEvaluation.Form.answer')}
      />
    </EvaluationQuestion>
  );

  function onAnswer(event) {
    setAnswer(event.target.value);
    props.onAnswer(props.input.id, event.target.value);
  }
}
