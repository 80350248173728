export default function chunk(array, chunkSize, leap, takeLastIfShorter = true) {
  if (chunkSize <= 0 || leap <= 0) {
    return []; // Maybe should we raise an exception ...
  }
  const res = [];
  for (let pos = 0; pos < array.length; pos += leap) {
    const chunk = array.slice(pos, pos + chunkSize);
    if (!takeLastIfShorter && chunk.length < chunkSize) {
      continue;
    }
    res.push(chunk);
  }
  return res;
}
