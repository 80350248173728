import { ADD_NOTIFICATIONS } from 'actions/video_conference';
import { useNotificationsDispatch } from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

export const useRecordingStoppedNotification = (isRecording, recordingId) => {
  const dispatchNotifications = useNotificationsDispatch();
  const { t } = useTranslation();

  const recordingStoppedNotification = useCallback(() => {
    dispatchNotifications({
      action: ADD_NOTIFICATIONS,
      payload: {
        newNotifications: [
          {
            id: 'recording-stopped',
            title: t('Notification.recording_complete.title'),
            text: t('Notification.recording_complete.text'),
            type: 'recording-stopped-information',
          },
        ],
      },
    });
  }, [dispatchNotifications, t]);

  useEffect(() => {
    if (!isRecording && recordingId) {
      recordingStoppedNotification();
    }
  }, [recordingStoppedNotification, isRecording, recordingId]);
};
