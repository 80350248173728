import React, { Component } from 'react';
import Tile, { NavigationLinkTile } from 'components/mainComponents/Tile';
import { evaluationLogo } from 'support/logos';

class EvaluationLink extends Component {
  render() {
    const { evaluation, trainee_id, trainee_name, playable } = this.props;
    const name = evaluation.name + renderTraineeName(evaluation, trainee_name);

    if (playable) {
      return (
        <NavigationLinkTile to={`/answer_evaluation/${evaluation.id}/${trainee_id}`} icon={evaluationLogo(evaluation)} title={name} />
      );
    }

    return <Tile icon={evaluationLogo(evaluation)} title={name} />;
  }
}

function renderTraineeName(evaluation, name) {
  if (evaluation.type !== 'company' || name === '' || name === undefined || name === null) {
    return '';
  }
  return ` - ${name}`;
}

export default EvaluationLink;
