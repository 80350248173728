import PropTypes from 'prop-types';

import { iconNames } from './iconNames';
import iconsSprite from './videoConfIcons.svg';

export function VideoConfSvgIcon(props) {
  return (
    <svg className={props.className} {...getSizeProps(props.name)}>
      <title>{props.title}</title>
      <use href={`${iconsSprite}#${props.name}`} />
    </svg>
  );
}

VideoConfSvgIcon.propType = {
  name: PropTypes.oneOf(iconNames).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

/*
 * All icons are not the same size, but symbols
 * can't set the outer <svg />'s width and height.
 * Let's have those sizes declared here until we find a better way.
 */
function getSizeProps(name) {
  switch (name) {
    case 'arrowBack':
    case 'arrowNext':
      return {
        width: 14,
        height: 16,
      };
    case 'camOn':
    case 'camOff':
    case 'micOn':
    case 'micOff':
    case 'screenOn':
    case 'screenOff':
    case 'sendMsg':
    case 'threeDots':
      return {
        width: 50,
        height: 50,
      };
    case 'network':
      return {
        width: 24,
        height: 26,
      };
    case 'background':
    default:
      return {
        width: 24,
        height: 24,
      };
  }
}
