export const iconNames = [
  'arrowBack',
  'arrowNext',
  'background',
  'camOff',
  'camOn',
  'chat',
  'close',
  'enterFullscreen',
  'micOff',
  'micOn',
  'network',
  'problemIndicator',
  'screenOff',
  'screenOn',
  'sendMsg',
  'star',
  'startRecording',
  'stopRecording',
  'threeDots',
];
