import React, { useReducer, useEffect, useCallback, useMemo, useContext, useRef } from 'react';
import { broadcastMessage } from 'helpers/video_conference/messaging';
import { UPDATE_STATUS, NETWORK_MODE_CHANGE, RECORDING_STARTED, RECORDING_STOPPED, CAMERA_STARTED } from 'actions/video_conference';
import {
  CPU_LOAD_LOW,
  JOINED_MEETING,
  MESSAGE_RECONNECTED,
  NETWORK_CONNECTION_EVENT,
  NETWORK_TOPOLOGY_P2P,
  NETWORK_TOPOLOGY_SFU,
  PARTICIPANT_UPDATED,
  RECORDING_ERROR_EVENT,
  RECORDING_STARTED_EVENT,
  RECORDING_STOPPED_EVENT,
  STARTED_CAMERA,
} from 'constants/video_conference';
import { getStreamStates } from 'helpers/video_conference/callStatus';
import { reduceCallStatus } from 'reducers/video_conference';
import { RoomOptsContext } from 'contexts/video_conference';
import useParticipants from 'contexts/video_conference/ParticipantsContext';
import { useDaily, useDailyEvent } from '@daily-co/daily-react';

export const CallStatusStateContext = React.createContext();
export const CallStatusDispatchContext = React.createContext();

// eslint-disable-next-line import/prefer-default-export
function useCallStatus(callObject, { moduleId, trainingSessionId, initialQuality }) {
  const normalizedParticipants = useParticipants();

  const { isSharingScreen } = getStreamStates(callObject);

  const reducer = useMemo(() => reduceCallStatus(callObject, normalizedParticipants), [callObject, normalizedParticipants]);

  const videoRef = useRef(null);

  const [callStatus, dispatchCallStatus] = useReducer(reducer, {
    moduleId,
    trainingSessionId,
    screenSharingParticipants: [],
    isSharingScreen,
    isMicBtnDisabled: false,
    isRecording: false,
    autoCamOffIgnored: false,
    connectionType: null,
    quality: initialQuality,
    cpuLoad: CPU_LOAD_LOW,
    participantQuality: {},
    offlineReason: null,
    comments: [],
    activityTimestamps: {},
    isCameraStarted: false,
    shouldTurnOffMic: false,
    videoRef,
    initialQuality,
  });

  const handleParticipantUpdated = useCallback(() => dispatchCallStatus({ action: UPDATE_STATUS }), []);

  const handleRecordingStarted = useCallback(
    ({ recordingId }) =>
      dispatchCallStatus({
        action: RECORDING_STARTED,
        payload: { recordingId },
      }),
    []
  );

  const handleRecordingStopped = useCallback(() => dispatchCallStatus({ action: RECORDING_STOPPED }), []);

  const handleRecordingError = useCallback(() => dispatchCallStatus({ action: RECORDING_STOPPED }), []);

  const handleNetworkConnection = useCallback(
    connection =>
      dispatchCallStatus({
        action: NETWORK_MODE_CHANGE,
        payload: connection,
      }),
    []
  );

  const handleCameraStarted = useCallback(() => dispatchCallStatus({ action: CAMERA_STARTED }), []);

  useDailyEvent(PARTICIPANT_UPDATED, handleParticipantUpdated);
  useDailyEvent(RECORDING_STARTED_EVENT, handleRecordingStarted);
  useDailyEvent(RECORDING_STOPPED_EVENT, handleRecordingStopped);
  useDailyEvent(RECORDING_ERROR_EVENT, handleRecordingError);
  useDailyEvent(NETWORK_CONNECTION_EVENT, handleNetworkConnection);
  useDailyEvent(STARTED_CAMERA, handleCameraStarted);

  const { offlineReason } = callStatus;

  const rejoin = useCallback(
    async oldSessionId => {
      await callObject.join();

      const newSessionId = callObject.participants().local.session_id;

      setTimeout(() => {
        broadcastMessage(callObject, {
          messageType: MESSAGE_RECONNECTED,
          payload: { oldSessionId, newSessionId },
        });
      }, 10000);
    },
    [callObject]
  );

  useEffect(() => {
    switch (offlineReason) {
      case 'signaling': {
        const heartbeatInterval = setInterval(() => {
          fetch('/speedtest/heartbeat').then(async () => {
            clearInterval(heartbeatInterval);

            const oldSessionId = callObject.participants().local.session_id;

            rejoin(oldSessionId);

            // In case an ERR_NETWORK_CHANGED occurs during the initial rejoin operation,
            // we were unable to catch the error because Daily.co's API seemingly doesn't
            // allow to do so. For this reason, wait 10 seconds, see if the meeting has
            // indeed been joined - if not, try again
            setTimeout(() => {
              if (callObject.meetingState() !== JOINED_MEETING) {
                rejoin(oldSessionId);
              }
            }, 10000);
          });
        }, 5000);
        break;
      }

      case NETWORK_TOPOLOGY_P2P:
      case NETWORK_TOPOLOGY_SFU:
      default:
        break;
    }
  }, [offlineReason, callObject, rejoin]);

  return [callStatus, dispatchCallStatus];
}

export function CallStatusProvider({ children }) {
  const { moduleId, trainingSessionId, initialQuality } = useContext(RoomOptsContext);

  const callObject = useDaily();

  const [callStatus, dispatch] = useCallStatus(callObject, {
    moduleId,
    trainingSessionId,
    initialQuality,
  });

  return (
    <CallStatusStateContext.Provider value={callStatus}>
      <CallStatusDispatchContext.Provider value={dispatch}>{children}</CallStatusDispatchContext.Provider>
    </CallStatusStateContext.Provider>
  );
}

export function useCallStatusDispatch() {
  return useContext(CallStatusDispatchContext);
}

export function useCallStatusState() {
  return useContext(CallStatusStateContext);
}
