import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Button.scss';

export const Button = forwardRef(({ as, size, children, className, href, fullWidth, variant, ...otherProps }, ref) => {
  const Component = as === 'a' ? Link : 'button';
  const buttonClassName = clsx(
    'Button',
    `Button--size-${size}`,
    `Button--variant-${variant}`,
    { 'Button--full-width': fullWidth },
    className
  );

  return (
    <Component to={href} className={buttonClassName} {...otherProps} ref={ref}>
      {children}
    </Component>
  );
});
Button.displayName = 'Button';

Button.propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
  as: PropTypes.oneOf(['a', 'button']),
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['solid', 'text']),
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  size: 'md',
  as: 'button',
  variant: 'solid',
  fullWidth: false,
};
