import { gql } from '@apollo/client';
import { editableComponentsQuery } from 'support/editableComponentsUtils';

export const trainingSessionQuery = gql`
  query training_session_page($id: ID!) {
    training_session(id: $id) {
      id
      name
      customName
      editableComponents ${editableComponentsQuery()}
      image {
        url
      }
    }
  }
`;
