import Icon from 'components/mainComponents/Icon';
import React, { useRef, useState } from 'react';
import styles from '../../../scss/video_conference/Dropdown.module.scss';
import PropTypes from 'prop-types';
import useClickOutsideComponent from 'hooks/video_conference/useClickOutsideComponent';

function Dropdown({ options, value, onChange, name, disabled, label, className }) {
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);

  const containerRef = useRef(null);

  const iconClasses = `${styles.button__icon} ${areOptionsOpen ? styles['button__icon--open'] : ''}`;
  const optionsClasses = `${styles.options} ${areOptionsOpen ? styles.show : ''}`;
  const dropdownClasses = `${styles.button} ${areOptionsOpen ? styles.expanded : ''}`;

  const handleClickOutside = () => {
    if (areOptionsOpen) setAreOptionsOpen(false);
  };

  useClickOutsideComponent(containerRef, handleClickOutside);

  const toggleOptions = () => setAreOptionsOpen(!areOptionsOpen);

  const handleKeyDown = index => event => {
    if ([' ', 'SpaceBar', 'Enter'].includes(event.key)) {
      handleSelect(event, index);
    }
  };

  const handleSelect = (event, targetId = event.target.id) => {
    event.preventDefault();
    onChange(targetId);
    setAreOptionsOpen(false);
  };

  return (
    <label className={`${styles.formGroup} ${className}`}>
      {label}
      <div className={styles.wrapper}>
        <div className={styles.container} ref={containerRef}>
          <button
            type='button'
            aria-haspopup='listbox'
            aria-expanded={areOptionsOpen}
            className={dropdownClasses}
            onClick={toggleOptions}
            disabled={disabled}
          >
            {options.find(option => option.value === value)?.label}
            <Icon icon='chevron--down' className={iconClasses} />
          </button>
          <ul className={optionsClasses} role='listbox' tabIndex={-1} aria-labelledby={name}>
            {options.map(option => (
              <li
                key={option.value}
                id={option.value}
                className={styles.option}
                role='option'
                aria-selected={value === option.value}
                tabIndex={0}
                onKeyDown={handleKeyDown(option.value)}
                onClick={handleSelect}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </label>
  );
}

export default Dropdown;

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  disabled: false,
  value: 'default',
  label: '',
  className: '',
};
