import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { documentLogo } from 'support/logos';
import { FileLinkTile } from '../mainComponents/Tile';
import { useAuth } from 'contexts/AuthContext';

const DocumentLink = ({ document }) => {
  const { auth } = useAuth();
  const [mutateNotifyDocumentDownloaded] = useMutation(NOTIFY_DOCUMENT_DOWNLOADED);

  const onDocumentDownloadClicked = () => {
    if (auth?.guestInfo?.type === 'trainee') notifyDocumentDownloaded();
  };

  const notifyDocumentDownloaded = () => {
    mutateNotifyDocumentDownloaded({
      variables: {
        id: document.id,
      },
    }).catch(() => {
      console.error(`Failed to notify document ${document.id} has been downloaded`);
    });
  };

  return (
    <FileLinkTile icon={documentLogo(document)} title={document.filename} to={document.url} onClickFn={onDocumentDownloadClicked} />
  );
};

const NOTIFY_DOCUMENT_DOWNLOADED = gql`
  mutation notify_document_downloaded($id: ID!) {
    notify_document_downloaded(id: $id)
  }
`;

export default DocumentLink;
