import React from 'react';
import styles from '../../scss/DatesModal.module.scss';
import PropTypes from 'prop-types';
import { UrlButton } from 'components/mainComponents/buttons';
import { useTranslation } from 'react-i18next';
import { GOOGLE_EXPORT } from 'actions/datesActions';

export const DatesModal = ({ handleCloseModal, setChosenCalendar, googleLink, webcalLink, chosenCalendar }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <div className={styles.modalHeaderText}>{t('Dates.export_my_calendar')}</div>
          <div className={styles.modalHeaderCloseButton}>
            <button onClick={() => handleCloseModal(false)} className={styles.closeIcon}>
              &times;
            </button>
          </div>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.modalExportTypeMessage}>{t('Dates.export_type')}</div>
          <div className={styles.modalCalendarTypeSelector}>
            <select name='calendar-type' className={styles.calendarSelector} onChange={event => setChosenCalendar(event.target.value)}>
              <option value={googleLink}>{GOOGLE_EXPORT}</option>
              <option value={webcalLink}>{t('Dates.export_to_default_calendar')}</option>
            </select>
          </div>
          <div className={styles.modalFooter}>
            <UrlButton type='secondary' className={styles.modalExportButton} label={t('Dates.export')} url={chosenCalendar} blank />
          </div>
        </div>
      </div>
    </div>
  );
};

DatesModal.propTypes = {
  handleCloseModal: PropTypes.func,
  setChosenCalendar: PropTypes.func,
  googleLink: PropTypes.string,
  webcalLink: PropTypes.string,
  chosenCalendar: PropTypes.string,
};
