import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'contexts/AuthContext';
import Icon from './mainComponents/Icon';
import { useGuestMenuEntries } from 'support/guest';
import EntryLinkButton from './mainComponents/Menu/EntryLinkButton';

import '../scss/GuestInfo.scss';

const GuestNameAndType = ({ inRightMenu }) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { type, name } = auth.guestInfo;

  return (
    <div className={`guestInfo__inside-menu--${inRightMenu}`}>
      {inRightMenu && <Icon icon='user' />}
      <div>
        <p className='guestInfo__name'>{name}</p>
        <p className='guestInfo__access-type'>{t('Header.access', { type: `${t(type)}` })}</p>
      </div>
    </div>
  );
};

const RightSubMenu = ({ entries }) => {
  if (entries) {
    return (
      <div className='menu__panel--right'>
        <GuestNameAndType inRightMenu={true} />
        {entries.map((link, index) => {
          return <EntryLinkButton path={link.path} icon={link.icon} alt={link.alt} label={link.label} key={`submenu-${index}`} />;
        })}
      </div>
    );
  }
};

const GuestInfo = () => {
  const { auth } = useAuth();
  const node = useRef(null);
  const entries = useGuestMenuEntries();
  const isLoggedIn = auth.isLoggedIn;
  const [isRightSubMenuOpen, setIsRightSubmenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsRightSubmenuOpen(!isRightSubMenuOpen);
  };

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (node.current && isRightSubMenuOpen && !node.current.contains(e.target)) {
        setIsRightSubmenuOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isRightSubMenuOpen]);

  if (isLoggedIn) {
    return (
      <div className='guestInfo' onClick={() => toggleSubMenu()} ref={node}>
        <Icon icon='user' />
        <GuestNameAndType inRightMenu={false} />
        {isRightSubMenuOpen && <RightSubMenu entries={entries} />}
      </div>
    );
  } else return null;
};

export default GuestInfo;
