import {
  BITRATE_THRESHOLD,
  MIN_SAMPLES,
  PACKET_LOSS_MS_WINDOW,
  PACKET_LOSS_THRESHOLD,
  STABILITY_THRESHOLD,
  WINDOW_LENGTH,
} from 'constants/video_conference';
import _ from 'lodash';

function speedIsStable(samples) {
  if (samples.length < 3) {
    return false;
  }

  const [min, max] = [Math.min(...samples), Math.max(...samples)];

  return min && max && min >= STABILITY_THRESHOLD * max;
}

function reduceRtcStats(oldResults, { reset, dailyStats, measuringMode, camMarkedUnavailable, timedOut }) {
  if (reset) {
    const { _callObject } = oldResults;

    return (window.stats = {
      _rxBpsSamples: [],
      _txBpsSamples: [],
      _zeroSampleCount: 0,
      _callObject,
      speedMeasureSupported: undefined,
      rxPacketLossSamples: [],
      txPacketLossSamples: [],
      timestampArr: [],
      highPacketLoss: false,
      lowRtcBandwidth: false,
    });
  }

  const { latest, worstVideoRecvPacketLoss, worstVideoSendPacketLoss } = dailyStats;

  let {
    speedMeasureSupported,
    speedMeasured,
    _rxBpsSamples,
    _txBpsSamples,
    _zeroSampleCount,
    _callObject,
    _rxPacketLossSamples,
    _txPacketLossSamples,
    timestampArr,
    highPacketLoss,
    lowRtcBandwidth,
  } = oldResults;

  const camIsOff = camMarkedUnavailable || (_callObject && !_callObject.participants().local.video);
  let packetLossMeasured = false;

  if (Object.keys(latest).length === 0) {
    return oldResults;
  }

  const { videoSendBitsPerSecond, videoRecvBitsPerSecond, videoSendPacketLoss, videoRecvPacketLoss, timestamp } = latest;

  if (typeof videoRecvBitsPerSecond === 'number' && (_rxBpsSamples.length || videoRecvBitsPerSecond !== 0)) {
    _rxBpsSamples = _.uniq(_.takeRight([..._rxBpsSamples, videoRecvBitsPerSecond], WINDOW_LENGTH));
  }

  if (typeof videoSendBitsPerSecond === 'number' && (_txBpsSamples.length || videoSendBitsPerSecond !== 0)) {
    _txBpsSamples = _.uniq(_.takeRight([..._txBpsSamples, videoSendBitsPerSecond], WINDOW_LENGTH));
  }

  if (videoRecvBitsPerSecond === 0 || videoSendBitsPerSecond === 0) {
    _zeroSampleCount += 1;
  } else {
    _zeroSampleCount = 0;
  }

  const rxBpsAvg = _rxBpsSamples.reduce((a, b) => a + b, 0) / _rxBpsSamples.length;
  const txBpsAvg = _txBpsSamples.reduce((a, b) => a + b, 0) / _txBpsSamples.length;

  if (speedMeasureSupported === undefined) {
    if (_zeroSampleCount >= MIN_SAMPLES && _rxBpsSamples.length === 0) {
      speedMeasureSupported = false;
    } else if (_rxBpsSamples.length > 0 && _txBpsSamples.length > 0) {
      speedMeasureSupported = true;
    }
  }

  speedMeasured = camIsOff || speedMeasured || (speedIsStable(_rxBpsSamples) && speedIsStable(_txBpsSamples));

  _rxPacketLossSamples = _.isArray(_rxPacketLossSamples) ? [..._rxPacketLossSamples, videoRecvPacketLoss] : _rxPacketLossSamples;

  _txPacketLossSamples = _.isArray(_txPacketLossSamples) ? [..._txPacketLossSamples, videoSendPacketLoss] : _txPacketLossSamples;

  const avgRecvPacketLoss =
    _.isArray(_rxPacketLossSamples) && _rxPacketLossSamples.reduce((a, b) => a + b, 0) / _rxPacketLossSamples.length;
  const avgSendPacketLoss =
    _.isArray(_txPacketLossSamples) && _txPacketLossSamples.reduce((a, b) => a + b, 0) / _txPacketLossSamples.length;

  if (typeof timestamp === 'number') {
    timestampArr = [...timestampArr, timestamp];
  }

  if (timestampArr[timestampArr.length - 1] - timestampArr[0] > PACKET_LOSS_MS_WINDOW[measuringMode]) {
    packetLossMeasured = true;
    _rxPacketLossSamples = [];
    _txPacketLossSamples = [];
    timestampArr = [];
    highPacketLoss = avgRecvPacketLoss > PACKET_LOSS_THRESHOLD || avgSendPacketLoss > PACKET_LOSS_THRESHOLD;
  }

  if (speedMeasured) {
    lowRtcBandwidth = txBpsAvg < BITRATE_THRESHOLD;
  }

  const testingFinished = (speedMeasured && packetLossMeasured) || speedMeasureSupported === false || timedOut;

  const newResults = {
    testingFinished,
    speedMeasureSupported,
    speedMeasured,
    lowRtcBandwidth,
    highPacketLoss,
    _worstRxPacketLoss: worstVideoRecvPacketLoss,
    _worstTxPacketLoss: worstVideoSendPacketLoss,
    _rxBpsSamples,
    _txBpsSamples,
    _rxPacketLossSamples,
    _txPacketLossSamples,
    _zeroSampleCount,
    _callObject,
    rxBpsAvg,
    txBpsAvg,
    avgSendPacketLoss,
    avgRecvPacketLoss,
    timestampArr,
  };

  return newResults;
}

export default reduceRtcStats;
