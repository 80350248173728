import { PROBLEM_ICONS } from '.';

export const STATUS_BAR_TYPES = {
  PROBLEM: 'problem',
  SINGLE: 'single',
};

export const STATUS_BAR_ICONS = {
  ...PROBLEM_ICONS,
  ATTENDANCE_SIGNING: '✍️',
  ATTENDANCE_SIGNING_DONE: '✅',
  SIGNATURE_LIST: '📃',
};

export const SIGNALING = 'signaling';
export const RECONNECTING = 'reconnecting';
