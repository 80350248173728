import { SET_CALL_STATE } from 'actions/video_conference/callStateActions';
import { BEFORE_PRE_CALL_STATE } from 'constants/video_conference';
import React, { useCallback, useContext, useReducer } from 'react';

export const CallStateContext = React.createContext();
export const CallStateDispatchContext = React.createContext();

const reducer = (oldCallState, { action, payload }) => {
  switch (action) {
    case SET_CALL_STATE: {
      const { newCallState } = payload;

      return { lastState: oldCallState.currentState, currentState: newCallState };
    }
    default:
      return oldCallState;
  }
};

export default reducer;

function useCallState() {
  const [callState, dispatchCallState] = useReducer(reducer, { lastState: '', currentState: BEFORE_PRE_CALL_STATE });

  const setCallState = useCallback(newCallState => {
    dispatchCallState({
      action: SET_CALL_STATE,
      payload: {
        newCallState,
      },
    });
  }, []);

  return { callState, setCallState };
}

export function CallStateProvider({ children }) {
  const { callState, setCallState } = useCallState();

  return (
    <CallStateContext.Provider value={callState}>
      <CallStateDispatchContext.Provider value={setCallState}>{children}</CallStateDispatchContext.Provider>
    </CallStateContext.Provider>
  );
}

export function useCallStateDispatch() {
  return useContext(CallStateDispatchContext);
}

export function useCallStateState() {
  return useContext(CallStateContext);
}
