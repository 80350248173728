export const MODULE_VISIBILITY = {
  always: 'ALWAYS',
  date: 'DATE',
};

export function isVisible(module) {
  if (module.visibilityMode === MODULE_VISIBILITY.always) return true;

  if (module.visibilityMode === MODULE_VISIBILITY.date) {
    const today = Date.now();
    const start = new Date(module.visibilityStartDate).setHours(0, 0, 0, 0);
    const end = new Date(module.visibilityEndDate).setHours(23, 59, 59, 999);

    return start <= today && end >= today;
  }

  return false;
}

const SCORABLE_RESOURCE_TYPES = new Set(['evaluation', 'module_scorm', 'upload_marker']);

export function mustShowTotalScore(module) {
  return module.show_score && module.sequence.some(activity => SCORABLE_RESOURCE_TYPES.has(activity.resource_type));
}

export function totalScore(module) {
  const [acc, counter] = module.sequence
    ?.filter(
      activity =>
        ['evaluation', 'module_scorm'].includes(activity.resource_type) ||
        (activity.resource_type === 'upload_marker' && activity.finished) ||
        (activity.resource_type === 'html_doc' && activity.finished && activity.scorable)
    )
    ?.reduce(([score, activityCounter], activity) => [score + activity.score, activityCounter + 1], [0, 0]) ?? [0, 0];

  return counter > 0 ? Math.floor(acc / counter) : 0;
}

/**
 * Get the item associated to the given activity in the given module.
 *
 * @param {ModuleSequenceItemPropType} activity Activity whose item to return.
 * @param {ModulePropType} module Module containing the activity and all activity items.
 * @returns The item of the given activity.
 */
export function activityItem(activity, module) {
  switch (activity?.resource_type) {
    case 'evaluation':
      return module.pending_evaluations.find(x => x.evaluation.id === activity.resource_id.toString());
    case 'document':
      return module.documents.find(x => x.id === activity.resource_id.toString());
    case 'embed':
      return module.embeds.find(x => x.id === activity.resource_id.toString());
    case 'module_scorm':
      return module.scorms.find(x => x.id === activity.resource_id.toString());
    case 'livestream':
      return module.livestreams.find(x => x.id === activity.resource_id.toString());
    case 'html_doc':
      return module.html_docs.find(x => x.id === activity.resource_id.toString());
    case 'email_marker':
      return module.email_markers.find(x => x.id === activity.resource_id.toString());
    case 'upload_marker':
      return module.upload_markers.find(x => x.id === activity.resource_id.toString());
    default:
      return null;
  }
}
