import React from 'react';
import PropTypes from 'prop-types';
import AppPropTypes from 'propTypes';
import VideoBox from 'components/video_conference/VideoBoxes/VideoBox';
import VideoPlaceholder from './VideoPlaceholder';
import { getVideoClassName, useDisplayedVideoTrack } from './utils';
import BigTile from './BigTile';

const TileContent = ({ tile, placeholderImageUrl, styles }) => {
  const displayedVideoTrack = useDisplayedVideoTrack(tile);

  const videoClassName = getVideoClassName({ styles, tile, displayedVideoTrack });

  const { local, screenVideoTrack } = tile;
  const tileDisplaysScreen = displayedVideoTrack === screenVideoTrack;
  const mirror = local && !tileDisplaysScreen;

  return displayedVideoTrack ? (
    <VideoBox className={videoClassName} videoTrack={displayedVideoTrack} styles={styles} mirror={mirror} />
  ) : (
    <VideoPlaceholder
      className={videoClassName}
      userName={tile.userName}
      imageUrl={placeholderImageUrl}
      styles={styles}
      isFeatured={tile.renderer === BigTile}
    />
  );
};

TileContent.propTypes = {
  tile: AppPropTypes.tile.isRequired,
  placeholderImageUrl: PropTypes.string,
  styles: PropTypes.shape({
    screen: PropTypes.string,
    flip: PropTypes.string,
    noVideo: PropTypes.string,
  }).isRequired,
};

TileContent.defaultProps = {
  placeholderImageUrl: null,
};

export default React.memo(TileContent);
