import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import { ACCOUNT_INFO_QUERY } from './queries';
import { withAuth } from 'contexts/AuthContext';
import { withData } from 'support/page';

const AccountInfoContext = createContext(undefined);

export function useAccountInfo() {
  return useContext(AccountInfoContext);
}

function AccountInfoProviderWithData(props) {
  return <AccountInfoContext.Provider value={props.accountInfos}>{props.children}</AccountInfoContext.Provider>;
}

AccountInfoProviderWithData.propTypes = {
  children: PropTypes.node,
};

// prettier-ignore
export const AccountInfoProvider = withAuth(
  graphql(ACCOUNT_INFO_QUERY, {
    name: 'accountInfos',
    options: props => {
      return {
        variables: {
          userCode: props?.auth?.userCode,
        },
      };
    },
  })(
    withData('accountInfos')(
        AccountInfoProviderWithData
    )
));
