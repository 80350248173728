import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import CommentInput from './CommentInput.jsx';
import useNewCommentNotifications from 'hooks/video_conference/useNewCommentNotifications/index.js';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay/index.js';
import { CHAT_ELEMENT } from 'constants/video_conference/roomElements.js';
import useMedia from 'hooks/video_conference/useMedia/index.js';
import { timestamp } from 'support/date.js';
import { MD_BREAKPOINT } from 'constants/breakpoints.js';

const VirtualChatList = React.lazy(() => import('./VirtualChatList'));

export const LastCommentTimeContext = React.createContext();
function Chat({ messages, moduleId, dailycoroomId, hasNextPage, isFetchingNextPage, fetchNextPage }) {
  const { roomElements } = useRoomElementsDisplay();
  const isChatOpen = roomElements.includes(CHAT_ELEMENT);
  const isDesktop = useMedia(MD_BREAKPOINT);

  const [isAtBottom, setIsAtBottom] = useState(true);

  const startReached = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
  }, [fetchNextPage, isFetchingNextPage, hasNextPage]);

  useNewCommentNotifications(messages, {
    filter: useCallback(() => !isAtBottom || (!isDesktop && !isChatOpen), [isAtBottom, isDesktop, isChatOpen]),
  });

  return (
    <LastCommentTimeContext.Provider value={parseInt(timestamp(messages[messages.length - 1]?.inserted_at))}>
      <VirtualChatList messages={messages} startReached={startReached} isAtBottom={isAtBottom} setIsAtBottom={setIsAtBottom} />
      <CommentInput moduleId={moduleId} dailycoroomId={dailycoroomId} />
    </LastCommentTimeContext.Provider>
  );
}

Chat.propTypes = {
  moduleId: PropTypes.string.isRequired,
  dailycoroomId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,
};

export default React.memo(Chat);
