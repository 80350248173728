import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/mainComponents/Icon';
import styles from 'scss/video_conference/TileIcon.module.scss';

function TileIcon({ icon, className }) {
  const classes = `${styles.container} ${className}`;
  return (
    <div className={classes}>
      <Icon icon={icon} className={styles.icon} />
    </div>
  );
}

export default TileIcon;

TileIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TileIcon.defaultProps = {
  className: '',
};
