import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useAuth } from 'contexts/AuthContext';

import './AccessRequestPage.scss';
import { REQUEST_ACCESS } from './queries';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import { Illustration } from 'components/mainComponents/Illustration';
import { Input } from 'components/mainComponents/Input';
import { Paper } from 'components/mainComponents/Paper';
import { TextArea } from 'components/mainComponents/TextArea';

export function AccessRequestPage() {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [requestAccess, { data }] = useMutation(REQUEST_ACCESS);
  let emailIsSent = false;

  const formHandler = useCallback(
    async event => {
      event.preventDefault();
      const formData = new FormData(event.target);
      await requestAccess({
        variables: {
          email: formData.get('email'),
          user_code: auth?.userCode,
          firstname: formData.get('firstname'),
          lastname: formData.get('lastname'),
          reason: formData.get('reason'),
        },
      });
    },
    [auth?.userCode, requestAccess]
  );

  if (data?.requestAccess) {
    emailIsSent = true;
  }

  return (
    <div className='V2__AccessRequestPage'>
      <Breadcrumb className='V2__AccessRequestPage__breadcrumb'>
        <Breadcrumb.Item url={'/login'}>{t('SigninPage.connect')}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('AccessRequestPage.send_access_request_to_extranet')}</Breadcrumb.Item>
      </Breadcrumb>
      {!emailIsSent && (
        <>
          <Heading as='h1' level={1}>
            {t('AccessRequestPage.send_access_request_to_extranet')}
          </Heading>
          <Heading as='p' level={4}>
            {t('AccessRequestPage.the_service_provider_will_receive_a_request_message')}
          </Heading>

          <Paper className='V2__AccessRequestPage__form' as='form' onSubmit={formHandler}>
            <Input className='V2__AccessRequestPage__input' name='email' type='email' placeholder={t('AccessRequestPage.email')} />
            <Input className='V2__AccessRequestPage__input' name='firstname' type='text' placeholder={t('AccessRequestPage.name')} />
            <Input className='V2__AccessRequestPage__input' name='lastname' type='text' placeholder={t('AccessRequestPage.surname')} />
            <TextArea
              className='V2__AccessRequestPage__input'
              name='reason'
              placeholder={t('AccessRequestPage.free_text_in_request')}
            />
            <Button type='submit'>{t('AccessRequestPage.send_request')}</Button>
          </Paper>
        </>
      )}
      {emailIsSent && (
        <>
          <Heading as='h1' level={1}>
            {t('SigninPage.see_your_email_inbox')}
          </Heading>
          <Heading as='p' level={4}>
            {t('AccessRequestPage.the_service_provider_will_receive_a_request_message')}
          </Heading>
          <div className='V2__AccessRequestPage__picto'>
            <Illustration name='robot-surfing' size='lg' />
          </div>
        </>
      )}
    </div>
  );
}
