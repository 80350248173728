import React from 'react';

import './ProgramPage.scss';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Heading } from 'components/mainComponents/Heading';
import { ProgramCardWidget } from 'components/mainComponents/ProgramCardWidgets';
import { ProgramContentWidget } from 'components/mainComponents/ProgramContentWidget';

import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withData, withRouteParams } from 'support/page';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function Program({ trainingSession }) {
  const { t } = useTranslation();
  const trainingSessionName = trainingSession.custom_name || trainingSession.name;
  const { program } = trainingSession;

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${trainingSession.id}`}>{trainingSessionName}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('SidebarNavigationLink.program')}</Breadcrumb.Item>
      </Breadcrumb>
      <section className='V2_ProgramePage'>
        <Heading className='V2_ProgramePage__title' as='h1' level={1}>
          {trainingSessionName}
        </Heading>
        {program ? <ProgramDisplay program={program} /> : <p>{t('ProgramPage.no_program')}</p>}
      </section>
    </LeftMenuLayout>
  );
}

function ProgramDisplay({ program }) {
  const { t } = useTranslation();
  if (!program) return null;

  return (
    <>
      <div className='V2_ProgramePage__formation-type'>{t(formatTrainingPedagogicalModality(program))}</div>
      <p className='V2_ProgramePage__program'>{program.description}</p>
      <div className='V2_ProgramePage__grid'>
        <div className='V2_ProgramePage__grid__C1'>
          {(program.duration_in_hours || program.duration_in_days || (program.duration_in_hours && program.duration_in_days)) && (
            <div className='V2_ProgramePage__duration'>
              <span className='V2_ProgramePage__duration__title'>{t('Program.duration')}:</span>
              <span>{formatDuration(program, t)}</span>
            </div>
          )}

          {program.goals?.length > 0 && (
            <ProgramCardWidget title={t('Program.educational_goals')} color='primary' icon='award--alt'>
              <ProgramCardWidget.Item elements={program.goals} />
            </ProgramCardWidget>
          )}

          {(program.targets?.length > 0 || program.prerequisites?.length > 0) && (
            <ProgramCardWidget title={t('Program.trainee_profile')} color='secondary' icon='student'>
              <ProgramCardWidget.Item elements={program.targets} secondaryTitle={t('Program.for_whom')} />
              <ProgramCardWidget.Item elements={program.prerequisites} secondaryTitle={t('Program.prequisites')} />
            </ProgramCardWidget>
          )}

          {program.assessments?.length > 0 && (
            <ProgramCardWidget title={t('Program.monitoring_of_implementation_and_evaluation')} color='alt-1' icon='star'>
              <ProgramCardWidget.Item elements={program.assessments} />
            </ProgramCardWidget>
          )}

          {program.pedagogical_resources?.length > 0 && (
            <ProgramCardWidget title={t('Program.technical_and_educational_resources')} color='alt-2' icon='notepad'>
              <ProgramCardWidget.Item elements={program.pedagogical_resources} />
            </ProgramCardWidget>
          )}

          {program.mentoring && (
            <ProgramCardWidget title={t('Program.educational_team')} color='alt-3' icon='users'>
              <ProgramCardWidget.Text>{program.mentoring}</ProgramCardWidget.Text>
            </ProgramCardWidget>
          )}
        </div>
        {program.steps?.length > 0 && (
          <div className='V2_ProgramePage__grid__C2'>
            <Heading level={3} as='h2'>
              {t('Program.training_content')}
            </Heading>
            {program.steps.map((step, i) => (
              <ProgramContentWidget key={`step-${i}`} title={step.text} elements={step.substeps} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

// Helper from old version
const formatTrainingPedagogicalModality = program => {
  switch (program.training_pedagogical_modality) {
    case 0:
      return 'Program.on_site_training';
    case 1:
      return 'Program.mixed_training';
    case 2:
      return 'Program.distance_learning';
    default:
      return 'Program.on_site_training';
  }
};

const formatDuration = (program, t) => {
  const duration = moment.duration(program.duration_in_hours, 'hours');
  const hours = Math.floor(duration.asHours());
  const mins = Math.floor(duration.asMinutes()) - hours * 60;

  const hoursDisplay = mins ? `${hours}:${mins}` : t('Program.hour', { count: hours });
  if (program.duration_in_days) {
    return `${hoursDisplay} ${t('Program.day', { count: program.duration_in_days })}`;
  }
  return hoursDisplay;
};

const PROGRAM_PAGE_QUERY = gql`
  query programPage($id: ID!) {
    trainingSession(id: $id) {
      id
      name
      show_program_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      has_numeric_signing
      modules {
        id
        name
        visible
      }
      start_date
      program {
        id
        name
        description
        duration_in_hours
        duration_in_days
        training_pedagogical_modality
        mentoring
        goals {
          text
        }
        pedagogical_resources {
          text
        }
        targets {
          text
        }
        prerequisites {
          text
        }
        assessments {
          text
        }
        steps {
          text
          substeps {
            text
          }
        }
        documents {
          filename
          url
          mime
        }
      }
    }
  }
`;

const withGraphqlData = graphql(PROGRAM_PAGE_QUERY, {
  name: 'trainingSession',
  options: props => {
    return {
      variables: { id: props.params.training_session_id },
    };
  },
});

// prettier-ignore
export const ProgramPage =
  withRouteParams(
    withGraphqlData(
      withData('trainingSession')(
        Program, { loading: PageLoader })));
