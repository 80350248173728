import { useMutation } from '@apollo/client';
import { TextArea } from 'components/mainComponents/inputs';
import { CREATE_COMMENT_MUTATION } from 'mutations/video_conference';
import React, { useState } from 'react';
import styles from '../../../../scss/video_conference/CommentInput.module.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/mainComponents/Menu/IconButton';
import { ADD_NOTIFICATIONS } from 'actions/video_conference';
import { useNotificationsDispatch } from 'hooks/useNotifications';
import { uniqueId } from 'lodash';

const ENTER_KEYCODE = 13;
const COMMENT_MAX_LENGTH = 500;
const LEFT_NUMBERS_INDICATOR = 400;

function CommentInput({ moduleId, dailycoroomId }) {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [createComment] = useMutation(CREATE_COMMENT_MUTATION, {});
  const dispatchNotifications = useNotificationsDispatch();

  const handleChange = value => {
    setComment(value);
  };

  const handleSend = async () => {
    setComment('');
    await createComment({
      variables: {
        module_id: moduleId,
        dailycoroom_id: dailycoroomId,
        text: comment,
      },
    }).catch(() => {
      dispatchNotifications({
        action: ADD_NOTIFICATIONS,
        payload: {
          newNotifications: [
            {
              id: `comment-error-${uniqueId()}`,
              title: t('Notification.comment_creation_error.title'),
              text: t('Notification.comment_creation_error.text'),
              type: 'comment-creation-error',
            },
          ],
        },
      });
    });
  };

  const handleKeyDown = async event => {
    // submit on enter
    if (event.keyCode === ENTER_KEYCODE && !event.shiftKey) {
      event.preventDefault();
      await handleSend();
    }
  };

  return (
    <div>
      {comment.length > LEFT_NUMBERS_INDICATOR && (
        <div className={styles.leftCharsIndicator}>
          {comment.length} / {COMMENT_MAX_LENGTH}
        </div>
      )}
      <div className={styles.commentInput}>
        <div className={styles.input}>
          <TextArea
            value={comment}
            rows={1}
            maxLength={COMMENT_MAX_LENGTH}
            placeholder={t('CommentInput.new_discussion')}
            changeFn={handleChange}
            onKeyDown={handleKeyDown}
            autoResize
          />
        </div>
        <IconButton icon='paper-plane' clickFn={handleSend} className={styles.button} />
      </div>
    </div>
  );
}

export default CommentInput;

CommentInput.propTypes = {
  moduleId: PropTypes.string.isRequired,
  dailycoroomId: PropTypes.string.isRequired,
};
