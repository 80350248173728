import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import LiveEvaluationLobby from './LiveEvaluationLobby';
import LiveEvaluationControls from './LiveEvaluationControls';
import { notify } from 'support/realtime';
import { useAuth } from 'contexts/AuthContext';
import FormInputCheckbox from './form_inputs/FormInputCheckbox';
import FormInputRadio from './form_inputs/FormInputRadio';
import FormInputFree from './form_inputs/FormInputFree';
import FormInputRating from './form_inputs/FormInputRating';
import FormInputScore from './form_inputs/FormInputScore';
import FormInputMatrix from './form_inputs/FormInputMatrix';
import Score from '../module/activities/Score';
import { ActionButton, UrlButton } from 'components/mainComponents/buttons';
import { getTraineeName } from 'support/evaluationUtils';
import { useParentCallback } from 'contexts/ParentCallbackContext';

import '../../scss/AnswerEvaluationLegacyForm.scss';

const AnswerEvaluationLegacyForm = props => {
  const fireParentCallback = useParentCallback();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({
    sending: false,
    correcting: false,
    score: null,
    show_score: true,
    key: 0,
    members: [], // Only for Live
    currentPage: 0, // Only for Live
    nbPages: 0, // Only for live
    syncWithInstructor: true, // Only for live,
    answer: {},
  });

  const [answers, setAnswers] = useState({});

  useEffect(() => {
    if (!props.previewMode) {
      const { evaluation } = props.pending_evaluation;
      if (!evaluation.live && evaluation.type === 'activity' && evaluation.module_id === null) {
        navigate(`/ts/${evaluation.training_session.id}`);
      }
      setState(prevState => ({ ...prevState, ...stateFromPendingEvaluation(props.pending_evaluation) }));
    }
  }, [props.pending_evaluation, props.previewMode, navigate]);

  // Only for Live
  const onMembersChanged = members => {
    setState(prevState => ({ ...prevState, members }));
  };

  // Only for Live
  const onCurrentPageChanged = (page, sync_with_instructor) => {
    if (sync_with_instructor !== undefined) {
      setState(prevState => ({ ...prevState, currentPage: page, syncWithInstructor: sync_with_instructor }));
    } else {
      setState(prevState => ({ ...prevState, currentPage: page }));
    }
  };

  // Only for live
  const onShowResults = () => {
    navigate(`/evaluation_results/${props.pending_evaluation.evaluation.id}`);
  };

  const onRetry = () => {
    const resetState = {
      correcting: false,
      answer: {},
      score: null,
      key: state.key + 1,
    };
    setState(prevState => ({ ...prevState, ...resetState }));
  };

  const onInputAnswered = (inputId, value) => {
    const { evaluation } = props.pending_evaluation;
    const isLive = evaluation.live;

    setAnswers({ ...answers, [inputId]: value });

    if (isLive) {
      notify(`evaluation_${evaluation.id}`, 'client-form_input_answered', {
        inputId,
        traineeId: auth?.guestInfo?.id,
      });
    }
  };

  const onAnswer = async () => {
    setState(prevState => ({ ...prevState, sending: true }));
    const evaluationId = parseInt(props.evaluation_id, 10);
    const traineeId = parseInt(props.trainee_id, 10);

    const result = await props.mutate({
      variables: {
        id: evaluationId,
        trainee_id: traineeId,
        answers: JSON.stringify(answers),
      },
    });
    const answer = result.data.answer_evaluation.form_answer && JSON.parse(result.data.answer_evaluation.form_answer.answers);
    const score = result.data.answer_evaluation.answer_evaluation_result;
    setState(prevState => ({
      ...prevState,
      sending: false,
      score,
      answer,
    }));
    const { evaluation } = props.pending_evaluation;

    // Show correct answers and score, or return to the ts page
    const corrected = evaluation.type === 'activity' && score.total > 0;
    if (corrected) {
      setState(prevState => ({ ...prevState, correcting: true, show_score: evaluation.show_score }));
    } else {
      setState(prevState => ({ ...prevState, correcting: true, show_score: false }));
      navigate(backPath());
    }

    fireParentCallback?.();

    // Notify possible parent frame (i.e. Skills) that the answer has been sent.
    //
    window.top.postMessage('EvaluationSubmitted', '*');
  };

  const backPath = () => {
    const baseUrl = `/ts/${props.pending_evaluation.evaluation.training_session.id}`;
    if (props.pending_evaluation.evaluation.module_id) {
      return `${baseUrl}/module/${props.pending_evaluation.evaluation.module_id}`;
    }
    return baseUrl;
  };

  const { evaluation } = props.pending_evaluation;
  const { form } = evaluation;
  const isLive = evaluation.live;
  const { correcting } = state;
  const inModule = evaluation.module_id !== null;
  const traineeName = getTraineeName(evaluation, evaluation.trainee_name);
  const { previewMode } = props;

  return (
    <div className={`AnswerEvaluation ${isLive ? 'live-activity' : ''} ${correcting ? 'correcting' : ''}`}>
      {isLive && !correcting && <LiveEvaluationLobby members={state.members} currentPage={state.currentPage} />}

      {!isLive && (
        <div>
          <h2>{evaluation.name}</h2>
          {traineeName && (
            <p className='trainee-name'>
              Apprenant: <span>{traineeName}</span>
            </p>
          )}
        </div>
      )}

      {state.show_score && renderScore(state.score)}

      <div className={correcting ? 'form-inputs correcting' : 'form-inputs'} key={`inputs-${state.key}`}>
        {form.inputs.map((formInput, i) => {
          const answer = getInputAnswer(state.answer, formInput.id);
          return (
            <div className='form-input' key={formInput.id} style={styleForInput(i, state.currentPage, evaluation.live, correcting)}>
              {renderFormInput(answer, onInputAnswered, formInput, correcting && evaluation.show_score, isLive)}
            </div>
          );
        })}
      </div>

      {!previewMode && isLive && !correcting && (
        <LiveEvaluationControls
          evaluationId={evaluation.id}
          onMembersChanged={onMembersChanged}
          currentPage={state.currentPage}
          nbPages={state.nbPages}
          onAnswer={onAnswer}
          syncWithInstructor={state.syncWithInstructor}
          onShowResults={onShowResults}
          onCurrentPageChanged={onCurrentPageChanged}
        />
      )}

      {!previewMode && !correcting && !state.sending && (
        <ActionButton label={t('AnswerEvaluation.send_reply')} clickFn={onAnswer} icon='mail' />
      )}

      {!previewMode && !correcting && state.sending && (
        <ActionButton label={t('AnswerEvaluation.sending_reply')} type='secondary' disabled={true} />
      )}

      {!previewMode && correcting && !inModule && (
        <UrlButton label={t('AnswerEvaluation.back_to_session_page')} url={backPath()} icon='arrow--left' type='secondary' />
      )}

      <div className='bottom-score' style={{ position: 'relative', marginTop: 20 }}>
        {evaluation.show_score && renderScore(state.score)}
      </div>

      {!previewMode && correcting && evaluation.replayable && !isLive && (
        <div>
          <ActionButton label={t('AnswerEvaluation.retry')} clickFn={onRetry} type='secondary' />
        </div>
      )}
    </div>
  );
};

export default AnswerEvaluationLegacyForm;

function renderScore(score) {
  if (score && score.total > 0) {
    const percent = _.round((score.score / score.total) * 100, 1);
    const correct = score.score === score.total;
    return <Score score={score.score} total={score.total} percent={percent} correct={correct} />;
  }
  return null;
}

function renderFormInput(answer, onInputAnswered, formInput, correcting, isLive) {
  switch (formInput.type) {
    case 'radio':
      return (
        <FormInputRadio input={formInput} correcting={correcting} isLive={isLive} answerSent={answer} onAnswer={onInputAnswered} />
      );
    case 'checkbox':
      return (
        <FormInputCheckbox input={formInput} correcting={correcting} isLive={isLive} answerSent={answer} onAnswer={onInputAnswered} />
      );
    case 'free':
      return (
        <FormInputFree input={formInput} correcting={correcting} isLive={isLive} answerSent={answer} onAnswer={onInputAnswered} />
      );
    case 'rating':
      return (
        <FormInputRating input={formInput} correcting={correcting} isLive={isLive} answerSent={answer} onAnswer={onInputAnswered} />
      );
    case 'score':
      return (
        <FormInputScore input={formInput} correcting={correcting} isLive={isLive} answerSent={answer} onAnswer={onInputAnswered} />
      );
    case 'matrix':
      return (
        <FormInputMatrix input={formInput} correcting={correcting} isLive={isLive} answerSent={answer} onAnswer={onInputAnswered} />
      );
    default:
      return null;
  }
}

function getInputAnswer(answer, inputId) {
  return answer && answer[inputId];
}

function styleForInput(i, currentPage, live, correcting) {
  if (live === true && correcting === false) {
    return {
      display: i + 1 === currentPage ? 'block' : 'none',
    };
  }
  return {};
}

function stateFromPendingEvaluation(pending_evaluation) {
  const { evaluation } = pending_evaluation;
  const nbPages = evaluation.form.inputs.length;
  const answer = pending_evaluation.form_answer && JSON.parse(pending_evaluation.form_answer.answers);
  const score = pending_evaluation.answer_evaluation_result;
  const { show_score } = evaluation;
  const correcting = !pending_evaluation.pending;

  return {
    currentPage: evaluation.current_page,
    nbPages,
    syncWithInstructor: evaluation.sync_with_instructor,
    answer,
    score,
    show_score,
    correcting,
  };
}
