import { getTruthyKeys } from 'helpers/utils';
import _ from 'lodash';

export function problemIndicatorDescriptionKeys(problemIndicators, descriptionMapping) {
  return _.pickBy(descriptionMapping, (_description, key) => problemIndicators[key]);
}

export function applyCallQuality(callObject, bandwidthSettings) {
  if (!_.isEmpty(bandwidthSettings)) {
    // Add webcam test result to bandwidthSettings after fixing background effect quality issue
    callObject.setBandwidth(bandwidthSettings);
  }
}

export function getDetailedProblemIndicators(problemIndicators) {
  return {
    problemIndicatorVideo: problemIndicators?.problemIndicatorVideo || false,
    problemIndicatorNetwork: problemIndicators?.problemIndicatorNetwork || false,
    problemIndicatorNetworkDropping: problemIndicators?.problemIndicatorNetworkDropping || false,
    problemIndicatorCpuLoad: problemIndicators?.problemIndicatorCpuLoad || false,
  };
}

export function getProblemKeys(problemIndicators) {
  return getTruthyKeys(problemIndicators).map(problem => {
    if (typeof problemIndicators[problem] === 'boolean') {
      return problem;
    }
    // in case of non-boolean values.
    // For example replace `problemIndicatorVideo` string with `CAM_OFF` or `CAM_LIMITED`
    return problemIndicators[problem];
  });
}
