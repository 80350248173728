import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import AudioPlayer from './players/AudioPlayer';
import ImagePlayer from './players/ImagePlayer';
import VideoPlayer from './players/VideoPlayer';
import PDFPlayer from './players/PDFPlayer';
import { UrlButton } from 'components/mainComponents/buttons';
import { useParentCallback } from 'contexts/ParentCallbackContext';

import { documentLogo } from 'support/logos';

import '../../scss/Document.scss';
import { useIsUIv2 } from 'components/v2Activation/UIv2Guard';
import { IconButton } from 'components/mainComponents/IconButton';

const Document = props => {
  const isUIv2 = useIsUIv2();
  const fireParentCallback = useParentCallback();
  const { t } = useTranslation();
  const [setDocumentRead] = useMutation(SET_DOCUMENT_READ);
  const [readNotified, setReadNotified] = useState(false);
  const { readForNotifications, document } = props;
  const { id, url } = document;
  const isDownloadable = isDocumentDownloadable(document);

  const onRead = useCallback(
    data => {
      if (readForNotifications === true && (!readNotified || !!data)) {
        setReadNotified(true);

        setDocumentRead({
          variables: { id, data },
        }).then(() => {
          fireParentCallback();
        });
      }
    },
    [readForNotifications, readNotified, setReadNotified, setDocumentRead, id, fireParentCallback]
  );

  return (
    <div className='Document'>
      <DocumentRenderer document={document} onRead={onRead} isDownloadable={isDownloadable} />
      {!isUIv2 && isDownloadable && (
        <UrlButton url={url} icon={documentLogo(document)} label={t('Document.download')} blank={true} type='secondary' />
      )}
      {isUIv2 && isDownloadable && (
        <IconButton variant='primary' as='a' href={url} icon={documentLogo(document)} target='_blank' rel='noopener noreferrer'>
          {t('Document.download')}
        </IconButton>
      )}
    </div>
  );
};

function isDocumentDownloadable(document) {
  return document.downloadable && /audio|image|video|pdf/.test(document.mime);
}

function DocumentRenderer({ document, onRead, isDownloadable }) {
  const { id, filename, mime, url, wistia } = document;
  const playerId = `player-${id}`;

  if (/audio/.test(mime)) {
    return <AudioPlayer url={url} key={playerId} onRead={onRead} />;
  }
  if (/image/.test(mime)) {
    return <ImagePlayer url={url} key={playerId} onRead={onRead} />;
  }
  if (/video/.test(mime)) {
    return <VideoPlayer url={url} wistia={wistia} onRead={onRead} key={playerId} />;
  }
  if (/pdf/.test(mime)) {
    return <PDFPlayer url={url} key={playerId} downloadable={isDownloadable} onRead={onRead} />;
  }
  return (
    <div className='baseplayer' key={playerId}>
      <a href={url} target='_blank' rel='noopener noreferrer' onClick={() => onRead()}>
        <strong>{filename}</strong>
      </a>
    </div>
  );
}

const SET_DOCUMENT_READ = gql`
  mutation set_document_read($id: ID!, $data: Json) {
    set_document_read(id: $id, data: $data)
  }
`;

export default Document;
