import PropTypes from 'prop-types';

export const DocumentPropTypes = PropTypes.shape({
  id: PropTypes.string,
  filename: PropTypes.string,
  url: PropTypes.string,
  mime: PropTypes.string,
  wistia: PropTypes.shape({
    hashed_id: PropTypes.string,
  }),
  folder_id: PropTypes.string,
  downloadable: PropTypes.bool,
  isShown: PropTypes.bool,
  inserted_at: PropTypes.string,
}).isRequired;

export const FolderPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  documents: PropTypes.arrayOf(DocumentPropTypes),
  isShown: PropTypes.bool,
  inserted_at: PropTypes.string,
}).isRequired;
