import React, { useContext, useState, useEffect } from 'react';
import * as Auth from 'support/auth';

export const AuthContext = React.createContext();

function buildAuthFromLocalStorage() {
  const guestInfo = Auth.getGuestInfo() || {};
  // Override type, such as useAuth().type matches getGuestType() (i.e. be lowercase)
  if (guestInfo?.type) {
    guestInfo.type = Auth.getGuestType();
  }

  return {
    token: Auth.getToken(),
    userCode: Auth.getUserCode(),
    isLoggedIn: Auth.isLoggedIn(),
    shouldAcceptTOS: Auth.getGuestInfo()?.should_accept_tos,
    guestInfo: guestInfo,
    switchGuestInfos: Auth.getSwitchGuestInfos(),
  };
}

export const AuthContextProvider = ({ children }) => {
  const [isAuthResolved, setIsAuthResolved] = useState(false);
  const [auth, setAuth] = useState({});

  useEffect(() => {
    setAuth(buildAuthFromLocalStorage());
    setIsAuthResolved(true);
  }, []);

  const signin = (guestInfo, token, switchGuestInfos) => {
    Auth.setLoggedIn(guestInfo, token, switchGuestInfos);
    setAuth(buildAuthFromLocalStorage());
  };

  const acceptTOS = () => {
    Auth.acceptTOS();
    setAuth(buildAuthFromLocalStorage());
  };

  const signout = () => {
    Auth.signout();
    setAuth(buildAuthFromLocalStorage());
  };

  return <AuthContext.Provider value={{ auth, signin, acceptTOS, signout }}>{isAuthResolved && children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);
  return auth;
};

/**
 * Return a HOC that will inject auth parameters as a property to wrapped element of type Component.
 *
 * Name of the property can be overriden by providing a `authParamsName` option.
 */
export function withAuth(Component, opts = {}) {
  const { authParamsName = 'auth' } = opts;

  return forward => {
    const { auth } = useAuth();
    const authParams = { [authParamsName]: auth };
    return <Component {...authParams} {...forward} />;
  };
}
