import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withData, withRouteParams } from '../../support/page';
import { useAuth } from 'contexts/AuthContext';
import ModuleList from './ModuleList';
import TrainingSessionHeader from 'components/training_session/TrainingSessionHeader';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { isVisible } from './module.js';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb/Breadcrumb';

const ModulesPage = props => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const { training_session } = props;
  const visibleModules = training_session.modules.filter(isVisible);

  const finsishedSequences = visibleModules.filter(module => {
    return module.sequence.filter(s => s.finished).length === module.sequence.length;
  });

  const availableSequences = visibleModules.filter(module => {
    const nbrFinishedSequences = module.sequence.filter(s => s.finished).length;
    const nbrSequences = module.sequence.length;
    return nbrFinishedSequences < nbrSequences;
  });

  const titleWhenAvailableSequences =
    auth?.guestInfo?.type === 'trainee'
      ? t('ModulePage.e-learning_activities_to_be_completed')
      : t('ModulePage.preview_e_learning_activities');

  const sessionToCompleteTitle =
    availableSequences.length > 0 ? titleWhenAvailableSequences : t('ModulePage.no_e-learning_activities');

  const training_session_name = training_session.custom_name || training_session.name;
  return (
    <div className='page ModulesPage'>
      <TrainingSessionHeader training_session_id={training_session.id} />
      <main>
        <Breadcrumb
          links={[
            { name: training_session_name, url: `/ts/${training_session.id}` },
            { name: t('TrainingSessionHeader.modules_details') },
          ]}
        />
        <h1>{sessionToCompleteTitle}</h1>
        {availableSequences.length > 0 && <ModuleList training_session_id={training_session.id} modules={availableSequences} />}
        {finsishedSequences.length > 0 && (
          <>
            <h1>{t('ModulePage.finished_sequences')}</h1>
            <ModuleList training_session_id={training_session.id} modules={finsishedSequences} />
          </>
        )}
      </main>
    </div>
  );
};

const modulesQuery = gql`
  query training_session_evaluations($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      modules {
        id
        name
        visibilityMode
        visibilityStartDate
        visibilityEndDate
        virtualroom
        sequence {
          finished
          available
        }
      }
    }
  }
`;

const withGraphqlData = graphql(modulesQuery, {
  name: 'training_session',
  options: props => {
    return {
      variables: {
        id: props.params.training_session_id,
      },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        ModulesPage, { loading: LoadingPagePlaceholder })));
