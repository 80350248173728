import React, { useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import SignaturePad from '../signature-pad';

import styles from '../../scss/SigningPad.module.scss';
import { ActionButton } from 'components/mainComponents/buttons';

function SigningPad({ traineeTrainingSessionId, trainingSessionInstructorId, trainingSessionSlotId, cancelFn, succeedFn }) {
  const [sign, { error, loading }] = useMutation(SIGN);
  const padElement = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    padElement.current && padElement.current.clear();
  }, [traineeTrainingSessionId, trainingSessionInstructorId]);

  const signAttendance = signatureData => {
    const emptyPad = padElement.current === null || padElement.current.isEmpty();
    if (emptyPad) {
      return;
    }

    // If no id is mentioned, the signature is for the connected trainee or instructor
    let variables = {
      trainingSessionInstructorId: trainingSessionInstructorId,
      trainingSessionSlotId: trainingSessionSlotId,
      signature: signatureData,
    };
    if (traineeTrainingSessionId) variables.traineeTrainingSessionId = traineeTrainingSessionId;
    if (trainingSessionInstructorId) variables.trainingSessionInstructorId = trainingSessionInstructorId;

    sign({
      variables: variables,
    })
      .then(() => {
        succeedFn(signatureData);
      })
      .catch(() => {
        /* DO NOT REMOVE this handler, otherwise GraphQL will consider any server-side error as unhandled */
      })
      .finally(() => {
        padElement.current && padElement.current.clear();
      });
  };

  return (
    <div id='trainee-form'>
      {error && <div className={styles.signingPad}>Une erreur est survenue...</div>}
      {loading && <div className={styles.signingPad}>Un moment...</div>}
      {!loading && (
        <>
          <div className={styles.signingPad}>
            <SignaturePad ref={padElement} width={600} height={300} />
            <ActionButton
              clickFn={() => padElement.current.clear()}
              icon='eraser'
              size='small'
              ariaLabel={t('SigninPad.erase')}
              type='secondary'
            />
          </div>
          <div className={styles.submitButtons}>
            <ActionButton clickFn={() => cancelFn()} icon='cross' label={t('SigninPad.cancel')} type='secondary' />
            <ActionButton
              clickFn={() => signAttendance(padElement.current.toDataURL())}
              icon='checkmark'
              label={t('SigninPad.save')}
            />
          </div>
        </>
      )}
    </div>
  );
}

export const SIGN = gql`
  mutation sign($signature: String!, $trainingSessionSlotId: ID!, $traineeTrainingSessionId: ID, $trainingSessionInstructorId: ID) {
    sign(
      signature: $signature
      trainingSessionSlotId: $trainingSessionSlotId
      traineeTrainingSessionId: $traineeTrainingSessionId
      trainingSessionInstructorId: $trainingSessionInstructorId
    ) {
      signature
    }
  }
`;

export default SigningPad;
