import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useActiveParticipant, useActiveSpeakerId } from '@daily-co/daily-react';

const CurrentSpeakerContext = createContext();

export const CurrentSpeakerProvider = ({ children }) => {
  const activeParticipant = useActiveParticipant({
    onActiveSpeakerChange: ({ activeSpeaker: { peerId } }) => {
      setActivityTimestamps(oldActivityTimestamps => ({
        ...oldActivityTimestamps,
        [peerId]: Date.now(),
      }));
    },
  });

  const currentSpeakerId = activeParticipant?.tracks.audio.state !== 'off' ? activeParticipant?.session_id : null;
  const lastRemoteSpeakerId = useActiveSpeakerId({ ignoreLocal: true });

  const [activityTimestamps, setActivityTimestamps] = useState({});

  const contextValue = useMemo(
    () => ({
      currentSpeakerId,
      lastRemoteSpeakerId,
      activityTimestamps,
    }),
    [currentSpeakerId, lastRemoteSpeakerId, activityTimestamps]
  );

  return <CurrentSpeakerContext.Provider value={contextValue}>{children}</CurrentSpeakerContext.Provider>;
};

function useCurrentSpeaker() {
  const currentSpeaker = useContext(CurrentSpeakerContext);

  if (currentSpeaker === undefined) {
    throw new Error('useCurrentSpeaker must be used within a CurrentSpeakerProvider');
  }

  return currentSpeaker;
}

CurrentSpeakerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default useCurrentSpeaker;
