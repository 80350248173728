import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './DiscussionPanel.scss';
import { CommentThread } from 'components/mainComponents/CommentThread';
import { Heading } from 'components/mainComponents/Heading';
import { CommentPropType, IDPropType } from 'propTypes';
import { CommentThreadInitiator } from 'components/mainComponents/CommentThread/CommentThreadInitiator';

export function DiscussionPanel(props) {
  const { t } = useTranslation();

  return (
    <div className='DiscussionPanel'>
      <Heading level={3}>{t('ModulePage.DiscussionPanel.title')}</Heading>
      <CommentThreadInitiator moduleId={props.moduleId} />
      <div className='DiscussionPanel__discussions-list'>
        {props.discussions.map(thread => (
          <CommentThread comment={thread} moduleId={props.moduleId} key={thread.id} />
        ))}
      </div>
    </div>
  );
}

DiscussionPanel.propTypes = {
  discussions: PropTypes.arrayOf(CommentPropType),
  moduleId: IDPropType,
};

DiscussionPanel.defaultProps = {
  discussions: [],
};
