import clsx from 'clsx';
import PropTypes from 'prop-types';

import './LoadingIndicator.scss';

export function LoadingIndicator(props) {
  const classname = clsx('LoadingIndicator', 'LoadingIndicator--size-' + props.size, props.className, {
    'LoadingIndicator--full-width': props.fullWidth,
  });

  return (
    <div className={classname} role='alert'>
      <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' className='LoadingIndicator__icon'>
        <defs>
          <linearGradient id='myGradient' gradientTransform='rotate(90)'>
            <stop offset='0%' stopColor='var(--colors-white)' />
            <stop offset='66%' stopColor='var(--colors-primary)' />
          </linearGradient>
        </defs>
        <circle cx='50' cy='50' r='47' stroke="url('#myGradient')" strokeWidth={3} fill='none' />
      </svg>
    </div>
  );
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  fullWidth: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  size: 'md',
  fullWidth: false,
};
