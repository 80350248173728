import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withData, withRouteParams } from '../../support/page';
import TrainingSessionHeader from 'components/training_session/TrainingSessionHeader';
import PendingSigninsPanel from 'components/training_session/PendingSigninsPanel';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb/Breadcrumb';

import 'scss/AttendancesSignPage.scss';

const AttendancesSignPage = props => {
  const { t } = useTranslation();
  const { training_session } = props;
  const training_session_name = training_session.custom_name || training_session.name;
  return (
    <div className='page AttendancesSignPage'>
      <TrainingSessionHeader training_session_id={training_session.id} />
      <main>
        <Breadcrumb
          links={[
            { name: training_session_name, url: `/ts/${training_session.id}` },
            { name: t('TrainingSessionAttendancePage.digital_signoff') },
          ]}
        />
        <h1>{t('TrainingSessionAttendancePage.digital_signoff')}</h1>
        <PendingSigninsPanel training_session_id={training_session.id} />
      </main>
    </div>
  );
};

const attendancesPageQuery = gql`
  query training_session($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
    }
  }
`;

const withGraphqlData = graphql(attendancesPageQuery, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: props.params.training_session_id },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        AttendancesSignPage, { loading: LoadingPagePlaceholder })));
