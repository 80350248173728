import { graphql } from '@apollo/client/react/hoc';
import { saveAs } from 'file-saver';
import { flowRight as compose } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DOCUMENTS_QUERY, NOTIFY_DOCUMENT_DOWNLOADED } from './queries';
import './TrainingSessionDocumentsPage.scss';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Heading } from 'components/mainComponents/Heading';
import { IconButton } from 'components/mainComponents/IconButton';
import { Input } from 'components/mainComponents/Input';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { Paper } from 'components/mainComponents/Paper';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';
import { SvgIcon } from 'components/mainComponents/SvgIcon';
import { LeftMenuLayout } from 'components/v2/layouts';
import { fuzzyContains } from 'helpers/utils';
import { documentLogo } from 'support/logos';
import { withData, withRouteParams } from 'support/page';
import { useMutation } from '@apollo/client';
import { useAuth } from 'contexts/AuthContext';
import { GUEST_TYPE } from 'support/auth';

export function TrainingSessionDocumentsPageWithData(props) {
  const { training_session_id } = useParams();
  const [workingDirectoryId, setWorkingDirectoryId] = useState();
  const { auth } = useAuth();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [notifyDocumentDownloaded] = useMutation(NOTIFY_DOCUMENT_DOWNLOADED);

  const sessionName = props.trainingSession.customName || props.trainingSession.name;
  const workingDirectory = directoryInformation(workingDirectoryId);

  const directories = filteredDirectories(directorySubdirectories(workingDirectoryId));
  const files = filteredFiles(directoryFiles(workingDirectoryId));

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${training_session_id}`}>{sessionName}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('TrainingSessionDocumentsPage.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <section className='TrainingSessionDocumentsPage'>
        <Heading className='TrainingSessionDocumentsPage__title' as='h1'>
          {t('TrainingSessionDocumentsPage.title')}
        </Heading>
        <Input
          className='TrainingSessionDocumentsPage__select'
          placeholder={t('TrainingSessionDocumentsPage.search_placeholder')}
          value={searchQuery}
          onChange={event => setSearchQuery(event.target.value)}
        />
        <Paper>
          <p className='TrainingSessionDocumentsPage__working-directory'>
            {workingDirectory ? (
              <IconButton
                onClick={() => changeWorkingDirectory()}
                title={t('TrainingSessionDocumentsPage.navigate_parent_folder')}
                icon='arrow--left'
              />
            ) : (
              <SvgIcon name='home' size='xl' />
            )}
            {workingDirectory?.name ?? t('TrainingSessionDocumentsPage.root_directory')}
          </p>
          {directories.length === 0 && files.length === 0 ? (
            <p className='TrainingSessionDocumentsPage__no-document-message'>{t('TrainingSessionDocumentsPage.no_document')}</p>
          ) : (
            <ul className='TrainingSessionDocumentsPage__documents-list'>
              {directories.map(directory => (
                <li key={directory.id}>
                  <button
                    onClick={() => changeWorkingDirectory(directory.id)}
                    title={t('TrainingSessionDocumentsPage.open_folder', { name: directory.name })}
                    className='TrainingSessionDocumentsPage__documents-list-item'
                  >
                    <RoundedIcon icon='folder' size='2xl' background='primary' />
                    <p className='TrainingSessionDocumentsPage__document-name'>{directory.name}</p>
                  </button>
                </li>
              ))}
              {files.map(file => (
                <li key={file.id}>
                  <button
                    onClick={() => download(file)}
                    title={t('TrainingSessionDocumentsPage.download_file', { name: file.filename })}
                    className='TrainingSessionDocumentsPage__documents-list-item'
                  >
                    <RoundedIcon icon={documentLogo(document)} size='2xl' background='secondary' />
                    <p className='TrainingSessionDocumentsPage__document-name'>{file.filename}</p>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </Paper>
      </section>
    </LeftMenuLayout>
  );

  function changeWorkingDirectory(directoryId) {
    if (!directoryId) {
      setWorkingDirectoryId();
    } else {
      setWorkingDirectoryId(directoryId);
    }
  }

  function directoryInformation(directoryId) {
    return props.trainingSession.foldersWithDocuments.find(folder => folder.id === directoryId);
  }

  function directorySubdirectories(directoryId) {
    if (!directoryId) {
      return props.trainingSession.foldersWithDocuments;
    }

    return []; // There are no subdirectories.
  }

  function directoryFiles(directoryId) {
    if (!directoryId) {
      return props.trainingSession.baseDocuments;
    }

    return props.trainingSession.foldersWithDocuments.filter(folder => folder.id === directoryId).flatMap(folder => folder.documents);
  }

  function filteredFiles(files) {
    return files.filter(file => fuzzyContains(file.filename, searchQuery));
  }

  function filteredDirectories(directories) {
    return directories.filter(directory => fuzzyContains(directory.name, searchQuery));
  }

  function download(file) {
    saveAs(file.url, file.filename);

    if (auth?.guestInfo?.type === GUEST_TYPE.TRAINEE) {
      notifyDocumentDownloaded({ variables: { id: file.id } });
    }
  }
}

const withGraphqlData = compose(
  graphql(DOCUMENTS_QUERY, {
    name: 'trainingSession',
    options: props => ({
      variables: {
        id: props.params.training_session_id,
      },
    }),
  })
);

export const TrainingSessionDocumentsPage = withRouteParams(
  withGraphqlData(withData('trainingSession')(TrainingSessionDocumentsPageWithData, { loading: PageLoader }))
);
