import _ from 'lodash';

import { documentLogo, evaluationLogo } from 'support/logos';

export function getItem(a, module) {
  switch (a.resource_type) {
    case 'evaluation':
      return module.pending_evaluations.find(x => x.evaluation.id === a.resource_id.toString());
    case 'document':
      return module.documents.find(x => x.id === a.resource_id.toString());
    case 'embed':
      return module.embeds.find(x => x.id === a.resource_id.toString());
    case 'module_scorm':
      return module.scorms.find(x => x.id === a.resource_id.toString());
    case 'livestream':
      return module.livestreams.find(x => x.id === a.resource_id.toString());
    case 'html_doc':
      return module.html_docs.find(x => x.id === a.resource_id.toString());
    case 'email_marker':
      return module.email_markers.find(x => x.id === a.resource_id.toString());
    case 'upload_marker':
      return module.upload_markers.find(x => x.id === a.resource_id.toString());
    default:
      return null;
  }
}

export function isActivityAvailable(activity, previewMode = false) {
  return previewMode || activity?.available;
}

export function statusClassName(a, previewMode = false) {
  if (previewMode) {
    return 'preview';
  }
  return `${isActivityAvailable(a, previewMode) ? 'available' : 'non-available'} ${a.finished ? 'finished' : 'non-finished'}`;
}

export function sameActivity(a1, a2) {
  return a1 && a2 && a1.resource_type === a2.resource_type && a1.resource_id === a2.resource_id;
}

export function logo(activity, item) {
  switch (activity.resource_type) {
    case 'html_doc':
      return 'document';
    case 'evaluation':
      return evaluationLogo(item);
    case 'document':
      return documentLogo(item);
    case 'embed':
      return 'link';
    case 'module_scorm':
      return 'external--link';
    case 'livestream':
      return 'video--on';
    case 'email_marker':
      return 'mail';
    case 'upload_marker':
      return 'fill';
    default:
      return null;
  }
}

export function mustShowTotalScore({ sequence, show_score }) {
  return (
    show_score &&
    sequence.filter(({ resource_type }) => _.includes(['evaluation', 'module_scorm', 'upload_marker'], resource_type)).length > 0
  );
}

export function computeTotalScore({ sequence }) {
  const [acc, counter] = sequence
    .filter(
      ({ finished, resource_type, scorable }) =>
        _.includes(['evaluation', 'module_scorm'], resource_type) ||
        (resource_type === 'upload_marker' && finished) ||
        (resource_type === 'html_doc' && finished && scorable)
    )
    .reduce(([acc, counter], { score }) => [acc + score, counter + 1], [0, 0]);
  return counter > 0 ? Math.floor(acc / counter) : 0;
}

export function isVisible({ visibilityMode, visibilityStartDate, visibilityEndDate }) {
  if (visibilityMode === 'ALWAYS') return true;
  if (visibilityMode === 'DATE') {
    const today = Date.now();
    const start = new Date(visibilityStartDate).setHours(0, 0, 0, 0);
    const end = new Date(visibilityEndDate).setHours(23, 59, 59, 999);
    return start <= today && end >= today;
  }
  return false;
}
