import React from 'react';

import CommentList from '../forum/CommentList';

import commentStyles from 'scss/Comment.module.scss';
import { useTranslation } from 'react-i18next';

export default function Discussions({ module }) {
  const { t } = useTranslation();
  return (
    <section className='module-forum'>
      <h2>{t('ModulePage.discussions')}</h2>
      <CommentList styles={commentStyles} module_id={module.id} comments={module.comments} />
    </section>
  );
}
