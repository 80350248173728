import React from 'react';

import Icon from 'components/mainComponents/Icon';
import clsx from 'clsx';

const IconButton = props => {
  const { icon, label, isActive, clickFn, children, className } = props;

  const buttonStyles = clsx('menu__button', isActive && 'menu__button--active', className);

  return (
    <button aria-label={label} className={buttonStyles} onClick={clickFn} title={label}>
      {icon && <Icon icon={icon} />}
      {children}
    </button>
  );
};

export default IconButton;
