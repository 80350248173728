import { gql } from '@apollo/client';
import { COMMENT_FRAGMENT } from 'controllers/comment_controller.js';

export const CREATE_COMMENT = gql`
  mutation create_comment($text: String!, $parentId: ID, $moduleId: ID, $traineeId: ID, $trainingSessionId: ID) {
    create_comment(
      text: $text
      parent_id: $parentId
      module_id: $moduleId
      trainee_id: $traineeId
      training_session_id: $trainingSessionId
    ) {
      ...comment
    }
  }

  ${COMMENT_FRAGMENT}
`;

export const DELETE_COMMENT = gql`
  mutation delete_comment($id: ID!) {
    delete_comment(id: $id)
  }
`;
