import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './AnswerEvaluationLegacyForm.scss';
import { FormInputCheckbox } from './form_inputs/FormInputCheckbox';
import { FormInputFreeText } from './form_inputs/FormInputFreeText';
import { FormInputMatrix } from './form_inputs/FormInputMatrix';
import { FormInputRadio } from './form_inputs/FormInputRadio';
import { FormInputRating } from './form_inputs/FormInputRating';
import { FormInputScore } from './form_inputs/FormInputScore';
import Score from './Score';
import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import { IconButton } from 'components/mainComponents/IconButton';
import { useParentCallback } from 'contexts/ParentCallbackContext';
import { getTraineeName } from 'support/evaluationUtils';

export function AnswerEvaluationLegacyForm(props) {
  const fireParentCallback = useParentCallback();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({
    sending: false,
    correcting: false,
    score: null,
    show_score: true,
    key: 0,
    answer: {},
  });

  const [answers, setAnswers] = useState({});

  useEffect(() => {
    if (!props.previewMode) {
      const { evaluation } = props.pending_evaluation;

      if (!evaluation.live && evaluation.type === 'activity' && evaluation.module_id === null) {
        return navigate(`/ts/${evaluation.training_session.id}`);
      }

      setState(prevState => ({ ...prevState, ...stateFromPendingEvaluation(props.pending_evaluation) }));
    }
  }, [props.pending_evaluation, props.previewMode, navigate]);

  const onRetry = () => {
    const resetState = {
      correcting: false,
      answer: {},
      score: null,
      key: state.key + 1,
    };
    setState(prevState => ({ ...prevState, ...resetState }));
  };

  const onInputAnswered = (inputId, value) => {
    setAnswers({ ...answers, [inputId]: value });
  };

  const onAnswer = async () => {
    setState(prevState => ({ ...prevState, sending: true }));
    const evaluationId = parseInt(props.evaluationId, 10);
    const traineeId = parseInt(props.traineeId, 10);

    const result = await props.mutate({
      variables: {
        id: evaluationId,
        trainee_id: traineeId,
        answers: JSON.stringify(answers),
      },
    });
    const answer = result.data.answer_evaluation.form_answer && JSON.parse(result.data.answer_evaluation.form_answer.answers);
    const score = result.data.answer_evaluation.answer_evaluation_result;
    setState(prevState => ({
      ...prevState,
      sending: false,
      score,
      answer,
    }));
    const { evaluation } = props.pending_evaluation;

    // Show correct answers and score, or return to the ts page
    const corrected = evaluation.type === 'activity' && score.total > 0;
    if (corrected) {
      setState(prevState => ({ ...prevState, correcting: true, show_score: evaluation.show_score }));
    } else {
      setState(prevState => ({ ...prevState, correcting: true, show_score: false }));
      navigate(backPath());
    }

    fireParentCallback?.();

    // Notify possible parent frame (i.e. Skills) that the answer has been sent.
    window.top.postMessage('EvaluationSubmitted', '*');
  };

  const backPath = () => {
    const baseUrl = `/ts/${props.pending_evaluation.evaluation.training_session.id}`;
    if (props.pending_evaluation.evaluation.module_id) {
      return `${baseUrl}/module/${props.pending_evaluation.evaluation.module_id}`;
    }
    return baseUrl;
  };

  const { evaluation } = props.pending_evaluation;
  const { form } = evaluation;
  const { correcting } = state;
  const inModule = evaluation.module_id !== null;
  const traineeName = getTraineeName(evaluation, evaluation.trainee_name);
  const { previewMode } = props;

  return (
    <div className={`v2_AnswerEvaluationLegacyForm ${correcting ? 'correcting' : ''}`}>
      <>
        <Heading level={2} className='v2_AnswerEvaluationLegacyForm__title'>
          {evaluation.name}
        </Heading>
        {traineeName && (
          <p className='v2_AnswerEvaluationLegacyForm__trainee-name'>
            Apprenant: <span>{traineeName}</span>
          </p>
        )}
      </>
      {state.show_score && renderScore(state.score)}
      <div
        className={clsx('v2_AnswerEvaluationLegacyForm__questions-list', {
          'v2_AnswerEvaluationLegacyForm__questions--correcting': correcting,
        })}
        key={`inputs-${state.key}`}
      >
        {form.inputs.map((formInput, i) => {
          const answer = getInputAnswer(state.answer, formInput.id);
          return (
            <div className='form-input' key={formInput.id} style={styleForInput(i, state.currentPage, evaluation.live, correcting)}>
              {renderFormInput(answer, onInputAnswered, formInput, correcting && evaluation.show_score)}
            </div>
          );
        })}
      </div>
      {!previewMode && !correcting && !state.sending && (
        <IconButton icon='mail' onClick={onAnswer} variant='primary'>
          {t('AnswerEvaluation.send_reply')}
        </IconButton>
      )}
      {!previewMode && !correcting && state.sending && (
        <Button disabled type='text'>
          {t('AnswerEvaluation.sending_reply')}
        </Button>
      )}
      {!previewMode && correcting && !inModule && (
        <IconButton icon='arrow--left' variant='transparent' as='a' href={backPath()}>
          {t('AnswerEvaluation.back_to_session_page')}
        </IconButton>
      )}
      <div className='bottom-score' style={{ position: 'relative', marginTop: 20 }}>
        {evaluation.show_score && renderScore(state.score)}
      </div>
      {!previewMode && correcting && evaluation.replayable && <Button onClick={onRetry}>{t('AnswerEvaluation.retry')}</Button>}
    </div>
  );
}

function renderScore(score) {
  if (score && score.total > 0) {
    const percent = _.round((score.score / score.total) * 100, 1);
    const correct = score.score === score.total;
    return <Score score={score.score} total={score.total} percent={percent} correct={correct} />;
  }
  return null;
}

function renderFormInput(answer, onInputAnswered, formInput, correcting) {
  switch (formInput.type) {
    case 'radio':
      return <FormInputRadio input={formInput} correcting={correcting} answerSent={answer} onAnswer={onInputAnswered} />;
    case 'checkbox':
      return <FormInputCheckbox input={formInput} correcting={correcting} answerSent={answer} onAnswer={onInputAnswered} />;
    case 'free':
      return <FormInputFreeText input={formInput} correcting={correcting} answerSent={answer} onAnswer={onInputAnswered} />;
    case 'rating':
      return <FormInputRating input={formInput} correcting={correcting} answerSent={answer} onAnswer={onInputAnswered} />;
    case 'score':
      return <FormInputScore input={formInput} correcting={correcting} answerSent={answer} onAnswer={onInputAnswered} />;
    case 'matrix':
      return <FormInputMatrix input={formInput} correcting={correcting} answerSent={answer} onAnswer={onInputAnswered} />;
    default:
      return null;
  }
}

function getInputAnswer(answer, inputId) {
  return answer && answer[inputId];
}

function styleForInput(i, currentPage, live, correcting) {
  if (live === true && correcting === false) {
    return {
      display: i + 1 === currentPage ? 'block' : 'none',
    };
  }
  return {};
}

function stateFromPendingEvaluation(pending_evaluation) {
  const { evaluation } = pending_evaluation;
  const nbPages = evaluation.form.inputs.length;
  const answer = pending_evaluation.form_answer && JSON.parse(pending_evaluation.form_answer.answers);
  const score = pending_evaluation.answer_evaluation_result;
  const { show_score } = evaluation;
  const correcting = !pending_evaluation.pending;

  return {
    currentPage: evaluation.current_page,
    nbPages,
    syncWithInstructor: evaluation.sync_with_instructor,
    answer,
    score,
    show_score,
    correcting,
  };
}
