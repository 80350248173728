import React from 'react';
import styles from 'scss/video_conference/AvatarSettings.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import RenderAvatarSettingItem from './RenderAvatarSettingItem';
import useAvatarSettings from 'hooks/video_conference/useAvatarSettings';
import { useParams } from 'react-router';
import { useAuth } from 'contexts/AuthContext';
import { GUEST_TYPE } from 'support/auth';
import { isSafari } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { v4 as uuidV4 } from 'uuid';
import { UPDATE_GUEST_AVATAR_MUTATION } from 'mutations/video_conference';
import {
  FILESTACK_SECURITY_QUERY,
  INSTRUCTORS_ACADEMY_ID_QUERY,
  TRAINEES_ACADEMY_ID_QUERY,
  TRAINEES_QUERY,
} from 'queries/video_conference';
import { AVATAR_CUSTOM, AVATAR_NONE, TYPE_OF_AVATAR } from 'constants/video_conference';
import { filestackDeleteFile, filestackInitializer } from 'components/filestack';
import getFilestackDefaultOptions from 'helpers/video_conference/filestackOptions';
import clsx from 'clsx';

function AvatarSettings({ classes }) {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const { chosenAvatar, setChosenAvatar, currentAvatarSettings } = useAvatarSettings();

  const { training_session_id: trainingSessionId } = useParams();
  const [updateAvatarSettings] = useMutation(UPDATE_GUEST_AVATAR_MUTATION);
  const { data: filestackSecurity } = useQuery(FILESTACK_SECURITY_QUERY, {
    fetchPolicy: 'network-only',
    variables: { handle: getFileHandle(), image_type: TYPE_OF_AVATAR },
  });

  const currentGuestType = _.snakeCase(auth.guestInfo.type);

  const GET_ACADEMY_ID_QUERY =
    snakeGuestType(auth.guestInfo) === GUEST_TYPE.INSTRUCTOR ? INSTRUCTORS_ACADEMY_ID_QUERY : TRAINEES_ACADEMY_ID_QUERY;

  function snakeGuestType(guestInfo) {
    return _.snakeCase(guestInfo.type).toLowerCase();
  }

  function getFileHandle() {
    const customAvatar = currentAvatarSettings.find(({ value }) => value === AVATAR_CUSTOM);
    return customAvatar === undefined ? '' : customAvatar.thumbSrc.split('/').pop();
  }

  const { data, loading } = useQuery(GET_ACADEMY_ID_QUERY, {
    variables: {
      id: auth.guestInfo.id,
    },
    skip: currentGuestType.toLowerCase() === GUEST_TYPE.USER,
  });

  const getStoragePath = () => {
    switch (currentGuestType.toLowerCase()) {
      case GUEST_TYPE.USER:
        return `/users/${auth.guestInfo.id}/`;
      case GUEST_TYPE.INSTRUCTOR:
        return `/users/${data[currentGuestType].user_id}/instructor_logos/${uuidV4()}/`;
      default:
        return `/users/${data[currentGuestType].user_id}/trainees_logos/${uuidV4()}/`;
    }
  };

  const updateCache = (guestType, logo, cache) => {
    let data = cache.readQuery({ query: TRAINEES_QUERY, variables: { id: trainingSessionId } });

    data = updateGivenGuestDataByType(data, logo, guestType);
    cache.writeQuery({ query: TRAINEES_QUERY, variables: { id: trainingSessionId }, data });
  };

  const updateGivenGuestDataByType = (data, logo, guestType) => {
    if (currentGuestType.toLowerCase() === GUEST_TYPE.USER) {
      const updatedAcademy = { ...data.trainingSession.user, academy_extranet_avatar: logo };
      return { ...data, trainingSession: { ...data.trainingSession, user: updatedAcademy } };
    }
    const currentType = guestType.toLowerCase() === GUEST_TYPE.TRAINEE ? 'trainees' : 'instructors';
    const updatedGivenGuests = data.trainingSession[currentType].map(guest => {
      return guest.id === auth.guestInfo.id ? { ...guest, logo: logo } : guest;
    });
    return { ...data, trainingSession: { ...data.trainingSession, [currentType]: updatedGivenGuests } };
  };

  const updateAvatarSettingsAndCache = logo => {
    updateAvatarSettings({
      variables: { guestAvatarConfig: { logo: logo } },
      update: cache => {
        updateCache(auth.guestInfo.type, logo, cache);
      },
    });
  };

  const setAndMutateAvatar = (event, file) => {
    filestackDeleteFile(filestackSecurity, getFileHandle());
    setChosenAvatar(event.target.value);
    let logo = null;
    if (event.target.value !== AVATAR_NONE) {
      logo = { id: uuidV4(), filename: file.filename, url: file.url, size: file.size, mime: file.mimetype };
    }
    updateAvatarSettingsAndCache(logo);
  };

  const pickFile = event => {
    // TODO: event.persist() can be removed once react will be upgraded to v17.
    event.persist();
    event.preventDefault();
    if (!loading) {
      const storagePath = getStoragePath();
      const options = getFilestackDefaultOptions(storagePath);
      filestackInitializer(event, options, setAndMutateAvatar, t);
    }
  };

  const formGroupLegendClasses = clsx(styles.formGroupLegend, { [styles.safari]: isSafari() });
  const formGroupClasses = clsx(styles.formGroup, classes, { [styles.safari]: isSafari });

  return (
    <fieldset className={formGroupClasses}>
      {<legend className={formGroupLegendClasses}>{t('Settings.avatar_settings')}</legend>}
      {currentAvatarSettings.map(avatarSettings => (
        <label key={avatarSettings.value} className={styles.formGroupLabel}>
          <RenderAvatarSettingItem
            avatarSettings={avatarSettings}
            setAndMutateAvatar={setAndMutateAvatar}
            pickFile={pickFile}
            chosenAvatar={chosenAvatar}
          />
        </label>
      ))}
    </fieldset>
  );
}

export default AvatarSettings;

AvatarSettings.propTypes = {
  classes: PropTypes.string,
};
