import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './SignatureFrame.scss';

export function SignatureFrame(props) {
  const { t } = useTranslation();
  const isPending = !props.src;

  return (
    <figure className='SignatureFrame'>
      <figcaption className={clsx('SignatureFrame__name', { 'SignatureFrame__name--pending': isPending })}>{props.name}</figcaption>
      <div className='SignatureFrame__content'>
        {isPending && <p className='SignatureFrame__pending-text'>{t('SignatureFrame.pending_signature')}</p>}
        {!isPending && <img src={props.src} alt={t('SignatureFrame.description', { name: props.name })} />}
      </div>
    </figure>
  );
}

SignatureFrame.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
};
