import React, { useEffect } from 'react';

import ColorPicker from './ColorPicker';
import ColorDemo from './ColorDemo';

import { useThemeColorSetter } from './ThemeContext';

import '../../../scss/mainComponents/customColorChooser/CustomColorChooser.scss';

const CustomColorChooser = ({ color: currentColor = '#000000', notifyColorSaveFn }) => {
  const setCustomColor = useThemeColorSetter();

  useEffect(() => {
    setCustomColor(currentColor);
  }, [currentColor, setCustomColor]);

  const handleColorChanged = color => {
    setCustomColor(color);
  };

  return (
    <div className='custom-color-chooser'>
      <ColorPicker color={currentColor} notifyColorChangeFn={handleColorChanged} notifyColorSaveFn={notifyColorSaveFn} />
      <ColorDemo />
    </div>
  );
};

export default CustomColorChooser;
