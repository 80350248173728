import React, { useReducer, useContext } from 'react';
import { ADD_NOTIFICATIONS, DISMISS_NOTIFICATION, ACTIVATE_NOTIFICATION } from '../../actions/video_conference';

export const NotificationsStateContext = React.createContext();
export const NotificationsDispatchContext = React.createContext();

const reducer = (oldNotifications, { action, payload }) => {
  switch (action) {
    case ADD_NOTIFICATIONS: {
      const { newNotifications } = payload;

      return [...oldNotifications, ...newNotifications];
    }

    case DISMISS_NOTIFICATION: {
      const { notificationId } = payload;

      return oldNotifications.filter(notification => notification.id !== notificationId);
    }

    case ACTIVATE_NOTIFICATION: {
      const { notificationId } = payload;

      return oldNotifications.map(notification => ({
        ...notification,
        active: notification.id === notificationId,
      }));
    }

    default:
      return oldNotifications;
  }
};

export default reducer;

// eslint-disable-next-line import/prefer-default-export
function useNotifications() {
  const [notifications, dispatchNotification] = useReducer(reducer, []);
  return [notifications, dispatchNotification];
}

export function NotificationsProvider({ children }) {
  const [notifications, dispatch] = useNotifications();

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <NotificationsStateContext.Provider value={notifications}>
      <NotificationsDispatchContext.Provider value={dispatch}>{children}</NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  );
}

export function useNotificationsDispatch() {
  return useContext(NotificationsDispatchContext);
}

export function useNotificationsState() {
  return useContext(NotificationsStateContext);
}
