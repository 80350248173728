import {
  CPU_LOAD_CHANGE_EVENT,
  CPU_LOAD_HIGH,
  CPU_LOAD_LOW,
  NETWORK_QUALITY_CHANGE_EVENT,
  NETWORK_QUALITY_DROPPING,
  NETWORK_QUALITY_GOOD,
  NETWORK_QUALITY_LOW,
  NETWORK_QUALITY_VERY_LOW,
  SEND_SETTINGS_FOR_HIGH_QUALITY,
  SEND_SETTINGS_FOR_LOW_QUALITY,
} from 'constants/video_conference';
import { useCallStatusDispatch, useQualityPersistence } from 'hooks/video_conference';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getDetailedProblemIndicators, getLocalConfig } from 'helpers/video_conference';
import { UPDATE_CPU_LOAD, UPDATE_QUALITY } from 'actions/video_conference';
import useParticipantNotification from 'hooks/video_conference/useRtcStats/useParticipationNotification';
import useProblemsStatusBars from 'hooks/video_conference/useProblemsStatusBars';
import { getConfig } from 'config';
import { useDaily, useDailyEvent } from '@daily-co/daily-react';
import * as Sentry from '@sentry/react';

export const QualityMeasuringContext = createContext();

function useInCallQualityMeasuring(callObject) {
  const dispatch = useCallStatusDispatch();

  const { qualityThreshold: localStorageQualityThreshold } = getLocalConfig();

  const [qualityThreshold, setQualityThreshold] = useState(localStorageQualityThreshold || NETWORK_QUALITY_GOOD);
  const [cpuLoadThreshold, setCpuLoadThreshold] = useState(CPU_LOAD_LOW);
  const [bandwidth, setBandwidth] = useState(null);

  const hasPoorNetwork = useMemo(() => [NETWORK_QUALITY_LOW, NETWORK_QUALITY_VERY_LOW].includes(qualityThreshold), [qualityThreshold]);
  const hasHighCpuLoad = useMemo(() => cpuLoadThreshold === CPU_LOAD_HIGH, [cpuLoadThreshold]);
  const isNetworkQualityDropping = qualityThreshold === NETWORK_QUALITY_DROPPING;
  const problemIndicators = getDetailedProblemIndicators({
    problemIndicatorNetwork: hasPoorNetwork,
    problemIndicatorNetworkDropping: isNetworkQualityDropping,
    problemIndicatorCpuLoad: hasHighCpuLoad,
  });

  const updateQualityThreshold = useCallback(
    ({ threshold, quality }) => {
      const updateQuality = threshold => {
        setQualityThreshold(threshold);
        dispatch({
          action: UPDATE_QUALITY,
          payload: {
            threshold,
          },
        });
      };

      if (threshold !== qualityThreshold || quality <= 50) {
        if ([NETWORK_QUALITY_LOW, NETWORK_QUALITY_VERY_LOW].includes(threshold)) {
          updateQuality(threshold);
        } else if (qualityThreshold !== NETWORK_QUALITY_DROPPING) {
          updateQuality(NETWORK_QUALITY_DROPPING);
        }
      }
    },
    [qualityThreshold, dispatch]
  );

  const updateCpuLoadThreshold = useCallback(
    ({ cpuLoadState, cpuLoadStateReason }) => {
      if (cpuLoadState !== cpuLoadThreshold && cpuLoadState === CPU_LOAD_HIGH) {
        setCpuLoadThreshold(cpuLoadState);
        dispatch({
          action: UPDATE_CPU_LOAD,
          payload: {
            threshold: cpuLoadState,
          },
        });

        Sentry.captureMessage('CPU load is high', { extra: { cpuLoadStateReason } });
      }
    },
    [cpuLoadThreshold, dispatch]
  );

  useDailyEvent(NETWORK_QUALITY_CHANGE_EVENT, updateQualityThreshold);
  useDailyEvent(CPU_LOAD_CHANGE_EVENT, updateCpuLoadThreshold);

  useEffect(() => {
    const setQuality = quality => {
      callObject
        .updateSendSettings({
          video: quality,
        })
        .then(sendSettings => {
          setBandwidth(sendSettings.video.encodings.high?.maxBitrate || null);
        });
    };

    setQuality(hasPoorNetwork || hasHighCpuLoad ? SEND_SETTINGS_FOR_LOW_QUALITY : SEND_SETTINGS_FOR_HIGH_QUALITY);
  }, [hasPoorNetwork, hasHighCpuLoad, callObject]);

  useQualityPersistence(qualityThreshold);
  useParticipantNotification(problemIndicators);
  useProblemsStatusBars(problemIndicators);

  if (process.env.NODE_ENV === 'development' || getConfig().env === 'dokku') {
    if (window.roomThrow) {
      throw new Error('Development error');
    }
    if (window.problemIndicators) {
      Object.assign(problemIndicators, {
        ...window.problemIndicators,
      });
    }
  }

  const debugProps = useMemo(() => [`Constraints-requested kbs: ${bandwidth?.kbs || 'N/A'}`], [bandwidth]);

  return { debugProps };
}

export function QualityMeasuringProvider({ children }) {
  const callObject = useDaily();
  const { debugProps } = useInCallQualityMeasuring(callObject);

  return <QualityMeasuringContext.Provider value={debugProps}>{children}</QualityMeasuringContext.Provider>;
}

export function useInCallQualityMeasuringState() {
  return useContext(QualityMeasuringContext);
}
