/**
 * @summary     JQSpeedTest
 * @description jQuery "Plugin" to measure download and upload bandwidth (speed)
 * @version     1.0.1 (13/06/2018)
 * @author      Per Lasse Baasch
 *
 * Features:
 * 	- download speed test
 *  - upload speed test
 *  - response (ping) speed test
 *  - can run mulitple download,upload and ping test as specified
 *  - loop functionality for ongoing tests
 *  - cross-browser support
 *  - works on any webserver (Apache HTTP, Apache Tomcat, IIS, nginx);
 *
 * Requirements:
 *  - An HTTP server that allows GET and POST calls
 *  - jQuery 1.3 or newer
 *
 * For details please refer to:
 * https://skycube.net
 * https://github.com/skycube/jqspeedtest
 */

import $ from 'jquery';

const JQSpeedTest = function (options) {
  //* ************************* Configuration START *********************//
  const defaults = {
    // Callback function for Download output
    testDlCallback: defaultCallbackFunction,
    // Callback function for Upload output
    testUlCallback: defaultCallbackFunction,
    // Callback function for Response output
    testReCallback: defaultCallbackFunction,
    // Callback function for State
    testStateCallback: defaultCallbackFunction,
    // Callback function for the finish
    testFinishCallback: defaultCallbackFunction,
    // Callback function for error
    testErrorCallback: defaultCallbackFunction,
    // Count of Download Samples taken
    countDlSamples: 1,
    // Count of Upload Samples taken
    countUlSamples: 1,
    // Count of Response Samples taken
    countReSamples: 1,
    // Test Image URL (DEFAULT IS testimage.jpg)
    // you may want to replace this with a real url
    testImageUrl: 'testimage.jpg',
    // Upload test URL - must accept POST
    testUploadUrl: '',
    // Latency test URL
    testPingUrl: '',
    // Test Imagee Size (Bytes) (DEFAULT IMAGE IS 4796123=4.8Mb)
    testImageSize: 4796123,
    // Test Upload Size (Bytes) (DEFAULT IS 2500000=2.5Mb)
    testUploadSize: 1000000,
    // Sleep time between tests to cool down a bit (DEFAULT IS 500ms)
    testSleepTime: 500,
  };
  const settings = $.extend({}, defaults, options);
  //* ************************* Configuration END *********************//

  //* * Current State
  let currentState = 'stopped';

  //* * Some Global Vars
  let dlCounts = 0;
  let dlIntervalId = 0;
  let dlTestRunning = 'no';
  let ulCounts = 0;
  let ulIntervalId = 0;
  let ulTestRunning = 'no';
  let reCounts = 0;
  let reIntervalId = 0;
  let reTestRunning = 'no';

  //* * Set the current state var from outside
  this.state = function (state) {
    currentState = state;
    return true;
  };
  // Set the current state var from internal and call a callback function
  function setCurrentState(state) {
    currentState = state;
    typeof settings.testStateCallback === 'function' && settings.testStateCallback(state);
  }

  //* * Get the current state var from outside
  this.getCurrentState = function (state) {
    return currentState;
  };

  //* * First Start
  function init() {
    dlCounts = 0;
    ulCounts = 0;
    reCounts = 0;
    testStart();
  }

  //* * START
  init();

  //* * Internal start and stop function
  function testStart() {
    if (currentState === 'forcestop') {
      setCurrentState('stopped');
      typeof settings.testFinishCallback === 'function' && settings.testFinishCallback('finished');
      return;
    }
    setCurrentState('running');
    if (dlCounts < settings.countDlSamples) {
      if (dlTestRunning === 'no' && ulTestRunning === 'no' && reTestRunning === 'no') {
        dlCounts++;
        dlTestRunning = 'yes';
        setTimeout(() => {
          TestDownload(settings.elDlOutput);
        }, settings.testSleepTime);
      }
      clearTimeout(dlIntervalId);
      dlIntervalId = setTimeout(() => {
        testStart();
      }, 1000);
      return;
    }
    if (ulCounts < settings.countUlSamples) {
      if (dlTestRunning === 'no' && ulTestRunning === 'no' && reTestRunning === 'no') {
        ulCounts++;
        ulTestRunning = 'yes';
        setTimeout(() => {
          TestUpload(settings.elUlOutput);
        }, settings.testSleepTime);
      }
      clearTimeout(ulIntervalId);
      ulIntervalId = setTimeout(() => {
        testStart();
      }, 1000);
      return;
    }
    if (reCounts < settings.countReSamples || settings.countReSamples === 'loop') {
      if (dlTestRunning === 'no' && ulTestRunning === 'no' && reTestRunning === 'no') {
        reCounts++;
        reTestRunning = 'yes';
        setTimeout(() => {
          TestResponse(settings.elReOutput);
        }, settings.testSleepTime);
      }
      clearTimeout(reIntervalId);
      reIntervalId = setTimeout(() => {
        testStart();
      }, 1000);
      return;
    }
    currentState = 'stopped';
    setCurrentState('stopped');
    typeof settings.testFinishCallback === 'function' && settings.testFinishCallback('finished');
  }

  //* * Test the download speed
  function TestDownload() {
    const sendDate = new Date().getTime();
    $.ajax({
      type: 'GET',
      url: settings.testImageUrl,
      timeout: 60000,
      cache: false,
      success() {
        const receiveDate = new Date().getTime();
        const duration = (receiveDate - sendDate) / 1000;
        const bitsLoaded = settings.testImageSize * 8;
        const speedBps = bitsLoaded / duration;
        const speedKbps = speedBps / 1024;
        dlTestRunning = 'no';
        typeof settings.testDlCallback === 'function' && settings.testDlCallback(speedKbps, duration);
      },
      error(jqXHR, textStatus, errorThrown) {
        typeof settings.testErrorCallback === 'function' && settings.testErrorCallback(new Error(`${textStatus} ${errorThrown}`));
      },
    });
  }

  //* * Function to create random string
  function randomString(length) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  //* * Test upload function
  function TestUpload() {
    const randData = { randomDataString: randomString(settings.testUploadSize) };
    const uploadSize = settings.testUploadSize;
    const sendDate = new Date().getTime();
    $.ajax({
      type: 'POST',
      url: settings.testUploadUrl,
      data: randData,
      timeout: 60000,
      cache: false,
      success() {
        const receiveDate = new Date().getTime();
        const duration = (receiveDate - sendDate) / 1000;
        const bitsLoaded = uploadSize * 8;
        const speedBps = bitsLoaded / duration;
        const speedKbps = speedBps / 1024;
        ulTestRunning = 'no';
        typeof settings.testUlCallback === 'function' && settings.testUlCallback(speedKbps, duration);
      },
    });
  }

  //* * Test Response time
  function TestResponse() {
    const sendDate = new Date().getTime();
    $.ajax({
      type: 'HEAD',
      url: settings.testPingUrl,
      timeout: 60000,
      cache: false,
      success() {
        const receiveDate = new Date().getTime();
        const response = receiveDate - sendDate;
        reTestRunning = 'no';
        typeof settings.testReCallback === 'function' && settings.testReCallback(response);
      },
    });
  }

  //* * Default callback function
  function defaultCallbackFunction(value) {}
};

export default JQSpeedTest;
