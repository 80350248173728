import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import './ProgressBar.scss';

export function ProgressBar({ value, className: additionalClassName = '', color, ...htmlAttributes }) {
  const className = clsx('ProgressBar', additionalClassName);
  const style = { '--progressbar-value-color': color };

  return (
    <div role='progressbar' aria-valuenow={value * 100} style={style} className={className} {...htmlAttributes}>
      <svg width='100%' className='ProgressBar__container'>
        <rect width='100%' height='100%' className='ProgressBar__track' />
        <rect width={`${value * 100}%`} height='100%' className='ProgressBar__value' />
      </svg>
    </div>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};
