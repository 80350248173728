export const UPDATE_STATUS = 'UPDATE_STATUS';
export const DEVICES_ENUMERATED = 'DEVICES_ENUMERATED';
export const TOGGLE_SCREEN = 'TOGGLE_SCREEN';
export const TOGGLE_RECORD = 'TOGGLE_RECORD';
export const CURRENT_SPEAKER_CHANGE = 'CURRENT_SPEAKER_CHANGE';
export const NETWORK_MODE_CHANGE = 'NETWORK_MODE_CHANGE';
export const UPDATE_CPU_LOAD = 'UPDATE_CPU_LOAD';
export const UPDATE_QUALITY = 'UPDATE_QUALITY';
export const UPDATE_PARTICIPANT_QUALITY = 'UPDATE_PARTICIPANT_QUALITY';
export const RECORDING_STARTED = 'RECORDING_STARTED';
export const RECORDING_STOPPED = 'RECORDING_STOPPED';
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const ACTIVATE_NOTIFICATION = 'ACTIVATE_NOTIFICATION';
export const CAMERA_STARTED = 'CAMERA_STARTED';
export const TOGGLE_MIC_BUTTON = 'TOGGLE_MIC_BUTTON';
export const TOGGLE_SHOULD_TURN_OFF_MIC = 'TOGGLE_SHOULD_TURN_OFF_MIC';
