import { gql } from '@apollo/client';

export const GUEST_SIGNIN = gql`
  mutation guest_signin($email: String!, $user_code: String!) {
    guestSignin(email: $email, user_code: $user_code) {
      guest_type
      error {
        message
        code
      }
    }
  }
`;
