import React from 'react';
import styles from '../../../../../scss/video_conference/SetUpWizardStep.module.scss';
import MicStep from '../MicStep';
import usePreCallState from 'hooks/video_conference/usePreCall';
import { CONNECTION_TEST_STEP, MIC_STEP, WEBCAM_STEP } from 'constants/video_conference';
import { useDevices } from '@daily-co/daily-react';
import DebugWindow from 'components/video_conference/DebugWindow';
import WebcamStep from '../WebcamStep';
import ConnectionTestStep from '../ConnectionTestStep';
import SetUpWizardButtonTray from './SetUpWizardButtonTray';
import useDebugWindow from 'hooks/useDebugWindow';

function SetUpWizardStep() {
  const { goToNextSetUpStep, currentStepName, setNamedStep, setCompletedStep, completedSteps, debugProps } = usePreCallState();
  const { hasMicError } = useDevices();
  const isConnectionStepCompleted = completedSteps.CONNECTION_TEST_STEP;
  const { isDebugWindowOpen, toggleDebugWindow } = useDebugWindow();

  const handleGoToWebcamStep = () => {
    setNamedStep(WEBCAM_STEP);
  };

  const handleMicStepCompleted = isCompleted => setCompletedStep(MIC_STEP, isCompleted);
  const handleWebcamStepCompleted = isCompleted => setCompletedStep(WEBCAM_STEP, isCompleted);
  const handleConnectionTestStepCompleted = isCompleted => setCompletedStep(CONNECTION_TEST_STEP, isCompleted);

  return (
    <div className={styles.wizard}>
      <SetUpWizardButtonTray hasMicError={hasMicError} completedSteps={completedSteps} />
      <MicStep
        onContinue={goToNextSetUpStep}
        onWebcamSettingsClick={handleGoToWebcamStep}
        onCompleted={handleMicStepCompleted}
        isActive={currentStepName === MIC_STEP}
      />
      <WebcamStep onContinue={goToNextSetUpStep} onCompleted={handleWebcamStepCompleted} isActive={currentStepName === WEBCAM_STEP} />
      <ConnectionTestStep
        onCompleted={handleConnectionTestStepCompleted}
        isCompleted={isConnectionStepCompleted}
        isActive={currentStepName === CONNECTION_TEST_STEP}
      />
      {isDebugWindowOpen && <DebugWindow toggleDebugWindow={toggleDebugWindow} debugProps={debugProps} />}
    </div>
  );
}

export default SetUpWizardStep;
