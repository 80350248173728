import React from 'react';

import EntryButton from './EntryButton';
import ButtonLabel from './ButtonLabel';
import Icon from 'components/mainComponents/Icon';

export const EntrySubmenuButton = props => {
  const { content: Content, notifyEnterSubMenuFn, ...rest } = props;
  const { label } = rest;

  return (
    <EntryButton clickFn={() => notifyEnterSubMenuFn(props)} {...rest}>
      {(Content && <Content />) || (label && <ButtonLabel label={label} />) || null}
      <Icon icon='chevron--right' />
    </EntryButton>
  );
};

export default EntrySubmenuButton;
