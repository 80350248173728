import SigningPad from 'components/attendance/SigningPad';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { localDateFormat } from 'support/date';
import styles from '../../../scss/video_conference/AttendanceSignModal.module.scss';
import { ATTENDANCE_SIGNING } from 'constants/video_conference';
import { useModalState } from 'hooks/video_conference/useModal';
import { useModalDispatch } from 'hooks/video_conference/useModal';
import useAttendanceSigningListener from 'hooks/video_conference/useAttendanceSigningListener';
import { RoomOptsContext } from 'contexts/video_conference';

function AttendanceSignModal() {
  const { training_session_id: id } = useParams();
  const { t } = useTranslation();
  const [isSigned, setIsSigned] = useState(false);
  const { setSignatureDone } = useAttendanceSigningListener();
  const { trainingSessionDates } = useContext(RoomOptsContext);

  const modal = useModalState();
  const { closeModal } = useModalDispatch();
  const isOpen = modal.id === ATTENDANCE_SIGNING;

  // example date object, should be replaced after implementation of attendance signing logic
  const date = trainingSessionDates[0];

  const handleIsSigned = () => {
    setSignatureDone();
    setIsSigned(true);
  };

  if (!isOpen) return null;
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <p className={styles.sectionTitle}>{`${t('AttendanceSignModal.training_session_of')} ${localDateFormat(date.date)}`}</p>
        <p className={styles.sectionTitle}>
          {isSigned ? t('AttendanceSignModal.signature_confirmation') : t('AttendanceSignModal.sign_below')}
        </p>
        {isSigned ? (
          <button className={styles.closeButton} onClick={closeModal}>
            {t('AttendanceSignModal.close')}
          </button>
        ) : (
          <SigningPad id={id} date={date} cancelFn={closeModal} succeedFn={handleIsSigned} />
        )}
      </div>
    </div>
  );
}

export default AttendanceSignModal;
