import React from 'react';
import { useTranslation } from 'react-i18next';

import 'scss/session/TechnicalError.scss';

export default function TechnicalError({ email }) {
  const { t } = useTranslation();

  return <div className='SessionTechnicalError__error-text'>{t('SessionTechnicalError.technical_error')}</div>;
}
