import { useTranslation } from 'react-i18next';

import './TraineeFollowupThread.scss';
import { CommentThread } from 'components/mainComponents/CommentThread';
import { localDateFormat, localTimeFormat } from 'support/date';

export function TraineeFollowupThread(props) {
  const { t } = useTranslation();

  return (
    <div className='TraineeFollowupThread__threads-list'>
      {props.comments.map(comment => (
        <div key={comment.id}>
          <h3 className='TraineeFollowupThread__thread-date'>
            {t('TrainingSessionFollowupPage.TraineeFollowup.comment_date', {
              date: localDateFormat(comment.inserted_at, true),
              time: localTimeFormat(comment.inserted_at, true),
            })}
          </h3>
          <CommentThread comment={comment} trainingSessionId={props.trainingSessionId} />
        </div>
      ))}
    </div>
  );
}
