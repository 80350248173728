import React from 'react';
import useDisplayModeComponent from 'hooks/useDisplayModeComponent';

function CurrentDisplayMode(props) {
  const DisplayModeComponent = useDisplayModeComponent();

  return <DisplayModeComponent {...props} />; // eslint-disable-line
}

export default CurrentDisplayMode;
