import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppPropTypes from 'propTypes';

const TileRendererContext = React.createContext();

export function useCurrentTileRenderer() {
  const ctx = useContext(TileRendererContext);
  if (!ctx) {
    throw new Error('`useCurrentRendererContext` must be used within a `TileRendererContext` provider');
  }

  const { rendererComponent } = ctx;
  return rendererComponent;
}

export function useCurrentTile() {
  const ctx = useContext(TileRendererContext);
  if (!ctx) return null;

  const { tile } = ctx;
  return tile;
}

function TileRenderer({ tile, ...props }) {
  const Renderer = tile.renderer;

  return (
    <TileRendererContext.Provider value={{ rendererComponent: Renderer, tile }}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Renderer tile={tile} {...props} />
    </TileRendererContext.Provider>
  );
}

TileRenderer.propTypes = {
  tile: AppPropTypes.tile.isRequired,
  props: PropTypes.shape({}),
};

export default TileRenderer;
