import { ADD_STALE_PARTICIPANT_ID } from '../../actions/video_conference';

function reduceStaleParticipants(oldState, { action, payload }) {
  switch (action) {
    case ADD_STALE_PARTICIPANT_ID: {
      const { oldSessionId } = payload;

      return {
        ...oldState,
        staleParticipantIds: [...oldState.staleParticipantIds, oldSessionId],
      };
    }

    default:
      return oldState;
  }
}

export default reduceStaleParticipants;
