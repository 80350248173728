import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTileInfo } from 'contexts/video_conference/TileInfoContext';
import useDisplayModeComponent from 'hooks/useDisplayModeComponent';
import useParticipants from 'contexts/video_conference/ParticipantsContext';
import { UPDATE_PARTICIPANT_QUALITY } from 'actions/video_conference';
import { listenMessage } from 'helpers/video_conference/messaging';
import { MESSAGE_PROBLEM_INDICATORS } from 'constants/video_conference';
import useAutoFullscreen from 'hooks/video_conference/useAutoFullscreen';
import AudioTracks from './AudioTracks';
import FullscreenTile from 'components/video_conference/Tile/FullscreenTile';
import IconButton from 'components/video_conference/IconButton';
import CurrentDisplayMode from './CurrentDisplayMode';
import { useCallStatusDispatch } from 'hooks/video_conference';
import TileRenderer from 'components/video_conference/Tile/TileRenderer';
import { useDaily } from '@daily-co/daily-react';
import TileIcon from 'components/video_conference/Tile/TileIcon';
import styles from 'scss/video_conference/FullscreenTile.module.scss';

function CallBox() {
  const { t } = useTranslation();
  const dispatch = useCallStatusDispatch();

  const participants = useParticipants();

  const tileInfo = useTileInfo();

  const callObject = useDaily();

  const displayModeComponent = useDisplayModeComponent();

  useEffect(() => {
    // TODO: figure out whether CallBox is the right place for it.
    // This should likely be moved to a custom hook that deals with either the entire
    // message passing concern, or the problem indication concern.
    listenMessage(callObject, MESSAGE_PROBLEM_INDICATORS, ({ fromId, payload }) => {
      const { problemIndicators } = payload;
      dispatch({
        action: UPDATE_PARTICIPANT_QUALITY,
        payload: {
          participantId: fromId,
          quality: { problemIndicators },
        },
      });
    });
    // eslint-disable-next-line
  }, []);

  const [fullscreenTileId, setFullscreenTileId] = useAutoFullscreen();

  useEffect(() => {
    setFullscreenTileId(null);
  }, [displayModeComponent, setFullscreenTileId]);

  const fullscreenTile = fullscreenTileId && tileInfo.find(tile => tile.sessionId === fullscreenTileId);
  const nonLocalParticipants = participants.filter(({ local }) => !local);

  const renderCurrentDisplayMode = () => <CurrentDisplayMode tiles={tileInfo} enterFullscreen={setFullscreenTileId} />;

  const renderFullscreenTile = () => (
    <TileRenderer
      tile={{
        ...fullscreenTile,
        renderer: FullscreenTile,
      }}
      rightIcons={
        <IconButton
          clickFn={() => setFullscreenTileId(null)}
          title={t('CallBox.exit_fullscreen')}
          iconElement={<TileIcon icon='cross' />}
          className={styles.crossIcon}
        />
      }
    />
  );

  return (
    <>
      <AudioTracks audioTracks={nonLocalParticipants} />

      {fullscreenTile ? renderFullscreenTile() : renderCurrentDisplayMode()}
    </>
  );
}

export default CallBox;
