import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogContent,
} from 'components/mainComponents/AlertDialog';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Button } from 'components/mainComponents/Button';
import { formatSlotDate } from 'support/date';
import { Heading } from 'components/mainComponents/Heading';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { Select } from 'components/mainComponents/Select';
import { SignatureFrame } from 'components/mainComponents/SignatureFrame';
import { SignatureCanvas } from 'components/mainComponents/SignatureCanvas';
import { useAttendanceSignatureMutation } from 'hooks/useAttendanceSignatureMutation';
import { userFullname } from 'controllers/userController';
import useDisclosure from 'hooks/useDisclosure/useDisclosure';
import { withData, withRouteParams } from 'support/page';

import './AttendanceOnSiteSignaturePage.scss';

function AttendanceOnSiteSignaturePage({ trainingSession, trainingSessionSlot }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sessionName = trainingSession.custom_name || trainingSession.name;
  const sessionId = trainingSession.id;
  const subsessionName = trainingSessionSlot.subsession.name;
  const {
    id: slotId,
    date,
    startTime,
    endTime,
    customers,
    trainingSessionInstructors: rawInstructors,
    signatures: rawSignatures,
  } = trainingSessionSlot;
  // this state is for optimistically updating the hasAlreadySigned list
  // it is a temporary solution for a proper refetch mechanism after sign mutation
  const [traineesAndInstructorsNormalized, setTraineesAndInstructorsNormalized] = useState(
    normalizeTraineesAndInstructors({ rawInstructors, customers, rawSignatures })
  );
  const { ids: allIds, traineeIds, instructorIds, entities: traineesAndInstructors } = traineesAndInstructorsNormalized;
  const signedIds = allIds.filter(id => !!traineesAndInstructors[id].signature);

  const [signatoryId, setSignatoryId] = useState('');
  const currentSignatoryIsTrainee = traineesAndInstructors[signatoryId]?.isTrainee;

  const [sign, { loading: signLoading, error: signError }] = useAttendanceSignatureMutation({
    slotId,
    ...(currentSignatoryIsTrainee ? { traineeId: signatoryId } : { instructorId: signatoryId }),
  });
  const subTitle = t('AttendanceOnSiteSignaturePage.subtitle', { date: formatSlotDate({ date, startTime, endTime }) });
  const {
    open: openConfirmSignAgain,
    isOpen: isConfirmSignAgainOpen,
    setIsOpen: setisConfirmSignAgainOpen,
    close: closeConfirmSignAgainOpen,
  } = useDisclosure();
  const signatoryIdBufferRef = useRef();
  return (
    <>
      <AlertDialog open={isConfirmSignAgainOpen} onOpenChange={setisConfirmSignAgainOpen}>
        <AlertDialogContent>{t('AttendanceOnSiteSignaturePage.confirm_signature_message')}</AlertDialogContent>
        <AlertDialogActions>
          <AlertDialogCancel>
            <Button onClick={closeConfirmSignAgainOpen} variant='text'>
              {t('AttendanceOnSiteSignaturePage.confirm_signature_cancel')}
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction>
            <Button onClick={() => setSignatoryId(signatoryIdBufferRef.current)}>
              {t('AttendanceOnSiteSignaturePage.confirm_signature_ok')}
            </Button>
          </AlertDialogAction>
        </AlertDialogActions>
      </AlertDialog>
      <LeftMenuLayout>
        <Breadcrumb>
          <Breadcrumb.Item url={`/ts/${sessionId}`}>{sessionName}</Breadcrumb.Item>
          <Breadcrumb.Item url={`/ts/${sessionId}/attendance`}>
            {t('AttendanceOnSiteSignaturePage.attendance_tracking')}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('AttendanceOnSiteSignaturePage.onsite_signature')}</Breadcrumb.Item>
        </Breadcrumb>
        <section>
          <Heading level={2}>{subsessionName}</Heading>
          <Heading level={3}>{subTitle}</Heading>

          <div className='AttendanceOnSiteSignaturePage__body'>
            <div className='AttendanceOnSiteSignaturePage__signature-form'>
              <Select label={t('AttendanceOnSiteSignaturePage.choose_signatory')} value={signatoryId} onChange={selectSignatory}>
                {instructorIds.length > 0 && (
                  <Select.Group>
                    <Select.GroupTitle>{t('AttendanceOnSiteSignaturePage.instructors')}</Select.GroupTitle>
                    {instructorIds.map(id => {
                      const person = traineesAndInstructors[id];
                      return (
                        <Select.Item rightIcon={person.signature && 'checkmark--circle'} key={id} value={id}>
                          {person.name}
                        </Select.Item>
                      );
                    })}
                  </Select.Group>
                )}
                {traineeIds.length > 0 && (
                  <Select.Group>
                    <Select.GroupTitle>{t('AttendanceOnSiteSignaturePage.trainees')}</Select.GroupTitle>
                    {traineeIds.map(id => {
                      const person = traineesAndInstructors[id];
                      return (
                        <Select.Item rightIcon={person.signature && 'checkmark--circle'} key={id} value={id}>
                          {person.name}
                        </Select.Item>
                      );
                    })}
                  </Select.Group>
                )}
              </Select>

              <SignatureCanvas
                // this allows the pad to reset (clear) if signatoryId changes
                key={signatoryId}
                onCancel={() => navigate(-1)}
                disabled={!signatoryId || signLoading}
                onSave={saveSignature}
              />
              {signError && (
                <p className='AttendanceOnSiteSignaturePage__signError' role='alert'>
                  {t('AttendanceOnSiteSignaturePage.sign_error')}
                </p>
              )}
            </div>

            {signedIds.length > 0 && (
              <aside className='AttendanceOnSiteSignaturePage__signatures-list'>
                <p>{t('AttendanceOnSiteSignaturePage.already_signed')}</p>
                <ul className='AttendanceOnSiteSignaturePage__signatures'>
                  {signedIds.map(id => {
                    const { name, signature } = traineesAndInstructors[id];
                    return (
                      <li key={id} className='AttendanceOnSiteSignaturePage__signature'>
                        <SignatureFrame src={signature} name={name} />
                      </li>
                    );
                  })}
                </ul>
              </aside>
            )}
          </div>
        </section>
      </LeftMenuLayout>
    </>
  );

  function selectSignatory(nextSignatoryId) {
    if (traineesAndInstructors[nextSignatoryId].signature) {
      signatoryIdBufferRef.current = nextSignatoryId;
      return openConfirmSignAgain();
    }
    setSignatoryId(nextSignatoryId);
  }

  async function saveSignature(signature) {
    try {
      await sign(signature);
      setTraineesAndInstructorsNormalized(prev => {
        prev.entities[signatoryId].signature = signature;
        return { ...prev };
      });
      setSignatoryId('');
    } catch (err) {
      console.error(err);
    }
  }
}

const TRAINING_SESSION = gql`
  query TrainingSession($id: ID!) {
    trainingSession(id: $id) {
      id
      name
      custom_name
    }
  }
`;

const TRAINING_SESSION_SLOT = gql`
  query TrainingSessionSlot($id: ID!) {
    trainingSessionSlot(id: $id) {
      id
      subsession {
        name
      }
      customers {
        customerTrainees {
          id
          trainee {
            firstname
            lastname
          }
        }
      }
      trainingSessionInstructors {
        id
        instructor {
          firstname
          lastname
        }
      }
      signatures {
        signature
        customerTrainee {
          id
        }
        trainingSessionInstructor {
          id
        }
      }
      date
      endTime
      startTime
    }
  }
`;

const withGraphqlData = compose(
  graphql(TRAINING_SESSION, {
    name: 'trainingSession',
    options: props => ({
      variables: {
        id: props.params.training_session_id,
      },
    }),
  }),
  graphql(TRAINING_SESSION_SLOT, {
    name: 'trainingSessionSlot',
    options: props => ({
      variables: {
        id: props.params.date,
      },
    }),
  })
);

export default withRouteParams(
  withGraphqlData(withData('trainingSession', 'trainingSessionSlot')(AttendanceOnSiteSignaturePage, { loading: PageLoader }))
);

function normalizeTraineesAndInstructors({ customers, rawInstructors, rawSignatures }) {
  const allTrainees = customers.flatMap(c => c.customerTrainees);
  const rawTraineesAndInstructors = [...allTrainees, ...rawInstructors];

  const ids = [];
  const instructorIds = [];
  const traineeIds = [];

  const entities = rawTraineesAndInstructors.reduce((personEntities, person) => {
    const signatory = person.trainee ?? person.instructor;
    const isTrainee = !!person.trainee;

    const personId = person.id;
    const signature = rawSignatures.find(s => {
      const ownerId = s.customerTrainee?.id ?? s.trainingSessionInstructor?.id;
      return ownerId === personId;
    });

    const personEntity = {
      name: userFullname(signatory),
      isTrainee,
      id: person.id,
      signature: signature?.signature,
    };

    ids.push(personId);
    if (isTrainee) traineeIds.push(personId);
    else instructorIds.push(personId);

    return {
      ...personEntities,
      [personId]: personEntity,
    };
  }, {});
  return { entities, ids, instructorIds, traineeIds };
}
