// Meeting events
export const JOINED_MEETING = 'joined-meeting';
export const LEFT_MEETING = 'left-meeting';
export const MEETING_SESSION_UPDATED = 'meeting-session-updated';
export const STARTED_CAMERA = 'started-camera';

// Participants events
export const PARTICIPANT_JOINED = 'participant-joined';
export const PARTICIPANT_LEFT = 'participant-left';
export const PARTICIPANT_UPDATED = 'participant-updated';

// Recording events
export const RECORDING_ERROR_EVENT = 'recording-error';
export const RECORDING_STARTED_EVENT = 'recording-started';
export const RECORDING_STOPPED_EVENT = 'recording-stopped';
