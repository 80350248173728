import { Navigate, Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import AnswerEvaluationPageV1 from 'components/evaluation/AnswerEvaluationPage';
import EvaluationResultsPage from 'components/evaluation/EvaluationResultsPage';
import FeedbackPage from 'components/video_conference/FeedbackPage';
import VideoConferencePageResolver from 'components/video_conference/VideoConferencePageResolver';
import { useAuth } from 'contexts/AuthContext';
// v2
import './App.scss';
import { AccountInfoProvider } from 'components/user/AccountInfoProvider';
import { AttendancesPage } from 'components/v2/AttendancesPage';
import { AboutPage } from 'components/v2/AboutPage';
import { AttendanceOnSiteSignaturePage } from 'components/v2/AttendanceOnSiteSignaturePage';
import { ColorConfigurationLoader, ColorConfigurationPage } from 'components/v2/ColorConfiguration';
import { TrainingSessionDocumentsPage } from 'components/v2/TrainingSessionDocumentsPage';
import { AnswerEvaluationPage } from 'components/v2/evaluations';
import { AttendanceSingleSignaturePage } from 'components/v2/AttendanceSingleSignaturePage';
import { AccessRequestPage, LogInPage, LogOutPage, PasswordlessValidationPage } from 'components/v2/session';
import { CalendarPage } from 'components/v2/CalendarPage';
import { EvaluationsPage } from 'components/v2/EvaluationsPage';
import { ModulePage } from 'components/v2/ModulePage';
import { ModulesPage } from 'components/v2/ModulesPage';
import { NotFoundPage } from 'components/v2/NotFoundPage';
import { ProgramPage } from 'components/v2/ProgramPage';
import { RootPageDispatcher } from 'components/v2/RootPage';
import { TermsOfServicePage } from 'components/v2/TermsOfServicePage';
import { TrainingSessionAttendanceTrackingPage } from 'components/v2/TrainingSessionAttendanceTrackingPage';
import { TrainingSessionFollowupPage } from 'components/v2/TrainingSessionFollowupPage';
import { TrainingSessionPage } from 'components/v2/TrainingSessionPage';
import { TrainingSessionTraineesPage } from 'components/v2/TrainingSessionTraineesPage';
import { TutorialsPage } from 'components/v2/TutorialsPage';

export function App({ RequireAdmin, RequireNotLogged, RequireAdminOrIntructor }) {
  return (
    <AccountInfoProvider>
      <ColorConfigurationLoader>
        <Routes>
          <Route path='/videoconference-feedback' element={<FeedbackPage />} />
          <Route path='/signin/passwordless_validation' element={<PasswordlessValidationPage />} />
          <Route path='*' element={<NotFoundPage />} />

          <Route element={<RequireNotLogged />}>
            <Route path='login' element={<LogInPage />} />
            <Route path='login/request_access' element={<AccessRequestPage />} />
          </Route>
          <Route element={<RequireLogged />}>
            <Route path='/' element={<RootPageDispatcher />} />
            <Route path='/tos' element={<TermsOfServicePage />} />
            <Route path='/tutorials' element={<TutorialsPage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/logout' element={<LogOutPage />} />
            <Route path='ts/:training_session_id/module/:id/videoconference' element={<VideoConferencePageResolver />} />
            <Route path='ts/:training_session_id/module/:module_id' element={<ModulePage />} />
            <Route path='ts/:training_session_id/modules' element={<ModulesPage />} />
            <Route path='ts/:training_session_id/program' element={<ProgramPage />} />
            <Route path='ts/:training_session_id/trainees' element={<TrainingSessionTraineesPage />} />
            <Route path='ts/:training_session_id/tracking' element={<TrainingSessionFollowupPage />} />
            <Route path='ts/:training_session_id/attendances' element={<AttendancesPage />} />
            <Route path='ts/:training_session_id/attendance/sign/single' element={<AttendanceSingleSignaturePage />} />
            <Route path='ts/:training_session_id/attendance/sign/class' element={<AttendanceOnSiteSignaturePage />} />
            <Route path='ts/:training_session_id/evaluations' element={<EvaluationsPage />} />
            <Route path='ts/:training_session_id/documents' element={<TrainingSessionDocumentsPage />} />
            <Route path='ts/:training_session_id/dates' element={<CalendarPage />} />
            <Route path='ts/:training_session_id' element={<TrainingSessionPage />} />
            <Route path='answer_evaluation/:evaluationId/:traineeId' element={<AnswerEvaluationPage />} />
            <Route path='answer_evaluation/live_v1/:evaluationId/:traineeId' element={<AnswerEvaluationPageV1 />} />
            <Route path='evaluation_results/:id' element={<EvaluationResultsPage />} />
          </Route>
          <Route element={<RequireAdminOrIntructor />}>
            <Route path='ts/:training_session_id/attendance' element={<TrainingSessionAttendanceTrackingPage />} />
          </Route>
          <Route element={<RequireAdmin />}>
            <Route path='/admin_page' element={<ColorConfigurationPage />} />
          </Route>
        </Routes>
      </ColorConfigurationLoader>
    </AccountInfoProvider>
  );
}

const RequireLogged = () => {
  const location = useLocation();
  const { auth } = useAuth();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (token) {
    return (
      <Navigate
        to={`/signin/passwordless_validation?token=${token}&redirect_path=${location.pathname}`}
        state={{ from: location }}
        replace
      />
    );
  }

  if (auth.isLoggedIn) {
    if (auth?.shouldAcceptTOS && location.pathname !== '/tos') {
      return (
        <Navigate
          to='/tos'
          state={{
            from: location,
          }}
          replace
        />
      );
    }
    return <Outlet />;
  }

  return <Navigate to='/login' state={{ from: location }} replace />;
};
