import React from 'react';
import PropTypes from 'prop-types';

import { ActionWidget } from 'components/mainComponents/ActionWidget';
import { useTranslation } from 'react-i18next';

export function AttendancesWidget({ pendingSignaturesCount, children }) {
  const { t } = useTranslation();

  return (
    <ActionWidget
      icon='sign'
      color='alt-2'
      title={t('AttendancesWidget.title')}
      subtitle={t('AttendancesWidget.pending_signatures', {
        count: pendingSignaturesCount,
      })}
    >
      {pendingSignaturesCount > 0 && children}
    </ActionWidget>
  );
}

AttendancesWidget.propTypes = {
  pendingSignaturesCount: PropTypes.number.isRequired,
  children: PropTypes.node,
};
