import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../scss/video_conference/Chat.module.scss';
import { ChildrenPropType } from '../../../propTypes';

const BlinkListItem = React.forwardRef(({ children, className, blinking }, ref) => {
  const blinkClassName = blinking ? styles.blink : '';

  return (
    <li ref={ref} className={[styles.commentListItem, blinkClassName, className].join(' ')}>
      {children}
    </li>
  );
});
BlinkListItem.displayName = 'BlinkListItem';

BlinkListItem.propTypes = {
  ...ChildrenPropType,
  blinking: PropTypes.bool,
  className: PropTypes.string,
};

BlinkListItem.defaultProps = {
  blinking: false,
  className: '',
};

export default BlinkListItem;
