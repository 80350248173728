import { gql } from '@apollo/client';
import { editableComponentsQuery } from 'support/editableComponentsUtils';

export const PENDING_EVALUATION_QUERY = gql`
  query pending_evaluation($id: ID!, $trainee_id: ID!) {
    pending_evaluation(id: $id, trainee_id: $trainee_id) {
      id
      pending
      trainee_name
      answer_evaluation_result {
        score
        total
      }
      form_answer {
        answers
      }
      evaluation {
        read
        editable_components ${editableComponentsQuery({ withQuizzes: true, withBasic: false })}
        id
        name
        type
        live
        module_id
        current_page
        sync_with_instructor
        replayable
        show_score
        training_session {
          id
          name
        }
        form {
          id
          inputs {
            id
            type
            data
          }
        }
      }
    }
  }
`;

export const ANSWER_EVALUATION_MUTATION = gql`
  mutation answer_evaluation($id: ID!, $trainee_id: ID, $answers: String!) {
    answer_evaluation(id: $id, answers: $answers, trainee_id: $trainee_id) {
      id
      pending
      answer_evaluation_result {
        score
        total
      }
      form_answer {
        answers
      }
    }
  }
`;
