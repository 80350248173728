import { DISPLAY_MODE_AUTO } from 'constants/video_conference';
import { SelectedTileProvider } from 'contexts/video_conference/SelectedTileContext';
import { ModalProvider } from 'hooks/video_conference/useModal';
import { PreCallProvider } from 'hooks/video_conference/usePreCall';
import React from 'react';
import PreCallPage from '..';
import DisplayModeProvider from 'components/video_conference/DisplayModeContext';

function PreCallRenderer() {
  return (
    <DisplayModeProvider defaultDisplayMode={DISPLAY_MODE_AUTO}>
      <SelectedTileProvider>
        <PreCallProvider>
          <ModalProvider>
            <PreCallPage />
          </ModalProvider>
        </PreCallProvider>
      </SelectedTileProvider>
    </DisplayModeProvider>
  );
}

export default PreCallRenderer;
