import React, { PureComponent, useState } from 'react';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withTranslation, useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import CommentInput from './CommentInput';
import { deleteComment, deleteSubcomment } from '../../../controllers/comment_controller.js';
import { getGuestType } from '../../../support/auth';
import { compareCommentsByInsertedAtAsc } from '../../../support/sorting';
import { setMomentLocale, getLocale } from 'translations/i18n';
import Linkify from './Linkify';

const nameToNumber = name =>
  Array.from(name)
    .map(c => c.codePointAt())
    .reduce((x, y) => (x + y) % 10);

class Comment extends PureComponent {
  onDelete = async () => {
    if (window.confirm(this.props.t('Comment.are_you_sure_you_want_to_delete_this_message'))) {
      if (this.props.onDelete) {
        this.props.onDelete(this.props.comment.id);
      } else {
        await this.props.mutate({
          variables: { id: this.props.comment.id },
        });
        deleteComment(this.props.client, _.merge(this.props.comment, { training_session_id: this.props.training_session_id }));
      }
    }
  };

  onDeleteSubcomment = async id => {
    await this.props.mutate({
      variables: { id },
    });
    deleteSubcomment(this.props.client, this.props.comment, id);
  };

  render() {
    setMomentLocale(getLocale());
    const { styles, comment, module_id, isSubcomment } = this.props;
    const date = moment.utc(comment.inserted_at).fromNow();
    const subcomments = isSubcomment ? [] : comment.subcomments;

    const specificStyles = {
      comment: isSubcomment ? styles.subcomment : styles.comment,
      author: isSubcomment ? styles.subcommentAuthor : styles.commentAuthor,
      insertedAt: isSubcomment ? styles.subcommentInsertedAt : styles.commentInsertedAt,
      authorName: [
        isSubcomment ? styles.subcommentAuthorName : styles.commentAuthorName,
        styles[`textColorBucket${nameToNumber(comment.author)}`],
      ].join(' '),
      text: isSubcomment ? styles.subcommentText : styles.commentText,
      textInner: isSubcomment ? styles.subcommentTextInner : styles.commentTextInner,
      delete: isSubcomment ? styles.subcommentDelete : styles.commentDelete,
    };

    return (
      <div className={specificStyles.comment}>
        <div className={specificStyles.authorName}>{comment.author}</div>
        <span className={`${specificStyles.insertedAt} ${styles[`textColorBucket${nameToNumber(comment.author)}`]}`}>{date}</span>
        <div className={specificStyles.text}>
          <span className={specificStyles.textInner}>
            <Linkify>{comment.text}</Linkify>
          </span>
        </div>

        {!isSubcomment && (
          <Subcomments
            styles={styles}
            parent_id={comment.id}
            module_id={module_id}
            onDelete={this.onDeleteSubcomment}
            comment={comment}
            subcomments={subcomments}
            foldable={this.props.foldable}
            inputButtonNode={this.props.inputButtonNode}
            hideButtonWhenBlank={this.props.hideButtonWhenBlank}
          />
        )}

        {(getGuestType() === 'user' || getGuestType() === 'instructor') && (
          <button className={specificStyles.delete} onClick={this.onDelete}>
            <i className='icon-close2' />
          </button>
        )}
      </div>
    );
  }
}

function ShowDiscussButton({ title, styles, onClick }) {
  return (
    <div className={styles.showDiscussButton} onClick={onClick}>
      {title}
    </div>
  );
}

function CloseDiscussButton({ title, styles, onClick }) {
  return (
    <div className={styles.hideDiscussButton} onClick={onClick}>
      <div className={styles.hideDiscussButtonText}>{title}</div>
    </div>
  );
}

function Subcomments(props) {
  const { t } = useTranslation();
  const { foldable, subcomments, styles } = props;
  const [folded, setFolded] = useState(subcomments.length === 0);
  const showShowDiscussButton = foldable && folded;
  const showCloseDiscussButton = foldable && !folded;
  const showSubcomments = !foldable || (foldable && !folded);

  const sortedSubcomments = props.subcomments.sort(compareCommentsByInsertedAtAsc);

  const subcommentsClasses = _.compact([
    styles.subcommentsInner,
    !subcomments.length && styles.subcommentsInnerEmpty,
    styles[`borderColorBucket${nameToNumber(props.comment.author)}`],
  ]);

  return (
    <>
      {showShowDiscussButton && <ShowDiscussButton title={t('Comment.discuss')} styles={styles} onClick={() => setFolded(false)} />}
      {showSubcomments && (
        <div className={styles.subcomments}>
          {showCloseDiscussButton && (
            <CloseDiscussButton title={t('Comment.close_discuss')} styles={styles} onClick={() => setFolded(true)} />
          )}
          <div className={subcommentsClasses.join(' ')}>
            <CommentInput
              isSubcomment
              styles={styles}
              parent_id={props.parent_id}
              module_id={props.module_id}
              placeholder={props.placeholder || t('Comment.reply')}
              inputButtonNode={props.inputButtonNode}
              hideButtonWhenBlank={props.hideButtonWhenBlank}
            />

            <ul className={styles.subcommentListInner}>
              {sortedSubcomments.map(c => (
                <li className={styles.subcommentListItem} key={c.id}>
                  <Comment styles={styles} t={t} comment={c} module_id={props.module_id} isSubcomment onDelete={props.onDelete} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

const deleteMutation = gql`
  mutation delete_comment($id: ID!) {
    delete_comment(id: $id)
  }
`;

export default withTranslation()(withApollo(graphql(deleteMutation)(Comment)));
