import React, { Suspense } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withData, withRouteParams } from '../../support/page';
import { ActionButton } from 'components/mainComponents/buttons';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';

import '../../scss/EvaluationResultsPage.scss';

const FormStats = React.lazy(() => import('./FormStats'));

const EvaluationResultsPage = ({ evaluation }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFinished = () => {
    navigate(`/ts${evaluation.training_session.id}`);
  };

  return (
    <div className='page EvaluationResultsPage'>
      <main>
        <div className='buttons'>
          <p>Résultats</p>
          <ActionButton label={t('EvaluationResultsPage.end_activity')} clickFn={onFinished} />
        </div>

        <Suspense fallback={<div>t('EvaluationResultsPage.load_results')</div>}>
          <FormStats form={evaluation.form} />
        </Suspense>
      </main>
    </div>
  );
};

const query = gql`
  query evaluation_result_page($id: ID!) {
    evaluation(id: $id) {
      id
      name
      module_id
      training_session {
        id
        name
      }
      form {
        id
        inputs {
          id
          type
          data
        }
        form_answers {
          id
          answers
        }
      }
    }
  }
`;

const withGraphqlData = graphql(query, {
  name: 'evaluation',
  options: props => {
    return {
      variables: { id: parseInt(props.params.id, 10) },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('evaluation')(
        EvaluationResultsPage, { loading: LoadingPagePlaceholder })));
