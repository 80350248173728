import React, { useCallback } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import { Illustration } from 'components/mainComponents/Illustration';
import { CommonPagesLayout } from 'components/v2/layouts';
import { Paper } from 'components/mainComponents/Paper';

import './LogOutPage.scss';

export function LogOutPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signout } = useAuth();

  const logout = useCallback(() => {
    signout();
    navigate('/');
  }, [signout, navigate]);

  return (
    <CommonPagesLayout>
      <Paper className='V2__LogOutPage'>
        <Heading level={2} className='V2__LogOutPage__title'>
          {t('LogOutPage.disconnection')}
        </Heading>
        <Illustration name='robot-bye' size='lg' />
        <Heading as='h3' level={3}>
          {t('LogOutPage.are_you_sure_disconnect')}
        </Heading>
        <div className='V2__LogOutPage__buttons'>
          <Button onClick={() => navigate(-1)} variant='text'>
            {t('LogOutPage.cancel')}
          </Button>
          <Button onClick={logout}>{t('LogOutPage.logout')}</Button>
        </div>
      </Paper>
    </CommonPagesLayout>
  );
}
