import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './PasswordlessValidationPage.scss';
import { PASSWORDLESS_VALIDATION } from './queries';
import { Button } from 'components/mainComponents/Button';
import { Heading } from 'components/mainComponents/Heading';
import { Illustration } from 'components/mainComponents/Illustration';
import { useAuth } from 'contexts/AuthContext';
import { withRouteParams } from 'support/page';

function PasswordlessValidationPageWithData(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth, signin } = useAuth();
  const {
    params: { token, redirect_path = '/' },
  } = props;
  const [validateToken, tokenValidation] = useMutation(PASSWORDLESS_VALIDATION);

  useEffect(() => {
    (async () => {
      await validateToken({
        variables: {
          token,
          user_code: auth.userCode,
        },
      });
    })();
  }, [auth.userCode, validateToken, token]);

  useEffect(() => {
    const answer = tokenValidation.data?.passwordlessValidation;
    if (answer?.guest) {
      signin(answer.guest, answer.token, answer.switch_guest_infos);
      navigate(redirect_path);
    }
  }, [signin, tokenValidation.data, navigate, redirect_path]);

  return (
    <div className='v2_PasswordlessValidationPage'>
      <Heading level={1}>{t('Session.access_control')}</Heading>
      <Heading level={2}>{t('Session.guest_access')}</Heading>
      {tokenValidation.loading && <div>{t('Session.processing_access')}</div>}
      {tokenValidation.error && (
        <PageContent illustration='robot-disappointed' title={t('Session.error_title')}>
          <p>{t('Session.technical_error')}</p>
        </PageContent>
      )}
      {tokenValidation.data?.passwordlessValidation?.error && (
        <PageContent illustration='robot-disappointed' title={t('Session.expired_token')}>
          <p>{t('Session.login_invite')}</p>
          <Button as='a' href='/login'>
            {t('Session.go_to_login')}
          </Button>
        </PageContent>
      )}
    </div>
  );

  function PageContent(props) {
    return (
      <>
        <Illustration name={props.illustration} size='lg' />
        <Heading level={3}>{props.title}</Heading>
        {props.children}
      </>
    );
  }
}

export const PasswordlessValidationPage = withRouteParams(PasswordlessValidationPageWithData);
