import useParticipants from 'contexts/video_conference/ParticipantsContext';
import useSignatureList from 'hooks/video_conference/useSignatureList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../scss/video_conference/StatusBar.module.scss';

function SignatureListStatusBar() {
  const { t } = useTranslation();
  const participants = useParticipants();
  const [signedParticipants] = useSignatureList();

  const renderSignatureListButton = () => {
    return <button className={styles.barButton}>{t('SignatureListStatusBar.button')}</button>;
  };

  if (!signedParticipants.length) {
    return t('SignatureListStatusBar.noSignedParticipants');
  } else if (signedParticipants.length < participants.length) {
    return (
      <>
        {t('SignatureListStatusBar.signedParticipants', {
          signedParticipantsCount: signedParticipants.length,
          allParticipantsCount: participants.length,
        })}
        {renderSignatureListButton()}
      </>
    );
  }
  return (
    <>
      {t('SignatureListStatusBar.allSignedParticipants')}
      {renderSignatureListButton()}
      <button className={styles.barButton}>{t('SignatureListStatusBar.closeButton')}</button>
    </>
  );
}

export default SignatureListStatusBar;
