import { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { VIDEO_CALL_CONFIG_MUTATION } from 'mutations/video_conference';
import _ from 'lodash';

function useConfigPersistence() {
  const [mutateSettings, { error }] = useMutation(VIDEO_CALL_CONFIG_MUTATION);

  useEffect(() => error && alert(error), [error]);

  return useCallback(
    (videoCallConfig, opts = { localOnly: false }) => {
      const { localOnly } = opts;

      if (!localOnly) {
        // Temporary solution - waiting for adding graphql missing fields
        mutateSettings({
          variables: {
            videoCallConfig: _.omit(videoCallConfig, [
              'backgroundEffect',
              'isCameraMuted',
              'isMicMuted',
              'videoResolution',
              'videoQualityThreshold',
            ]),
          },
        });
      }

      localStorage.setItem('videoCallConfig', JSON.stringify(videoCallConfig));
    },
    [mutateSettings]
  );
}

export default useConfigPersistence;
