import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import mapbox from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Icon from 'components/mainComponents/Icon';

import './Mapbox.scss';

mapbox.accessToken = 'pk.eyJ1IjoiYXdvcmxkZm9ydXMiLCJhIjoiY2o4Yno3OW5sMDMwbDMzbXRzYjg2cDR1MSJ9.uZqHPlJpWiRTgEbBoVN68A';

export function Mapbox(props) {
  const geoMapContainer = useRef(null);
  const geoMap = useRef(null);
  const markerElement = useRef(null);
  const markerObject = useRef(null);

  useEffect(() => {
    if (geoMap.current) return;

    geoMap.current = new mapbox.Map({
      container: geoMapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: props.coordinates,
      zoom: 13,
      attributionControl: false,
    });
  }, [props.coordinates]);

  // Recenter the map
  useEffect(() => {
    if (!geoMap.current) return;

    geoMap.current.easeTo({
      center: props.coordinates,
    });
  }, [props.coordinates]);

  // Add, remove, or reposition marker
  useEffect(() => {
    if (!geoMap.current) return;

    if (markerElement.current) {
      markerObject.current && markerObject.current.remove();
      markerObject.current = new mapbox.Marker(markerElement.current).setLngLat(props.coordinates).addTo(geoMap.current);
    }

    if (props.showMarker) {
      markerObject.current.addTo(geoMap.current);
    } else {
      markerObject.current.remove();
    }
  }, [props.showMarker, props.coordinates]);

  return (
    <div className='Mapbox' ref={geoMapContainer}>
      <div ref={markerElement}>
        <Icon icon='location--filled' />
      </div>
    </div>
  );
}

Mapbox.propTypes = {
  coordinates: PropTypes.shape({
    lon: PropTypes.number,
    lat: PropTypes.number,
  }),
  showMarker: PropTypes.bool,
};

Mapbox.defaultProps = {
  showMarker: true,
};
