import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useCallStatusState } from 'hooks/video_conference';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';
import styles from 'scss/video_conference/RemoteParticipant.module.scss';
import { RoomOptsContext } from 'contexts/video_conference';
import { participantContent } from './utils';
import PropTypes from 'prop-types';
import Avatar from 'components/video_conference/Avatar';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import { NETWORK_STATS_ELEMENT, PARTICIPANT_ELEMENT } from 'constants/video_conference/roomElements';
import { VideoConfSvgIcon } from 'components/video_conference/VideoConfSvgIcon/VideoConfSvgIcon';

function RemoteParticipant({ participant }) {
  const { t } = useTranslation();
  const { usersData } = useContext(RoomOptsContext);
  const callStatus = useCallStatusState();
  const { currentSpeakerId } = useCurrentSpeaker();
  const { participantQuality } = callStatus;
  const { roomElements, openRoomElement, closeRoomElement } = useRoomElementsDisplay();

  const { userName, sessionId } = participant;

  const firstName = userName.split(' ')[0];

  const participantQualityVerdict = participantQuality[sessionId] || {};

  const { imageUrl } = useMemo(() => participantContent(participant, usersData), [participant, usersData]);

  const isActive = currentSpeakerId === sessionId;

  const problemsPresent = _.some(participantQualityVerdict.problemIndicators);

  const participantStyles = `${styles.RemoteParticipant} ${isActive ? styles.active : ''}`;

  const handleParticipantClick = () => {
    if (roomElements.includes(NETWORK_STATS_ELEMENT)) closeRoomElement(NETWORK_STATS_ELEMENT);
    openRoomElement(PARTICIPANT_ELEMENT, participant);
  };

  function renderAvatar() {
    return (
      <>
        {problemsPresent && (
          <VideoConfSvgIcon
            name='problemIndicator'
            className={styles.avatarProblemIndicator}
            title={t('NewVideoConferencePage.problem')}
          />
        )}

        <Avatar src={imageUrl} userName={userName} icon='user' shape='square' />

        <span className={styles.avatarCaption} />
        <p className={styles.avatarName}>{firstName}</p>
      </>
    );
  }

  return (
    <div className={participantStyles} onClick={handleParticipantClick}>
      <div className={styles.avatar}>{renderAvatar()}</div>
    </div>
  );
}

export default RemoteParticipant;

RemoteParticipant.propTypes = {
  participant: PropTypes.shape({
    userName: PropTypes.string,
    audio: PropTypes.bool,
    video: PropTypes.bool,
    screen: PropTypes.bool,
    sessionId: PropTypes.string,
  }).isRequired,
};
