import React from 'react';
import { useQuery } from '@apollo/client';
import { useAuth } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { COMPANY_ROOT_PAGE, FUNDING_AGENCY_ROOT_PAGE, INSTRUCTOR_ROOT_PAGE, TRAINEE_ROOT_PAGE } from './queries';
import { GUEST_TYPE } from 'support/auth';

import { CommonPagesLayout } from 'components/v2/layouts/CommonPagesLayout';
import { Heading } from 'components/mainComponents/Heading';
import { localDateFormat } from 'support/date';
import { Paper } from 'components/mainComponents/Paper';
import { PageLoader } from 'components/v2/layouts/PageLoader';

import './RootPage.scss';

export function RootPageDispatcher(props) {
  const { auth } = useAuth();
  switch (auth?.guestInfo?.type) {
    case GUEST_TYPE.FUNDING_AGENCY:
      return <RootPage query={FUNDING_AGENCY_ROOT_PAGE} guestId={auth?.guestInfo.id} guest={GUEST_TYPE.FUNDING_AGENCY} {...props} />;
    case GUEST_TYPE.INSTRUCTOR:
      return <RootPage query={INSTRUCTOR_ROOT_PAGE} guestId={auth?.guestInfo.id} guest={GUEST_TYPE.INSTRUCTOR} {...props} />;
    case GUEST_TYPE.COMPANY:
      return <RootPage query={COMPANY_ROOT_PAGE} guestId={auth?.guestInfo.id} guest={GUEST_TYPE.COMPANY} {...props} />;
    default:
      return <RootPage query={TRAINEE_ROOT_PAGE} guestId={auth?.guestInfo.id} guest={GUEST_TYPE.TRAINEE} {...props} />;
  }
}

function RootPage(props) {
  const { t } = useTranslation();
  const { query, guestId, guest } = props;
  const { loading, data } = useQuery(query, {
    variables: { id: guestId },
  });

  if (loading) return <PageLoader />;
  if (data) {
    const trainingSessions = data[guest].training_sessions;

    return (
      <CommonPagesLayout className='RootPage'>
        <div className='RootPage__tsList'>
          {trainingSessions.length === 0 && <Heading>{t('RootPage.no_session')}</Heading>}
          {trainingSessions.map(ts => {
            return (
              <Paper noPadding key={ts.id} as='a' href={`ts/${ts.id}`}>
                <div className='RootPage__item-img' style={trainingSessionStyle(ts)}>
                  <p className='RootPage__item-name'>{ts.custom_name || ts.name}</p>
                </div>
                <div className='RootPage__item-body'>
                  <p className='RootPage__item-date'>{formatDate(t, ts.start_date)}</p>
                </div>
              </Paper>
            );
          })}
        </div>
      </CommonPagesLayout>
    );
  }
}

function formatDate(t, startDate) {
  if (startDate) return localDateFormat(startDate);
  return t('RootPage.no_date');
}

function trainingSessionStyle(ts) {
  if (ts.image) {
    return {
      backgroundImage: `url(${ts.image.url})`,
    };
  }
}
