import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import enTranslations from './en.json';
import frTranslations from './fr.json';
import esTranslations from './es.json';
import deTranslations from './de.json';

import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';

const LOCALE_KEY = 'i18nextLng';

export const languageCode = Object.freeze({
  english: 'en',
  french: 'fr',
  spanish: 'es',
  german: 'de',
});
const languageCodes = Object.keys(languageCode);

// the translations
const resources = {
  [languageCode.english]: {
    default: enTranslations,
  },
  [languageCode.french]: {
    default: frTranslations,
  },
  [languageCode.spanish]: {
    default: esTranslations,
  },
  [languageCode.german]: {
    default: deTranslations,
  },
};

const instance = i18n.createInstance();

instance.on('languageChanged', setMomentLocale);
instance.on('languageChanged', updateHtmlLang);

instance.use(LanguageDetector).init(
  {
    resources,
    languages: languageCodes,
    fallbackLng: languageCode.french,
    ns: ['default'],
    defaultNS: 'default',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    debug: true,
  },
  (err, t) => {
    if (err) return console.error('MomentJS initialization failed', err);
  }
);

export default instance;

function updateHtmlLang(locale) {
  document.documentElement.lang = locale;
}

export function setMomentLocale(locale) {
  console.log('Moment locale set to', locale);
  moment.locale(locale);
}

export function setLocale(locale) {
  instance.changeLanguage(locale);
}

export function getLocale() {
  return localStorage.getItem(`${LOCALE_KEY}`);
}

export function clearLocale() {
  localStorage.removeItem(`${LOCALE_KEY}`);
}
