import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'scss/video_conference/Settings.module.scss';
import { useSettingsPersistence } from 'hooks/video_conference';
import {
  BACKGROUND_BLUR,
  BACKGROUND_BLUR_TYPE,
  BACKGROUND_CAFE,
  BACKGROUND_CUSTOM,
  BACKGROUND_IMAGE_TYPE,
  BACKGROUND_NONE,
  BACKGROUND_NONE_TYPE,
  BACKGROUND_SPACE,
  BACKGROUND_UPLOAD,
  GRANTED_STATE,
  VIDEOCALL_SETTINGS_MODAL,
} from 'constants/video_conference';
import BarVolumeIndicator from 'components/video_conference/VolumeIndicator/BarVolumeIndicator';
import { useAudioTrack, useDaily, useDevices, useLocalParticipant } from '@daily-co/daily-react';
import BackgroundBlur from 'components/video_conference/images/background-blur.jpg';
import BackgroundCafe from 'components/video_conference/images/background-cafe.jpg';
import BackgroundSpace from 'components/video_conference/images/background-space.jpg';
import BackgroundSettings from './BackgroundSettings';
import AvatarSettings from './AvatarSettings';
import useBackgroundSettings from 'hooks/video_conference/useBackgroundSettings';
import { updateBackgroundImage } from 'helpers/video_conference';
import Modal from 'components/video_conference/Modal';
import Dropdown from 'components/video_conference/Dropdown';
import useDevicesToggle from 'hooks/video_conference/useDevicesToggle';

export const backgroundEffectOptions = [
  {
    value: BACKGROUND_NONE,
    labelKey: 'Settings.background_none',
    icon: 'user',
    type: BACKGROUND_NONE_TYPE,
    config: {},
  },
  {
    value: BACKGROUND_UPLOAD,
    labelKey: 'Settings.background_upload',
    icon: 'upload',
    type: BACKGROUND_IMAGE_TYPE,
    config: { source: '' },
    inputType: 'file',
  },
  {
    value: BACKGROUND_BLUR,
    labelKey: 'Settings.background_blur',
    thumbSrc: BackgroundBlur,
    type: BACKGROUND_BLUR_TYPE,
    config: { strength: 0.5 },
  },
  {
    value: BACKGROUND_SPACE,
    labelKey: 'Settings.background_space',
    thumbSrc: BackgroundSpace,
    type: BACKGROUND_IMAGE_TYPE,
    config: { source: `${window.location.origin}/images/background-space.jpg` },
  },
  {
    value: BACKGROUND_CAFE,
    labelKey: 'Settings.background_cafe',
    thumbSrc: BackgroundCafe,
    type: BACKGROUND_IMAGE_TYPE,
    config: { source: `${window.location.origin}/images/background-cafe.jpg` },
  },
  {
    value: BACKGROUND_CUSTOM,
    labelKey: 'Settings.background_custom',
    thumbSrc: '',
    type: BACKGROUND_IMAGE_TYPE,
    config: { source: '' },
  },
];

function Settings() {
  const { t } = useTranslation();
  const { microphones, cameras, speakers, setMicrophone, setCamera, setSpeaker, micState, camState } = useDevices();
  const { chosenBackground, setChosenBackground, currentBackgroundSettings } = useBackgroundSettings();
  const localParticipant = useLocalParticipant();
  const callObject = useDaily();
  const localAudio = useAudioTrack(localParticipant?.session_id);

  const [selectedMic, setSelectedMic] = useState(null);
  const [selectedCam, setSelectedCam] = useState(null);
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);

  const persistSetting = useSettingsPersistence();

  const generateOptions = devices =>
    devices.map(({ device: { deviceId, label } }) => ({
      value: deviceId,
      label,
    }));

  const handleChangeMic = newAudioDeviceId => {
    setMicrophone(newAudioDeviceId);
    persistSetting('audioDeviceId', newAudioDeviceId);
    setSelectedMic(newAudioDeviceId);
  };

  const handleChangeCam = newVideoDeviceId => {
    setCamera(newVideoDeviceId);
    persistSetting('videoDeviceId', newVideoDeviceId);
    setSelectedCam(newVideoDeviceId);
  };

  const handleChangeOutput = newOutputDeviceId => {
    setSpeaker(newOutputDeviceId);
    persistSetting('outputDeviceId', newOutputDeviceId);
    setSelectedSpeaker(newOutputDeviceId);
  };

  const { toggleMic, isMicMuted } = useDevicesToggle();

  const handleBackgroundEffectChange = ({ target: { value: newBackgroundEffect } }, customBackgroundUrl) => {
    let chosenBackground = currentBackgroundSettings.find(option => option.value === newBackgroundEffect);
    if (newBackgroundEffect === BACKGROUND_UPLOAD)
      chosenBackground = {
        ...chosenBackground,
        config: { source: customBackgroundUrl },
      };
    updateBackgroundImage(chosenBackground, persistSetting, callObject);
  };

  useEffect(() => {
    if (micState === GRANTED_STATE && !selectedMic) {
      setSelectedMic(microphones?.find(mic => mic.selected)?.device?.deviceId);
    }
  }, [micState, microphones, selectedMic]);

  useEffect(() => {
    if (camState === GRANTED_STATE && !selectedCam) {
      setSelectedCam(cameras?.find(cam => cam.selected)?.device?.deviceId || cameras[0]?.device?.deviceId);
    }
  }, [camState, cameras, selectedCam]);

  useEffect(() => {
    if (speakers?.length && !selectedSpeaker) {
      setSelectedSpeaker(speakers?.find(speaker => speaker.selected)?.device?.deviceId);
    }
  }, [speakers, selectedSpeaker]);

  return (
    <Modal modalId={VIDEOCALL_SETTINGS_MODAL}>
      <form>
        {speakers?.length ? (
          <Dropdown
            options={generateOptions(speakers)}
            value={selectedSpeaker}
            onChange={handleChangeOutput}
            name='audioOutputs'
            label={t('Settings.output_device')}
            className={styles.setting}
          />
        ) : null}
        <div>
          {microphones?.length ? (
            <Dropdown
              options={generateOptions(microphones)}
              value={selectedMic}
              onChange={handleChangeMic}
              name='audioInputs'
              label={t('Settings.microphone')}
              className={styles.setting}
            />
          ) : (
            <small>{t('Settings.no_mic')}</small>
          )}
          {isMicMuted || !localAudio.track ? (
            <button className={styles.link} type='button' onClick={toggleMic}>
              <small>{t('Settings.turn_mic_on')}</small>
            </button>
          ) : (
            <BarVolumeIndicator audioTrack={localAudio.track} styles={styles} horizontal />
          )}
        </div>

        {cameras?.length ? (
          <Dropdown
            value={selectedCam}
            onChange={handleChangeCam}
            options={generateOptions(cameras)}
            name='cams'
            label={t('Settings.camera')}
            className={styles.setting}
          />
        ) : (
          <small>{t('Settings.no_cam')}</small>
        )}

        <AvatarSettings classes={styles.setting} />

        <BackgroundSettings
          options={backgroundEffectOptions}
          onChange={handleBackgroundEffectChange}
          setChosenBackground={setChosenBackground}
          chosenBackground={chosenBackground}
          currentBackgroundSettings={currentBackgroundSettings}
          withLabels
          classes={styles.backgroundSettings}
        />
      </form>
    </Modal>
  );
}

export default Settings;
