import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useLocation, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Vex from 'vex-js';
import { default as VexDialog } from 'vex-dialog';
import FeedbackPage from 'components/video_conference/FeedbackPage';
import { getConfig } from './config';

import AdminPage from 'components/admin';
import SigninPage from './components/session/SigninPage';
import AccessRequestPage from './components/session/AccessRequestPage';
import PasswordlessValidationPage from 'components/session/PasswordlessValidationPage';
import TraineeRootPage from './components/trainee/TraineeRootPage';
import InstructorRootPage from './components/instructor/InstructorRootPage';
import CompanyRootPage from './components/company/CompanyRootPage';
import FundingAgencyRootPage from './components/funding_agency/FundingAgencyRootPage';
import TrainingSessionPage from './components/training_session/TrainingSessionPage';
import TrainingSessionProgramPage from './components/program/TrainingSessionProgramPage';
import TrainingSessionTraineesPage from './components/trainees/TrainingSessionTraineesPage';
import TrainingSessionTrackingPage from './components/tracking/TrainingSessionTrackingPage';
import TrainingSessionAttendancePage from './components/attendance/TrainingSessionAttendancePage';
import ModulePage from './components/module/ModulePage';
import ModulesPage from './components/module/ModulesPage';
import AnswerEvaluationPage from './components/evaluation/AnswerEvaluationPage';
import EvaluationPage from 'components/evaluation/EvaluationPage';
import DocumentPage from 'components/document/DocumentPage';
import EvaluationResultsPage from './components/evaluation/EvaluationResultsPage';
import Error404Page from './components/Error404Page';
import EmptyPage from './components/session/EmptyPage';
import AboutPage from './components/AboutPage';
import AttendanceInClassSignPage from './components/attendance/AttendanceInClassSignPage';
import AttendanceSingleSignPage from './components/attendance/AttendanceSingleSignPage';
import TermsOfServicePage from './components/legal/TermsOfServicePage';
import TutorialsPage from 'components/TutorialsPage';
import AttendancesSignPage from './components/attendance/AttendancesSignPage.js';
import DatesPage from 'components/dates/DatesPage';
import LogOutPage from 'components/LogOutPage';
import VirtualRoomPage from 'components/module/VirtualRoomPage';
import { V2ActivationDialog, UIv2Guard } from 'components/v2Activation';
import { useAuth } from 'contexts/AuthContext';

import { getUserCode, getGuestInfo } from './support/auth';

import VideoConferencePageResolver from './components/video_conference/VideoConferencePageResolver';
import RootErrorBoundary from './components/RootErrorBoundary';
import { RootErrorBoundary as RootErrorBoundaryV2 } from 'components/v2/RootErrorBoundary';

import 'scss/App.scss';
import { Navigate } from 'react-router-dom/dist';
import { App as RoutesV2 } from 'components/v2/App';
import { Providers } from './Providers';

// Vex config
//
Vex.defaultOptions.className = 'vex-theme-flat-attack';
Vex.registerPlugin(VexDialog);
window.vex = Vex;

//----------------------------------------------------------------------------------------------------------------------------
// Sentry
//----------------------------------------------------------------------------------------------------------------------------
window.config = getConfig();

const sentrySettings = {};

if (process.env.REACT_APP_SENTRY_RELEASE) {
  sentrySettings.release = process.env.REACT_APP_SENTRY_RELEASE;
  window._release_version = sentrySettings.release;
}

if (process.env.REACT_APP_SENTRY_ENV) {
  sentrySettings.environment = process.env.REACT_APP_SENTRY_ENV;
  window._deploy_env = sentrySettings.environment;
}

if (process.env.REACT_APP_SENTRY_DSN) {
  sentrySettings.dsn = process.env.REACT_APP_SENTRY_DSN;
} else if (window.config.env === 'prod') {
  sentrySettings.dsn = 'https://d1c31551e1e84ad0b605991c73ace1c8@o61992.ingest.sentry.io/293905';
}

window.Sentry = Sentry;

if (sentrySettings.dsn) Sentry.init(sentrySettings);

//----------------------------------------------------------------------------------------------------------------------------
// App
//----------------------------------------------------------------------------------------------------------------------------
function App() {
  useEffect(() => {
    if (getUserCode() === 'www' && window.location.pathname !== '/home') {
      window.location.pathname = '/home';
    }
  }, []);

  const guestInfo = getGuestInfo();

  if (guestInfo) {
    delete guestInfo.__typename;
    Sentry.setUser({ ...guestInfo, userCode: getUserCode() });
  }

  return (
    <Providers>
      <UIv2Guard>
        {({ isV2Active, isV2Activable }) => {
          if (process.env.NODE_ENV === 'development') {
            console.log(`LMS version : ${isV2Active ? 2 : 1}`);
          }
          if (isV2Active)
            return (
              <RootErrorBoundaryV2>
                <RoutesV2
                  RequireAdmin={RequireAdmin}
                  RequireAdminOrIntructor={RequireAdminOrIntructor}
                  RequireLogged={RequireLogged}
                  RequireNotLogged={RequireNotLogged}
                  RootPage={RootPage}
                />
              </RootErrorBoundaryV2>
            );

          return (
            <RootErrorBoundary>
              <V2ActivationDialog isActivable={isV2Activable}>
                <RoutesV1 />
              </V2ActivationDialog>
            </RootErrorBoundary>
          );
        }}
      </UIv2Guard>
    </Providers>
  );
}

function RoutesV1() {
  return (
    <Routes>
      <Route path='/home' element={<EmptyPage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/tutorials' element={<TutorialsPage />} />
      <Route path='/videoconference-feedback' element={<FeedbackPage />} />
      <Route path='/signin/passwordless_validation' element={<PasswordlessValidationPage />} />
      <Route path='*' element={<Error404Page />} />

      <Route element={<RequireNotLogged />}>
        <Route path='signin' element={<SigninPage />} />
        <Route path='signin/request_access' element={<AccessRequestPage />} />
      </Route>

      <Route element={<RequireLogged />}>
        <Route path='/' element={<RootPage />} />
        <Route path='tos' element={<TermsOfServicePage />} />
        <Route path='ts/:training_session_id/module/:id/virtual_room' element={<VirtualRoomPage />} />
        <Route path='ts/:training_session_id/module/:id/videoconference' element={<VideoConferencePageResolver />} />
        <Route path='ts/:training_session_id/module/:id' element={<ModulePage />} />
        <Route path='ts/:training_session_id/modules' element={<ModulesPage />} />
        <Route path='ts/:training_session_id/program' element={<TrainingSessionProgramPage />} />
        <Route path='ts/:training_session_id/trainees' element={<TrainingSessionTraineesPage />} />
        <Route path='ts/:training_session_id/tracking' element={<TrainingSessionTrackingPage />} />
        <Route path='ts/:training_session_id/attendance/sign/single' element={<AttendanceSingleSignPage />} />
        <Route path='ts/:training_session_id/attendance/sign/class' element={<AttendanceInClassSignPage />} />
        <Route path='ts/:training_session_id/attendances' element={<AttendancesSignPage />} />
        <Route path='ts/:training_session_id/evaluations' element={<EvaluationPage />} />
        <Route path='ts/:training_session_id/documents' element={<DocumentPage />} />
        <Route path='ts/:training_session_id/dates' element={<DatesPage />} />
        <Route path='ts/:id' element={<TrainingSessionPage />} />
        <Route path='answer_evaluation/:evaluationId/:traineeId' element={<AnswerEvaluationPage />} />
        <Route path='evaluation_results/:id' element={<EvaluationResultsPage />} />
        <Route path='logout' element={<LogOutPage />} />
      </Route>

      <Route element={<RequireAdminOrIntructor />}>
        <Route path='ts/:training_session_id/attendance' element={<TrainingSessionAttendancePage />} />
      </Route>

      <Route element={<RequireAdmin />}>
        <Route path='ts/:training_session_id/admin_page' element={<AdminPage />} />
      </Route>
    </Routes>
  );
}

const RootPage = props => {
  const { auth } = useAuth();

  switch (auth?.guestInfo?.type) {
    case 'funding_agency':
      return <FundingAgencyRootPage {...props} />;
    case 'instructor':
      return <InstructorRootPage {...props} />;
    case 'company':
      return <CompanyRootPage {...props} />;
    default:
      return <TraineeRootPage {...props} />;
  }
};

const RequireNotLogged = () => {
  const location = useLocation();
  const { auth } = useAuth();
  return auth?.isLoggedIn ? <Navigate to='/' state={{ from: location }} replace /> : <Outlet />;
};

const RequireLogged = () => {
  const location = useLocation();
  const { auth } = useAuth();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const areRobots = searchParams.get('robots');
  if (token) {
    return (
      <Navigate
        to={`/signin/passwordless_validation?token=${token}&redirect_path=${location.pathname}${areRobots && '?robots=true'}`}
        state={{ from: location }}
        replace
      />
    );
  }

  if (auth.isLoggedIn) {
    if (auth?.shouldAcceptTOS && location.pathname !== '/tos') {
      return (
        <Navigate
          to='/tos'
          state={{
            from: location,
          }}
          replace
        />
      );
    }

    return <Outlet />;
  }

  return <Navigate to='/signin' state={{ from: location }} replace />;
};

const RequireAdminOrIntructor = () => {
  const location = useLocation();
  const { auth } = useAuth();
  const accessType = auth?.guestInfo?.type;

  if (accessType === 'instructor' || accessType === 'user') {
    return <Outlet />;
  }
  return <Navigate to='/' state={{ from: location }} replace />;
};

const RequireAdmin = () => {
  const location = useLocation();
  const { auth } = useAuth();

  if (auth?.guestInfo?.type === 'user') {
    return <Outlet />;
  }

  return <Navigate to='/' state={{ from: location }} replace />;
};

//----------------------------------------------------------------------------------------------------------------------------
// Start App
//----------------------------------------------------------------------------------------------------------------------------
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
