import { ActionWidget } from 'components/mainComponents/ActionWidget';
import { useTranslation } from 'react-i18next';

export function RulesWidget({ children }) {
  const { t } = useTranslation();

  return (
    <ActionWidget icon='download' color='alt-1' title={t('RulesWidget.title')}>
      {children}
    </ActionWidget>
  );
}
