import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { withRouteParams } from 'support/page';
import SigninComponent from '../SigninComponent';
import TechnicalError from '../TechnicalError';
import IncomingEmailNotice from '../SigninComponent/IncomingEmailNotice';
import { PASSWORDLESS_VALIDATION } from './mutations';
import { useAuth } from 'contexts/AuthContext';
import 'scss/session/PasswordlessValidationPage.scss';
import { Illustration } from 'components/mainComponents/Illustration';

function PasswordlessValidationPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);
  const [passwordlessValidation, mutationState] = useMutation(PASSWORDLESS_VALIDATION);
  const { data, loading, error } = mutationState;
  const { auth, signin } = useAuth();
  const { userCode } = auth;

  const {
    params: { token, redirect_path = '/' },
  } = props;

  useEffect(() => {
    (async () => {
      await passwordlessValidation({
        variables: {
          token,
          user_code: userCode,
        },
      });
    })();
  }, [userCode, passwordlessValidation, token]);

  useEffect(() => {
    const answer = data?.passwordlessValidation;
    if (answer?.guest) {
      signin(answer.guest, answer.token, answer.switch_guest_infos);
      navigate(redirect_path);
    }
  }, [signin, data, navigate, redirect_path]);

  return (
    <div className='page PasswordlessValidationPage'>
      <div className='PasswordlessValidationPage__content'>
        <div className='h1 PasswordlessValidationPage__title'>{t('PasswordlessValidationPage.access_denied')}</div>
        {!sent && error && <TechnicalError />}
        {!sent && loading && <div>{t('PasswordlessValidationPage.authorizing_access')}</div>}
        {!sent && data?.passwordlessValidation?.error && (
          <>
            <Illustration name='robot-disappointed' size='lg' />
            <div className='h2 PasswordlessValidationPage__guest-access-text'>{t('SigninPage.guest_access')}</div>
            <div className='h2 PasswordlessValidationPage__token-expired-text'>{t('PasswordlessValidationPage.token_expired')}</div>
            <div className='h4 PasswordlessValidationPage__receive-text'>{t('PasswordlessValidationPage.receive_a_new_code')}</div>
            <div className='PasswordlessValidationPage__signin-component'>
              <SigninComponent mailSentFn={() => setSent(true)} />
            </div>
          </>
        )}
        {sent && <IncomingEmailNotice />}
      </div>
    </div>
  );
}

export default withRouteParams(PasswordlessValidationPage);
