import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';
import { JOIN_ROOM_MUTATION } from '../mutations/video_conference';
import { createRoomUrl } from 'helpers/video_conference/room';
import { ActionButton } from 'components/mainComponents/buttons';
import useTrainingSessionConnectionMonitor from 'components/training_session/useTrainingSessionConnectionMonitor';
import '../scss/VideoConferencePage.scss';
import { getLocale } from 'translations/i18n';
import { JOINED_MEETING, LEFT_MEETING } from 'constants/video_conference';

let loadingDependenciesTimeout = null;
let forceEjectTimeout = null;
let callFrame = null;
let leaveOnNoConfirmationTimeout = null;

const FORCE_EJECT_DELAY = 1000 * 60 * 60 * 3; // 3 hours
const LEAVE_ON_NO_CONFORMATION_DELAY = 1000 * 60 * 2; // 2 minutes

function VideoConferencePage(props) {
  const {
    mutate,
    params: { training_session_id, id: module_id },
  } = props;
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [idlePopupVisible, setIdlePopupVisible] = useState(false);
  const videoElementRef = useRef(null);
  const room_id = `${window.config.env}-module-${module_id}`;
  const localLanguage = getLocale();
  useTrainingSessionConnectionMonitor(training_session_id);

  const reStartForceEjectTimeout = useCallback(
    () =>
      (async function () {
        // restarting the eject timeout
        clearTimeout(forceEjectTimeout);
        clearTimeout(leaveOnNoConfirmationTimeout);
        forceEjectTimeout = setTimeout(() => {
          leaveOnNoConfirmationTimeout = setTimeout(leave, LEAVE_ON_NO_CONFORMATION_DELAY);
          setIdlePopupVisible(true);
        }, FORCE_EJECT_DELAY);
      })(),
    [setIdlePopupVisible]
  );

  // Normal Webpack imports fails for an unknown reason (maybe we need to upgrade the Webpack version)
  // So the file is loaded externaly
  const loadDailyco = useCallback(
    () =>
      (async function () {
        const {
          data: { join_videoconference_room: token },
        } = await mutate({ variables: { module_id, room_id } });

        lazyLoadDependencies('https://unpkg.com/@daily-co/daily-js', 'DailyIframe', () => {
          callFrame = window.DailyIframe.createFrame(videoElementRef.current);
          callFrame.join({
            url: createRoomUrl(room_id, token),
            lang: localLanguage,
            showFullscreenButton: true,
            showLeaveButton: true,
            customLayout: true,
          });
          callFrame.on(JOINED_MEETING, () => {
            setIsLoaded(true);
            reStartForceEjectTimeout();
          });
          callFrame.on(LEFT_MEETING, () => {
            const url = `/ts/${training_session_id}/module/${module_id}`;
            window.location = url; // Don't use history push, to be sure to quit the Daily.co room and avoid running costs
          });
        });
      })(),
    [room_id, training_session_id, module_id, mutate, videoElementRef, reStartForceEjectTimeout, localLanguage]
  );

  useEffect(() => {
    loadDailyco();
    return () => {
      clearTimeout(loadingDependenciesTimeout);
      clearTimeout(forceEjectTimeout);
      if (callFrame) {
        callFrame.destroy();
      }
    };
  }, [loadDailyco]);

  const onNotifyThere = () => {
    reStartForceEjectTimeout();
    setIdlePopupVisible(false);
    setIsLoaded(true);
  };

  return (
    <div className='VideoConferencePage'>
      {isLoaded === false && <h1 id='loading-wait'>{t('VideoConferencePage.loading')}</h1>}
      <div id='dailyco' key='dailyco' ref={videoElementRef} />
      {idlePopupVisible && (
        <div id='idle-popup'>
          <p>{t('VideoConferencePage.presence_request')}</p>
          <div id='buttons'>
            <ActionButton
              label={t('VideoConferencePage.presence_confirm')}
              clickFn={onNotifyThere}
              icon='checkmark'
              size='small'
              type='secondary'
            />
            <ActionButton label={t('VideoConferencePage.presence_leave')} clickFn={leave} icon='cross' size='small' type='secondary' />
          </div>
        </div>
      )}
    </div>
  );
}

function lazyLoadDependencies(url, windowKey, callback) {
  if (window[windowKey]) {
    callback();
    return;
  }
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = url;
  const x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);

  waitForDependencies(windowKey, callback);
}

function waitForDependencies(windowKey, callback) {
  if (window[windowKey]) {
    callback();
  } else {
    loadingDependenciesTimeout = setTimeout(() => {
      waitForDependencies(windowKey, callback);
    }, 300);
  }
}

function leave() {
  // forceful ejecting the user after an idle time
  if (callFrame) {
    callFrame.destroy();
  }
  window.location = '/';
}

export default graphql(JOIN_ROOM_MUTATION)(VideoConferencePage);
