import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../../scss/video_conference/Avatar.module.scss';
import { Illustration } from 'components/mainComponents/Illustration';

function Avatar({ src, alt, size, icon, className, shape }) {
  const avatarStyles = clsx(styles.avatar, className, styles[`avatar--${shape}`], {
    [styles[`avatar--${size}`]]: size,
  });

  return src ? (
    <img className={avatarStyles} src={src} alt={alt} />
  ) : (
    <div className={avatarStyles}>
      <Illustration name={icon} />
    </div>
  );
}

export default Avatar;

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  shape: PropTypes.string,
};

Avatar.defaultProps = {
  src: null,
  alt: '',
  size: null,
  icon: null,
  className: '',
  shape: 'circle',
};
