import { PROBLEM_ICONS, STATUS_BAR_TYPES } from 'constants/video_conference';
import { getProblemKeys } from 'helpers/video_conference';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStatusBarsDispatch, useStatusBarsState } from 'hooks/video_conference/useStatusBars';
import _ from 'lodash';
import { NETWORK_STATS_ELEMENT } from 'constants/video_conference/roomElements';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import styles from 'scss/video_conference/StatusBar.module.scss';

const useProblemsStatusBars = problemIndicators => {
  const { closeStatusBar, addStatusBar } = useStatusBarsDispatch();
  const statusBars = useStatusBarsState();
  const { t } = useTranslation();
  const { openRoomElement } = useRoomElementsDisplay();

  const [statusBarActive, setStatusBarActive] = useState(false);
  const problemKeys = getProblemKeys(problemIndicators);

  useEffect(() => {
    const isThereAProblem = Boolean(problemKeys.length);
    if (isThereAProblem && !statusBarActive) {
      setStatusBarActive(true);
    }

    const problemCount = problemKeys.length;

    const inactiveStatusBars = _.filter(
      statusBars,
      statusBar => statusBar.type === STATUS_BAR_TYPES.PROBLEM && !problemKeys.includes(statusBar.id)
    );

    const handleNetworkStatsClick = () => openRoomElement(NETWORK_STATS_ELEMENT);

    inactiveStatusBars.forEach(statusBar => {
      closeStatusBar(statusBar.id);
    });

    if (problemCount === 0 && statusBarActive) {
      !_.find(statusBars, { id: 'no-problems' }) &&
        addStatusBar([
          {
            id: 'no-problems',
            label: t('ProblemsStatusBar.noProblems'),
            emoji: '✅',
            content: t('ProblemsStatusBar.problems_resolved'),
            type: STATUS_BAR_TYPES.SINGLE,
          },
        ]);

      setStatusBarActive(false);
    }
    if (problemCount > 0 && !statusBarActive) {
      problemKeys.forEach(
        problem =>
          !_.find(statusBars, { id: problem }) &&
          addStatusBar([
            {
              id: problem,
              label: problem,
              emoji: PROBLEM_ICONS[problem],
              content: (
                <Trans
                  i18nKey={t(`ProblemsStatusBar.${problem}_problem`)}
                  components={{
                    button: <button className={styles.barButton} onClick={handleNetworkStatsClick} />,
                  }}
                />
              ),

              type: STATUS_BAR_TYPES.PROBLEM,
            },
          ])
      );
    }
  }, [problemKeys, statusBarActive, statusBars, t, addStatusBar, closeStatusBar, openRoomElement]);
};

export default useProblemsStatusBars;
