import { gql, useMutation } from '@apollo/client';

const SIGNATURE_MUTATION = gql`
  mutation AttendanceSignature(
    $signature: String!
    $trainingSessionSlotId: ID!
    $traineeTrainingSessionId: ID
    $trainingSessionInstructorId: ID
  ) {
    sign(
      signature: $signature
      trainingSessionSlotId: $trainingSessionSlotId
      traineeTrainingSessionId: $traineeTrainingSessionId
      trainingSessionInstructorId: $trainingSessionInstructorId
    ) {
      signature
    }
  }
`;

export function useAttendanceSignatureMutation({ slotId, traineeId, instructorId }) {
  const [mutate, ...rest] = useMutation(SIGNATURE_MUTATION);
  async function sign(signature) {
    const data = await mutate({
      variables: {
        trainingSessionSlotId: slotId,
        signature,
        traineeTrainingSessionId: traineeId,
        trainingSessionInstructorId: instructorId,
      },
    });
    return data;
  }
  return [sign, ...rest];
}
