import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import clsx from 'clsx';
import { Heading } from 'components/mainComponents/Heading';

import './AlertDialog.scss';

/***
 * A modal dialog interrupting the user with important content and expects a response.
 *
 * # Examples
 *
 * ## With a trigger
 *
 * ```
 * <AlertDialog trigger={<button>Unsubscribe</button>}>
 *     <AlertDialogTitle>Are you sure you want to unsubscribe?</AlertDialogTitle>
 *     <AlertDialogContent>
 *         If you confirm, you will not have access to the app and any of your data anymore.<br/>
 *         You will be able to subscribe again later to gain access back to your data.
 *     </AlertDialogContent>
 *     <AlertDialogActions>
 *         <AlertDialogCancel><button>Keep my subscription</button></AlertDialogCancel>
 *         <AlertDialogAction><button>Proceed and unsubscribe</button></AlertDialogAction>
 *     </AlertDialogActions>
 * </AlertDialog>
 * ```
 *
 * ## Without a trigger
 *
 * ```
 * <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
 *     <AlertDialogTitle>Are you sure you want to unsubscribe?</AlertDialogTitle>
 *     <AlertDialogContent>
 *         If you confirm, you will not have access to the app and any of your data anymore.<br/>
 *         You will be able to subscribe again later to gain access back to your data.
 *     </AlertDialogContent>
 *     <AlertDialogActions>
 *         <AlertDialogCancel><button>Keep my subscription</button></AlertDialogCancel>
 *         <AlertDialogAction><button>Proceed and unsubscribe</button></AlertDialogAction>
 *     </AlertDialogActions>
 * </AlertDialog>
 * ```
 */
export function AlertDialog(props) {
  const { className: extraClassname, ...otherProps } = props;
  const classname = clsx('AlertDialog', extraClassname);

  return (
    <RadixAlertDialog.Root className={classname} {...otherProps}>
      <RadixAlertDialog.Trigger asChild>{props.trigger}</RadixAlertDialog.Trigger>
      <RadixAlertDialog.Portal>
        <RadixAlertDialog.Overlay className='AlertDialog__overlay' />
        <RadixAlertDialog.Content className='AlertDialog__content'>{props.children}</RadixAlertDialog.Content>
      </RadixAlertDialog.Portal>
    </RadixAlertDialog.Root>
  );
}

export function AlertDialogTitle(props) {
  return (
    <RadixAlertDialog.Title asChild>
      <Heading level={3} as='h3'>
        {props.children}
      </Heading>
    </RadixAlertDialog.Title>
  );
}

export function AlertDialogContent(props) {
  return (
    <RadixAlertDialog.Description asChild>
      <>{props.children}</>
    </RadixAlertDialog.Description>
  );
}

export function AlertDialogActions(props) {
  return <div className='AlertDialog__actions'>{props.children}</div>;
}

export function AlertDialogAction(props) {
  return <RadixAlertDialog.Action asChild>{props.children}</RadixAlertDialog.Action>;
}

export function AlertDialogCancel(props) {
  return <RadixAlertDialog.Cancel asChild>{props.children}</RadixAlertDialog.Cancel>;
}
