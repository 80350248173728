export const MIC_OK = 'MIC_OK';
export const MIC_OFF = 'MIC_OFF';
export const CAM_OK = 'CAM_OK';
export const CAM_LIMITED = 'CAM_LIMITED';
export const CAM_OFF = 'CAM_OFF';
export const CAM_UNAVAILABLE = 'CAM_UNAVAILABLE';
export const MIC_UNAVAILABLE = 'MIC_UNAVAILABLE';

export const VIDEO_QUALITY_POOR = 'VIDEO_QUALITY_POOR';
export const VIDEO_QUALITY_FAIR = 'VIDEO_QUALITY_FAIR';
export const VIDEO_QUALITY_LAGGY = 'VIDEO_QUALITY_LAGGY';
export const VIDEO_QUALITY_GOOD = 'VIDEO_QUALITY_GOOD';

export const AUDIO_QUALITY_POOR = 'AUDIO_QUALITY_POOR';
export const AUDIO_QUALITY_FAIR = 'AUDIO_QUALITY_FAIR';
export const AUDIO_QUALITY_GOOD = 'AUDIO_QUALITY_GOOD';

export const NETWORK_QUALITY_GOOD = 'good';
export const NETWORK_QUALITY_LOW = 'low';
export const NETWORK_QUALITY_VERY_LOW = 'very-low';
export const NETWORK_QUALITY_DROPPING = 'dropping';

export const PRE_CALL_CONNECTION_QUALITY_GOOD = 'good';
export const PRE_CALL_CONNECTION_QUALITY_ABORTED = 'aborted';
export const PRE_CALL_CONNECTION_QUALITY_FAILED = 'failed';
export const PRE_CALL_CONNECTION_QUALITY_BAD = 'bad';
export const PRE_CALL_CONNECTION_QUALITY_WARNING = 'warning';

export const CPU_LOAD_HIGH = 'high';
export const CPU_LOAD_LOW = 'low';

// Tiles length for specific receive settings layers (daily.co) in the grid mode display
export const MIN_TILES_LENGTH_MIDDLE_LAYER = 5;
export const MIN_TILES_LENGTH_LOW_LAYER = 20;

export const PROBLEM_INDICATOR_DESCRIPTION_KEYS = {
  problemIndicatorVideo: 'NewVideoConferencePage.problem_descriptions.local.problemIndicatorVideo',
  problemIndicatorNetwork: 'NewVideoConferencePage.problem_descriptions.local.problemIndicatorNetwork',
};

export const REMOTE_PROBLEM_INDICATOR_DESCRIPTION_KEYS = {
  problemIndicatorNetwork: 'NewVideoConferencePage.problem_descriptions.remote.problemIndicatorNetwork',
  [CAM_OFF]: 'NewVideoConferencePage.problem_descriptions.remote.video_cam_off',
  [CAM_LIMITED]: 'NewVideoConferencePage.problem_descriptions.remote.video_cam_limited',
};

export const PROBLEM_ICONS = {
  problemIndicatorNetwork: '📶',
  [CAM_OFF]: '🎥',
  [CAM_LIMITED]: '🎥',
};

// Quality events
export const CPU_LOAD_CHANGE_EVENT = 'cpu-load-change';
export const NETWORK_CONNECTION_EVENT = 'network-connection';
export const NETWORK_QUALITY_CHANGE_EVENT = 'network-quality-change';

export const SEND_SETTINGS_FOR_LOW_QUALITY = 'bandwidth-optimized';
export const SEND_SETTINGS_FOR_HIGH_QUALITY = 'bandwidth-and-quality-balanced';
