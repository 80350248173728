import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { SvgIcon } from 'components/mainComponents/SvgIcon';
import 'scss/DocumentsFilter.scss';

const FILTER_OPTIONS = [
  { id: 'date', value: 'DocumentsFilter.date' },
  { id: 'name', value: 'DocumentsFilter.name' },
];
export default function DocumentsFilter({ sortDocumentsBy }) {
  const [selectedFilter, setSelectedFilter] = useState({ id: 'sort', value: 'DocumentsFilter.sort' });
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { t } = useTranslation();

  function setSelected(event) {
    const selected = FILTER_OPTIONS.find(({ id }) => id === event.target.id);
    sortDocumentsBy(selected.id);
    setSelectedFilter(selected);
  }

  return (
    <div className='documents-filter' onMouseOver={() => setDropdownVisible(true)} onMouseOut={() => setDropdownVisible(false)}>
      <div className='documents-filter__dropdown-main-view'>
        <p id='documents-filter__dropdown-selected-filter'>{t(selectedFilter.value)}</p>
        <SvgIcon
          name='chevron--down'
          className={clsx('documents-filter__dropdown-arrow', { 'documents-filter__dropdown-arrow__rotated': dropdownVisible })}
        />
      </div>
      <div className={clsx('documents-filter__content', { 'documents-filter__content_on-hover': dropdownVisible })}>
        {FILTER_OPTIONS.map(({ id, value }) => (
          <div key={id}>
            <input type='radio' id={id} value={t(value)} className='documents-filter__select' onClick={setSelected} />
            <label
              className={clsx('documents-filter__label', { 'documents-filter__selected-filter': selectedFilter.id === id })}
              htmlFor={id}
            >
              {t(value)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

DocumentsFilter.propTypes = {
  sortDocumentsBy: PropTypes.func.isRequired,
};
