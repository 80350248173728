import React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';

import { LoadingPlaceholder, withData } from 'support/page';
import { useAuth } from 'contexts/AuthContext';
import useTrainingSessionConnectionMonitor from './useTrainingSessionConnectionMonitor';
import HeaderBar from 'components/mainComponents/HeaderBar';
import { getLocale } from 'translations/i18n';

const TrainingSessionHeader = props => {
  const { training_session, training_session_id } = props;
  const { auth } = useAuth();
  const { t } = useTranslation();
  useTrainingSessionConnectionMonitor(training_session_id);
  const locale = getLocale().toLowerCase();

  const guestType = auth?.guestInfo?.type;

  const generateLinks = () => {
    const hasNumSigning = training_session.has_numeric_signing && training_session.start_date;
    const pendingEvaluations = training_session.pending_evaluations.filter(pe => pe.pending === true);

    let links = [
      {
        path: `/ts/${training_session_id}`,
        icon: 'home',
        alt: t('TrainingSessionHeader.training_home_page'),
        label: t('TrainingSessionHeader.training_home_page'),
        sticky: true,
      },
      {
        path: `/ts/${training_session_id}/modules`,
        icon: 'steps--vertical',
        alt: t('TrainingSessionHeader.modules_details'),
        label: t('TrainingSessionHeader.modules_details'),
      },
    ];

    if (training_session.show_trainee_pedagogical_tracking_in_extranet && ['user', 'instructor', 'trainee'].includes(guestType)) {
      links.push({
        path: `/ts/${training_session_id}/tracking/`,
        icon: 'student',
        alt: t('TrainingSessionHeader.educational_follow_up'),
        label: t('TrainingSessionHeader.educational_follow_up'),
      });
    }

    if (training_session.show_program_in_extranet && training_session.program) {
      links.push({
        path: `/ts/${training_session_id}/program/`,
        icon: 'notepad',
        alt: t('TrainingSessionHeader.program'),
        label: t('TrainingSessionHeader.program'),
      });
    }

    if (training_session.show_trainees_in_extranet) {
      links.push({
        path: `/ts/${training_session_id}/trainees/`,
        icon: 'users',
        alt: t('TrainingSessionHeader.trainees'),
        label: t('TrainingSessionHeader.trainees'),
      });
    }

    if (hasNumSigning && ['user', 'instructor'].includes(guestType)) {
      links.push({
        path: `/ts/${training_session_id}/attendance/`,
        icon: 'list--boxes',
        alt: t('TrainingSessionAttendancePage.digital_signoff_follow_up'),
        label: t('TrainingSessionAttendancePage.digital_signoff_follow_up'),
      });
    }

    if (hasNumSigning && training_session.show_signing_button_in_extranet && training_session.pendingSignins.length > 0) {
      links.push({
        path: `/ts/${training_session_id}/attendances/`,
        icon: 'sign',
        alt: t('TrainingSessionAttendancePage.digital_signoff'),
        label: t('TrainingSessionAttendancePage.digital_signoff'),
      });
    }

    if (pendingEvaluations.length > 0) {
      links.push({
        path: `/ts/${training_session_id}/evaluations/`,
        icon: 'star',
        alt: t('TrainingSessionPage.evaluations_to_be_completed'),
        label: t('TrainingSessionPage.evaluations_to_be_completed'),
      });
    }

    if (training_session.documents.length > 0 || training_session.addressed_documents.length > 0)
      links.push({
        path: `/ts/${training_session_id}/documents/`,
        icon: 'folder',
        alt: t('TrainingSessionPage.personal_documents'),
        label: t('TrainingSessionPage.personal_documents'),
      });

    if (training_session.start_date && training_session.show_dates_in_extranet) {
      links.push({
        path: `/ts/${training_session_id}/dates/`,
        icon: 'calendar',
        alt: t('TrainingSessionPage.calendar'),
        label: t('TrainingSessionPage.calendar'),
        sticky: true,
      });
    }

    if (guestType === 'user') {
      links.push({
        path: `/ts/${training_session_id}/admin_page`,
        icon: 'gear',
        alt: t('AdminPage.configuration'),
        label: t('AdminPage.configuration'),
      });
    }

    links.push({
      path: '/',
      icon: 'grid',
      alt: t('TrainingSessionHeader.all_my_sessions'),
      label: t('TrainingSessionHeader.all_my_sessions'),
    });

    if (guestType === 'trainee' && !locale.startsWith('es')) {
      links.push({
        path: `/tutorials`,
        icon: 'play--circle', // wait for another icon
        alt: t('Header.tutorials'),
        label: t('Header.tutorials'),
      });
    }

    return links;
  };
  const links = generateLinks();
  return <HeaderBar links={links} />;
};

const trainingSessionHeaderQuery = gql`
  query training_session_header($id: ID!) {
    training_session(id: $id) {
      id
      show_program_in_extranet
      show_trainees_in_extranet
      show_signing_button_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      show_dates_in_extranet
      has_numeric_signing
      program {
        name
      }
      start_date
      pending_evaluations {
        pending
      }
      documents {
        id
      }
      addressed_documents {
        id
      }
      pendingSignins {
        date
      }
    }
  }
`;

function LoadingHeaderBar(props) {
  return <LoadingPlaceholder className='header-bar--loading' />;
}

export default graphql(trainingSessionHeaderQuery, {
  name: 'training_session',
  options: p => ({
    variables: { id: p.training_session_id },
  }),
})(withData('training_session')(TrainingSessionHeader, { loading: LoadingHeaderBar }));
