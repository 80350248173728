import React, { useMemo, useRef } from 'react';
import _ from 'lodash';
import { useTileInfo } from 'contexts/video_conference/TileInfoContext';
import SmallTile from 'components/video_conference/Tile/SmallTile';
import BigTile from 'components/video_conference/Tile/BigTile';
import TileRenderer from 'components/video_conference/Tile/TileRenderer';
import { sortTilesByActivityTimestamp } from 'components/video_conference/CallBox/utils';
import styles from 'scss/video_conference/CallBox.module.scss';
import ToggleCameraButton from 'components/video_conference/CallBox/ToggleCameraButton';
import EnterFullscreenButton from 'components/video_conference/CallBox/EnterFullscreenButton';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';
import { useStatusBarsState } from 'hooks/video_conference/useStatusBars';
import StatusBars from 'components/video_conference/StatusBars';
import useReceiveSideQuality from 'hooks/video_conference/useReceiveSideQuality';
import { DISPLAY_MODE_FEATURED_SPEAKER } from 'constants/video_conference';
import { useMediaTracks } from 'hooks/video_conference/useMediaTracks';
import useFeaturedSpeakerMode from 'hooks/video_conference/useFeaturedSpeakerMode';
import RemainingParticipantsTile from './RemainingParticipantsTile';
import { useScreenShare } from '@daily-co/daily-react';

function FeaturedSpeakerDisplayMode() {
  const smallTilesRef = useRef(null);

  const statusBars = useStatusBarsState();
  const tiles = useTileInfo();
  const { activityTimestamps, currentSpeakerId } = useCurrentSpeaker();
  const { smallTilesLimit, containerRef } = useFeaturedSpeakerMode();
  const { screens } = useScreenShare();

  useReceiveSideQuality(tiles, DISPLAY_MODE_FEATURED_SPEAKER);

  const [[bigTile], smallTiles] = _.partition(tiles, tile => tile.renderer === BigTile);
  const [[localTile], remoteTiles] = _.partition(smallTiles, tile => tile.local);

  const remoteTilesInOrder = sortTilesByActivityTimestamp({
    tiles: screens.length ? remoteTiles : remoteTiles.filter(tile => tile.sessionId !== currentSpeakerId),
    limit: Math.max(smallTilesLimit - 1, 0),
    activityTimestamps,
  });

  let displayedTiles = useMemo(() => [bigTile, localTile, ...remoteTilesInOrder], [bigTile, localTile, remoteTilesInOrder]);

  useMediaTracks(displayedTiles);

  if (process.env.NODE_ENV !== 'production') {
    const fakeMultiplier = window.fakeMultiplier || 1;

    displayedTiles = _.flatten([bigTile, Array.from({ length: fakeMultiplier }, () => localTile), ...remoteTilesInOrder]);
  }

  const remainingParticipants = _.difference(tiles, displayedTiles);

  return (
    <div ref={containerRef} className={styles.tilesContainer}>
      <TileRenderer key={`${bigTile.renderer.name}-${bigTile.sessionId}`} tile={bigTile} />
      <div className={styles.smallTiles} ref={smallTilesRef}>
        {displayedTiles.map(tile => {
          if (tile.renderer === BigTile) return null;
          return <TileRenderer key={`${tile.renderer.name}-${tile.sessionId}`} tile={tile} />;
        })}
        {smallTilesLimit > 1 && <RemainingParticipantsTile remainingParticipants={remainingParticipants} />}
      </div>
      <StatusBars statusBars={statusBars} />
    </div>
  );
}

FeaturedSpeakerDisplayMode.tileListGenerator = ({ normalizedParticipants, lastRemoteSpeakerId, selectedSpeakerId }) => {
  const screenSharingParticipants = normalizedParticipants.filter(participant => participant.screen);

  // Construct primary tile
  let primaryTile;

  if (selectedSpeakerId) {
    primaryTile = normalizedParticipants.find(p => selectedSpeakerId === p.sessionId) || normalizedParticipants[0];
  } else if (screenSharingParticipants.length) {
    // One or more participants are screensharing: display the one that was last speaking

    const screenSharingInstructor = screenSharingParticipants.find(p => p.digiformaType === 'instructor');

    if (screenSharingInstructor !== undefined) {
      primaryTile = screenSharingInstructor;
    } else if (lastRemoteSpeakerId) {
      primaryTile =
        screenSharingParticipants.find(p => p.screen && lastRemoteSpeakerId === p.sessionId) || screenSharingParticipants[0];
    } else {
      primaryTile = normalizedParticipants.find(p => p.screen);
    }
  } else {
    // Nobody is screensharing: display last speaker or selected speaker
    primaryTile = normalizedParticipants.find(p => lastRemoteSpeakerId === p.sessionId) || normalizedParticipants[0];
  }

  primaryTile = {
    ...primaryTile,
    renderer: BigTile,
    rightIcons: <EnterFullscreenButton sessionId={primaryTile.sessionId} />,
  };

  const smallTiles = normalizedParticipants
    .filter(
      participant =>
        participant.local || selectedSpeakerId === participant.sessionId || primaryTile.sessionId !== participant.sessionId
    )
    .map(participant => {
      const leftIcons = participant.local ? <ToggleCameraButton /> : undefined;

      return {
        ...participant,
        renderer: SmallTile,
        leftIcons,
      };
    });

  const result = [primaryTile, ...smallTiles];

  return result;
};

export default FeaturedSpeakerDisplayMode;
