import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../scss/video_conference/LoadingPlaceholder.module.scss';

function LoadingPlaceholder() {
  const { t } = useTranslation();
  return <h1 className={styles.LoadingPlaceholder}>{t('LoadingPlaceholder.message')}</h1>;
}

export default LoadingPlaceholder;
