import { useDaily, useParticipantIds } from '@daily-co/daily-react';
import { sortTilesByActivityTimestamp } from 'components/video_conference/CallBox/utils';
import { PARTICIPANT_REMOTE } from 'constants/video_conference/participants';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';

const MEDIA_TRACKS_STAGED = 'staged';
const AUTOMATIC_SUBSCRIPTIONS = ['audio', 'screenAudio', 'screenVideo'];
const SUBSCRIBED_TILES_LIMIT = 8;

export const useMediaTracks = displayedTiles => {
  const daily = useDaily();
  const participants = daily.participants();
  const participantIds = useParticipantIds({ filter: PARTICIPANT_REMOTE });
  const displayedTilesIds = displayedTiles.map(tile => tile.sessionId);
  const lastVideoTracksUpdates = useRef(null);
  const { activityTimestamps } = useCurrentSpeaker();

  const hasSubscribed = useCallback(
    (participantId, subscriptionTypes) =>
      subscriptionTypes.every(subscriptionType => participants[participantId]?.tracks[subscriptionType].subscribed),
    [participants]
  );

  const lastVideoSubscribed = useMemo(
    () =>
      sortTilesByActivityTimestamp({
        tiles: participantIds.reduce((ids, participantId) => {
          if (hasSubscribed(participantId, ['video'])) ids.push({ sessionId: participantId });
          return ids;
        }, []),
        activityTimestamps,
        limit: SUBSCRIBED_TILES_LIMIT,
      }),
    [activityTimestamps, hasSubscribed, participantIds]
  );

  const updateVideoSubscriptions = useCallback(() => {
    const tracksUpdates = participantIds.reduce((updates, participantId) => {
      const isParticipantDisplayed = displayedTilesIds.includes(participantId);
      const isParticipantSubscribed = lastVideoSubscribed.some(subscribedTile => subscribedTile.sessionId === participantId);

      updates[participantId] = {
        setSubscribedTracks: {
          video: isParticipantDisplayed || isParticipantSubscribed || MEDIA_TRACKS_STAGED,
        },
      };
      return updates;
    }, {});

    if (_.isEqual(tracksUpdates, lastVideoTracksUpdates.current) || daily.isDestroyed()) return;
    lastVideoTracksUpdates.current = { ...tracksUpdates };
    daily.updateParticipants(tracksUpdates);
  }, [daily, displayedTilesIds, participantIds, lastVideoSubscribed]);

  const updateAutomaticSubscriptions = useCallback(() => {
    const hasAllSubscribed = participantIds.every(participantId => hasSubscribed(participantId, AUTOMATIC_SUBSCRIPTIONS));
    if (hasAllSubscribed) return;
    const tracksUpdates = participantIds.reduce((updates, participantId) => {
      updates[participantId] = { setSubscribedTracks: { audio: true, screenAudio: true, screenVideo: true } };
      return updates;
    }, {});

    daily.updateParticipants(tracksUpdates);
  }, [hasSubscribed, participantIds, daily]);

  const debouncedUpdateSubscriptions = useMemo(() => _.debounce(updateVideoSubscriptions, 100), [updateVideoSubscriptions]);

  useEffect(() => {
    debouncedUpdateSubscriptions();
    updateAutomaticSubscriptions();
  }, [debouncedUpdateSubscriptions, updateAutomaticSubscriptions]);
};
