import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { withAuth } from 'contexts/AuthContext';
import { withData } from '../../support/page';
import HeaderBar from 'components/mainComponents/HeaderBar';
import TrainingSessionList from '../training_session/TrainingSessionList';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';

const FundingAgencyRootPage = ({ funding_agency }) => {
  const { t } = useTranslation();

  return (
    <div className='page FundingAgencyRootPage'>
      <HeaderBar />
      <main>
        <h1>{funding_agency.name}</h1>

        <h2>{t('CompanyRootPage.training_sessions')}</h2>
        <TrainingSessionList training_sessions={funding_agency.training_sessions} />
      </main>
    </div>
  );
};

const query = gql`
  query funding_agency_root_page($id: ID!) {
    funding_agency(id: $id) {
      id
      name
      training_sessions {
        id
        name
        custom_name
        start_date
        image {
          url
        }
      }
    }
  }
`;

const withGraphqlData = graphql(query, {
  name: 'funding_agency',
  options: props => {
    return {
      variables: { id: props.auth?.guestInfo?.id },
    };
  },
});

// prettier-ignore
export default
  withAuth(
    withGraphqlData(
      withData('funding_agency')(
        FundingAgencyRootPage, { loading: LoadingPagePlaceholder })));
