import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fscreen from 'fscreen';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '../../../scss/PDFPlayer.scss';
import { ActionButton, UrlButton } from 'components/mainComponents/buttons';
import { IconButton } from 'components/mainComponents/IconButton';
import { useIsUIv2 } from 'components/v2Activation/UIv2Guard';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFPlayer({ url, downloadable, onRead }) {
  // This is only available to IE, not even to Edge
  const isIE = !!document.documentMode;

  if (isIE) {
    return <PDFPlayerIE url={url} downloadable={downloadable} onRead={onRead} />;
  }
  return <PDFPlayerNew url={url} downloadable={downloadable} onRead={onRead} />;
}

function PDFPlayerNew({ url, downloadable, onRead }) {
  const isUIv2 = useIsUIv2();
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100);
  const containerElementRef = useRef();
  const zoomElementRef = useRef();
  const modes = {
    ENTER: 'enter',
    EXIT: 'exit',
    NONE: 'none',
  };
  const fullScreenMode = useRef(modes.NONE);
  const containerWidthRef = useRef(null);

  function onFullScreen() {
    const element = containerElementRef.current;
    // reset zoom
    setZoomLevel(100);

    function onFullscreenChange(_event) {
      const isEnteringFullscreen = !!fscreen.fullscreenElement;

      fullScreenMode.current = isEnteringFullscreen ? modes.ENTER : modes.EXIT;
      if (!isEnteringFullscreen) {
        fullScreenMode.current = modes.NONE;
      }
    }

    try {
      fullScreenMode.current = modes.ENTER;
      fscreen.addEventListener('fullscreenchange', onFullscreenChange, false);
      fscreen.requestFullscreen(element);
    } catch (error) {
      // fscreen library fails on Chrome/iPad ..
      // https://github.com/rafgraph/fscreen/issues/20
      // fail silently (minor inconvenience for the user)
      console.error('Failed to request fullscreen with fscreen', error);
      fullScreenMode.current = modes.NONE;
    }
  }

  function adjustWidth() {
    const isExitingFullscreen = fullScreenMode.current === modes.EXIT;
    const elementWidth =
      isExitingFullscreen && containerWidthRef.current
        ? containerWidthRef.current
        : containerElementRef.current.getBoundingClientRect().width;

    if (fullScreenMode.current === modes.NONE) {
      containerWidthRef.current = elementWidth;
    }

    const isEnteringFullscreen = fullScreenMode.current === modes.ENTER;
    const multiplier = isEnteringFullscreen ? 0.98 : 0.95;
    const width = multiplier * elementWidth;

    if (pageWidth !== width) {
      // avoid unnecessary rendering
      setPageWidth(width);
    }
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function onZoomChange(event) {
    setZoomLevel(parseFloat(event.target.value));
  }

  function handleResize() {
    adjustWidth();
  }

  useEffect(() => {
    onRead();

    adjustWidth();

    window.addEventListener('resize', handleResize);

    return _ => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
    cMapPacked: true,
  };

  return (
    <div className='PDFPlayer'>
      <div className='toolbar'>
        {!isUIv2 && downloadable && (
          <UrlButton url={url} label={t('PDFPlayer.download')} icon='download' size='small' type='secondary' />
        )}
        {isUIv2 && downloadable && (
          <IconButton as='a' href={url} icon='download' variant='primary'>
            {t('PDFPlayer.download')}
          </IconButton>
        )}
        <div className='zoom'>
          <select ref={zoomElementRef} onChange={onZoomChange} value={zoomLevel} title={t('PDFPlayer.zoom')}>
            <option disabled>{t('PDFPlayer.zoom')}</option>
            <option value='50'>50%</option>
            <option value='75'>75%</option>
            <option value='100'>100%</option>
            <option value='150'>150%</option>
            <option value='200'>200%</option>
          </select>
        </div>
        {isUIv2 ? (
          <IconButton onClick={fscreen.fullscreenEnabled ? onFullScreen : null} icon='expand' variant='primary'>
            {t('PDFPlayer.full_screen')}
          </IconButton>
        ) : (
          <ActionButton
            clickFn={fscreen.fullscreenEnabled ? onFullScreen : null}
            label={t('PDFPlayer.full_screen')}
            icon='expand'
            size='small'
            type='secondary'
          />
        )}
      </div>
      <div
        className={`pdfContainer${zoomLevel > 100 ? ' zoomed' : ''}`}
        ref={containerElementRef}
        style={{ width: `${containerWidthRef.current}px` }}
      >
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess} options={options}>
          {Array.from(new Array(numPages), (_el, index) => (
            <Page width={pageWidth} key={`page_${index + 1}`} pageNumber={index + 1} scale={zoomLevel / 100} />
          ))}
        </Document>
      </div>
    </div>
  );
}

// for IE we keep the old implementation as nobody seems to have complained about it..
function PDFPlayerIE({ url, downloadable, onRead }) {
  const { t } = useTranslation();
  const embedElementRef = useRef();

  function onFullScreen() {
    const elem = embedElementRef.current;
    fscreen.requestFullscreen(elem);
  }

  useEffect(() => {
    onRead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='PDFPlayerIE'>
      <button className='button fullscreen' onClick={onFullScreen}>
        {t('PDFPlayer.full_screen')}
      </button>
      <embed ref={embedElementRef} src={`${url}#toolbar=${downloadable ? 1 : 0}`} type='application/pdf' width='100%' height='100%' />
    </div>
  );
}

export default PDFPlayer;
