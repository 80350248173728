import { useModalDispatch, useModalState } from 'hooks/video_conference/useModal';
import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import IconButton from '../IconButton';
import PropTypes from 'prop-types';
import styles from '../../../scss/video_conference/Modal.module.scss';
import { ThemeContext } from 'components/mainComponents/CustomColorChooser/ThemeContext';
import ThemedBlock from 'components/mainComponents/CustomColorChooser/ThemedBlock';

function Modal({ children, modalId, title }) {
  const modal = useModalState();
  const { closeModal } = useModalDispatch();
  const { themeBlockColors } = useContext(ThemeContext);

  return (
    <ReactModal
      isOpen={modal.id === modalId}
      overlayClassName={styles.overlay}
      className={styles.content}
      onRequestClose={closeModal}
      appElement={document.querySelector('#root')}
    >
      <ThemedBlock colors={themeBlockColors}>
        <div className={styles.top}>
          <h2>{title}</h2>
          <IconButton clickFn={closeModal} icon='cross' type='secondary' title='Close' className={styles.close} />
        </div>
        {children}
      </ThemedBlock>
    </ReactModal>
  );
}

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

Modal.defaultProps = {
  title: '',
};
