import React, { useState } from 'react';
import _ from 'lodash';

import '../../../scss/form_inputs/FormInputRadio.scss';

const FormInputRadio = props => {
  const [answerGiven, setAnswerGiven] = useState(null);

  const onAnswer = value => {
    setAnswerGiven(value);
    props.onAnswer(props.input.id, value);
  };

  const data = JSON.parse(props.input.data);
  const correctAnswer = _.find(data.answers, a => a.correct);
  const answerDisplayed = props.answerSent !== null && props.answerSent !== undefined ? props.answerSent : answerGiven;

  let correctClass = '';
  if (props.correcting && correctAnswer) {
    const value = data.answers[answerDisplayed];
    correctClass = value && value.correct ? 'correct' : 'wrong';
  }

  return (
    <div className={`FormInputRadio FormInput ${props.isLive ? 'live' : ''} ${correctClass}`}>
      <p className={`question ${data.question.length < 80 ? 'short-question' : ''}`}>{data.question}</p>

      <div className='answers'>
        {data.answers.map((answer, i) => (
          <div className={`answer ${answerDisplayed === i ? 'selected' : ''}`} key={i}>
            <input type='radio' name={data.question} />
            <div className='check' onClick={() => onAnswer(i)} />
            <label onClick={() => onAnswer(i)}>{answer.text}</label>
          </div>
        ))}
      </div>

      {props.correcting && data.correct_answer && data.correct_answer.length > 0 && (
        <p className='correct-answer'>{data.correct_answer}</p>
      )}
    </div>
  );
};

export default FormInputRadio;
