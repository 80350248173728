import React, { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { withApollo } from '@apollo/client/react/hoc';
import CommentInput from './CommentInput';
import Comment from './Comment';
import { guestSubscribe } from '../../../support/realtime';
import { createComment } from '../../../controllers/comment_controller';
import { compareCommentsByInsertedAtDesc } from '../../../support/sorting';
import { timestamp } from '../../../support/date';
import BlinkListItem from './BlinkListItem';

const CommentList = props => {
  const mountTime = useRef(timestamp()).current;

  useEffect(() => {
    if (props.livestream_id) {
      guestSubscribe(`livestream-${props.livestream_id}`, 'new-comment', comment => {
        createComment(props.client, comment);
      });
    }
    // eslint-disable-next-line
  }, []);

  const { styles, comments, listRef, onScroll } = props;
  const sortedComments = useMemo(() => _.cloneDeep(comments).sort(compareCommentsByInsertedAtDesc), [comments]);

  const renderInput = 'renderInput' in props ? props.renderInput : true;
  const CommentComponent = props.commentComponent || Comment;

  return (
    <div className={styles.commentList}>
      {renderInput && <CommentInput isSubcomment={false} styles={styles} {...props} />}

      <ul className={styles.commentListInner} onScroll={onScroll} ref={listRef}>
        {sortedComments.map(comment => {
          const isNewMessage = timestamp(comment.inserted_at) > mountTime;

          return (
            <BlinkListItem key={comment.id} blinking={isNewMessage}>
              <CommentComponent
                styles={styles}
                comment={comment}
                isSubcomment={false}
                trainee_training_session_id={comment.trainee_training_session_id}
                {...props}
              />
            </BlinkListItem>
          );
        })}
      </ul>
    </div>
  );
};

export default withApollo(CommentList);
