import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Button } from 'components/mainComponents/Button';
import { Paper } from 'components/mainComponents/Paper';
import { ProgressBar } from 'components/mainComponents/ProgressBar';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';

import './ElearningSequenceWidget.scss';

export function ElearningSequenceWidget({
  imgSrc,
  title,
  progressValue,
  accentColor,
  moduleUrl,
  finished = false,
  className: additionalClassName,
  as,
}) {
  const { t } = useTranslation();
  const className = clsx('ElearningSequenceWidget', additionalClassName);

  return (
    <Paper as={as} noPadding className={className}>
      {imgSrc ? (
        <div className='ElearningSequenceWidget__cover' style={{ backgroundImage: `url(${imgSrc})` }} />
      ) : (
        <div className='ElearningSequenceWidget__coverless'>{title.at(0)}</div>
      )}
      <h2 title={title} className='ElearningSequenceWidget__title'>
        {title}
      </h2>
      <div className='ElearningSequenceWidget__footer'>
        {progressValue !== 0 && (
          <label className='ElearningSequenceWidget__progress-container'>
            <ProgressBar value={progressValue} color={accentColor} />
            <span className='ElearningSequenceWidget__progress-label'>{parseInt(progressValue * 100)} %</span>
          </label>
        )}
        <Button as='a' href={moduleUrl} size='sm'>
          {t(getCtaLabel({ progressValue, sequenceIsFinished: finished }))}
        </Button>
      </div>
      {finished && <RoundedIcon background='secondary' className='ElearningSequenceWidget__finishedBadge' icon='checkmark--circle' />}
    </Paper>
  );
}

ElearningSequenceWidget.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  progressValue: PropTypes.number,
  accentColor: PropTypes.string.isRequired,
  moduleUrl: PropTypes.string.isRequired,
  finished: PropTypes.bool,
  className: PropTypes.string,
  as: PropTypes.elementType,
};

const CTA_LABELS = {
  0: 'ElearningSequenceWidget.cta_start',
  1: 'ElearningSequenceWidget.cta_restart',
  default: 'ElearningSequenceWidget.cta_continue',
};

function getCtaLabel({ progressValue, sequenceIsFinished }) {
  if (typeof progressValue === 'number') return CTA_LABELS[progressValue] ?? CTA_LABELS.default;
  if (sequenceIsFinished) return CTA_LABELS[1];
  return CTA_LABELS.default;
}
