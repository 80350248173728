import { MD_BREAKPOINT } from 'constants/breakpoints';
import { ASPECT_RATIO_LIMITS, GRID_GAP, MIN_TILE_WIDTH, TILES_PER_PAGE_LIMIT, TILES_PER_PAGE_MIN } from 'constants/video_conference';
import { calculateSingleTileDimension, clampWidthToAspectRatio, createStyleObject } from 'helpers/video_conference/gridMode';
import useGridPagination from 'hooks/useGridPagination';
import useMedia from 'hooks/video_conference/useMedia';
import { useMemo } from 'react';

function useGridMode({
  width,
  height,
  gap = GRID_GAP,
  minTileWidth = MIN_TILE_WIDTH,
  sessionIds,
  minCountPerPage = TILES_PER_PAGE_MIN,
  maxCountPerPage = TILES_PER_PAGE_LIMIT,
  displayedTiles,
}) {
  const isDesktop = useMedia(MD_BREAKPOINT);
  const [maxColumns, maxRows] = useMemo(() => {
    const columns = Math.max(1, Math.floor(width / minTileWidth));
    const widthPerTile = width / columns;
    const rows = Math.max(1, Math.floor(height / (widthPerTile / ASPECT_RATIO_LIMITS.MIN)));

    return [columns, rows];
  }, [height, minTileWidth, width]);

  const pageSize = useMemo(
    () => Math.max(minCountPerPage, Math.min(maxColumns * maxRows, maxCountPerPage)),
    [maxColumns, maxRows, maxCountPerPage, minCountPerPage]
  );
  const { visibleParticipants, ...paginationProps } = useGridPagination(displayedTiles, pageSize);

  const [columns, rows] = useMemo(() => {
    const tileCount = Math.min(pageSize, sessionIds.length);
    if (tileCount === 0) return [width, height];

    const getWidthByColumnCount = cols => Math.floor((width - gap * (cols - 1)) / cols);
    const getHeightByRowCount = rows => Math.floor((height - gap * (rows - 1)) / rows);

    const idealTileArea = (width * height) / tileCount;
    const idealTileWidth = Math.sqrt(idealTileArea * ASPECT_RATIO_LIMITS.MIN);
    const idealTileHeight = Math.sqrt(idealTileArea / ASPECT_RATIO_LIMITS.MIN);

    const roundedColumns = Math.round(width / idealTileWidth);
    const roundedRows = Math.round(height / idealTileHeight);

    const ceiledRows = Math.ceil(tileCount / roundedColumns);
    const ceiledColumns = Math.ceil(tileCount / roundedRows);

    const layoutWithColumnPriority = {
      area: tileCount * getWidthByColumnCount(roundedColumns) * getHeightByRowCount(ceiledRows),
      result: [roundedColumns, ceiledRows],
    };
    const layoutWithRowPriority = {
      area: tileCount * getWidthByColumnCount(ceiledColumns) * getHeightByRowCount(roundedRows),
      result: [ceiledColumns, roundedRows],
    };

    if (!isDesktop) {
      return layoutWithColumnPriority.result;
    } else {
      return layoutWithRowPriority.result;
    }
  }, [gap, height, pageSize, sessionIds.length, width, isDesktop]);

  const [containerWidth, tileWidth, tileHeight] = useMemo(() => {
    const columnGap = gap * (columns - 1);
    const rowGap = gap * (rows - 1);
    const initialTileWidth = (width - columnGap) / columns;
    const initialTileHeight = initialTileWidth / ASPECT_RATIO_LIMITS.MIN;
    const isOverflowing = rows * initialTileHeight + rowGap > height;
    const tileHeight = isOverflowing ? (height - rowGap) / rows : initialTileHeight;
    const shouldChangeAspectRatio = tileHeight * ASPECT_RATIO_LIMITS.MAX * columns < width;
    const tileWidth = isOverflowing
      ? shouldChangeAspectRatio
        ? tileHeight * ASPECT_RATIO_LIMITS.MAX
        : tileHeight * ASPECT_RATIO_LIMITS.MIN
      : initialTileWidth;

    return [Math.floor(tileWidth * columns + columnGap), tileWidth, tileHeight];
  }, [columns, gap, height, rows, width]);

  const firstIndexInLastRow = useMemo(() => columns * (rows - 1), [columns, rows]);
  const numberOfTilesInLastRow = useMemo(
    () => visibleParticipants.length - firstIndexInLastRow,
    [firstIndexInLastRow, visibleParticipants.length]
  );

  const lastRowTileWidth = useMemo(() => {
    const tileDimension = calculateSingleTileDimension(
      {
        numberOfTiles: numberOfTilesInLastRow,
        availableSpace: containerWidth,
      },
      []
    );

    return clampWidthToAspectRatio(tileDimension, tileHeight);
  }, [containerWidth, numberOfTilesInLastRow, tileHeight]);

  const defaultTileStyle = useMemo(() => createStyleObject(tileWidth, tileHeight), [tileHeight, tileWidth]);

  const lastRowStyle = useMemo(
    () => (numberOfTilesInLastRow !== columns ? createStyleObject(lastRowTileWidth, tileHeight) : defaultTileStyle),
    [defaultTileStyle, lastRowTileWidth, tileHeight, numberOfTilesInLastRow, columns]
  );

  return {
    firstIndexInLastRow,
    defaultTileStyle,
    lastRowStyle,
    visibleParticipants,
    paginationProps,
  };
}

export default useGridMode;
