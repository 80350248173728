import React, { useState } from 'react';

import '../../../scss/form_inputs/FormInputMatrix.scss';

const FormInputMatrix = props => {
  const [answerGiven, setAnswerGiven] = useState({});

  const onAnswer = (i, j) => {
    const newAnswers = { ...answerGiven, [i]: j };
    setAnswerGiven(newAnswers);
    props.onAnswer(props.input.id, newAnswers);
  };

  const data = JSON.parse(props.input.data);
  const answerDisplayed = props.answerSent || answerGiven;

  return (
    <div className={`FormInputMatrix FormInput ${props.isLive ? 'live' : ''}`}>
      <p className={`question ${data.topic.length < 80 ? 'short-question' : ''}`}>{data.topic}</p>

      <div className='answers'>
        <table>
          <thead>
            <tr>
              <th />
              {data.levels.map((level, i) => (
                <th key={i}>
                  <span>{level.text}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.answers.map((answer, i) => (
              <tr key={i}>
                <td className='answer-label'>{answer.text}</td>
                {data.levels.map((level, j) => (
                  <td key={`${i}-${j}`}>
                    <button onClick={() => onAnswer(i, j)} className={isSelected(answerDisplayed, i, j) ? 'selected' : ''}>
                      ✓
                    </button>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function isSelected(answer, i, j) {
  return answer[i] === j;
}

export default FormInputMatrix;
