import clsx from 'clsx';
import PropTypes from 'prop-types';

import './EvaluationQuestion.scss';
import { Heading } from 'components/mainComponents/Heading';

export function EvaluationQuestion(props) {
  const classname = clsx('EvaluationQuestion', {
    'EvaluationQuestion--live': props.isLive,
    [`EvaluationQuestion--${props.correction}`]: props.correction !== undefined,
  });

  return <div className={classname}>{props.children}</div>;
}

EvaluationQuestion.propTypes = {
  correction: PropTypes.oneOf(['correct', 'wrong']),
};

export function EvaluationQuestionLabel(props) {
  return (
    <Heading level={4} className='EvaluationQuestion__label'>
      {props.children}
    </Heading>
  );
}

export function EvaluationQuestionAnswerDescription(props) {
  return <p className='EvaluationQuestion__answer-description'>{props.children}</p>;
}
