import React from 'react';
import PropTypes from 'prop-types';
import '../../scss/mainComponents/radioButton.scss';
import clsx from 'clsx';

export const RadioButton = ({ variant, label, size, className, ...props }) => {
  const checkmarkLabelStyles = clsx(
    'radioButton',
    className,
    `radioButton--${size}`,
    variant !== 'default' && `radioButton--${variant}`
  );

  return (
    <label className={checkmarkLabelStyles} {...props}>
      <input type='radio' {...props} />
      <div className='checkmark'></div>
      {['default', 'accent'].includes(variant) && label}
    </label>
  );
};

RadioButton.propTypes = {
  variant: PropTypes.oneOf(['default', 'hide', 'accent']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

RadioButton.defaultProps = {
  variant: 'default',
  size: 'medium',
};
