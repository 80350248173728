import React, { useEffect, useCallback } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { gql } from '@apollo/client';
import { withData } from 'support/page';
import { useParentCallback } from 'contexts/ParentCallbackContext';

import '../../../scss/Embed.scss';

const Embed = props => {
  const { embed, mutate } = props;
  const { id } = embed;
  const embedly = getEmbedly(embed);
  const fireParentCallback = useParentCallback();

  const onRead = useCallback(async () => {
    await mutate({
      variables: { id },
    });
    fireParentCallback();
  }, [mutate, id, fireParentCallback]);

  useEffect(() => {
    const readTimeout = setTimeout(onRead, 5000);
    return () => {
      clearTimeout(readTimeout);
    };
  }, [onRead]);

  return <div className='Embed'>{embedly ? renderEmbedly(embedly) : renderURL(embed)}</div>;
};

function getEmbedly(embed) {
  if (embed && embed.embedly) {
    const embedly2 = JSON.parse(embed.embedly);
    if (embedly2.html) {
      return embedly2;
    }
  }
  return null;
}

function renderEmbedly(embedly) {
  const html = { __html: embedly.html };
  const width = window.innerWidth - 450;
  let ratio;
  if (embedly.height) {
    ratio = embedly.width / embedly.height;
  } else {
    ratio = 1.77;
  }
  const height = width / ratio;
  const style = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return <div className='embed' style={style} dangerouslySetInnerHTML={html} />;
}

function renderURL(embed) {
  if (embed) {
    return (
      <a className='embed-url' href={embed.url} target='_blank' rel='noopener noreferrer'>
        {embed.url}
      </a>
    );
  }
  return null;
}

const query = gql`
  query embed($id: ID!) {
    embed(id: $id) {
      id
      name
      description
      url
      embedly
    }
  }
`;

const mutation = gql`
  mutation set_embed_read($id: ID!) {
    set_embed_read(id: $id)
  }
`;

const withGraphqlData = compose(
  graphql(query, {
    name: 'embed',
    options: p => ({ variables: { id: parseInt(p.embed.id, 10) } }),
  }),
  graphql(mutation)
);

// prettier-ignore
export default
  withGraphqlData(
    withData('embed')(
      Embed));
