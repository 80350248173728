import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import 'scss/mainComponents/inputs/TextArea.scss';
import usePrevious from 'hooks/video_conference/usePrevious';

export function TextArea(props) {
  const { label, value = '', changeFn, placeholder = '', className = '', rows, autoResize, ...textareaProps } = props;

  const ref = useRef(null);
  const previousValue = usePrevious(value);

  const resize = element => {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
  };

  const handleChange = event => changeFn(event.target.value);

  const inputProps = {
    className,
    onChange: handleChange,
    rows: rows,
    ...textareaProps,
  };

  useEffect(() => {
    if (autoResize && ref.current && previousValue !== null) resize(ref.current);
  }, [autoResize, previousValue]);

  if (label) {
    return (
      <label className='input__label'>
        {label}
        <textarea value={value} placeholder={placeholder} ref={ref} {...inputProps} />
      </label>
    );
  }

  return <textarea value={value} placeholder={placeholder} ref={ref} {...inputProps} />;
}

TextArea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  changeFn: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  rows: PropTypes.number,
  autoResize: PropTypes.bool,
  maxLength: PropTypes.number,
};

TextArea.defaultProps = {
  rows: 5,
  autoResize: false,
};
