import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { withData, withRouteParams } from '../../support/page';
import { gql } from '@apollo/client';
import EvaluationList from './EvaluationList';
import TrainingSessionHeader from 'components/training_session/TrainingSessionHeader';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb/Breadcrumb';

import 'scss/EvaluationPage.scss';

const EvaluationPage = props => {
  const { t } = useTranslation();
  const { training_session } = props;
  const pendingEvaluations = training_session.pending_evaluations.filter(pe => pe.pending === true);
  const training_session_name = training_session.custom_name || training_session.name;

  return (
    <div className='page EvaluationPage'>
      <TrainingSessionHeader training_session_id={training_session.id} />
      <main>
        <Breadcrumb
          links={[
            { name: training_session_name, url: `/ts/${training_session.id}` },
            { name: t('TrainingSessionPage.evaluations_to_be_completed') },
          ]}
        />
        <h1>{t('TrainingSessionPage.evaluations_to_be_completed')}</h1>
        <EvaluationList evaluations={pendingEvaluations} />
      </main>
    </div>
  );
};

const evaluationsQuery = gql`
  query training_session_evaluations($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      pending_evaluations {
        pending
        trainee_id
        trainee_name
        evaluation {
          id
          name
          type
        }
      }
    }
  }
`;

const withGraphqlData = graphql(evaluationsQuery, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: props.params.training_session_id },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        EvaluationPage, { loading: LoadingPagePlaceholder })));
