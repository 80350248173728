import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withRouteParams } from 'support/page';
import TrainingSessionHeader from 'components/training_session/TrainingSessionHeader';
import DisplayConfigurationPanel from './DisplayConfigurationPanel';
import { ActionButton } from 'components/mainComponents/buttons';

import 'scss/AdminPage.scss';

const Configurator = () => {
  const { t } = useTranslation();
  return (
    <div className='config-panels'>
      <h1>{t('Configurator.configuration_learner_space')}</h1>
      <DisplayConfigurationPanel />
    </div>
  );
};

const AdminPage = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className='page'>
      <TrainingSessionHeader training_session_id={props.params.training_session_id} />
      <main>
        <ActionButton icon='arrow--left' label={t('AdminPage.go_back')} clickFn={() => navigate(-1)} type='secondary' />
        <Configurator />
      </main>
    </div>
  );
};

export default withRouteParams(AdminPage);
