import { gql } from '@apollo/client';

export const DOCUMENTS_QUERY = gql`
  query training_session_documents($id: ID!) {
    trainingSession(id: $id) {
      name
      customName
      foldersWithDocuments {
        id
        name
        documents {
          id
          filename
          url
          mime
          wistia {
            hashedId
          }
          folderId
          downloadable
          insertedAt
        }
        insertedAt
      }
      baseDocuments {
        id
        filename
        url
        mime
        wistia {
          hashedId
        }
        folderId
        downloadable
        insertedAt
      }
    }
  }
`;

export const NOTIFY_DOCUMENT_DOWNLOADED = gql`
  mutation notify_document_downloaded($id: ID!) {
    notify_document_downloaded(id: $id)
  }
`;
