import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppPropTypes from 'propTypes';
import { RoomOptsContext } from 'contexts/video_conference';
import BasicTile from './BasicTile';
import { getUserImageUrl } from 'helpers/video_conference';
import preCallTileStyles from 'scss/video_conference/PreCallTile.module.scss';
import generalTileStyles from 'scss/video_conference/GeneralTile.module.scss';
import VolumeIndicator from 'components/video_conference/VolumeIndicator';
import { getVideoClassName } from './utils';
import VideoPlaceholder from './VideoPlaceholder';
import VideoBox from 'components/video_conference/VideoBoxes/VideoBox';
import { useAuth } from 'contexts/AuthContext';

const styles = { ...generalTileStyles, ...preCallTileStyles };

const PreCallTile = ({ tile, classes, wrapperClasses }) => {
  const { auth } = useAuth();
  const { usersData } = useContext(RoomOptsContext);
  const imageUrl = getUserImageUrl(usersData, `${auth.guestInfo.type}-${auth.guestInfo.id}`);
  const tileClasses = `${styles.Tile} ${classes}`;
  const videoWrapperClasses = `${styles.videoWrapper} ${wrapperClasses}`;

  const { videoTrack } = tile;
  const videoClassName = getVideoClassName({ styles, tile, videoTrack });

  return (
    <BasicTile
      className={tileClasses}
      styles={styles}
      rightIcons={tile.audio && tile.audioTrack && <VolumeIndicator audioTrack={tile.audioTrack} mode='bar' styles={styles} />}
    >
      {videoTrack ? (
        <VideoBox className={videoClassName} videoTrack={videoTrack} styles={styles} mirror wrapperStyles={videoWrapperClasses} />
      ) : (
        <VideoPlaceholder className={videoWrapperClasses} userName={tile.user_name} imageUrl={imageUrl} styles={styles} />
      )}
    </BasicTile>
  );
};

PreCallTile.propTypes = {
  tile: AppPropTypes.tile.isRequired,
  leftIcons: PropTypes.node,
  rightIcons: PropTypes.node,
  classes: PropTypes.string,
  wrapperClasses: PropTypes.string,
};

PreCallTile.defaultProps = {
  leftIcons: undefined,
  rightIcons: undefined,
  classes: '',
  wrapperClasses: '',
};

export default PreCallTile;
