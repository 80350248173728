import React from 'react';
import PropTypes from 'prop-types';

import './RoundedIcon.scss';
import clsx from 'clsx';
import { PaletteColorPropType } from 'propTypes';
import { SvgIcon } from 'components/mainComponents/SvgIcon/SvgIcon';

export function RoundedIcon(props) {
  const wrapperClassname = clsx('RoundedIcon', `RoundedIcon--background-${props.background}`, props.className);

  return (
    <div className={wrapperClassname}>
      <SvgIcon name={props.icon} size='2xl' />
    </div>
  );
}

RoundedIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  background: PaletteColorPropType,
  className: PropTypes.string,
};

RoundedIcon.defaultProps = {
  background: 'primary',
};
