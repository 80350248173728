import React, { forwardRef } from 'react';
import {
  Content,
  Group,
  Icon,
  Item,
  ItemIndicator,
  ItemText,
  Label,
  Portal,
  Root,
  ScrollDownButton,
  ScrollUpButton,
  Separator,
  Trigger,
  Value,
  Viewport,
} from '@radix-ui/react-select';
import { clsx } from 'clsx';
import './Select.scss';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

export const Select = ({ placeholder, children, id, value, onChange, defaultValue, label }) => {
  return (
    <label className='SelectRoot__label'>
      {label && <span>{label}</span>}
      <Root onValueChange={onChange} value={value} defaultValue={defaultValue}>
        <Trigger id={id} className='SelectRoot__trigger'>
          <Value placeholder={placeholder} />
          <Icon className='SelectRoot__icon'>
            <SvgIcon name='chevron--down' size='md' />
          </Icon>
        </Trigger>
        <Portal>
          <Content className='SelectRoot__content'>
            <ScrollUpButton className='SelectRoot__scrollBtn'>
              <SvgIcon name='chevron--up' />
            </ScrollUpButton>
            <Viewport>{children}</Viewport>
            <ScrollDownButton className='SelectRoot__scrollBtn'>
              <SvgIcon name='chevron--down' />
            </ScrollDownButton>
          </Content>
        </Portal>
      </Root>
    </label>
  );
};

Select.Item = forwardRef(({ children, disabled, rightIcon, ...props }, ref) => {
  return (
    <Item disabled={disabled} ref={ref} className='SelectRoot__item' {...props}>
      <ItemIndicator className='SelectRoot__itemIndicator'>
        <SvgIcon name='checkmark' />
      </ItemIndicator>
      <ItemText>{children}</ItemText>
      {rightIcon && (
        <span className='SelectRoot__rightIcon'>
          <SvgIcon name={rightIcon} />
        </span>
      )}
    </Item>
  );
});
Select.Item.displayName = 'SelectItem';

Select.Group = forwardRef((props, ref) => {
  return <Group {...props} className={clsx('SelectRoot__group', props.className)} ref={ref} />;
});
Select.Group.displayName = 'SelectGroup';
Select.GroupTitle = forwardRef(({ className, ...props }, ref) => {
  return <Label className={clsx('SelectRoot__group-title', className)} {...props} ref={ref} />;
});
Select.GroupTitle.displayName = 'SelectGroupTitle';
Select.Separator = () => <Separator className='SelectRoot__separator' />;
