import React, { useState } from 'react';

import './FormInputCheckbox.scss';
import { Checkbox } from 'components/mainComponents/CheckboxV2';
import {
  EvaluationQuestion,
  EvaluationQuestionAnswerDescription,
  EvaluationQuestionLabel,
} from 'components/v2/evaluations/form_inputs/EvaluationQuestion';

export function FormInputCheckbox(props) {
  const [answerGiven, setAnswerGiven] = useState([]);

  const data = JSON.parse(props.input.data);
  const correctAnswers = data.answers.filter(answer => answer.correct);
  const answerDisplayed = props.answerSent !== null && props.answerSent !== undefined ? props.answerSent : answerGiven;

  let correction = undefined;
  if (props.correcting && correctAnswers.length > 0) {
    correction =
      answerDisplayed.length > 0 &&
      correctAnswers.length === answerDisplayed.length &&
      answerDisplayed.every(answerIndex => data.answers[answerIndex].correct === true)
        ? 'correct'
        : 'wrong';
  }

  return (
    <EvaluationQuestion correction={correction}>
      <EvaluationQuestionLabel>{data.question}</EvaluationQuestionLabel>
      <div className='FormInputCheckbox__answers-list'>
        {data.answers.map((answer, index) => (
          <Checkbox
            checked={answerDisplayed.includes(index)}
            onCheckedChange={() => toggleAnswer(index)}
            disabled={props.correcting}
            key={index}
          >
            {answer.text}
          </Checkbox>
        ))}
      </div>

      {props.correcting && data.correct_answer && data.correct_answer.length > 0 && (
        <EvaluationQuestionAnswerDescription>{data.correct_answer}</EvaluationQuestionAnswerDescription>
      )}
    </EvaluationQuestion>
  );

  function toggleAnswer(answer) {
    const newAnswer = [...answerGiven];
    const valueIndex = answerGiven.findIndex(savedAnswer => savedAnswer === answer);

    if (valueIndex > -1) {
      newAnswer.splice(valueIndex, 1);
    } else {
      newAnswer.push(answer);
    }

    setAnswerGiven(newAnswer);
    props.onAnswer(props.input.id, newAnswer);
  }
}
