import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Select } from 'components/mainComponents/Select';

export function TrainingSessionSelect(props) {
  const navigate = useNavigate();
  const {
    currentSessionId,
    user: { training_sessions: trainingSessions },
  } = props;

  function goToSession(sessionId) {
    navigate(`/ts/${sessionId}`);
  }

  return (
    <Select defaultValue={currentSessionId} onChange={goToSession}>
      {trainingSessions.map(session => (
        <Select.Item value={session.id} key={session.id}>
          {session.name}
        </Select.Item>
      ))}
    </Select>
  );
}

TrainingSessionSelect.propTypes = {
  currentSessionId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    training_sessions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export function TrainingSessionSelectLoader() {
  return (
    <Select disabled>
      <option />
    </Select>
  );
}
