import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'components/mainComponents/Button';
import { Illustration } from 'components/mainComponents/Illustration';

import './NotFoundPage.scss';

export function NotFoundPage() {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const isLoggedIn = auth?.isLoggedIn;
  return (
    <main className='NotFoundPage'>
      <Illustration name='robot-disappointed' size='lg' />
      <footer className='NotFoundPage__cta'>
        <p className='NotFoundPage__alert'>{t('NotFoundPage.alert')}</p>
        {isLoggedIn ? (
          <Button as='a' href='/'>
            {t('NotFoundPage.back_to_home')}
          </Button>
        ) : (
          <Button as='a' href='/login'>
            {t('NotFoundPage.connection')}
          </Button>
        )}
      </footer>
    </main>
  );
}
