import React, { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class LivestreamActivity extends Component {
  render() {
    const { livestream } = this.props;

    let { url } = livestream;
    if (livestream.use_virtualroom) {
      url = `/ts/${this.props.training_session_id}/module/${this.props.module_id}/videoconference`;
    }

    return (
      <div className='livestream'>
        <div>
          {this.props.t('LivestreamActivity.date')}
          <span className='livestream-date'>{moment.utc(livestream.start).local().format('DD/MM/YYYY')}</span>
          <span className='livestream-time'>{moment.utc(livestream.start).local().format('HH:mm')}</span>
        </div>
        <div>
          {this.props.t('LivestreamActivity.link')}
          <span className='livestream-link'>
            <a target='_blank' rel='noopener noreferrer' href={url}>
              {url}
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LivestreamActivity);
