import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RoomOptsContext } from 'contexts/video_conference';
import { CallStatusProvider } from 'hooks/video_conference';
import { CurrentSpeakerProvider } from 'contexts/video_conference/CurrentSpeakerContext';
import LoadingPlaceholder from 'components/video_conference/LoadingPlaceholder';
import { JOIN_ROOM_WITH_DATA_MUTATION } from 'mutations/video_conference';
import { COMMENTS_QUERY, TRAINING_SESSION_QUERY, VIDEO_CALL_CONFIG_QUERY } from 'queries/video_conference';
import RoomAfterApiJoin from './RoomAfterApiJoin';
import { UserDeviceRatingsProvider } from 'contexts/video_conference/UserDeviceRatingsContext';
import { useCallStateDispatch, useCallStateState } from 'hooks/video_conference/useCallState';
import { BEFORE_PRE_CALL_STATE, PRE_CALL_STATE, READY_TO_JOIN_STATE, JOINED_STATE } from 'constants/video_conference';
import { getLocalConfig, joinCall, preCall } from 'helpers/video_conference';
import { createRoomUrl } from 'helpers/video_conference/room';
import { useDaily, useDevices } from '@daily-co/daily-react';
import PreCallRenderer from 'components/video_conference/PreCallPage/PreCallRenderer';
import { QualityMeasuringProvider } from 'hooks/useInCallQualityMeasuring';
import RoomElementsProvider from 'hooks/video_conference/useRoomElementsDisplay/RoomElementsContext';

function Room({ usersData, roomId, moduleId, trainingSessionId, onError, accountInfosData }) {
  const { t } = useTranslation();
  const [mutateJoinRoom, { data, error: joinRoomMutationError }] = useMutation(JOIN_ROOM_WITH_DATA_MUTATION);
  const { data: queryData, error: queryError } = useQuery(VIDEO_CALL_CONFIG_QUERY);
  const { data: trainingSessionData } = useQuery(TRAINING_SESSION_QUERY, {
    variables: {
      id: trainingSessionId,
    },
  });
  useEffect(() => queryError && onError(queryError), [onError, queryError]);

  const [roomOpts, setRoomOpts] = useState(false);
  const { currentState, lastState } = useCallStateState();
  const setCallState = useCallStateDispatch();
  const callObject = useDaily();
  const { setCamera, setSpeaker, setMicrophone } = useDevices();

  const localConfig = getLocalConfig();

  const { videoDeviceId, audioDeviceId, outputDeviceId, isCameraMuted, isMicMuted } = localConfig;

  const setInputDevices = useCallback(() => {
    if (videoDeviceId) {
      setCamera(videoDeviceId);
    }
    if (audioDeviceId) {
      setMicrophone(audioDeviceId);
    }
  }, [setCamera, setMicrophone, videoDeviceId, audioDeviceId]);

  const setOutputDevice = useCallback(() => {
    if (outputDeviceId) {
      setSpeaker(outputDeviceId);
    }
    if (isCameraMuted) {
      callObject.setLocalVideo(false);
    }

    if (isMicMuted) {
      callObject.setLocalAudio(false);
    }
  }, [setSpeaker, outputDeviceId, callObject, isCameraMuted, isMicMuted]);

  const qualityVerdict = useMemo(
    () => ({
      bandwidthSettings: JSON.parse(localStorage.getItem('bandwidthSettings')),
    }),
    []
  );

  useEffect(() => {
    mutateJoinRoom({
      variables: { room_id: roomId, module_id: moduleId },
      update: (
        store,
        {
          data: {
            join_videoconference_room_with_data: { dailycoroom },
          },
        }
      ) => {
        // ...so that when Chat component is loaded, it can first render data from cache
        store.writeQuery({
          query: COMMENTS_QUERY,
          data: { dailycoroom },
          variables: { id: dailycoroom.id },
        });
      },
      errorPolicy: 'all',
    });
  }, [moduleId, mutateJoinRoom, roomId]);

  useEffect(
    () => joinRoomMutationError && onError(joinRoomMutationError),
    [joinRoomMutationError] // eslint-disable-line
  );

  useEffect(() => {
    if (data && callObject) {
      const {
        // eslint-disable-next-line camelcase
        join_videoconference_room_with_data: { token },
      } = data;

      if (currentState === BEFORE_PRE_CALL_STATE) {
        preCall({
          callObject,
          url: createRoomUrl(roomId, token),
          token,
          quality: qualityVerdict,
          setInputDevices,
          setOutputDevice,
        }).then(() => {
          setCallState(PRE_CALL_STATE);
          callObject.startCamera();
        });
      }

      if (currentState === READY_TO_JOIN_STATE) {
        joinCall({
          callObject,
          url: createRoomUrl(roomId, token),
          token,
        }).then(() => setCallState(JOINED_STATE));
      }
    }
  }, [callObject, data, roomId, t, currentState, lastState, qualityVerdict, setCallState, setInputDevices, setOutputDevice]);

  useEffect(() => {
    if (joinRoomMutationError) {
      onError(joinRoomMutationError);
    }
  }, [joinRoomMutationError, onError]);

  useEffect(() => {
    if (queryData && data && trainingSessionData) {
      const { videoCallConfig } = queryData;
      const initialQuality = {
        problemIndicators: {
          problemIndicatorVideo: videoCallConfig.problemIndicatorVideo,
          problemIndicatorNetwork: videoCallConfig.problemIndicatorNetwork,
        },
        bandwidthSettings: JSON.parse(localStorage.getItem('bandwidthSettings')),
      };

      const {
        // eslint-disable-next-line camelcase
        join_videoconference_room_with_data: {
          enable_recording,
          dailycoroom: { id: dailycoroomId },
        },
      } = data;

      setRoomOpts({
        enableRecording: enable_recording,
        roomId,
        moduleId,
        usersData,
        trainingSessionId,
        dailycoroomId,
        initialQuality,
        trainingSessionName: trainingSessionData.training_session?.custom_name || trainingSessionData.training_session.name,
        trainingSessionDates: trainingSessionData.training_session.dates,
        moduleName: trainingSessionData.training_session.modules.find(module => module.id === moduleId).name,
        academyName: `${accountInfosData.accountInfos.firstname} ${accountInfosData.accountInfos.lastname}`,
        logo: accountInfosData.accountInfos.logo,
        isAttendanceSigningVisible: false,
      });
    }
  }, [data, moduleId, queryData, roomId, trainingSessionId, usersData, trainingSessionData, accountInfosData]);

  const renderContent = () => {
    switch (currentState) {
      case BEFORE_PRE_CALL_STATE:
      case PRE_CALL_STATE:
        return <PreCallRenderer />;
      case JOINED_STATE:
        return (
          <RoomElementsProvider>
            <QualityMeasuringProvider>
              <RoomAfterApiJoin />
            </QualityMeasuringProvider>
          </RoomElementsProvider>
        );
      default:
        return <LoadingPlaceholder />;
    }
  };

  if (roomOpts) {
    return (
      <RoomOptsContext.Provider value={roomOpts}>
        <UserDeviceRatingsProvider>
          <CallStatusProvider>
            <CurrentSpeakerProvider>
              <RoomElementsProvider>{renderContent()}</RoomElementsProvider>
            </CurrentSpeakerProvider>
          </CallStatusProvider>
        </UserDeviceRatingsProvider>
      </RoomOptsContext.Provider>
    );
  }
  return <LoadingPlaceholder />;
}

Room.propTypes = {
  usersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  accountInfosData: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  roomId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  trainingSessionId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Room;
