import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { ComponentsRenderer } from '@a-world-for-us/elearning_content_editor';
import { getLocale } from 'translations/i18n';
import { CommonPagesLayout } from 'components/v2/layouts/CommonPagesLayout';
import { withAuth } from 'contexts/AuthContext';
import { withData } from 'support/page';
import { PageLoader } from 'components/v2/layouts/PageLoader';
import { editableComponentsQuery } from 'support/editableComponentsUtils';

function AboutPage({ extranetConfig }) {
  const { editableComponents, descriptionHtml } = extranetConfig;

  useEffect(() => {
    if (editableComponents?.length) loadContentEditor(editableComponents);
  }, [editableComponents]);

  return (
    <CommonPagesLayout>
      {descriptionHtml && (
        <>
          <style>{descriptionHtml.css}</style>
          <div
            dangerouslySetInnerHTML={{
              __html: descriptionHtml.html,
            }}
          />
        </>
      )}
      {editableComponents && <div id={CONTENT_EDITOR_ID} />}
    </CommonPagesLayout>
  );
}

const CONTENT_EDITOR_ID = 'about-content';
function loadContentEditor(componentsPayload) {
  ComponentsRenderer.init({
    elementId: CONTENT_EDITOR_ID,
    componentsPayload,
    shouldTrackProgress: false,
    config: {
      language: getLocale(),
    },
  });
}

const EXTRANET_CONFIG = gql`
  query ExtranetConfig($userCode: String!) {
    extranetConfig(userCode: $userCode) {
      id
      descriptionHtml {
        html
        css
      }
      editableComponents ${editableComponentsQuery()}
    }
  }
`;

const withGraphqlData = graphql(EXTRANET_CONFIG, {
  name: 'extranetConfig',
  options(props) {
    return {
      variables: {
        userCode: props.auth?.userCode,
      },
    };
  },
});

export default withAuth(withGraphqlData(withData('extranetConfig')(AboutPage, { loading: PageLoader })));
