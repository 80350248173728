import React from 'react';

import HeaderPublicPages from './HeaderPublicPages';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActionButton } from 'components/mainComponents/buttons';

import '../scss/TutorialsPage.scss';

const TutorialCard = ({ title, link, text, id }) => {
  return (
    <div className='TutorialCard'>
      <h3>{title}</h3>
      <p className='description'>{text}</p>
      <div className='video-emeded'>
        <iframe
          src={link}
          key={id}
          frameBorder='0'
          scrolling='no'
          title={title}
          name='wistia-embed'
          allowFullScreen={true}
          mozallowfullscreen='true'
          webkitallowfullscreen='true'
          oallowfullscreen='true'
          msallowfullscreen='true'
        />
      </div>
    </div>
  );
};

const TutorialsPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const tutorialDescs = [
    {
      title: t('TutorialsPage.homepage'),
      text: t('TutorialsPage.homepageText'),
      links: {
        fr: 'https://aworldforus.wistia.com/medias/qsczc80grm',
        en: 'https://aworldforus.wistia.com/medias/vwt15pgx9z',
      },
    },

    {
      title: t('TutorialsPage.evaluations'),
      text: t('TutorialsPage.evaluationsText'),
      links: {
        fr: 'https://aworldforus.wistia.com/medias/e43i25px5o',
        en: 'https://aworldforus.wistia.com/medias/6h6ai4hxk8',
      },
    },

    {
      title: t('TutorialsPage.activities'),
      text: t('TutorialsPage.activitiesText'),
      links: {
        fr: 'https://aworldforus.wistia.com/medias/72y9wpsr9i',
        en: 'https://aworldforus.wistia.com/medias/v0xi4zvrp3',
      },
    },
  ];

  return (
    <div className='TutorialsPage page'>
      <HeaderPublicPages />
      <main>
        <ActionButton icon='arrow--left' label={t('TutorialsPage.goBack')} clickFn={() => navigate(-1)} type='secondary' />
        <h2>{t('TutorialsPage.welcome')}</h2>
        <p className='description'>{t('TutorialsPage.intro')}</p>

        {tutorialDescs.map((desc, idx) => {
          const { links, ...forward } = desc;
          const link = links[i18n.language] || links['fr'];

          return <TutorialCard {...forward} link={link} key={idx} />;
        })}
        <h3 className='footer-msg'>{t('TutorialsPage.goodTrainingSession')}</h3>
      </main>
    </div>
  );
};

export default TutorialsPage;
