import { useState } from 'react';
import useResizeObserver from 'use-resize-observer';

/**
 * ResizeObserver is tied to browser repainting,
 * so the requestAnimationFrame is used here (instead of a simple throttle function)
 * to optimize performance by applying changes before the next repaint.
 */

export default function useThrottledResizeObserver(outsideRef) {
  const [size, setSize] = useState({ width: null, height: null });
  const { ref } = useResizeObserver({ onResize: size => requestAnimationFrame(() => setSize(size)), ref: outsideRef });

  return { ref, ...size };
}
