import React, { createContext, useContext, useState } from 'react';
import { ChildrenPropType } from '../../../propTypes';

const RoomElementsStateContext = createContext();
const RoomElementsDispatchContext = createContext();

const RoomElementsProvider = ({ children }) => {
  const [roomElements, setRoomElements] = useState([]);
  const [contentProps, setContentProps] = useState(null);

  return (
    <RoomElementsStateContext.Provider value={{ roomElements, contentProps }}>
      <RoomElementsDispatchContext.Provider value={{ setRoomElements, setContentProps }}>
        {children}
      </RoomElementsDispatchContext.Provider>
    </RoomElementsStateContext.Provider>
  );
};

RoomElementsProvider.propTypes = ChildrenPropType;

export const useRoomElementsState = () => {
  const sidebarState = useContext(RoomElementsStateContext);
  if (sidebarState === undefined) {
    throw new Error('useRoomElementsState must be used within a RoomElementsProvider');
  }

  return sidebarState;
};

export const useRoomElementsDispatch = () => {
  const { setRoomElements, setContentProps } = useContext(RoomElementsDispatchContext);
  const { roomElements } = useContext(RoomElementsStateContext);
  if (setRoomElements === undefined) {
    throw new Error('useRoomElementsDispatch must be used within a RoomElementsProvider');
  }

  const openRoomElement = (id, contentProps) => {
    setRoomElements(prevState => [...prevState, id]);
    if (contentProps) {
      setContentProps(prevContentProps => ({
        ...prevContentProps,
        [id]: contentProps,
      }));
    }
  };
  const closeRoomElement = id => setRoomElements(prevState => prevState.filter(element => element !== id));
  const toggleRoomElement = (id, contentProps) =>
    roomElements.includes(id) ? closeRoomElement(id) : openRoomElement(id, contentProps);

  return { openRoomElement, closeRoomElement, toggleRoomElement };
};

export default RoomElementsProvider;
