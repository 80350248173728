import { NETWORK_QUALITY_LOW, NETWORK_QUALITY_VERY_LOW, SEND_SETTINGS_FOR_LOW_QUALITY } from 'constants/video_conference';

function setListenersAndGlobals(callObject) {
  // Attempt to gracefully leave the call when user is not graceful with exiting
  // browser window. This may or may not be executed.
  window.addEventListener('beforeunload', () => callObject.destroy());

  // Just for development purposes, expose call object to window for easy debugging
  if (process.env.NODE_ENV === 'development') {
    window.callObject = callObject;
  }
}

export function joinCall({ callObject, url, token }) {
  return callObject.join({ url, token });
}

export async function preCall({ callObject, url, token, qualityThreshold, setInputDevices, setOutputDevice }) {
  setListenersAndGlobals(callObject);
  if ([NETWORK_QUALITY_LOW, NETWORK_QUALITY_VERY_LOW].includes(qualityThreshold))
    callObject.updateSendSettings({ video: SEND_SETTINGS_FOR_LOW_QUALITY });

  await callObject.preAuth({ url, token }).then(() => {
    setInputDevices();
    setOutputDevice();
  });
}
