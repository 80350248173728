import { useParams } from 'react-router-dom';

import './AnswerEvaluationPage.scss';
import { AnswerEvaluation } from 'components/v2/evaluations';
import { HeaderBar } from 'components/v2/HeaderBar';

export function AnswerEvaluationPage() {
  const params = useParams();

  return (
    <main className='v2_AnswerEvaluationPage'>
      <HeaderBar />
      <div className='v2_AnswerEvaluationPage__evaluation'>
        <AnswerEvaluation traineeId={params.traineeId} evaluationId={params.evaluationId} />
      </div>
    </main>
  );
}
