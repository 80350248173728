import { pick } from 'lodash';

export const pickKeys = (obj, keys) => Object.keys(pick(obj, keys));

export const getTruthyKeys = obj => {
  const truthyKeys = Object.keys(obj).filter(key => Boolean(obj[key]));
  return pickKeys(obj, truthyKeys);
};

export const callAll =
  (...fns) =>
  (...args) => {
    fns.forEach(fn => {
      if (fn) {
        fn(...args);
      }
    });
  };

export const isMacOS = navigator.platform.toLowerCase().includes('mac');
const isIEedge = navigator.userAgent.indexOf('Edg') > -1;
const isChromium = window.chrome;
const isOpera = typeof window.opr !== 'undefined';

export const isChrome = () => {
  const winNav = window.navigator;
  const vendorName = winNav.vendor;

  return (
    isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && isOpera === false && isIEedge === false
  );
};

export const isSafari = () => !isChrome() && !isIEedge && !isOpera && window.navigator.userAgent.indexOf('Safari') > -1;

/**
 * Strip an object of all properties not listed.
 * Nested objects are also stripped of the same properties.
 * @param {object} target Object to strip of extraneous properties
 * @param {string[]} keptProperties Properties to keep in the target
 * @returns A semi-shallow copy of the target object stripped
 *  of all properties not listed in `keptProperties`
 */
export function keepProperties(target, keptProperties) {
  const cleanTarget = {};

  for (const property in target) {
    if (typeof target[property] === 'object') {
      const subTarget = keepProperties(target[property], keptProperties);

      if (subTarget) {
        cleanTarget[property] = subTarget;
      }
    }

    if (typeof target[property] !== 'object' && keptProperties.includes(property)) {
      cleanTarget[property] = target[property];
    }
  }

  return cleanTarget;
}

const localeSearchCollator = new Intl.Collator(undefined, { sensitivity: 'base', usage: 'search' });

/**
 * Look for a substring in a reference string.
 * The search is locale-aware, case- and accent-insensitive.
 *
 * @param {string} reference String in which to look.
 * @param {string} search String to look for.
 * @returns `true` if `search` is contained in `reference`, `false` otherwise.
 */
export function fuzzyContains(reference, search) {
  if (search.length === 0) {
    return true;
  }

  reference = reference.normalize('NFC');
  search = search.normalize('NFC');

  for (let i = 0; i + search.length <= reference.length; i++) {
    const referenceSlice = reference.slice(i, i + search.length);

    if (localeSearchCollator.compare(search, referenceSlice) === 0) {
      return true;
    }
  }

  return false;
}
