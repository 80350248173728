import { userFullname } from 'controllers/userController';
import { SignatureFrame } from 'components/mainComponents/SignatureFrame';

import './TrainingSessionSignatures.scss';

export function TrainingSessionSignatures(props) {
  return (
    <div className='TrainingSessionSignatures'>
      {props.instructors.map(instructor => {
        const signature = props.signatures.find(signature => signature.trainingSessionInstructor?.id === instructor.id);

        return <SignatureFrame name={userFullname(instructor.instructor)} src={signature?.signature} key={instructor.id} />;
      })}
      {props.trainees.map(trainee => {
        const signature = props.signatures.find(signature => signature.customerTrainee?.id === trainee.id);

        return <SignatureFrame name={userFullname(trainee.trainee)} src={signature?.signature} key={trainee.id} />;
      })}
    </div>
  );
}
