export const illustrationNames = [
  'calendar',
  'chat',
  'document-blank',
  'document',
  'e-learning',
  'folder-blank',
  'folder-four',
  'folder',
  'loading-spinner-full',
  'loading-spinner-half',
  'loading-spinner-one-quarter',
  'loading-spinner-three-quarters',
  'map',
  'regis-hello',
  'robot-bye',
  'robot-disappointed',
  'robot-head-amazed',
  'robot-head-amused',
  'robot-head-neutral',
  'robot-levitation',
  'robot-surfing',
  'robot-with-stars',
  'rocket',
  'user',
  'user-boy',
  'user-confused',
  'user-girl',
  'user-questioning',
  'user-sleeping',
  'user-student',
];
