import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useDisplayModeComponent from 'hooks/useDisplayModeComponent';
import FeaturedSpeakerDisplayMode from 'components/video_conference/CallBox/DisplayModes/FeaturedSpeakerDisplayMode';
import useParticipants from 'contexts/video_conference/ParticipantsContext';

const AutoFullscreenContext = React.createContext();

// TODO: it should not depend on tiles, but just participants
const useAutoFullscreenState = () => {
  const displayModeComponent = useDisplayModeComponent();

  const [fullscreenTileId, setFullscreenTileId] = useState(null);

  // const tileInfo = useTileInfo();
  const normalizedParticipants = useParticipants();
  // const screenSharingTiles = useMemo(() => tileInfo.filter((tile) => tile.screen), [tileInfo]);
  const screenSharingParticipants = useMemo(() => normalizedParticipants.filter(tile => tile.screen), [normalizedParticipants]);

  const prevScreenSharingIds = useRef(screenSharingParticipants.map(tile => tile.sessionId));

  useEffect(() => {
    // Full screen tile stopped screen sharing
    if (
      prevScreenSharingIds.current.includes(fullscreenTileId) &&
      !screenSharingParticipants.some(tile => tile.sessionId === fullscreenTileId)
    ) {
      setFullscreenTileId(null);
    }

    // There is a new screen sharer
    if (screenSharingParticipants.length > prevScreenSharingIds.current.length) {
      if (screenSharingParticipants.length === 1 && ![FeaturedSpeakerDisplayMode].includes(displayModeComponent)) {
        setFullscreenTileId(screenSharingParticipants[0].sessionId);
      } else {
        setFullscreenTileId(null);
      }
    }
    prevScreenSharingIds.current = screenSharingParticipants.map(tile => tile.sessionId);
  }, [displayModeComponent, fullscreenTileId, screenSharingParticipants]);

  return [fullscreenTileId, setFullscreenTileId];
};

export const AutoFullscreenProvider = ({ children }) => {
  const [fullscreenTileId, setFullscreenTileId] = useAutoFullscreenState();

  return <AutoFullscreenContext.Provider value={[fullscreenTileId, setFullscreenTileId]}>{children}</AutoFullscreenContext.Provider>;
};

AutoFullscreenProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAutoFullscreen = () => useContext(AutoFullscreenContext);

export default useAutoFullscreen;
