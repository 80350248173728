import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatSlotDate } from '../../support/date';

const PENDING_SIGNINGS_QUERY = gql`
  query pending_signins_panel($id: ID!) {
    trainingSession(id: $id) {
      id
      show_signing_button_in_extranet
      pendingSlotsToSign {
        id
        subsession {
          name
        }
        date
        startTime
        endTime
      }
    }
  }
`;

const PendingSigninsPanel = props => {
  const { loading, error, data } = useQuery(PENDING_SIGNINGS_QUERY, {
    variables: { id: props.training_session_id },
  });

  if (loading) return null;

  if (error) return null;

  const { trainingSession } = data;
  const { pendingSlotsToSign } = trainingSession;
  if (pendingSlotsToSign.length === 0) return <NoAttendanceToSign {...props} />;

  return (
    <section className='training-session-attendances'>
      <h2>{props.t('PendingSignings.attendances_to_sign')}</h2>
      <div className='signin-link-list'>
        {pendingSlotsToSign.map((date, idx) => (
          <SigninLink key={idx} id={trainingSession.id} dateSlot={date} />
        ))}
      </div>
    </section>
  );
};

const NoAttendanceToSign = props => (
  <section className='training-session-attendances'>
    <h2>{props.t('PendingSignings.no_attendances_to_sign')}</h2>
  </section>
);

const SigninLink = ({ id: tsId, dateSlot }) => {
  const { id: slotId, subsession } = dateSlot;

  return (
    <div className='signin-link'>
      <Link to={`/ts/${tsId}/attendance/sign/single?date=${slotId}&goback=true`}>
        <div>{subsession.name}</div>
        <div> {formatSlotDate(dateSlot)}</div>
      </Link>
    </div>
  );
};

export default withTranslation()(PendingSigninsPanel);
