import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import ReactDOM from 'react-dom';
import propTypes from 'prop-types';

import '../../scss/Vex.scss';

export const Type = {
  Alert: 'alert',
  Confirm: 'confirm',
  Prompt: 'prompt',
};

const wrap = maybeList => {
  if (Array.isArray(maybeList)) {
    return maybeList;
  }

  return [maybeList];
};

class Vex extends Component {
  instance = null;

  constructor(props) {
    super(props);

    this.onCallback = this.onCallback.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }

  openDialog() {
    const options = {};
    const children = wrap(this.props.children).filter(c => !!c); // remove null or undefined children

    const messageComponent = children.find(child => {
      if (child.type === VexMessage) {
        return true;
      }

      return false;
    });

    const inputComponent = children.find(child => {
      if (child.type === VexInput) {
        return true;
      }

      return false;
    });

    const buttons = children.filter(child => {
      if (child.type === VexButton) {
        return true;
      }

      return false;
    });

    options.unsafeMessage = VexMessage.toString(messageComponent).replace('&#x27;', "'");
    if (inputComponent) {
      options.input = VexInput.toString(inputComponent);
    }
    options.className = [this.props.className, window.vex.defaultOptions.className].join(' ');
    options.callback = this.onCallback;

    if (buttons.length) {
      options.buttons = buttons.map(VexButton.toObject);
    }

    this.instance = window.vex.dialog.open(options);

    if (inputComponent) {
      const inputEl = this.instance.contentEl.querySelector('.vex-dialog-input');

      ReactDOM.hydrate(inputComponent, inputEl);
    }
  }

  componentDidMount() {
    if (this.props.open) {
      this.openDialog();
    }
  }

  componentWillReceiveProps(props) {
    if (props.open && !this.props.open) {
      this.openDialog();
    }

    if (!props.open && this.props.open) {
      this.instance.close();
    }
  }

  onCallback(value) {
    const { onConfirm, onCancel } = this.props;
    if (value) {
      onConfirm && onConfirm(value);
    } else {
      onCancel && onCancel();
    }
  }

  render() {
    return null;
  }
}

Vex.propTypes = {
  open: propTypes.bool.isRequired,
  type: propTypes.oneOf(['custom', ...Object.values(Type)]),
  placeholder: propTypes.oneOfType([propTypes.node, propTypes.string]),
  children: propTypes.oneOfType([propTypes.node, propTypes.string]).isRequired,
  onConfirm: propTypes.func,
  onCancel: propTypes.func,
};

export class VexMessage extends Component {
  //  eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  static toString(node) {
    return renderToString(<VexMessage {...node.props} />);
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export class VexInput extends VexMessage {}

export class VexButton extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  static toObject({ props }) {
    const { type, children, className } = props;

    const classes = wrap(className);
    let button = window.vex.dialog.buttons.YES;
    switch (type) {
      case 'primary':
        classes.push('vex-dialog-button-primary');
        button.className = classes.join(' ');
        break;

      default:
        classes.push('vex-dialog-button-secondary');
        button = window.vex.dialog.buttons.NO;
        button.className = classes.join(' ');
        break;
    }

    button.text = children;
    return button;
  }

  render() {
    return null;
  }
}

Vex.Message = VexMessage;
Vex.Input = VexInput;
Vex.Button = VexButton;

export default Vex;
