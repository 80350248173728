import React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { useTranslation } from 'react-i18next';
import { withData, withRouteParams } from 'support/page';

import { Button } from 'components/mainComponents/Button';
import { Breadcrumb } from 'components/mainComponents/BreadcrumbV2';
import { Heading } from 'components/mainComponents/Heading';
import { LeftMenuLayout } from 'components/v2/layouts/LeftMenuLayout';
import { Paper } from 'components/mainComponents/Paper';
import { PageLoader } from 'components/v2/layouts/PageLoader';

import './EvaluationsPage.scss';

function Evaluations({ trainingSession }) {
  const { t } = useTranslation();
  const trainingSessionName = trainingSession.custom_name || trainingSession.name;
  const evaluations = trainingSession.pending_evaluations;

  return (
    <LeftMenuLayout>
      <Breadcrumb>
        <Breadcrumb.Item url={`/ts/${trainingSession.id}`}>{trainingSessionName}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('EvaluationsPage.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <section className='V2__EvaluationsPage'>
        <Heading className='V2__EvaluationsPage__title' as='h1' level={1}>
          {t('EvaluationsPage.title')}
        </Heading>
        {evaluations.length === 0 && <Heading level={3}>{t('EvaluationsPage.no_evaluation')}</Heading>}
        <ul className='V2__EvaluationsPage__list'>
          {evaluations.map(evaluation => {
            return <EvaluationItem evaluation={evaluation} key={evaluation.evaluation.id} />;
          })}
        </ul>
      </section>
    </LeftMenuLayout>
  );
}

const EVALUATIONS_QUERY = gql`
  query training_session_evaluations($id: ID!) {
    trainingSession(id: $id) {
      id
      name
      custom_name
      pending_evaluations {
        pending
        trainee_id
        trainee_name
        evaluation {
          id
          name
          type
        }
      }
    }
  }
`;

const EvaluationItem = ({ evaluation }) => {
  const { t } = useTranslation();
  const path = `/answer_evaluation/${evaluation.evaluation.id}/${evaluation.trainee_id}`;
  const isCompleted = !evaluation.pending;

  return (
    <Paper className='EvaluationItem' as='li'>
      <p>{evaluation.evaluation.name}</p>
      {isCompleted ? (
        <p className='EvaluationItem__subtitle'>{t('EvaluationsPage.subtitle')}</p>
      ) : (
        <Button className='EvaluationItem__link' as='a' href={path} size='sm'>
          {t('EvaluationsPage.action')}
        </Button>
      )}
    </Paper>
  );
};

const withGraphqlData = graphql(EVALUATIONS_QUERY, {
  name: 'trainingSession',
  options: props => {
    return {
      variables: { id: props.params.training_session_id },
    };
  },
});

// prettier-ignore
export const EvaluationsPage =
withRouteParams(
  withGraphqlData(
    withData('trainingSession')(
      Evaluations, { loading: PageLoader })));
