import Icon from 'components/mainComponents/Icon';
import { RadioButton } from 'components/mainComponents/RadioButton';
import Modal from 'components/video_conference/Modal';
import { DISPLAY_MODE_AUTO, DISPLAY_MODE_FEATURED_SPEAKER, DISPLAY_MODE_GRID, DISPLAY_MODE_MODAL } from 'constants/video_conference';
import { useSettingsPersistence } from 'hooks/video_conference';
import useDisplayMode from 'hooks/video_conference/useDisplayMode';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../../scss/video_conference/DisplayModeModal.module.scss';

function DisplayModeModal() {
  const { t } = useTranslation();
  const [displayMode, { changeMode }] = useDisplayMode();
  const persistSetting = useSettingsPersistence();

  const handleDisplayModeChange = ({ target: { value: newDisplayMode } }) => {
    persistSetting('displayMode', newDisplayMode);
    changeMode(newDisplayMode);
  };

  const displayModeOptions = [
    { value: DISPLAY_MODE_FEATURED_SPEAKER, labelKey: 'Settings.active_speaker' },
    { value: DISPLAY_MODE_GRID, labelKey: 'Settings.grid' },
    { value: DISPLAY_MODE_AUTO, labelKey: 'Settings.auto' },
  ];

  const DISPLAY_MODE_BUTTON_ICONS = {
    FEATURED_SPEAKER: 'layout-spotlight',
    GRID: 'layout-tiled',
    AUTO: 'layout-auto',
  };

  return (
    <Modal modalId={DISPLAY_MODE_MODAL} title={t('DisplayModeModal.title')}>
      <fieldset className={styles.formGroup}>
        {displayModeOptions.map(({ value, labelKey }) => (
          <label className={styles.option} key={value}>
            <RadioButton
              variant='accent'
              size='large'
              name='display_mode'
              value={value}
              checked={displayMode === value}
              onChange={handleDisplayModeChange}
            />

            <span className={styles.label}>{t(labelKey)}</span>
            <Icon icon={DISPLAY_MODE_BUTTON_ICONS[value]} className={styles.icon} />
          </label>
        ))}
      </fieldset>
    </Modal>
  );
}

export default DisplayModeModal;
