import React from 'react';
import PropTypes from 'prop-types';
import { PaletteColorPropType } from 'propTypes';
import { Paper } from 'components/mainComponents/Paper';
import { RoundedIcon } from 'components/mainComponents/RoundedIcon';
import { Heading } from 'components/mainComponents/Heading';
import { IconedList } from 'components/mainComponents/IconedList';

import './ProgramCardWidget.scss';

export function ProgramCardWidget({ title, icon, color, children }) {
  return (
    <Paper className='ProgramCardWidget'>
      <div className='ProgramCardWidget__title'>
        <RoundedIcon icon={icon} background={color} />
        <Heading level={3} as='h2'>
          {title}
        </Heading>
      </div>
      {children}
    </Paper>
  );
}

ProgramCardWidget.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string.isRequired,
  color: PaletteColorPropType,
  children: PropTypes.node,
};

ProgramCardWidget.Item = props => {
  if (props.elements.length === 0) return null;
  return (
    <div className='ProgramCardWidget__item'>
      {props.secondaryTitle && (
        <Heading level={4} as='h3' className='ProgramCardWidget__item__secondary-title'>
          {props.secondaryTitle}
        </Heading>
      )}
      <IconedList {...props} icon='caret--right' />
    </div>
  );
};

ProgramCardWidget.Item.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  secondaryTitle: PropTypes.string,
};

ProgramCardWidget.Text = ({ children }) => <p className='ProgramCardWidget__text'>{children}</p>;

ProgramCardWidget.Text.propTypes = {
  children: PropTypes.node,
};
