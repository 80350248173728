import { ADD_STATUS_BAR, CLOSE_STATUS_BAR } from 'actions/video_conference/statusBarActions';
import React, { useReducer, useContext, useCallback } from 'react';
import _ from 'lodash';

export const StatusBarsStateContext = React.createContext();
export const StatusBarsDispatchContext = React.createContext();

const reducer = (oldStatusBars, { action, payload }) => {
  switch (action) {
    case ADD_STATUS_BAR: {
      const { newStatusBars } = payload;

      const statusBars = _.filter(newStatusBars, bar => !_.find(oldStatusBars, { id: bar.id }));

      return [...oldStatusBars, ...statusBars];
    }

    case CLOSE_STATUS_BAR: {
      const { statusBarId } = payload;

      return oldStatusBars.filter(statusBar => statusBar.id !== statusBarId);
    }

    default:
      return oldStatusBars;
  }
};

export default reducer;

function useStatusBars() {
  const [statusBars, dispatchStatusBar] = useReducer(reducer, []);

  const closeStatusBar = useCallback(
    statusBarId => {
      dispatchStatusBar({
        action: CLOSE_STATUS_BAR,
        payload: {
          statusBarId,
        },
      });
    },
    [dispatchStatusBar]
  );

  const addStatusBar = useCallback(
    newStatusBars => {
      dispatchStatusBar({
        action: ADD_STATUS_BAR,
        payload: {
          newStatusBars,
        },
      });
    },
    [dispatchStatusBar]
  );

  return { statusBars, closeStatusBar, addStatusBar };
}

export function StatusBarsProvider({ children }) {
  const { statusBars, closeStatusBar, addStatusBar } = useStatusBars();

  const actions = {
    closeStatusBar,
    addStatusBar,
  };

  return (
    <StatusBarsStateContext.Provider value={statusBars}>
      <StatusBarsDispatchContext.Provider value={actions}>{children}</StatusBarsDispatchContext.Provider>
    </StatusBarsStateContext.Provider>
  );
}

export function useStatusBarsDispatch() {
  return useContext(StatusBarsDispatchContext);
}

export function useStatusBarsState() {
  return useContext(StatusBarsStateContext);
}
