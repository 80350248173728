import React, { useState } from 'react';
import { getLocale } from 'translations/i18n';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import fullCalendarFr from '@fullcalendar/core/locales/fr';
import fullCalendarDe from '@fullcalendar/core/locales/de';
import fullCalendarEs from '@fullcalendar/core/locales/es';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTitle,
} from 'components/mainComponents/AlertDialog';
import { Button } from 'components/mainComponents/Button';
import { getGuestType, GUEST_TYPE } from 'support/auth';
import { localTimeFormat } from 'support/date';
import { Paper } from 'components/mainComponents/Paper';
import { Select } from 'components/mainComponents/Select';
import Icon from 'components/mainComponents/Icon';
import useDisclosure from 'hooks/useDisclosure/useDisclosure';

import './Calendar.scss';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

const ALL_INSTRUCTORS = 'ALL_INSTRUCTORS';
const FULL_CALENDAR_LOCALES = [fullCalendarFr, fullCalendarDe, fullCalendarEs];

export function Calendar({ trainingSession, events }) {
  const { t } = useTranslation();
  const guestType = getGuestType();
  const currentLocale = getLocale();
  const instructors = trainingSession.instructors;
  const [selectedInstructorId, setSelectedInstructorId] = useState(ALL_INSTRUCTORS);
  const { googleLink, webcalLink } = addNecessaryParamsToUrls(selectedInstructorId, guestType, trainingSession);
  const [chosenCalendar, setChosenCalendar] = useState(googleLink);
  const { isOpen: isExportDialogOpen, setIsOpen: setExportDialogOpen } = useDisclosure();

  function selectInstructor(instructorId) {
    setSelectedInstructorId(instructorId);
    getInstructorEvents(selectedInstructorId);
  }

  const calendarEvents = getInstructorEvents(selectedInstructorId);

  return (
    <>
      <Paper className='Calendar'>
        {guestType === 'instructor' && instructors.length > 1 && (
          <Select value={selectedInstructorId} onChange={selectInstructor}>
            <Select.Group>
              <Select.GroupTitle>{t('Select.select_instructors')}</Select.GroupTitle>
              <Select.Item value={ALL_INSTRUCTORS} key='allInstructor'>
                {t('Select.select_instructors')}
              </Select.Item>
              {instructors.map(instructor => {
                return (
                  <Select.Item key={instructor.id} value={instructor.id}>
                    {instructor.lastname} {instructor.firstname}
                  </Select.Item>
                );
              })}
            </Select.Group>
          </Select>
        )}
        <FullCalendar
          eventContent={Event}
          events={calendarEvents}
          headerToolbar={{
            start: 'today,prev',
            center: 'title',
            end: 'next,dayGridMonth,listYear',
          }}
          footerToolbar={true}
          initialView='listYear'
          locale={currentLocale}
          locales={FULL_CALENDAR_LOCALES}
          plugins={[listPlugin, dayGridPlugin]}
          height='100%'
        />
      </Paper>
      <div className='Calendar__export'>
        <Button onClick={() => setExportDialogOpen(true)}>{t('Calendar.export')}</Button>
        <span>
          {t('Calendar.export_link')} :
          <input type='text' value={webcalLink} placeholder={webcalLink} readOnly={true} />
        </span>
        <span onClick={() => copyToClipboard(webcalLink)}>
          <Icon icon='copy' className='Calendar__icon Calendar__icon__copy' />
        </span>
      </div>
      <AlertDialog open={isExportDialogOpen} onOpenChange={setExportDialogOpen}>
        <AlertDialogTitle>{t('Calendar.export_my_calendar')}</AlertDialogTitle>
        <AlertDialogContent>
          <p>{t('Calendar.export_type')}</p>
          <Select value={chosenCalendar} onChange={setChosenCalendar}>
            <Select.Group>
              <Select.Item value={googleLink} key='Google'>
                Google
              </Select.Item>
              <Select.Item value={webcalLink} key='Webcal'>
                {t('Calendar.export_to_default_calendar')}
              </Select.Item>
            </Select.Group>
          </Select>
        </AlertDialogContent>
        <AlertDialogActions>
          <AlertDialogCancel>
            <Button>{t('Calendar.cancel')}</Button>
          </AlertDialogCancel>
          <AlertDialogAction>
            <Button>
              <Link to={chosenCalendar} target='_blank'>
                {t('Calendar.export')}
              </Link>
            </Button>
          </AlertDialogAction>
        </AlertDialogActions>
      </AlertDialog>
    </>
  );

  function getInstructorEvents(selectedInstructorId) {
    if (selectedInstructorId === ALL_INSTRUCTORS) {
      return events;
    } else {
      return events.filter(event => event.instructors.some(instructorData => instructorData.instructor.id === selectedInstructorId));
    }
  }
}

function Event(props) {
  const view = props.view.type;
  const timeString = `${localTimeFormat(props.event.start)} - ${localTimeFormat(props.event.end)}`;
  const instructors = props.event.extendedProps.instructors
    .map(instructor => `${instructor.instructor.firstname} ${instructor.instructor.lastname}`)
    .join(', ');

  return (
    <div className={`Calendar__event__${view}`}>
      {view === 'dayGridMonth' && <p>{timeString}</p>}
      <p>{props.event.extendedProps.name}</p>
      <p>{props.event.extendedProps.modality}</p>
      {view === 'listYear' && (
        <div className='Calendar__event__instructor'>
          <div className='Calendar__event__instructor__icon'>
            <SvgIcon name='user--circle' />
          </div>
          <p>{instructors}</p>
        </div>
      )}
    </div>
  );
}

function addNecessaryParamsToUrls(selectedInstructorId, guestType, { dates_google_url, dates_webcal_url, id, subsessions }) {
  const webcal = new URL(dates_webcal_url);
  const google = new URL(dates_google_url);

  webcal.searchParams.append('sessions[]', id);
  webcal.searchParams.append('subsession_dates_are_interval', false);
  if (guestType === GUEST_TYPE.TRAINEE) {
    subsessions.flatMap(({ id }) => webcal.searchParams.append('subsessions[]', id));
  }

  if (selectedInstructorId !== ALL_INSTRUCTORS) {
    webcal.searchParams.append('instructors[]', selectedInstructorId);
  }

  const webcalLink = webcal.toString().replace(webcal.protocol, 'webcal:');
  google.searchParams.append('cid', webcalLink);

  return {
    webcalLink: webcalLink,
    googleLink: google.toString(),
  };
}

function copyToClipboard(webcalLink) {
  navigator.clipboard?.writeText(webcalLink);
}
