import React from 'react';
import PropTypes from 'prop-types';

import '../../scss/mainComponents/ToggleSwitch.scss';

export const ToggleSwitch = ({ label, state, className, ...inputProps }) => {
  const isDisabled = state === 'disabled' ? true : false;

  return (
    <label className={['toggle-switch', `toggle-switch--${state}`, className].join(' ')} title={label}>
      <input type='checkbox' disabled={isDisabled} {...inputProps} />
      <span className={['toggle-switch__slider', `toggle-switch__slider--${state}`].join(' ')}></span>
      {label && label}
    </label>
  );
};

ToggleSwitch.propTypes = {
  state: PropTypes.oneOf(['default', 'disabled']),
  label: PropTypes.string,
  className: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  state: 'default',
  className: '',
};
