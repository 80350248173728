import moment from 'moment';
import { setMomentLocale, getLocale } from 'translations/i18n';

/**
 * Format a date to local date string
 * @param {string} date A valid date string
 * @param {bool} isUtc Whether the given date is UTC or local
 *
 * @returns {string} A string representing the date in the local format
 *
 * @example
 * let formatted = localDateFormat("2019-07-25")
 * assert(formatted === "25/07/2019") // when locale = "fr"
 */
export function localDateFormat(date, isUtc = false) {
  setMomentLocale(getLocale());
  const momentDate = isUtc ? moment.utc(date).local() : moment(date);
  return momentDate.format('L');
}

/**
 * Formatte l'heure pour l'affichage
 * @param {string} date Une date parsable par momentJS
 * @returns {string} Heure au format HH:mm
 * @example
 * let formatted = formatTime("22:12")
 * assert(formatted === "22:12")
 */
export function formatTime(time) {
  setMomentLocale(getLocale());
  return moment(time, 'HH:mm:ss').format('HH:mm');
}

/**
 * Format a date to locale time display
 *
 * @param {string | Date} date Any date parsable by Momentjs
 * @param {bool} isUtc Whether the given date is UTC or local
 *
 * @returns {string} Time in a locale-compliant format
 *
 * @example
 * const formatted = localTimeFormat("2023-04-19T16:04:52.360Z")
 * assert(formatted === "18:04") // with locale = "fr"
 */
export function localTimeFormat(date, isUtc = false) {
  setMomentLocale(getLocale());
  const momentDate = isUtc ? moment.utc(date).local() : moment(date);
  return momentDate.format('LT');
}

/**
 * Format a date to local date & time string.
 * @param {string} date A valid date string.
 * @param {"short" | "long"} dateFormat Whether to use a long or short format for the date. Defaults to `short`.
 * @param {bool} isUtc Whether the given date is UTC or local. Defaults to `false`.
 *
 * @returns {string} A string representing the date & time in the local format.
 *
 * @example
 * let formatted = localDateTimeFormat("2019-07-25 18:00:00")
 * assert(formatted === "25/07/2019 18:00") // when locale = "fr"
 *
 * formatted = localDateTimeFormat("2019-07-25 18:00:00", "long")
 * assert(formatted === "Jeudi 25 juillet 2019 18:00") // when locale = "fr"
 */
export function localDateTimeFormat(date, dateFormat = 'short', isUtc = false) {
  setMomentLocale(getLocale());
  const momentDate = isUtc ? moment.utc(date).local() : moment(date);

  if (dateFormat === 'short') {
    return `${localDateFormat(date, isUtc)} ${localTimeFormat(date, isUtc)}`;
  } else {
    return momentDate.format('LLLL');
  }
}

/**
 * Format a date to relative time string from the given date to now.
 * @param {string} date A valid date string.
 * @param {bool} isUtc Whether the given date is UTC or local.
 *
 * @returns {string} A string representing the relative time from the given date to now.
 *
 * @example
 * let relativeTime = relativeTime("2019-07-25T15:54:00")
 * assert(relativeTime === "Il y a 5 minutes") // when locale = "fr" and now = 2019-07-25 15:59:00
 */
export function relativeTime(date, isUtc = false) {
  setMomentLocale(getLocale());
  const momentDate = isUtc ? moment.utc(date).local() : moment(date);
  return momentDate.fromNow();
}

export const formatSlotTime = ({ startTime, endTime }) => `(${formatTime(startTime)} - ${formatTime(endTime)})`;

export function formatSlotDate({ date, startTime, endTime }) {
  return localDateFormat(date) + ' (' + formatTime(startTime) + ' - ' + formatTime(endTime) + ')';
}

/**
 * Format a number of seconds to a string with hours and minutes.
 * This format is **not** localized.
 *
 * @param {number} durationInSeconds Duration, in seconds, to format.
 * @returns {Object} An object containing the formatted string, the number of hours and the number of minutes:
 *  `{formattedString: string, hours: number, minutes: number}`
 *
 * @example
 * let formatted = formatDuration(7400);
 * assert(formatted.formattedString === "2h03")
 */
export function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const formattedString = `${hours}h${minutes.toString().padStart(2, '0')}`;

  return { formattedString, hours, minutes };
}

export const timestamp = dateString => {
  if (dateString) {
    return moment.utc(dateString).format('X');
  }
  return moment.utc().format('X');
};
