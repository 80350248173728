import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useParticipants from 'contexts/video_conference/ParticipantsContext';
import { useCallStatusDispatch, useCallStatusState } from 'hooks/video_conference';
import styles from 'scss/video_conference/Participants.module.scss';
import RemoteParticipant from 'components/video_conference/Sidebar/RemoteParticipant';
import useProblemIndicatorUpdating from './useProblemIndicatorUpdating';
import useScrollOnHover from 'hooks/video_conference/useScrollOnHover';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import { PARTICIPANTS_ELEMENT } from 'constants/video_conference/roomElements';
import useMedia from 'hooks/video_conference/useMedia';
import IconButton from 'components/video_conference/IconButton';
import { useTranslation } from 'react-i18next';
import { MD_BREAKPOINT } from 'constants/breakpoints';
import variables from 'scss/video_conference/_export.scss';
import useResizeObserver from 'use-resize-observer';

const PARTICIPANTS_CONTAINER_PADDING = parseInt(variables['participants-padding'], 10) * 2;

function Participants() {
  const callStatus = useCallStatusState();
  const dispatch = useCallStatusDispatch();
  const { participantQuality } = callStatus;
  const { t } = useTranslation();

  useProblemIndicatorUpdating({ participantQuality, dispatch });

  const normalizedParticipants = useParticipants();
  const { roomElements, closeRoomElement } = useRoomElementsDisplay();
  const isDesktop = useMedia(MD_BREAKPOINT);

  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  const { height } = useResizeObserver({ ref: containerRef });
  const { handleMouseEnterTop, handleMouseEnterBottom, handleMouseLeave } = useScrollOnHover({
    scrollRef,
    containerRef,
  });

  const [scrollTop, setScrollTop] = useState(0);
  const [isTopVisible, setIsTopVisible] = useState(false);
  const [isBottomVisible, setIsBottomVisible] = useState(false);

  const isVisible = roomElements.includes(PARTICIPANTS_ELEMENT) || isDesktop;

  const handleCloseParticipants = () => closeRoomElement(PARTICIPANTS_ELEMENT);
  const handleScroll = event => setScrollTop(event.currentTarget.scrollTop);

  useEffect(() => {
    setIsTopVisible(scrollTop > 0);
    setIsBottomVisible(
      scrollTop + containerRef.current?.clientHeight < scrollRef.current?.scrollHeight + PARTICIPANTS_CONTAINER_PADDING
    );
  }, [height, normalizedParticipants.length, scrollTop]);

  if (normalizedParticipants.length) {
    const remoteParticipants = _.reject(normalizedParticipants, participant => participant.local);

    return (
      isVisible && (
        <div className={styles.container} ref={containerRef}>
          {!isDesktop && (
            <IconButton
              icon='cross'
              ariaLabel={t('Participants.close')}
              clickFn={handleCloseParticipants}
              type='secondary'
              className={styles.closeButton}
            />
          )}
          {isDesktop && (
            <div
              onMouseEnter={handleMouseEnterTop}
              onMouseLeave={handleMouseLeave}
              className={styles.scrollTop}
              style={{ display: isTopVisible ? 'block' : 'none' }}
            />
          )}
          <div className={styles.participants} ref={scrollRef} onScroll={handleScroll}>
            {remoteParticipants.map(participant => (
              <RemoteParticipant key={participant.sessionId} participant={participant} />
            ))}
          </div>
          {isDesktop && (
            <div
              onMouseEnter={handleMouseEnterBottom}
              onMouseLeave={handleMouseLeave}
              className={styles.scrollBottom}
              style={{ display: isBottomVisible ? 'block' : 'none' }}
            />
          )}
        </div>
      )
    );
  }

  return <div className={styles.participants}>Loading...</div>;
}

export default Participants;
