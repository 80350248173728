import React, { Component } from 'react';

import { AnswerEvaluation } from './AnswerEvaluation';
import 'scss/AnswerEvaluationPage.scss';
import { buildPatternStyle } from 'support/image';
import { withRouteParams } from 'support/page';

class AnswerEvaluationPage extends Component {
  render() {
    const patternStyle = buildPatternStyle({ name: 'x' });
    patternStyle.backgroundSize = 'normal';

    return (
      <div className='page AnswerEvaluationPage'>
        <main style={patternStyle}>
          <AnswerEvaluation evaluation_id={this.props.params.evaluationId} trainee_id={this.props.params.traineeId} />
        </main>
      </div>
    );
  }
}

export default withRouteParams(AnswerEvaluationPage);
