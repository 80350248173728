import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppPropTypes from 'propTypes';
import { RoomOptsContext } from 'contexts/video_conference';
import BasicTile from './BasicTile';
import { getUserImageUrl } from 'helpers/video_conference';
import { getTileClassName } from './utils';
import bigTileStyles from 'scss/video_conference/BigTile.module.scss';
import generalTileStyles from 'scss/video_conference/GeneralTile.module.scss';
import TileOverlay from './TileOverlay';
import TileContent from './TileContent';
import useStatusCaption from 'hooks/video_conference/useStatusCaption';
import { useDaily } from '@daily-co/daily-react';
import useCurrentSpeaker from 'contexts/video_conference/CurrentSpeakerContext';
import clsx from 'clsx';

const tileStyles = { ...generalTileStyles, ...bigTileStyles };
const screenSharingTileStyles = { ...tileStyles };

const BigTile = ({ tile }) => {
  const { usersData } = useContext(RoomOptsContext);
  const callObject = useDaily();
  const { currentSpeakerId } = useCurrentSpeaker();

  const imageUrl = getUserImageUrl(usersData, `${tile.digiformaType}-${tile.digiformaId}`);

  const styles = tile.screen ? screenSharingTileStyles : tileStyles;

  const current = tile.sessionId === currentSpeakerId;

  const tileClassName = getTileClassName(styles, {
    ...tile,
    current: current && Object.keys(callObject.participants()).length > 2,
  });

  const statusCaption = useStatusCaption();

  return (
    <BasicTile
      className={clsx(tile.bigTileStyles, tileClassName)}
      styles={styles}
      leftIcons={tile.leftIcons}
      rightIcons={tile.rightIcons}
    >
      <TileContent tile={tile} styles={styles} placeholderImageUrl={imageUrl} />

      <TileOverlay caption={statusCaption || tile.userName} styles={styles} />
    </BasicTile>
  );
};

BigTile.propTypes = {
  tile: AppPropTypes.tile.isRequired,
  caption: PropTypes.string,
  leftIcons: PropTypes.node,
  rightIcons: PropTypes.node,
};

BigTile.defaultProps = {
  caption: undefined,
  leftIcons: undefined,
  rightIcons: undefined,
};

export default BigTile;
