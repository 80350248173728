import React from 'react';
import { useTranslation } from 'react-i18next';

import Document from '../../document/Document';
import LivestreamActivity from '../activities/LivestreamActivity';
import EmailMarker from '../activities/EmailMarker';
import UploadMarker from '../activities/UploadMarker';
import Embed from '../activities/Embed';
import HtmlDoc from '../activities/HtmlDoc';
import { AnswerEvaluation } from 'components/evaluation/AnswerEvaluation';
import Scorm from '../activities/Scorm';

export default function ActivityRenderer({ previewMode, item, activity, module, training_session, guestId }) {
  const { t } = useTranslation();

  let content = null;
  switch (activity.resource_type) {
    case 'evaluation':
      content = (
        <AnswerEvaluation
          evaluation_id={item.evaluation.id}
          trainee_id={item.trainee_id}
          key={`evaluation-${item.id}`}
          previewMode={previewMode}
        />
      );
      break;
    case 'document':
      content = <Document document={item} key={`document-${item.id}`} readForNotifications />;
      break;
    case 'embed':
      content = <Embed embed={item} key={`embed-${item.id}`} />;
      break;
    case 'module_scorm':
      content = <Scorm scorm={item} previewMode={previewMode} key={`module-scorm-${item.id}`} />;
      break;
    case 'livestream':
      content = (
        <LivestreamActivity
          livestream={item}
          training_session_id={training_session.id}
          module_id={module.id}
          key={`livestream-${item.id}`}
        />
      );
      break;
    case 'html_doc':
      content = <HtmlDoc html_doc={item} traineeId={guestId} key={`html-doc-${item.id}`} previewMode={previewMode} />;
      break;
    case 'email_marker':
      content = <EmailMarker activity={activity} email={item} key={`html-email-${item.id}`} />;
      break;
    case 'upload_marker':
      content = <UploadMarker upload={item} trainee_id={guestId} key={`upload-marker-${item.id}`} />;
      break;
    default:
      break;
  }

  const classNames = ['activity', `activity-${activity.resource_type.split('_').join('-')}`].join(' ');

  return (
    <div className={classNames} key={`activity-${activity.id}`}>
      {previewMode && <PreviewBanner />}
      {activity.resource_type !== 'html_doc' && <h2>{activity.name}</h2>}
      {activity.resource_type !== 'html_doc' && <div className='activity-description'>{activity.description}</div>}
      {activity.resource_type === 'evaluation' && previewMode && (
        <div className='evaluation-preview-mode-text'>{t('ModulePage.quiz_preview_mode_button_placeholder')}</div>
      )}

      {content}
    </div>
  );
}

const PreviewBanner = () => {
  const { t } = useTranslation();
  return <div className='PreviewBanner'>{t('ModulePage.preview_banner')}</div>;
};
