import React from 'react';
import PropTypes from 'prop-types';

import 'scss/mainComponents/inputs/SimpleInput.scss';

export function SimpleInput(props) {
  const { type = 'text', label, value = '', changeFn, placeholder = '', className = '', ...forward } = props;

  const inputProps = {
    className: `input ${className}`,
    onChange: changeFn && (e => changeFn(e.target.value)),
    ...forward,
  };

  if (label) {
    return (
      <label className='input__label'>
        {label}
        <input type={type} value={value} placeholder={placeholder} {...inputProps} />
      </label>
    );
  }

  return <input type={type} value={value} placeholder={placeholder} {...inputProps} />;
}

SimpleInput.propTypes = {
  type: PropTypes.oneOf(['text', 'email']),
  label: PropTypes.string,
  value: PropTypes.string,
  changeFn: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
