import React, { useRef, useState } from 'react';
import TrainingSessionHeader from 'components/training_session/TrainingSessionHeader';
import DocumentsList from './NewDocumentsView/DocumentsList';
import DocumentsGrid from './NewDocumentsView/DocumentsGrid';
import Icon from 'components/mainComponents/Icon';
import clsx from 'clsx';
import _ from 'lodash';
import JSZip from 'jszip';
import PropTypes from 'prop-types';
import DocumentsFilter from './NewDocumentsView/DocumentsFilter';
import 'scss/NewDocumentsPage.scss';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'components/mainComponents/Breadcrumb/Breadcrumb';
import { gql, useQuery } from '@apollo/client';
import { IconButton } from 'components/mainComponents/IconButton';
import { blobAndStoreInFolder, sortByDate, sortByName } from './DocumentUtils';

const DOCUMENTS_VIEW = {
  LIST: 'list',
  GRID: 'grid',
};

const SORTING_OPTIONS = {
  DATE: sortByDate,
  NAME: sortByName,
};

const NewDocumentPage = ({ training_session: { id } }) => {
  const { t } = useTranslation();
  const [documentsView, setDocumentsView] = useState(DOCUMENTS_VIEW.LIST);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [folders, setFolders] = useState();
  const [documents, setDocuments] = useState();
  const searchInput = useRef();
  const { data, loading } = useQuery(DOCUMENTS_QUERY, {
    variables: { id: id },
    onCompleted: ({ training_session }) => {
      const folders = training_session.folders_with_documents.map(folder => {
        const documents = folder.documents.map(document => ({ ...document, isShown: true }));
        return { ...folder, documents, isShown: true };
      });
      const documents = training_session.base_documents.map(document => ({ ...document, isShown: true }));
      setFolders(folders);
      setDocuments(documents);
    },
  });

  const currentFolderPath = selectedFolder?.name ?? t('NewDocumentsPage.base');
  const backpathStyles = clsx('new-documents__control__folder__back-path', {
    'new-documents__div-disabled': _.isEmpty(selectedFolder),
  });

  if (!loading) {
    const { training_session } = data;
    const trainingSessionName = training_session.custom_name || training_session.name;

    const download = () => {
      const zip = new JSZip();
      const mainFolder = zip.folder(trainingSessionName);

      if (_.isEmpty(selectedFolder)) {
        const selectedFolders = folders.reduce((folders, folder) => addFoldersForDownload(folders, folder), []);
        documents.forEach(({ url, filename }) => blobAndStoreInFolder(url, filename, mainFolder));
        selectedFolders.forEach(folder => {
          const nestedFolder = mainFolder.folder(folder.name);
          folder.documents.forEach(({ url, filename }) => blobAndStoreInFolder(url, filename, nestedFolder));
        });
      } else {
        selectedFolder.documents.forEach(({ url, filename }) => blobAndStoreInFolder(url, filename, mainFolder));
      }

      zip.generateAsync({ type: 'blob' }).then(blob => saveAs(blob, trainingSessionName));
    };

    const addFoldersForDownload = (folders, folder) => {
      const repeatedFolderId = folders.findIndex(({ name }) => name === folder.name);
      if (repeatedFolderId !== -1) {
        const mergedDocuments = folders[repeatedFolderId].documents.concat(folder.documents);
        folders[repeatedFolderId] = { ...folders[repeatedFolderId], documents: mergedDocuments };
        return folders;
      }

      return [...folders, folder];
    };

    const moveBack = () => {
      searchInput.current.value = '';
      setSelectedFolder({});
      searchDocuments('', {});
    };

    const searchDocuments = (documentsFilterInput, currentFolder = selectedFolder) => {
      const filteredValue = documentsFilterInput.toLowerCase();
      // If we are currently in the folder, then we should only filter by documents of given folder
      if (!_.isEmpty(currentFolder)) return searchDocumentsInFolder(filteredValue, currentFolder);

      let updatedFolders = folders;
      let updatedDocuments = documents;
      // If input === '', then show all of the folders and documents
      if (filteredValue === '') {
        updatedFolders = folders.map(folder => {
          const documents = folder.documents.map(document => ({ ...document, isShown: true }));
          return { ...folder, documents, isShown: true };
        });

        // Show only documents, that does not belong to any folder
        updatedDocuments = documents.reduce((documentsList, document) => {
          if (document.folder_id === null) documentsList.push({ ...document, isShown: true });
          return documentsList;
        }, []);
      } else {
        // Filter folders
        updatedFolders = folders.map(folder => {
          const isFiltered = folder.name.toLowerCase().includes(filteredValue);
          return { ...folder, isShown: isFiltered };
        });
        // Filter documents
        updatedDocuments = documents.map(document => {
          const isFiltered = document.filename.toLowerCase().includes(filteredValue);
          return { ...document, isShown: isFiltered };
        });
      }

      setFolders(updatedFolders);
      setDocuments(updatedDocuments);
    };

    const searchDocumentsInFolder = (filteredValue, currentFolder) => {
      let currentlyDisplayedDocuments = documents;
      if (filteredValue === '') {
        currentlyDisplayedDocuments = documents.map(document => {
          return { ...document, isShown: document.folder_id === currentFolder.id };
        });
      } else {
        currentlyDisplayedDocuments = documents.map(document => {
          const includesSearchedPhrase = document.filename.toLowerCase().includes(filteredValue);
          const isFiltered = includesSearchedPhrase && document.folder_id === currentFolder.id;
          return { ...document, isShown: isFiltered };
        });
      }

      return setDocuments(currentlyDisplayedDocuments);
    };

    // Filter by properites
    const sortDocumentsBy = filterOption => {
      const sortBy = SORTING_OPTIONS[filterOption.toUpperCase()];
      if (sortBy) {
        const sortedFdolders = sortBy(folders);
        const sortedDocuments = sortBy(documents, 'document');

        setFolders(sortedFdolders);
        setDocuments(sortedDocuments);
      }
    };

    return (
      <div className='page'>
        <TrainingSessionHeader training_session_id={id} />
        <main>
          <Breadcrumb
            links={[
              {
                name: trainingSessionName,
                url: `/ts/${id}`,
              },
              { name: t('TrainingSessionPage.personal_documents') },
            ]}
          />
          <h1 className='new-documents__title'>{t('NewDocumentsPage.my_documents')}</h1>
          <div className='new-documents__base'>
            <div className='new-documents__control'>
              <div className='new-documents__control__folder'>
                <div className={backpathStyles} onClick={moveBack}>
                  <Icon icon='arrow--left' />
                  <p className='new-documents__control__folder-back'>{t('NewDocumentsPage.go_back')}</p>
                </div>
                <p className='new-documents__control__folder-path'>
                  {t('NewDocumentsPage.current_folder')} {currentFolderPath}
                </p>
              </div>
              <div className='new-documents__control-panel'>
                <IconButton
                  icon='list--boxes'
                  className='new-documents__control-panel__child new-documents_control-panel__selected-view'
                  id='listed_view'
                  onClick={() => toggleDocumentView(DOCUMENTS_VIEW.LIST, setDocumentsView)}
                />
                <IconButton
                  icon='image'
                  className='new-documents__control-panel__child'
                  id='grid_view'
                  onClick={() => toggleDocumentView(DOCUMENTS_VIEW.GRID, setDocumentsView)}
                />
                <DocumentsFilter sortDocumentsBy={sortDocumentsBy} />
                <input
                  ref={searchInput}
                  type='text'
                  placeholder={t('NewDocumentsPage.search')}
                  className='new-documents__control-panel__child new-documents__control-panel__search'
                  id='filter_documents'
                  onChange={({ currentTarget: { value } }) => searchDocuments(value)}
                />
              </div>
            </div>
            <div className='new-docouments__download-panel'>
              <IconButton icon='download' className='new-documents__download-panel__child' onClick={download} />
            </div>
            {documentsView === DOCUMENTS_VIEW.LIST ? (
              <DocumentsList
                folders={folders}
                setFolders={setFolders}
                setSelectedFolder={setSelectedFolder}
                documents={documents}
                setDocuments={setDocuments}
                searchInput={searchInput}
              />
            ) : (
              <DocumentsGrid
                folders={folders}
                setFolders={setFolders}
                setSelectedFolder={setSelectedFolder}
                documents={documents}
                setDocuments={setDocuments}
                searchInput={searchInput}
              />
            )}
          </div>
        </main>
      </div>
    );
  }
};

const toggleDocumentView = (displayType, setDocumentsView) => {
  const listViewButton = document.getElementById('listed_view');
  const gridViewButton = document.getElementById('grid_view');
  setDocumentsView(displayType);
  // Highlight selected type
  if (displayType === DOCUMENTS_VIEW.LIST) {
    listViewButton.classList.add('new-documents_control-panel__selected-view');
    gridViewButton.classList.remove('new-documents_control-panel__selected-view');
  } else {
    gridViewButton.classList.add('new-documents_control-panel__selected-view');
    listViewButton.classList.remove('new-documents_control-panel__selected-view');
  }
};

const DOCUMENTS_QUERY = gql`
  query training_session_documents($id: ID!) {
    training_session(id: $id) {
      name
      custom_name
      folders_with_documents {
        id
        name
        documents {
          id
          filename
          url
          mime
          wistia {
            hashed_id
          }
          folder_id
          downloadable
          inserted_at
        }
        inserted_at
      }
      base_documents {
        id
        filename
        url
        mime
        wistia {
          hashed_id
        }
        folder_id
        downloadable
        inserted_at
      }
    }
  }
`;

NewDocumentPage.propTypes = {
  training_session: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default NewDocumentPage;
