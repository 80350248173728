import { compareParticipantsBySessionId } from 'components/video_conference/CallBox/utils';
import { TILES_PER_PAGE_LIMIT } from 'constants/video_conference';
import { PARTICIPANT_LOCAL } from 'constants/video_conference/participants';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

function useGridPagination(tiles, pageSize = TILES_PER_PAGE_LIMIT) {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const visibleParticipants = useMemo(() => {
    const paginatedTiles =
      tiles.length - page * pageSize > 0 ? tiles.slice((page - 1) * pageSize, page * pageSize) : tiles.slice(-pageSize);
    const [[localTile], remoteTiles] = _.partition(paginatedTiles.sort(compareParticipantsBySessionId), PARTICIPANT_LOCAL);
    return localTile ? [localTile, ...remoteTiles] : remoteTiles;
  }, [page, tiles, pageSize]);

  const handlePrevClick = () => {
    setPage(page => page - 1);
  };
  const handleNextClick = () => {
    setPage(page => page + 1);
  };

  const isPrevDisabled = pages <= 1 || page <= 1;
  const isNextDisabled = pages <= 1 || page >= pages;
  const isPaginationVisible = pages > 1;

  useEffect(() => {
    setPages(Math.ceil(tiles.length / pageSize));
  }, [tiles.length, pages, pageSize]);

  return {
    handleNextClick,
    handlePrevClick,
    visibleParticipants,
    isPrevDisabled,
    isNextDisabled,
    isPaginationVisible,
  };
}

export default useGridPagination;
