import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';

import { createConfiguration } from './colorConfiguration';
import { ColorConfigurationProvider } from './ColorConfigurationProvider';
import { SAVE_COLOR_CONFIGURATION } from './queries';
import { useAccountInfo } from 'components/user';
import { keepProperties } from 'helpers/utils';

const ColorConfigurationLoaderContext = createContext();

export function ColorConfigurationLoader(props) {
  const { extranetColorConfiguration, extranetCustomColor } = useAccountInfo();
  const [savedConfiguration, setSavedConfiguration] = useState(createConfiguration(extranetColorConfiguration ?? extranetCustomColor));
  const [
    commitSaveColorConfiguration,
    { loading: isSavingColorConfiguration, error: saveColorConfigurationError, data: saveColorConfigurationData },
  ] = useMutation(SAVE_COLOR_CONFIGURATION);

  useEffect(() => {
    setSavedConfiguration(createConfiguration(extranetColorConfiguration ?? extranetCustomColor));
  }, [extranetColorConfiguration, extranetCustomColor]);

  return (
    <ColorConfigurationLoaderContext.Provider
      value={{ saveConfiguration, isSavingColorConfiguration, saveColorConfigurationError, saveColorConfigurationData }}
    >
      <ColorConfigurationProvider defaultConfiguration={savedConfiguration}>{props.children}</ColorConfigurationProvider>
    </ColorConfigurationLoaderContext.Provider>
  );

  /**
   * Save the given color configuration.
   * @param {Object} configuration Color configuration to save.
   */
  function saveConfiguration(configuration) {
    const cleanConfiguration = keepProperties(configuration, ['value']);

    const isConfigurationSaved = commitSaveColorConfiguration({
      variables: {
        configuration: cleanConfiguration,
      },
    });

    if (isConfigurationSaved) {
      setSavedConfiguration(cleanConfiguration);
    }
  }
}

ColorConfigurationLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export function useColorConfigurationLoader() {
  return useContext(ColorConfigurationLoaderContext);
}
