import React, { Suspense, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Chat from 'components/video_conference/Chat';
import roomStyles from 'scss/video_conference/Room.module.scss';
import sidebarStyles from 'scss/video_conference/Sidebar.module.scss';
import chatStyles from 'scss/video_conference/Chat.module.scss';
import useErrorMessage from 'hooks/useErrorMessage';
import SentryErrorBoundary from 'components/SentryErrorBoundary';
import { RoomOptsContext } from 'contexts/video_conference';
import useCommentsQuery from 'hooks/video_conference/useCommentsQuery';
import IconButton from 'components/video_conference/IconButton';
import useRoomElementsDisplay from 'hooks/video_conference/useRoomElementsDisplay';
import { CHAT_ELEMENT, NETWORK_STATS_ELEMENT, PARTICIPANT_ELEMENT } from 'constants/video_conference/roomElements';
import ParticipantView from 'components/video_conference/Participants/ParticipantView';
import { ActionButton } from 'components/mainComponents/buttons';
import NetworkStatsPanel from 'components/video_conference/NetworkStatsPanel';
import clsx from 'clsx';
import { SvgIcon } from 'components/mainComponents/SvgIcon';
import { guestSubscribe } from 'support/realtime';

function ChatErrorFallback({ error, resetError }) {
  const { t } = useTranslation();
  const errorMessage = useErrorMessage(error.message);
  return (
    <div className={sidebarStyles.errorAlert} role='alert'>
      <h2>{t('Sidebar.chat_error_title')}</h2>
      <p>{errorMessage}</p>
      <ActionButton clickFn={resetError} label={t('Sidebar.reload_chat')} className={sidebarStyles.resetButton} icon='redo' />
    </div>
  );
}

ChatErrorFallback.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }).isRequired,
  resetError: PropTypes.func.isRequired,
};

const Sidebar = () => {
  const { roomElements, toggleRoomElement, closeRoomElement } = useRoomElementsDisplay();
  const { t } = useTranslation();

  const { moduleId, dailycoroomId } = useContext(RoomOptsContext);
  const commentsProps = useCommentsQuery(dailycoroomId);

  const commentsToRender = useMemo(() => [...commentsProps.comments].reverse(), [commentsProps.comments]);
  const [messages, setMessages] = useState(commentsToRender);

  const setMessagesCall = comment => {
    setMessages([...messages, comment]);
  };

  const deleteMessageCall = commentId => {
    setMessages(previousMessages => {
      return previousMessages.filter(({ id }) => id.toString() !== commentId.toString());
    });
  };

  guestSubscribe(`videocall-${dailycoroomId}`, 'new-comment', setMessagesCall);
  guestSubscribe(`videocall-${dailycoroomId}`, 'delete-comment', deleteMessageCall);

  const isChatOpen = roomElements.includes(CHAT_ELEMENT);
  const isParticipantOpen = roomElements.includes(PARTICIPANT_ELEMENT);
  const isNetworkStatsOpen = roomElements.includes(NETWORK_STATS_ELEMENT);
  const isSidebarOpen = isChatOpen || isParticipantOpen || isNetworkStatsOpen;

  const isChatCovered = roomElements => {
    return (
      roomElements.includes(CHAT_ELEMENT) &&
      roomElements.some(element => [PARTICIPANT_ELEMENT, NETWORK_STATS_ELEMENT].includes(element))
    );
  };

  const handleToggleChatClick = () => {
    if (isChatCovered(roomElements)) {
      closeRoomElement(PARTICIPANT_ELEMENT);
      closeRoomElement(NETWORK_STATS_ELEMENT);
    } else {
      toggleRoomElement(CHAT_ELEMENT);
    }
  };
  const handleCloseChat = () => closeRoomElement(CHAT_ELEMENT);

  const sidebarContainerStyles = clsx(roomStyles.sidebarWrapper, { [roomStyles.sidebarWrapperHidden]: !isSidebarOpen });

  return (
    <>
      <div className={sidebarContainerStyles}>
        <IconButton
          icon='cross'
          ariaLabel={t('Sidebar.close')}
          clickFn={handleCloseChat}
          type='secondary'
          className={sidebarStyles.closeButton}
        />
        <div className={sidebarStyles.Sidebar}>
          <div className={sidebarStyles.chatAndParticipants}>
            {isParticipantOpen && <ParticipantView />}
            {isNetworkStatsOpen && <NetworkStatsPanel />}
            {isChatOpen && (
              <Suspense fallback={<SvgIcon name='loading-spinner--dark--one-quarter' />}>
                <div className={sidebarStyles.chat}>
                  <div className={chatStyles.Chat}>
                    <SentryErrorBoundary id='chat' FallbackComponent={ChatErrorFallback}>
                      <Chat moduleId={moduleId} dailycoroomId={dailycoroomId} messages={messages} {...commentsProps} />
                    </SentryErrorBoundary>
                  </div>
                </div>
              </Suspense>
            )}
          </div>
        </div>
      </div>
      <IconButton
        title={t('Tray.chat')}
        icon='speech-balloon'
        clickFn={handleToggleChatClick}
        type='secondary'
        className={roomStyles.chatButton}
      />
    </>
  );
};

export default Sidebar;
