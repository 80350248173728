import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { useLocation } from 'react-router';
import { withData, withRouteParams } from '../../support/page';
import { buildPatternStyle } from '../../support/image';
import { localDateFormat, formatSlotDate } from '../../support/date';
import TrainingSessionHeader from '../training_session/TrainingSessionHeader';
import AttendanceBoard from './AttendanceBoard';
import '../../scss/TrainingSessionAttendancePage.scss';
import { getConfig } from 'config';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';

const AttendanceLink = ({ name, slot, date, id, selectedDate, updateId }) => {
  const { t } = useTranslation();

  return (
    <div onClick={() => updateId(id)} className={`AttendanceDate ${selectedDate === id ? 'selected' : ''}`}>
      <span className='AttendanceLink'>
        <span className='date'>{localDateFormat(date)}</span>
        <span className='slot'>{t(slot)}</span>
        <span className='session-name'>{name}</span>
      </span>
    </div>
  );
};

const DateList = ({ subsessions, selectedDate, updateId, fromDigiforma }) => (
  <div className='DateList'>
    {fromDigiforma && <BackToDigiformaLink urlQuery={fromDigiforma} />}
    {subsessions.map(({ id, name, trainingSessionSlots }) => {
      return (
        <details key={id} open={true}>
          <summary className='DateListItem'>{name}</summary>
          {trainingSessionSlots.map(({ id, name, slot, date }) => (
            <AttendanceLink key={id} id={id} updateId={updateId} name={name} slot={slot} date={date} selectedDate={selectedDate} />
          ))}
        </details>
      );
    })}
  </div>
);

const BackToDigiformaLink = ({ urlQuery }) => {
  const { t } = useTranslation();

  return (
    <div className='AttendanceDate'>
      <a className='AttendanceLink' href={`${getConfig().serverRoot}${urlQuery}`}>
        {t('TrainingSessionAttendancePage.back_to_digiforma')}
      </a>
    </div>
  );
};

const TrainingSessionAttendancePage = ({ training_session }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { search } = useLocation();
  const trainingSessionName = training_session.custom_name || training_session.name;
  const fromDigiforma = new URLSearchParams(search).get('fromDigiforma');
  const subsessions = training_session.subsessions.filter(
    subsession => !subsession.datesAreInterval && subsession.trainingSessionSlots.length > 0
  );
  const [selectedId, setSelectedId] = useState(subsessions.length > 0 ? subsessions[0].trainingSessionSlots[0].id : null);
  const selectedDate = subsessions
    .map(it => it.trainingSessionSlots)
    .flat()
    .find(it => it.id === selectedId);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('id')) {
      setSelectedId(searchParams.get('id'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, training_session]);

  if (!training_session.has_numeric_signing) {
    return null;
  }

  return (
    <div className='page TrainingSessionAttendancePage'>
      {!fromDigiforma && <TrainingSessionHeader training_session_id={training_session.id} selected='attendance' />}
      <main className={fromDigiforma ? 'main--no-header' : ''}>
        <div className='hero' style={buildPatternStyle(training_session)}>
          <h1>{trainingSessionName}</h1>
        </div>
        {subsessions.length > 0 ? (
          <section className='AttendancePanel'>
            <DateList subsessions={subsessions} selectedDate={selectedId} updateId={setSelectedId} fromDigiforma={fromDigiforma} />
            <div className='right-column'>
              <h1 className='title'>
                {t('TrainingSessionAttendancePage.digital_signoff_follow_up')}{' '}
                {selectedDate && <span>{formatSlotDate(selectedDate)}</span>}
                {selectedDate && <div className='session-name'>{selectedDate.name}</div>}
              </h1>
              {selectedDate && (
                <AttendanceBoard
                  id={training_session.id}
                  date={selectedDate}
                  attendance_id={training_session.attendance_id}
                  fromDigiforma={fromDigiforma}
                />
              )}
              {!selectedDate && <div className='ChooseDateLabel'>{t('TrainingSessionAttendancePage.choose_a_date')}</div>}
            </div>
          </section>
        ) : (
          <section>
            <h1 className='title'>{t('TrainingSessionAttendancePage.no_dates_to_sign')}</h1>
          </section>
        )}
      </main>
    </div>
  );
};

const query = gql`
  query training_session_attendance_page($id: ID!) {
    training_session(id: $id) {
      id
      name
      custom_name
      address
      use_map
      show_program_in_extranet
      show_rules_in_extranet
      show_place_in_extranet
      show_dates_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      has_numeric_signing
      extranet_description_html {
        html
        css
      }
      image {
        url
      }
      start_date
      end_date
      subsessions {
        id
        name
        datesAreInterval
        trainingSessionSlots {
          id
          date
          startTime
          endTime
          slot
          signatures {
            signature
            customerTrainee {
              id
            }
            trainingSessionInstructor {
              id
            }
          }
          trainingSessionInstructors {
            id
            instructor {
              id
              firstname
              lastname
              email
            }
          }
          customers {
            customerTrainees {
              id
              abandons {
                id
                trainingSessionSlots {
                  id
                }
              }
              trainee {
                id
                firstname
                lastname
                email
              }
            }
          }
        }
      }
      modules {
        id
        name
        visible
      }
      program {
        id
      }
      attendance_id
    }
  }
`;

const withGraphqlData = graphql(query, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: parseInt(props.params.training_session_id, 10) },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        TrainingSessionAttendancePage, { loading: LoadingPagePlaceholder })));
