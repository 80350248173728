import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { TextedUserConfused } from '../widgets/TextedUserConfused';
import moment from 'moment';
import _ from 'lodash';
import { buildPatternStyle } from '../../support/image';

import '../../scss/TrainingSessionList.scss';

class TrainingSessionsList extends Component {
  render() {
    const training_sessions = sortByDate(this.props.training_sessions);

    if (training_sessions.length === 0) return <TextedUserConfused />;
    return (
      <ul className='no-bullet TrainingSessionList'>
        {training_sessions.map(ts => (
          <li key={ts.id}>
            <Link to={`/ts/${ts.id}`} style={buildPatternStyle(ts)}>
              <span className='name'>{ts.custom_name || ts.name}</span>
              <span className='date'>{formatDate(ts.start_date, this.props.t('TrainingSessionList.no_date'))}</span>
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

function formatDate(date, nonValidDateMsg) {
  const m = moment(date);
  if (m.isValid()) {
    return m.format('L');
  }
  return nonValidDateMsg;
}

function sortByDate(training_sessions) {
  return _(training_sessions)
    .sortBy(ts => moment(ts.start_date).valueOf())
    .reverse()
    .value();
}

export default withTranslation()(TrainingSessionsList);
