import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import Program from './Program';
import TrainingSessionHeader from '../training_session/TrainingSessionHeader';
import { withData, withRouteParams } from '../../support/page';
import LoadingPagePlaceholder from 'components/mainComponents/pages/LoadingPagePlaceholder.jsx';

const TrainingSessionProgramPage = ({ training_session }) => {
  const { program } = training_session;

  return (
    <div className='page TrainingSessionProgramPage'>
      <TrainingSessionHeader training_session_id={training_session.id} selected='program' />
      <main>{program && <Program program={program} />}</main>
    </div>
  );
};

const query = gql`
  query training_session_program_page($id: ID!) {
    training_session(id: $id) {
      id
      name
      show_program_in_extranet
      show_trainees_in_extranet
      show_trainee_pedagogical_tracking_in_extranet
      has_numeric_signing
      modules {
        id
        name
        visible
      }
      start_date
      program {
        id
        name
        description
        duration_in_hours
        duration_in_days
        training_pedagogical_modality
        mentoring
        goals {
          text
        }
        pedagogical_resources {
          text
        }
        targets {
          text
        }
        prerequisites {
          text
        }
        assessments {
          text
        }
        steps {
          text
          substeps {
            text
          }
        }
        documents {
          filename
          url
          mime
        }
      }
    }
  }
`;

const withGraphqlData = graphql(query, {
  name: 'training_session',
  options: props => {
    return {
      variables: { id: parseInt(props.params.training_session_id, 10) },
    };
  },
});

// prettier-ignore
export default
  withRouteParams(
    withGraphqlData(
      withData('training_session')(
        TrainingSessionProgramPage, { loading: LoadingPagePlaceholder })));
