import React from 'react';
import ItemsCollection from 'components/mainComponents/ItemsCollection';
import EvaluationLink from './EvaluationLink';

const TYPES_IN_ORDER = ['pre', 'hot', 'cold'];

function evaluationSortingIndex({ type }) {
  return TYPES_IN_ORDER.indexOf(type);
}

function sortByType({ evaluation: eval_1 }, { evaluation: eval_2 }) {
  return evaluationSortingIndex(eval_1) > evaluationSortingIndex(eval_2);
}
const EvaluationList = ({ evaluations }) => {
  return (
    <ItemsCollection>
      {evaluations.sort(sortByType).map((props, idx) => (
        <EvaluationLink key={idx} {...props} playable />
      ))}
    </ItemsCollection>
  );
};

export default EvaluationList;
