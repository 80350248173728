import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as RadixCheckbox from '@radix-ui/react-checkbox';

import './Checkbox.scss';
import { forwardRef } from 'react';

/**
 * Stylized checkbox-type input. All undocumented props will be passed down to the `input` element.
 * @param {object} props
 * @param {ReactNode} props.children Label of the checkbox. Though not required, a labelless input is not accessible.
 * @param {'left' | 'right'} props.position Position of the checkbox around the label. Default to 'left'.
 * @param {boolean} props.fullWidth Whther the checkbox will grow the label to fill horizontal space.
 */
export const Checkbox = forwardRef((props, ref) => {
  const { children, position, fullWidth, className: extraClassname, disabled, ...checkboxProps } = props;
  const classname = clsx('Checkbox', { 'Checkbox--full-width': fullWidth, 'Checkbox--disabled': disabled }, extraClassname);

  return (
    <label className={classname}>
      {children && position === 'right' && <span className='Checkbox__label'>{props.children}</span>}
      <RadixCheckbox.Root disabled={disabled} {...checkboxProps} className='Checkbox__input' ref={ref}>
        <RadixCheckbox.Indicator className='Checkbox__indicator' />
      </RadixCheckbox.Root>
      {children && position === 'left' && <span className='Checkbox__label'>{props.children}</span>}
    </label>
  );
});

Checkbox.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  fullWidth: PropTypes.bool,
};

Checkbox.defaultProps = {
  position: 'left',
  fullWidth: false,
};
