import { useMutation } from '@apollo/client';
import { RATE_VIDEO_CALL_QUALITY_MUTATION } from 'mutations/video_conference';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ActionButton, SubmitButton } from 'components/mainComponents/buttons';
import styles from 'scss/video_conference/FeedbackPage.module.scss';
import Rating from '../Rating';
import { Illustration } from 'components/mainComponents/Illustration';

function FeedbackPage() {
  const { t } = useTranslation();
  const [ratingValue, setRatingValue] = useState(5);
  const { state } = useLocation();

  const feedbackUUID = state?.feedbackUUID;
  const navigateTo = state?.navigateTo ?? '/';
  const returnTo =
    state?.trainingSessionId && state?.moduleId ? `/ts/${state.trainingSessionId}/module/${state.moduleId}/videoconference` : '/';

  const navigate = useNavigate();
  const [rateVideoCallQuality, { loading }] = useMutation(RATE_VIDEO_CALL_QUALITY_MUTATION, {
    onCompleted: () => {
      navigate(navigateTo);
    },
  });

  if (!feedbackUUID) {
    navigate('404', { replace: true });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!loading) {
      rateVideoCallQuality({ variables: { feedbackUUID, rating: ratingValue } });
    }
  }

  function handleClose() {
    navigate(navigateTo);
  }

  function handleReturn() {
    navigate(returnTo);
  }

  return (
    <div className='page EmptyPage EmptyPage__feedbackPage'>
      <div className={styles.returnButton}>
        <ActionButton clickFn={handleReturn} label={t('FeedbackPage.return')} type='secondary' icon='arrow--left' />
      </div>
      <div className={styles.container}>
        <Illustration name='robot-with-stars' size='lg' />
        <h1 className={styles.title}>{t('FeedbackPage.header')}</h1>
        <p className={styles.subtitle}>{t('FeedbackPage.body')}</p>
        <form className={styles.feedbackForm} onSubmit={handleSubmit}>
          <Rating value={ratingValue} onChange={setRatingValue} ratingUniqueId='video-conference-feedback-rating' />
          <div className={styles.buttons}>
            <ActionButton clickFn={handleClose} label={t('FeedbackPage.close')} type='secondary' />
            <SubmitButton disabled={loading} label={t('FeedbackPage.submit')} icon='mail' />
          </div>
        </form>
      </div>
    </div>
  );
}

export default FeedbackPage;
