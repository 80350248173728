import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'react-i18next';

import { AuthContextProvider } from 'contexts/AuthContext';
import { apolloClient } from 'store/apolloClient';
import ThemeContextProvider from 'components/mainComponents/CustomColorChooser/ThemeContext';
import i18n from 'translations/i18n';

export function Providers({ children }) {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <ThemeContextProvider>{children}</ThemeContextProvider>
          </I18nextProvider>
        </BrowserRouter>
      </AuthContextProvider>
    </ApolloProvider>
  );
}
