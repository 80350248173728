import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import 'scss/InstructorsSelect.scss';
import { useTranslation } from 'react-i18next';
import { ALL_INSTRUCTORS } from 'actions/datesActions';
import { SvgIcon } from 'components/mainComponents/SvgIcon';

/* TEMPORARY COMMENT
  This selector will not work if you put more than one on the same page.
  It can be used as just one selector on one page!
  It will be replaced after creating a versatile selector. 
*/

export default function Select({ instructors, onChange }) {
  const { t } = useTranslation();

  function setSelectedInstructorAndStyles(event) {
    const instructorId = event.target.id;
    const instructorFullName = event.target.value;

    onChange(instructorId);

    const currentlySelectedInstructor = document.querySelector('.apply-selected-instructors-styles');
    if (currentlySelectedInstructor) currentlySelectedInstructor.classList.remove('apply-selected-instructors-styles');
    document.querySelector('.currently-selected-instructor').innerText = instructorFullName;
    document.querySelector(`label[for="${instructorId}"]`).classList.add('apply-selected-instructors-styles');
  }

  return (
    <div className='instructors-dropdown' onMouseOver={showDropdown} onMouseOut={hideDropdown}>
      <div className='main-selector-view'>
        <p className='currently-selected-instructor'>{t('Select.select_instructors')}</p>
        <SvgIcon name='chevron--down' className='instructors-dropdown-arrow' />
      </div>
      <div className='instructors-dropdown-content'>
        <input
          type='radio'
          id={ALL_INSTRUCTORS}
          value={t('Select.select_instructors')}
          className='instructors-selector'
          onClick={setSelectedInstructorAndStyles}
        />
        <label className='instructors-label' htmlFor={ALL_INSTRUCTORS}>
          {t('Select.select_instructors')}
        </label>
        {instructors.map(({ id, firstname, lastname }) => (
          <Fragment key={id}>
            <input
              type='radio'
              id={id}
              value={`${firstname} ${lastname}`}
              className='instructors-selector'
              onClick={setSelectedInstructorAndStyles}
            />
            <label className='instructors-label' htmlFor={id}>
              {firstname} {lastname}
            </label>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function showDropdown() {
  document.querySelector('.instructors-dropdown-content').classList.add('instructors-dropdown-content-on-hover');
  document.querySelector('.instructors-dropdown-arrow').classList.add('instructors-dropdown-arrow-rotate');
}

function hideDropdown() {
  document.querySelector('.instructors-dropdown-content').classList.remove('instructors-dropdown-content-on-hover');
  document.querySelector('.instructors-dropdown-arrow').classList.remove('instructors-dropdown-arrow-rotate');
}

Select.propTypes = {
  instructors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
};
