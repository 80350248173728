import { DISPLAY_MODE_FEATURED_SPEAKER, DISPLAY_MODE_GRID } from '../../constants/video_conference';
import { isInstructor } from './participants';

export function callReady(callObject) {
  return !!callObject.participants().local;
}

export function getStreamStates(callObject) {
  let isCameraMuted;
  let isMicMuted;
  let isSharingScreen;

  if (callReady(callObject)) {
    const localParticipant = callObject.participants().local;
    isCameraMuted = !localParticipant.video;
    isMicMuted = !localParticipant.audio;
    isSharingScreen = localParticipant.screen;
  }

  return { isCameraMuted, isMicMuted, isSharingScreen };
}

export const defaultDisplayMode = participant => {
  if (isInstructor(participant)) {
    return DISPLAY_MODE_GRID;
  }
  return DISPLAY_MODE_FEATURED_SPEAKER;
};
